import { useMemo } from "react";
import { useMemberTags } from "@circle-workflows/hooks/useMemberTags";

export const useMemberTagsIdMap = () => {
  const { memberTags } = useMemberTags();
  return useMemo(
    () =>
      memberTags
        .map(tag => ({
          id: tag.id,
          name: tag.name,
        }))
        .reduce((accumulator, tag) => {
          accumulator[tag.id] = tag.name;
          return accumulator;
        }, {}),
    [memberTags],
  );
};
