import { t } from "@/i18n-js/instance";
import { useAccessGroup } from "@circle-react/hooks/accessRegistry/useAccessGroup";
import { BaseResource } from "../../BaseResource";

export const AccessGroupResource = ({ resourceId }: { resourceId: number }) => {
  const { accessGroup, isLoadingAccessGroup } = useAccessGroup(resourceId);
  return (
    <BaseResource
      label={t("settings.workflows.view.resources.access_group")}
      value={accessGroup?.name}
      isLoading={isLoadingAccessGroup}
    />
  );
};
