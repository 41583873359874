import { useBoolean } from "react-use";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import type { DropdownOptions } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { useAccessGroupData } from "../DataStoreContext/AccessGroupDataContext";
import { RemoveAccessGroupMembersConfirmationModal } from "./RemoveAccessGroupMembersConfirmationModal";

const i18nRoot = "settings.access_groups.admin.actions.bulk_actions";

export const AccessGroupMembersBulkActions = () => {
  const { shouldBlockActions } = useAccessGroupData();

  const [isRemovalModalVisible, toggleIsRemovalModalVisible] =
    useBoolean(false);

  const dropdownOptions: DropdownOptions = [
    {
      icon: "16-remove-user",
      label: t([i18nRoot, "remove_members"]),
      onClick: () => {
        toggleIsRemovalModalVisible();
      },
      value: "reorder",
    },
  ];
  return (
    <>
      <RemoveAccessGroupMembersConfirmationModal
        isVisible={isRemovalModalVisible}
        onClose={toggleIsRemovalModalVisible}
      />

      <Dropdown
        direction="bottom-start"
        button={
          <Dropdown.MenuButton>
            <Button
              disabled={shouldBlockActions}
              small
              variant="secondary-light"
            >
              <Typography.LabelXs weight="medium">
                {t([i18nRoot, "title"])}
              </Typography.LabelXs>
              <Icon type="16-chevron-down" size={16} />
            </Button>
          </Dropdown.MenuButton>
        }
        options={dropdownOptions}
      />
    </>
  );
};
