import { useEffect } from "react";
import { useScroll } from "@/react/components/SettingsApp/CommunityInbox/hooks/useScroll";
import { usePunditUserContext } from "@circle-react/contexts";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { useWebSocket } from "@circle-react/hooks/useWebSocket";
import type { MemberChannelMessage } from "@circle-react/types/CommunityInbox/MemberChannelMessage";

export const useCommunityInboxSync = ({
  currentChatId,
  isPreview = false,
}: {
  currentChatId: number | undefined;
  isPreview?: boolean;
}) => {
  const { currentCommunityMember } = usePunditUserContext();
  const communityMemberId = currentCommunityMember?.id;
  const {
    setCurrentChatId,
    appendMessage,
    updateMessage,
    destroyMessage,
    appendChat,
    updateChat,
    destroyChat,
    setPreviewChat,
    previewChat,
  } = useCommunityInboxStore();

  const { adjustScroller } = useScroll();

  useEffect(() => {
    if (currentChatId) {
      setCurrentChatId(currentChatId);
    }
  }, [currentChatId, setCurrentChatId]);

  return useWebSocket(
    {
      channel: "CommunityInbox::MemberChannel",
      community_member_id: communityMemberId,
      canCreateConnection: !!communityMemberId,
      onMessageReceive: (data: MemberChannelMessage) => {
        switch (data.event) {
          case "createMessage": {
            appendMessage(data.json_message, "member");
            break;
          }
          case "updateMessage": {
            updateMessage(data.json_message);
            adjustScroller();
            break;
          }
          case "destroyMessage": {
            destroyMessage(data.json_message.id);
            break;
          }
          case "createChat": {
            appendChat(data.json_message, "member");
            break;
          }
          case "updateChat": {
            const chatId = data.json_message.id;
            if (isPreview && chatId === previewChat?.id) {
              setPreviewChat(data.json_message);
            } else {
              updateChat(data.json_message, "member");
            }
            break;
          }
          case "destroyChat": {
            destroyChat(data.json_message.id, "member");
            break;
          }
          default:
            break;
        }
      },
    },
    [communityMemberId],
  );
};
