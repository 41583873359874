import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { StandardLayout } from "@/react/components/Layout/StandardLayout";
import { spaces } from "@/react/helpers/urlHelpers";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import type { Post } from "@circle-react/types/Post";
import type { Space } from "@circle-react/types/Space";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { PostDetailsHeader } from "./PostDetailsHeader";
import { VersionedPostWrapper } from "./VersionedPostWrapper";

interface PostNotFoundProps {
  space: Space;
  postResource: Post;
  onPostDestroy: () => void;
  spaceSlug: string;
}

export const PostNotFound = ({
  space,
  postResource,
  onPostDestroy,
  spaceSlug,
}: PostNotFoundProps) => {
  const history = useHistory();
  return (
    <VersionedContent fullWidth>
      <StandardLayout.Body>
        <PostDetailsHeader
          space={space}
          postResource={postResource}
          onPostDestroy={onPostDestroy}
        />
        <VersionedPostWrapper>
          <div className="w-full p-4 md:px-0">
            <div className="bg-primary border-primary mt-5 flex w-full flex-col items-center justify-center gap-4 rounded-lg border p-6 md:mt-0">
              <Typography.TitleMd weight="font-bold">
                {t("post_not_found")}
              </Typography.TitleMd>
              <Typography.BodyMd textAlign="text-center">
                {t("post_not_found_description")}
              </Typography.BodyMd>
              <Button
                large
                variant="circle"
                onClick={() => history.push(spaces.show({ spaceSlug }))}
              >
                {t("back_to_space")}
              </Button>
            </div>
          </div>
        </VersionedPostWrapper>
      </StandardLayout.Body>
    </VersionedContent>
  );
};
