import { t } from "@/i18n-js/instance";
import { Form } from "@/react/components/shared/uikit/Form";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { FormContent } from "./FormContent";
import { MIGRATION_FLOW_OPTIONS } from "./FormContent/constants";

interface MigrationModalType {
  paywallId: number;
  closePaywallFormModal: () => void;
}

const i18nBase = "settings.access_groups.paywall_migration.modal";

export const MigrationContent = ({
  paywallId,
  closePaywallFormModal,
}: MigrationModalType) => {
  const submitHandler = () => {};

  const getDefaultValues = () => ({
    paywall_id: paywallId,
    access_groups: [],
    migration_flow: MIGRATION_FLOW_OPTIONS.new_access_group,
  });

  const modal = useModal();

  const closeModals = () => {
    void modal.hide();
    closePaywallFormModal();
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content size="xl">
        <Modal.Header>
          <Modal.Title size="sm">
            <Typography.TitleSm weight="bold">
              {t([i18nBase, "title"])}
            </Typography.TitleSm>
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <div className="py-2">
          <Form onSubmit={submitHandler} defaultValues={getDefaultValues()}>
            <FormContent closeModals={closeModals} />
          </Form>
        </div>
      </Modal.Content>
    </Modal>
  );
};

const MigrationModal = ModalManager.create(
  ({ paywallId, closePaywallFormModal }: MigrationModalType) => (
    <MigrationContent
      paywallId={paywallId}
      closePaywallFormModal={closePaywallFormModal}
    />
  ),
);

export const useMigrationModal = () => useModal(MigrationModal);
