import { t } from "@/i18n-js/instance";
import { OpenQPModal } from "@/react/components/QuickPost/OpenQPModal";
import { Icon } from "@circle-react-shared/Icon";

export const OpenQPModalButton = () => (
  <OpenQPModal
    render={({ onClick }) => (
      <button
        type="button"
        className="text-dark flex w-full justify-center p-5"
        onClick={onClick}
      >
        <Icon
          aria-label={t("mobile_nav.quick_post")}
          type="bar-plus"
          size={24}
          useWithFillCurrentColor
        />
      </button>
    )}
  />
);
