import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { HeaderForm } from "../Forms/HeaderForm";
import { PaywallForm } from "../Forms/PaywallForm";
import { PaywallPriceForm } from "../Forms/PaywallPriceForm";
import { usePaywallsEditFormContext } from "../PaywallsEditFormProvider";

export const RootForm = () => {
  const {
    shouldDisableAddPaywall,
    fieldArrayMethods,
    onClickAddPaywall: onClick,
  } = usePaywallsEditFormContext();

  return (
    <>
      <div className="divide-primary flex w-full flex-col divide-y">
        {fieldArrayMethods.fields &&
          fieldArrayMethods.fields.map((paywall: any, index: number) => (
            <div
              key={paywall._id}
              className="flex flex-col gap-y-4 py-6 first:pt-0 last:pb-0"
            >
              <HeaderForm index={index} />
              <div className={classNames("flex flex-col gap-y-4")}>
                <PaywallForm initialPaywallId={paywall.id} index={index} />
                <PaywallPriceForm index={index} />
              </div>
            </div>
          ))}
      </div>
      <Button
        variant="circle"
        large
        type="button"
        onClick={onClick}
        disabled={shouldDisableAddPaywall}
      >
        {t("paywalls.lock_screen.sidebar.paywall_edit_form.add_paywall")}
      </Button>
    </>
  );
};
