import {
  isCardViewSpace,
  isGridOrMasonryViewImageSpace,
  isListViewSpace,
} from "@/react/helpers/spaceHelpers";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";

export const ListLoader = ({ space, count, withContainer, columnsCount }) => {
  if (isCardViewSpace(space)) {
    return (
      <div className="grid grid-cols-1 gap-6 first:mt-5 md:mx-0 md:grid-cols-2 md:first:mt-0 xl:grid-cols-3">
        <SkeletonLoader
          variant="card-view"
          count={count}
          withContainer={withContainer}
        />
      </div>
    );
  }

  if (isGridOrMasonryViewImageSpace(space)) {
    return (
      <SkeletonLoader
        variant="grid-view"
        count={count}
        columnsCount={columnsCount}
      />
    );
  }

  if (isListViewSpace(space)) {
    return <SkeletonLoader variant="list-view" count={count} />;
  }

  return <SkeletonLoader variant="feed-view" count={count} />;
};
