import { getAdminAudienceEditPathsV2 } from "@circle-react/helpers/urlHelpers";
import { AdminAudienceEditPage } from "@circle-react/pages/AdminAudience/AdminAudienceEditPage";
import { ProtectedRoute } from "@circle-react-shared/ProtectedRoute";

const paths = getAdminAudienceEditPathsV2({
  sqid: ":sqid",
});

const audienceRoutes = [
  paths.index,
  paths.info,
  paths.access,
  paths.billing,
  paths.activityLogs,
] as const;

export const AdminAudienceEditRoutes = () => (
  <ProtectedRoute
    component={AdminAudienceEditPage}
    path={audienceRoutes}
    exact
  />
);
