import { DataTable } from "@circle-react-uikit/DataTableV2";

export const TableList = ({ columns, query, onChangePage }: any) => (
  <DataTable
    columns={columns}
    data={query?.data?.records ?? []}
    isLoading={query?.isLoading}
    initialPage={query?.data?.page}
    pageSize={query?.data?.per_page}
    totalRecordsCount={query?.data?.count}
    onChangePage={onChangePage}
  >
    <DataTable.Table>
      <DataTable.Header isSticky={false} />
      <DataTable.Loader />
      <DataTable.Body />
    </DataTable.Table>
    {query?.data?.count > query?.data?.per_page && (
      <DataTable.Pagination
        align="left"
        direction="reverse"
        buttonType="button"
      />
    )}
  </DataTable>
);
