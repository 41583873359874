import { t } from "@/i18n-js/instance";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { MemberTagSelector } from "./shared/MemberTagSelector";
import { TagTriggerConditionsView } from "./shared/TagTriggerConditionsView";

export const memberUntagged: Readonly<EventOption> = {
  label: t("settings.workflows.form.events.contact_untagged.title"),
  value: "contact_untagged",
  group: t("settings.workflows.form.event_groups.tags"),
  description: t("settings.workflows.form.events.contact_untagged.description"),
  resource: TRIGGER_RESOURCE_TYPE.MEMBER_TAG,
  formComponent: MemberTagSelector,
  viewComponent: TagTriggerConditionsView,
  icon: "tag",
};
