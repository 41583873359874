import { useEffect, useState } from "react";
import {
  canAccessSpaceMembersPage,
  canShowPinnedPostSidebar,
  isCardViewSpace,
  isEventsSpace,
  isImagesSpace,
  isMembersSpace,
} from "@/react/helpers/spaceHelpers";
import { usePostListPinnedOnSidebar } from "@/react/hooks/posts/usePostList";
import {
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { useActiveSpaceMembersCount } from "@circle-react/contexts/useActiveSpaceMembersCount";
import { isMemberDirectoryVisible } from "@circle-react/helpers/communityMemberHelpers";
import { useIsLockedSpace } from "@circle-react/hooks/useIsLockedSpace";

export const useHasSpaceRightSidebar = () => {
  const [hasRightSidebar, setHasRightSidebar] = useState(false);
  const isLockedSpace = useIsLockedSpace();
  const { data: space } = useCurrentSpaceContext();
  const { currentCommunityMember, currentCommunitySettings } =
    usePunditUserContext();
  const canAccessSpaceMembers = canAccessSpaceMembersPage(
    space,
    currentCommunityMember,
  );

  const {
    count: activeSpaceMembersCount,
    isLoading: isLoadingActiveSpaceMembersCount,
  } = useActiveSpaceMembersCount({
    spaceId: space?.id,
    enabled: canAccessSpaceMembers,
  });
  const { posts: pinnedPosts = [], isLoading: isPinnedPostsLoading } =
    usePostListPinnedOnSidebar();

  useEffect(() => {
    if (isLockedSpace) {
      setHasRightSidebar(false);
      return;
    }

    if (!space || isPinnedPostsLoading || isLoadingActiveSpaceMembersCount) {
      return;
    }

    const { hide_right_sidebar: hideRightSidebar } = space;

    if (
      isEventsSpace(space) ||
      isMembersSpace(space) ||
      isImagesSpace(space) ||
      isCardViewSpace(space) ||
      hideRightSidebar
    ) {
      setHasRightSidebar(false);
      return;
    }

    const canAccessSpaceMembers = canAccessSpaceMembersPage(
      space,
      currentCommunityMember,
    );
    const canShowPinnedPosts = canShowPinnedPostSidebar(
      space,
      currentCommunityMember,
    );

    const hasMembers = Boolean(
      canAccessSpaceMembers &&
        isMemberDirectoryVisible(
          currentCommunityMember,
          currentCommunitySettings,
        ) &&
        activeSpaceMembersCount > 0,
    );
    const hasPinnedPosts = Boolean(
      canShowPinnedPosts && pinnedPosts.length > 0,
    );

    setHasRightSidebar(hasMembers || hasPinnedPosts);
  }, [
    isLockedSpace,
    isPinnedPostsLoading,
    pinnedPosts.length,
    isLoadingActiveSpaceMembersCount,
    activeSpaceMembersCount,
    space,
    currentCommunityMember,
    isMemberDirectoryVisible,
    canAccessSpaceMembers,
  ]);

  return hasRightSidebar;
};
