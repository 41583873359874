import { t } from "@/i18n-js/instance";
import { FormTipTapEditor } from "@circle-react/components/shared/uikit/Form/FormTipTapEditor";
import { URL_PATTERN } from "@circle-react/helpers/urlHelpers/constants";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { useCurrentAction } from "../../ActionRule";

export const FormSendToWebhook = () => {
  const { paramsPath, isViewOnlyMode } = useCurrentAction();

  return (
    <div className="react-form flex flex-col gap-9">
      <Form.Item
        name={`${paramsPath}.url`}
        hideBorder
        fullWidth
        hideDescription
        label={t("settings.workflows.form.webhook_path.name")}
        labelClassName="!mb-2 !text-sm"
        className="!py-0"
        placeholder={t("settings.workflows.form.webhook_path.placeholder")}
        rules={{
          pattern: {
            value: URL_PATTERN,
            message: t("settings.workflows.form.webhook_path.error"),
          },
        }}
      >
        <Form.Input />
      </Form.Item>

      <div className="flex flex-col gap-2">
        <Typography.LabelSm weight="semibold">
          {t("settings.workflows.form.optional_description")}
        </Typography.LabelSm>
        <FormTipTapEditor
          placeholder={t(
            "settings.workflows.form.optional_description_placeholder",
          )}
          name={`${paramsPath}.description.body`}
          showMenuBar={false}
          submitOnEnter={false}
          className="!border-primary focus-within:!border-light !mb-0 !px-4 !text-base"
          editorClassName="min-h-[5rem]"
          type="workflow"
          disabled={isViewOnlyMode}
        />
      </div>
    </div>
  );
};
