import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { Dropdown } from "@circle-react-uikit/Dropdown";

const i18nRoot = "settings.workflows.table.actions";

export const View = ({ workflowId }) => {
  const history = useHistory();

  const onClickHandler = () => {
    history.push({
      pathname: workflowsSettingsPaths.view({ workflowId }),
      state: {
        from: {
          path: history.location.pathname,
          query: history.location.search,
        },
      },
    });
  };

  return (
    <Dropdown.ItemWithLink onClick={onClickHandler}>
      {t([i18nRoot, "view"])}
    </Dropdown.ItemWithLink>
  );
};

View.propTypes = {
  workflowId: PropTypes.string.isRequired,
};
