import { useQuery } from "react-query";
import type { MigrationFormPayloadType } from "@/react/types/PaywallAccessGroupMigration";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface AccessGroupPreviewData {
  name: string;
  total_spaces: number;
  content: {
    space_group_name: string;
    spaces: {
      name: string;
    }[];
  }[];
}

export interface PreviewDataType {
  access_groups: AccessGroupPreviewData[];
  changes: {
    new_spaces: string[];
    lost_spaces: string[];
  };
}

export const useAccessGroupsMigrationPreviewApi = (
  params: MigrationFormPayloadType,
) => {
  const {
    data: previewData,
    isLoading,
    isError,
    error,
  } = useQuery<PreviewDataType, Error>(
    internalApi.admin.paywalls.accessGroupsMigration.preview(params),
  );

  return {
    previewData,
    isLoading,
    isError,
    error,
  };
};
