import { useEffect, useState } from "react";
import { getCurrentCircleLocale } from "@circle-react/helpers/localeHelpers";

interface ImageVariants {
  thumbnail: string;
  small: string;
  medium: string;
  large: string;
}

export const useAIAgentsLocalizedFeatureImages = (featureNumber: number) => {
  const currentLocale = getCurrentCircleLocale();
  const [imageVariants, setImageVariants] = useState<ImageVariants | null>(
    null,
  );

  useEffect(() => {
    const loadImages = async () => {
      const localePath =
        currentLocale === "pt" ? "landing-page-pt" : "landing-page-en";

      try {
        const [thumbnail, small, medium, large] = await Promise.all([
          import(
            /* webpackChunkName: "AIAgents_Thumbnail" */
            `@circle-assets/images/features/ai-agents/${localePath}/feature-${featureNumber}_1x.png`
          ),
          import(
            /* webpackChunkName: "AIAgents_Small" */
            `@circle-assets/images/features/ai-agents/${localePath}/feature-${featureNumber}_1x.png`
          ),
          import(
            /* webpackChunkName: "AIAgents_Medium" */
            `@circle-assets/images/features/ai-agents/${localePath}/feature-${featureNumber}_2x.png`
          ),
          import(
            /* webpackChunkName: "AIAgents_Large" */
            `@circle-assets/images/features/ai-agents/${localePath}/feature-${featureNumber}_3x.png`
          ),
        ]);

        setImageVariants({
          thumbnail: thumbnail.default,
          small: small.default,
          medium: medium.default,
          large: large.default,
        });
      } catch (error) {
        console.error("Failed to load feature images:", error);
        setImageVariants(null);
      }
    };

    void loadImages();
  }, [currentLocale, featureNumber]);

  return imageVariants;
};
