import { t } from "@/i18n-js/instance";
import { useToast } from "@/react/components/shared/uikit/ToastV2";
import { useUpdateCommunitySetting } from "@/react/hooks/UseCommunitySetting";
import type { CommunitySettings } from "@/react/types/CommunitySettings";
import { VIEWS } from "@circle-react-shared/MembersList/constants";
import { Button } from "@circle-react-uikit/Button";
import { Drawer } from "@circle-react-uikit/Drawer";
import { Form } from "@circle-react-uikit/Form";
import { DefaultSort } from "./DefaultSort";

const localeScope = "members_directory.header.settings";

const viewOptions = [
  { value: VIEWS.CARDS, label: t(["members_directory.header", "views.cards"]) },
  { value: VIEWS.LIST, label: t(["members_directory.header", "views.list"]) },
] as const;

export function MemberDirectorySettingsForm({
  currentCommunitySettings,
  onClose,
}: {
  currentCommunitySettings: CommunitySettings;
  onClose: () => void;
}) {
  const toastr = useToast();

  const updateCommunitySettingMutation = useUpdateCommunitySetting({
    onSuccess: (): void => {
      toastr.success(t(`${localeScope}.save.success`));
      onClose();
    },
    onError: (): void => {
      toastr.error(t(`${localeScope}.save.error`));
    },
  });

  const onSubmit = (formData: {
    member_directory_settings: typeof currentCommunitySettings.member_directory_settings;
  }): void => {
    updateCommunitySettingMutation.mutate({
      data: { community_settings: currentCommunitySettings },
      updates: formData,
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={{
        member_directory_settings:
          currentCommunitySettings.member_directory_settings,
      }}
      className="h-full"
      formClassName="flex flex-col h-full"
    >
      <div className="grow px-6">
        <DefaultSort
          defaultValue={currentCommunitySettings.member_directory_settings.sort}
        />
        <Form.Item
          name="member_directory_settings.view"
          label={t([localeScope, "form.view"])}
          hideDescription
          hideBorder
        >
          <Form.SelectNative
            name="member_directory_settings.view"
            options={viewOptions}
            defaultValue={VIEWS.CARDS}
          />
        </Form.Item>
      </div>
      <Drawer.Footer>
        <Button
          disabled={updateCommunitySettingMutation.isLoading}
          type="submit"
          variant="primary"
          full
        >
          {t(`${localeScope}.save.button`)}
        </Button>
      </Drawer.Footer>
    </Form>
  );
}
