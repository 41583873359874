import { isNil, omit } from "lodash";
import { usePageQuery } from "@circle-react/components/SettingsApp/ManageMembers/TabContent/usePageQuery";
import {
  DEFAULT_PAGE_SIZE,
  useAccessGroupsInfiniteQuery,
} from "@circle-react/hooks/accessGroups/useAccessGroupsInfiniteQuery";
import { useFilterUrl } from "@circle-react-shared/Filter";
import { TABS } from "../constants";

export const useAccessGroupsStatusInfiniteQuery = (status = TABS.ALL) => {
  const { queryParams } = useFilterUrl();
  const { setPage, initialPage } = usePageQuery();

  const userFilters = omit(queryParams, ["page", "status"]);
  const finalFilters = isNil(status)
    ? userFilters
    : {
        status,
        ...userFilters,
      };

  const accessGroupsInfiniteQuery = useAccessGroupsInfiniteQuery({
    perPage: DEFAULT_PAGE_SIZE,
    initialPage,
    options: {
      keepPreviousData: false,
    },
    filters: finalFilters,
  });

  return {
    accessGroupsInfiniteQuery,
    initialPage,
    setPage,
  };
};
