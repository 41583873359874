import { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { sortBy } from "lodash";
import { useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { SpaceAccessManagement as Panel } from "@circle-react/components/Spaces/SpaceAccessManagement";
import type { AccessGroupContentParamsType } from "@circle-react/types/AccessGroup";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import { useAccessGroupContentManagement } from "./hooks/useAccessGroupContentManagement";

interface AccessGroupContentManagementProps {
  defaultValues: AccessGroupContentParamsType;
}

export const AccessGroupContentManagement = ({
  defaultValues,
}: AccessGroupContentManagementProps) => {
  const { options } = useAccessGroupContentManagement();
  const formState = useWatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  useEffect(() => {
    const shouldDisableButton =
      isEqual(sortBy(formState.space_ids), sortBy(defaultValues.space_ids)) &&
      isEqual(
        sortBy(formState.space_group_ids),
        sortBy(defaultValues.space_group_ids),
      );
    setIsButtonDisabled(shouldDisableButton);
  }, [formState, defaultValues]);

  return (
    <FullScreenModal.Body className="h-full overflow-y-auto">
      <div className="mb-4 mt-14 h-full overflow-y-auto px-8 pb-20">
        <Typography.TitleMd>
          {t("settings.access_groups.admin.modal.access.title")}
        </Typography.TitleMd>

        <Typography.LabelMd color="text-light">
          {t("settings.access_groups.admin.modal.access.description")}
        </Typography.LabelMd>

        <div className="mt-5">
          <Panel options={options} className="flex-auto">
            <Panel.Header>
              <Panel.SearchBar />
            </Panel.Header>
            <Panel.Body className="overflow-y-auto">
              <Panel.ListHeader className="min-h-[3rem]">
                <Panel.ListHeaderColumnNoAccess />
                <Panel.ListHeaderColumnAccessGranted />
              </Panel.ListHeader>
              <Panel.ListContainer>
                <Panel.ListMobile />
                <Panel.ListNoAccess />
                <Panel.ListAccessGranted />
              </Panel.ListContainer>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className="bg-primary border-primary absolute bottom-0 left-0 w-full border-t">
        <div className="mx-auto max-w-screen-md px-6 py-4">
          <div className="flex w-full flex-row justify-end">
            <Button type="submit" variant="circle" disabled={isButtonDisabled}>
              {t("settings.access_groups.admin.modal.access.actions.save")}
            </Button>
          </div>
        </div>
      </div>
    </FullScreenModal.Body>
  );
};
