import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { accountSubscriptionApi } from "@/react/api/accountSubscriptionApi";
import { communityBillingPath } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useAccountSubscriptionApi = () => {
  const { success, error } = useToast();
  const history = useHistory();

  const { mutate: renewSubscription, isLoading: isRenewLoading } = useMutation(
    (planId: number) => accountSubscriptionApi.renew(planId),
    {
      onSuccess: () => {
        history.push(communityBillingPath());
        success(t("pricing_plans.subscription_updated"));
      },
      onError: () => {
        error(t("pricing_plans.errors.renew_error"));
      },
    },
  );

  const { mutate: updateSubscription, isLoading: isUpdatingSubscription } =
    useMutation((planId: string) => accountSubscriptionApi.update(planId), {
      onSuccess: () => {
        history.push(communityBillingPath());
        success(t("pricing_plans.subscription_updated"));
      },
      onError: () => {
        error(t("pricing_plans.errors.update_subscription_error"));
      },
    });

  const {
    mutate: reactivateMarketingHubSubscription,
    isLoading: isReactivatingMarketingHubSubscription,
  } = useMutation(
    () => accountSubscriptionApi.reactivateMarketingHubSubscription(),
    {
      onSuccess: () => {
        success(t("pricing_plans.subscription_updated"));
      },
      onError: () => {
        error(t("pricing_plans.errors.update_subscription_error"));
      },
    },
  );

  return {
    renewSubscription,
    isRenewLoading,
    updateSubscription,
    isUpdatingSubscription,
    reactivateMarketingHubSubscription,
    isReactivatingMarketingHubSubscription,
  };
};
