import { useMemo } from "react";
import { t } from "@/i18n-js/instance";
import { usePaywallsAdminFormModal } from "@circle-react/components/Paywalls/Admin/PaywallsAdmin/FormModal";
import { tabs } from "@circle-react/components/Paywalls/Admin/PaywallsAdmin/constants";
import { TextSelect } from "@circle-react-shared/TextSelect";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { usePaywallsEditFormContext } from "../PaywallsEditFormProvider";
import { filterPaywallPrice } from "../helpers/filterPaywallPrice";

export const PriceMobileSelector = ({ paywall, filterByPrice, index }: any) => {
  const { show: showPaywallModal } = usePaywallsAdminFormModal();
  const {
    paywallsListQuery,
    allInAppPaywallPriceOptions,
    fieldArrayMethods,

    allMobilePrices,
  } = usePaywallsEditFormContext();

  const showPaywallsAdminFormModal = () =>
    showPaywallModal({
      paywall: (paywallsListQuery?.data ?? []).find(
        (paywallElement: any) => paywallElement.id === paywall.id,
      ),
      refetchPaywalls: () => {
        paywallsListQuery.refetch();
      },
      paywallGroupsEnabled: true,
      initialTab: tabs.PRICING_TAB,
    });

  const paywallPriceOnChange = (paywallPriceId: string) => {
    const selectedPrice = allMobilePrices.find(
      (price: any) => price.id === paywallPriceId,
    );

    fieldArrayMethods.update(index, {
      ...paywall,
      mobile_paywall_price: filterPaywallPrice(selectedPrice, "mobile"),
    });
  };

  const isEmptyAndNotSelected = useMemo(
    () =>
      filterByPrice(allInAppPaywallPriceOptions).length === 0 &&
      !paywall?.mobile_paywall_price?.id,
    [allInAppPaywallPriceOptions, filterByPrice, paywall],
  );

  return (
    <>
      {isEmptyAndNotSelected ? (
        <div className="bg-tertiary flex flex-col gap-y-2 rounded p-4">
          <Typography.BodySm>
            {t(
              "paywalls.lock_screen.sidebar.paywall_edit_form.no_mobile_prices",
            )}
          </Typography.BodySm>
          <div>
            <Button
              variant="circle"
              type="button"
              onClick={showPaywallsAdminFormModal}
            >
              {t(
                "paywalls.lock_screen.sidebar.paywall_edit_form.add_mobile_price",
              )}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Typography.LabelSm weight="semibold">
            {t(
              "paywalls.lock_screen.sidebar.paywall_edit_form.fields.select_default_price",
            )}
          </Typography.LabelSm>
          <TextSelect
            options={allInAppPaywallPriceOptions}
            value={paywall?.mobile_paywall_price?.price_package_id}
            buttonClassName="flex flex-col w-full p-4"
            filterBy={filterByPrice}
            onChange={paywallPriceOnChange}
          />
        </>
      )}
    </>
  );
};
