import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { FormSidebar } from "./FormSidebar";
import { usePaywallsEditFormContext } from "./PaywallsEditFormProvider";

export const PaywallsEditForm = () => {
  const { paywallsListQuery, fieldNameRoot } = usePaywallsEditFormContext();

  const { watch, reset } = useFormContext();
  const paywalls = watch(fieldNameRoot);

  // We need to reset the form state whenever the paywall information is changed,
  // otherwise we would always show the error on the PaywallForm if the form is not valid.
  // We want to show this when it is clicked on the submit button.
  // https://github.com/circleco/circle/pull/21538/files#r1708108748
  useEffect(() => {
    reset(undefined, {
      keepDirty: true,
      keepDirtyValues: true,
      keepValues: true,
      keepDefaultValues: true,
      keepTouched: true,
    });
  }, [paywalls, reset]);

  if (paywallsListQuery.isLoading) {
    return (
      <div className="flex flex-col">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col justify-start gap-y-6">
      <FormSidebar />
    </div>
  );
};
