import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { usePunditUserContext } from "@circle-react/contexts";

export const HiddenCommunityInputs = () => {
  const { register, getValues, setValue } = useFormContext();
  const { currentCommunity, isLoading } = usePunditUserContext();
  const resourceId = getValues("trigger.resource_id");

  useEffect(() => {
    if (isLoading || !!resourceId) return;
    setValue("trigger.resource_type", "Community");
    setValue("trigger.resource_id", currentCommunity?.id ?? "");
  }, [currentCommunity, isLoading, setValue, resourceId]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="hidden">
      <input type="hidden" {...register("trigger.resource_type")} />
      <input type="hidden" {...register("trigger.resource_id")} />
    </div>
  );
};
