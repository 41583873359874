import { t } from "@/i18n-js/instance";
import { EntityResource } from "../EntityResource";
import type { EventOption } from "../types";
import { AnyEventSpecificSelector } from "./shared/AnyEventSpecificSelector";

export const rsvpToEvent: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_rsvps_to_event.title",
  ),
  value: "community_member_rsvps_to_event",
  group: t("settings.workflows.form.event_groups.events"),
  description: t(
    "settings.workflows.form.events.community_member_rsvps_to_event.description",
  ),
  formComponent: AnyEventSpecificSelector,
  viewComponent: EntityResource,
  icon: "event",
};
