import { Comment } from "@/react/components/search/SearchResultItem/Comment";
import { Event } from "@/react/components/search/SearchResultItem/Event";
import { ImagePost } from "@/react/components/search/SearchResultItem/ImagePost";
import { Lesson } from "@/react/components/search/SearchResultItem/Lesson";
import { Member } from "@/react/components/search/SearchResultItem/Member";
import { Messages } from "@/react/components/search/SearchResultItem/Messages";
import { Post } from "@/react/components/search/SearchResultItem/Post";

const SearchResultItem = Object.assign(
  {},
  {
    Comment,
    Event,
    Member,
    Post,
    Lesson,
    ImagePost,
    Messages,
  },
);

export { SearchResultItem };
