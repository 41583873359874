import { useMemo } from "react";
import { useSegments } from "@circle-workflows/hooks/useSegments";

export const useSegmentsIdMap = () => {
  const { segments } = useSegments();

  return useMemo(
    () =>
      segments
        ?.map(segment => ({
          id: segment.id,
          title: segment.title,
        }))
        .reduce((accumulator, segment) => {
          accumulator[segment.id] = segment.title;
          return accumulator;
        }, {}),
    [segments],
  );
};
