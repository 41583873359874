import { t } from "@/i18n-js/instance";
import { StandardLayoutHeaderWithPortal } from "@circle-react/components/Layout/StandardLayoutV2/StandardLayoutHeader/StandardLayoutHeaderWithPortal";
import { LeaderboardSettingsDropdown } from "@circle-react/components/Leaderboard/Dashboard/SettingsDropdown";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { useNavigationLinks } from "@circle-react-uikit/HeaderV3/NavigationBar/hooks/useNavigationLinks";

export const LeaderboardHeader = () => {
  const { leaderboardLink } = useNavigationLinks();
  const title = leaderboardLink?.label || t("leaderboard.title");

  return (
    <StandardLayoutHeaderWithPortal
      leftContent={<SpaceHeaderTitleText>{title}</SpaceHeaderTitleText>}
      rightContent={<LeaderboardSettingsDropdown />}
    />
  );
};
