import { useEffect } from "react";
import { useGoLive } from ".";
import { useLiveStreamContext } from "@circle-react/contexts/LiveStreams";
import {
  canHostRoom,
  canManageRoom,
  isBackstage,
  isConference,
} from "@circle-react/helpers/liveStreamRoomHelpers";

export const useAutoGoLiveInConference = () => {
  const goLive = useGoLive();
  const {
    data: { room, currentParticipant },
    status: { isStreamEnded, isJoining },
  } = useLiveStreamContext();
  const isBackstageRoom = isBackstage(room);
  const canHost = canHostRoom(room);
  const canManage = canManageRoom(room);
  const isRoomConference = isConference(room);

  useEffect(() => {
    if (
      currentParticipant &&
      isBackstageRoom &&
      !isJoining &&
      !isStreamEnded &&
      canHost &&
      canManage &&
      isRoomConference
    ) {
      void goLive();
    }
  }, [
    currentParticipant,
    isBackstageRoom,
    isJoining,
    isStreamEnded,
    canHost,
    canManage,
    isRoomConference,
  ]);
};
