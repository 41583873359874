import { t } from "@/i18n-js/instance";
import { LOCATION_TYPES } from "@circle-react/components/Events/helpers/constants";
import { isPublicStream } from "@circle-react/components/EventsV3/Modal/utils";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { usePunditUserContext } from "@circle-react/contexts";
import { liveStreamPaths } from "@circle-react/helpers/urlHelpers";
import type { Event } from "@circle-react/types/Event";
import { InPersonLocation } from "../InPersonLocation";
import { LiveLocation } from "./LiveLocation";
import { LocationIcon } from "./LocationIcon";

interface LocationProps {
  hasEventFinished: boolean;
  event: Event;
  shouldDisplayJoinButton: boolean;
  isLiveLimitReached: boolean;
  shouldHideLocationFromCurrentMember: boolean;
}

export const Location = ({
  hasEventFinished,
  event,
  shouldDisplayJoinButton,
  isLiveLimitReached,
  shouldHideLocationFromCurrentMember,
}: LocationProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isLoggedIn = !!currentCommunityMember;

  const { event_setting_attributes: eventData } = event || {};

  const {
    location_type: locationType,
    in_person_location: inPersonLocation,
    virtual_location_url: virtualLocationUrl,
    live_stream_slug: liveStreamSlug,
  } = eventData || {};

  const handleViewLiveStreamClick = () => {
    let url = liveStreamPaths.room({ roomSlug: liveStreamSlug });

    if (isPublicStream(event) && !isLoggedIn)
      url = liveStreamPaths.publicRoom({ roomSlug: liveStreamSlug });

    window.open(url, "_blank");
  };

  if (shouldHideLocationFromCurrentMember) {
    return null;
  }

  const shouldHideOnlineLink =
    !isLoggedIn || shouldDisplayJoinButton || hasEventFinished;

  const liveLocationTypes: string[] = [
    LOCATION_TYPES.LIVE_STREAM,
    LOCATION_TYPES.LIVE_ROOM,
  ];

  return (
    <div className="pt-4" data-testid="event-location">
      {locationType === "virtual" && virtualLocationUrl && (
        <div className="flex flex-row items-center space-x-4">
          <LocationIcon icon="20-camera" />
          {shouldHideOnlineLink ? (
            <Typography.LabelSm>
              {t("events.locations.virtual")}
            </Typography.LabelSm>
          ) : (
            <a href={virtualLocationUrl} target="_blank" rel="noreferrer">
              <Typography.LabelSm color="text-link" weight="semibold">
                {t("events.locations.join_virtual_event")}
              </Typography.LabelSm>
            </a>
          )}
        </div>
      )}
      {locationType === "in_person" && (
        <div className="flex flex-row items-start space-x-4">
          <LocationIcon icon="20-location" />
          <InPersonLocation
            serializedLocation={inPersonLocation}
            variant="text"
          />
        </div>
      )}
      {locationType === "tbd" && (
        <div className="flex flex-row items-center space-x-4">
          <LocationIcon icon="20-location" />
          <Typography.LabelSm>{t("events.rsvp.tbd")}</Typography.LabelSm>
        </div>
      )}
      {liveLocationTypes.includes(locationType) && (
        <div className="flex flex-row items-center space-x-4">
          <LocationIcon icon="20-camera" />
          <LiveLocation
            event={event}
            shouldDisplayJoinButton={shouldDisplayJoinButton}
            isLiveLimitReached={isLiveLimitReached}
            handleViewLiveStreamClick={handleViewLiveStreamClick}
            hasEventFinished={hasEventFinished}
            isLoggedIn={isLoggedIn}
          />
        </div>
      )}
    </div>
  );
};
