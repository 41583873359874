import { useSettingsHeader } from "@circle-react-shared/uikit/HeaderV3/useSettingsHeader";
import { useHeightStyles } from "./useHeightStyles";

export const useTopBarClassNames = (
  isPromoCodeEnabledAndHasSubscriptionExpired: boolean,
  isMasqueradingEnabledAndActive: boolean,
): string => {
  const { isReactFlowWorkflowsRoute } = useSettingsHeader();
  const { topBarStyles, topBarStylesWithMasquerade } = useHeightStyles();

  if (isReactFlowWorkflowsRoute) {
    return "";
  }

  if (
    (!isPromoCodeEnabledAndHasSubscriptionExpired &&
      !isMasqueradingEnabledAndActive) ||
    (isPromoCodeEnabledAndHasSubscriptionExpired &&
      isMasqueradingEnabledAndActive)
  ) {
    return topBarStyles;
  }

  if (
    !isPromoCodeEnabledAndHasSubscriptionExpired &&
    isMasqueradingEnabledAndActive
  ) {
    return topBarStylesWithMasquerade;
  }

  return "";
};
