import { useBoolean } from "react-use";
import { t } from "@/i18n-js/instance";
import { useBroadcastData } from "@circle-react-shared/Emails/BroadcastDataContext";
import { Button } from "@circle-react-shared/uikit/Button";
import { PreviewModal } from "./PreviewModal";
import { SmallInlinePreview } from "./SmallInlinePreview";
import ViewEmailExcerpt from "./ViewEmailExcerpt";

const localeNamespace = "settings.workflows.view";

interface ViewEmailDetailsProps {
  hideViewEmailModal?: boolean;
}

export function ViewEmailDetails({
  hideViewEmailModal = false,
}: ViewEmailDetailsProps) {
  const { data } = useBroadcastData();
  const [isPreviewModalOpen, toggleIsPreviewModalOpen] = useBoolean(false);

  return (
    <div className="flex justify-between gap-x-2">
      <ViewEmailExcerpt
        inlinePreviewComponent={<SmallInlinePreview />}
        internalName={data?.internal_name}
        subject={data?.broadcast_content?.subject}
      />
      {!hideViewEmailModal && (
        <>
          <div>
            <Button
              variant="secondary-light"
              type="button"
              onClick={toggleIsPreviewModalOpen}
            >
              {t([localeNamespace, "view_email"])}
            </Button>
          </div>
          <PreviewModal
            isOpen={isPreviewModalOpen}
            onClose={toggleIsPreviewModalOpen}
          />
        </>
      )}
    </div>
  );
}
