import { t } from "@/i18n-js/instance";
import { Avatar } from "./Avatar";

export const AvatarImage = ({
  avatarUrl,
  response,
}: {
  avatarUrl: string;
  response: any;
}) =>
  avatarUrl || response?.blob_url ? (
    <img
      loading="eager"
      src={response?.blob_url || avatarUrl}
      alt={t("community_bot.bot_builder.customize_page.avatar")}
      className="h-16 w-16 rounded-full"
    />
  ) : (
    <Avatar />
  );
