import { t } from "@/i18n-js/instance";
import { useActionNodeUpdateParams } from "@circle-react-shared/WorkflowsV2/Canvas/workflowStore";
import { sendEmail } from "../Actions/Member";
import { ContinueButton } from "../WorkflowForm/ContinueButton";
import { useResourceStatus } from "../WorkflowForm/useResourceStatus";
import { useCurrentAction } from "./ActionContext";
import { useActions } from "./useActions";
import { useSendEmailActionContinue } from "./useSendEmailActionContinue";

interface ActionContinueButtonProps {
  onSubmit?: () => void;
  onSuccess?: () => void;
}

export const ActionContinueButton = ({
  onSubmit,
  onSuccess,
}: ActionContinueButtonProps) => {
  const { setActionNodeUpdateParams } = useActionNodeUpdateParams();
  const { saveActions, isSavingActions } = useActions();
  const { action, path } = useCurrentAction();
  const { value, revalidate, hasValue } = useResourceStatus(path);

  const { handleEmailActionContinue, isLoadingMarketingSettings } =
    useSendEmailActionContinue(action);
  const actionType = value.action_type;

  let buttonMessage;

  if (isSavingActions) {
    buttonMessage = t("settings.workflows.form.saving");
  } else {
    buttonMessage = t("settings.workflows.form.continue");
  }

  const isSendEmailAction = actionType === sendEmail.value;

  const isContinueButtonDisabled =
    isSavingActions ||
    !hasValue ||
    (isSendEmailAction && isLoadingMarketingSettings);

  const handleContinue = async () => {
    const isValid = await revalidate();
    if (!isValid) return;

    setActionNodeUpdateParams({
      nodeId: value.id,
      isUpdating: true,
    });

    const continueAction = async () => {
      await saveActions(onSuccess);
      onSubmit?.();
    };

    if (isSendEmailAction) {
      await handleEmailActionContinue({ continueAction });
    } else {
      await continueAction();
    }

    setActionNodeUpdateParams({
      nodeId: "",
      isUpdating: false,
    });
  };

  return (
    <ContinueButton
      disabled={isContinueButtonDisabled}
      onClick={handleContinue}
    >
      {buttonMessage}
    </ContinueButton>
  );
};

export default ActionContinueButton;
