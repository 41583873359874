import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useSpaceAccessManagementContext } from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import { i18nRoot } from "../../constants";
import { defaultAction } from "../../constants";
import { ListHeaderColumn } from "./ListHeaderColumn";

interface ListHeaderColumnAccessGrantedProp {
  title?: string;
  onRemoveAll?: (action: () => void) => void;
}

export const ListHeaderColumnAccessGranted = ({
  title = t([i18nRoot, "access"]),
  onRemoveAll = action => {
    action();
  },
}: ListHeaderColumnAccessGrantedProp) => {
  const {
    parentFieldName,
    childFieldName,
    areAllSpacesAndSpaceGroupsRemovable,
  } = useSpaceAccessManagementContext();
  const { setValue } = useFormContext();

  const removeAllSpacesAndSpaceGroups = () => {
    setValue(parentFieldName, []);
    setValue(childFieldName, []);
  };
  const onClick = () => {
    const method = onRemoveAll || defaultAction;
    method(removeAllSpacesAndSpaceGroups);
  };

  const areAllRemovable = areAllSpacesAndSpaceGroupsRemovable();
  return (
    <ListHeaderColumn
      title={title}
      actionText={t([i18nRoot, "remove_all"])}
      shouldShowButton={areAllRemovable}
      onClick={areAllRemovable ? onClick : undefined}
    />
  );
};
