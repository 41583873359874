import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { EmailFilter } from "./EmailFilter";

const localeRoot =
  "settings.paywalls_admin_dashboard.filters.community_member_email";

export const filterProperties = {
  name: "community_member_email",
  filterKeys: ["email"],
  component: (prop: any) => <EmailFilter {...prop} onApply={noop} />,
  label: t([localeRoot, "title"]),
};
