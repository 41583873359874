import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { formId } from "../constants";

export const ModalFooter = ({ isDisabled, onClose }: any) => (
  <Modal.Footer>
    <div className="flex items-stretch justify-end space-x-4">
      <Button
        type="button"
        variant="secondary"
        onClick={onClose}
        disabled={isDisabled}
      >
        {t("cancel")}
      </Button>
      <Button
        form={formId}
        type="submit"
        variant="circle"
        disabled={isDisabled}
      >
        {t("continue")}
      </Button>
    </div>
  </Modal.Footer>
);
