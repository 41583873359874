import { t } from "@/i18n-js/instance";
import { ConfirmBulkActionBody } from "./ConfirmBulkActionBody";
import { ConfirmDynamicBody } from "./ConfirmDynamicBody";
import { ConfirmRerunBody } from "./ConfirmRerunBody";

export interface ConfirmBodyProps {
  isBulkAction?: boolean;
  isScheduled?: boolean;
  isRerun?: boolean;
  rerunParams?: any;
}

export const confirmBodyProps = ({
  isBulkAction,
  isScheduled,
  isRerun,
  rerunParams,
}: ConfirmBodyProps) => {
  if (isBulkAction && isRerun) {
    return {
      title: t("settings.workflows.confirm_modal.rerun_summary"),
      body: <ConfirmRerunBody rerunParams={rerunParams} />,
      successButtonLabel: t(
        "settings.workflows.upgrade_modal.rerun_this_workflow",
      ),
    };
  }

  if (isBulkAction) {
    return {
      title: t("settings.workflows.confirm_modal.run_this_workflow"),
      body: <ConfirmBulkActionBody />,
      successButtonLabel: t(
        "settings.workflows.upgrade_modal.run_this_workflow",
      ),
    };
  }

  if (isScheduled) {
    return {
      title: t("settings.workflows.confirm_modal.schedule_this_workflow"),
      body: <ConfirmBulkActionBody />,
      successButtonLabel: t(
        "settings.workflows.upgrade_modal.schedule_this_workflow",
      ),
    };
  }

  return {
    title: t("settings.workflows.confirm_modal.publish_your_workflow"),
    body: <ConfirmDynamicBody />,
    successButtonLabel: t(
      "settings.workflows.upgrade_modal.activate_this_workflow",
    ),
  };
};
