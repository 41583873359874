import type { DoNotSkipTasks } from "@circle-workflows/hooks/useRerunWorkflow";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Fieldset } from "@circle-react-uikit/Fieldset";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Radio } from "@circle-react-uikit/Radio";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.workflows.rerun_modal.form";

interface RerunFormOption {
  value: DoNotSkipTasks;
  label: string;
  description: string;
}

const options: RerunFormOption[] = [
  {
    value: "true",
    label: t([i18nRoot, "include_all_members.title"]),
    description: t([i18nRoot, "include_all_members.description"]),
  },
  {
    value: "false",
    label: t([i18nRoot, "only_new_members.title"]),
    description: t([i18nRoot, "only_new_members.description"]),
  },
];

export const RerunBody = () => {
  const { register } = useFormContext();

  return (
    <Modal.Body>
      <Typography.LabelMd weight="semibold">
        {t([i18nRoot, "title"])}
      </Typography.LabelMd>
      <div className="flex flex-col pt-5">
        <Fieldset>
          {options.map(({ value, label, description }) => (
            <Radio
              key={value}
              id={`rerunbody-radio-${value}`}
              label={label}
              description={description}
              value={value}
              {...register("do_not_skip_tasks")}
            />
          ))}
        </Fieldset>
      </div>
    </Modal.Body>
  );
};
