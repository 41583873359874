import { t } from "@/i18n-js/instance";
import type {
  ChatSortType,
  ChatType,
} from "@/react/types/CommunityBot/ChatSort";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface DropdownMenuProps<T extends ChatSortType | ChatType> {
  className?: string;
  activeItem: T;
  localeScope: string;
  onItemClick: (item: T) => void;
  items: T[];
  button?: React.ReactNode;
  direction?: "bottom-start" | "bottom-end";
}

export const DropdownMenu = <T extends ChatSortType | ChatType>({
  className,
  activeItem,
  localeScope,
  onItemClick,
  items,
  button,
  direction = "bottom-start",
}: DropdownMenuProps<T>) => (
  <Dropdown
    direction={direction}
    className={className}
    buttonClassName="w-full"
    menuItemsClassName="!w-40"
    button={
      button ?? (
        <Dropdown.MenuButton className="hover:bg-secondary-dark border-primary flex justify-between rounded-lg border bg-transparent !py-1.5 pl-3 pr-2 transition-colors">
          <div className="flex gap-x-2">
            <Typography.LabelSm weight="medium">
              {t([localeScope, activeItem])}
            </Typography.LabelSm>
          </div>
          <Icon
            size={16}
            type="16-chevron-triangle-down-small"
            className="text-dark"
          />
        </Dropdown.MenuButton>
      )
    }
  >
    {items.map((item: T) => (
      <Dropdown.Item key={item} onClick={() => onItemClick(item)}>
        <Dropdown.ItemLink
          type="button"
          active={activeItem === item}
          aria-label={t([localeScope, item])}
        >
          <div className="flex items-center gap-x-2">
            <span>{t([localeScope, item])}</span>
          </div>
        </Dropdown.ItemLink>
      </Dropdown.Item>
    ))}
  </Dropdown>
);
