import { useMemo } from "react";
import type { ReactNode } from "react";
import { compact } from "lodash";
import { t } from "@/i18n-js/instance";
import { getPageSize } from "@circle-react/hooks/useCommunityMembersInfiniteQueryV2";
import { useIsActivityScoreEnabledOrUpgradable } from "@circle-react/hooks/useIsActivityScoreEnabled";
import { ScoreContextProvider } from "@circle-react-shared/ActivityScore";
import { InfiniteQueryDataTable } from "@circle-react-shared/InfiniteQueryDataTable";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { useAccessGroupData } from "../DataStoreContext/AccessGroupDataContext";
import { MemberIdCheckBoxCell } from "./Cells/MemberIdCheckBoxCell";
import { NameCell } from "./Cells/NameCell";
import { ScoreCell } from "./Cells/ScoreCell";
import { formatDate } from "./Cells/functions";
import { getRole } from "./Cells/functions";
import { MembersFilters } from "./MembersFilters";

interface TableColumn {
  accessorKey: string;
  header: string;
  id?: string;
  accessorFn?: (row: any) => any;
  cell?: (info: any) => JSX.Element | string;
  canSort?: boolean;
  cellClassName?: string;
}
const getTableColumns = (
  isMemberActivityScoreEnabledOrUpgradable: boolean,
  accessGroupId: number,
): TableColumn[] =>
  compact([
    {
      accessorKey: "memberIdCheckbox",
      header: "",
      accessorFn: row => row,
      cell: info => (
        <MemberIdCheckBoxCell
          accessGroupId={accessGroupId}
          communityMember={info.getValue()}
        />
      ),
      cellClassName: "!align-middle",
    },
    {
      accessorKey: "name",
      header: t("settings.manage_members.name"),
      accessorFn: row => row,
      cell: info => <NameCell member={info.getValue()} />,
    },
    isMemberActivityScoreEnabledOrUpgradable && {
      accessorKey: "activity_score",
      header: t("settings.manage_members.activity_score"),
      accessorFn: row => row.id,
      cell: info => <ScoreCell shortDisabledLabel memberId={info.getValue()} />,
      cellClassName: "!align-middle",
    },
    {
      accessorKey: "roles",
      header: t("settings.manage_members.role"),
      cell: info => getRole(info.getValue()),
      canSort: false,
      cellClassName: "!align-middle",
    },
    {
      accessorKey: "profile_confirmed_at",
      header: t("settings.manage_members.joined"),
      cell: info => formatDate(info.getValue()),
      cellClassName: "!align-middle",
    },
  ]);

interface AccessGroupMembersTablePropsInterface {
  onSort: (sort: any) => void;
  infiniteQuery: any;
  setPage: (page: any) => void;
  order: Record<string, "asc" | "desc">;
  initialPage: number;
  children: ReactNode;
}

export const AccessGroupMembersTable = ({
  onSort,
  setPage,
  initialPage,
  infiniteQuery,
  order,
  children,
}: AccessGroupMembersTablePropsInterface) => {
  const isMemberActivityScoreEnabledOrUpgradable =
    useIsActivityScoreEnabledOrUpgradable();

  const reactTableInitialSort = useMemo(() => {
    if (!order) return null;
    return Object.entries(order).map(([key, value]) => ({
      id: key,
      desc: value === "desc",
    }));
  }, [order]);

  const { accessGroup } = useAccessGroupData();

  const columns: TableColumn[] = useMemo(
    () =>
      getTableColumns(isMemberActivityScoreEnabledOrUpgradable, accessGroup.id),
    [isMemberActivityScoreEnabledOrUpgradable, accessGroup.id],
  );
  const communityMemberIds = useMemo(
    () =>
      infiniteQuery.data?.pages
        ?.flatMap((page: any) => page.records)
        .map((record: any) => record.id) || [],
    [infiniteQuery],
  );

  return (
    <ScoreContextProvider ids={communityMemberIds}>
      <MembersFilters />
      <InfiniteQueryDataTable
        columns={columns}
        infiniteQuery={infiniteQuery}
        initialSort={reactTableInitialSort}
        onChangeSorting={onSort}
        onChangePage={(newPage: any) => {
          setPage(newPage);
        }}
        initialPage={initialPage}
        pageSize={getPageSize()}
      >
        <div className="flex h-full flex-col">
          {children}
          <div
            className="max-w-full overflow-x-auto px-6 lg:px-9"
            data-testid="access-group-members-table"
          >
            <DataTable.Table className="w-full min-w-[800px]">
              <colgroup>
                <col className="w-4" />
                <col />
                {isMemberActivityScoreEnabledOrUpgradable && (
                  <col className="min-w-[2rem]" />
                )}
                <col className="w-3/12" />
                <col className="min-w-[4rem]" />
              </colgroup>
              <DataTable.SortableHead />
              <DataTable.Loader />
              <DataTable.NoRecords
                text={t("settings.manage_members.no_members_found")}
              />
              <DataTable.Body />
            </DataTable.Table>
            <DataTable.Pagination align="left" direction="reverse" />
          </div>
        </div>
      </InfiniteQueryDataTable>
    </ScoreContextProvider>
  );
};
