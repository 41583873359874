import { getAdminAudienceEditPaths } from "@circle-react/helpers/urlHelpers";
import { AdminMemberEditPage } from "@circle-react/pages/AdminAudience/AdminMemberEditPage";
import { ProtectedRoute } from "@circle-react-shared/ProtectedRoute";

const paths = getAdminAudienceEditPaths({
  type: "CommunityMember",
});

const profileRoutes = [
  paths.index,
  paths.info,
  paths.access,
  paths.billing,
  paths.activityLogs,
] as const;

/**
 * @deprecated
 * Please use the AdminAudienceEditRoutes component instead.
 * This component will be removed in the future.
 */
export const AdminMemberEditRoutes = () => (
  <ProtectedRoute component={AdminMemberEditPage} path={profileRoutes} exact />
);
