import { React } from "react";
import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Button } from "@circle-react-shared/uikit/Button";

export const TYPE_AI_CHAT = "type_ai_chat";
export const TYPE_LIVE_CHAT = "type_live_chat";

export const ChatEnded = ({ onClick, type }) => (
  <div className="bg-tertiary flex flex-col gap-6 px-4 py-6">
    <div className="flex flex-col items-center">
      <Typography.BodySm>
        {t("support_widget.chatbot.live_chat_ended")}
      </Typography.BodySm>
      {type == TYPE_LIVE_CHAT ? (
        <Typography.BodySm weight="font-bold">
          {t("support_widget.chatbot.live_chat_follow_up")}
        </Typography.BodySm>
      ) : null}
    </div>
    <Button variant="circle" large onClick={onClick}>
      {t("support_widget.chatbot.live_chat_back_home")}
    </Button>
  </div>
);
