/* eslint-disable no-restricted-syntax -- text and badge variant mapping */
import { t } from "@/i18n-js/instance";
import type {
  WorkflowResultValue,
  WorkflowStatusValue,
} from "@circle-react/components/SettingsApp/Workflows/constants";
import { WORKFLOW_STATUS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { WORKFLOW_RESULT } from "@circle-react/components/SettingsApp/Workflows/constants";
import { StatusBadge } from "@circle-react-shared/uikit/StatusBadge";
import { StatusText } from "@circle-react-shared/uikit/StatusText";

const CAPITALIZE = "capitalize";

type BadgeVariant = "success" | "warning" | "danger" | "info" | "default";
type TextVariant = "success" | "warning" | "danger" | "default";

const RESULT_VARIANT_MAP: Record<
  WorkflowResultValue | WorkflowStatusValue,
  { text: TextVariant; badge: BadgeVariant }
> = {
  [WORKFLOW_RESULT.SUCCESS]: { text: "success", badge: "success" },
  [WORKFLOW_RESULT.PARTIAL_SUCCESS]: { text: "warning", badge: "warning" },
  [WORKFLOW_RESULT.FAILED]: { text: "danger", badge: "danger" },
  [WORKFLOW_RESULT.CANCELLED]: { text: "danger", badge: "danger" },
  [WORKFLOW_RESULT.IN_PROGRESS]: { text: "default", badge: "info" },
  [WORKFLOW_RESULT.SKIPPED]: { text: "default", badge: "info" },
  [WORKFLOW_RESULT.DUPLICATE_SKIPPED]: { text: "default", badge: "info" },
  [WORKFLOW_STATUS.ACTIVE]: { text: "success", badge: "success" },
  [WORKFLOW_STATUS.INACTIVE]: { text: "default", badge: "default" },
  [WORKFLOW_STATUS.DRAFT]: { text: "default", badge: "default" },
  [WORKFLOW_STATUS.ARCHIVED]: { text: "default", badge: "default" },
  [WORKFLOW_STATUS.ALL]: { text: "default", badge: "default" },
  [WORKFLOW_STATUS.PUBLISHED]: { text: "success", badge: "success" },
};

interface ResultProps {
  result: WorkflowResultValue | WorkflowStatusValue;
  isBadge?: boolean;
}

const isWorkflowResult = (
  value: WorkflowResultValue | WorkflowStatusValue,
): value is WorkflowResultValue => value in WORKFLOW_RESULT;

export const Result = ({
  result,
  isBadge = true,
}: ResultProps): JSX.Element => {
  const isWorkflowProcessed = isWorkflowResult(result);
  const resultVariant = RESULT_VARIANT_MAP[result] ?? {
    text: "default",
    badge: "default",
  };

  const variant = isBadge ? resultVariant.badge : resultVariant.text;
  const Component = isBadge ? StatusBadge : StatusText;

  return (
    <Component
      variant={variant}
      {...(!isWorkflowProcessed && {
        textTransform: CAPITALIZE,
      })}
    >
      {t(["settings.workflows.table.result", result], {
        defaultValue: result || "─",
      })}
    </Component>
  );
};
