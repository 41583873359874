import { t } from "@/i18n-js/instance";
import { Loader } from "@circle-react-uikit/Loader";
import { ChangePlanFormChooser } from "./ChangePlanFormChooser";
import { SelectedPlanDetails } from "./SelectedPlanDetails";
import { useCurrentAndSelectedPlans } from "./hooks/useCurrentAndSelectedPlans";

export const PriceChooserStep = () => {
  const { isLoadingSelectedPaywall, selectedPaywallPricingOptions } =
    useCurrentAndSelectedPlans();

  if (isLoadingSelectedPaywall) {
    return <Loader />;
  }

  return (
    <>
      <SelectedPlanDetails />
      <ChangePlanFormChooser
        name="new_paywall_price_id"
        warningMessage={t(
          "member_profile_modal.billing.subscriptions.alerts.errors.no_cycle_chosen",
        )}
        options={selectedPaywallPricingOptions}
      />
    </>
  );
};
