import type { SetStateAction } from "react";
import { useState } from "react";
import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { mobileCellStyles } from "@circle-react-shared/uikit/TableV2/constants";
import { DataTable } from "@circle-react-uikit/DataTable";
import { Actions } from "./Actions";
import { DetailsModal } from "./DetailsModal";
import { LiveStreamStatus } from "./LiveStreamStatus";
import "./styles.scss";

export interface LiveStreamsTable {
  records: any[];
  refetch: () => void;
  className?: string;
}

export const LiveStreamsTable = ({
  records,
  refetch,
  className,
}: LiveStreamsTable) => {
  const [currentRoom, setCurrentRoom] = useState<object | null>(null);
  const columns = [
    {
      title: t("live_streams.dashboard.table.name"),
      dataIndex: "name",
      key: "name",
      variant: "uppercase",
    },
    {
      title: t("live_streams.dashboard.table.status"),
      dataIndex: "status",
      key: "status",
      variant: "uppercase",
      className: mobileCellStyles.sm,
      tdClassName: mobileCellStyles.sm,
    },
    {
      title: t("live_streams.dashboard.table.date"),
      dataIndex: "live_at",
      key: "live_at",
      variant: "uppercase",
      className: mobileCellStyles.xl,
      tdClassName: mobileCellStyles.xl,
    },
    {
      title: t("live_streams.dashboard.table.recording"),
      dataIndex: "recording",
      key: "recording",
      variant: "uppercase",
    },
    {
      title: t("live_streams.dashboard.table.participants"),
      dataIndex: "total_participants",
      key: "total_participants",
      variant: "uppercase",
      className: mobileCellStyles.md,
      tdClassName: mobileCellStyles.md,
    },
    {
      title: t("live_streams.dashboard.table.type"),
      dataIndex: "type",
      key: "type",
      variant: "uppercase",
      className: mobileCellStyles.xl,
      tdClassName: mobileCellStyles.xl,
    },
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
      variant: "actions",
    },
  ];

  const handleOpenModal = (room: SetStateAction<object | null>) => {
    setCurrentRoom(room);
  };

  const handleCloseModal = () => {
    setCurrentRoom(null);
  };

  const transformedRecords =
    records?.map(record => ({
      ...record,
      key: record.id,
      name: (
        <button
          type="button"
          className="max-w-[240px] cursor-pointer truncate"
          title={record.name}
          onClick={() => handleOpenModal(record)}
        >
          {record.name}
        </button>
      ),
      status: <LiveStreamStatus status={record.status} />,
      live_at: formatDateString(record.live_at, {
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
      recording: (
        <a
          className={classnames(
            "text-dark inline max-w-sm truncate hover:text-black",
            {
              "cursor-text": !record.recording_url,
            },
          )}
          title={t("live_streams.dashboard.table.actions.download")}
          href={record.recording_url}
          download
          target="_blank"
          rel="noreferrer"
        >
          {record.recording_url
            ? t("live_streams.dashboard.table.actions.download")
            : "-"}
        </a>
      ),
      type:
        record.room_type == "conference"
          ? t("live_streams.dashboard.table.room")
          : t("live_streams.dashboard.table.stream"),
      actions: (
        <Actions
          room={record}
          refetch={refetch}
          setCurrentRoom={setCurrentRoom}
        />
      ),
      rowClassName: "group",
    })) || [];

  return (
    <>
      <DetailsModal
        isOpen={!!currentRoom}
        onClose={handleCloseModal}
        room={currentRoom}
        refetch={refetch}
      />
      <DataTable
        columns={columns}
        dataSource={transformedRecords}
        className={className}
      />
    </>
  );
};
