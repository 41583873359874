import { useLocation } from "react-router-dom";
import { useIndexAgents } from "@/react/components/SettingsApp/CommunityBot/agentsQueries";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { useIsCommunityBotMessagingEnabled } from "@circle-react/hooks/useIsMessagingEnabled";

export const useDisableCommunityInboxLeftSidebar = () => {
  const { pathname } = useLocation();
  const { data: agentsQuery } = useIndexAgents({ currentPage: 1 });
  const shouldHideSidebar = !agentsQuery?.count;
  const isBotMessagingEnabled = useIsCommunityBotMessagingEnabled();
  const isCommunityInboxPage = pathname.includes(
    settingsNavPathV3.community_inbox.index,
  );

  return isBotMessagingEnabled && isCommunityInboxPage && shouldHideSidebar;
};
