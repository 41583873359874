import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import I18n from "@/i18n-js/instance";
import { communityPlanAddonApi } from "@/react/api/communityPlanAddonApi";
import { useCommunityPlanAddonApi } from "./hooks/useCommunityPlanAddonApi";
import { calculateAddOnPrice } from "./utils";

const AddOnsSettingsContext = createContext();
AddOnsSettingsContext.displayName = "AddOnsSettingsContext";

export const AddOnsSettingsContextProvider = ({
  onSuccess,
  onCloseModal,
  children,
  refetchCurrentPlan,
}) => {
  const [total, setTotal] = useState();
  const [addOnsData, setAddOnsData] = useState([]);
  const [defaultValues, setDefaultValues] = useState();
  const [addOnsTotalPrices, setAddOnsTotalPrices] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [serverErrors, setServerErrors] = useState();
  const [grandfatheredAddonNames, setGrandfatheredAddonNames] = useState();

  const { handleAddonsMutation } = useCommunityPlanAddonApi({
    handleOnSuccess: onSuccess,
    setServerErrors: setServerErrors,
    onCloseModal: onCloseModal,
    refetchCurrentPlan: refetchCurrentPlan,
  });

  const updateTotalPrice = (name, price) => {
    const state = { ...addOnsTotalPrices };
    state[name] = price;
    setAddOnsTotalPrices(state);
  };

  const calculateTotal = () => {
    let total = 0;
    if (!addOnsTotalPrices) return;
    Object.keys(addOnsTotalPrices).forEach(name => {
      total += addOnsTotalPrices[name];
    });
    setTotal(total);
  };

  const valueIsToggleable = value => typeof value === "boolean";

  const buildFormModel = data =>
    data.map(item => {
      item.step = item.unit_size;
      item.toggleable = valueIsToggleable(item.quantity);
      return item;
    });

  const buildPricesModel = data => {
    const obj = {};
    data.map(item => {
      obj[item.slug] = calculateAddOnPrice(
        item.quantity,
        item.base_amount,
        item.unit_size,
        valueIsToggleable(item.quantity),
      );
    });
    return obj;
  };

  const getGrandfatheredAddonNames = data =>
    data
      .filter(planAddon => planAddon.is_grandfathered)
      .map(planAddon => planAddon.name)
      .join(", ");

  const buildDefaultValues = data => {
    const values = {};
    data.map(item => {
      values[item.slug] = item.quantity;
    });
    return values;
  };

  const getAddOnBySlug = slug => addOnsData.find(item => item.slug === slug);

  const getPlanInterval = () => addOnsData[0].interval;

  const getPlanCurrency = () => addOnsData[0].price_currency;

  const getAddOnPrice = (name, quantity) => {
    const addOn = getAddOnBySlug(name);
    return calculateAddOnPrice(
      quantity,
      addOn.base_amount,
      addOn.unit_size,
      addOn.toggleable,
    );
  };

  const loadData = async () => {
    const response = await communityPlanAddonApi.index();

    if (response.ok) {
      const data = await response.json();

      setDefaultValues(buildDefaultValues(data));
      setAddOnsData(buildFormModel(data));
      setGrandfatheredAddonNames(getGrandfatheredAddonNames(data));
      setAddOnsTotalPrices(buildPricesModel(data));
      setIsLoading(false);
    } else {
      setServerErrors({ title: I18n.t("something_went_wrong") });
    }
  };

  const onSubmit = async data => {
    const params = {};
    Object.keys(data).forEach(key => {
      const addOn = getAddOnBySlug(key);
      params[addOn.id.toString()] = data[key];
    });

    handleAddonsMutation.mutate(params);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [addOnsTotalPrices]);

  const value = {
    getPlanInterval,
    getPlanCurrency,
    total,
    addOnsData,
    defaultValues,
    isLoading,
    handleAddonsMutation,
    serverErrors,
    getAddOnPrice,
    updateTotalPrice,
    onSubmit,
    grandfatheredAddonNames,
  };

  return (
    <AddOnsSettingsContext.Provider value={value}>
      {children}
    </AddOnsSettingsContext.Provider>
  );
};

export const useAddOnsSettingsContext = () => useContext(AddOnsSettingsContext);

AddOnsSettingsContextProvider.propTypes = {
  onSuccess: PropTypes.func,
  children: PropTypes.node.isRequired,
};
