import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { ListLoader } from "./ListLoader";

export interface PostListLoaderProps {
  hasPinnedPost?: any;
  space?: any;
  count: number;
  withContainer?: boolean;
  columnsCount?: number;
}

export const PostListLoader = ({
  hasPinnedPost,
  space,
  count,
  withContainer = false,
  columnsCount,
}: PostListLoaderProps) => {
  const isPinned = Boolean(hasPinnedPost);

  return (
    <>
      {isPinned && <SkeletonLoader variant="feed-view" count={1} />}
      <ListLoader
        space={space}
        count={count}
        withContainer={withContainer}
        columnsCount={columnsCount}
      />
    </>
  );
};
