import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSpaceSlugFromUrl } from "@/react/hooks/useSpaceSlugFromUrl";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";

const workflowSourceKey = "workflowSource";

export const useSetWorkflowSource = () => {
  const location = useLocation();
  const spaceSlug = useSpaceSlugFromUrl();

  useMemo(() => {
    let workflowSource;

    if (location.pathname.includes(settingsNavPathV3.paywalls.index)) {
      workflowSource = UPGRADE_FLOW_SOURCES.PAYWALLS_WORKFLOWS_MODAL;
    } else if (
      location.pathname.includes(settingsNavPathV3.members.onboarding)
    ) {
      workflowSource = UPGRADE_FLOW_SOURCES.ONBOARDING_WORKFLOWS_MODAL;
    } else if (spaceSlug) {
      workflowSource = UPGRADE_FLOW_SOURCES.SPACE_WORKFLOWS_MODAL;
    }

    if (workflowSource) {
      try {
        sessionStorage.setItem(workflowSourceKey, workflowSource);
      } catch (error) {
        console.error("Failed to set workflow source in sessionStorage", error);
      }
    }
  }, [location, spaceSlug]);
};

export const getWorkflowSource = () => {
  try {
    return (
      sessionStorage.getItem(workflowSourceKey) ||
      UPGRADE_FLOW_SOURCES.WORKFLOWS_UPGRADE_MODAL
    );
  } catch (error) {
    console.error("Failed to get workflow source from sessionStorage", error);
    return UPGRADE_FLOW_SOURCES.WORKFLOWS_UPGRADE_MODAL;
  }
};

export const useOpenedFromPage = () => {
  const workflowSource = getWorkflowSource();

  const shouldGoBack =
    workflowSource !== UPGRADE_FLOW_SOURCES.WORKFLOWS_UPGRADE_MODAL;

  return { shouldGoBack };
};
