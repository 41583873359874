import type { Peer } from "@/react/types/Live/Peer";
import { useHMSSpeakerRotation } from "../hms";
import { useLivekitSpeakerRotation } from "../livekit";
import { useProviderHook } from "./useProviderHook";

export const useProviderSpeakerRotation = (
  peers: Peer[],
  { pageSize }: { pageSize: number },
) =>
  useProviderHook({
    hms: useHMSSpeakerRotation,
    livekit: useLivekitSpeakerRotation,
  })(peers, { pageSize });
