import { createContext, useContext, useMemo, useState } from "react";
import { noop } from "lodash";
import type { PreviewMode } from "@circle-react/components/Spaces/CourseSpace/Lockscreen/Providers/FormBlocksProvider";
import { useIsInAppEnabled } from "@circle-react/hooks/useIsInAppEnabled";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";
import { useFormBlocks } from "./FormBlocksProvider";
import { isMobilePreviewMode } from "./helpers";

interface PaywallBlockContextProp {
  previewMode: PreviewMode;
  setPreviewMode: (param: PreviewMode) => void;

  isPreviewWindowOpen: boolean;
  setPreviewWindowOpen: (param: boolean) => void;

  isInAppEnabled: boolean;
  isCommunityOnPlus: boolean;
  shouldShowBrandedAppBanner: boolean;
}

const PaywallBlockContext = createContext<PaywallBlockContextProp>({
  previewMode: "desktop",
  setPreviewMode: noop,

  isPreviewWindowOpen: false,
  setPreviewWindowOpen: noop,

  isInAppEnabled: false,
  isCommunityOnPlus: false,
  shouldShowBrandedAppBanner: true,
});

PaywallBlockContext.displayName = "PaywallBlockContext";

export const usePaywallBlockContext = () => useContext(PaywallBlockContext);

export const PaywallBlockProvider = ({ children }: any) => {
  const { isCommunityOnPlus } = usePlanStatus();
  const { previewMode, setPreviewMode } = useFormBlocks();
  const [isPreviewWindowOpen, setPreviewWindowOpen] = useState(false);

  const isInAppEnabled = useIsInAppEnabled();

  const shouldShowBrandedAppBanner = useMemo(
    () =>
      (isMobilePreviewMode(previewMode) &&
        isCommunityOnPlus &&
        !isInAppEnabled) ||
      (isMobilePreviewMode(previewMode) && !isCommunityOnPlus),
    [isMobilePreviewMode, previewMode, isCommunityOnPlus, isInAppEnabled],
  );

  return (
    <PaywallBlockContext.Provider
      value={{
        previewMode,
        setPreviewMode,

        isPreviewWindowOpen,
        setPreviewWindowOpen,

        isInAppEnabled,
        isCommunityOnPlus,
        shouldShowBrandedAppBanner,
      }}
    >
      {children}
    </PaywallBlockContext.Provider>
  );
};
