import type { FocusEvent, MouseEvent } from "react";
import { t } from "@/i18n-js/instance";
import { ResultTypeBadge } from "@circle-react/components/HeaderV2/Search/SearchResult/components";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface DefaultSearchQuickItemProps {
  isSelected: boolean;
  onClick: () => void;
  onMouseOver: (event: MouseEvent) => void;
  onFocus: (event: FocusEvent) => void;
  index: number;
  searchValue: string;
}

export const DefaultSearchQuickItem = ({
  isSelected,
  onClick,
  onMouseOver,
  onFocus,
  index,
  searchValue,
}: DefaultSearchQuickItemProps) => {
  const isSmScreen = useSmScreenMediaQuery();
  return (
    <li
      aria-current={isSelected}
      aria-posinset={index}
      className={classNames(
        "focus:bg-tertiary flex w-full cursor-pointer list-none items-center rounded px-4 py-2.5",
        {
          "bg-tertiary": isSelected,
        },
      )}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
    >
      <button
        type="button"
        onClick={onClick}
        onKeyDown={onClick}
        className="mr-3 flex flex-1 items-center gap-3 truncate focus-visible:!outline"
      >
        <Icon
          type="search-v2"
          className="text-default !h-5 min-h-[20px] !w-5 min-w-[20px]"
          size={20}
          useWithFillCurrentColor
        />
        <Typography.LabelSm as="div">
          <span className="[word-break:break-word]">
            {searchValue} -{" "}
            {isSmScreen
              ? t("search_v2.search_community_quick_mobile")
              : t("search_v2.search_community_quick")}
          </span>
        </Typography.LabelSm>
      </button>
      {isSelected && <ResultTypeBadge label={t("search_v2.enter_character")} />}
    </li>
  );
};
