import type { ReactNode } from "react";
import { t } from "@/i18n-js/instance";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { Modal } from "@circle-react-uikit/ModalV2";

export interface PreviewDmModalContainerProps {
  children: ReactNode;
}

export const PreviewDmModalContainer = ({
  children,
}: PreviewDmModalContainerProps) => {
  const modal = useModal();

  return (
    <Modal
      title={t("settings.workflows.form.preview_message.modal_title")}
      isOpen={modal.visible}
      onClose={modal.hide}
      contentPortalId=""
    >
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title className="text-left">
            {t("settings.workflows.form.preview_message.modal_title")}
          </Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <p className="text-default mb-6 text-sm">
            {t("settings.workflows.form.preview_message.modal_description")}
          </p>
          <div className="border-primary mb-6 flex rounded border px-4 py-6">
            {children}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
