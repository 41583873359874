import { useState } from "react";
import { truncate } from "lodash";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Loader } from "@/react/components/shared/uikit/Loader";
import { useToast } from "@/react/components/shared/uikit/ToastV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import { CheckboxCell } from "./CheckboxCell";
import { CheckboxColumnHeader } from "./CheckboxColumnHeader";
import { EmptyState } from "./EmptyState";
import { useImportSpacesModal } from "./ImportSpacesModal";
import { SpaceDropdownActions } from "./SpaceDropdownActions";
import { TableList } from "./TableList";
import { TypeBadge } from "./TypeBadge";
import { useSpacesForKnowledge } from "./queries";
import { useMultiSelect } from "./useMultiSelect";

const spaceColumns = ({
  onRemove,
  allIdsOnPage,
  handleSelectAllOnPage,
  handleCheckboxChange,
  selectedIds,
}: {
  onRemove: (id: number) => void;
  allIdsOnPage: number[];
  handleSelectAllOnPage: (checked: boolean) => void;
  handleCheckboxChange: (id: number) => void;
  selectedIds: number[];
}) => [
  {
    header: (
      <CheckboxColumnHeader
        allOnPageSelected={
          allIdsOnPage.length > 0 &&
          allIdsOnPage.every(id => selectedIds.includes(id))
        }
        handleSelectAllOnPage={handleSelectAllOnPage}
      />
    ),
    accessorKey: "checkbox",
    cellClassName: "align-middle gap-0 !px-0",
    thClassName: "!px-0 w-4",
    canSort: false,
    cell: ({ row }: any) => (
      <CheckboxCell
        row={row}
        handleCheckboxChange={handleCheckboxChange}
        selectedIds={selectedIds}
      />
    ),
  },
  {
    header: t(
      "community_bot.bot_builder.knowledge_page.community_resources_table.columns.title",
    ),
    accessorKey: "title",
    cell: ({ row }: any) => (
      <Typography.LabelSm weight="semibold">
        {truncate(row.original?.name, { length: 30 })}
      </Typography.LabelSm>
    ),
    canSort: false,
    cellClassName: "w-1/2",
  },
  {
    header: t(
      "community_bot.bot_builder.knowledge_page.community_resources_table.columns.type",
    ),
    accessorKey: "type",
    cell: ({ row }: any) => <TypeBadge type={row.original?.type || "Post"} />,
    canSort: false,
  },
  {
    header: t(
      "community_bot.bot_builder.knowledge_page.community_resources_table.columns.items",
    ),
    accessorKey: "posts_count",
    cell: ({ row }: any) => row.original?.posts_count,
    canSort: false,
  },
  {
    header: t(
      "community_bot.bot_builder.knowledge_page.community_resources_table.columns.used_in_replies",
    ),
    accessorKey: "used_in_replies",
    cell: ({ row }: any) => row.original?.used_in_replies,
    canSort: false,
  },
  {
    accessorKey: "actions",
    header: "",
    cell: ({ row }: any) => (
      <SpaceDropdownActions space={row.original} onRemove={onRemove} />
    ),
  },
];

export const SpacesTab = () => {
  const { watch, setValue } = useFormContext();
  const [space_ids] = watch(["space_ids"]);
  const [page, setPage] = useState(1);
  const spacesQuery = useSpacesForKnowledge({
    currentPage: page,
    spaceIds: space_ids,
  });
  const onChangePage = (page: number) => {
    // Forcing useQuery to only cache one page
    setPage(page);
    spacesQuery.remove();
  };
  const {
    selectedIds,
    allIdsOnPage,
    handleCheckboxChange,
    handleSelectAllOnPage,
    reset,
  } = useMultiSelect({ query: spacesQuery });

  const spacesCount = spacesQuery?.data?.count || 0;
  const toastr = useToast();

  const importSpacesModal = useImportSpacesModal();

  const openImportSpacesModal = () => {
    void importSpacesModal.show({
      spaceIds: space_ids,
      onImport: (ids: number[]) => {
        setValue("space_ids", [...space_ids, ...ids]);
        toastr.success(
          t(
            "community_bot.bot_builder.knowledge_page.community_resources_table.import_success",
            {
              count: ids.length,
            },
          ),
        );
      },
    });
  };

  const removeSelected = () => {
    setValue(
      "space_ids",
      space_ids.filter((spaceId: number) => !selectedIds.includes(spaceId)),
    );
    reset();
    toastr.success(
      t(
        "community_bot.bot_builder.knowledge_page.community_resources_table.remove_selected_success",
        {
          count: selectedIds.length,
        },
      ),
    );
  };

  if (spacesQuery.isLoading) {
    return <Loader />;
  }

  if (spacesCount === 0) {
    return <EmptyState onPrimaryClick={openImportSpacesModal} />;
  }

  return (
    <>
      <div className="flex min-h-[1.75rem] w-full items-center justify-between gap-4">
        {selectedIds.length > 0 ? (
          <div className="flex items-center gap-4">
            <Typography.LabelMd weight="semibold">
              {t("community_bot.bot_builder.knowledge_page.selected_count", {
                selected_count: selectedIds.length,
                count: spacesCount,
              })}
            </Typography.LabelMd>
            <Button type="button" variant="secondary" onClick={removeSelected}>
              {t("community_bot.bot_builder.knowledge_page.remove_selected")}
            </Button>
          </div>
        ) : (
          <Typography.LabelMd weight="semibold">
            {t(
              "community_bot.bot_builder.knowledge_page.community_resources_table.count",
              {
                count: spacesCount,
              },
            )}
          </Typography.LabelMd>
        )}
        <Button
          type="button"
          onClick={openImportSpacesModal}
          variant="secondary"
        >
          {t("community_bot.bot_builder.knowledge_page.import")}
        </Button>
      </div>
      <TableList
        columns={spaceColumns({
          onRemove: (id: number) => {
            setValue(
              "space_ids",
              space_ids.filter((spaceId: number) => spaceId !== id),
            );
          },
          allIdsOnPage,
          handleSelectAllOnPage,
          handleCheckboxChange,
          selectedIds,
        })}
        query={spacesQuery}
        onChangePage={onChangePage}
      />
    </>
  );
};
