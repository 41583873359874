import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";

interface ResetButtonProps {
  onClick: () => void;
}

export const ResetButton = ({ onClick }: ResetButtonProps) => (
  <button
    type="button"
    className="text-xs-plus bg-primary text-darkest border-primary hover:bg-circle-button hover:text-circle-button flex items-center justify-center gap-2 rounded-full border px-3.5 py-1.5 font-semibold"
    onClick={onClick}
  >
    <Icon type="20-reload" size={20} />
    {t("community_inbox.reset_preview")}
  </button>
);
