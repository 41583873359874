import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { Header } from "@/react/components/Chat/MessagingV2/RailBar/Header";
import { RailBarPortal } from "@circle-react/components/Layout/StandardLayoutV2/RailBarPortal";
import { newChatRoomPath } from "@circle-react/helpers/urlHelpers";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { useElementHeight } from "@circle-react/hooks/utils/useElementHeight";
import type { Profile } from "@circle-react/types/CommunityBot/Profile";
import { Icon } from "@circle-react-shared/Icon";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Rail } from "@circle-react-uikit/Rail";
import { AgentAvatar } from "./AgentAvatar";
import { PrivacyNotice } from "./PrivacyNotice";

interface BotProfileRailbarProps {
  botProfile: Profile;
}

export const BotProfileRailbar = ({ botProfile }: BotProfileRailbarProps) => {
  const { ref: headerRef, height: headerHeight } = useElementHeight();
  const history = useHistory();
  const { setCurrentBotProfile } = useCommunityInboxStore();

  const handleNewChat = () => {
    setCurrentBotProfile(botProfile);
    history.push(newChatRoomPath());
  };

  return (
    <RailBarPortal>
      <div ref={headerRef}>
        <Rail.Header className="!min-h-14">
          <Header title={t("profile")} isDirect />
        </Rail.Header>
      </div>
      <Rail.Content noPadding heightGap={headerHeight}>
        <div className="flex h-full flex-col px-6 py-5">
          <div className="flex grow flex-col">
            <div className="mb-4">
              <AgentAvatar profile={botProfile} />
              <Typography.TitleSm weight="semibold">
                {botProfile.name}
              </Typography.TitleSm>
            </div>
            <div className="mb-4">
              <button
                type="button"
                className="hover:bg-tertiary rounded-full border px-4 py-2"
                aria-label={t("chat_space.header.new_conversation")}
                onClick={handleNewChat}
              >
                <Icon
                  aria-label={t("chat_space.header.new_conversation")}
                  type="new-conversation"
                  className="text-dark mr-1"
                  useWithFillCurrentColor
                />
                <Typography.LabelSm>
                  {t("chat_space.header.new_conversation")}
                </Typography.LabelSm>
              </button>
            </div>
            {botProfile.bio && (
              <div className="mb-5 flex flex-col gap-y-2">
                <Typography.LabelSm weight="semibold">
                  {t("community_inbox.profile.bio")}
                </Typography.LabelSm>
                <div className="text-sm font-normal leading-5 tracking-tighter">
                  <TiptapEditor
                    editable={false}
                    rich_text_body={botProfile.bio}
                    shouldShowPreviewBlocks={false}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="shrink-0 pb-2 text-center">
            <PrivacyNotice />
          </div>
        </div>
      </Rail.Content>
    </RailBarPortal>
  );
};
