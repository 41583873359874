import { UserImage as UiKitUserImage } from "@circleco/ui-kit";
import thinkingGif from "./thinking.gif";

interface ThinkingAvatarProps {
  name: string;
}

export const ThinkingAvatar = ({ name }: ThinkingAvatarProps) => (
  <UiKitUserImage isFullRounded name={name} size="9" src={thinkingGif} />
);
