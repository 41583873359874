import isFunction from "lodash/isFunction";
import { t } from "@/i18n-js/instance";
import { memberProfileModalPaths } from "@/react/helpers/urlHelpers";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Icon } from "@circle-react-shared/Icon";
import { Tippy } from "@circle-react-shared/Tippy";

export const Header = ({ markAllAsRead, handleClose }) => (
  <div className="notifications__header">
    <div className="pl-[10px] pt-[5px]">
      <Typography.TitleSm weight="bold">
        {t("notifications.page_title")}
      </Typography.TitleSm>
    </div>
    <span>
      <Tippy content={t("notifications.mark_all_as_read")}>
        <button type="submit" onClick={markAllAsRead}>
          <Icon type="mark-all-read" className="icon--mark-all-read" />
        </button>
      </Tippy>
      <Tippy content={t("notifications.notification_settings")}>
        <a href={memberProfileModalPaths.notifications}>
          <Icon type="settings" className="icon--settings" />
        </a>
      </Tippy>
      {isFunction(handleClose) && (
        <Tippy content={t("notifications.close")}>
          <button type="submit" onClick={handleClose}>
            <Icon type="close" />
          </button>
        </Tippy>
      )}
    </span>
  </div>
);
