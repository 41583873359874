import slugify from "slugify";

export const getBasePath = checkoutPath =>
  slugify(checkoutPath ?? "", {
    replacement: "-",
    remove: /[*+~.,;()'"!:@]/g,
    lower: true,
  }).split("?coupon_code=")[0];

export const getCouponFromCheckoutPath = (checkoutPath, dirtyFields) =>
  !dirtyFields.checkout_path
    ? slugify(checkoutPath ?? "", {
        replacement: "-",
        remove: /[*+~.,;()'"!:@]/g,
        lower: false,
      }).split("?coupon_code=")[1] || ""
    : "";
