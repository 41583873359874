import { useState } from "react";
import type { RerunFormData } from "@circle-workflows/hooks/useRerunWorkflow";
import { useMutation } from "react-query";
import * as yup from "yup";
import { t } from "@/i18n-js/instance";
import { workflowsApi } from "@/react/api";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ModalRerun } from "../RecurringSchedule/ModalRerun";
import { OneTimeFormContent } from "./OneTimeFormContent";

interface OneTimeScheduleProps {
  workflowId: string;
  onCancel: () => void;
  setIsConfirmContent: (value: boolean) => void;
  hasRuns: boolean;
  schedule: {
    last_run_at: string;
    isReschedule: boolean;
    do_not_skip_tasks: boolean;
    id: string;
  };
}

export const OneTimeSchedule = ({
  workflowId,
  onCancel,
  setIsConfirmContent,
  hasRuns,
  schedule,
}: OneTimeScheduleProps) => {
  const toast = useToast();
  const i18nRoot = "settings.workflows.one_time_schedule_modal";
  const [isRerun, setIsRerun] = useState(false);
  const [formData, setFormData] = useState({});
  const { id, isReschedule } = schedule;

  const upsertOneTimeSchedule = (workflowId: any, _: any, data: any) => {
    const lastRunAt = new Date(data.scheduler.last_run_at).toISOString();

    if (id) {
      if (isReschedule) {
        return workflowsApi.reschedule({
          workflowId,
          id,
          body: {
            last_run_at: lastRunAt,
            schedule_type: "one_time",
            do_not_skip_tasks: data.do_not_skip_tasks,
          },
        });
      }

      return workflowsApi.updateOneTimeSchedule({
        workflowId,
        id,
        body: {
          last_run_at: lastRunAt,
          do_not_skip_tasks: data.do_not_skip_tasks,
        },
      });
    }

    return workflowsApi.createOneTimeSchedule({
      workflowId,
      body: {
        last_run_at: lastRunAt,
      },
    });
  };

  const { mutate: callOneTimeSchedule } = useMutation(
    (data: object) => upsertOneTimeSchedule(workflowId, schedule, data),
    {
      onSuccess: () => {
        setIsConfirmContent(true);
      },
      onError: (error: Error) => {
        if ("message" in error) {
          toast.error(error?.message);
        }
      },
    },
  );

  const submitOneTimeSchedule = (data: any) => {
    setFormData(data);
    if (hasRuns) {
      setIsRerun(true);
    } else {
      callOneTimeSchedule(data);
    }
  };

  const rerunSubmit = (data: RerunFormData) => {
    callOneTimeSchedule({ ...formData, ...data });
  };

  const defaultLastRunAt = isReschedule ? "" : schedule?.last_run_at;

  if (isRerun) {
    return (
      <ModalRerun
        onSubmit={rerunSubmit}
        doNotSkipTasks={schedule?.do_not_skip_tasks?.toString() ?? "false"}
        onCancel={onCancel}
      />
    );
  }

  return (
    <Modal.Content size="md">
      <Modal.Header>
        <Modal.Title size="md">{t([i18nRoot, "title"])}</Modal.Title>
        <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
          <Modal.CloseButton onClick={onCancel} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Form
        defaultValues={{
          scheduler: {
            last_run_at: defaultLastRunAt,
          },
        }}
        validationSchema={yup.object().shape({
          scheduler: yup.object().shape({
            last_run_at: yup
              .date()
              .required()
              .min(
                new Date(),
                t([i18nRoot, "past_error"], {
                  currentDateTime: formattedDateTime({
                    dateTime: new Date(),
                    format: "date_time_with_short_timezone",
                  }),
                }),
              ),
          }),
        })}
        onSubmit={submitOneTimeSchedule}
      >
        <OneTimeFormContent />
      </Form>
    </Modal.Content>
  );
};
