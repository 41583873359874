import { t } from "@/i18n-js/instance";

export const sumDurations = (
  durations: string[],
): { hours: number; minutes: number; seconds: number } => {
  const seconds = durations.reduce((acc, duration) => {
    const [lessonSec, lessonMin, lessonHr] = duration.split(":").reverse();
    const lessonSeconds =
      parseInt(lessonSec) +
      parseInt(lessonMin) * 60 +
      parseInt(lessonHr ?? 0) * 3600;
    return acc + lessonSeconds;
  }, 0);

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return {
    hours,
    minutes,
    seconds: remainingSeconds,
  };
};

// Converts a { hour, minute } duration to a string of the form "1 hr 30 min" or "30 min"
export const durationToHrMin = ({
  hours,
  minutes,
}: {
  hours: number;
  minutes: number;
}): string =>
  hours > 0
    ? t("courses.summary.content.hr_min_duration", { hours, minutes })
    : t("courses.summary.content.min_duration", { minutes });
