import { t } from "@/i18n-js/instance";
import { Typography } from "@/react/components/shared/uikit/Typography";
import type { PreviewDataType } from "@/react/hooks/paywalls/paywallAccessGroupMigration/useAccessGroupsMigrationPreviewApi";
import { AccordionV2 } from "@circle-react-uikit/AccordionV2";
import { Button } from "@circle-react-uikit/Button";
import { useSpaceChangesModal } from "./SpaceChangesModal";

const i18nBase = "settings.access_groups.paywall_migration.modal.preview";

export const AccessGroups = ({
  previewData,
}: {
  previewData: PreviewDataType;
}) => {
  const spaceChangesModal = useSpaceChangesModal();

  const spacesChangesCount =
    previewData.changes?.new_spaces.length +
    previewData.changes?.lost_spaces.length;
  const shouldShowSpaceChangesModal = spacesChangesCount > 0;

  return (
    <div className="border-primary rounded-xl border">
      <div className="border-primary bg-xy-flow flex justify-between rounded-t-xl border-b p-4">
        <Typography.LabelSm weight="bold">
          {t([i18nBase, "access_groups"])}
        </Typography.LabelSm>
        {shouldShowSpaceChangesModal && (
          <Button
            type="button"
            variant="link"
            small
            className="font-normal underline"
            onClick={() =>
              spaceChangesModal.show({
                lostSpaces: previewData.changes.lost_spaces || [],
                newSpaces: previewData.changes.new_spaces || [],
              })
            }
          >
            {t([i18nBase, "space_changes.link"], {
              count: spacesChangesCount,
            })}
          </Button>
        )}
      </div>
      <div className="h-[30vh] overflow-auto px-6 py-5">
        {previewData.access_groups.map(accessGroup => (
          <div key={`container-${accessGroup.name}`} className="mb-2">
            <AccordionV2
              title={accessGroup.name}
              subtitle={t([i18nBase, "total_spaces"], {
                count: accessGroup.total_spaces,
              })}
              key={accessGroup.name}
              defaultExpanded={false}
              headerBackgroundColor="bg-primary"
            >
              <AccordionV2.BulletedList
                composedItems={accessGroup.content.map(content => ({
                  label: content.space_group_name,
                  items: content.spaces.map(space => space.name),
                }))}
              />
            </AccordionV2>
          </div>
        ))}
      </div>
    </div>
  );
};
