import { t } from "@/i18n-js/instance";
import {
  useShowSetting,
  useUpdateSetting,
} from "@circle-react/components/SettingsApp/CommunityBot/queries";
import { InfoBox } from "@circle-react/components/shared/uikit/InfoBox";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const PreviewBanner = () => {
  const { data: setting } = useShowSetting();
  const updateSettingMutation = useUpdateSetting();

  return (
    <div className="flex w-full items-center pt-4">
      {setting && !setting?.hide_preview_banner && (
        <InfoBox className="bg-secondary border-primary mx-5 flex w-full flex-row justify-between rounded border text-base">
          <div className="flex items-center">
            <Icon className="mr-3" type="20-info" size={20} />
            <Typography.BodySm>
              {t("community_bot.bot_builder.preview_banner")}
            </Typography.BodySm>
          </div>
          <div className="flex">
            <Icon
              type="20-close"
              size={20}
              className="cursor-pointer"
              onClick={event => {
                event.preventDefault();
                updateSettingMutation.mutate({
                  hide_preview_banner: true,
                });
              }}
            />
          </div>
        </InfoBox>
      )}
    </div>
  );
};
