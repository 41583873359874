import { useRouteMatch } from "react-router-dom";
import { SETTINGS_ROUTES } from "@circle-react/components/SettingsApp/Routes/constants";

export const useRouteHelpers = () => {
  const isFeedRoute = Boolean(
    useRouteMatch({
      path: "/feed",
    }),
  );

  const isSpaceRoute = Boolean(
    useRouteMatch({
      path: "/c/:spaceSlug",
    }),
  );

  const isSpaceGroupRoute = Boolean(
    useRouteMatch({
      path: "/s/:spaceSlug",
    }),
  );

  const isHomePage = Boolean(
    useRouteMatch({
      path: "/feed",
    })?.isExact,
  );

  const isSpaceShowPage = Boolean(
    useRouteMatch({
      path: "/c/:spaceSlug",
    })?.isExact,
  );

  const isMemberDirectoryPage = Boolean(
    useRouteMatch({
      path: "/members",
    })?.isExact,
  );

  const isMessagesPage = Boolean(
    useRouteMatch({
      path: "/messages",
    })?.isExact,
  );

  const isGettingStartedRoute = Boolean(
    useRouteMatch({
      path: "/getting-started",
    })?.isExact,
  );

  const isSettingsPage = Boolean(useRouteMatch(SETTINGS_ROUTES));

  return {
    isSpaceRoute,
    isSpaceGroupRoute,
    isFeedRoute,
    isHomePage,
    isSpaceShowPage,
    isMemberDirectoryPage,
    isMessagesPage,
    isSettingsPage,
    isGettingStartedRoute,
  };
};
