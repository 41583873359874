import { REFETCH_INTERVAL } from "@/react/components/SettingsApp/Emails/components/Broadcasts/constants";
import { usePageQuery } from "@circle-react/components/SettingsApp/ManageMembers/TabContent/usePageQuery";
import { useFilterUrl } from "@circle-react-shared/Filter";
import { usePushNotificationsInfiniteQuery } from "./usePushNotificationsInfiniteQuery";

export const DEFAULT_PAGE_SIZE = 20;

export const useTable = ({ status }: { status: string }) => {
  const { queryParams } = useFilterUrl();
  const { setPage, initialPage } = usePageQuery();

  const query = String(queryParams?.query ?? "");

  const infiniteQuery = usePushNotificationsInfiniteQuery({
    perPage: DEFAULT_PAGE_SIZE,
    query,
    status,
    initialPage,
    options: {
      keepPreviousData: false,
      refetchInterval: REFETCH_INTERVAL,
    },
  });

  return {
    infiniteQuery,
    statusWiseCount: infiniteQuery.statusWiseCount || {},
    isLoading: infiniteQuery?.isLoading,
    setPage,
    initialPage,
    defaultPageSize: DEFAULT_PAGE_SIZE,
  };
};
