import { t } from "@/i18n-js/instance";
import {
  useCurrentCommunityMember,
  usePunditUserContext,
} from "@circle-react/contexts/punditUserContext";
import { createNewCommunityPath } from "@circle-react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";

/* cspell:disable-next-line */
const pendoParam = "?pendo=0oguJmJrou1SpPPXRwwyzbd4Wlo";

export const NewCommunityButton = () => {
  const currentCommunityMember = useCurrentCommunityMember();
  const isCommunityAdmin = currentCommunityMember?.is_admin;
  const { currentCommunity } = usePunditUserContext();
  const pendoNewCommunityGuidePath = window.location.href + pendoParam;

  const isCurrentPagePlansPage = window.location
    .toString()
    .includes("settings/plans");

  const isExpiredTrialCommunity =
    currentCommunity?.plan_status === "Expired Trial";

  const shouldShowPendoUrl =
    isCommunityAdmin && isCurrentPagePlansPage && isExpiredTrialCommunity;

  const communitySwitcherUrl = shouldShowPendoUrl
    ? pendoNewCommunityGuidePath
    : createNewCommunityPath();

  return (
    <TippyV2
      content={t("create_new_community")}
      placement="right"
      arrow={false}
      offset={[0, 16]}
      interactive={false}
    >
      <a
        className="hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover text-c-sidebar hover:text-c-sidebar focus:text-c-sidebar flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg transition-colors duration-150"
        href={communitySwitcherUrl}
        aria-label={t("create_new_community")}
      >
        <Icon type="20-plus-v3" size={20} />
      </a>
    </TippyV2>
  );
};
