import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { TextSelect } from "@circle-react-shared/TextSelect";
import { Typography } from "@circle-react-uikit/Typography";
import { usePaywallsEditFormContext } from "../PaywallsEditFormProvider";
import { filterPaywallPrice } from "../helpers/filterPaywallPrice";

interface PaywallFormProp {
  index: number;
  initialPaywallId?: string | number;
}

export const PaywallForm = ({ index, initialPaywallId }: PaywallFormProp) => {
  const {
    allPaywallOptions,
    allMobilePrices,

    paywallsListQuery,
    selectedPaywallIds,
    fieldNameRoot,
    fieldArrayMethods,
  } = usePaywallsEditFormContext();

  const currentFieldName = `${fieldNameRoot}.${index}.id`;

  // We are using useWatch for currentPaywallId because `watch` always
  // returns the default value defined in the `useForm`, which always
  // re-populate the PaywallForm even if it is deleted and recreated.
  const currentPaywallId = useWatch({
    name: currentFieldName,
    defaultValue: null,
  });

  const { getFieldState, formState } = useFormContext();
  const fieldError = getFieldState(currentFieldName).error;
  const hasError = !!formState.errors && !!fieldError;

  const updatePaywall = () => {
    const selectedPaywall = paywallsListQuery.data.find(
      (rawPaywall: any) => rawPaywall.id === currentPaywallId,
    );
    const firstWebPrice = selectedPaywall.prices.find(
      (price: any) => price.is_web_price,
    );

    const firstMobilePrice = allMobilePrices.find(
      (price: any) => price.paywall_id === selectedPaywall.id,
    );

    fieldArrayMethods.update(index, {
      id: selectedPaywall.id,
      display_name: selectedPaywall.display_name,
      description: selectedPaywall.description,
      checkout_path: selectedPaywall.checkout_path,
      paywall_highlighted: false,
      currency: {
        id: selectedPaywall.currency.id,
        code: selectedPaywall.currency.code,
        symbol: selectedPaywall.currency.symbol,
      },
      processor_id: selectedPaywall?.processor_id,
      mobile_processor_id: selectedPaywall?.mobile_processor_id,
      status: selectedPaywall?.status,
      web_paywall_price: {
        ...filterPaywallPrice(firstWebPrice),
        trial_days: selectedPaywall?.trial_days,
      },
      mobile_paywall_price: filterPaywallPrice(firstMobilePrice, "mobile"),
    });
  };

  useEffect(() => {
    if (currentPaywallId && currentPaywallId !== initialPaywallId) {
      updatePaywall();
    }
  }, [currentPaywallId, initialPaywallId]);

  const filterByText = (option: any, search: string) =>
    (typeof option.label === "string" ? option.label : option.textLabel)
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(search.toLowerCase().replace(/\s+/g, ""));

  const filterAvailablePaywalls = (paywallOption: any) =>
    !selectedPaywallIds.includes(paywallOption.value);
  const filterByPaywall = (options: any[] = [], search = "") => {
    const availableOptions = options.filter(filterAvailablePaywalls);

    if (!search) {
      return [...availableOptions];
    }

    return availableOptions.filter(option => filterByText(option, search));
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Typography.LabelSm weight="semibold">
        {t(
          "paywalls.lock_screen.sidebar.paywall_edit_form.fields.select_paywall",
        )}
      </Typography.LabelSm>
      <Controller
        name={currentFieldName}
        defaultValue={initialPaywallId}
        rules={{
          required: t(
            "paywalls.lock_screen.sidebar.errors.paywall_not_selected",
          ),
        }}
        render={({ field }) => (
          <div className="flex flex-col gap-y-1">
            <TextSelect
              isError={hasError}
              options={allPaywallOptions}
              buttonClassName="flex flex-col w-full p-4"
              filterBy={filterByPaywall}
              {...field}
            />
            {hasError && (
              <Typography.LabelXs color="text-feedback-error">
                {fieldError.message}
              </Typography.LabelXs>
            )}
          </div>
        )}
      />
    </div>
  );
};
