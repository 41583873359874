import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import slugify from "slugify";
import {
  getBasePath,
  getCouponFromCheckoutPath,
} from "@/react/components/Paywalls/Admin/PaywallsAdmin/FormModal/DetailsTab/detailsUtils";
import { copyPaywallCheckoutUrlWithPrefix } from "@circle-react/helpers/paywallCheckoutHelpers";

export const useDetailsTab = () => {
  const { control, getValues, setValue, setFocus, formState } =
    useFormContext();

  const watcherDisplayName = useWatch({ control, name: "display_name" });
  const watcherAutoApplyCoupon = useWatch({ control, name: "coupon_selector" });
  const watcherCheckoutPath = useWatch({ control, name: "checkout_path" });

  useEffect(() => {
    setValue(
      "checkout_path",
      slugify(watcherCheckoutPath ?? "", {
        replacement: "-",
        remove: /[*+~.,;()'"!:@]/g,
        lower: true,
      }),
    );
  }, [watcherCheckoutPath]);

  useEffect(() => {
    // TODO: Remove both useEffect when onChange feature
    // is available in FormInput
    if (
      watcherCheckoutPath === "" ||
      (formState.dirtyFields.display_name &&
        !formState.dirtyFields.checkout_path)
    ) {
      setValue("checkout_path", watcherDisplayName);
    }
  }, [watcherDisplayName]);

  useEffect(() => {
    const basePath = getBasePath(watcherCheckoutPath);
    const couponFromSelect = getValues("coupon_selector");

    const couponFromCheckoutPath = getCouponFromCheckoutPath(
      watcherCheckoutPath,
      formState.dirtyFields,
    );

    const couponQuery = couponFromSelect
      ? `?coupon_code=${encodeURIComponent(couponFromSelect)}`
      : couponFromCheckoutPath
        ? `?coupon_code=${encodeURIComponent(couponFromCheckoutPath)}`
        : "";

    const shouldDirty = couponFromCheckoutPath && !couponFromSelect;
    setValue("checkout_path", `${basePath}${couponQuery}`, {
      shouldDirty: shouldDirty,
    });
  }, [
    formState.dirtyFields.checkout_path,
    getValues,
    setValue,
    watcherAutoApplyCoupon,
    watcherCheckoutPath,
  ]);

  useEffect(() => {
    if (watcherCheckoutPath && !watcherCheckoutPath.includes("coupon_code=")) {
      setValue("coupon_selector", "");
    }
  }, [watcherCheckoutPath, setValue]);

  const copyWithPrefix = () => {
    copyPaywallCheckoutUrlWithPrefix(
      getValues("checkout_path"),
      "#paywalls-list",
    );

    setFocus("checkout_path", { shouldSelect: true });
  };

  return {
    copyWithPrefix,
  };
};
