import { t } from "@/i18n-js/instance";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Typography } from "@circle-react-uikit/Typography";
import { useUpdateMemberAccess } from "./hooks/useUpdateMemberAccess";

export interface UpdateMemberAccessModalProps {
  paywallId: string;
}

export const UpdateMemberAccessModal = ({
  paywallId,
}: UpdateMemberAccessModalProps) => {
  const { isOpen, isSubmitting, onClose, handleSubmit } =
    useUpdateMemberAccess(paywallId);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={t("settings.paywalls.modal_update_access_member.title")}
      onClose={onClose}
      cancelText={t(
        "settings.paywalls.modal_update_access_member.only_new_members",
      )}
      onConfirm={handleSubmit}
      confirmVariant="primary"
      confirmText={t(
        "settings.paywalls.modal_update_access_member.all_members",
      )}
      disabled={isSubmitting}
    >
      <Typography.BodyMd>
        {t("settings.paywalls.modal_update_access_member.description")}
      </Typography.BodyMd>
      <br />
      <Typography.BodyMd>
        {t("settings.paywalls.modal_update_access_member.description_warning")}
      </Typography.BodyMd>
    </ConfirmationModal>
  );
};
