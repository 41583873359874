import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { useFilterContext } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { TOPICS_QUERY_KEY } from "@circle-react/components/Spaces/TopicFilters/hooks/useTopics";
import { usePunditUserContext } from "@circle-react/contexts";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { PaginationHelper } from "@circle-react/types";
import {
  CheckboxFilterV2 as CheckboxFilter,
  Filter,
} from "@circle-react-shared/Filter";

export const TopicsFilter = () => {
  const { filters, setFilters } = useFilterContext();
  const { currentCommunityMember } = usePunditUserContext();
  const { data: topics } = useQuery<
    PaginationHelper<{
      id: number;
      name: string;
      admin_only: boolean;
    }>
  >({
    queryKey: [TOPICS_QUERY_KEY, "communityMember", currentCommunityMember?.id],
    enabled: !!currentCommunityMember,
    queryFn: () => reactQueryGet(internalApi.topics.communityMemberTopics({})),
  });

  if (!topics || !topics.records || !currentCommunityMember) return null;

  return (
    <Filter
      chip={t("search_v2.filters.topic")}
      title={t("search_v2.filters.topic")}
      selectedText={
        filters?.topic_ids
          ? t("search_v2.filters.n_topics", {
              count: filters?.topic_ids?.length || 0,
            })
          : ""
      }
      onApply={data => {
        setFilters({
          ...filters,
          topic_ids: data?.value,
        });
      }}
      shouldShowRemoveButton={!!filters?.topic_ids}
    >
      <CheckboxFilter
        options={topics.records.map(topic => ({
          label: topic.name,
          value: topic.id,
        }))}
        className="w-full"
        param={{
          id: "topics",
          key: "topics",
          value: filters?.topic_ids || [],
        }}
        legend={t("search_v2.filters.topic")}
        hasSearch
        searchProps={{
          placeholder: t("search_v2.filters.search_topics"),
        }}
      />
    </Filter>
  );
};
