import { t } from "@/i18n-js/instance";
import { BrandedApp } from "@circle-react-shared/BrandedApp";
import { EmptyState } from "@circle-react-shared/uikit/EmptyState";
import { PricingTabAddPriceModal } from "./AddPriceModal";
import { PricingTabPriceList } from "./PriceList";
import { PricingSettings } from "./PricingSettings";
import { usePricingTabContext } from "./hooks/usePricingTabContext";

export const StripePricingTab = () => {
  const { getPrices, isShowingAddPriceModal, showAddPriceModal } =
    usePricingTabContext();

  const prices = getPrices();

  return (
    <>
      <div className="mr-4 flex flex-col gap-y-8">
        {!prices || !prices.length ? (
          <div className="mt-4 flex flex-col items-center justify-center gap-4 rounded-lg border py-20">
            <EmptyState
              header={t(
                "settings.paywalls.modal.tabs.pricing.empty_state.title",
              )}
              description={t(
                "settings.paywalls.modal.tabs.pricing.empty_state.description",
              )}
              onPrimaryClick={showAddPriceModal}
              primaryLabel={t(
                "settings.paywalls.modal.tabs.pricing.actions.add_price",
              )}
            />
          </div>
        ) : (
          <>
            <PricingTabPriceList />
            <PricingSettings />
          </>
        )}
        <BrandedApp.Banner
          title={t("settings.paywalls.modal.tabs.pricing.branded_apps_title")}
        />
      </div>
      {isShowingAddPriceModal() && <PricingTabAddPriceModal />}
    </>
  );
};
