import { t } from "@/i18n-js/instance";
import { PAYWALL_STATUS } from "@circle-react/helpers/paywallHelpers";
import {
  paywallStatusesDefault,
  usePaywallForm,
} from "@circle-react-shared/Paywalls/Modals/CreatePaywallSubscriptionModal/Form/hooks/usePaywallForm";
import { useBulkImportTrialPaywallSubscriptionFormContext } from "./BulkImportTrialPaywallSubscriptionFormProvider";
import { CustomFormItem } from "./CustomFormItem";
import { CustomSelectInput } from "./CustomSelectInput";

const noPaywallSelected = {
  label: t("bulk_import_page.paywall.choose_paywall"),
  value: "",
};

export const PaywallForm = () => {
  const { paywallId, setPaywallId, skipDateValidations } =
    useBulkImportTrialPaywallSubscriptionFormContext();
  const { paywallOptions } = usePaywallForm({
    communityMemberId: null,
    paywallStatuses: skipDateValidations
      ? paywallStatusesDefault
      : [PAYWALL_STATUS.ACTIVE],
  });

  const onPaywallChange = e => {
    setPaywallId(e.target.value ?? paywallId);
  };

  return (
    <CustomFormItem
      label={t("create_paywall_subscription_modal.form.paywall_id")}
    >
      <CustomSelectInput
        name="paywall[paywall_id]"
        options={[noPaywallSelected, ...paywallOptions]}
        onChange={onPaywallChange}
        value={paywallId}
      />
    </CustomFormItem>
  );
};
