import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";

export const UpgradePlan = () => (
  <div className="mb-8 mt-4 p-8 text-center shadow-none">
    <h1 className="mb-8 text-2xl">
      {t("community.feature.no_live_streams.title")}
    </h1>
    <p className="mb-8">{t("community.feature.no_live_streams.description")}</p>
    <ul className="mx-4 mb-8 list-none text-justify">
      <li className="mb-2 flex list-none font-medium">
        <Icon type="star" className="mr-2" size={20} />
        <span className="w-full">{t("plan_details.up_to_3_admins")}</span>
      </li>
      <li className="mb-2 flex list-none font-medium">
        <Icon type="star" className="mr-2" size={20} />
        <span className="w-full">{t("plan_details.up_to_10_moderators")}</span>
      </li>
      <li className="mb-2 flex list-none font-medium">
        <Icon type="star" className="mr-2" size={20} />
        <span className="w-full">{t("plan_details.up_to_20_spaces")}</span>
      </li>
      <li className="mb-2 flex list-none font-medium">
        <Icon type="star" className="mr-2" size={20} />
        <span className="w-full">{t("plan_details.unlimited_members")}</span>
      </li>
      <li className="mb-2 flex list-none font-medium">
        <Icon type="star" className="mr-2" size={20} />
        <span className="w-full">{t("plan_details.custom_domain")}</span>
      </li>
      <li className="mb-2 flex list-none font-medium">
        <Icon type="star" className="mr-2" size={20} />
        <span className="w-full">{t("plan_details.advanced_analytics")}</span>
      </li>
      <li className="mb-2 flex list-none font-medium">
        <Icon type="star" className="mr-2" size={20} />
        <span className="w-full">{t("plan_details.custom_css")}</span>
      </li>
      <li className="mb-2 flex list-none font-medium">
        <Icon type="star" className="mr-2" size={20} />
        <span className="w-full">{t("plan_details.sso_wp_memberful")}</span>
      </li>
      <li className="mb-2 flex list-none font-medium">
        <Icon type="star" className="mr-2" size={20} />
        <span className="w-full">{t("plan_details.500MB_file_limit")}</span>
      </li>
      <li className="mb-2 flex list-none font-medium">
        <Icon type="star" className="mr-2" size={20} />
        <span className="w-full">{t("plan_details.100GB_total_storage")}</span>
      </li>
    </ul>
    <a className="btn btn-primary" href="/settings/plans">
      {t("plan_details.upgrade_plan")}
    </a>
  </div>
);
