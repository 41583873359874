import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { useLiveStreamsUsageLimits } from "@circle-react/components/LiveStreams/hooks";
import { useCreateLiveModal } from "@circle-react/components/Modals/CreateLiveModal";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { useSidebarBorderStyles } from "../hooks";
import { useShouldDisplayGoLiveFooter } from "./useShouldDisplayGoLiveFooter";

export interface GoLiveProps {
  isPreviewMode?: boolean;
  inSettings?: boolean;
}

export const GoLive = ({
  isPreviewMode = false,
  inSettings = false,
}: GoLiveProps) => {
  const {
    isLoading: areLiveLimitsLoading,
    isMonthlyHourLimitReached,
    isStorageLimitReached,
  } = useLiveStreamsUsageLimits();
  const areLiveLimitsReached =
    isMonthlyHourLimitReached || isStorageLimitReached;
  const createLiveModal = useCreateLiveModal();
  const styles = useSidebarBorderStyles();
  const shouldDisplayGoLiveFooter = useShouldDisplayGoLiveFooter();

  const { currentCommunityMember, isViewOnlyMasquerading } =
    usePunditUserContext();

  const shouldDisableGoLiveButton =
    !isCommunityAdmin(currentCommunityMember) &&
    (areLiveLimitsLoading || areLiveLimitsReached);

  const toggleFormOpen = () => {
    void createLiveModal.show();
  };

  if (!shouldDisplayGoLiveFooter) {
    return null;
  }

  return (
    <div
      className={classNames("flex w-full items-center justify-between", {
        "pl-4 pr-0": inSettings,
        "bg-c-sidebar": !inSettings,
        "cursor-not-allowed": isViewOnlyMasquerading,
      })}
      style={styles}
    >
      <Button
        full
        onClick={toggleFormOpen}
        variant="secondary-light"
        disabled={shouldDisableGoLiveButton}
        className={classNames(
          "!border-dark hover:bg-secondary px-4 py-[8.5px] font-medium transition-colors duration-150 ease-in-out",
          {
            "pointer-events-none": isPreviewMode || isViewOnlyMasquerading,
          },
        )}
      >
        <div className="text-selector-active flex items-center justify-center">
          <Icon type="16-go-live" size={16} className="text-default mr-2" />
          {t("sidebar_footer_v3.go_live")}
        </div>
      </Button>
    </div>
  );
};
