import { t } from "@/i18n-js/instance";
import { TiptapEditor } from "@circle-react-uikit/TipTap";
import { Typography } from "@circle-react-uikit/Typography";
import { Avatar } from "../../../Avatar";

export interface HistoryDetailViewDMProps {
  body: {
    executor_member_id: number;
    receiver_member_id: number;
    rich_text_body: object;
  };
}

export const HistoryDetailViewDM = ({ body }: HistoryDetailViewDMProps) => {
  const {
    executor_member_id: executorMemberId,
    receiver_member_id: receiverMemberId,
    rich_text_body: richTextBody,
  } = body;

  return (
    <div className="flex flex-col gap-2">
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.send_dm.from")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <Avatar communityMemberId={executorMemberId} />
        </div>
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.send_dm.to")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <Avatar communityMemberId={receiverMemberId} />
        </div>
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.send_dm.message")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <TiptapEditor
            editable={false}
            rich_text_body={richTextBody}
            editorClassName="text-sm inline-flex flex-col w-full"
          />
        </div>
      </div>
    </div>
  );
};
