import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const LegalText = () => {
  const termsOfServiceText = t("terms_of_service");
  const privacyPolicyText = t("privacy_policy");
  const separatorDot = t("separator_dot");

  return (
    <Typography.LabelSm weight="medium">
      {[termsOfServiceText, separatorDot, privacyPolicyText].join(" ")}
    </Typography.LabelSm>
  );
};
