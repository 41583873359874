import type { MouseEvent } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCommunitySwitcher } from "@circle-react/hooks/community_switcher/useCommunitySwitcherApi";
import type { CommunitySwitcher } from "@circle-react/types/CommunitySwitcher";
import { SortableList } from "@circle-react-shared/uikit/SortableList";
import { CommunityDragOverlay } from "./CommunityDragOverlay";
import { CommunitySwitcherPresentationV3 } from "./CommunitySwitcherPresentationV3";
import { useSeeMyCommunitiesModal } from "./MyCommunitiesModal";
import { SortableCommunityItem } from "./SortableCommunityItem";

export const MAX_COMMUNITIES_TO_DISPLAY = 5;

export interface CommunitySwitcherContentV3Props {
  isLoadingAppData?: boolean;
}

const isExternalLink = (href: string) => {
  try {
    const linkUrl = new URL(href, window.location.origin);
    return linkUrl.origin !== window.location.origin;
  } catch (error) {
    return false;
  }
};

export const CommunitySwitcherContentV3 = ({
  isLoadingAppData = false,
}: CommunitySwitcherContentV3Props) => {
  const [isNavigating, setIsNavigating] = useState(false);
  const navigationTimeout = useRef<number | null>(null);

  const [sortedCommunities, setSortedCommunities] = useState<
    CommunitySwitcher[]
  >([]);

  const {
    communities,
    token,
    isLoadingCommunities,
    isReorderingCommunities,
    onSortCommunities,
  } = useCommunitySwitcher();

  const myCommunitiesModal = useSeeMyCommunitiesModal();

  const isLoading = isLoadingAppData || isLoadingCommunities || isNavigating;

  const shouldShowShowMoreButton =
    communities.length > MAX_COMMUNITIES_TO_DISPLAY;

  const onClickSeeMyCommunities = useCallback(async () => {
    await myCommunitiesModal.show();
  }, [myCommunitiesModal]);

  const onSortHandler = useCallback(
    (sortedCommunities: CommunitySwitcher[]) => {
      setSortedCommunities(sortedCommunities);
      onSortCommunities(sortedCommunities, {
        onError: () => {
          setSortedCommunities(communities);
        },
      });
    },
    [onSortCommunities, setSortedCommunities, communities],
  );

  const extraCommunity = useMemo(() => {
    const currentCommunityIndex = sortedCommunities.findIndex(
      community => community.is_current_community,
    );
    if (currentCommunityIndex >= MAX_COMMUNITIES_TO_DISPLAY) {
      return sortedCommunities[currentCommunityIndex];
    }
    return undefined;
  }, [sortedCommunities]);

  useEffect(() => {
    if (isLoadingCommunities) return;
    setSortedCommunities(communities);
  }, [communities, isLoadingCommunities]);

  const handleAnchorEventBubble = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (!(event.target instanceof Element)) return;

      const closestAnchor = event.target.closest("a");
      if (!closestAnchor) return;

      const href = closestAnchor.getAttribute("href");
      if (!href) return;

      setIsNavigating(true);

      // For internal navigation, set a timeout to reset the state
      if (!isExternalLink(href)) {
        navigationTimeout.current = window.setTimeout(() => {
          setIsNavigating(false);
        }, 1000);
      }
    },
    [setIsNavigating],
  );

  useEffect(
    () => () => {
      if (navigationTimeout.current === null) return;
      setIsNavigating(false);
      window.clearTimeout(navigationTimeout.current);
      navigationTimeout.current = null;
    },
    [],
  );

  return (
    <CommunitySwitcherPresentationV3
      id="community-switcher"
      isLoading={isLoading}
      shouldShowShowMoreButton={shouldShowShowMoreButton}
      onClickSeeMyCommunities={onClickSeeMyCommunities}
      onClick={handleAnchorEventBubble}
    >
      <SortableList
        items={sortedCommunities}
        lockedVerticalAxis
        onSort={onSortHandler}
        shouldUseKeyboardSensor={false}
      >
        <SortableList.List>
          {sortedCommunities
            .slice(0, MAX_COMMUNITIES_TO_DISPLAY)
            .map((community, index) => (
              <SortableCommunityItem
                key={community.id}
                community={community}
                sortedCommunities={sortedCommunities}
                isReorderingCommunities={isReorderingCommunities}
                token={token}
                index={index}
              />
            ))}
          {extraCommunity && (
            <SortableCommunityItem
              key={extraCommunity.id}
              community={extraCommunity}
              sortedCommunities={sortedCommunities}
              isReorderingCommunities={isReorderingCommunities}
              token={token}
              index={sortedCommunities.length - 1}
            />
          )}
        </SortableList.List>
        <SortableList.DragOverlay shouldRestrictToParent>
          <CommunityDragOverlay />
        </SortableList.DragOverlay>
      </SortableList>
    </CommunitySwitcherPresentationV3>
  );
};
