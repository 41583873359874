import { noop } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { reactQueryPut } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { getCourseSectionsKey } from "@circle-react/hooks/courses/useCoursesApi";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";

export interface MobileCourseRenameModalProps {
  courseId?: string;
  sectionId?: string;
  lessonId?: string;
  defaultName?: string;
  type?: "lesson" | "section";
}

const MobileCourseRenameModalComponent = ({
  courseId = "",
  sectionId = "",
  lessonId = "",
  defaultName = "",
  type = "lesson",
}: MobileCourseRenameModalProps) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const toast = useToast();
  const renameMutationConfig = {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getCourseSectionsKey(courseId));
      void modal.hide();
    },
    onError: (e: any) => {
      toast.error(e.errorDetails);
    },
  };

  const renameLessonMutation = useMutation<any, any, any>(
    data =>
      reactQueryPut(
        internalApi.courses.updateLesson({
          courseId,
          sectionId,
          lessonId,
        }),
        data,
      ),
    renameMutationConfig,
  );

  const renameSectionMutation = useMutation<any, any, any>(
    data =>
      reactQueryPut(
        internalApi.courses.updateSection({
          courseId,
          sectionId,
        }),
        data,
      ),
    renameMutationConfig,
  );

  const onSubmit = ({ name }: any) => {
    if (type === "lesson") {
      renameLessonMutation.mutate({
        course_lesson: { name },
      });
    } else if (type === "section") {
      renameSectionMutation.mutate({
        course_section: { name },
      });
    }
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.visible ? modal.hide : noop}>
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title>
            {type === "lesson"
              ? t("courses.edit.sections.rename_lesson")
              : t("courses.edit.sections.rename_section")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.visible ? modal.hide : noop} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={onSubmit}
            defaultValues={{
              name: defaultName,
            }}
            id="course-section-lesson-rename-form"
          >
            <Form.Item
              hideDescription
              hideBorder
              fullWidth
              name="name"
              label={t("title")}
              rules={{
                required: t(
                  "courses.edit.sections.validations.name_is_required",
                ),
              }}
            >
              <Form.Input autoFocus />
            </Form.Item>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            form="course-section-lesson-rename-form"
            type="submit"
            full
            extraLarge
            variant="circle"
          >
            {renameLessonMutation.isLoading || renameSectionMutation.isLoading
              ? t("saving_changes")
              : t("save_changes")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

const MobileCourseRenameModal = ModalManager.create(
  (props: MobileCourseRenameModalProps) => (
    <MobileCourseRenameModalComponent {...props} />
  ),
);

export const useMobileCourseRenameModal = () =>
  useModal(MobileCourseRenameModal);
