import { LOCATION_TYPES } from "@circle-react/components/Events/helpers/constants";
import { dateIsPast } from "@circle-react/helpers/dateTimeHelpers";

const isWithinXMinutes = (date: Date, minutes: number) => {
  // Create Date objects from the input date
  const dateObj1 = new Date();
  const dateObj2 = new Date(date);

  // Calculate the time difference in milliseconds
  const timeDiffInMillis = Math.abs(dateObj1.getTime() - dateObj2.getTime());

  // Convert the time difference to minutes
  const timeDiffInMinutes = timeDiffInMillis / (1000 * 60);

  // Check if the time difference is less than or equal to 15 minutes
  return timeDiffInMinutes <= minutes;
};

export const isEventActive = (event: any) => {
  const { event_setting_attributes: eventData } = event || {};
  const { starts_at: startsAt } = eventData || {};

  return isWithinXMinutes(startsAt, 10) || dateIsPast(startsAt);
};

export const isEventFinished = (event: any) => {
  const { event_setting_attributes: eventData } = event || {};
  const { ends_at: endsAt } = eventData || {};

  return dateIsPast(endsAt);
};

export const isOnlineEvent = (event: any) => {
  const { event_setting_attributes: eventData } = event || {};
  const { location_type: locationType } = eventData || {};

  return (
    locationType !== LOCATION_TYPES.IN_PERSON &&
    locationType !== LOCATION_TYPES.TBD
  );
};

export const canRespondToInvitation = (event: any) => {
  const {
    event_setting_attributes: eventData,
    invited_attendee: attendeeWasInvited,
  } = event || {};
  const { rsvp_disabled: isRsvpDisabled } = eventData || {};

  return !isRsvpDisabled || (isRsvpDisabled && attendeeWasInvited);
};
