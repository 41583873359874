import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { Filter, InputFilter, useFilterUrl } from "@circle-react-shared/Filter";

export const NameFilter = ({
  isInitialOpen,
  onApply,
  changeUrl = true,
  value,
  localeRoot = "settings.paywalls_admin_dashboard.filters.community_member_name",
}: any) => {
  const { queryParams, updateUrl } = useFilterUrl();

  return (
    <Filter
      chip={t([localeRoot, "title"])}
      title={t([localeRoot, "title"])}
      selectedText={value || queryParams.name}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        changeUrl && updateUrl("name", value);
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton
    >
      <InputFilter
        name="name-filter"
        placeholder={t([localeRoot, "placeholder"])}
      />
    </Filter>
  );
};
