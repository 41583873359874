import { t } from "@/i18n-js/instance";
import type { ChatType } from "@circle-react/types/CommunityBot/ChatSort";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { CHAT_TYPES_DROPDOWN_ITEMS } from "./constants";
import type { ChatTypeDropdownItem } from "./constants";

interface ChatTypesDropdownProps {
  className?: string;
  activeItem: ChatType;
  localeScope: string;
  onItemClick: (item: ChatType) => void;
}

export const ChatTypesDropdown = ({
  className,
  activeItem,
  localeScope,
  onItemClick,
}: ChatTypesDropdownProps) => (
  <Dropdown
    direction="bottom-start"
    className={className}
    buttonClassName="w-full"
    menuItemsClassName="!w-40"
    button={
      <Dropdown.MenuButton className="hover:bg-secondary-dark border-primary flex justify-between rounded-md border bg-transparent !py-1.5 pl-3 pr-2 transition-colors">
        <div className="flex gap-x-2">
          <Typography.LabelSm weight="medium">
            {t([localeScope, activeItem])}
          </Typography.LabelSm>
        </div>
        <Icon
          size={16}
          type="16-chevron-triangle-down-small"
          className="text-dark"
        />
      </Dropdown.MenuButton>
    }
  >
    {CHAT_TYPES_DROPDOWN_ITEMS.map((item: ChatTypeDropdownItem) => {
      const { value, icon } = item;
      return (
        <Dropdown.Item key={value} onClick={() => onItemClick(value)}>
          <Dropdown.ItemLink
            type="button"
            active={activeItem === value}
            aria-label={t([localeScope, value])}
          >
            <div className="flex items-center gap-x-2">
              <Icon type={icon} size={20} />
              <span>{t([localeScope, value])}</span>
            </div>
          </Dropdown.ItemLink>
        </Dropdown.Item>
      );
    })}
  </Dropdown>
);
