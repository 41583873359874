import type { ReactNode } from "react";
import { useCallback, useState } from "react";
import { createContext, useContext } from "react";
import { isEqual } from "lodash";
import noop from "lodash/noop";
import invariant from "tiny-invariant";
import { useShowProfileEdit } from "@circle-react/hooks/profile/useShowProfileEdit";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import type { AccessGroupMemberFilterType } from "@circle-react/types/AccessGroup";
import type { CommunityMember } from "@circle-react/types/CommunityMember";

export interface AccessGroupDataContextInterface {
  accessGroup: AccessGroupType;
  selectedMemberIds: number[];
  setSelectedMemberIds: (communityMemberIds: number[]) => void;
  updateFiltersToAddMembers: (filters: AccessGroupMemberFilterType) => void;
  clearSelectedMemberIds: () => void;
  addMemberId: (communityMemberId: number) => void;
  updateTotalMembersToProcess: (total: number) => void;
  totalMembersToProcess: number;
  shouldBlockActions: boolean;
  setShouldBlockActions: (blockActions: boolean) => void;
  filtersToProcessMembers: any;
  setFiltersToProcessMembers: (filters: AccessGroupMemberFilterType) => void;
  clearMemberIdsAndFilters: () => void;
  showMemberProfileEdit: ({ member }: { member: CommunityMember }) => void;
}

const AccessGroupDataContext = createContext<AccessGroupDataContextInterface>({
  accessGroup: {
    id: 0,
    name: "",
    space_group_ids: [],
    space_ids: [],
    has_members: false,
    status: "active",
  },
  updateFiltersToAddMembers: noop,
  selectedMemberIds: [],
  setSelectedMemberIds: noop,
  clearSelectedMemberIds: noop,
  addMemberId: noop,
  updateTotalMembersToProcess: noop,
  totalMembersToProcess: 0,
  shouldBlockActions: false,
  setShouldBlockActions: noop,
  filtersToProcessMembers: {},
  setFiltersToProcessMembers: noop,
  clearMemberIdsAndFilters: noop,
  showMemberProfileEdit: noop,
});

AccessGroupDataContext.displayName = "AccessGroupDataContext";

export const useAccessGroupData = () => {
  const context = useContext(AccessGroupDataContext);
  invariant(
    context,
    "useAccessGroupData must be used within a AccessGroupDataContextProvider",
  );
  return context;
};

interface AccessGroupDataContextProviderInterface {
  accessGroup: AccessGroupType;
  children: ReactNode;
}

export function AccessGroupDataProvider({
  accessGroup,
  children,
}: Readonly<AccessGroupDataContextProviderInterface>) {
  const [selectedMemberIds, setSelectedMemberIds] = useState<number[]>([]);

  const [shouldBlockActions, setShouldBlockActions] = useState(false);

  const [totalMembersToProcess, setTotalMembersToProcess] = useState(0);

  const [filtersToProcessMembers, setFiltersToProcessMembers] =
    useState<AccessGroupMemberFilterType>([]);

  const clearSelectedMemberIds = useCallback(() => {
    setSelectedMemberIds([]);
  }, [setSelectedMemberIds]);

  const addMemberId = useCallback(
    (communityMemberId: number) => {
      setSelectedMemberIds(selectedMemberIds => {
        if (selectedMemberIds.includes(communityMemberId)) {
          return selectedMemberIds.filter(id => id !== communityMemberId);
        }
        return [...selectedMemberIds, communityMemberId];
      });
    },
    [setSelectedMemberIds],
  );

  const updateFiltersToAddMembers = useCallback(
    (newFilters: AccessGroupMemberFilterType) => {
      if (!isEqual(newFilters, filtersToProcessMembers)) {
        clearSelectedMemberIds();
        setFiltersToProcessMembers(newFilters);
      }
    },
    [filtersToProcessMembers, clearSelectedMemberIds],
  );

  const updateTotalMembersToProcess = useCallback(
    (total = 0) => {
      // members manually selected have priority
      const totalSelected = selectedMemberIds.length;
      if (totalSelected > 0) {
        if (totalSelected != totalMembersToProcess) {
          setTotalMembersToProcess(selectedMemberIds.length);
        }
      } else if (total != totalMembersToProcess) {
        setTotalMembersToProcess(total);
      }
    },
    [selectedMemberIds.length, totalMembersToProcess],
  );

  const clearMemberIdsAndFilters = useCallback(() => {
    setSelectedMemberIds([]);
    setFiltersToProcessMembers([]);
  }, [setSelectedMemberIds, setFiltersToProcessMembers]);

  // this redirects to member modal, it has to be defined outside of the MemoryRouter to make it work
  const { showMemberProfileEdit } = useShowProfileEdit();

  return (
    <AccessGroupDataContext.Provider
      value={{
        accessGroup,
        selectedMemberIds,
        setSelectedMemberIds,
        clearSelectedMemberIds,
        addMemberId,
        updateFiltersToAddMembers,
        updateTotalMembersToProcess,
        totalMembersToProcess,
        shouldBlockActions,
        setShouldBlockActions,
        setFiltersToProcessMembers,
        filtersToProcessMembers,
        clearMemberIdsAndFilters,
        showMemberProfileEdit,
      }}
    >
      {children}
    </AccessGroupDataContext.Provider>
  );
}
