import type { ComponentPropsWithoutRef } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export interface CommunityContentProps extends ComponentPropsWithoutRef<"div"> {
  disableSidebar?: boolean;
}

export const CommunityContent = ({
  disableSidebar = false,
  children,
  className,
  ...rest
}: CommunityContentProps) => (
  <div
    id="panel"
    data-testid="community-content"
    className={classNames(
      "community__content trix-v2 bg-secondary h-[calc(100vh-48px)] w-full flex-1 overflow-y-auto print:overflow-visible",
      {
        "community__content--no-sidebar": disableSidebar,
      },
      className,
    )}
    data-target="mobile-sidebar.panel"
    {...rest}
  >
    {children}
  </div>
);
