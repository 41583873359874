import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ImportSpacesModalComponent } from "./ImportSpacesModalComponent";

const ImportSpacesModal = ModalManager.create(
  ({
    spaceIds,
    onImport,
  }: {
    spaceIds: number[];
    onImport: (ids: number[]) => void;
  }) => (
    <ImportSpacesModalComponent
      spaceIds={spaceIds}
      onImport={onImport}
      page={1}
    />
  ),
);

export const useImportSpacesModal = () => useModal(ImportSpacesModal);
