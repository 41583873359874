import { t } from "@/i18n-js/instance";
import type { WorkflowType } from "@circle-react/components/SettingsApp/Workflows/constants";
import type { Audience, Trigger } from "../../../generated/WorkflowsApi.types";
import { ActionGroup } from "../../NewWorkflowV2/ActionGroup";
import { EntryRule } from "../../NewWorkflowV2/EntryRule";
import TemplateItemWrapper from "../TemplateItemWrapper";
import { getActionsByActionType } from "../utils";
import { Title } from "./Title";
import { TypeBadge } from "./TypeBadge";

export interface TemplateItemProps {
  templateKey: string;
  templateType: WorkflowType;
  trigger: Trigger;
  audience: Audience;
  actions: string[];
  onClick: () => void;
}

export const TemplateItem = ({
  templateKey,
  templateType,
  trigger,
  audience,
  actions: actionProps = [],
  onClick,
}: TemplateItemProps) => {
  const actions = getActionsByActionType(actionProps);
  const hasActions = actions.length > 0;

  return (
    <TemplateItemWrapper onClick={onClick}>
      <div className="flex gap-2">
        <EntryRule
          trigger={trigger}
          audience={audience}
          workflowType={templateType}
        />
        {hasActions && <ActionGroup actions={actions} />}
      </div>
      <div className="flex h-full flex-col justify-between gap-2">
        <div className="flex">
          <Title>{t(`workflows.templates.${templateKey}`)}</Title>
        </div>
        {templateType && (
          <div className="flex h-5">
            <TypeBadge type={templateType} />
          </div>
        )}
      </div>
    </TemplateItemWrapper>
  );
};
