import { t } from "@/i18n-js/instance";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { LiveEventsSelector } from "./shared/LiveEventsSelector";
import { PostsEventResource } from "./shared/PostsEventResource";

export const memberAttendedLiveEvent: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_attended_live_event.title",
  ),
  value: "community_member_attended_live_event",
  group: t("settings.workflows.form.event_groups.events"),
  description: t(
    "settings.workflows.form.events.community_member_attended_live_event.description",
  ),
  resource: TRIGGER_RESOURCE_TYPE.EVENT,
  formComponent: LiveEventsSelector,
  viewComponent: PostsEventResource,
  icon: "event",
  released: true,
};
