import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { SCHEDULE_TYPE } from "../../constants";

export const ScheduleType = ({ scheduleType }) => {
  const i18nRoot = "settings.workflows.schedule_types";

  const typeMap = {
    [SCHEDULE_TYPE.ONE_TIME]: t(`${i18nRoot}.one_time`),
    [SCHEDULE_TYPE.RECURRING]: t(`${i18nRoot}.recurring`),
  };
  return (
    <span className="flex min-h-[1.5rem] items-center gap-2">
      <span className="break-normal">{typeMap[scheduleType]}</span>
    </span>
  );
};

ScheduleType.propTypes = {
  scheduleType: PropTypes.string,
};
