import { t } from "@/i18n-js/instance";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export interface AddOptionsProps {
  position?: number;
  isAdmin: boolean;
  canCreateSpaces: boolean;
  onCreateSpace: () => void;
  onCreateSpaceGroup: (position?: number) => void;
  onToggle: (isOpen: boolean) => void;
}

export const AddOptions = ({
  position,
  isAdmin,
  canCreateSpaces,
  onCreateSpace,
  onCreateSpaceGroup,
  onToggle,
}: AddOptionsProps) => {
  if (!isAdmin && !canCreateSpaces) {
    /* Neither of the options are visible */
    return null;
  }

  const handleCreateSpaceGroup = () => {
    onCreateSpaceGroup(position);
  };

  return (
    <BlockInViewOnlyMode>
      <Dropdown
        className="flex items-center"
        buttonClassName="flex py-0.5 w-5 h-5 rounded hover:bg-black/10 focus-visible:bg-black/10 transition-colors"
        menuButtonEl="button"
        buttonLabel={t("community_sidebar.add_space_or_space_group")}
        button={
          <Icon
            type="16-add-new"
            size={16}
            aria-hidden="true"
            useWithFillCurrentColor
          />
        }
        onToggle={onToggle}
        appendTo={document.body}
        direction="bottom-start"
        data-testid="spacegroup-add-wrapper"
      >
        {canCreateSpaces && (
          <Dropdown.ItemWithLink onClick={onCreateSpace}>
            {t("add_space")}
          </Dropdown.ItemWithLink>
        )}

        {isAdmin && (
          <Dropdown.ItemWithLink onClick={handleCreateSpaceGroup}>
            {t("add_space_group")}
          </Dropdown.ItemWithLink>
        )}
      </Dropdown>
    </BlockInViewOnlyMode>
  );
};
