import { t } from "@/i18n-js/instance";
import { useTrialBanner } from "@/react/components/Layout/TrialBanner/useTrialBanner";
import { planSettingsPath } from "@/react/helpers/urlHelpers";
import { useEnterpriseOfferEnabled } from "@/react/hooks/useEnterpriseOfferEnabled";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";

export const TrialOffer = () => {
  const {
    isCommunityOnTrial,
    trialDaysRemainingInWords,
    trialEndDateFormatted,
  } = useTrialBanner();
  const {
    isEnterpriseOfferEnabled,
    enterprisePriceIncreaseDate,
    savingsAmount,
  } = useEnterpriseOfferEnabled();

  return (
    <>
      {isCommunityOnTrial && (
        <div>
          <div>
            {isEnterpriseOfferEnabled ? (
              <SanitizeHTMLString
                content={t("trial_banner.enterprise_offer", {
                  trialDaysRemainingInWords,
                  trialEndDateFormatted,
                  enterprisePriceIncreaseDate,
                  savingsAmount,
                  href: planSettingsPath(),
                })}
              />
            ) : (
              <>
                {t("trial_banner.trial_ends_in", {
                  trialDaysRemainingInWords,
                  trialEndDateFormatted,
                })}
                <a
                  className="hover:!text-badge text-badge ml-1 cursor-pointer underline"
                  href={planSettingsPath()}
                >
                  {t("trial_banner.upgrade_plan")}
                </a>{" "}
                {t("trial_banner.before_trial_expires")}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
