import { t } from "@/i18n-js/instance";
import { AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../../types";
import { FormSendEmail } from "./FormSendEmail";
import type { HistoryDetailViewEmailProps } from "./HistoryDetailViewEmail";
import { HistoryDetailViewEmail } from "./HistoryDetailViewEmail";
import { ViewSendEmail } from "./ViewSendEmail";

export const sendEmail: Readonly<ActionOption<HistoryDetailViewEmailProps>> = {
  label: t("settings.workflows.form.actions.send_email"),
  textLabel: t("settings.workflows.form.actions.send_email"),
  value: "send_email",
  group: t("settings.workflows.form.action_groups.communication"),
  leftAddon: <Icon type="16-email" size={16} className="!h-4 !w-4" />,
  formComponent: FormSendEmail,
  viewComponent: ViewSendEmail,
  historyDetailViewComponent: HistoryDetailViewEmail,
  icon: "email",
  isApplicableToMembersOnly: false,
  availablePlanTier: AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS.MH_ONLY,
} as const;
