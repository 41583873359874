import { useQuery } from "react-query";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";
import type {
  ContentSpecAccessDetails,
  ContentSpecType,
} from "@circle-react/types/AccessRegistry";
import { contentSpecTypes } from "@circle-react/types/AccessRegistry";

const baseQueryKey = "access_registry_content_spec";

const routesByContentSpecType = {
  [contentSpecTypes.Space]: internalApi.accessRegistry.spaces,
  [contentSpecTypes.SpaceGroup]: internalApi.accessRegistry.spaceGroups,
  [contentSpecTypes.AccessGroup]: internalApi.accessRegistry.accessGroups,
};

export const useContentSpecAccessDetails = <T extends ContentSpecAccessDetails>(
  contentSpecType: ContentSpecType,
  contentSpecId: number,
  enabled = true,
) => {
  const contentSpecRoutes = routesByContentSpecType[contentSpecType];
  return useQuery<T, ApiError>(
    [baseQueryKey, contentSpecType, contentSpecId],
    () => reactQueryGet(contentSpecRoutes.show(contentSpecId)),
    {
      enabled,
    },
  );
};
