import { useHistory } from "react-router-dom";
import {
  getQueryParam,
  removeQueryParam,
  updateOrRemoveQueryParam,
} from "@circle-react/helpers/urlHelpers";

const QUERY_PARAM = "topics";

export const useFilters = () => {
  const history = useHistory();

  const currentFilters = new Set(
    getQueryParam(QUERY_PARAM)
      ?.split(",")
      .filter(topic => topic !== ""),
  );

  const AddOrRemoveFilter = (topic: string) => {
    // ToDo, add flag to activate multiple or single filter
    if (currentFilters.has(topic)) {
      currentFilters.delete(topic);
    } else {
      currentFilters.clear(); // Remove this line to allow multiple filters
      currentFilters.add(topic);
    }
    const newQueryParams = updateOrRemoveQueryParam(
      QUERY_PARAM,
      Array.from(currentFilters).join(","),
    );
    history.push(`?${newQueryParams}`);
  };

  const clearFilters = () => {
    const newQueryParams = removeQueryParam(QUERY_PARAM);
    history.push(`?${newQueryParams}`);
  };

  const isFilterSelected = (topic: string) => currentFilters.has(topic);

  return { currentFilters, AddOrRemoveFilter, clearFilters, isFilterSelected };
};
