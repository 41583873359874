import { useState } from "react";
import type { Cell } from "@tanstack/react-table";
import { isEmpty, noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { FormWrapper } from "@/react/components/SettingsApp/Layout/FormWrapper";
import { TypeBadge } from "@/react/pages/BotBuilder/KnowledgePage/TypeBadge";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { Button } from "@circle-react-uikit/Button";
import { EmptyState } from "@circle-react-uikit/EmptyState";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { Form } from "../../shared/uikit/Form";
import { useAddContentModal } from "./AddContentModal";
import { useBulkDeleteSnippetModal } from "./BulkDeleteSnippetModal";
import { DropdownActions } from "./DropdownActions";
import { SnippetCheckboxCell } from "./SnippetCheckboxCell";
import { SnippetCheckboxColumnHeader } from "./SnippetCheckboxColumnHeader";
import { SnippetsList } from "./SnippetsList";
import { useIndexSnippets } from "./snippetsQueries";

const columns = (
  selectedIds: number[],
  handleCheckboxChange: (id: number) => void,
  handleSelectAllOnPage: (checked: boolean) => void,
  allIdsOnPage: number[],
) => [
  {
    header: (
      <SnippetCheckboxColumnHeader
        allOnPageSelected={
          allIdsOnPage.length > 0 &&
          allIdsOnPage.every(id => selectedIds.includes(id))
        }
        handleSelectAllOnPage={handleSelectAllOnPage}
      />
    ),
    accessorKey: "checkbox",
    cellClassName: "align-middle gap-0 !px-0",
    thClassName: "!px-0 w-4",
    canSort: false,
    cell: ({ row }: any) => (
      <SnippetCheckboxCell
        row={row}
        handleCheckboxChange={handleCheckboxChange}
        selectedIds={selectedIds}
      />
    ),
  },
  {
    header: (
      <span className="text-xxs text-default font-semibold uppercase leading-tight tracking-wide">
        {t("settings.community_bot.knowledge.custom.columns.title")}
      </span>
    ),
    accessorKey: "title",
    cell: ({ row }: any) => (
      <div
        className={classNames({
          "cursor-default":
            row.original?.type ===
            t("settings.community_bot.knowledge.custom.types.file"),
        })}
      >
        <Typography.LabelSm weight="semibold">
          {row.original?.title}
        </Typography.LabelSm>
      </div>
    ),
    canSort: false,
  },
  {
    header: t("settings.community_bot.knowledge.custom.columns.type"),
    accessorKey: "type",
    cell: ({ row }: any) => (
      <TypeBadge type={row.original?.type || "Snippet"} />
    ),
    canSort: false,
  },
  {
    header: t(
      "settings.community_bot.knowledge.custom.columns.used_in_replies",
    ),
    accessorKey: "used_in_replies",
    cell: ({ row }: any) => (
      <Typography.LabelSm weight="regular">
        {row.original?.used_in_replies}
      </Typography.LabelSm>
    ),
    canSort: false,
  },
  {
    accessorKey: "actions",
    header: "",
    cell: ({ row }: SnippetTableCell<unknown>) => (
      <DropdownActions snippet={row.original} />
    ),
  },
];

export interface Snippet {
  id: number;
  title: string;
  used_in_replies: number;
  type: string;
}

type SnippetTableCell<TValue> = Cell<Snippet, TValue>;

export const SnippetsV2 = () => {
  const [currentPage, setPage] = useState(1);
  const snippetsQuery = useIndexSnippets({ currentPage });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const allIdsOnPage = snippetsQuery.data?.records?.map(row => row.id) || [];

  const handleCheckboxChange = (id: number) => {
    setSelectedIds(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id],
    );
  };

  const handleSelectAllOnPage = (checked: boolean) => {
    setSelectedIds(prev => {
      const newSelectedIds = new Set(prev);

      if (checked) {
        // Add all items from the current page
        allIdsOnPage.forEach(id => newSelectedIds.add(id));
      } else {
        // Remove items only from the current page
        allIdsOnPage.forEach(id => newSelectedIds.delete(id));
      }

      return Array.from(newSelectedIds);
    });
  };

  const bulkDeleteSnippetModal = useBulkDeleteSnippetModal();
  const addContentModal = useAddContentModal();

  const openAddContentModal = () => {
    void addContentModal.show();
  };

  const openBulkDeleteSnippetModal = () => {
    void bulkDeleteSnippetModal.show({
      selectedIds,
      setSelectedIds,
    });
  };

  const onChangePage = (page: number) => {
    // Forcing useQuery to only cache one page
    setPage(page);
    snippetsQuery.remove();
  };

  const snippetsCount = snippetsQuery?.data?.count || 0;
  const hasNoResults = isEmpty(snippetsQuery?.data?.records || []);

  if (snippetsQuery?.isLoading) {
    return <Loader />;
  }

  if (hasNoResults) {
    return (
      <div className="mt-[20vh]">
        <EmptyState
          header={t(
            "settings.community_bot.knowledge.custom.empty_state.header",
          )}
          description={t(
            "settings.community_bot.knowledge.custom.empty_state.description",
          )}
          primaryLabel={t(
            "settings.community_bot.knowledge.custom.empty_state.primary_label",
          )}
          onPrimaryClick={openAddContentModal}
        />
      </div>
    );
  }

  return (
    <Form name="delete-snippets" onSubmit={noop}>
      {snippetsCount > 0 && (
        <div className="px-6 pb-1 pt-5 lg:px-9">
          <div className="flex min-h-[1.75rem] items-center justify-between gap-4">
            <div className="flex items-center gap-4">
              {selectedIds.length > 0 ? (
                <>
                  <Typography.LabelMd weight="semibold">
                    {t(
                      "settings.community_bot.knowledge.custom.selected_count",
                      {
                        selected_count: selectedIds.length,
                        count: snippetsCount,
                      },
                    )}
                  </Typography.LabelMd>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={openBulkDeleteSnippetModal}
                  >
                    {t(
                      "settings.community_bot.knowledge.custom.delete_resource",
                      {
                        count: selectedIds.length,
                      },
                    )}
                  </Button>
                </>
              ) : (
                <Typography.LabelMd weight="semibold">
                  {t("settings.community_bot.knowledge.custom.count", {
                    count: snippetsCount,
                  })}
                </Typography.LabelMd>
              )}
            </div>
            <Button
              type="button"
              onClick={openAddContentModal}
              variant="circle"
            >
              <Icon
                className="mr-1 align-bottom"
                type="20-plus-v3"
                size={20}
                useWithStrokeCurrentColor
              />
              {t(
                "settings.community_bot.knowledge.custom.add_content_modal.title",
              )}
            </Button>
          </div>
        </div>
      )}
      <DataTable.TableWrapper>
        <FormWrapper>
          <SnippetsList
            columns={columns(
              selectedIds,
              handleCheckboxChange,
              handleSelectAllOnPage,
              allIdsOnPage,
            )}
            snippetsQuery={snippetsQuery}
            onChangePage={onChangePage}
          />
        </FormWrapper>
      </DataTable.TableWrapper>
    </Form>
  );
};
