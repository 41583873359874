import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { useIsMessagingEnabled } from "@circle-react/hooks/useIsMessagingEnabled";
import { Tippy } from "@circle-react-shared/Tippy";
import { Typography } from "@circle-react-uikit/Typography";
import { DMsList } from "./DMsList";

export const DMsDropdown = () => {
  const isMessagingEnabled = useIsMessagingEnabled();
  const history = useHistory();

  return (
    <div className="h-full w-full" data-testid="chat-dropdown">
      <div className="border-primary flex h-16 items-center justify-between border-b px-6">
        <Typography.TitleSm weight="bold">
          {t("sidebar_all_dms")}
        </Typography.TitleSm>
        {isMessagingEnabled && (
          <Tippy content={t("new_message")}>
            <button
              type="submit"
              onClick={() => {
                history.push("/messages/new");
              }}
            >
              <Icon
                type="plus-big"
                className="cursor-pointer"
                data-testid="new-message"
              />
            </button>
          </Tippy>
        )}
      </div>
      <DMsList />
    </div>
  );
};
