import { ViewPriceToggle } from "./Forms/ViewPriceToggleForm";
import { Header } from "./Header";
import { PaywallsEditForm } from "./PaywallsEditForm";
import { PaywallsEditFormProvider } from "./PaywallsEditFormProvider";

interface PaywallsEditProp {
  blockDataPath: string;
}

export const PaywallsEdit = ({ blockDataPath }: PaywallsEditProp) => (
  <PaywallsEditFormProvider blockDataPath={blockDataPath}>
    <div className="flex h-full flex-col gap-y-6">
      <Header />
      <ViewPriceToggle />
      <PaywallsEditForm />
    </div>
  </PaywallsEditFormProvider>
);
