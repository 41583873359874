import type { ComponentPropsWithoutRef } from "react";
import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import type { ShowBroadcastParams } from "@circle-react/api/broadcastsApi";
import { broadcastsApi } from "@circle-react/api/broadcastsApi";
import type {
  BroadcastStatusValue,
  FailureReasonValue,
} from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/constants";
import type { CommunitySegmentRule } from "@circle-react/types/CommunitySegment";
import type { TipTapBlockEditor } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

type TipTapBlockEditorProps = ComponentPropsWithoutRef<
  typeof TipTapBlockEditor
>;

export interface BroadcastContentAttributes {
  subject: string;
  preview_text: string;
  email_content_html: string;
  email_content: {
    body: TipTapBlockEditorProps["value"];
    sgids_to_object_map: TipTapBlockEditorProps["sgidToObjectMap"];
    inline_attachments: TipTapBlockEditorProps["inlineAttachments"];
  };
}

export interface CommunitySegmentAttributes {
  id: number;
  rules: CommunitySegmentRule;
  audience_count: number;
}

export type ScheduleOptions = "send_now" | "schedule_for_later";

export interface BroadcastBuilderFormFields {
  id: number;
  status: BroadcastStatusValue;
  internal_name: string;
  broadcast_content?: BroadcastContentAttributes;
  community_segment?: CommunitySegmentAttributes;
  scheduled_at: string | null;
  send_type: ScheduleOptions | "";
}

interface ResponseData extends BroadcastBuilderFormFields {
  id: number;
  status: BroadcastStatusValue;
  failure_reason: FailureReasonValue;
  updated_at: string;
}

export const getBroadcastQueryKey = (id: number) => ["broadcasts", "show", id];

export const useGetBroadcast = ({
  id,
  ...queryOptions
}: ShowBroadcastParams & UseQueryOptions<ResponseData, unknown>) => {
  const toastr = useToast();

  return useQuery<ResponseData>({
    queryKey: getBroadcastQueryKey(id),
    queryFn: () => broadcastsApi.show({ id }),
    onError: () => toastr.error(t("error")),
    enabled: !!id,
    ...queryOptions,
  });
};
