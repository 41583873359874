import { useEffect } from "react";
import { PostItem } from "@/react/components/PostsV3/PostViewList/PostItem";
import { useDynamicLayoutContext } from "@circle-react/components/Layout/DynamicLayout";
import { PageMetaData } from "@circle-react/components/Layout/PageMetaData";
import { StandardLayout } from "@circle-react/components/Layout/StandardLayout";

export const MinimalPostView = ({ postResource, onPostDestroy }) => {
  const { setMinimalLayout, setStandardLayout } = useDynamicLayoutContext();

  useEffect(() => {
    setMinimalLayout();
    return setStandardLayout;
  }, []);

  return (
    <StandardLayout.Body isMinimalLayout>
      <PageMetaData bodyClassNames="view-shared-post minimal-layout hide-search-bar" />
      <StandardLayout.Content>
        <PostItem
          forcePostView
          isRenderedOutsideTheSpace
          key={postResource.data?.id}
          onPostChange={postResource.onPostChange}
          onPostDestroy={onPostDestroy}
          post={postResource.data}
          pageSupportsContentTruncation={false}
          hideShareLink
          defaultShowComment
        />
      </StandardLayout.Content>
    </StandardLayout.Body>
  );
};
