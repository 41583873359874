import { NavLink } from "react-router-dom";
import type { AdvancedSearchResultMember } from "@/react/components/search/types";
import { communityMemberProfilePath } from "@/react/helpers/urlHelpers";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

export const Member = ({ item }: { item: AdvancedSearchResultMember }) => {
  const path = communityMemberProfilePath({ public_uid: item.public_uid });

  return (
    <NavLink
      to={path}
      className="flex gap-4 pr-6"
      data-testid="second-user-result"
    >
      <UserImage src={item.avatar_url ?? ""} name={item.name} size="16" />
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <Typography.LabelMd weight="semibold">{item.name}</Typography.LabelMd>{" "}
          {item.headline && (
            <Typography.LabelXs color="text-default">
              {item.headline}
            </Typography.LabelXs>
          )}
        </div>
        {item.bio && (
          <div className="line-clamp-4">
            <Typography.LabelSm color="text-selector-active">
              {item.bio}
            </Typography.LabelSm>
          </div>
        )}
        {/*{item.tags && (*/}
        {/*  <div>*/}
        {/*    <MemberTags memberTags={item.tags || []} />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </NavLink>
  );
};
