import { useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { CHAT_NOTIFICATION_TYPES_OPTIONS } from "@/react/components/Spaces/constants";
import { usePunditUserContext } from "@circle-react/contexts";
import type { SpaceType } from "@circle-react/types/Space";
import { Form } from "@circle-react-uikit/Form";

export const NotificationTypePicker = () => {
  const postType: SpaceType = useWatch({ name: "post_type" });

  const { currentCommunitySettings, currentCommunity } = usePunditUserContext();

  const areChatNotificationsEnabled = Boolean(
    currentCommunitySettings?.chat_notification_preferences_enabled,
  );

  const areMobileNotificationsEnabled = Boolean(
    currentCommunity?.ios_app_enabled || currentCommunity?.has_branded_app,
  );

  const hasHideChat = areChatNotificationsEnabled
    ? !areMobileNotificationsEnabled
    : true;

  if (
    (postType == "chat" && hasHideChat) ||
    postType == "course" ||
    postType == "members"
  ) {
    return null;
  }

  const notificationTypeOptions = [
    {
      label: t("spaces.form.create.notification_types.email"),
      description: t(
        `spaces.form.create.notification_by_space_type.${postType}.email_description`,
      ),
      value: "default_notification_setting",
    },
    {
      label: t("spaces.form.create.notification_types.inapp"),
      description: t(
        `spaces.form.create.notification_by_space_type.${postType}.inapp_description`,
      ),
      value: "default_in_app_notification_setting",
    },
    {
      label: t("spaces.form.create.notification_types.mobile"),
      description: t(
        `spaces.form.create.notification_by_space_type.${postType}.mobile_description`,
      ),
      value: "default_mobile_notification_setting",
    },
  ];

  const pickerLabel =
    postType == "chat"
      ? t("spaces.form.create.notification_types.chat_label")
      : t("spaces.form.create.notification_types.label");

  return (
    <Form.Item
      name="notification_type"
      label={pickerLabel}
      hideDescription
      hideBorder
      fullWidth
      variant="extra-bottom-margin"
    >
      {postType == "chat" ? (
        <div className="mt-4">
          <Form.RadioGroup
            variant="radio"
            name="notification_type"
            verticalSpacing="normal"
            options={CHAT_NOTIFICATION_TYPES_OPTIONS}
          />
        </div>
      ) : (
        <Form.CheckboxGroup
          name="notification_type"
          options={notificationTypeOptions}
        />
      )}
    </Form.Item>
  );
};
