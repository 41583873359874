import { useRef } from "react";
import classNames from "classnames";
import { debounce } from "lodash";
import { useBoolean } from "react-use";
import { t } from "@/i18n-js/instance";
import { NavigationMenu } from "@circle-react/components/Events/NewEventsSpace/shared/NavigationMenu";
import { SkeletonLoader } from "@circle-react/components/Spaces/TopicFilters/SkeletonLoader";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { useMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { FilterButton } from "./FilterButton";
import { useActiveFiltersHistory } from "./hooks/useActiveFiltersHistory";
import { useResize } from "./hooks/useResize";
import { useTopics } from "./hooks/useTopics";

interface TopicFiltersProps {
  inline?: boolean;
  isCourseDirectory?: boolean;
}

export const TopicFilters = ({
  inline,
  isCourseDirectory = false,
}: TopicFiltersProps) => {
  const containerRef = useRef(null);
  const moreButtonRef = useRef(null);
  const [shouldShowLeftGradient, setShouldShowLeftGradient] = useBoolean(false);
  const [shouldShowRightGradient, setShouldShowRightGradient] =
    useBoolean(true);
  const { data: space } = useCurrentSpaceContext();
  const { topics: unsortedTopics, isLoading } = useTopics({
    spaceId: space?.id,
    isCourseDirectory,
  });
  const isMd = useMdScreenMediaQuery();
  const {
    topics,
    currentFilters,
    toggleFilter,
    clearFiltersAndHistory,
    isFilterSelected,
  } = useActiveFiltersHistory(unsortedTopics, { sortTopics: isMd });

  const { visibleTopics } = useResize({
    containerRef,
    moreButtonRef,
    topics,
  });

  if (!isLoading && topics.length === 0) {
    return null;
  }

  const calculateVisibility = ({
    scrollLeft,
    scrollWidth,
    clientWidth,
  }: {
    scrollLeft: number;
    scrollWidth: number;
    clientWidth: number;
  }) => {
    setShouldShowLeftGradient(scrollLeft > 0);
    setShouldShowRightGradient(scrollLeft + clientWidth < scrollWidth);
  };

  const debouncedCalculateVisibility = debounce(calculateVisibility, 50);

  const visibleTopicsIds = visibleTopics.map(topic => topic.id);
  const topicsInMenu = topics.filter(
    topic => !visibleTopicsIds.includes(topic.id),
  );

  return (
    <div
      className={classNames("relative", {
        "w-full": inline,
        "-mb-2 mt-2 w-full p-2 sm:-mb-0 sm:mt-6 sm:p-0 md:mb-6 md:mt-0":
          !inline,
        "scrollbar-hide overflow-x-auto": !isMd,
      })}
      ref={containerRef}
    >
      {shouldShowLeftGradient && !isMd && (
        <button
          type="button"
          className="from-secondary absolute left-0 top-0 h-full w-16 bg-gradient-to-r to-transparent"
        />
      )}
      <div
        onScroll={e => {
          const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
          debouncedCalculateVisibility({
            scrollLeft,
            scrollWidth,
            clientWidth,
          });
        }}
        className={classNames("flex w-full gap-x-3 gap-y-2 md:flex-wrap", {
          "scrollbar-hide overflow-x-auto": !isMd,
        })}
        data-testid="topic-filters"
      >
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <>
            <FilterButton
              onClick={clearFiltersAndHistory}
              isActive={currentFilters.size === 0}
              label={t("topics.filters.all_topics")}
            />
            {(isMd ? visibleTopics : topics).map(topic => (
              <FilterButton
                key={topic.id}
                onClick={() => toggleFilter(topic.id.toString())}
                isActive={isFilterSelected(topic.id.toString())}
                label={topic.name}
              />
            ))}

            {isMd && topicsInMenu.length > 0 && (
              <NavigationMenu
                label={t("topics.filters.more")}
                active={topicsInMenu.some(topic =>
                  isFilterSelected(topic.id.toString()),
                )}
                buttonRef={moreButtonRef}
              >
                {topicsInMenu.map(topic => (
                  <NavigationMenu.Button
                    key={topic.id}
                    label={topic.name}
                    onClick={() => toggleFilter(topic.id.toString(), true)}
                    active={isFilterSelected(topic.id.toString())}
                  />
                ))}
              </NavigationMenu>
            )}
          </>
        )}
      </div>
      {shouldShowRightGradient && !isMd && (
        <button
          type="button"
          className="from-secondary absolute right-0 top-0 h-full w-16 bg-gradient-to-l to-transparent"
        />
      )}
    </div>
  );
};
