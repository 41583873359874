import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { useMembersAvatarRow } from "@/react/hooks/members/useMembersAvatarRow";
import { useCustomizeSpaceDrawer } from "@circle-react/components/Drawers/CustomizeSpaceDrawer";
import { usePunditUserContext } from "@circle-react/contexts";
import { useActiveSpaceMembersCount } from "@circle-react/contexts/useActiveSpaceMembersCount";
import type { Space } from "@circle-react/types/Space";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { MembersAvatarRow } from "@circle-react-uikit/MembersAvatarRow";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { useShouldHideMembersCount } from "./useShouldHideMembersCount";

interface AvatarsButtonProps {
  space: Space;
}

export const AvatarsButton = ({ space }: AvatarsButtonProps) => {
  const history = useHistory();
  const { currentCommunityMember } = usePunditUserContext();
  const { members, isLoading, canSeeMembersDirectory } = useMembersAvatarRow({
    spaces: space.slug,
  });
  const { show: showSpaceDrawer } = useCustomizeSpaceDrawer();
  const shouldHideMembersCount = useShouldHideMembersCount(space);

  const { count, isLoading: isLoadingSpaceMembersCount } =
    useActiveSpaceMembersCount({
      spaceId: space.id,
      enabled: !shouldHideMembersCount,
    });

  const goToMembersDirectoryForSpace = () => {
    if (isCommunityAdmin(currentCommunityMember)) {
      return showSpaceDrawer({
        spaceId: space.id,
        initialSection: t("customize_space.members"),
      });
    }
    if (canSeeMembersDirectory) {
      history.push(`/members?filter[spaces]=${space.slug}`);
    }

    return undefined;
  };

  if (shouldHideMembersCount) return null;

  if (isLoadingSpaceMembersCount || isLoading)
    return <SkeletonLoader variant="members-avatar-row" />;

  return (
    <TippyV2 content={t("see_all_members")} disabled={!canSeeMembersDirectory}>
      <button
        type="button"
        disabled={!canSeeMembersDirectory}
        onClick={goToMembersDirectoryForSpace}
        className="focus-visible:outline-secondary rounded-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      >
        <div className="md:hidden">
          <Dropdown.Header>{t("members")}</Dropdown.Header>
        </div>
        <MembersAvatarRow
          members={members}
          amountToShow={3}
          totalCount={count}
        />
      </button>
    </TippyV2>
  );
};
