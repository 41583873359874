import { useMemo } from "react";
import { PaymentMethodType } from "@/react/helpers/paywallCheckoutHelpers";

export const useFilterPaymentMethodsSupported = (
  paymentMethodsSupported: string[],
  disabledPaymentMethods: string[],
): string[] =>
  useMemo(() => {
    const ALLOWED_METHODS_FOR_PAYMENT_ELEMENT: string[] = [
      PaymentMethodType.CARD,
      PaymentMethodType.LINK,
      PaymentMethodType.KLARNA,
      PaymentMethodType.AFTERPAY_CLEARPAY,
      PaymentMethodType.AFFIRM,
    ];

    return paymentMethodsSupported.filter(
      (paymentMethod: string) =>
        ALLOWED_METHODS_FOR_PAYMENT_ELEMENT.includes(paymentMethod) &&
        !disabledPaymentMethods.includes(paymentMethod),
    );
  }, [paymentMethodsSupported, disabledPaymentMethods]);
