import { useFormContext } from "react-hook-form";
import I18n from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";

export default function EventOptions() {
  const { watch } = useFormContext();
  const postType = watch("post_type");
  if (postType !== "event") {
    return null;
  }
  return (
    <Form.Item
      name="event_auto_rsvp_enabled"
      label={I18n.t("spaces.form.event_auto_rsvp_enabled")}
      hideBorder
      hideDescription
      inline
      shouldToggleValueOnLabelClick
    >
      <Form.ToggleSwitch variant="small" />
    </Form.Item>
  );
}
