import classNames from "classnames";
import { compact } from "lodash";
import { t } from "@/i18n-js/instance";
import { isSubscriptionPrice } from "@circle-react/helpers/paywallPriceHelpers";
import { formatPaywallCurrencyAmount } from "@circle-react/helpers/paywalls/currencies";
import { Typography } from "@circle-react-uikit/Typography";
import { usePaywallBlockContext } from "../../../Providers/PaywallBlockProvider";
import { isDesktopPreviewMode } from "../../../Providers/helpers";

const recurringPaymentLocale =
  "paywalls.lock_screen.preview.price.recurring_payment";

const priceTypeLocale = "settings.paywall_prices.price_types";
const freeTrialLocale = "paywalls.lock_screen.preview.price.free_trial";
const oncePaymentLocale = "paywalls.lock_screen.preview.price.once_payment";
const todayPaymentLocale = "paywalls.lock_screen.preview.price.today_payment";
const trialLocale = "paywalls.lock_screen.preview.price.trial";

const scenarios: Record<string, any> = {
  web: {
    trial_then_upfront_then_value: {
      free_trial_label: freeTrialLocale,
      once_payment: oncePaymentLocale,
      recurring_payment: recurringPaymentLocale,
    },
    upfront_then_trial_then_value: {
      once_payment: todayPaymentLocale,
      free_trial_label: trialLocale,
      recurring_payment: recurringPaymentLocale,
    },
    trial_then_value: {
      free_trial_label: freeTrialLocale,
      recurring_payment: recurringPaymentLocale,
    },
    upfront_then_value: {
      once_payment: todayPaymentLocale,
      recurring_payment: recurringPaymentLocale,
    },
    value: {},
  },
  mobile: {
    trial_then_upfront_then_value: {
      free_trial_label: freeTrialLocale,
    },
    // This type of price is not available on In-app purchases
    //upfront_then_trial_then_value: {}
    trial_then_value: {
      free_trial_label: freeTrialLocale,
    },
    // This type of price is not available on In-app purchases
    //upfront_then_value: {},
    value: {},
  },
};

export const Price = ({ price, currency }: any) => {
  const { previewMode } = usePaywallBlockContext();
  const type = isDesktopPreviewMode(previewMode) ? "web" : "mobile";
  const scenarioToRender =
    scenarios[type][price?.scenario] || scenarios[type]["value"];
  const displayAmount = formatPaywallCurrencyAmount(currency, price?.amount, {
    includeCurrencyCode: false,
    includeCurrencySymbol: true,
  });

  const shouldBeInvisible = Object.keys(scenarioToRender).length === 0;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-x-1">
        <Typography.TitleXl size="text-4xl">{displayAmount}</Typography.TitleXl>
        <Typography.LabelSm
          color="text-default"
          textTransform="lowercase"
        >{`/ ${t([
          priceTypeLocale,
          "when_advertising",
          price.interval,
          price.interval_count,
        ])}`}</Typography.LabelSm>
      </div>
      <div
        className={classNames("flex flex-col gap-y-2", {
          invisible: shouldBeInvisible,
        })}
      >
        <Typography.LabelXs color="text-default">
          {compact(
            Object.keys(scenarioToRender).map(key => scenarioToRender[key]),
          )
            .map((localeString: string) =>
              t(localeString, {
                once_payment_amount: formatPaywallCurrencyAmount(
                  currency,
                  price?.upfront_payment_amount,
                ),
                recurring_payment_amount: formatPaywallCurrencyAmount(
                  currency,
                  price?.amount,
                ),
                interval_qualifier: t([
                  priceTypeLocale,
                  "as_adverb",
                  price.interval,
                  price.interval_count,
                ]).toLowerCase(),
                count: price?.trial_days,
              }),
            )
            .join(t("paywalls.lock_screen.preview.price.separator")) || "."}
        </Typography.LabelXs>
      </div>
    </div>
  );
};

const PriceSubscription = Object.assign(Price, {
  validateToRender: (price: any) => isSubscriptionPrice(price),
});

export { PriceSubscription };
