import type { NiceModalHandler } from "@ebay/nice-modal-react";
import { isFunction } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { marketingEmailAddressSettingsApi } from "@circle-react/api/marketingEmailAddressSettingsApi";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { I18N_ROOT } from "../components/BroadcastSettings/constants";
import type { MarketingEmailAddressSettingsAttributes } from "../components/BroadcastSettings/interfaces";
import { MARKETING_EMAIL_ADDRESS_SETTINGS_KEY } from "./useGetMarketingEmailAddressSettings";

interface UseUpsertMarketingEmailAddressSettingsProps {
  modal?: NiceModalHandler;
  onSuccess?: () => void;
}

export const useUpsertMarketingEmailAddressSettings = ({
  modal,
  onSuccess,
}: UseUpsertMarketingEmailAddressSettingsProps) => {
  const queryClient = useQueryClient();
  const toastr = useToast();

  return useMutation<
    MarketingEmailAddressSettingsAttributes,
    unknown,
    MarketingEmailAddressSettingsAttributes
  >({
    mutationFn: payload => marketingEmailAddressSettingsApi.upsert(payload),
    onSuccess: () => {
      toastr.success(t([I18N_ROOT, "settings_updated"]));
      void queryClient.invalidateQueries(MARKETING_EMAIL_ADDRESS_SETTINGS_KEY);
      modal?.remove();
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    },
    onError: () => {
      toastr.error(t("unexpected_error"));
    },
  });
};
