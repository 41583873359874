import { usePunditUserContext } from "@circle-react/contexts";

export const useIsAccessGroupsWorkflowsEnabled = (): boolean => {
  const { currentCommunitySettings } = usePunditUserContext();

  return Boolean(
    currentCommunitySettings?.access_groups_enabled &&
      currentCommunitySettings?.access_groups_workflows_enabled,
  );
};
