import { useModal } from "@ebay/nice-modal-react";
import { t } from "@/i18n-js/instance";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { useUpdateAgent } from "@circle-react/components/SettingsApp/CommunityBot/agentsQueries";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export const EnableModalComponent = ({ agent }: { agent: AgentProp }) => {
  const { mutate: updateAgent } = useUpdateAgent({ id: agent.id });
  const modal = useModal();

  return (
    <ConfirmationModal
      isOpen={modal.visible}
      onClose={modal.hide}
      confirmVariant="circle"
      onConfirm={() => {
        updateAgent({
          enabled: true,
        });
        void modal.hide();
      }}
      title={t("community_bot.bot_builder.enable_modal.title", {
        name: agent.name,
      })}
      confirmText={t("community_bot.bot_builder.enable_modal.enable")}
    >
      {t("community_bot.bot_builder.enable_modal.description")}
    </ConfirmationModal>
  );
};
