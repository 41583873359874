import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface RangeTabProps {
  label: string;
  queryParams: string;
}

export const RangeTab = ({ label, queryParams }: RangeTabProps) => {
  const location = useLocation();
  const isActive = location.search === queryParams;

  return (
    <NavLink
      to={`/leaderboard/${queryParams}`}
      className={classNames(
        "bg-primary border-primary hover:bg-tertiary focus-visible:outline-secondary text-darkest max-w-[200px] shrink-0 truncate rounded-full border px-4 py-2 text-sm transition-colors duration-75 focus-visible:rounded-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        {
          "border-secondary": isActive,
        },
      )}
    >
      <Typography.LabelXs weight="medium">{label}</Typography.LabelXs>
    </NavLink>
  );
};
