import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const LandingFooter = () => (
  <div className="border-primary flex w-full flex-col items-center rounded-lg border p-14">
    <Typography.TitleXl weight="font-bold" color="text-dark">
      {t("leaderboard.landing_page_title")}
    </Typography.TitleXl>
    <div className="mt-2 text-center">
      <Typography.TitleSm color="text-dark">
        {t("leaderboard.description")}
      </Typography.TitleSm>
    </div>
    <Link to="/settings/gamification">
      <Button large className="mt-8" variant="circle">
        {t("leaderboard.activate")}
      </Button>
    </Link>
  </div>
);
