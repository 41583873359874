import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { displayPaywallPrice } from "@circle-react/helpers/paywallPriceHelpers";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";

export const PaywallsDropdown = ({
  paywalls,
  onClick,
  isLoading,
  appendTo,
}) => {
  const history = useHistory();
  const { onExit } = useCustomizeUIData();

  const onAddPaywallClick = () => {
    history.push("/settings/paywalls");
    onExit();
  };

  return (
    <div className="mt-4 lg:mt-7">
      <Dropdown
        size="full"
        direction="bottom"
        className="w-full"
        buttonClassName="flex justify-between px-4 w-full rounded-lg border border-primary h-11 items-center"
        menuWrapperClassName="px-0 mt-1"
        popperOptions={{
          strategy: "absolute",
        }}
        button={
          <>
            <Typography.LabelMd>
              {t("customize_space.paywalls.link_paywall")}
            </Typography.LabelMd>
            <Icon
              size={16}
              type="caret-down-bold"
              useWithFillCurrentColor
              className="text-dark -mr-1.5"
            />
          </>
        }
        appendTo={appendTo}
      >
        {isLoading ? (
          <Dropdown.Item>
            <Loader center />
          </Dropdown.Item>
        ) : (
          <div className="max-h-40 overflow-scroll">
            {!paywalls.length && (
              <div className="px-4 py-2">
                <Dropdown.Item>
                  <Typography.LabelSm>
                    {t("customize_space.paywalls.no_paywalls")}
                  </Typography.LabelSm>
                </Dropdown.Item>
              </div>
            )}

            {paywalls.map(paywall => (
              <Dropdown.ItemLink
                key={paywall.id}
                onClick={() => onClick(paywall)}
                disabled={isLoading}
              >
                {paywall.display_name} - {displayPaywallPrice(paywall)}
              </Dropdown.ItemLink>
            ))}
          </div>
        )}

        <Dropdown.ItemLink
          className="hover:bg-secondary hover:text-dark flex w-full items-center gap-2.5 px-4 py-2"
          onClick={onAddPaywallClick}
        >
          <span className="flex">
            <Icon type="16-plus-v2" size={16} className="text-dark w-4" />
          </span>
          <Typography.LabelSm>
            {t("customize_space.paywalls.create_paywall")}
          </Typography.LabelSm>
        </Dropdown.ItemLink>
      </Dropdown>
    </div>
  );
};

PaywallsDropdown.propTypes = {
  paywalls: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  appendTo: PropTypes.node,
};
