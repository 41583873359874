import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { TiptapEditor } from "@circle-react-uikit/TipTap";
import { Avatar } from "../../../Avatar";
import { Badge } from "../../../Badge";
import { BaseView } from "../../BaseView";

const i18nRoot = "settings.workflows.view";

export const ViewDM = ({ action }) => {
  const { api_params: apiParams } = action || {};
  const { from, rich_text_body: richTextBody } = apiParams || {};

  if (!from || !richTextBody) return null;

  return (
    <BaseView>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "from"])}</BaseView.Title>
        <div>
          <Avatar communityMemberId={from} />
        </div>
      </BaseView.Section>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "to"])}</BaseView.Title>
        <div>
          <Badge
            icon={
              <Icon
                type="12-lightning"
                size={12}
                className="!text-default !h-3 !w-3"
              />
            }
            text={t("settings.workflows.view.initiator")}
          />
        </div>
      </BaseView.Section>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "message_body"])}</BaseView.Title>
        <div className="text-default text-sm">
          <TiptapEditor
            type="workflow"
            key={uniqueId("tiptap_")}
            rich_text_body={{
              body: richTextBody.body,
              sgids_to_object_map: richTextBody.localSgidToObjectMap,
            }}
            editable={false}
          />
        </div>
      </BaseView.Section>
    </BaseView>
  );
};

ViewDM.propTypes = {
  action: PropTypes.object,
  hasSeparator: PropTypes.bool,
};
