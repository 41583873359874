import { useHistory } from "react-router-dom";
import { MEMBER_EDIT_PATHS } from "../constants";

export const useAccessGroupRedirections = () => {
  const history = useHistory();

  const redirectToEditModal = () => {
    history.push(MEMBER_EDIT_PATHS.MEMBERS);
  };

  const redirectToAddMembers = () => {
    history.push(MEMBER_EDIT_PATHS.ADD_MEMBERS);
  };

  return {
    redirectToEditModal,
    redirectToAddMembers,
  };
};
