import { t } from "@/i18n-js/instance";
import { botBuilderNavPaths } from "@circle-react/helpers/urlHelpers/botBuilderNavPaths";
import { DataTable } from "@circle-react-uikit/DataTableV2";

export const AgentsDropdownActions = ({ agent }: { agent: any }) => {
  const handleEditAgent = (agentId: number) => {
    window.location.href = botBuilderNavPaths.customize({
      botPublicId: agentId.toString(),
    });
  };

  return (
    <DataTable.Actions>
      <DataTable.Actions.Item onClick={() => handleEditAgent(agent.id)}>
        {t("settings.community_bot.knowledge.custom.edit")}
      </DataTable.Actions.Item>
    </DataTable.Actions>
  );
};
