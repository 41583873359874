import { usePaywallsEditFormContext } from "../../PaywallsEditFormProvider";
import { PaywallsEmptyState } from "./PaywallsEmptyState";
import { WebPaywallPricesEmptyState } from "./WebPaywallPricesEmptyState";

export const EmptyState = ({ children }: any) => {
  const { hasPaywalls, hasWebPaywallPrices } = usePaywallsEditFormContext();

  if (hasPaywalls && !hasWebPaywallPrices) {
    return <WebPaywallPricesEmptyState />;
  }

  if (!hasPaywalls && !hasWebPaywallPrices) {
    return <PaywallsEmptyState />;
  }

  return <>{children}</>;
};
