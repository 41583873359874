import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-shared/uikit/Form";

export const SubscriptionGroupSelect = ({
  isSelectDisabled,
  paywallGroupSelectOptions,
  className = "",
}: any) => {
  const { watch } = useFormContext();
  const paywallGroupIdSelected = watch("paywall_group_id");

  return (
    <Form.Item
      className={`!py-0 ${className}`}
      labelClassName="!font-normal"
      name="paywall_group_id"
      hideDescription
      hidePlaceholder
      hideBorder
      hideLabel
      isDisabled={isSelectDisabled}
    >
      <Form.SelectNative
        prompt={t("settings.paywalls.modal.tabs.pricing.paywall_group_prompt")}
        value={paywallGroupIdSelected}
        name="paywall_group_id"
        options={paywallGroupSelectOptions}
        disabled={isSelectDisabled}
        rules={{
          shouldUnregister: true,
        }}
      />
    </Form.Item>
  );
};
