import { useMutation } from "react-query";
import { accessGroupMembersApi } from "@circle-react/api/admin/accessGroups/accessGroupMembersApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { manageTaskProcessTypes } from "@circle-react/helpers/accessGroups/manageTaskHelper";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { useAccessGroupData } from "../AccessGroupMembers/DataStoreContext/AccessGroupDataContext";

interface ResponseData {
  community_member_ids: number;
}

interface ManageAccessGroupMembersProps {
  closeConfirmationModal: () => void;
}

export const useRemoveAccessGroupMembers = ({
  closeConfirmationModal,
}: ManageAccessGroupMembersProps) => {
  const {
    selectedMemberIds,
    filtersToProcessMembers,
    accessGroup,
    setShouldBlockActions,
    clearSelectedMemberIds,
  } = useAccessGroupData();

  const toastr = useToast();
  const { manageAccessGroupMembers } = accessGroupMembersApi();

  return useMutation<ResponseData, ApiError>({
    mutationFn: () =>
      manageAccessGroupMembers({
        id: accessGroup.id,
        params: {
          community_member_ids: selectedMemberIds,
          process_type: manageTaskProcessTypes.REMOVE,
          filters:
            selectedMemberIds.length > 0 ? undefined : filtersToProcessMembers,
        },
      }),
    onSuccess: () => {
      clearSelectedMemberIds();
      setShouldBlockActions(true);
      closeConfirmationModal();
    },
    onError: (error: any) => toastr.error(error.message),
  });
};
