import { useHistory, useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";
import { SendUsAMessageForm } from "../../Form";
import { HeaderIconButton } from "../../HeaderIconButton";
import { useSupportWidgetContext } from "../../SupportWidgetContext";
import { SEND_MESSAGE_FORM_DEFAULT_VALUES } from "../../constants";
import { useZendeskUploads } from "../../hooks/useZendeskUploads";
import { useCreateConversation } from "../../queries";
import { CREATE_CONVERSATION_SCHEMA } from "../../schemas";
import { getLocalStorage, setLocalStorage } from "../../utils";

export const CreateConversationView = () => {
  const history = useHistory();
  const location = useLocation();
  const toastr = useToast();
  const { uploadAttachments, attachments, cancelAttachment } =
    useZendeskUploads({
      controlName: "conversation-create",
      cachingEnabled: true,
    });
  const { setIsDraftConversationPresent } = useSupportWidgetContext();
  const { mutate: createSupportWidgetConversation, isLoading } =
    useCreateConversation({
      attachments,
      source: location.state?.source,
      chatBotSessionId: location.state?.chatBotSessionId,
      onSuccess: ticket => {
        const currentLocalStorage = getLocalStorage();
        delete currentLocalStorage["conversation-create-attachments"];
        delete currentLocalStorage["formValues"];
        setLocalStorage(currentLocalStorage);
        setIsDraftConversationPresent(false);

        toastr.success(t("support_widget.conversation_created_toast"));
        history.push(`/conversations/${ticket.id}`, {
          from: "CreateConversationView",
        });
      },
      onError: () => {
        toastr.error(t("request_failure_message"));
      },
    });

  const defaultValues = {
    ...SEND_MESSAGE_FORM_DEFAULT_VALUES,
    ...getLocalStorage().formValues,
  };

  return (
    <div className="flex flex-col" id="send-message-form-container">
      <div className="border-primary bg-primary sticky top-0 z-10 flex h-[52px] shrink-0 items-center justify-start space-x-4 border-b px-3">
        <HeaderIconButton type="back" onClick={() => history.replace("/")} />
        <span className="flex-1 text-center">
          <Typography.LabelMd weight="semibold" color="text-dark">
            {isLoading
              ? t("support_widget.sending_message")
              : t("support_widget.send_a_message")}
          </Typography.LabelMd>
        </span>
        <HeaderIconButton type="expand" />
      </div>
      <div className="grow overflow-y-auto">
        <Form
          preventAccidentalLeave
          validationSchema={CREATE_CONVERSATION_SCHEMA}
          id="send-message-form"
          onSubmit={createSupportWidgetConversation}
          mode="onSubmit"
          defaultValues={defaultValues}
        >
          <SendUsAMessageForm
            isLoading={isLoading}
            attachments={attachments}
            uploadAttachments={uploadAttachments}
            cancelAttachment={cancelAttachment}
          />
        </Form>
      </div>
    </div>
  );
};
