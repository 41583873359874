import classNames from "classnames";
import { SortableElement } from "react-sortable-hoc";
import { t } from "@/i18n-js/instance";
import { useAddLinkModal } from "@circle-react/components/Modals/AddLinkModal";
import { Icon } from "@circle-react/components/shared/Icon";
import { Dropdown } from "@circle-react/components/shared/uikit/Dropdown";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { DeleteLinkConfirmation } from "./DeleteLinkConfirmation";

interface LinkItemProps {
  link: any;
  isAdmin?: boolean;
}

export const LinkItem = SortableElement(({ link, isAdmin }: LinkItemProps) => {
  const [shouldShowDeleteConfirmation, toggleDeleteConfirmation] =
    useBoolean(false);
  const [isDropdownClosed, toggleIsDropdownClosed] = useBoolean(false);
  const canHover = useAnyHoverMediaQuery();
  const addLinkModal = useAddLinkModal();

  return (
    <>
      <a
        key={link.url}
        href={link.url}
        target="_blank"
        rel="noreferrer"
        draggable="false"
        title={link.name}
        className="text-c-sidebar hover:text-c-sidebar focus:text-c-sidebar active:text-c-sidebar hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover text-xs-plus h-8.5 group !m-0 flex w-full items-center gap-2 rounded-lg px-4 font-medium transition-colors duration-200"
      >
        <span className="flex !h-5 !w-5 items-center justify-center">
          <Icon type="16-external-link" className="!h-4 !w-4" size={16} />
        </span>
        <h4 className="text-xs-plus flex-1 truncate font-medium text-current">
          {link.name}
        </h4>

        <button
          type="button"
          className={classNames("flex gap-0.5", {
            "hidden group-hover:flex": canHover && isDropdownClosed,
          })}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
          aria-label={t("community_sidebar.view_sidebar_link_options")}
        >
          {isAdmin && (
            <Dropdown
              buttonClassName="flex py-0.5 w-5 h-5 rounded hover:bg-black/10 transition-colors"
              button={<Icon type="16-menu-dots-horizontal" size={16} />}
              onToggle={toggleIsDropdownClosed}
              appendTo={document.body}
            >
              <Dropdown.ItemWithLink
                onClick={() => {
                  void addLinkModal.show({ isEdit: true, link });
                }}
              >
                {t("edit")}
              </Dropdown.ItemWithLink>
              <Dropdown.ItemWithLink onClick={toggleDeleteConfirmation}>
                {t("delete")}
              </Dropdown.ItemWithLink>
            </Dropdown>
          )}
        </button>
      </a>
      <DeleteLinkConfirmation
        link={link}
        isOpen={shouldShowDeleteConfirmation}
        onClose={toggleDeleteConfirmation}
      />
    </>
  );
});
