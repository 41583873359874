import { t } from "@/i18n-js/instance";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface ArchiveAccessGroupWithRunningEntryPointsProps {
  accessGroup: AccessGroupType;
  isOpen: boolean;
  onClose: () => void;
}
const i18nBase = "settings.access_groups.admin.form.archive";
export const ArchiveAccessGroupWithRunningEntryPoints = ({
  accessGroup,
  isOpen,
  onClose,
}: ArchiveAccessGroupWithRunningEntryPointsProps) => (
  <ConfirmationModal
    title={t([i18nBase, "title"], { name: accessGroup.name })}
    isOpen={isOpen}
    onClose={onClose}
    disabled
    onConfirm={() => {}}
    confirmText={t([i18nBase, "action"])}
    confirmVariant="danger"
  >
    <>
      {t([i18nBase, "remove_entry_points_body"])}

      <div className="mt-4">
        <Typography.LabelMd weight="semibold">
          {t([i18nBase, "entry_points"])}
        </Typography.LabelMd>
      </div>
      {accessGroup?.running_sources
        ?.map(source => source.record_display_name)
        ?.filter(name => name)
        ?.join(", ")}
    </>
  </ConfirmationModal>
);
