import { useQuery } from "react-query";
import { accessGroupsApi } from "@circle-react/api/admin/accessGroups/accessGroupsApi";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";

const baseQueryKey = ["access_groups", "show"];

export const useAccessGroup = (accessGroupId: number) => {
  const { showAccessGroup } = accessGroupsApi();

  const queryKey = [...baseQueryKey, accessGroupId];
  const { data: accessGroup, isLoading: isLoadingAccessGroup } =
    useQuery<AccessGroupType>(queryKey, () => showAccessGroup(accessGroupId));

  return {
    queryKey,
    accessGroup,
    isLoadingAccessGroup,
  };
};
