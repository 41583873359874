import { t } from "@/i18n-js/instance";
import { useTrialBanner } from "@/react/components/Layout/TrialBanner/useTrialBanner";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { scrollToThread } from "./helpers";

interface UnreadThreadIndicatorButtonProps {
  unreadChatThreadIds: number[];
  isTopButton?: boolean;
}

export const UnreadThreadIndicatorButton = ({
  unreadChatThreadIds,
  isTopButton = false,
}: UnreadThreadIndicatorButtonProps) => {
  const { shouldShowTrialBanner } = useTrialBanner();

  const unreadCount = unreadChatThreadIds.length;
  if (unreadCount === 0) return null;
  return (
    <div
      className={classNames(
        "sticky left-0 z-10 flex w-full items-center justify-center",
        {
          "top-20": isTopButton && shouldShowTrialBanner,
          "top-[74px]": isTopButton && !shouldShowTrialBanner,
          "bottom-6": !isTopButton,
        },
      )}
    >
      <button
        type="submit"
        className="bg-circle rounded-2xl px-2.5 py-1 text-xs font-semibold leading-5 text-white hover:opacity-90"
        onClick={() => scrollToThread(unreadChatThreadIds[0])}
      >
        {unreadCount === 1
          ? t(isTopButton ? "unread_thread_at_top" : "unread_thread")
          : t(isTopButton ? "unread_threads_at_top" : "unread_threads", {
              unread_count: unreadCount,
            })}
      </button>
    </div>
  );
};
