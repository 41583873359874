import { useBoolean } from "react-use";
import { useBroadcastReport } from "@circle-react-shared/Emails/BroadcastReport/BroadcastReportContext";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { EmailInlineStats } from "./EmailInlineStats";
import { FullReportsModal } from "./FullReportsModal";
import { ViewEmailDetails } from "./ViewEmailDetails";

export function ViewEmailWrapper() {
  const { isLoading } = useBroadcastReport();
  const [isFullReportsOpen, toggleIsFullReportsOpen] = useBoolean(false);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col gap-y-6">
      <ViewEmailDetails />
      <EmailInlineStats />
      <FullReportsModal
        isOpen={isFullReportsOpen}
        onClose={toggleIsFullReportsOpen}
      />
    </div>
  );
}
