import { t } from "@/i18n-js/instance";
import { useCreateLiveModal } from "@circle-react/components/Modals/CreateLiveModal";
import { EmptyState } from "@circle-react-uikit/EmptyState";

export const LiveEmptyState = () => {
  const liveModal = useCreateLiveModal();

  return (
    <EmptyState
      header={t("live_streams.dashboard.table.no_live_records")}
      description={t("live_streams.dashboard.table.engage_with_live")}
      primaryLabel={t("live_streams.now_live.go_live")}
      onPrimaryClick={() => liveModal.show()}
    />
  );
};
