import { useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { t } from "@/i18n-js/instance";
import { usePaymentMethodApi } from "@/react/hooks/paywalls/usePaymentMethodApi";
import { useToast } from "@circle-react-uikit/ToastV2";

export interface UsePaymentMethodFormProps {
  CardElement: any;
  currency: any;
  onSuccess?: () => void;
  paymentMethodsRegistrableByCurrency?: any;
}

export const usePaymentMethodForm = ({
  CardElement,
  currency,
  onSuccess,
  paymentMethodsRegistrableByCurrency,
}: UsePaymentMethodFormProps) => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const { success, error } = useToast();

  const { createPaymentMethodMutation, createSetupIntentMutation } =
    usePaymentMethodApi();

  const [isCardInfoComplete, setIsCardInfoComplete] = useState(false);
  const [isConfirmingCard, setIsConfirmingCard] = useState(false);
  const isButtonDisabled = !isCardInfoComplete || isConfirmingCard;

  const handleOnChangeCard = (event: any) => {
    if (event.complete) {
      setIsCardInfoComplete(true);
    } else if (isCardInfoComplete) setIsCardInfoComplete(false);
  };

  const defaultValues = {
    currency_id: currency?.id,
  };

  const setupPaymentMethodOnStripeAndReturnId = async (
    clientSecret: string,
  ) => {
    const { setupIntent, error: confirmationError } =
      await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

    if (confirmationError) {
      throw new Error(confirmationError.message);
    }
    return setupIntent.payment_method;
  };

  const createPaymentMethod = async ({ currencyId }: any) => {
    const setupIntentResponse = await createSetupIntentMutation.mutateAsync({
      currency_id: currencyId,
    });
    const paymentMethodProcessorId =
      await setupPaymentMethodOnStripeAndReturnId(
        setupIntentResponse.client_secret,
      );
    await createPaymentMethodMutation.mutateAsync({
      currency_id: currencyId,
      payment_method_processor_id: paymentMethodProcessorId,
    });
  };

  const currenciesOptions = currency
    ? [
        {
          value: currency.id,
          label: currency.code.toUpperCase(),
        },
      ]
    : paymentMethodsRegistrableByCurrency.map((e: any) => ({
        value: e.id,
        label: e.code.toUpperCase(),
      }));

  const handleOnClick = async (data: any) => {
    setIsConfirmingCard(true);
    try {
      await createPaymentMethod({ currencyId: data.currency_id });

      onSuccess && onSuccess();

      success(
        t(
          "member_profile_modal.billing.subscriptions.alerts.success.payment_created",
        ),
      );
    } catch (processError: any) {
      error(processError.message);
    } finally {
      setIsConfirmingCard(false);
    }
  };

  return {
    defaultValues,
    isConfirmingCard,
    disableButton: isButtonDisabled,
    handleOnClick,
    handleOnChangeCard,
    currenciesOptions,
    createPaymentMethod,
  };
};
