import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";

interface ConfirmSpaceGroupChangeModalContentType {
  spaceName: string;
  currentSpaceGroupName: string;
  newSpaceGroupName: string;
  onConfirm: () => void;
}

const i18nBase = "spaces.form.edit.change_space_group";
export const ConfirmSpaceGroupChangeModalContent = ({
  spaceName,
  currentSpaceGroupName,
  newSpaceGroupName,
  onConfirm,
}: ConfirmSpaceGroupChangeModalContentType) => {
  const modal = useModal();
  return (
    <ConfirmationModal
      title={t([i18nBase, "title"])}
      isOpen={modal.visible}
      onClose={modal.hide}
      onConfirm={() => {
        onConfirm();
        void modal.hide();
      }}
    >
      <SanitizeHTMLString
        content={t([i18nBase, "description"], {
          space_name: spaceName,
          current_space_group: currentSpaceGroupName,
          new_space_group: newSpaceGroupName,
        })}
      />
    </ConfirmationModal>
  );
};

const ConfirmSpaceGroupChangeModal = ModalManager.create(
  ({
    spaceName,
    currentSpaceGroupName,
    newSpaceGroupName,
    onConfirm,
  }: ConfirmSpaceGroupChangeModalContentType) => (
    <ConfirmSpaceGroupChangeModalContent
      spaceName={spaceName}
      currentSpaceGroupName={currentSpaceGroupName}
      newSpaceGroupName={newSpaceGroupName}
      onConfirm={onConfirm}
    />
  ),
);

export const useConfirmSpaceGroupChangeModal = () =>
  useModal(ConfirmSpaceGroupChangeModal);
