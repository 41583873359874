import type { RerunFormData } from "@circle-workflows/hooks/useRerunWorkflow";
import { format } from "date-fns";
import { capitalize, isFunction } from "lodash";
import invariant from "tiny-invariant";
import { t } from "@/i18n-js/instance";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { days } from "./FrequencyOptions/EveryWeekOptions";
import { useMessageParts } from "./FrequencyOptions/useMessageParts";
import type { ScheduleFormData } from "./ModalCreateSchedule";

interface ConfirmScheduleProps {
  data: RerunFormData & ScheduleFormData;
  onCancel: () => void;
  onBack: () => void;
  onPublish: () => void;
}

const messagesUntil = {
  daily: "every_day_at_until",
  weekly: "every_week_on_until",
  monthly: "every_month_on_until",
  yearly: "every_year_on_until",
};

const messagesNever = {
  daily: "every_day_at",
  weekly: "every_week_on",
  monthly: "every_month_on",
  yearly: "every_year_on",
};

export const ConfirmSchedule = ({
  data,
  onBack,
  onCancel,
  onPublish,
}: ConfirmScheduleProps) => {
  const rerunText =
    data?.do_not_skip_tasks == "true"
      ? t("settings.workflows.rerun_modal.all_members")
      : t("settings.workflows.rerun_modal.only_new_members");

  const {
    ends_on: endsOn,
    frequency,
    first_run_at: firstRun,
    last_run_at: lastRun,
    days_of_week: daysOfWeek,
    next_run_at: nextRun,
    has_schedule_run_before: hasScheduleRunBefore,
  } = data;

  const { prefix, midSection } = useMessageParts(hasScheduleRunBefore);

  const recurringHourMinutes =
    !hasScheduleRunBefore || !nextRun ? new Date(firstRun) : new Date(nextRun);

  const getScheduleMessage = () => {
    const messagesBase: any = endsOn === "on" ? messagesUntil : messagesNever;

    const messageByFrequency: any = messagesBase[frequency];
    invariant(messageByFrequency, "Message by frequency should be selected.");

    let daysOfWeekLabel: string | null = null;

    if (frequency === "weekly") {
      const listFormatter = new Intl.ListFormat(undefined, {
        style: "long",
        type: "conjunction",
      });

      const labelDaysOfWeek = daysOfWeek.map(day => {
        const dayLabel = days.find(d => d.value === day)?.label;

        return capitalize(dayLabel);
      });
      daysOfWeekLabel = listFormatter.format(labelDaysOfWeek);
    }

    return t(
      ["settings.workflows.recurring_schedule_modal", messageByFrequency],
      {
        start_time_at: formattedDateTime({
          dateTime: new Date(firstRun),
          format: "date_time_with_short_timezone",
        }),
        end_time_at: formattedDateTime({
          dateTime: new Date(lastRun),
          format: "long_date",
        }),
        day_of_month: firstRun ? format(new Date(firstRun), "do") : null,
        repeat_days: daysOfWeekLabel,
        prefix,
        mid_section: midSection,
        recurring_time: firstRun
          ? formattedDateTime({
              dateTime: new Date(firstRun),
              format: "short_month_and_date",
            })
          : null,
        recurring_hour_minutes: formattedDateTime({
          dateTime: recurringHourMinutes,
          format: "short_time",
        }),
      },
    );
  };

  const messages: { message: string; icon: IconType }[] = [
    {
      message: getScheduleMessage(),
      icon: "16-clock",
    },
    {
      message: rerunText,
      icon: "16-members",
    },
  ];

  const buttonText = hasScheduleRunBefore
    ? t("settings.workflows.confirm_modal.modify_schedule")
    : t("settings.workflows.confirm_modal.schedule");

  return (
    <Modal.Content size="md">
      <Modal.Header>
        <Modal.Title size="md">
          {t("settings.workflows.confirm_modal.schedule_summary")}
        </Modal.Title>
        <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
          <Modal.CloseButton onClick={onCancel} />
        </Modal.HeaderActions>
        {isFunction(onBack) && (
          <Modal.HeaderActions className="absolute left-0 top-0 ml-6 mt-6">
            <Modal.BackButton onClick={onBack} />
          </Modal.HeaderActions>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-y-5 pt-5">
          <Typography.LabelMd weight="semibold">
            {t("settings.workflows.confirm_modal.workflow_summary")}
          </Typography.LabelMd>
          <ul className="flex list-none flex-col gap-y-4">
            {messages.map(({ message, icon }) => (
              <li
                className="text-default flex items-center gap-x-2"
                key={message}
              >
                <Icon
                  type={icon}
                  size={16}
                  className="mt-1 !h-4 !w-4 shrink-0 !self-start"
                />
                <Typography.BodySm weight="font-medium">
                  {message}
                </Typography.BodySm>
              </li>
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex w-full items-center justify-end">
        <Button variant="circle" onClick={onPublish}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
