import type { UseFieldArrayRemove } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { ThreeDotsDropdownV3 } from "@circle-react-shared/ThreeDotsDropdownV3";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface ActionsProps {
  accessGroupIndex: number;
  removeAccessGroup: UseFieldArrayRemove;
}

const i18nBase = "settings.access_groups.paywall_migration.modal.form.actions";

export const AccessGroupFormActions = ({
  accessGroupIndex,
  removeAccessGroup,
}: ActionsProps) => {
  const actions = [
    {
      label: t([i18nBase, "remove"]),
      onClick: () => {
        removeAccessGroup(accessGroupIndex);
      },
    },
  ];

  return (
    <ThreeDotsDropdownV3 label={t([i18nBase, "label"])}>
      {actions?.map(action => (
        <Dropdown.ItemWithLink
          key={action.label}
          linkType="button"
          onClick={action.onClick}
        >
          <Dropdown.IconContainer>
            <Typography.LabelSm weight="regular" color="text-very-dark">
              {action.label}
            </Typography.LabelSm>
          </Dropdown.IconContainer>
        </Dropdown.ItemWithLink>
      ))}
    </ThreeDotsDropdownV3>
  );
};
