import type { ComponentType } from "react";
import classNames from "classnames";
import { FiltersList } from "@circle-react-shared/Filter/FiltersList";

export interface HistoryFiltersProps {
  filtersConfig: {
    name: string;
    component: ComponentType<any>;
  }[];
}

export const HistoryFilters = ({ filtersConfig }: HistoryFiltersProps) => (
  <FiltersList
    className={classNames(
      "border-stroke-background relative border-b px-6 py-3 lg:px-9",
    )}
  >
    {filtersConfig.map(({ name, component: Component }) => (
      <Component key={name} />
    ))}
  </FiltersList>
);
