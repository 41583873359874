import { Checkbox } from "../../shared/uikit/Form/Checkbox";

export const SnippetCheckboxColumnHeader = ({
  allOnPageSelected,
  handleSelectAllOnPage,
}: {
  allOnPageSelected: boolean;
  handleSelectAllOnPage: (checked: boolean) => void;
}) => (
  <Checkbox
    checked={allOnPageSelected}
    onChange={e => handleSelectAllOnPage(e.target.checked)}
    name="select-all"
    isFormItem={false}
  />
);
