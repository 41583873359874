import { useFormContext } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "@/i18n-js/instance";
import { LoaderRow } from "@/react/components/shared/LoaderRow";
import { TruncateText } from "@/react/components/shared/uikit/TruncateText";
import { Typography } from "@/react/components/shared/uikit/Typography";
import { UserImage } from "@/react/components/shared/uikit/UserImage";
import {
  DEFAULT_PAGE_SIZE,
  useAudienceInfiniteQuery,
} from "@circle-react/hooks/useAudienceInfiniteQuery";
import { FORM_FIELD_KEYS } from "../constants";

const i18nBase = "settings.access_groups.paywall_migration.modal.preview";
const SCROLLABLE_TARGET_ID = "paywall-migration-members-scrollable-target";

export const Members = () => {
  const { watch } = useFormContext();

  const paywallId = watch(FORM_FIELD_KEYS.paywall_id);

  const {
    totalQueryAudience,
    isLoading,
    audience,
    fetchNextPage,
    hasNextPage,
  } = useAudienceInfiniteQuery({
    perPage: DEFAULT_PAGE_SIZE,
    order: { created_at: "desc" },
    query: "",
    initialPage: 1,
    options: {
      keepPreviousData: true,
    },
    filters: [{ id: paywallId, key: "paywalls", value: paywallId }],
    scope: {
      status: "all",
    },
  });

  return (
    <div className="border-primary rounded-xl border">
      <div className="border-primary bg-xy-flow rounded-t-xl border-b p-4">
        <Typography.LabelSm weight="bold">
          {t([i18nBase, "total_members"], { total: totalQueryAudience })}
        </Typography.LabelSm>
      </div>
      <div id={SCROLLABLE_TARGET_ID} className="h-[30vh] overflow-auto">
        {isLoading && <LoaderRow />}
        <InfiniteScroll
          style={{ height: "100%", overflow: "hidden" }}
          next={fetchNextPage}
          hasMore={hasNextPage || false}
          scrollableTarget={SCROLLABLE_TARGET_ID}
          loader={<LoaderRow />}
          dataLength={audience?.length || 0}
          scrollThreshold={0.5}
        >
          <div className="flex w-full flex-col px-5 pt-2">
            {audience?.map(({ id, avatar_url, name }: any) => (
              <div
                className="group flex h-12 items-center rounded-md border-none"
                key={id}
              >
                <div className="mr-2">
                  <UserImage src={avatar_url} name={name} size="8" />
                </div>
                <div className="w-40">
                  <TruncateText>
                    <Typography.LabelSm weight="semibold">
                      {name}
                    </Typography.LabelSm>
                  </TruncateText>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};
