import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export const ArchiveConfirmationActiveWorkflowModalContent = ({
  name,
  onConfirm,
  disabled,
}: any) => {
  const modal = useModal();
  return (
    <ConfirmationModal
      isOpen={modal.visible}
      onClose={modal.hide}
      confirmVariant="danger"
      confirmText={t("settings.workflows.table.actions.deactivate_and_archive")}
      title={t(
        "settings.workflows.table.actions.archive_confirm_active_workflow_title",
      )}
      onConfirm={onConfirm}
      disabled={disabled}
    >
      {t(
        "settings.workflows.table.actions.archive_confirm_active_workflow_description",
        {
          name: name || t("settings.workflows.table.untitled_workflow"),
        },
      )}
    </ConfirmationModal>
  );
};

export const ArchiveConfirmationActiveWorkflowModal = ModalManager.create(
  ({ name, onConfirm, disabled }: any) => (
    <ArchiveConfirmationActiveWorkflowModalContent
      name={name}
      onConfirm={onConfirm}
      disabled={disabled}
    />
  ),
);

export const useArchiveConfirmationActiveWorkflowModal = () =>
  useModal(ArchiveConfirmationActiveWorkflowModal);
