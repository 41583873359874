import { t } from "@/i18n-js/instance";
import type { CurrentPlan } from "@circle-react/types";
import { Typography } from "@circle-react-uikit/Typography";
import { useManageAddonModal } from "./ManageAddOnsModal";

interface CurrentPlanProps {
  currentPlan: CurrentPlan;
  refetchCurrentPlan: () => void;
}
export const AddOns = ({
  currentPlan,
  refetchCurrentPlan,
}: CurrentPlanProps) => {
  const manageAddonsModal = useManageAddonModal();
  const { plan_addons_total_amount_with_interval } = currentPlan;
  const openManageAddonsModal = () =>
    manageAddonsModal.show({ refetchCurrentPlan: refetchCurrentPlan });

  return (
    <div className="bg-primary border-primary my-6 rounded-lg border px-8 py-7">
      <div className="border-primary mb-6 flex justify-between border-b pb-6">
        <Typography.TitleSm weight="bold">
          {t(`settings.billing.add_ons.my_addons`)}
        </Typography.TitleSm>
        <Typography.TitleSm weight="bold">
          {plan_addons_total_amount_with_interval}
        </Typography.TitleSm>
      </div>

      {currentPlan?.plan_addons?.map(planAddon => (
        <div
          key={planAddon.community_plan_addon_id}
          className="flex justify-between gap-3 pb-2"
        >
          <div>
            <Typography.LabelSm>
              {planAddon.value} {planAddon.label}
            </Typography.LabelSm>

            {typeof planAddon.remaining_resources == "number" && (
              <Typography.LabelSm color="text-light pl-2">
                {t("settings.billing.add_ons.remaining_resources", {
                  count: planAddon.remaining_resources,
                })}
              </Typography.LabelSm>
            )}
          </div>

          <div>
            <Typography.LabelSm weight="bold">
              {planAddon.amount_with_interval}
            </Typography.LabelSm>
          </div>
        </div>
      ))}

      <button type="button" onClick={openManageAddonsModal}>
        <Typography.LabelSm color="text-messaging-info-blue">
          {t("settings.billing.add_ons.manage_add_ons")}
        </Typography.LabelSm>
      </button>
    </div>
  );
};
