import type { ComponentType } from "react";
import { createContext, useContext, useState } from "react";
import { usePunditUserContext } from "@circle-react/contexts";
import { useBackendProps } from "@circle-react/providers";
import { EmptyLayout } from "../EmptyLayout";
import { FullScreenLayout } from "../FullScreenLayout";
import { MinimalLayout as MinimalLayourComponent } from "../MinimalLayout";
import { StandardLayoutV2 } from "../StandardLayoutV2";
import { LAYOUT_CONSTANTS } from "./constants";
import type { LayoutType } from "./constants";
import { useSidebarDisabledV3 } from "./useSidebarDisabledV3";

type AnyObject = Record<string, any>;

export interface DynamicLayoutContext {
  layoutType: LayoutType;
  setLayoutType: (layoutType: LayoutType) => void;
  setStandardLayout: () => void;
  setMinimalLayout: () => void;
  setEmptyLayout: () => void;
  setDynamicLayoutProps: (props: AnyObject) => void;
  resetDynamicLayoutProps: () => void;
}

type ContextType = DynamicLayoutContext | null;

const DynamicLayoutContext = createContext<ContextType>(null);
DynamicLayoutContext.displayName = "DynamicLayoutContext";

export const DynamicLayout = ({ ...props }: AnyObject) => {
  const { isLoading } = usePunditUserContext();
  const { minimal_layout: isMinimalLayout } = useBackendProps();
  const [dynamicLayoutProps, setDynamicLayoutProps] = useState<AnyObject>({});
  const [layoutType, setLayoutType] = useState<LayoutType>(
    isMinimalLayout ? LAYOUT_CONSTANTS.minimal : LAYOUT_CONSTANTS.standard,
  );

  const isSidebarDisabled = useSidebarDisabledV3();
  const setStandardLayout = () => setLayoutType(LAYOUT_CONSTANTS.standard);
  const setMinimalLayout = () => setLayoutType(LAYOUT_CONSTANTS.minimal);
  const setEmptyLayout = () => setLayoutType(LAYOUT_CONSTANTS.empty);

  let LayoutComponent: ComponentType<any>;

  switch (layoutType) {
    case LAYOUT_CONSTANTS.fullscreen:
      LayoutComponent = FullScreenLayout;
      break;
    case LAYOUT_CONSTANTS.standard:
      LayoutComponent = StandardLayoutV2;
      break;
    case LAYOUT_CONSTANTS.minimal:
      LayoutComponent = MinimalLayourComponent;
      break;
    case LAYOUT_CONSTANTS.empty:
      LayoutComponent = EmptyLayout;
      break;
    default:
      LayoutComponent = StandardLayoutV2;
  }

  if (isLoading) {
    return null;
  }

  return (
    <DynamicLayoutContext.Provider
      value={{
        layoutType,
        setLayoutType,
        setStandardLayout,
        setMinimalLayout,
        setEmptyLayout,
        setDynamicLayoutProps,
        resetDynamicLayoutProps: () => setDynamicLayoutProps({}),
      }}
    >
      <LayoutComponent
        {...props}
        {...dynamicLayoutProps}
        disableSidebar={isSidebarDisabled}
      />
    </DynamicLayoutContext.Provider>
  );
};

export const useDynamicLayoutContext = () => {
  const context = useContext(DynamicLayoutContext);

  if (!context) {
    throw new Error(
      "useDynamicLayoutContext must be used within a DynamicLayout",
    );
  }

  return context;
};
