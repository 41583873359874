import { useState } from "react";
import { Confirm } from "@circle-react/components/shared/uikit/Confirm";

/**
 * Hook for managing a confirmation modal dialog
 * @param {Object} params - The parameters object
 * @param {string} params.title - The title text for the modal
 * @param {Function} params.onConfirm - Callback function executed when user confirms
 * @param {React.ReactNode} [params.bodyElement=null] - Optional custom body content for the modal
 * @param {string} [params.description=""] - Optional description text for the modal
 * @param {string} [params.buttonLabel=""] - Optional custom label for the confirm button
 * @param {boolean} [params.maxIndex=false] - Whether to use maximum z-index for the modal
 * @param {('primary'|'secondary'|'secondary-light'|'tertiary'|'plain'|'text'|'danger'|'danger-outline'|'circle'|'circle-plain'|'circle-text'|'marketing-gradient'|'link')} [params.sentiment="danger"] - The sentiment/theme of the modal
 * @returns Modal state and handler functions
 */
export const useConfirmModal = ({
  title,
  onConfirm,
  bodyElement = null,
  description = "",
  buttonLabel = "",
  maxIndex = false,
  sentiment = "danger",
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [event, setEvent] = useState();

  const onConfirmButtonClick = e => {
    setConfirmModalOpen(true);
    setEvent(e);
  };

  const onConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  const onConfirmModal = () => {
    setConfirmModalOpen(false);
    onConfirm(event);
  };

  const confirmModal = (
    <Confirm
      show={confirmModalOpen}
      onClose={onConfirmModalClose}
      onConfirm={onConfirmModal}
      title={title}
      description={description}
      bodyElement={bodyElement}
      className="reset-banner-modal"
      maxZIndexEnabled={maxIndex}
      buttonLabel={buttonLabel}
      sentiment={sentiment}
    />
  );

  return {
    confirmModal,
    confirmModalOpen,
    onConfirmButtonClick,
    onConfirmModalClose,
    onConfirmModal,
  };
};
