import { t } from "@/i18n-js/instance";

export const RESULTS_PER_PAGE = 10;

export const TABS = {
  ALL: "all",
  ACTIVE: "active",
  CANCELED: "canceled",
};

export const TABS_LOCALE = {
  [TABS.ALL]: t("settings.paywalls_admin_dashboard.subscriptions.tabs.all"),
  [TABS.ACTIVE]: t(
    "settings.paywalls_admin_dashboard.subscriptions.tabs.active",
  ),
  [TABS.CANCELED]: t(
    "settings.paywalls_admin_dashboard.subscriptions.tabs.canceled",
  ),
};
