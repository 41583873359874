import { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import slugify from "slugify";
import { t } from "@/i18n-js/instance";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import { ImageInputBanner } from "@circle-react-uikit/CoverImageInput/ImageInputBanner";
import { Form } from "@circle-react-uikit/Form";

const fields = [
  {
    name: "slug",
    label: t("spaces.form.edit.options.seo.slug"),
    description: t("spaces.form.edit.options.seo.slug_description", {
      host: window.location.host,
    }),
    transformValue: value =>
      slugify(value, {
        replacement: "-",
        remove: /[*+~.;()'"!:@#]/g,
        lower: true,
      }),
  },
  {
    name: "meta_tag_attributes[meta_title]",
    label: t("spaces.form.edit.options.seo.meta_title"),
  },
  {
    name: "meta_tag_attributes[meta_description]",
    label: t("spaces.form.edit.options.seo.meta_description"),
  },
  {
    name: "meta_tag_attributes[opengraph_title]",
    label: t("spaces.form.edit.options.seo.opengraph_title"),
  },
  {
    name: "meta_tag_attributes[opengraph_description]",
    label: t("spaces.form.edit.options.seo.opengraph_description"),
  },
];

export const SEOForm = ({ onChange, setImageToDelete }) => {
  const {
    getValues,
    formState: { dirtyFields },
  } = useFormContext();

  useEffect(() => {
    if ("slug" in dirtyFields || "meta_tag_attributes" in dirtyFields) {
      onChange(true);
    }
  }, [dirtyFields.slug, dirtyFields.meta_tag_attributes]);

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
        {fields.map(({ name, label, description, transformValue }) => (
          <Form.Item
            key={name}
            hideBorder
            hideDescription
            label={label}
            name={name}
            transformValue={transformValue}
            tooltipText={description}
            variant="extra-bottom-margin"
          >
            <Form.Input />
          </Form.Item>
        ))}
      </div>
      <div className="grid grid-cols-1">
        <div className="min-h-[255px] w-full">
          <Form.Label
            hideBorder
            label={t("spaces.form.edit.options.seo.opengraph_image.label")}
          />
          <CoverImageInput
            name="meta_tag_attributes[opengraph_image]"
            alt={t("spaces.form.edit.options.seo.opengraph_image.label")}
            className="relative"
            showAddCoverBtn
            aspectRatio={ASPECT_RATIO.OPEN_GRAPH}
            changeImageText={t(
              "spaces.form.edit.options.seo.opengraph_image.change",
            )}
            onDelete={() => {
              onChange(true);
              setImageToDelete(
                getValues("meta_tag_attributes[opengraph_image_id]"),
              );
            }}
            customAddCoverBtn={
              <ImageInputBanner
                title={t("spaces.form.edit.options.seo.opengraph_image.add")}
                description={t(
                  "spaces.form.edit.options.seo.opengraph_image.description",
                )}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

SEOForm.propTypes = {
  setImageToDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
