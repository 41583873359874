import { t } from "@/i18n-js/instance";
import type { RuleType } from "../../Broadcasts/interfaces";

const localeNamespace = "settings.emails.builder";

export const RULE_TYPES = {
  ALL_CONTACTS: "all_contacts",
  ALL_MEMBERS: "all_members",
  CUSTOM_FILTERS: "custom_filters",
  SELECTED_MEMBERS: "selected_members",
} as const;

export const allMemberOptions: ReadonlyArray<{
  text: string;
  value: RuleType;
}> = [
  {
    text: t([localeNamespace, "all_contacts"]),
    value: RULE_TYPES.ALL_CONTACTS,
  },
  {
    text: t([localeNamespace, "all_members"]),
    value: RULE_TYPES.ALL_MEMBERS,
  },
  {
    text: t([localeNamespace, "custom_filters"]),
    value: RULE_TYPES.CUSTOM_FILTERS,
  },
];
