import { useFormContext, useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import "./style.scss";

export interface HeaderProps {
  isPaywallNew: boolean;
}

export const Header = ({ isPaywallNew }: HeaderProps) => {
  const { control } = useFormContext();
  const displayNameWatcher = useWatch({ control, name: "display_name" });
  const noPaywallNameAvailable = t(
    "settings.paywalls.modal.no_title_available",
  );

  return (
    <>
      {isPaywallNew
        ? t("settings.paywalls.modal.title_creation", {
            paywall_name: displayNameWatcher ?? noPaywallNameAvailable,
          })
        : t("settings.paywalls.modal.title_editing", {
            paywall_name: displayNameWatcher,
          })}
    </>
  );
};
