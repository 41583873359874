import { LinkItem } from "./LinkItem";
import { useCollapsedSidebarLinks } from "./userCollapsedSidebarLinks";

export const CollapsedSidebar = () => {
  const links = useCollapsedSidebarLinks();

  return (
    <div
      className="bg-primary border-r-primary flex h-[calc(100vh-67px)] w-16 shrink-0 flex-col items-center justify-start space-y-2 border-r p-3"
      data-testid="collapsed-sidebar"
    >
      {links.map(({ label, path, icon, enabled }) => {
        if (!enabled) return null;
        return <LinkItem key={label} label={label} path={path} icon={icon} />;
      })}
    </div>
  );
};
