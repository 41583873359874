import { useCurrentSpaceContext } from "@circle-react/contexts";
import type { Post } from "@circle-react/types";

export const usePostView = (allPosts: Post[]) => {
  const { data: space } = useCurrentSpaceContext();
  const shouldDisplayTopicsFilters = Number(space?.topics_count) > 0;

  const [firstPost, ...remainingPosts] = allPosts;

  const hasNoPosts = !allPosts.length;
  const isCardsView = space?.display_view === "cards";
  const hasFirstPost = Boolean(firstPost);
  const isFirstPostPinned = hasFirstPost && firstPost.pinned_at_top_of_space;
  const isFirstPostPinnedOutside = hasFirstPost && isFirstPostPinned;
  const isFirstPostInside = hasFirstPost && !isFirstPostPinned;

  return {
    firstPost,
    remainingPosts,
    shouldDisplayTopicsFilters,
    isFirstPostPinnedOutside,
    isFirstPostInside,
    hasNoPosts,
    isCardsView,
  };
};
