import { BrandedAppEmptyState } from "../States/BrandedAppEmptyState";
import { EmptyState } from "../States/EmptyState";
import { RootForm } from "./RootForm";

export const MobileForm = () => (
  <BrandedAppEmptyState>
    <EmptyState>
      <RootForm />
    </EmptyState>
  </BrandedAppEmptyState>
);
