import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import {
  getBasePath,
  getCouponFromCheckoutPath,
} from "@/react/components/Paywalls/Admin/PaywallsAdmin/FormModal/DetailsTab/detailsUtils";
import { usePaywallCouponList } from "@/react/hooks/paywalls/usePaywallCouponList";
import { Form } from "@circle-react-uikit/Form";

export const CheckoutUrlWithCouponSelector = () => {
  const { formState, control, setValue, getValues, watch } = useFormContext();
  const watcherCheckoutPath = useWatch({ control, name: "checkout_path" });
  const paywallId = watch("id");
  const { paywallCouponsListQuery } = usePaywallCouponList({
    paywallId: paywallId || "",
    enabled: true,
  });
  const { data: couponsData } = paywallCouponsListQuery;
  const [couponsOptions, setCouponsOptions] = useState([]);
  const couponFromCheckoutPath = getCouponFromCheckoutPath(
    watcherCheckoutPath,
    formState.dirtyFields,
  );
  const [isAutoApplyCouponVisible, setIsAutoApplyCouponVisible] = useState(
    couponFromCheckoutPath !== "",
  );

  useEffect(() => {
    if (couponsData) {
      const options = couponsData.map(coupon => ({
        label: coupon.code,
        value: coupon.code,
      }));
      setCouponsOptions(options);

      if (couponFromCheckoutPath && !formState.dirtyFields.coupon_selector) {
        setValue("coupon_selector", couponFromCheckoutPath);
      }
    }
  }, [
    couponsData,
    couponFromCheckoutPath,
    formState.dirtyFields.coupon_selector,
    setValue,
  ]);

  const handleAutoApplyCouponToggle = checked => {
    setIsAutoApplyCouponVisible(checked);
    if (!checked) {
      setValue("coupon_selector", "");
      const basePath = getBasePath(watcherCheckoutPath);

      setValue("checkout_path", basePath);
    }
  };

  return (
    <div className="flex flex-col gap-y-2.5">
      <Form.Item
        labelClassName="!font-normal"
        name="auto_apply_coupon"
        translationRoot="settings.paywalls.modal.tabs.details"
        fullWidth
        tooltipText={t(
          "settings.paywalls.modal.tabs.details.auto_apply_coupon_tooltip",
        )}
        hideBorder
        hideDescription
        hidePlaceholder
        inlineReverse
      >
        <Form.ToggleSwitch
          data-testid="auto_apply_coupon"
          name="auto_apply_coupon_toggle"
          variant="small"
          onToggleChange={handleAutoApplyCouponToggle}
          checked={isAutoApplyCouponVisible}
        />
      </Form.Item>
      {isAutoApplyCouponVisible && (
        <Form.Item
          className="!pt-2"
          fullWidth
          hideBorder
          hideDescription
          hidePlaceholder
          hideLabel
          name="coupon_selector"
          translationRoot="settings.paywalls.modal.tabs.details"
        >
          <Form.SelectNative
            name="coupon_selector"
            options={[
              {
                label: t(
                  "settings.paywalls.modal.tabs.details.auto_apply_coupon_placeholder",
                ),
                value: "",
              },
              ...couponsOptions,
            ]}
            value={getValues("coupon_selector")}
          />
        </Form.Item>
      )}
    </div>
  );
};
