import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { liveStreamApi } from "@circle-react/api";
import type { ApiError } from "@circle-react/config/CustomErrors";
import {
  PARTICIPANT_ROLES,
  isEnded,
  roomTypeData,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useLiveStreamsFeatureFlags } from "@circle-react/hooks/useLiveStreamsFeatureFlags";
import type { Participant } from "@circle-react/types/Live/Participant";
import type { Room } from "@circle-react/types/Live/Room";
import { useToast } from "@circle-react-uikit/ToastV2";

const PRE_JOIN_REFETCH_INTERVAL = 10000;

export const useLiveStreamRoom = (roomSlug: string) => {
  const { isLiveStreamsHlsEnabled } = useLiveStreamsFeatureFlags();
  const participantRole = isLiveStreamsHlsEnabled
    ? PARTICIPANT_ROLES.HLS_SPECTATOR
    : PARTICIPANT_ROLES.SPECTATOR;
  const { error, success } = useToast();
  const [currentParticipant, setCurrentParticipant] =
    useState<Participant | null>(null);
  const [viewTypeOverride, setViewTypeOverride] = useState<string | null>(null);
  const {
    data: room,
    isLoading,
    isError,
    error: apiError,
    refetch: refetchRoom,
    ...passThroughProps
  } = useQuery<Room, ApiError>(
    internalApi.liveStreams.show({
      id: roomSlug,
    }),
    {
      refetchInterval: currentParticipant ? false : PRE_JOIN_REFETCH_INTERVAL,
    },
  );

  // Note: We're using both room.status and streamEnded state
  // to make sure we're able to react quickly without refetching the room
  // or in case fetching the room fails but stream is still ended.
  const [isStreamEnded, setIsStreamEnded] = useState<boolean>(false);
  const joinMutation = useMutation<Participant, ApiError, string>(
    role => liveStreamApi.join({ id: room?.id, role, forReactQuery: true }),
    {
      onSuccess: participant => {
        setCurrentParticipant(participant);
      },
      onError: response => {
        const errorMessage = response.firstErrorDetail
          ? `${t("live_streams.room.join_error")}: ${response.firstErrorDetail}`
          : `${t("live_streams.room.join_error")}.`;
        error(errorMessage);
      },
    },
  );
  const joinAsHost = () => joinMutation.mutate(PARTICIPANT_ROLES.HOST);
  const joinAsParticipant = () => joinMutation.mutate(participantRole);
  const leaveMutation = useMutation<Participant, ApiError>(
    () =>
      liveStreamApi.leave({
        id: room?.id,
        participantId: currentParticipant?.id,
        forReactQuery: true,
      }),
    {
      onSuccess: participant => setCurrentParticipant(participant),
      onError: () => error(t("live_streams.room.leave_error")),
    },
  );
  const goLiveMutation = useMutation(
    () => liveStreamApi.goLive({ id: room?.id, forReactQuery: true }),
    {
      onSuccess: () => refetchRoom(),
      onError: () => error(t("live_streams.room.go_live_error")),
    },
  );

  const endStreamMutationOptions = {
    onSuccess: () => {
      success(t(`live_streams.room.${roomTypeData(room)}.end_success`));
      setIsStreamEnded(true);
      void refetchRoom();
    },
    onError: () =>
      error(t(`live_streams.room.${roomTypeData(room)}.end_error`)),
  };

  const endStreamMutation = useMutation(
    () => liveStreamApi.endStream({ id: room?.id }),
    endStreamMutationOptions,
  );

  const endStreamDelayedMutation = useMutation(
    () => liveStreamApi.endStreamDelayed({ id: room?.id }),
    endStreamMutationOptions,
  );

  const updateRoomMutation = useMutation(
    formData => liveStreamApi.update({ id: room?.id, formData }),
    {
      onSuccess: () => {
        void refetchRoom();
        success(t(`live_streams.room.${roomTypeData(room)}.update_success`), {
          duration: "short",
          shouldUseProgress: false,
        });
      },
      onError: () =>
        error(t(`live_streams.room.${roomTypeData(room)}.update_error`)),
    },
  );
  const updateRoomSilentlyMutation = useMutation(
    formData => liveStreamApi.update({ id: room?.id, formData }),
    {
      onSuccess: () => refetchRoom(),
    },
  );
  const onEndStream = (reason: string) => {
    success(reason);
    setIsStreamEnded(true);
    void refetchRoom();
  };

  return {
    room,
    isLoading,
    isError,
    apiError,
    joinAsHost,
    joinAsParticipant,
    isJoining: joinMutation.isLoading,
    currentParticipant,
    updateRoom: updateRoomMutation.mutate,
    updateRoomAsync: updateRoomMutation.mutateAsync,
    updateRoomSilentlyAsync: updateRoomSilentlyMutation.mutateAsync,
    goLive: goLiveMutation.mutate,
    goLiveAsync: goLiveMutation.mutateAsync,
    leave: leaveMutation.mutate,
    leaveAsync: leaveMutation.mutateAsync,
    endStream: endStreamMutation.mutate,
    endStreamDelayed: endStreamDelayedMutation.mutate,
    endStreamDelayedAsync: endStreamDelayedMutation.mutateAsync,
    onEndStream,
    isLeaving: leaveMutation.isLoading,
    refetchRoom,
    isStreamEnded: isEnded(room) || isStreamEnded,
    viewTypeOverride,
    setViewTypeOverride,
    ...passThroughProps,
  };
};
