import { Redirect, Route, Switch } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Typography } from "@circle-react-uikit/Typography";
import { AccessGroupActions } from "./AccessGroupActions";
import { AccessGroupContent } from "./AccessGroupContent";
import { AccessGroupMembers } from "./AccessGroupMembers";
import { AddAccessGroupMembersModal } from "./AccessGroupMembers/AddAccessGroupMembersModal";
import { useAccessGroupData } from "./AccessGroupMembers/DataStoreContext/AccessGroupDataContext";
import { MEMBER_EDIT_PATHS } from "./constants";

export interface EditAccessGroupContentType {
  onClose: () => void;
  initialTab?: string;
}

export const EditAccessGroupModalBody = ({
  onClose,
  initialTab = "",
}: EditAccessGroupContentType) => {
  const { accessGroup } = useAccessGroupData();

  const sidebarNavItems = [
    {
      label: t("settings.access_groups.admin.modal.tabs.members"),
      href: MEMBER_EDIT_PATHS.MEMBERS,
    },
    {
      label: t("settings.access_groups.admin.modal.tabs.access"),
      href: MEMBER_EDIT_PATHS.CONTENT,
    },
  ];

  const getInitialTab = () => initialTab || MEMBER_EDIT_PATHS.MEMBERS;

  return (
    <>
      <FullScreenModal.Header>
        <FullScreenModal.HeaderNav
          titleNode={
            <div className="divide-primary flex items-center divide-x">
              <IconButton
                name="20-arrow-left"
                iconSize={20}
                buttonSize={28}
                className="text-default me-4"
                iconClassName="w-5 h-5"
                ariaLabel={t("back")}
                onClick={onClose}
              />
              <div className="ps-4">
                <Typography.TitleSm weight="semibold" truncate>
                  {accessGroup?.name}
                </Typography.TitleSm>
              </div>
            </div>
          }
          items={sidebarNavItems}
          rightNode={<AccessGroupActions />}
        />
      </FullScreenModal.Header>
      <FullScreenModal.Body wrapperClassName="max-w-screen-lg">
        <Switch>
          <Route exact path={MEMBER_EDIT_PATHS.MEMBERS}>
            <AccessGroupMembers />
          </Route>
          <Route exact path={MEMBER_EDIT_PATHS.ADD_MEMBERS}>
            <AddAccessGroupMembersModal />
          </Route>
          <Route exact path={MEMBER_EDIT_PATHS.CONTENT}>
            <AccessGroupContent />
          </Route>
          <Redirect to={getInitialTab()} />
        </Switch>
      </FullScreenModal.Body>
    </>
  );
};
