import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";

export const CommunityInfo = () => {
  const { currentCommunity } = usePaywallCheckoutContext();

  return (
    <div className="mb-6 flex items-center">
      {currentCommunity.logo_url && (
        <img
          loading="lazy"
          className="mr-2.5 max-h-10"
          src={currentCommunity.logo_url}
          alt=""
        />
      )}

      {!currentCommunity.logo_url && currentCommunity.icon_url && (
        <>
          <div className="box-border h-7 w-7 p-1 text-center">
            <img loading="lazy" src={currentCommunity.icon_url} alt="" />
          </div>
          <span className="text-lg font-medium">{currentCommunity.name}</span>
        </>
      )}

      {!currentCommunity.logo_url && !currentCommunity.icon_url && (
        <span className="text-lg font-medium">{currentCommunity.name}</span>
      )}
    </div>
  );
};
