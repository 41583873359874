import { t } from "@/i18n-js/instance";
import { TemplateList } from "@circle-react/components/SettingsApp/Workflows/components/CreateNewWorkflow/components/TemplateList";
import { CreateWorkflowProvider } from "@circle-react/components/SettingsApp/Workflows/components/CreateNewWorkflow/provider/WorkflowFormDataProvider";
import { Typography } from "@circle-react-uikit/Typography";

interface EmptyWorkflowsProps {
  category?: string;
}

export const EmptyWorkflows = ({ category }: EmptyWorkflowsProps) => (
  <CreateWorkflowProvider category={category}>
    <div className="px-6">
      <Typography.TitleSm weight="bold">
        {t("settings.workflows.workflows")}
      </Typography.TitleSm>
      <TemplateList category={category} includeCreateButton />
    </div>
  </CreateWorkflowProvider>
);
