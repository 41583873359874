import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { useActions } from "../ActionRule";
import { removeMember } from "../Actions/Member";
import { useFormAudienceCounter } from "../AudienceMatchCounter/useFormAudienceCounter";
import { BulkActionList } from "./BulkActionList";

export const ConfirmBulkActionBody = () => {
  const { isLoading, hasFilters } = useFormAudienceCounter();

  const { formActions } = useActions();

  const isRemoveMemberFromCommunityPresent = formActions.find(
    action => action.action_type === removeMember.value,
  );

  const title = isRemoveMemberFromCommunityPresent
    ? t(
        "settings.workflows.confirm_modal.workflow_summary_remove_members_title",
      )
    : t("settings.workflows.confirm_modal.workflow_summary");

  return (
    <Modal.Body>
      <Typography.LabelMd weight="semibold">{title}</Typography.LabelMd>
      <div className="pt-5">
        <div className="bg-secondary rounded-lg border p-5">
          {hasFilters && (
            <div className="text-default flex flex-col gap-5">
              {isLoading && (
                <div className="flex flex-row items-center gap-3">
                  <Icon type="loader" size={16} className="!h-4 !w-4" />
                </div>
              )}
              <BulkActionList />
            </div>
          )}
        </div>
      </div>
      {isRemoveMemberFromCommunityPresent && (
        <div className="pt-5">
          <Typography.LabelSm>
            {t(
              "settings.workflows.confirm_modal.workflow_summary_remove_members_description",
            )}
          </Typography.LabelSm>
        </div>
      )}
    </Modal.Body>
  );
};
