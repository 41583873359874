import { useThemeObject } from "@circle-react/providers";
import { CanvasCard } from "@circle-react-shared/CanvasCard";
import { MobileLockscreenFrame } from "@circle-react-shared/CanvasPreviews/LockscreenCanvas/MobileLockscreenFrame";
import { WindowPortal } from "@circle-react-shared/WindowPortal";
import { useFormBlocks } from "../Providers";
import { usePaywallBlockContext } from "../Providers/PaywallBlockProvider";

export const MobilePreview = ({ space = {} }: any) => {
  const theme = useThemeObject();
  const { isPreviewWindowOpen, setPreviewWindowOpen } =
    usePaywallBlockContext();
  const { blocks } = useFormBlocks();
  const { name = "" } = space;
  const lightProperty = "light";

  const mobileLockscreenFrameProp = {
    headerSpaceTitle: name,
    blocks,
    theme: {
      ...theme,
      default_appearance: lightProperty,
    },
    border: true,
  };

  const desconstructor = () => {
    setPreviewWindowOpen(false);
  };

  return (
    <CanvasCard className="!bg-secondary">
      <MobileLockscreenFrame {...mobileLockscreenFrameProp} />
      {isPreviewWindowOpen && (
        <WindowPortal desconstructor={desconstructor} newTab>
          <MobileLockscreenFrame
            {...mobileLockscreenFrameProp}
            border={false}
            width={26.875}
            height={58.25}
          />
        </WindowPortal>
      )}
    </CanvasCard>
  );
};
