import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { broadcastsApi } from "@circle-react/api/broadcastsApi";
import { useBroadcastData } from "@circle-react-shared/Emails/BroadcastDataContext";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { useBroadcastSettingsAddressModal } from "../components/BroadcastSettings/BroadcastSettingsAddressModal";
import { useSendTestEmailModal } from "../components/Builder/SendTestEmailModal";
import { useAreMarketingSettingsAvailable } from "./useAreMarketingSettingsAvailable";

const i18nRoot = "settings.emails.builder";

export const useSendTestEmail = () => {
  const toastr = useToast();
  const { data } = useBroadcastData();
  const { id: paramsId } = useParams<{ id: string }>();
  const broadcastId = data?.id ?? Number(paramsId);

  const {
    isLoading: isLoadingMarketingSettings,
    areMarketingSettingsAvailable,
  } = useAreMarketingSettingsAvailable();
  const broadcastSettingsModal = useBroadcastSettingsAddressModal();
  const sendTestEmailModal = useSendTestEmailModal();

  const { mutate: sendTestEmail, isLoading: isSending } = useMutation<
    void,
    unknown
  >(
    () =>
      broadcastsApi.sendTestEmail({
        id: broadcastId,
      }),
    {
      onSuccess: () => {
        toastr.success(
          t("settings.emails.builder.send_test_email_modal.test_email_sent"),
        );
      },
      onError: () => {
        toastr.error(t("request_failure_message"));
      },
    },
  );

  const handleTestEmail = async () => {
    if (areMarketingSettingsAvailable) {
      return sendTestEmailConfirmation();
    }
    await broadcastSettingsModal.show({
      confirmText: t([i18nRoot, "save_and_send"]),
      onSuccess: sendTestEmail,
    });
  };

  const sendTestEmailConfirmation = () => {
    void sendTestEmailModal.show({
      handleSendTestEmail: () => {
        sendTestEmail();
        void sendTestEmailModal.hide();
      },
    });
  };

  return {
    isSending,
    isLoadingMarketingSettings,
    handleTestEmail,
  };
};
