import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { accessGroupEntryPoints } from "@/react/helpers/accessGroups/accessGroupHelpers";
import {
  CHANNEL_NAME,
  isAsyncTaskChannelUpdated,
  isPaywallMembersMigrationTask,
} from "@/react/helpers/asyncTaskHelpers";
import { useWebSocket } from "@/react/hooks/useWebSocket";
import { usePunditUserContext } from "@circle-react/contexts";
import { AccessGroups } from "@circle-react-shared/AccessGroups";
import { usePaywallFormContext } from "../PaywallFormProvider";
import { TabTitle } from "../TabTitle";
import { AccessContent } from "./AccessContent";
import { PaywallMigrationToAccessGroups } from "./PaywallMigrationToAccessGroups";

export const AccessTab = () => {
  const { control } = useFormContext();
  const { onClose: closePaywallFormModal } = usePaywallFormContext();
  const id = useWatch({ control, name: "id" });
  const isAccessGroupEnabledForThisPaywall = useWatch({
    control,
    name: "access_groups_enabled",
  });
  const isAccessGroupsEnabledForExisting =
    isAccessGroupEnabledForThisPaywall && id;

  const { currentCommunitySettings } = usePunditUserContext();
  const isAccessGroupsEnabled =
    (currentCommunitySettings?.access_groups_enabled && !id) ||
    isAccessGroupsEnabledForExisting;

  const isPaywallMigrationEnabled =
    currentCommunitySettings?.paywalls_with_access_groups_migration_enabled &&
    id &&
    !isAccessGroupEnabledForThisPaywall;

  const [isSyncing, setIsSyncing] = useState(false);
  const { currentCommunityMember } = usePunditUserContext();

  const onMessageReceive = (eventData: any) => {
    const { event, record } = eventData;
    const parsedRecord = JSON.parse(record);
    const { params } = parsedRecord;

    if (
      !isPaywallMembersMigrationTask(parsedRecord?.type) ||
      !params?.paywall_id
    ) {
      return;
    }

    if (isAsyncTaskChannelUpdated(event)) {
      setIsSyncing(true);
    } else {
      setIsSyncing(false);
    }
  };

  useWebSocket({
    channel: CHANNEL_NAME,
    onMessageReceive: onMessageReceive,
    canCreateConnection: !!currentCommunityMember?.id,
    community_member_id: currentCommunityMember?.id,
  });

  const componentToRender = () => {
    if (isPaywallMigrationEnabled) {
      return (
        <PaywallMigrationToAccessGroups
          spaceAccessNode={<AccessContent />}
          closePaywallFormModal={closePaywallFormModal}
        />
      );
    }
    if (isAccessGroupsEnabled) {
      return (
        <div className="flex h-[50vh] flex-col overflow-auto pb-4">
          <AccessGroups
            description={t(
              "settings.access_groups.admin.entry_points.association.paywall.description",
            )}
            recordType={accessGroupEntryPoints.paywall}
            areFormItemsEmbedded
            spaceAccessNode={
              isAccessGroupsEnabledForExisting ? null : (
                <div className="-mt-2">
                  <AccessContent />
                </div>
              )
            }
            isAccessGroupsTabEnabled={isAccessGroupsEnabled}
            canRemoveAllAccessGroups={false}
            isSyncing={isSyncing}
            syncingTooltip={t(
              "settings.access_groups.paywall_migration.syncing_tooltip",
            )}
          />
        </div>
      );
    }

    return <AccessContent />;
  };

  return (
    <div className="px-4">
      <TabTitle title={t("settings.paywalls.modal.tabs.access.title")} />
      {componentToRender()}
    </div>
  );
};
