import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export const ArchiveConfirmationModalContent = ({
  name,
  onConfirm,
  disabled,
}: any) => {
  const modal = useModal();
  const message = t(
    "settings.workflows.table.actions.archive_confirm_description",
    { name: name || t("settings.workflows.table.untitled_workflow") },
  );
  const sanitizedMessage = <SanitizeHTMLString content={message} />;
  return (
    <ConfirmationModal
      isOpen={modal.visible}
      onClose={modal.hide}
      confirmVariant="danger"
      confirmText={t("settings.workflows.table.actions.archive")}
      title={t("settings.workflows.table.actions.archive_confirm_title")}
      onConfirm={onConfirm}
      disabled={disabled}
    >
      {sanitizedMessage}
    </ConfirmationModal>
  );
};

export const ArchiveConfirmationModal = ModalManager.create(
  ({ name, onConfirm, disabled }: any) => (
    <ArchiveConfirmationModalContent
      name={name}
      onConfirm={onConfirm}
      disabled={disabled}
    />
  ),
);

export const useArchiveConfirmationModal = () =>
  useModal(ArchiveConfirmationModal);
