import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { usePaywalls } from "@circle-react/components/PaywallCoupons/Admin/FormFields/usePaywalls";
import { paywallsEligibleForTrial } from "@circle-react/helpers/paywallHelpers";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useCurrentAction } from "../../../../ActionRule";
import { NotifyMemberForm } from "./NotifyMemberForm";
import { PaywallPriceSelectionInput } from "./PaywallPriceSelectionInput";
import { TrialDaysInput } from "./TrialDaysInput";

const i18nRoot = "settings.workflows.form";

export const FormPaywallTrial = () => {
  const { paramsPath } = useCurrentAction();
  const { isLoading, isSuccess, activePaywalls } = usePaywalls();
  const { watch } = useFormContext();
  const selectedPaywallId = watch(`${paramsPath}.paywall_id`);

  const paywallOptions = [];
  const paywallMappedById = {};
  paywallsEligibleForTrial(activePaywalls).forEach(paywall => {
    paywallOptions.push({
      value: paywall.id,
      label: paywall.name || paywall.display_name,
    });

    paywallMappedById[paywall.id] = { ...paywall };
  });

  if (isLoading) {
    return <Loader center />;
  }

  if (!isSuccess) {
    return (
      <Typography.LabelXs>
        <span className="text-v2-danger">
          {t([i18nRoot, "errors.paywall_prices.fetch_failed"])}
        </span>
      </Typography.LabelXs>
    );
  }

  return (
    <>
      <FormTextSelect
        name={`${paramsPath}.paywall_id`}
        label={
          <Typography.LabelSm weight="semibold">
            {t([i18nRoot, "select_paywall"])}
          </Typography.LabelSm>
        }
        options={paywallOptions}
        rules={{
          required: t([i18nRoot, "errors.required"]),
        }}
        emptyMessage={t([i18nRoot, "errors.paywalls.not_found"])}
      />
      {selectedPaywallId && (
        <>
          <div className="flex flex-col gap-x-5 md:flex-row">
            <PaywallPriceSelectionInput
              paywallPrices={
                paywallMappedById[selectedPaywallId].stripe_prices_attributes
              }
            />

            <TrialDaysInput
              name={`${paramsPath}.trial_days`}
              disabled={isLoading}
            />
          </div>
          <div className="flex flex-col gap-x-5 md:flex-row">
            <NotifyMemberForm />
          </div>
        </>
      )}
    </>
  );
};
