import type { ComponentPropsWithoutRef } from "react";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { CommunitySwitcherSkeleton as CommunitySwitcherLoader } from "@circle-react-uikit/SkeletonLoader/skeletons/CommunitySwitcher";
import { DiscoverButton } from "./DiscoverButton";
import { Divider } from "./Divider";
import { NewCommunityButton } from "./NewCommunityButton";
import { useCommunitySwitcherStyles } from "./useCommunitySwitcherStyles";

export interface CommunitySwitcherPresentationV3Props
  extends ComponentPropsWithoutRef<"div"> {
  isLoading?: boolean;
  shouldShowShowMoreButton?: boolean;
  onClickSeeMyCommunities?: () => void;
}

export const CommunitySwitcherPresentationV3 = ({
  children,
  isLoading = true,
  shouldShowShowMoreButton,
  onClickSeeMyCommunities = () => {},
  className,
  ...rest
}: CommunitySwitcherPresentationV3Props) => {
  const { presentationClasses, bodyClasses, bodyStyles } =
    useCommunitySwitcherStyles();
  const { currentCommunitySettings } = usePunditUserContext();

  if (isLoading) {
    return <CommunitySwitcherLoader />;
  }

  return (
    <div
      data-testid="community-switcher-presentation"
      className={classNames("w-18", className, presentationClasses)}
      {...rest}
    >
      <div
        data-testid="community-switcher-presentation-body"
        className={classNames("flex flex-col items-center gap-4", bodyClasses)}
        style={bodyStyles}
      >
        <div className="flex flex-col items-center gap-2">
          {children}
          {shouldShowShowMoreButton && (
            <TippyV2
              content={t("community_switcher_see_my_communities")}
              placement="right"
              arrow={false}
              offset={[0, 16]}
              interactive={false}
            >
              <IconButton
                name="20-menu-dots-horizontal"
                className="hover:!bg-c-header-hover !rounded-lg"
                iconClassName="!text-c-sidebar group-hover:!text-dark !w-6 !h-6"
                variant="secondary"
                iconSize={20}
                buttonSize={32}
                ariaLabel={t("community_switcher_see_my_communities")}
                onClick={onClickSeeMyCommunities}
              />
            </TippyV2>
          )}
        </div>
        {shouldShowShowMoreButton && <Divider />}
        {currentCommunitySettings?.discover_enabled && <DiscoverButton />}
        <NewCommunityButton />
      </div>
    </div>
  );
};
