import { t } from "@/i18n-js/instance";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const PaywallsEmptyState = () => {
  const redirectToPaywalls = () => {
    window.open(settingsNavPathV3.paywalls.index, "_blank");
  };

  return (
    <div className="flex w-full flex-col items-center">
      <div className="flex h-full w-64 flex-col gap-y-4 py-8">
        <Typography.BodySm textTransform="text-center">
          {t("paywalls.lock_screen.sidebar.paywalls_empty_state.title")}
        </Typography.BodySm>
        <div className="flex justify-center">
          <Button type="button" onClick={redirectToPaywalls} full={false}>
            {t("paywalls.lock_screen.sidebar.paywalls_empty_state.cta")}
          </Button>
        </div>
      </div>
    </div>
  );
};
