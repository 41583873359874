import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useManageAddonModal } from "@circle-react/components/SettingsApp/Plan/AddOns/ManageAddOnsModal";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";
import { Loader } from "@circle-react-uikit/Loader";

export const LimitPanel = ({
  title,
  children,
  isLoading,
  canPurchaseMore = true,
  onPurchase,
}) => {
  const { isCommunityOnTrial } = usePlanStatus();
  const manageAddonsModal = useManageAddonModal();

  const openManageAddonsModal = () => {
    manageAddonsModal.show({
      onSuccess: onPurchase,
    });
  };

  return (
    <div
      className="bg-primary border-primary relative shrink-0 grow rounded-lg border px-5 py-4"
      data-testid="live-main-status"
    >
      {isLoading ? (
        <div className="flex h-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex h-full flex-col">
          <div className="flex items-center justify-between">
            {title && <Typography.LabelSm>{title}</Typography.LabelSm>}
            {!isCommunityOnTrial && canPurchaseMore && (
              <button
                type="button"
                className="cursor-pointer text-sm"
                onClick={openManageAddonsModal}
              >
                {t("live_streams.dashboard.limits.add_more")}
              </button>
            )}
          </div>
          <div className="relative grow">{children}</div>
        </div>
      )}
    </div>
  );
};

LimitPanel.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  canPurchaseMore: PropTypes.bool,
  onPurchase: PropTypes.func,
};
