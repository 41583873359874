import { memo, useMemo } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { t } from "@/i18n-js/instance";
import { eventLocation } from "@circle-react/helpers/postHelpers";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";

const TARGET_ZOOM = 15;
type Libraries = (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[];

const libraries: Libraries = ["places"];

const mapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  scrollWheel: false,
  fullscreenControl: false,
  panControl: false,
  rotateControl: false,
  gestureHandling: "none",
  clickableIcons: false,
  controlSize: 25,
  disableDoubleClickZoom: true,
  draggableCursor: "arrow",
  draggingCursor: "arrow",
  keyboardShortcuts: false,
};

const locationFromPlace = (place: any) => {
  if (place?.geometry) {
    return {
      lat: place?.geometry?.location?.lat,
      lng: place?.geometry?.location?.lng,
    };
  }
  return null;
};

interface InPersonLocationProps {
  serializedLocation: any;
  variant?: "default" | "full" | "text";
}

const InPersonLocationComponent = ({
  serializedLocation,
  variant = "default",
}: InPersonLocationProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: window.GOOGLE_MAPS_API_KEY || "",
    libraries,
  });
  const place = useMemo(
    () => eventLocation(serializedLocation),
    [serializedLocation],
  );
  const location = useMemo(() => locationFromPlace(place), [place]);
  const handleViewMapClick = () => {
    window.open(place.url, "_blank");
  };

  if (!isLoaded) {
    return null;
  }

  if (variant === "default") {
    return (
      <div className="mt-6 flex space-x-3">
        {location && (
          <GoogleMap
            mapContainerClassName="h-16 w-[150px] rounded-md border border-primary"
            zoom={TARGET_ZOOM}
            options={mapOptions}
            center={location}
          >
            <Marker cursor="arrow" position={location} />
          </GoogleMap>
        )}
        <div className="-mt-1">
          <div>
            {place?.name && (
              <Typography.LabelXs weight="semibold">
                {place?.name}
              </Typography.LabelXs>
            )}
            {place?.formatted_address && (
              <Typography.BodyXs>{place?.formatted_address}</Typography.BodyXs>
            )}
          </div>
          {place?.url && (
            <Button
              variant="text"
              onClick={handleViewMapClick}
              className="!text-link !text-xs"
            >
              {t("events.rsvp.view_map")}
            </Button>
          )}
        </div>
      </div>
    );
  }
  if (variant === "full") {
    return (
      <div className="mt-6 flex flex-col space-y-4">
        {location && (
          <GoogleMap
            mapContainerClassName="h-[220px] w-full rounded-md border border-primary"
            zoom={14}
            options={mapOptions}
            center={location}
          >
            <Marker cursor="arrow" position={location} />
          </GoogleMap>
        )}
        <div className="-mt-1">
          {place?.name && place?.url && (
            <Button
              variant="text"
              onClick={handleViewMapClick}
              className="!text-left"
            >
              <Typography.LabelMd weight="semibold" color="text-darkest">
                {place?.name}
              </Typography.LabelMd>
            </Button>
          )}
          {place?.formatted_address && (
            <Typography.BodySm>{place?.formatted_address}</Typography.BodySm>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col space-y-4">
      <div className="-mt-1">
        {place?.name && place?.url && (
          <Button
            variant="text"
            onClick={handleViewMapClick}
            className="!text-left"
          >
            <Typography.LabelSm weight="semibold" color="text-darkest">
              {place?.name}
            </Typography.LabelSm>
          </Button>
        )}
        {place?.formatted_address && (
          <Typography.BodyXs>{place?.formatted_address}</Typography.BodyXs>
        )}
      </div>
    </div>
  );
};

export const InPersonLocation = memo(InPersonLocationComponent);
