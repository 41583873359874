import { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { BadgeV2 } from "@/react/components/shared/uikit/BadgeV2";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { useSpaceGroupsContext } from "@circle-react/contexts";
import { isChatSpace } from "@circle-react/helpers/spaceHelpers";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";

export const AutoAddMembers = ({
  spaceGroupMemberCount,
  chatSpaceMemberLimit,
}) => {
  const {
    helpers: { findBy },
  } = useSpaceGroupsContext();
  const { watch, setValue } = useFormContext();

  const spaceGroupId = watch("space_group_id");
  const post_type = watch("post_type");
  const spaceGroup = findBy({ id: spaceGroupId });

  useEffect(() => {
    if (spaceGroup) {
      setValue(
        "add_members_from_space_group",
        spaceGroup.automatically_add_members_to_new_spaces,
      );
    }
  }, [spaceGroupId]);

  const isToggleDisabled =
    isChatSpace({ post_type }) && spaceGroupMemberCount > chatSpaceMemberLimit;

  return (
    <>
      <div className="flex gap-1 pt-4">
        <div>
          <Typography.LabelSm weight="semibold">
            {t("settings.access_groups.admin.legacy_access.title")}
          </Typography.LabelSm>
        </div>
        <div>
          <TippyV2
            role="tooltip"
            content={t(
              "settings.access_groups.admin.legacy_access.legacy_tooltip",
            )}
            interactive={false}
          >
            <BadgeV2
              square
              label={t(
                "settings.access_groups.admin.legacy_access.legacy_badge",
              )}
            />
          </TippyV2>
        </div>
      </div>
      <TippyV2
        content={t("members_in_space_group_limit_reached")}
        disabled={!isToggleDisabled}
      >
        <Form.Item
          name="add_members_from_space_group"
          label={I18n.t("spaces.form.add_members_from_space_this_group")}
          hideBorder
          hideDescription
          className="!mt-1 !pt-1"
          inline
          shouldToggleValueOnLabelClick
          isDisabled={isToggleDisabled}
        >
          <Form.ToggleSwitch variant="small" disabled={isToggleDisabled} />
        </Form.Item>
      </TippyV2>
    </>
  );
};

AutoAddMembers.propTypes = {
  spaceGroupMemberCount: PropTypes.number,
  chatSpaceMemberLimit: PropTypes.number,
};
