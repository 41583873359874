import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { Icon } from "@/react/components/shared/Icon";
import { Text_INTERNAL } from "@/react/components/shared/uikit/Typography/components/Text_INTERNAL";
import { useExtraLargePlusScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import botBuilderCanvasDark from "@circle-assets/images/bot-builder-canvas-dark.png";
import botBuilderCanvas from "@circle-assets/images/bot-builder-canvas.png";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useThemeContext } from "@circle-react/providers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { ChatPreview } from "../ChatPreview";
import { CollapsedSidebar } from "../CollapsedSidebar";
import { Header } from "../Header";
import { PlatformSupportToggle } from "./PlatformSupportToggle";
import { SnippetsTab } from "./SnippetsTab";
import { SpacesTab } from "./SpacesTab";

export const KnowledgePage = ({ agent }: { agent: AgentProp }) => {
  const [selectedTab, setSelectedTab] = useState<"community" | "custom">(
    "community",
  );

  const onClose = () => {
    window.location.href = "/settings/ai-agents/agents";
  };

  const { isDarkAppearance } = useThemeContext();
  const shouldShowChatPreview = useExtraLargePlusScreenMediaQuery();

  if (!agent) {
    return null;
  }

  return (
    <div>
      <Header agent={agent} onClose={onClose} />
      <div
        className="flex h-full"
        style={{
          backgroundImage: `url(${isDarkAppearance ? botBuilderCanvasDark : botBuilderCanvas})`,
        }}
      >
        <CollapsedSidebar />
        <div className="bg-secondary border-r-primary w-[857px] border-r">
          <div className="bg-primary border-primary flex items-center justify-start gap-2 border-b px-6 py-5">
            <Text_INTERNAL
              as="span"
              color="text-darkest font-[InterVariable,sans-serif]"
              weight="font-semibold"
              size="text-xl"
              leading="leading-6"
              tracking="tracking-[-0.4px]"
              textAlign=""
            >
              {t("community_bot.bot_builder.knowledge_page.title")}
            </Text_INTERNAL>
            <TippyV2
              className="flex h-5 items-center"
              content={
                <div className="flex flex-col">
                  <Typography.BodySm
                    weight="semibold"
                    color="text-dark-inverse"
                  >
                    {t(
                      "community_bot.bot_builder.knowledge_page.title_tooltip",
                    )}
                  </Typography.BodySm>
                </div>
              }
            >
              <Icon type="info" size={20} />
            </TippyV2>
          </div>
          <div className="border-primary flex h-[calc(100vh-67px-66px)] border-b">
            <div className="border-r-primary flex h-full w-[224px] flex-col justify-between border-r px-4 py-5">
              <div className="flex flex-col gap-1">
                <button
                  type="button"
                  className={classNames(
                    "hover:bg-primary flex h-[34px] items-center rounded-lg px-3 py-[10px] text-left",
                    { "bg-primary": selectedTab === "community" },
                  )}
                  onClick={() => setSelectedTab("community")}
                >
                  <Text_INTERNAL
                    as="span"
                    color={classNames("font-[InterVariable,sans-serif] block", {
                      "text-tertiary": selectedTab === "custom",
                      "text-darkest": selectedTab === "community",
                    })}
                    weight="font-medium"
                    size="text-[14px]"
                    leading="leading-[1]"
                    tracking="tracking-normal"
                    textAlign=""
                  >
                    {t(
                      "community_bot.bot_builder.knowledge_page.tabs.community",
                    )}
                  </Text_INTERNAL>
                </button>
                <button
                  type="button"
                  className={classNames(
                    "hover:bg-primary rounded-lg px-3 py-[10px] text-left",
                    { "bg-primary": selectedTab === "custom" },
                  )}
                  onClick={() => setSelectedTab("custom")}
                >
                  <Text_INTERNAL
                    as="span"
                    color={classNames("font-[InterVariable,sans-serif] block", {
                      "text-darkest": selectedTab === "custom",
                      "text-tertiary": selectedTab === "community",
                    })}
                    weight="font-medium"
                    size="text-[14px]"
                    leading="leading-[1]"
                    tracking="tracking-normal"
                    textAlign=""
                  >
                    {t("community_bot.bot_builder.knowledge_page.tabs.custom")}
                  </Text_INTERNAL>
                </button>
              </div>

              <PlatformSupportToggle />
            </div>
            <div className="bg-primary flex h-full w-[662px] justify-center py-6 pl-5 pr-6">
              <div
                className={
                  selectedTab === "community"
                    ? "block w-full overflow-y-scroll pl-1"
                    : "hidden"
                }
              >
                <SpacesTab />
              </div>
              <div
                className={
                  selectedTab === "custom"
                    ? "block w-full overflow-y-scroll pl-1"
                    : "hidden"
                }
              >
                <SnippetsTab />
              </div>
            </div>
          </div>
        </div>
        {shouldShowChatPreview && <ChatPreview />}
      </div>
    </div>
  );
};
