import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPatch,
  reactQueryPost,
} from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

interface ListPushNotificationsParams {
  params: {
    status?: string;
  };
}

const list = ({ params = {} }: ListPushNotificationsParams) =>
  reactQueryGet(internalApi.pushNotifications.list({ params }));

const create = (params: object) =>
  reactQueryPost(internalApi.pushNotifications.create(), params);

const deletePushNotification = ({ id }: { id: number }) =>
  reactQueryDelete(internalApi.pushNotifications.deleteBroadcast({ id }));

const rename = ({
  id,
  body,
}: {
  id: number;
  body: { internal_name: string };
}) => reactQueryPatch(internalApi.pushNotifications.update({ id }), body);

export const pushNotificationsApi = {
  list,
  create,
  deletePushNotification,
  rename,
};
