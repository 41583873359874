import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { sortBy } from "lodash";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useCommunityChat } from "@/react/components/SettingsApp/CommunityInbox/hooks/useCommunityChat";
import { useCreateBotChat } from "@/react/components/SettingsApp/CommunityInbox/hooks/useCreateBotChat";
import { selectInboxMessages } from "@/react/hooks/chatsV2/store/selectors";
import {
  RailbarContextProvider,
  useChatStore,
} from "@circle-react/hooks/chatsV2";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useMessageList } from "../hooks/useCommunityMessageList";
import { ACTIONS, useScroll } from "../hooks/useScroll";
import { ChatWindow } from "./ChatWindow";
import { Frame } from "./Frame";
import { Header } from "./Header";

interface BotChatRoomInterface {
  isDms?: boolean;
  isNewConversation?: boolean;
  previewId?: number;
}

export const BotChatRoom = ({
  isDms = false,
  isNewConversation = false,
  previewId,
}: BotChatRoomInterface) => {
  const isPreviewMode = Boolean(previewId);
  const [botChatRoomId, setBotChatRoomId] = useState(null);
  const { id: roomIdParam } = useParams<{ id: string }>();
  const id: number = previewId ?? Number(roomIdParam || botChatRoomId);
  const { setFirstMessage, setScrollAction, adjustScroller } = useScroll();
  const { createMessage, fetchNextPage, hasNextPage, isFetching } =
    useMessageList({ id, isDms });
  const { createChat } = useCreateBotChat();

  const {
    setCurrentChatId,
    currentBotProfile,
    adminMarkAsRead,
    memberMarkAsRead,
    previewChat,
    setIsProcessing,
    setPreviewChatProcessing,
  } = useCommunityInboxStore();

  const markAsRead = isDms ? memberMarkAsRead : adminMarkAsRead;
  const {
    isChatLoading: isCurrentChatLoading,
    currentChat,
    error,
  } = useCommunityChat(id, isDms);

  const history = useHistory();
  const toastr = useToast();

  if (error) {
    history.push("/settings/ai-inbox");
    toastr.error(t("community_inbox.chats.errors.not_found"));
  }

  const agentName =
    currentChat?.community_bot_profile?.name ||
    currentBotProfile?.name ||
    t("community_inbox.agent");

  const isProcessing = isPreviewMode
    ? !!previewChat?.is_processing
    : !!currentChat?.is_processing;
  const isDisabled =
    !!currentChat?.disabled ||
    (!currentChat?.community_bot_profile?.enabled &&
      !isPreviewMode &&
      !isNewConversation);

  useEffect(() => {
    id && setCurrentChatId(id);
  }, [id, setCurrentChatId]);

  const hasUnread = isDms
    ? currentChat?.has_unread_messages_for_member
    : currentChat?.has_unread_messages_for_admin;

  useEffect(() => {
    if (hasUnread) markAsRead(id);
  }, [id, markAsRead, hasUnread]);

  const messages = useChatStore(selectInboxMessages);
  const sortedMessages = useMemo(() => {
    if (!messages?.length) return [];
    return sortBy(messages || [], "created_at");
  }, [messages]);

  const onFetchNextPage = () => {
    if (isFetching) return;
    setFirstMessage(sortedMessages[0]);
    setScrollAction(ACTIONS.PAGINATION);
    void fetchNextPage();
  };

  useLayoutEffect(() => {
    if (!isFetching && sortedMessages.length > 0) adjustScroller();
  }, [sortedMessages, isFetching, adjustScroller]);

  const onCreateMessage = async (formValues: any) => {
    try {
      if (isNewConversation) {
        const roomData = await createChat(currentBotProfile);
        setBotChatRoomId(roomData.id);
      }
      // Note: We set isProcessing for DMs to true before creating the message
      // to prevent the user from creating multiple messages at the same time
      // isProcessing will be set to false when the chat is updated via the
      // websocket listener
      if (isDms) {
        isPreviewMode
          ? setPreviewChatProcessing(true)
          : setIsProcessing(true, "member");
      }
      await createMessage(formValues);
    } catch (error) {
      console.error("Error while creating message", error);
      if (isDms) {
        isPreviewMode
          ? setPreviewChatProcessing(false)
          : setIsProcessing(false, "member");
      }
    }
  };

  const isCurrentChatUnAvailable =
    !isPreviewMode && !currentChat && !isNewConversation;

  if (isFetching || isCurrentChatLoading || isCurrentChatUnAvailable) {
    return (
      <Frame isDms={isDms}>
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      </Frame>
    );
  }
  const chatCommunityMember = currentChat?.community_member;
  const shouldDisplayHeader = !isPreviewMode && !isNewConversation;
  return (
    <RailbarContextProvider>
      <Frame isDms={isDms}>
        <div className="w-full">
          {shouldDisplayHeader && <Header isDms={isDms} chat={currentChat} />}
          <ChatWindow
            messages={isNewConversation ? [] : sortedMessages}
            createMessage={onCreateMessage}
            isDms={isDms}
            fetchNextPage={onFetchNextPage}
            hasNextPage={Boolean(hasNextPage)}
            isLoading={isFetching}
            isNewConversation={isNewConversation}
            isPreviewMode={isPreviewMode}
            agentName={agentName}
            isProcessing={isProcessing}
            isDisabled={isDisabled}
            chatCommunityMember={chatCommunityMember}
            currentChat={currentChat}
          />
        </div>
        {shouldDisplayHeader && <div id="rail-bar-header" />}
      </Frame>
    </RailbarContextProvider>
  );
};
