import { useMemo } from "react";
import type { Paywall } from "@/react/types";
import { usePaywalls } from "@circle-react/components/PaywallCoupons/Admin/FormFields/usePaywalls";

export const usePaywallsNameMap = () => {
  const { allPaywalls } = usePaywalls();

  return useMemo(
    () =>
      allPaywalls.reduce(
        (accumulator: Record<string, string>, record: Paywall) => {
          accumulator[record.id] = record.display_name;
          return accumulator;
        },
        {},
      ),
    [allPaywalls],
  );
};
