export const SETTINGS_ROUTES = [
  "/settings",
  "/audience/manage",
  "/members/invite",
  "/members/invitation_links",
  "/members/onboarding",
  "/members/bulk_import",
  "/members/edit",
  "/members/profile_fields",
  "/settings/members/activity_logs",
];
