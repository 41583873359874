import { t } from "@/i18n-js/instance";
import { useBroadcastData } from "@circle-react-shared/Emails/BroadcastDataContext";
import { EmailPreview } from "@circle-react-shared/Emails/EmailPreview";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Modal } from "@circle-react-shared/uikit/ModalV2";

interface FullReportsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function PreviewModal({ isOpen, onClose }: FullReportsModalProps) {
  const { data, isLoading } = useBroadcastData();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content
        size="xl"
        shouldCloseOnOutsideClick
        className="grid grid-rows-[auto,_1fr]"
      >
        <Modal.Header>
          <Modal.Title>{t("settings.workflows.view.view_email")}</Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="pb-6 pt-4">
          {isLoading || !data ? (
            <Loader />
          ) : (
            <div className="border-primary h-full overflow-hidden rounded-xl border">
              <EmailPreview />
            </div>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
