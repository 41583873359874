import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useSidebarBorderStyles } from "../Spaces/SidebarV2/hooks";

export const Divider = () => {
  const { borderColor: backgroundColor } = useSidebarBorderStyles();

  return (
    <div
      style={{ backgroundColor }}
      className={classNames("bg-overlay-dark h-px w-9 rounded-full")}
    />
  );
};
