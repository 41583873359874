import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIsActivityScoreEnabled } from "@circle-react/hooks/useIsActivityScoreEnabled";
import { useMemberFilterUrl } from "@circle-react-shared/MemberFilters/useMemberFilterUrlV2";
import { STATUS_OPTIONS_MAP } from "@circle-react-shared/MembersList/constants";

interface FilterParamsReturn {
  query?: string;
  onSort: (sortingState: Array<{ id: string; desc?: boolean }>) => void;
  order: Record<string, "asc" | "desc">;
  filters: Array<{
    key: string;
    value: any;
    filter_type?: string;
  }>;
  scope: {
    status: string;
  };
  [key: string]: any;
}

interface UseFilterParamsArgs {
  accessGroupId: number;
  shouldLoadGroupMembers?: boolean;
}

export const useFilterParams = ({
  accessGroupId,
  shouldLoadGroupMembers,
}: UseFilterParamsArgs): FilterParamsReturn => {
  const history = useHistory();
  const location = useLocation();
  const isMemberActivityScoreEnabled = useIsActivityScoreEnabled();

  /**
   * Default for order field
   * @returns {{[columnId: string]: "asc" | "desc"}}
   */
  const getOrderField = useCallback(() => {
    if (!isMemberActivityScoreEnabled) {
      return {
        profile_confirmed_at: "desc",
      };
    }
    return { activity_score: "desc" };
  }, [isMemberActivityScoreEnabled]);

  const getAccessGroupFilterFilter = () => [
    {
      key: "access_group_ids",
      value: accessGroupId,
      ...(!shouldLoadGroupMembers && { filter_type: "is_not" }),
    },
  ];

  const scope = () => ({
    exclude_empty_profiles: false,
    status: STATUS_OPTIONS_MAP.ALL,
  });

  const getGroupMembersInitialQueryOptions = () => ({
    order: getOrderField(),
    initialPage: 1,
    query: undefined,
    filters: {
      ...getAccessGroupFilterFilter(),
    },
    scope: scope,
  });

  const {
    queryParams: { order, filters, ...rest },
  } = useMemberFilterUrl();

  const handleChangeOrder = ([sortingState]: {
    id: string;
    desc?: boolean | undefined;
  }[]) => {
    const searchParams = new URLSearchParams(location.search);
    if (order) {
      Object.keys(order).forEach(key => {
        searchParams.delete(`order[${key}]`);
      });
    }
    if (sortingState) {
      searchParams.append(
        `order[${sortingState.id}]`,
        sortingState.desc ? "desc" : "asc",
      );
    }
    history.push({
      search: searchParams.toString(),
    });
  };

  return {
    ...rest,
    onSort: handleChangeOrder,
    order: order ?? getOrderField(),
    filters: [...getAccessGroupFilterFilter(), ...filters],
    scope: scope(),
    getAccessGroupFilterFilter: getAccessGroupFilterFilter,
    getOrderField: getOrderField,
    getGroupMembersInitialQueryOptions,
  };
};
