import { t } from "@/i18n-js/instance";
import { QuizResource } from "@circle-react/components/SettingsApp/Workflows/components/Events/Course/shared/QuizResource";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { AnyQuizSelector } from "./shared/AnyQuizSelector";

const i18nRoot = "settings.workflows.form";

export const submittedQuiz: Readonly<EventOption> = {
  label: t([i18nRoot, "events.community_member_submitted_quiz.title"]),
  value: "community_member_submitted_quiz",
  group: t("settings.workflows.form.event_groups.courses"),
  description: t([
    i18nRoot,
    "events.community_member_submitted_quiz.description",
  ]),
  resource: TRIGGER_RESOURCE_TYPE.QUIZ,
  formComponent: AnyQuizSelector,
  viewComponent: QuizResource,
  icon: "course",
  released: true,
};
