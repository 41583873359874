import { t } from "@/i18n-js/instance";
import { AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../../types";
import { FormSendPushNotification } from "./FormSendPushNotification";
import type { HistoryDetailViewNotificationProps } from "./HistoryDetailViewNotification";
import { HistoryDetailViewNotification } from "./HistoryDetailViewNotification";
import { ViewSendPushNotification } from "./ViewSendPushNotification";

export const sendPushNotification: Readonly<
  ActionOption<HistoryDetailViewNotificationProps>
> = {
  label: t("settings.workflows.form.actions.send_notification_to_member"),
  value: "send_notification_to_member",
  group: t("settings.workflows.form.action_groups.communication"),
  leftAddon: <Icon type="16-mobile" size={16} className="!h-4 !w-4" />,
  formComponent: FormSendPushNotification,
  viewComponent: ViewSendPushNotification,
  historyDetailViewComponent: HistoryDetailViewNotification,
  icon: "notification",
  isApplicableToMembersOnly: true,
  availablePlanTier:
    AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS.HIGHER_TIER_PLAN_ONLY,
} as const;
