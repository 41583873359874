import type { ReactNode } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";

export interface SettingsContainerProps {
  children: ReactNode;
  maxWidth?: "md" | "lg" | "xl" | "none";
  className?: string;
}

export const SettingsContainer = ({
  maxWidth = "md",
  className,
  children,
}: SettingsContainerProps) => {
  const maxWidthClasses = {
    md: "max-w-4xl",
    lg: "max-w-[1152px]",
    xl: "max-w-8xl",
    none: "max-w-none",
  };

  const maxWidthClassName = maxWidthClasses[maxWidth] || "";

  return (
    <main
      aria-label={t("main_content")}
      className={classNames(
        "settings-app text-dark mx-auto p-4 lg:px-9 lg:py-12",
        maxWidthClassName,
        className,
      )}
    >
      {children}
    </main>
  );
};
