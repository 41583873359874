import { isEmpty, isNumber, omit } from "lodash";
import {
  PREVIEW_TEXT_MAX_LENGTH,
  SUBJECT_MAX_LENGTH,
} from "@circle-react/components/SettingsApp/Emails/components/Builder/constants";
import { awardPoints } from "@circle-react/components/SettingsApp/Workflows/components/Actions/Gamification/awardPoints";
import { timeDelay } from "../Actions/Flow/TimeDelay";
import {
  disableMessaging,
  enableMessaging,
  removeMember,
  sendDM,
  sendEmail,
  sendPushNotification,
  unsubscribe,
} from "../Actions/Member";
import { subscribeToPaywallTrial } from "../Actions/Paywall";
import { sendToWebhook } from "../Actions/Webhook";
import { hasTruthyValues } from "./hasTruthyValues";

export const getActionHasValue = (action = {}) => {
  const { action_type = "", api_params = {} } = action;

  if (action_type === sendEmail.value) {
    return getSendEmailHasValue(api_params);
  }

  if (action_type === sendDM.value) {
    return getSendDmHasValue(api_params);
  }

  if (action_type === removeMember.value) {
    return true;
  }

  if (action_type === enableMessaging.value) {
    return true;
  }

  if (action_type === disableMessaging.value) {
    return true;
  }

  if (action_type === sendPushNotification.value) {
    return bodyContentHasValue(api_params?.message?.body?.content);
  }

  if (action_type === sendToWebhook.value) {
    return hasTruthyValues(api_params?.url);
  }

  if (action_type === subscribeToPaywallTrial.value) {
    return hasTruthyValues(
      omit(api_params, "send_notification_subscription_created_by_admin"),
    );
  }

  if (action_type === timeDelay.value) {
    return hasTruthyValues(omit(api_params, "time_delay"));
  }

  if (action_type === awardPoints.value) {
    return hasTruthyValues(api_params?.points);
  }

  if (action_type === unsubscribe.value) {
    return true;
  }

  return hasTruthyValues(api_params);
};

const getSendEmailHasValue = (params = {}) => {
  if (!params.internal_name || !params.subject || !params.broadcast_id) {
    return false;
  }
  if (
    params.subject.length > SUBJECT_MAX_LENGTH ||
    params.preview_text?.length > PREVIEW_TEXT_MAX_LENGTH ||
    isEmpty(params.email_content)
  ) {
    return false;
  }

  return !isEmpty(params?.email_content?.body.content);
};

const getSendDmHasValue = (params = {}) => {
  if (!isNumber(params?.from) || isEmpty(params?.rich_text_body)) {
    return false;
  }

  return bodyContentHasValue(params?.rich_text_body?.body?.content);
};

const bodyContentHasValue = (bodyContent = []) => {
  if (isEmpty(bodyContent)) return false;

  return bodyContent.some((value = {}) => {
    const { type = "", content = [], text = "" } = value;

    if (type === "text") {
      return !isEmpty(text);
    }

    if (type === "paragraph") {
      return content.some((value = {}) => {
        const { text = "" } = value;
        return !isEmpty(text);
      });
    }
  });
};
