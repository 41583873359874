import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { reactQueryDelete } from "@/react/helpers/backendRequestHelpers";
import { internalApi, signOutPath } from "@/react/helpers/urlHelpers";
import { ActionModal } from "@circle-react/components/shared/ModalsV2/ActionModal";
import { useCurrentCommunityMember } from "@circle-react/contexts";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";

export const DeactivateAccountConfirmationModal = ({ show, onClose }) => {
  const toast = useToast();
  const currentCommunityMember = useCurrentCommunityMember();

  const hasActiveSubscriptions =
    currentCommunityMember.active_paywall_subscriptions;
  const hasActiveButNonCancelableSubscriptions =
    currentCommunityMember.active_but_non_cancelable_paywall_subscriptions;

  const requestUrl = internalApi.communityMembers.deactivateAccount({
    id: currentCommunityMember.id,
  });
  const { mutateAsync: deactivateAccount, isLoading } = useMutation(
    () => reactQueryDelete(requestUrl),
    {
      onSuccess: () => {
        toast.success(
          t("deactivate_account_confirmation_modal.successfully_deactivated"),
        );
        onClose();
        window.location.href = signOutPath();
      },
      onError: () => {
        toast.error(
          t("deactivate_account_confirmation_modal.error_deactivated"),
        );
      },
    },
  );

  if (hasActiveButNonCancelableSubscriptions) {
    return (
      <ActionModal
        title={t("deactivate_account_confirmation_modal.title")}
        isOpen={show}
        onClose={onClose}
        contentPortalId="deactivate-account-not-allowed"
        actionVariant="primary"
        actionText={t(
          "deactivate_account_confirmation_modal.not_allowed_action",
        )}
        onAction={onClose}
        disabled={isLoading}
      >
        <Typography.LabelMd color="text-default">
          {t("deactivate_account_confirmation_modal.not_allowed_description")}
        </Typography.LabelMd>
      </ActionModal>
    );
  }

  return (
    <ActionModal
      title={t("deactivate_account_confirmation_modal.title")}
      isOpen={show}
      onClose={onClose}
      contentPortalId="deactivate-account"
      actionVariant="danger"
      actionText={t("deactivate_account_confirmation_modal.deactivate")}
      onAction={deactivateAccount}
      disabled={isLoading}
    >
      {hasActiveSubscriptions && (
        <div className="pb-3">
          <Typography.LabelMd color="text-default">
            {t(
              "deactivate_account_confirmation_modal.description_with_subscription",
            )}
          </Typography.LabelMd>
        </div>
      )}
      <Typography.LabelMd color="text-default">
        {t("deactivate_account_confirmation_modal.description")}
      </Typography.LabelMd>
    </ActionModal>
  );
};

DeactivateAccountConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
