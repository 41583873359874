import { Form } from "@circle-react-uikit/Form";
import { useAccessGroupData } from "../AccessGroupMembers/DataStoreContext/AccessGroupDataContext";
import { AccessGroupContentManagement } from "./AccessGroupContentManagement";
import { useAccessGroupContentUpdateConfirmationModal } from "./AccessGroupContentUpdateConfirmationModal";
import { useUpdateAccessGroupContent } from "./hooks/useUpdateAccessGroupContent";

export const AccessGroupContent = () => {
  const { accessGroup } = useAccessGroupData();

  const confirmationModal = useAccessGroupContentUpdateConfirmationModal();
  const { handleSubmit } = useUpdateAccessGroupContent({
    accessGroupId: accessGroup.id,
  });
  const submitHandler = async (contentData: any) => {
    if (accessGroup.has_members) {
      await confirmationModal.show({
        contentData: contentData,
        accessGroupId: accessGroup.id,
      });
    } else {
      handleSubmit(contentData);
    }
  };
  const defaultValues = {
    space_group_ids: accessGroup.space_group_ids,
    space_ids: accessGroup.space_ids,
  };

  return (
    <Form onSubmit={submitHandler} defaultValues={defaultValues}>
      <AccessGroupContentManagement defaultValues={defaultValues} />
    </Form>
  );
};
