import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { accessGroupContentApi } from "@circle-react/api/admin/accessGroups/accessGroupContentApi";
import { queryClient } from "@circle-react/providers";
import type { AccessGroupContentParamsType } from "@circle-react/types/AccessGroup";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { getAccessGroupQueryKey } from "../../hooks/useGetAccessGroup";

interface UpdateAccessGroupContentProps {
  accessGroupId: number;
  closeModal?: () => void;
}

export const useUpdateAccessGroupContent = ({
  accessGroupId,
  closeModal,
}: UpdateAccessGroupContentProps) => {
  const toastr = useToast();
  const { updateAccessGroupContent } = accessGroupContentApi();

  const { mutate: handleSubmit, isLoading } = useMutation({
    mutationFn: (data: AccessGroupContentParamsType) =>
      updateAccessGroupContent({ accessGroupId: accessGroupId, params: data }),
    onSuccess: () => {
      void queryClient.invalidateQueries(getAccessGroupQueryKey(accessGroupId));

      toastr.success(
        t("settings.access_groups.admin.modal.access.messages.updated"),
      );
      closeModal && closeModal();
    },

    onError: (error: any) => {
      toastr.error(error.message);
      closeModal && closeModal();
    },
  });

  return {
    isSubmitting: isLoading,
    handleSubmit,
  };
};
