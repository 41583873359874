import type { ReactNode } from "react";
import classNames from "classnames";
import isFunction from "lodash/isFunction";

export interface TemplateItemWrapperProps {
  children: ReactNode;
  onClick?: () => void;
  centerContent?: boolean;
}

const TemplateItemWrapper = ({
  children,
  onClick,
  centerContent = false,
}: TemplateItemWrapperProps) => {
  const hasOnClick = isFunction(onClick);

  const clickProps = hasOnClick
    ? {
        role: "button",
        onClick,
      }
    : {};

  return (
    <div
      className={classNames(
        "bg-primary border-primary hover:shadow-xs hover:border-secondary flex h-auto w-full max-w-xs flex-col gap-5 rounded-xl border p-5 transition-colors duration-75",
        {
          "cursor-pointer": hasOnClick,
          "items-center justify-center text-center": centerContent,
        },
      )}
      {...clickProps}
    >
      {children}
    </div>
  );
};

export default TemplateItemWrapper;
