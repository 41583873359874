import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useUpcomingInvoiceApi = () => {
  const {
    data: upcomingInvoice,
    isLoading: isUpcomingInvoiceLoading,
    error: errorFetchingUpcomingInvoice,
    refetch: refetchUpcomingInvoice,
    isRefetching: isRefetchingUpcomingInvoice,
  } = useQuery(
    ["community-upcoming-invoice"],
    () =>
      reactQueryGet(
        internalApi.accountPaymentProcessors.invoice.upcomingInvoice(),
      ),
    {
      retry: 10,
    },
  );

  return {
    upcomingInvoice,
    isUpcomingInvoiceLoading,
    errorFetchingUpcomingInvoice,
    refetchUpcomingInvoice,
    isRefetchingUpcomingInvoice,
  };
};
