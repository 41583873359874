import { t } from "@/i18n-js/instance";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { SecureConfirmationModal } from "@circle-react-shared/ModalsV2/SecureConfirmationModal";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useArchiveAccessGroup } from "./../hooks/useArchiveAccessGroup";

interface ArchiveAccessGroupProps {
  accessGroup: AccessGroupType;
  isOpen: boolean;
  onClose: () => void;
}
const i18nBase = "settings.access_groups.admin.form.archive";
export const ArchiveAccessGroup = ({
  accessGroup,
  isOpen,
  onClose,
}: ArchiveAccessGroupProps) => {
  const { mutate: handleArchive, isLoading: isSubmitting } =
    useArchiveAccessGroup({ hideModal: onClose });
  const canBeArchived = accessGroup?.policies?.can_destroy;
  return (
    <SecureConfirmationModal
      title={t([i18nBase, "title"], { name: accessGroup.name })}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      confirmText={t([i18nBase, "action"])}
      confirmVariant="danger"
      isDisabled={isSubmitting || !canBeArchived}
      onConfirm={() => {
        handleArchive({ accessGroupId: accessGroup.id });
      }}
    >
      <div className="flex flex-col gap-2">
        <Typography.BodyMd>
          {accessGroup.has_members
            ? t([i18nBase, "archive_with_members.count"], {
                count: accessGroup.total_members,
              })
            : t([i18nBase, "are_you_sure"])}
        </Typography.BodyMd>
        <Typography.BodyMd>
          {t([i18nBase, "active_workflows_notice"])}
        </Typography.BodyMd>
      </div>
    </SecureConfirmationModal>
  );
};
