import type { ReactNode } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";

export interface OptionItemProps {
  option: {
    value: string;
    label: string;
    disabled?: boolean;
    description?: string;
  };
  checked: boolean;
  rightIcon?: ReactNode;
}

export const OptionItem = ({
  option: { label, disabled, description },
  checked,
  rightIcon,
}: OptionItemProps) => {
  const hasLabel = Boolean(label);

  return (
    <div
      className={classNames("option-group__option-wrapper", {
        disabled: disabled && !checked,
        "!items-start": true,
      })}
    >
      <div
        className={classNames(
          "option-group__option hover:border-secondary hover:border-[1.5px]",
          {
            active: checked,
            "!items-start": true,
          },
        )}
      >
        {hasLabel && (
          <div
            className={classNames(
              "flex w-full flex-row items-center justify-between gap-2",
            )}
          >
            <p className="option-group__option-label flex items-baseline gap-2 !text-left">
              <span className="text-[14px] font-semibold leading-5">
                {label}
              </span>
              {disabled && (
                <span
                  id="coming-soon-pill"
                  className="inline-flex items-center justify-center rounded-[64px] bg-zinc-200 px-3 py-1 font-[InterVariable,sans-serif] text-[10px] font-bold leading-[10px] text-zinc-950"
                >
                  {t("community_bot.bot_builder.behavior_page.soon")}
                </span>
              )}
            </p>

            {rightIcon && (
              <div
                className={classNames("option__icon", {
                  active: checked,
                })}
              >
                {rightIcon}
              </div>
            )}
          </div>
        )}

        {description && (
          <p className="option-group__option-description !text-left">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};
