import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Icon } from "@circle-react-shared/Icon";

const Invited = () => (
  <>
    <Typography.TitleSm weight="semibold">
      {t("please_check_your_email")}
    </Typography.TitleSm>
    <Typography.BodyMd>
      {t("please_check_your_email_description")}
    </Typography.BodyMd>
  </>
);

const NotInvited = () => (
  <>
    <div className="mb-3">
      <Typography.TitleSm weight="semibold">
        {t("this_space_is_private")}
      </Typography.TitleSm>
    </div>
    <div className="mb-4">
      <Typography.BodyMd>
        {t("this_space_is_private_description")}
      </Typography.BodyMd>
    </div>
  </>
);

export const LockedSpaceDefault = ({ isInvited }) => (
  <div
    className="border-primary bg-primary h-full rounded-none border-0 px-10 py-20 text-center sm:h-auto sm:rounded-lg sm:border"
    data-testid="locked-space"
  >
    <Icon type="lock" className="mb-5 !w-14" />
    {isInvited ? <Invited /> : <NotInvited />}
  </div>
);

LockedSpaceDefault.propTypes = {
  isInvited: PropTypes.bool,
};
