import { t } from "@/i18n-js/instance";
import { Collapsible } from "@circle-react/components/shared/uikit/Collapsible";
import { Form } from "@circle-react-uikit/Form";

export const Labels = () => (
  <Collapsible title={t("spaces.form.edit.options.labels.label")}>
    <div className="edit-space__container">
      <Form.Item
        hideBorder
        label={t("spaces.form.edit.options.labels.pinned_posts.label")}
        description={t(
          "spaces.form.edit.options.labels.pinned_posts.description",
        )}
        name="pinned_posts_label"
        variant="extra-bottom-margin"
      >
        <Form.Input placeholder={t("spaces.form.pinned_posts")} />
      </Form.Item>
    </div>
  </Collapsible>
);
