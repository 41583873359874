import { t } from "@/i18n-js/instance";
import { useFetchSpaces } from "@/react/components/SettingsApp/Workflows/hooks/useFetchSpaces";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useCurrentAction } from "../../../ActionRule";

const i18nRoot = "settings.workflows.form";

export const FormSelectSpace = () => {
  const { paramsPath } = useCurrentAction();

  const { data, isLoading, isError } = useFetchSpaces();
  const { records: spaces = [] } = data || {};

  const options = spaces.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  if (isLoading) {
    return <Loader center />;
  }

  if (isError) {
    return (
      <Typography.LabelXs>
        <span className="text-v2-danger">
          {t([i18nRoot, "spaces.fetch_failed"])}
        </span>
      </Typography.LabelXs>
    );
  }

  return (
    <FormTextSelect
      name={`${paramsPath}.space_id`}
      label={
        <Typography.LabelSm weight="semibold">
          {t([i18nRoot, "select_space"])}
        </Typography.LabelSm>
      }
      options={options}
      rules={{
        required: t([i18nRoot, "errors.required"]),
      }}
      emptyMessage={t([i18nRoot, "errors.spaces.not_found"])}
    />
  );
};

FormSelectSpace.propTypes = {};
