import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";

export const StatusHeader = () => (
  <>
    {t("settings.paywalls.modal.tabs.pricing.table.columns.status")}
    <span className="ml-2 inline-flex cursor-pointer">
      <TippyV2
        content={t("settings.paywalls.modal.tabs.pricing.tooltip_text.status")}
      >
        <Icon type="question-mark" className="icon icon--xs" />
      </TippyV2>
    </span>
  </>
);
