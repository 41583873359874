import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { workflowsApi } from "@/react/api";
import { useToast } from "@circle-react-uikit/ToastV2";
import type { UpdateWorkflow } from "../generated/WorkflowsApi.types";

export const useUpdateWorkflow = (options = {}) => {
  const toast = useToast();

  return useMutation<void, Error, { id: string; body: UpdateWorkflow }>(
    ({ id, body }) => workflowsApi.update({ id, body }),
    {
      onError: (error: Error) => {
        console.error(error);
        toast.error(error.message);
      },
      ...options,
    },
  );
};

export const useUpdateWorkflowForm = (options = {}) => {
  const { watch } = useFormContext();
  const mutation = useUpdateWorkflow(options);
  const id = watch("id");

  return {
    ...mutation,
    mutate: (body: UpdateWorkflow) => mutation.mutate({ id, body }),
    mutateAsync: (body: UpdateWorkflow) => mutation.mutateAsync({ id, body }),
  };
};
