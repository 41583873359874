import { differenceInHours } from "date-fns";
import { noop } from "lodash";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { EventDropdownActions as EventDropdownActionsV3 } from "@/react/components/PostsV3/PostViewListItem/PostActionsDropdownMenu/NewPostActionsDropdown/EventDropdownActions";
import { PostBody as PostBodyV3 } from "@/react/components/PostsV3/PostViewListItem/PostBody";
import { useSearchParams } from "@/react/components/PostsV3/Show/useSearchParams";
import { InvitedByTab } from "@circle-react/components/Events/NewEventsSpace/shared/InvitedByTab";
import {
  LOCATION_TYPES,
  RSVP_STATUS,
} from "@circle-react/components/Events/helpers/constants";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { CommentsSection as CommentsSectionV3 } from "@circle-react/components/PostsV3/PostViewListItem/CommentsSection";
import { EngagementActions as EngagementActionsV3 } from "@circle-react/components/PostsV3/PostViewListItem/EngagementActions";
import { useCurrentPostContext } from "@circle-react/contexts";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useLargeScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { contentTypeParser } from "@circle-react-shared/uikit/TipTap/utilities/contentTypeParser";
import { TranscriptsPlayer } from "@circle-react-uikit/TranscriptsPlayer";
import { Typography } from "@circle-react-uikit/Typography";
import { ConfirmationModal } from "../../Rsvp/ConfirmationModal";
import { STATUS } from "../../helpers/constants";
import { EventTimeChip } from "../ListViewItem/EventTimeChip";
import { CoverImage } from "../shared";
import { canRespondToInvitation, isEventFinished } from "../shared/helpers";
import { Attendees } from "./Attendees";
import { Host } from "./Host";
import { InPersonLocation } from "./InPersonLocation";
import { ShareEvent } from "./ShareEvent";
import { Sidebar } from "./Sidebar";
import { useComments } from "./useComments";

export const EventDetail = ({
  onDestroy = noop,
  onChange = noop,
  shouldOpenCommentPage = false,
  isSharedPostMinimalLayout = false,
}) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const { isPaymentSuccessful } = useSearchParams();
  const { record: event, refetchPostDetails } = useCurrentPostContext();
  const isSmallScreen = useLargeScreenMediaQuery();
  const history = useHistory();

  const {
    name,
    status,
    event_setting_attributes: eventData,
    pin_to_top: isPinned,
    post_details_loaded,
    is_comments_enabled,
    rsvped_event: rsvpedEvent,
    body_trix_content: bodyTrixContent,
    tiptap_body: tiptapBody,
    internal_custom_html: html,
    community_member: host,
    hide_meta_info: hideMetaInfo,
    rsvp_status: rsvpStatus,
  } = event;

  const hasEventDescription = bodyTrixContent || tiptapBody || html;
  const {
    live_stream_room_setting_attributes: liveRoomSettingAttributes,
    in_person_location: inPersonLocation,
    hide_location_from_non_attendees: hideLocationFromNonAttendees,
    location_type: locationType,
    starts_at: startsAt,
    ends_at: endsAt,
  } = eventData || {};

  const isAutoPostRecordingEnabled =
    !!liveRoomSettingAttributes?.auto_post_recording_enabled;
  const recordingAttachment = liveRoomSettingAttributes?.recording_attributes;
  const isVideoTranscodingEnabled =
    Boolean(recordingAttachment?.content_type) &&
    Boolean(recordingAttachment?.media_transcoding) &&
    contentTypeParser.isVideo(recordingAttachment?.content_type);

  const {
    commentsFormRef,
    shouldAutofocusReplyForm,
    shouldShowComment,
    toggleComment,
    showComments,
  } = useComments();

  const closeConfirmationModal = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("payment_success");
    history.push(url);
  };

  const isPublished = status === STATUS.PUBLISHED;
  const isInPersonEventType = locationType === LOCATION_TYPES.IN_PERSON;
  const shouldHideLocation = !rsvpedEvent && hideLocationFromNonAttendees;
  const shouldDisplayRsvpAction =
    isPublished && !isEventFinished(event) && canRespondToInvitation(event);
  const shouldDisplayInPersonLocation =
    !shouldHideLocation && isInPersonEventType && inPersonLocation;

  const isEventStartingSoon =
    differenceInHours(new Date(startsAt), new Date()) <= 5;
  const isAttendeeInvited = rsvpStatus == RSVP_STATUS.INVITED;

  const showRecording =
    recordingAttachment?.url &&
    isAutoPostRecordingEnabled &&
    (!shouldHideLocation || isAdmin) &&
    (!isPaidEvent(event) || rsvpStatus == "yes");

  return (
    <div
      className={classNames("flex w-full flex-col", {
        "py-8": isSharedPostMinimalLayout,
      })}
    >
      <CoverImage isPinned={isPinned} event={event} isFullView />
      {isAttendeeInvited && <InvitedByTab name={host.name} />}
      <div className="flex flex-row space-x-6">
        <div
          className={classNames(
            "bg-primary h-full w-full sm:rounded-md md:w-[680px] md:rounded-2xl",
            {
              "border-primary border": !isSharedPostMinimalLayout,
            },
          )}
        >
          <div
            className={classNames({
              "flex flex-col gap-5 p-5 md:px-6": !isSharedPostMinimalLayout,
              "flex flex-col gap-5 px-0 py-5 md:px-6":
                isSharedPostMinimalLayout,
            })}
          >
            {isEventStartingSoon && (
              <div className="flex w-full justify-between">
                <EventTimeChip
                  startsAt={startsAt}
                  endsAt={endsAt}
                  status={status}
                />
              </div>
            )}
            <div className="flex w-full items-start justify-between">
              <Typography.TitleMd
                as="h1"
                leading="!leading-7"
                dataTestId="event-title"
              >
                {name}
              </Typography.TitleMd>
              {!isSmallScreen && (
                <div className="flex items-center gap-0.5">
                  <BookmarkButton
                    bookmarkType="event"
                    record={event}
                    onSuccess={refetchPostDetails}
                    className={classNames("!text-default", {
                      "group-hover:!text-dark": !event.bookmark_id,
                    })}
                  />
                  <ShareEvent onChange={onChange} />
                  <EventDropdownActionsV3
                    onChange={onChange}
                    onPostDestroy={onDestroy}
                  />
                </div>
              )}
            </div>
            <div className="m-0 flex md:mx-auto md:my-6 md:hidden">
              <Sidebar
                onChange={onChange}
                shouldDisplayRsvpAction={shouldDisplayRsvpAction}
              />
            </div>
            {!hideMetaInfo && (
              <div className="hidden md:block">
                <Host host={host} />
              </div>
            )}
            {hasEventDescription && (
              <div
                className={classNames({
                  "border-primary md:border-t": !hideMetaInfo,
                })}
              >
                <div className="py-5">
                  <Typography.TitleSm weight="semibold" leading="leading-4">
                    {t("event_details.details")}
                  </Typography.TitleSm>
                </div>
                <PostBodyV3 enableTruncation={false} noPadding />
              </div>
            )}
            {showRecording && (
              <div className="border-primary border-t pb-[30px] pt-7">
                <Typography.TitleSm weight="semibold" leading="leading-4">
                  {t("event_details.recording")}
                </Typography.TitleSm>
                <div className="mt-6">
                  <TranscriptsPlayer
                    src={recordingAttachment.url}
                    type={recordingAttachment.content_type}
                    mediaTranscoding={recordingAttachment.media_transcoding}
                    mediaTranscriptId={recordingAttachment.media_transcript_id}
                    isDownloadable={recordingAttachment.is_downloadable}
                    poster={recordingAttachment.image_variants?.thumbnail}
                    variants={recordingAttachment.video_variants}
                    signedId={recordingAttachment.signed_id}
                    isTranscodingEnabled={isVideoTranscodingEnabled}
                  />
                </div>
              </div>
            )}
            {shouldDisplayInPersonLocation && (
              <div className="border-primary border-t pb-[30px] pt-7">
                <Typography.TitleSm weight="semibold" leading="leading-4">
                  {t("event_details.location")}
                </Typography.TitleSm>
                <InPersonLocation
                  serializedLocation={inPersonLocation}
                  variant="full"
                />
              </div>
            )}
          </div>
          <div className="post !mb-0 !border-none sm:!rounded-md md:!rounded-2xl">
            <div>
              <EngagementActionsV3
                toggleComment={toggleComment}
                showCommentAndFocusCommentForm={showComments}
                shouldOpenCommentPage={shouldOpenCommentPage}
                className="!py-3.5"
              />
              {post_details_loaded &&
                is_comments_enabled &&
                shouldShowComment && (
                  <CommentsSectionV3
                    commentsFormRef={commentsFormRef}
                    autofocusReplyForm={shouldAutofocusReplyForm}
                    className={classNames({
                      "px-0 md:px-6": isSharedPostMinimalLayout,
                    })}
                  />
                )}
            </div>
          </div>
        </div>
        <div className="hidden w-[320px] flex-col space-y-6 md:flex">
          <Sidebar
            onChange={onChange}
            shouldDisplayRsvpAction={shouldDisplayRsvpAction}
          />
          <Attendees event={event} />
        </div>
      </div>
      {isPaymentSuccessful && (
        <ConfirmationModal
          event={event}
          events={[event]}
          show={isPaymentSuccessful}
          onClose={closeConfirmationModal}
        />
      )}
    </div>
  );
};
