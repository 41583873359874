import { truncate } from "lodash";
import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";
import { CheckboxCell } from "./CheckboxCell";
import { CheckboxColumnHeader } from "./CheckboxColumnHeader";
import { TypeBadge } from "./TypeBadge";

export const generateColumns = (
  selectedIds: number[],
  handleCheckboxChange: (id: number) => void,
  handleSelectAllOnPage: (checked: boolean) => void,
  allIdsOnPage: number[],
  type: "spaces" | "snippets",
) =>
  [
    {
      header: (
        <CheckboxColumnHeader
          allOnPageSelected={
            allIdsOnPage.length > 0 &&
            allIdsOnPage.every(id => selectedIds.includes(id))
          }
          handleSelectAllOnPage={handleSelectAllOnPage}
        />
      ),
      accessorKey: "checkbox",
      cellClassName: "align-middle gap-0 !px-0",
      thClassName: "!px-0 w-4",
      canSort: false,
      cell: ({ row }: any) => (
        <CheckboxCell
          row={row}
          handleCheckboxChange={handleCheckboxChange}
          selectedIds={selectedIds}
        />
      ),
    },
    {
      header: (
        <span className="text-xxs text-default font-semibold uppercase leading-tight tracking-wide">
          {t(
            "community_bot.bot_builder.knowledge_page.import_table.columns.title",
          )}
        </span>
      ),
      accessorKey: "title",
      cell: ({ row }: any) => (
        <Typography.LabelSm weight="semibold">
          {type === "spaces"
            ? truncate(row.original?.name, { length: 40 })
            : truncate(row.original?.title, { length: 60 })}
        </Typography.LabelSm>
      ),
      canSort: false,
    },
    {
      header: t(
        "community_bot.bot_builder.knowledge_page.import_table.columns.type",
      ),
      accessorKey: "type",
      cell: ({ row }: any) => (
        <TypeBadge
          type={row.original?.type || (type === "spaces" ? "Post" : "Snippet")}
        />
      ),
      canSort: false,
    },
    type === "spaces" && {
      header: t(
        "community_bot.bot_builder.knowledge_page.import_table.columns.items_included",
      ),
      accessorKey: "items_included",
      cell: ({ row }: any) => (
        <Typography.LabelSm weight="regular">
          {row.original?.posts_count}
        </Typography.LabelSm>
      ),
      canSort: false,
    },
    {
      header: t(
        "community_bot.bot_builder.knowledge_page.import_table.columns.used_in_replies",
      ),
      accessorKey: "used_in_replies",
      cell: ({ row }: any) => (
        <Typography.LabelSm weight="regular">
          {row.original?.used_in_replies}
        </Typography.LabelSm>
      ),
      canSort: false,
    },
  ].filter(Boolean);
