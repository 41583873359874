import { useRef } from "react";
import { uniqueId } from "lodash";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Form } from "@circle-react-uikit/Form";
import { useRenameAccessGroup } from "./hooks/useRenameAccessGroup";

const i18nBase = "settings.access_groups.admin.form";

const formId = uniqueId();

interface RenameAccessGroupModalContentType {
  accessGroup: AccessGroupType;
}

export const RenameAccessGroupModalContent = ({
  accessGroup,
}: RenameAccessGroupModalContentType) => {
  const initialFocusRef = useRef(null);
  const modal = useModal();

  const { mutate: handleRename, isLoading: isSubmitting } =
    useRenameAccessGroup({
      id: accessGroup.id,
    });

  const handleSubmit = (data: { name: string; description: string }) => {
    handleRename(data, {
      onSuccess: () => modal.hide(),
    });
  };

  return (
    <ConfirmationModal
      formId={formId}
      isOpen={modal.visible}
      onClose={modal.hide}
      title={t([i18nBase, "update_title"])}
      confirmVariant="circle"
      confirmText={t(["save"])}
      disabled={isSubmitting}
      initialFocusRef={initialFocusRef}
    >
      <Form
        id={formId}
        mode="onChange"
        defaultValues={{
          name: accessGroup.name,
          description: accessGroup.description || "",
        }}
        onSubmit={data => handleSubmit(data)}
      >
        <Form.Item
          name="name"
          translationRoot={i18nBase}
          hideDescription
          hidePlaceholder
          hideBorder
          rules={{
            required: t([i18nBase, "validations.name_required"]),
          }}
          tooltipText={t([i18nBase, "tooltips.name"])}
        >
          <Form.Input ref={initialFocusRef} />
        </Form.Item>
        <Form.Item
          name="description"
          translationRoot={i18nBase}
          hideDescription
          hidePlaceholder
          hideBorder
          tooltipText={t([i18nBase, "tooltips.description"])}
        >
          <Form.Input />
        </Form.Item>
      </Form>
    </ConfirmationModal>
  );
};

const RenameAccessGroupModal = ModalManager.create(
  ({ accessGroup }: RenameAccessGroupModalContentType) => (
    <RenameAccessGroupModalContent accessGroup={accessGroup} />
  ),
);

export const useRenameAccessGroupModal = () => useModal(RenameAccessGroupModal);
