import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { getActionIcon } from "../Actions";

const actionsRoot = "settings.workflows.view.actions";

export const ActionList = () => {
  const { watch } = useFormContext();
  const actions = watch("actions") || [];

  const actionTitles = actions
    .map(action => action?.action_type)
    .filter(Boolean)
    .map(actionType => {
      const title = t([actionsRoot, actionType, "title"], {
        defaultValue: t("settings.workflows.form.add_an_action"),
      });

      const icon = getActionIcon(actionType);

      return { title, icon };
    });

  return (
    <>
      {actionTitles.map(({ title, icon }, index) => (
        <div key={index} className="flex flex-row items-center gap-3">
          <Icon
            type={`24-workflow-${icon}`}
            size={24}
            useWithFillCurrentColor
            className="!h-5 !w-5"
          />
          <Typography.BodySm>{title}</Typography.BodySm>
        </div>
      ))}
    </>
  );
};
