import { useRouteMatch } from "react-router-dom";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useIsCustomColorTheme } from "@circle-react/hooks/useIsCustomColorTheme";
import { useMasqueradeEnabledAndActive } from "@circle-react/hooks/useMasqueradeEnabledAndActive";
import { useThemeContext } from "@circle-react/providers";
import { useSidebarBorderStyles } from "../Spaces/SidebarV2/hooks";

export const useCommunitySwitcherStyles = (className?: string) => {
  const isCustomColorTheme = useIsCustomColorTheme();
  const bodyStyles = useSidebarBorderStyles();

  const themeContext = useThemeContext();
  const isDarkAppearance = Boolean(themeContext.isDarkAppearance);

  const analyticsRoute = useRouteMatch({ path: "/analytics", exact: false });
  const isAnalyticsRoute = Boolean(analyticsRoute);

  const isDefault = !isDarkAppearance && !isCustomColorTheme;
  const isDarkDefault = isDarkAppearance && !isCustomColorTheme;
  const hasMasquerade = useMasqueradeEnabledAndActive();

  const v3Presentation = classNames(
    "community__switcher",
    className,
    "min-h-screen",
    {
      "!bg-c-switcher border-primary border-r": isDefault,
      "!bg-c-switcher border-c-sidebar border-r": isDarkDefault,
    },
  );

  const v3Body = classNames("min-h-screen p-4", {
    "!bg-c-sidebar overflow-hidden border-r": isCustomColorTheme,
    "!top-24": !isAnalyticsRoute && hasMasquerade,
  });

  return {
    presentationClasses: v3Presentation,
    bodyClasses: v3Body,
    bodyStyles,
  };
};
