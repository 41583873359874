import { MainAppProviders } from "@circle-react/providers";

export const withMainAppProviders = (Component: any) => {
  const ComponentWithProviders = (props: any) => (
    <MainAppProviders>
      <Component {...props} />
    </MainAppProviders>
  );

  ComponentWithProviders.displayName = `withMainAppProviders(${
    Component.displayName || Component.name || "Component"
  })`;

  return ComponentWithProviders;
};
