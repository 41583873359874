import { Container } from "@circle-react/components/shared/Container";
import { useHasPostRightSidebar } from "../hooks/useHasPostRightSidebar";

export const VersionedPostWrapper = ({ children }) => {
  const hasPostRightSidebar = useHasPostRightSidebar();

  return (
    <Container maxWidth={hasPostRightSidebar ? "lg" : "md"}>
      {children}
    </Container>
  );
};
