import { StatusIndicator } from "@circleco/ui-kit";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useSmOrMdOrLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import {
  chatRoomsPath,
  communityInboxPath,
  newChatRoomPath,
} from "@circle-react/helpers/urlHelpers";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import type { Chat } from "@circle-react/types/CommunityInbox/Chat";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";
import { Typography } from "@circle-react-uikit/Typography";
import { PauseAIButton } from "./PauseAIButton";
import { Railbar } from "./Railbar";

export interface HeaderProps {
  isDms: boolean;
  chat?: Chat | null;
}

export const Header = ({ isDms, chat }: HeaderProps) => {
  const { setCurrentBotProfile } = useCommunityInboxStore();
  const { shouldShowRailBar, toggleShowRailBar } = useRailbar();
  const history = useHistory();
  const isSmallScreen = useSmOrMdOrLgScreenMediaQuery();
  const {
    community_member: communityMember,
    community_bot_profile: communityBotProfile,
  } = chat || {};

  const handleNewChat = () => {
    setCurrentBotProfile(communityBotProfile || null);
    history.push(newChatRoomPath());
  };

  const author = isDms ? communityBotProfile : communityMember;
  return (
    <div className="border-primary bg-primary flex h-14 w-full items-center border-b px-6 py-1">
      {!chat ? (
        <RowContainer full>
          <Row xxl extraLong />
        </RowContainer>
      ) : (
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            {isSmallScreen && (
              <IconButton
                name="24-arrow-left"
                iconSize={24}
                ariaLabel={t("back")}
                className="mr-2"
                onClick={() =>
                  history.push(isDms ? chatRoomsPath() : communityInboxPath())
                }
              />
            )}
            <div className="max-w-[200px] md:max-w-[300px]">
              <div className="flex items-center gap-2">
                <Typography.LabelMd weight="semibold" as="h2" truncate>
                  {author?.name}
                </Typography.LabelMd>
                {author &&
                  "status" in author &&
                  (author.status === "idle" || author.status === "online") && (
                    <StatusIndicator status={author.status} />
                  )}
              </div>
            </div>
          </div>
          <div className="flex space-x-4">
            {!isDms && <PauseAIButton chat={chat} />}
            {author === communityBotProfile && (
              <TippyV2
                interactive={false}
                content={t("chat_space.header.new_conversation")}
              >
                <button
                  type="button"
                  className="hover:bg-tertiary focus-visible:outline-secondary mt-1 hidden h-6 w-6 rounded transition-colors duration-200 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 lg:block"
                  onClick={handleNewChat}
                >
                  <Icon
                    type="new-conversation"
                    className="text-dark ml-0.5 mt-0.5"
                    useWithFillCurrentColor
                  />
                </button>
              </TippyV2>
            )}
            <button
              type="button"
              className="hover:bg-tertiary focus-visible:outline-secondary mt-1 hidden h-6 w-6 rounded transition-colors duration-200 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 lg:block"
              onClick={toggleShowRailBar}
            >
              <Icon
                aria-label={
                  shouldShowRailBar
                    ? t("chat_space.header.collapse_railbar")
                    : t("chat_space.header.expand_railbar")
                }
                className="text-dark ml-0.5 mt-0.5"
                useWithFillCurrentColor
                type={
                  shouldShowRailBar
                    ? "rail-collapse-right"
                    : "rail-collapse-left"
                }
              />
            </button>
          </div>
        </div>
      )}
      {shouldShowRailBar && <Railbar author={author} isDms={isDms} />}
    </div>
  );
};
