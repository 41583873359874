import { t } from "@/i18n-js/instance";
import { FormItem } from "@circle-react/components/SettingsApp/General/FormItem";
import { useCurrentAction } from "@circle-react/components/SettingsApp/Workflows/components/ActionRule";
import { Form } from "@circle-react-shared/uikit/Form";

const minValue = 1;
const maxValue = 500;

export const i18nRoot = "settings.workflows.form.award_points";

export const FormChoosePoints = () => {
  const { paramsPath } = useCurrentAction();
  return (
    <div>
      <FormItem
        name={`${paramsPath}.points`}
        hideDescription
        label={t([i18nRoot, "points.label"])}
        placeholder={t([i18nRoot, "points.placeholder"])}
        translationRoot={i18nRoot}
        rules={{
          required: t([i18nRoot, "errors.points.required"]),
          min: {
            value: minValue,
            message: t([i18nRoot, "errors.points.enter_a_value_between"], {
              start: minValue,
              end: maxValue,
            }),
          },
          max: {
            value: maxValue,
            message: t([i18nRoot, "errors.points.enter_a_value_between"], {
              start: minValue,
              end: maxValue,
            }),
          },
        }}
      >
        <Form.Input
          type="number"
          name={`${paramsPath}.points`}
          data-testid="points"
        />
      </FormItem>
      <FormItem
        name={`${paramsPath}.reason`}
        label={t([i18nRoot, "reason.label"])}
        hideDescription
        placeholder={t([i18nRoot, "reason.placeholder"])}
        translationRoot={i18nRoot}
        rules={{
          maxLength: {
            value: 255,
            message: t([i18nRoot, "errors.reason.max_length"]),
          },
        }}
      >
        <Form.Input
          type="text"
          name={`${paramsPath}.reason`}
          data-testid="reason"
        />
      </FormItem>
    </div>
  );
};
