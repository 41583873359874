/* eslint-disable no-restricted-syntax -- Not translatable strings */
import { Portal } from "@material-ui/core";
import type { Editor } from "@tiptap/react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { InsertVariableButton } from "@circle-react-uikit/InsertVariableButton";

const VARIABLES = [
  {
    group: t("dynamic_vars_groups.community"),
    label: "{{community.name}}",
    value: "{{community.name}}",
  },
  {
    group: t("dynamic_vars_groups.member"),
    label: "{{member.first_name}}",
    value: "{{member.first_name}}",
  },
  {
    group: t("dynamic_vars_groups.member"),
    label: "{{member.last_name}}",
    value: "{{member.last_name}}",
  },
  {
    group: t("dynamic_vars_groups.course"),
    label: "{{space.name}}",
    value: "{{space.name}}",
  },
  {
    group: t("dynamic_vars_groups.course"),
    label: "{{section.name}}",
    value: "{{section.name}}",
  },
  {
    group: t("dynamic_vars_groups.course"),
    label: "{{first_lesson_in_section.link}}",
    value: "{{first_lesson_in_section.link}}",
  },
];
interface InsertVariablesMenuProps {
  editor: Editor | null;
  isEditorFocused: boolean;
  portalId: string;
}

export function InsertVariablesMenu({
  editor,
  isEditorFocused,
  portalId,
}: InsertVariablesMenuProps) {
  if (!editor) {
    return null;
  }

  const menuIconClass = classNames(
    isEditorFocused
      ? "text-default dark:text-dark"
      : "text-lightest dark:text-default",
  );

  return (
    <Portal container={() => document.getElementById(portalId)}>
      <InsertVariableButton
        wrapperClassName="px-8 w-80 pt-3"
        optionsClassName="bottom-8"
        className={menuIconClass}
        variables={VARIABLES}
        editor={editor}
        tooltipPlacement="top"
      />
    </Portal>
  );
}
