import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { useNotificationsApi } from "@/react/hooks/notifications/useNotificationsApi";
import { usePunditUserContext } from "@circle-react/contexts";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useWindowResize } from "@circle-react/hooks/utils/useWindowResize";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Pagination } from "@circle-react-shared/Pagination";
import { usePopoverPortalContext } from "@circle-react-uikit/PopoverPortal";
import { Typography } from "@circle-react-uikit/Typography";
import { GroupTabs } from "./GroupTabs";
import { Header } from "./Header";
import { List } from "./List";
import "./styles.scss";

export const NotificationsList = ({
  loadPagination = true,
  containerElement,
  handleClose,
  isDropdown = false,
  isFullPage = false,
}) => {
  const DEFAULT_NOTIFICATION_GROUP = "inbox";
  const {
    notifications,
    hasNextPage,
    dataLoaded,
    setDataLoaded,
    setHasNextPage,
    markAllAsRead,
    fetchNotifications,
    fetchFirstPage,
    markAsRead,
    unfollowNotification,
    followNotification,
    archiveNotification,
    resizeNotificationWindow,
  } = useNotificationsApi();
  const { onClose = noop } = usePopoverPortalContext() || {};
  const [notificationGroup, setNotificationGroup] = useState(
    DEFAULT_NOTIFICATION_GROUP,
  );
  const {
    currentCommunity: { current_plan_tier },
  } = usePunditUserContext();

  useWindowResize(resizeNotificationWindow);

  useEffect(() => {
    fetchFirstPage(notificationGroup);
  }, [notificationGroup]);

  return (
    <>
      {!isFullPage && (
        <Header markAllAsRead={markAllAsRead} handleClose={handleClose} />
      )}
      <GroupTabs
        mobile
        notificationGroup={notificationGroup}
        setNotificationGroup={setNotificationGroup}
        setDataLoaded={setDataLoaded}
        setHasNextPage={setHasNextPage}
      />
      <div
        id={containerElement + "NotificationWrapperInner"}
        className={classNames("relative flex h-full flex-col justify-between", {
          "h-[calc(100%-109px)] overflow-auto rounded-b": isDropdown,
          "overflow-auto": isFullPage,
        })}
      >
        {dataLoaded || hasNextPage ? (
          !notifications.length ? (
            <div className="notifications__empty">
              <div>{t("no_notifications")}</div>
            </div>
          ) : (
            <ul className="notifications__list">
              {notifications
                .filter(item => item?.action_web_path)
                .map(notification => (
                  <List
                    onClickNotification={e => {
                      if (isIntendedOnNewTab(e)) {
                        return;
                      }
                      isDropdown ? onClose() : handleClose?.();
                    }}
                    key={notification.id}
                    notification={notification}
                    markAsRead={markAsRead}
                    unfollowNotification={unfollowNotification}
                    followNotification={followNotification}
                    archiveNotification={archiveNotification}
                  />
                ))}
            </ul>
          )
        ) : (
          <LoaderRow />
        )}
        {loadPagination && hasNextPage && (
          <Pagination
            fetchNewPage={page => fetchNotifications(page, notificationGroup)}
            initialPage={2}
            componentId={
              containerElement
                ? containerElement + "NotificationWrapperInner"
                : null
            }
          />
        )}
        {current_plan_tier && (
          <span className="w-full bg-white py-4 text-center">
            <Typography.LabelXs>
              {t("notification_retention_message", {
                months: t(`notification_retention_months.${current_plan_tier}`),
              })}
            </Typography.LabelXs>
          </span>
        )}
      </div>
    </>
  );
};

NotificationsList.propTypes = {
  loadPagination: PropTypes.bool,
  containerElement: PropTypes.string,
  handleClose: PropTypes.func,
  isDropdown: PropTypes.bool,
  isFullPage: PropTypes.bool,
};
