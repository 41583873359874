import { t } from "@/i18n-js/instance";
import { useSpaceGroupResource } from "@circle-react/contexts";
import { BaseResource } from "../../BaseResource";

export const SpaceGroupResource = ({ resourceId }: { resourceId: number }) => {
  const { data: spaceGroup, isLoading } = useSpaceGroupResource(resourceId);
  return (
    <BaseResource
      label={t("settings.workflows.view.resources.space_group")}
      value={spaceGroup?.name}
      isLoading={isLoading}
    />
  );
};
