import type { ReactNode } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export interface BodyProps {
  hasPaddingTopMobile?: boolean;
  isMinimalLayout?: boolean;
  children?: ReactNode;
  className?: string;
  wrapperClassName?: string;
}

export const Body = ({
  hasPaddingTopMobile = true,
  isMinimalLayout,
  children,
  className,
  wrapperClassName,
}: BodyProps) => (
  <div
    className={classNames(
      "main h-full",
      {
        "bg-secondary": !isMinimalLayout,
      },
      className,
    )}
  >
    <div
      className={classNames(
        "main__wrapper h-full sm:mx-6 md:pt-6 lg:mx-9",
        {
          "pt-0": !hasPaddingTopMobile,
          "px-4 sm:px-0": isMinimalLayout,
        },
        wrapperClassName,
      )}
    >
      {children}
    </div>
  </div>
);
