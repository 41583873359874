import { t } from "@/i18n-js/instance";
import { STATUS } from "@circle-react/helpers/communityMemberChargeHelpers";

export const RESULTS_PER_PAGE = 10;

export const TABS = {
  ALL: "all",
  PAID: "paid",
  REFUNDED: "refunded",
  FAILED: "failed",
};

export const TABS_LOCALE = {
  [TABS.ALL]: t("settings.paywalls_admin_dashboard.charges.tabs.all"),
  [TABS.PAID]: t("settings.paywalls_admin_dashboard.charges.tabs.paid"),
  [TABS.REFUNDED]: t("settings.paywalls_admin_dashboard.charges.tabs.refunded"),
  [TABS.FAILED]: t("settings.paywalls_admin_dashboard.charges.tabs.failed"),
};

export const statusByTab = {
  [TABS.ALL]: null,
  [TABS.PAID]: [STATUS.PAID, STATUS.PARTIAL_REFUNDED].join(","),
  [TABS.REFUNDED]: STATUS.REFUNDED,
  [TABS.FAILED]: STATUS.FAILED,
};
