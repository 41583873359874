import type { Profile } from "@circle-react/types/CommunityBot/Profile";
import { Icon } from "@circle-react-shared/Icon";

interface AgentAvatarProps {
  profile: Profile;
}

export const AgentAvatar = ({ profile }: AgentAvatarProps) => (
  <>
    {!profile.avatar_url && (
      <Icon type="36-ai-bot" size={36} className="!h-6 !w-6" />
    )}
    {profile.avatar_url && (
      <img
        src={profile.avatar_url}
        alt={profile.name}
        className="h-6 w-6 rounded-full"
        loading="lazy"
      />
    )}
  </>
);
