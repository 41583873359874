import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import type { ItemProps } from "@circle-react-uikit/Form/Item";
import { FormItem } from "./FormItem";

const HEX_REGEX = /^#[0-9A-Fa-f]{6}$/;

export const ColorPickerField = ({ ...rest }: ItemProps) => (
  <FormItem
    name="prefs.brand_color.light"
    variant="extra-bottom-margin"
    rules={{
      required: t("settings.branding_form.prefs.brand_color_required"),
      pattern: {
        value: HEX_REGEX,
        message: t("settings.branding_form.prefs.brand_color_invalid"),
      },
    }}
    {...rest}
  >
    <Form.ColorPicker name="prefs.brand_color.light" />
  </FormItem>
);
