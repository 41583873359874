import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const PrivacyNotice = () => (
  <div className="group relative inline-block">
    <div className="border-disabled cursor-default border-b">
      <Typography.LabelSm color="text-disabled">
        {t("community_inbox.privacy_notice.visibility")}
      </Typography.LabelSm>
    </div>
    <div className="absolute bottom-8 left-1/2 z-[1100] hidden w-80 -translate-x-1/2 flex-col gap-1 rounded-lg bg-black px-4 py-3 text-start group-hover:flex">
      <Typography.LabelSm weight="bold" color="text-white">
        {t("community_inbox.privacy_notice.title")}
      </Typography.LabelSm>
      <Typography.BodySm color="text-white">
        {t("community_inbox.privacy_notice.message")}
      </Typography.BodySm>
    </div>
  </div>
);
