import isFunction from "lodash/isFunction";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useMemberCSV = (onSuccess, onError) => {
  const { currentCommunityMember } = usePunditUserContext();
  const { success, error } = useToast();

  const { mutate } = useMutation(
    ({ downloadURL }) => reactQueryGet(downloadURL),
    {
      onSuccess: () => {
        success(
          t("export_member_csv.message", {
            email: currentCommunityMember.email,
          }),
        );
        if (isFunction(onSuccess)) {
          onSuccess();
        }
      },
      onError: () => {
        error(t("export_member_csv.error_message"));
        if (isFunction(onError)) {
          onError();
        }
      },
    },
  );

  return {
    downloadMembersCSV: mutate,
  };
};
