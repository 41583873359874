import { t } from "@/i18n-js/instance";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Loader } from "@circle-react-uikit/Loader";
import { useCommunityMembersListContext } from "./CommunityMembersListContext";

export interface CommunityMemberColumnProps {
  id: any;
  isAvatarHidden?: boolean;
  isLastNameHidden?: boolean;
}

export const CommunityMemberColumn = ({
  id,
  isAvatarHidden,
  isLastNameHidden,
}: CommunityMemberColumnProps) => {
  const communityMembersListContext = useCommunityMembersListContext();
  if (!communityMembersListContext) {
    return <Loader center className="p-0" />;
  }

  const {
    data,
    isLoading,
  }: {
    data: any;
    isLoading: any;
  } = communityMembersListContext;
  if (isLoading) {
    return <Loader center className="!p-0" />;
  }

  const communityMember = data?.records.find((member: any) => member.id === id);
  if (!communityMember) {
    return (
      <span className="break-normal text-sm">{t("deactivated_member")}</span>
    );
  }

  const {
    first_name: firstName,
    name,
    avatar_url: avatarUrl,
  } = communityMember;

  return (
    <>
      {!isAvatarHidden && (
        <span className="pl-3">
          <div className="float-left">
            <UserImage size="6" src={avatarUrl} name={name} />
          </div>
        </span>
      )}
      <span className="break-normal text-sm">
        {isLastNameHidden ? firstName : name}
      </span>
    </>
  );
};
