import { t } from "@/i18n-js/instance";
import { StandardLayoutHeader } from "@/react/components/Layout/StandardLayoutV2/StandardLayoutHeader";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { usePunditUserContext } from "@circle-react/contexts";
import { hasRevenueCatPaymentProcessor } from "@circle-react/helpers/paymentProcessorHelpers";
import { useCommunityPaymentProcessorStatus } from "@circle-react/hooks/communityPaymentProcessor/useCommunityPaymentProcessorStatus";
import { PaywallsEmptyState } from "@circle-react-shared/Paywalls/PaywallsEmptyState";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { Filters } from "../Filters";
import type { FILTERS_AVAILABLE } from "../Filters/useFilters";
import { useIsFilterBeingUsedOnSubscriptions } from "../Filters/useIsFilterBeingUsed";
import { HeaderRightContent } from "./HeaderRightContent";
import { Headline } from "./Headline";
import { Stats } from "./Stats";
import { SubscriptionList } from "./SubscriptionList";
import { TabWithFilters } from "./TabWithFilters";
import { TABS } from "./constants";
import { usePaywallSubscriptionsAdmin } from "./hooks/usePaywallSubscriptionsAdmin";

const customFilters: Array<Partial<FILTERS_AVAILABLE>> = [
  "total_amount_paid",
  "currency",
];

export const PaywallSubscriptionsAdmin = () => {
  const {
    subscriptionsDataByTab,
    resetAllSubscriptionToFirstPage,
    activeFilterTab,
    setActiveFilterTab,
    defaultValues,
    refetchAllSubscriptions,
    setSearchKey,
    onSubmit,
    showStats,
    isLoadingSubscriptions,
  } = usePaywallSubscriptionsAdmin();

  const { currentCommunity, currentCommunitySettings } = usePunditUserContext();
  const paymentProcessor = currentCommunity?.payment_processor;
  const isFilterBeingUsed = useIsFilterBeingUsedOnSubscriptions();
  const formDefaultValues: any = defaultValues;

  const isPaywallSnapshotsEnabled =
    currentCommunitySettings?.paywall_snapshots_enabled;

  const activeFilterTabValue = activeFilterTab
    ? String(activeFilterTab)
    : TABS.ALL;

  const totalSubscriptionsCount =
    subscriptionsDataByTab[activeFilterTabValue]?.totalSubscriptions;

  const { isPaymentProcessorEnabled } = useCommunityPaymentProcessorStatus();

  const platformFilterToRender: Array<Partial<FILTERS_AVAILABLE>> =
    hasRevenueCatPaymentProcessor(currentCommunity?.payment_processors)
      ? ["platform"]
      : [];

  const filters: Array<Partial<FILTERS_AVAILABLE>> = [
    "community_member_name",
    "community_member_email",
    "community_member_id",
    "subscription_status",
    "paywalls",
    ...platformFilterToRender,
    "start_date",
    "subscription_id",
  ];

  if (isLoadingSubscriptions) {
    return (
      <div className="flex h-full">
        <Loader center />
      </div>
    );
  }

  if (
    !isLoadingSubscriptions &&
    !isFilterBeingUsed &&
    (totalSubscriptionsCount === 0 || !isPaymentProcessorEnabled)
  ) {
    return (
      <PaywallsEmptyState
        isPaymentProcessorEnabled={isPaymentProcessorEnabled}
        i18nBase="settings.paywalls_admin_dashboard.subscriptions"
        redirectToPaywalls
      />
    );
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={formDefaultValues}>
      <StandardLayoutHeader
        leftContent={
          <SpaceHeaderTitleText>
            {t("settings.paywalls_admin_dashboard.subscriptions.title")}
          </SpaceHeaderTitleText>
        }
        rightContent={
          <HeaderRightContent
            totalSubscriptionsCount={totalSubscriptionsCount}
          />
        }
        filters={
          <TabWithFilters
            setActiveFilterTab={setActiveFilterTab}
            activeFilterTab={activeFilterTabValue}
            setSearchKey={setSearchKey}
            resetAllSubscriptionToFirstPage={resetAllSubscriptionToFirstPage}
          />
        }
        hasBorder={false}
      />

      <Filters filterNames={filters} customFilterNames={customFilters} />
      <Headline totalSubscriptionsCount={totalSubscriptionsCount} />

      {isPaywallSnapshotsEnabled && paymentProcessor && showStats() && (
        <Stats />
      )}

      <SubscriptionList
        activeFilterTab={activeFilterTabValue}
        subscriptionsDataByTab={subscriptionsDataByTab}
        refetchAllSubscriptions={refetchAllSubscriptions}
      />
    </Form>
  );
};
