import { t } from "@/i18n-js/instance";
import { useCustomizeSpaceDrawer } from "@circle-react/components/Drawers/CustomizeSpaceDrawer";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import {
  Header as CustomizeUIHeader,
  useCustomizeUIData,
} from "@circle-react-shared/CustomizeUI";
import { ModalCloseButton } from "@circle-react-shared/ModalsV2/FullScreenModal/components/ModalCloseButton";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { SelectRadioGroup } from "@circle-react-uikit/SelectRadioGroup";
import { useSpaceCustomizationModal } from "../../Header/SpaceCustomizationModal";
import { SaveAction } from "./SaveAction";

export const Header = () => {
  const { sections, currentSection, setCurrentSection, title } =
    useCustomizeUIData();
  const isSmScreen = useSmScreenMediaQuery();
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();
  const drawer = useCustomizeSpaceDrawer();
  const spaceCustomizationModal = useSpaceCustomizationModal();

  const isLockScreen = currentSection === t("customize_space.lock_screen");

  const radioOptions = sections.map(value => ({
    value,
    label: value,
  }));

  return (
    <CustomizeUIHeader
      className="flex !max-h-[initial] flex-col !items-start gap-4 !p-0 lg:grid lg:!items-center"
      style={{
        gridTemplateColumns: "1fr 1fr 1fr",
      }}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          {isSmScreen && isMobileWebNewSpaceCustomizationUIEnabled && (
            <IconButton name="16-arrow-left" onClick={drawer.hide} />
          )}
          {title && (
            <span className="flex w-full min-w-0">
              <TippyV2
                content={title}
                className="truncate text-lg font-semibold"
                interactive={false}
              >
                {title}
              </TippyV2>
            </span>
          )}
        </div>
        {isSmScreen && (
          <ModalCloseButton
            onClick={() => {
              drawer.hide();
              spaceCustomizationModal.hide();
            }}
          />
        )}
      </div>
      <CustomizeUIHeader.Actions className="hidden w-full overflow-auto md:block lg:justify-center lg:overflow-visible">
        <SelectRadioGroup
          value={currentSection}
          onChange={setCurrentSection}
          options={radioOptions}
        />
      </CustomizeUIHeader.Actions>
      {isLockScreen && <SaveAction />}
    </CustomizeUIHeader>
  );
};

Header.propTypes = {};
