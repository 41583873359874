import { t } from "@/i18n-js/instance";
import { LinkButton } from "@circle-react-uikit/LinkButton";
import { Typography } from "@circle-react-uikit/Typography";

export const AccountManager = ({ name, email, image }) => (
  <div className="inline-block rounded-lg border border-gray-200">
    <img loading="lazy" alt="" src={image} className="rounded-t-lg" />
    <div className="p-5">
      <div>
        <Typography.LabelMd weight="semibold">{name}</Typography.LabelMd>
      </div>
      <div>
        <Typography.LabelMd>
          {t("settings.billing.account_manager.contact")}
        </Typography.LabelMd>
      </div>
      <div className="pt-6">
        <LinkButton href={`mailto:${email}`} variant="circle">
          {t("settings.billing.account_manager.message")}
        </LinkButton>
      </div>
    </div>
  </div>
);

AccountManager.propTypes = {};
