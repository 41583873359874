import { t } from "@/i18n-js/instance";
import type { SpaceType } from "@circle-react/types/Space";
import { Form } from "@circle-react-uikit/Form";
import type { Option } from "@circle-react-uikit/Form/RadioGroup/types";

export interface SpaceOption extends Option {
  value: SpaceType;
}

export interface SpaceTypePickerProps {
  disabled?: boolean;
  isCoursesEnabled?: boolean;
  hideLabel?: boolean;
}

export function SpaceTypePicker({
  disabled = false,
  isCoursesEnabled = false,
  hideLabel = false,
}: SpaceTypePickerProps) {
  const spaceTypeOptions: SpaceOption[] = [
    {
      label: t("spaces.form.create.space_types.post_label"),
      icon: {
        type: "post-2",
        viewBox: "0 0 20 20",
      },
      value: "basic",
    },
    {
      label: t("spaces.form.create.space_types.event_label"),
      icon: {
        type: "event",
        viewBox: "0 0 20 20",
      },
      value: "event",
    },
    {
      label: t("spaces.form.create.space_types.chat_label"),
      icon: {
        type: "chat-2",
        viewBox: "0 0 20 20",
      },
      value: "chat",
    },
  ];

  isCoursesEnabled &&
    spaceTypeOptions.push({
      label: t("spaces.form.create.space_types.course_label"),
      icon: {
        type: "book",
        viewBox: "0 0 20 20",
      },
      value: "course",
    });

  spaceTypeOptions.push({
    label: t("spaces.form.create.space_types.members_label"),
    icon: {
      type: "20-space-picker-members",
      viewBox: "0 0 20 20",
    },
    value: "members",
  });

  spaceTypeOptions.push({
    label: t("spaces.form.create.space_types.image_label"),
    icon: {
      type: "20-images",
      viewBox: "0 0 20 20",
    },
    value: "image",
  });

  return (
    <Form.Item
      name="post_type"
      hideLabel={hideLabel}
      label={t("spaces.form.create.space_types.label")}
      hideDescription
      rules={{ required: "Please choose an access type!", maxLength: 255 }}
      hideBorder
      fullWidth
      variant="extra-bottom-margin"
    >
      <Form.RadioGroup
        className="create-space__form__grid-type"
        disabled={disabled}
        variant="box"
        fullWidth={false}
        name="space_type"
        options={spaceTypeOptions}
      />
    </Form.Item>
  );
}
