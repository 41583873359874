import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Title } from "../TemplateItem/Title";
import TemplateItemWrapper from "../TemplateItemWrapper";

export const CreateWorkflowItem = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push(workflowsSettingsPaths.select_type());
  };

  return (
    <TemplateItemWrapper onClick={handleClick} centerContent>
      <div className="flex gap-2">
        <div className="text-default bg-secondary flex h-10 min-w-[2.5rem] cursor-pointer items-center justify-center rounded-full">
          <Icon
            type="plus-v2"
            size={16}
            useWithFillCurrentColor
            className="!h-5 !w-5"
          />
        </div>
      </div>
      <div className="flex">
        <Title>
          {t("settings.onboarding.tabs.workflows.actions.create_custom")}
        </Title>
      </div>
    </TemplateItemWrapper>
  );
};
