import { useMutation } from "react-query";
import type { Space } from "@/react/types/Space";
import { reactQueryPut } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface UseCourseSettingsMutationProps {
  space: Space;
  options?: Record<string, unknown>;
}

export interface CourseSettingFormValues {
  course_setting: {
    course_type?: string;
    custom_lesson_label?: string | null;
    custom_section_label?: string | null;
  };
}
export const useCourseSettingsMutation = ({
  space,
  options = {},
}: UseCourseSettingsMutationProps) => {
  const courseSettingsUrl = internalApi.courses.settings({
    courseId: space.id,
  });

  return useMutation(
    (courseSetting: CourseSettingFormValues) =>
      reactQueryPut(courseSettingsUrl, courseSetting),
    options,
  );
};
