import { t } from "@/i18n-js/instance";
import { MobileCommunitySwitcher } from "@/react/components/MobileCommunitySwitcher";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Modal } from "@circle-react-uikit/ModalV2";

export const MobileCommunitySwitcherModalComponent = () => {
  const drawer = useModal();

  return (
    <Modal
      isOpen={drawer.visible}
      onClose={drawer.hide}
      contentPortalId="mobile-community-switcher-modal"
      title={t("community_switcher_title")}
    >
      <Modal.Overlay />
      <Modal.Content size="xl">
        <Modal.Body className="!px-3">
          <MobileCommunitySwitcher onClickDone={drawer.hide} />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const MobileCommunitySwitcherModal = ModalManager.create(() => (
  <MobileCommunitySwitcherModalComponent />
));

export const useMobileCommunitySwitcherModal = () =>
  useModal(MobileCommunitySwitcherModal);
