import type { TimeDelayApiParams } from ".";
import { timeDelay } from ".";
import { t } from "@/i18n-js/instance";
import { WORKFLOW_RUN_STATUS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Typography } from "@circle-react-shared/uikit/Typography";
import type { Log } from "../../../HistoryWorkflowDetailModal/HistoryAction";
import { calculateProjectedTime } from "./calculateProjectedTime";

type DelayStatus = "cancelled" | "in_progress" | "success";

interface Display {
  time: (log: Log, apiParams: TimeDelayApiParams) => JSX.Element;
  title: JSX.Element;
  statusTitle: JSX.Element;
}

const DELAY_EXTRA_INFO__MAP: Record<DelayStatus, Display> = {
  cancelled: {
    time: (log: Log) => (
      <Typography.LabelSm>
        {formattedDateTime({
          dateTime: new Date(log?.updated_at),
          format: "short_date_at_short_time",
        })}
      </Typography.LabelSm>
    ),
    title: (
      <Typography.LabelSm weight="medium">
        {t("settings.workflows.view.actions.time_delay.cancelled")}
      </Typography.LabelSm>
    ),
    statusTitle: (
      <Typography.LabelSm weight="medium" color="text-v2-danger">
        {t("settings.workflows.table.result.cancelled")}
      </Typography.LabelSm>
    ),
  },
  in_progress: {
    time: (log: Log, apiParams: TimeDelayApiParams) => (
      <Typography.LabelSm>
        <i>{calculateProjectedTime(log?.created_at, apiParams)}</i>
      </Typography.LabelSm>
    ),
    title: (
      <Typography.LabelSm weight="medium">
        <i>{t("settings.workflows.view.actions.time_delay.expected_end")}</i>
      </Typography.LabelSm>
    ),
    statusTitle: (
      <Typography.LabelSm color="text-messaging-info-blue">
        {t("settings.workflows.table.result.in_progress")}
      </Typography.LabelSm>
    ),
  },
  success: {
    time: (log: Log) => (
      <Typography.LabelSm>
        {formattedDateTime({
          dateTime: new Date(log.updated_at),
          format: "short_date_at_short_time",
        })}
      </Typography.LabelSm>
    ),
    title: (
      <Typography.LabelSm weight="medium">
        {t("settings.workflows.view.actions.time_delay.ended")}
      </Typography.LabelSm>
    ),
    statusTitle: (
      <Typography.LabelSm color="text-v2-success">
        {t("settings.workflows.table.result.success")}
      </Typography.LabelSm>
    ),
  },
};

export interface HistoryTimeDelayViewProps {
  apiParams: TimeDelayApiParams;
  log: Log;
  status?: string;
}

export const HistoryTimeDelayView = ({
  apiParams,
  log,
  status,
}: HistoryTimeDelayViewProps) => {
  const getDelayStatus = (): DelayStatus => {
    // We add this comparing logic for dates for now because if the workflow is cancelled while waiting for a delay action,
    // log.message.status will be in_progress instead of cancelled
    if (
      log.message.status === WORKFLOW_RUN_STATUS.IN_PROGRESS &&
      status === WORKFLOW_RUN_STATUS.IN_PROGRESS
    )
      return WORKFLOW_RUN_STATUS.IN_PROGRESS;
    if (log.message.status === WORKFLOW_RUN_STATUS.SUCCESS)
      return WORKFLOW_RUN_STATUS.SUCCESS;
    if (
      (log?.created_at === log?.updated_at &&
        log?.action === "task_action_completed") ||
      log.message.status === "cancelled"
    )
      return "cancelled";
    return "in_progress";
  };
  const delayStatus = getDelayStatus();
  const extraInfo = DELAY_EXTRA_INFO__MAP[delayStatus];

  const startedAt = formattedDateTime({
    dateTime: new Date(log.created_at),
    format: "short_date_at_short_time",
  });

  return (
    <div className="flex flex-col rounded-lg">
      <div className="bg-secondary border-dark flex flex-col gap-1 rounded-t-lg border-b px-6 py-5">
        <Typography.LabelMd weight="semibold">
          {`${t(
            "settings.workflows.view.actions.time_delay.delay",
          )} ${timeDelay.getActionTitle?.(apiParams)}`}
        </Typography.LabelMd>
        {extraInfo.statusTitle}
      </div>

      <div className="flex flex-col gap-2 px-6 py-5">
        <div className="flex gap-2">
          <Typography.LabelSm weight="medium">
            {t("settings.workflows.view.actions.time_delay.started")}
          </Typography.LabelSm>
          <Typography.LabelSm>{startedAt}</Typography.LabelSm>
        </div>

        <div className="flex gap-2">
          {extraInfo.title}
          <Typography.LabelSm>
            {extraInfo.time(log, apiParams)}
          </Typography.LabelSm>
        </div>
      </div>
    </div>
  );
};
