import { t } from "@/i18n-js/instance";
import { IdFilter } from "./";

const localeRoot = "settings.paywalls_admin_dashboard.filters.transaction_id";

export const TransactionIdFilter = (props: any) => (
  <IdFilter
    {...props}
    localeRoot={localeRoot}
    queryParamsKey="transaction_id"
  />
);

export const filterProperties = {
  name: "transaction_id",
  filterKeys: ["transaction_id"],
  component: TransactionIdFilter,
  label: t([localeRoot, "chip"]),
};
