import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { billingPortalApi } from "@circle-react/api/accountPaymentProcessors/billingPortalApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import type { BillingPortal } from "@circle-react/types";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useBillingPortal = () => {
  const { error: showError } = useToast();

  const billingPortalMutation = useMutation<BillingPortal, ApiError>(
    billingPortalApi.create,
    {
      onSuccess: data => {
        if (data.url) {
          window.location.replace(data.url);
        } else {
          showError(t("pricing_plans.payment_processor.errors.billing_portal"));
        }
      },
      onError: error => {
        console.error(error);
        showError(t("pricing_plans.payment_processor.errors.billing_portal"));
      },
    },
  );

  const onGenerateBillingPortalUrl = () => {
    billingPortalMutation.mutate();
  };

  const { isLoading: isCreatingBillingPortal } = billingPortalMutation;

  return {
    onGenerateBillingPortalUrl,
    isCreatingBillingPortal,
  };
};
