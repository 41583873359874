import { usePunditUserContext } from "@circle-react/contexts/punditUserContext";
import { isSubscriptionExpired } from "@circle-react/helpers/communityHelpers";
import { isPromoCodeEnabled } from "@circle-react/helpers/communityHelpers";
import { PlusPlan } from "../Plan/PlusPlan/PlusPlan";

export const Billing = () => {
  const { currentCommunity } = usePunditUserContext();

  const isExpired =
    isSubscriptionExpired(currentCommunity) ||
    isPromoCodeEnabled(currentCommunity);

  return (
    <div>
      <PlusPlan isExpired={isExpired} />
    </div>
  );
};
