import { t } from "@/i18n-js/instance";
import { getCurrentCircleLocale } from "@/react/helpers/localeHelpers";
import { useThemeContext } from "@/react/providers";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import setupBotBuilderKnowledgeImageDarkPt from "./svgs/knowledge-empty-state-dark-pt.svg";
import setupBotBuilderKnowledgeImageDark from "./svgs/knowledge-empty-state-dark.svg";
import setupBotBuilderKnowledgeImageLightPt from "./svgs/knowledge-empty-state-light-pt.svg";
import setupBotBuilderKnowledgeImageLight from "./svgs/knowledge-empty-state-light.svg";

export const EmptyState = ({
  onPrimaryClick,
}: {
  onPrimaryClick: () => void;
}) => {
  const { isDarkAppearance } = useThemeContext();
  const isPortugueseLocale = getCurrentCircleLocale() === "pt";

  const getImageSrc = () => {
    if (isPortugueseLocale) {
      return isDarkAppearance
        ? setupBotBuilderKnowledgeImageDarkPt
        : setupBotBuilderKnowledgeImageLightPt;
    }
    return isDarkAppearance
      ? setupBotBuilderKnowledgeImageDark
      : setupBotBuilderKnowledgeImageLight;
  };

  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center gap-2 p-8 text-center"
      data-testid="empty-state"
    >
      <img
        width={248}
        height={182}
        src={getImageSrc()}
        alt=""
        aria-hidden="true"
        loading="eager"
      />
      <div className="flex flex-col items-center justify-center gap-5">
        <div className="flex max-w-lg flex-col gap-3">
          <Typography.TitleMd>
            {t(
              "settings.community_bot.bot_builder.knowledge_page.empty_state.header",
            )}
          </Typography.TitleMd>
          <Typography.BodyMd>
            {t(
              "settings.community_bot.bot_builder.knowledge_page.empty_state.description",
            )}
          </Typography.BodyMd>
        </div>
        <div className="flex gap-3">
          <Button type="button" variant="circle" large onClick={onPrimaryClick}>
            {t(
              "settings.community_bot.bot_builder.knowledge_page.empty_state.primary_label",
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
