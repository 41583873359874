import { t } from "@/i18n-js/instance";
import { FilterButtonSkeleton } from "@circle-react/components/Spaces/TopicFilters/FilterButtonSkeleton";

export const SkeletonLoader = () => (
  <>
    <FilterButtonSkeleton text={t("topics.filters.all_topics")} />
    <FilterButtonSkeleton className="delay-500" />
    <FilterButtonSkeleton className="delay-[1000ms]" />
    <FilterButtonSkeleton className="delay-[1500ms]" />
    <FilterButtonSkeleton className="delay-[2000ms]" />
    <FilterButtonSkeleton className="delay-[2500ms]" />
  </>
);
