import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { spaceGroupApi } from "@/react/api";
import { useSpaceGroupsContext } from "@circle-react/contexts";
import { spaceGroupPaths } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useCreateSpaceGroupMutation = ({ position, onSuccess }) => {
  const history = useHistory();
  const { success, error } = useToast();
  const { actions } = useSpaceGroupsContext();

  const mutationFn = formData =>
    spaceGroupApi.create({
      space_group: { ...formData, position },
    });

  return useMutation(mutationFn, {
    onSuccess: response => {
      success(t("space_groups.form.create.saved_successfully"));
      actions.refresh();
      onSuccess();
      history.push(spaceGroupPaths.show({ spaceGroupSlug: response.slug }));
    },
    onError: data =>
      error(data.errorDetails || t("space_groups.form.create.save_error")),
  });
};
