import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { HeaderButtons } from "./HeaderButtons";
import { LeftBackArrow } from "./LeftBackArrow";
import { TitleInput } from "./TitleInput";

interface HeaderProps {
  agent: AgentProp;
  onClose: () => void;
}

export const Header = ({ agent, onClose }: HeaderProps) => (
  <div className="text-dark border-primary bg-primary sticky flex w-full items-center justify-between border-b px-5 py-4">
    <div className="bg-primary flex h-full w-full justify-between">
      <div className="flex w-full flex-row items-center gap-6 truncate">
        <LeftBackArrow onClick={onClose} />
        <TitleInput />
      </div>
      <div className="flex w-full items-center gap-6 overflow-auto lg:justify-center lg:overflow-visible" />
      <div className="flex w-full items-center justify-end gap-3">
        <HeaderButtons agent={agent} />
      </div>
    </div>
  </div>
);
