import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";

interface AIPausedIndicatorProps {
  aiPaused: boolean;
}

export const AIPausedIndicator = ({ aiPaused }: AIPausedIndicatorProps) => (
  <div className="before:border-primary relative z-0 mx-5 flex items-center justify-center before:absolute before:bottom-0 before:left-0 before:top-1/2 before:z-[-1] before:m-0 before:w-full before:border-t before:border-solid before:content-['']">
    <span className="bg-primary text-disabled text-xxs flex items-center gap-2 border-none px-[15px] py-[5px] font-bold uppercase">
      {t(aiPaused ? "community_inbox.ai_paused" : "community_inbox.ai_resumed")}
      <Icon type="16-stars-ai" size={16} />
    </span>
  </div>
);
