import { t } from "@/i18n-js/instance";

export const DEFAULT_PAUSE_AI_HANDOFF_MESSAGE = {
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          text: t(
            "community_bot.bot_builder.behavior_page.pause_ai_setting.handoff_message_placeholder",
          ),
          type: "text",
        },
      ],
    },
  ],
};
