import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { SettingsHeader } from "@/react/components/SettingsApp/SettingsHeader";
import { usePunditUserContext } from "@/react/contexts";
import { useSmOrMdOrLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Tab } from "@circle-react-uikit/TabV2";
import { LandingPage } from "./LandingPage";
import { MobileDashboard } from "./MobileDashboard";
import { NoAgents } from "./NoAgents";
import { SnippetsV2 } from "./SnippetsV2";
import { SpacesV2 } from "./SpacesV2";
import { useIndexAgents } from "./agentsQueries";

export const TABS = {
  TAB_COMMUNITY: "tab_community",
  TAB_CUSTOM: "tab_custom",
};
export const TABS_LABELS = {
  [TABS.TAB_COMMUNITY]: t("settings.community_bot.knowledge.tab_community"),
  [TABS.TAB_CUSTOM]: t("settings.community_bot.knowledge.tab_custom"),
};
const tabNames = Object.values(TABS);

export const CommunityBotKnowledge = () => {
  const { data: agentsQuery, isLoading } = useIndexAgents({ currentPage: 1 });
  const isMobileView = useSmOrMdOrLgScreenMediaQuery();
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    tabNames.indexOf(TABS.TAB_COMMUNITY),
  );

  const { currentCommunity } = usePunditUserContext();

  const hasNoAgents = !agentsQuery?.records || agentsQuery.records.length === 0;

  const shouldShowLandingPage =
    !currentCommunity?.ai_agents_feature_flag_enabled && hasNoAgents;

  const shouldShowEmptyState =
    Boolean(currentCommunity?.ai_agents_feature_flag_enabled) && hasNoAgents;

  if (isMobileView) {
    return <MobileDashboard />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (shouldShowLandingPage) {
    return <LandingPage />;
  }

  if (shouldShowEmptyState) {
    return <NoAgents />;
  }

  return (
    <div className="bg-primary h-full">
      <Tab.Group
        className="!h-auto overflow-visible"
        selectedIndex={selectedTabIndex}
        onChange={tabIndex => setSelectedTabIndex(tabIndex)}
      >
        <SettingsHeader
          pageTitle={t("settings.community_bot.knowledge.title")}
          hasBorder={false}
          filters={
            <Tab.ListWrapper className="lg:px-9">
              <Tab.List>
                {tabNames.map(tab => (
                  <Tab key={tab}>{TABS_LABELS[tab]}</Tab>
                ))}
              </Tab.List>
            </Tab.ListWrapper>
          }
        />

        <Tab.Panels>
          <Tab.Panel>
            <SpacesV2 />
          </Tab.Panel>
          <Tab.Panel>
            <SnippetsV2 />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
