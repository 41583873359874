import { t } from "@/i18n-js/instance";
import { useSpaceResource } from "@circle-react/contexts";
import { BaseResource } from "../../BaseResource";

export const SpaceResource = ({ resourceId }: { resourceId: number }) => {
  const { data: space, isLoading } = useSpaceResource({
    spaceId: resourceId,
  });
  return (
    <BaseResource
      label={t("settings.workflows.view.resources.space")}
      value={space?.name}
      isLoading={isLoading}
    />
  );
};
