import { useFormContext } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { MembersToAddList } from "@circle-react/components/Modals/AddSpaceMember/MembersToAddList";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers";
import { OrDivider } from "@circle-react-shared/OrDivider";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal, useModalData } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

export interface AddMemberContentProps {
  onClose: () => void;
  formId: string;
  isLoading?: boolean;
}

export const AddMemberContent = ({
  onClose,
  formId,
  isLoading = false,
}: AddMemberContentProps) => {
  const {
    watch,
    setValue,
    formState: { isDirty },
  } = useFormContext();
  const memberIds: string[] = watch("community_member_ids", []) || [];
  const shouldAddEveryone = watch("add_all_members", false);
  const hasMembers = memberIds.length > 0;
  const { contentPortalElement } = useModalData();
  const { pathname } = useLocation();

  const removeMemberId = (memberId: string) => {
    const newMemberIds = memberIds.filter(id => id !== memberId);
    setValue("community_member_ids", newMemberIds);
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title size="sm" className="text-center">
          {t("space_groups.form.edit.members.add_member")}
        </Modal.Title>
        <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
          <Modal.CloseButton onClick={onClose} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-8">
          <Typography.LabelMd>
            {t(
              "space_groups.add_members_to_space_group_description_without_link",
            )}{" "}
            <Link to={`${settingsNavPathV3.members.invite}?from=${pathname}`}>
              {t("space_groups.add_members_to_space_group_link")}
            </Link>
            .
          </Typography.LabelMd>
        </div>
        <Form.CommunityMemberSelect
          name="community_member_ids"
          direction="down"
          variant="tags"
          hideSelectedItems
          hideIcon
          disabled={shouldAddEveryone}
          appendTo={contentPortalElement}
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [-10, 0],
                },
              },
            ],
          }}
        />
        <MembersToAddList memberIds={memberIds} onRemove={removeMemberId} />
        <div
          className={classNames({
            hidden: hasMembers,
            visible: !hasMembers,
          })}
        >
          <OrDivider />
          <div className="flex items-baseline">
            <Form.Item
              hiddenField
              hideDescription
              label={t("space_groups.form.add_everyone_from_community")}
              id="add_all_members"
              name="add_all_members"
              className="my-5 mr-2 flex shrink-0 flex-row-reverse items-center justify-end py-0 font-normal"
              labelClassName="ml-4 mb-0 font-normal text-sm"
              shouldToggleValueOnLabelClick
            >
              <Form.ToggleSwitch name="add_all_members" variant="small" />
            </Form.Item>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-baseline justify-end">
        <Button
          variant="primary"
          type="submit"
          form={formId}
          disabled={!isDirty || isLoading}
        >
          {isLoading
            ? t("space_groups.invite.adding_to_space_group")
            : t("space_groups.invite.add_to_space_group")}
        </Button>
      </Modal.Footer>
    </>
  );
};
