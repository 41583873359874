import type { PropsWithChildren } from "react";
import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { FormProvider, useForm } from "react-hook-form";
import type { Workflow } from "../../generated/WorkflowsApi.types";

interface WorkflowFormProviderProps {
  workflow: Workflow;
}

export const WorkflowFormProvider = ({
  workflow,
  children,
}: PropsWithChildren<WorkflowFormProviderProps>) => {
  const hasActions = !isEmpty(workflow?.actions);
  const actions = useMemo(() => {
    if (!hasActions) return [];
    return workflow.actions;
  }, [hasActions, workflow?.actions]);

  const isBulkAction = workflow?.workflow_type === "bulk_action";
  const isScheduled = workflow?.workflow_type === "scheduled";
  const hasAudienceFilters = workflow?.audience?.rules?.rules;
  const audience = useMemo(() => {
    if ((isBulkAction || isScheduled) && hasAudienceFilters) {
      return workflow.audience;
    }
    return {
      rules: {
        rule_type: "custom_filters",
        rules: [],
      },
    };
  }, [hasAudienceFilters, isBulkAction, isScheduled, workflow.audience]);

  const schedule = isScheduled ? workflow.schedule : {};

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...workflow,
      actions,
      audience,
      schedule,
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
