import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import type { CommunitySettings } from "@circle-react/types/CommunitySettings";

export const accessGroupEntryPoints = {
  communitySetting: "CommunitySetting",
  paywall: "Paywall",
  invitationLink: "InvitationLink",
  memberBulkImport: "AsyncTasks::BulkCommunityMemberImportTask",
};
export type AccessGroupEntryPoint =
  (typeof accessGroupEntryPoints)[keyof typeof accessGroupEntryPoints];

export const isOnboardingSettingEntryPoint = (
  entryPoint: AccessGroupEntryPoint,
) => entryPoint === accessGroupEntryPoints.communitySetting;

export const accessGroupStatuses = Object.freeze({
  ARCHIVED: "archived",
  ACTIVE: "active",
});

export const isAccessGroupArchived = (accessGroup: AccessGroupType) =>
  accessGroup?.status === accessGroupStatuses.ARCHIVED;

export const isLegacyAccessSystemAvailable = (
  currentCommunitySettings?: CommunitySettings,
) => !currentCommunitySettings?.block_access_groups_for_trials_enabled;
