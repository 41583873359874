import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { workflows } from "@circle-react/helpers/urlHelpers/internalApi/workflows";
import { BaseResource } from "../../BaseResource";

export const PostsBasicResource = ({ resourceId }: { resourceId: number }) => {
  const { data: post, isLoading } = useQuery<{ name: string } | undefined>(
    workflows.posts.show({ id: String(resourceId) }),
  );

  return (
    <BaseResource
      label={t("settings.workflows.view.resources.post")}
      value={post?.name}
      isLoading={isLoading}
    />
  );
};
