import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react/components/shared/uikit/Typography";

export const NoPostsWithTopic = () => (
  <div className="px-2 py-4 md:p-0">
    <div
      className="border-primary bg-primary flex w-full flex-col justify-center rounded-lg border py-20 text-center md:h-auto md:w-auto"
      data-testid="post_section_with_topic"
    >
      <Typography.TitleSm weight="semibold">
        {t("topics.filters.no_posts_with_topic")}
      </Typography.TitleSm>
      <div className="mt-4">
        <Typography.LabelMd>
          {t("topics.filters.no_posts_with_topic_description")}
        </Typography.LabelMd>
      </div>
    </div>
  </div>
);
