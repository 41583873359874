import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import type { Space } from "@circle-react/types/Space";

export interface PostListWrapperProps extends ComponentPropsWithoutRef<"div"> {
  displayView?: Space["display_view"];
}

export const PostListWrapper = ({
  children,
  className,
  displayView,
  ...rest
}: PostListWrapperProps) => (
  <div
    className={classNames(
      "space__posts !pb-16 lg:!pb-6",
      {
        [`space__posts--${displayView}`]: displayView,
      },
      className,
    )}
    data-testid="post_section"
    {...rest}
  >
    {children}
  </div>
);
