import { t } from "@/i18n-js/instance";
import { useMarketingHubModal } from "@circle-react-shared/MarketingHub/LandingPage/Modal";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const InactiveState = () => {
  const marketingHubModal = useMarketingHubModal();

  return (
    <div className="dark:bg-tertiary border-primary my-6 rounded-lg border bg-blue-100 px-8 py-7">
      <div className="mb-2">
        <Typography.TitleSm weight="medium">
          {t("settings.billing.marketing_hub.inactive_state.title")}
        </Typography.TitleSm>
      </div>
      <div className="mb-4">
        <Typography.BodySm color="secondary">
          {t("settings.billing.marketing_hub.inactive_state.description")}
        </Typography.BodySm>
      </div>
      <div>
        <Button onClick={() => marketingHubModal.show()} variant="secondary">
          <Typography.LabelSm weight="medium">
            {t("settings.billing.marketing_hub.inactive_state.enable_button")}
          </Typography.LabelSm>
        </Button>
      </div>
    </div>
  );
};
