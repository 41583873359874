import type { ReactNode } from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import { useSubmitWorkflow } from "../ConfirmSubmitWorkflowModal/useSubmitWorkflow";

interface FormProps {
  children: ReactNode;
  isRerun?: boolean;
  className?: string;
}

export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ children, className, isRerun }, ref) => {
    const { submitWorkflow } = useSubmitWorkflow({ isRerun });

    return (
      <form
        ref={ref}
        className={classNames("flex h-full w-full flex-col", className)}
        onSubmit={submitWorkflow}
      >
        {children}
      </form>
    );
  },
);

Form.displayName = "Form";
