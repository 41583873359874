import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@/react/components/shared/Icon";
import { TippyV2 } from "@/react/components/shared/TippyV2";
import circleLogo from "@circle-assets/images/bot-builder-knowledge-logo.png";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { ToggleSwitch } from "@circle-react-shared/uikit/Form/ToggleSwitch";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const PlatformSupportToggle = () => {
  const { watch } = useFormContext();
  const usePlatformSupport = watch("use_platform_support");
  const [spaceIds, snippetsIds] = watch(["space_ids", "snippet_ids"]);

  return (
    <div
      className={classNames(
        "bg-primary border-primary relative flex flex-col gap-3 !overflow-clip rounded-lg border p-4 leading-[18px] hover:border-[#D4D4D8] hover:[box-shadow:0px_12px_16px_-4px_rgba(25,27,31,0.08),0px_4px_6px_-2px_rgba(25,27,31,0.05)]",
        { "gradient-border-active": usePlatformSupport },
      )}
    >
      <div className="w-[132px]">
        <Typography.LabelSm weight="semibold">
          {t(
            "community_bot.bot_builder.knowledge_page.tabs.train_agent_on_platform_support",
          )}
        </Typography.LabelSm>
      </div>
      <TippyV2
        className="absolute right-[9px] top-[12px]"
        content={
          <div className="flex flex-col">
            <Typography.LabelSm weight="semibold" color="text-dark-inverse">
              {t(
                "community_bot.bot_builder.knowledge_page.use_platform_support_tooltip_title",
              )}
            </Typography.LabelSm>
            <Typography.BodySm color="text-dark-inverse font-[InterVariable,sans-serif]">
              {t(
                "community_bot.bot_builder.knowledge_page.use_platform_support_tooltip_description",
              )}

              <a
                className="ml-1 font-bold text-inherit underline hover:text-inherit"
                href="https://help.circle.so/p/members"
                target="_blank"
                rel="noreferrer"
              >
                {t("community_bot.bot_builder.knowledge_page.learn_more")}
              </a>
            </Typography.BodySm>
          </div>
        }
      >
        <Icon type="info" size={16} />
      </TippyV2>
      {spaceIds.length === 0 && snippetsIds.length === 0 ? (
        <TippyV2
          content={t(
            "community_bot.bot_builder.knowledge_page.at_least_one_tooltip",
          )}
          className="w-8"
        >
          <div className="pointer-events-none">
            <ToggleSwitch
              name="use_platform_support"
              shouldUseCheckmark
              variant="small"
            />
          </div>
        </TippyV2>
      ) : (
        <ToggleSwitch
          name="use_platform_support"
          shouldUseCheckmark
          variant="small"
        />
      )}
      <img
        src={circleLogo}
        width={57}
        height={57}
        alt=""
        loading="eager"
        className={classNames("absolute -bottom-4 right-4", {
          "opacity-20 grayscale": !usePlatformSupport,
        })}
      />
    </div>
  );
};
