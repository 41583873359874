import { t } from "@/i18n-js/instance";
import { Icon } from "@/react/components/shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import type { FileToUpload } from "./AddFileModalComponent";

export const FileList = ({
  files,
  setFiles,
  openFilePicker,
}: {
  files: FileToUpload[];
  setFiles: (files: FileToUpload[]) => void;
  openFilePicker: () => void;
}) => (
  <div className="flex h-full w-full flex-col">
    <div className="flex-1 overflow-auto">
      <div className="flex min-h-full w-full flex-col items-center justify-center gap-4 py-4">
        <div className="flex w-full flex-col items-center justify-center gap-2">
          {files.map(file => (
            <div
              key={file.file?.name}
              className="text-dark bg-tertiary border-primary flex w-full max-w-[450px] flex-row justify-between rounded-lg border p-2 text-base"
            >
              <div className="flex justify-center">
                <Icon
                  className="mr-3"
                  type="20-ai-file"
                  size={20}
                  useWithStrokeCurrentColor
                />
                <Typography.BodySm>{file.file?.name}</Typography.BodySm>
              </div>
              <div className="flex">
                {file.status === "valid" ? (
                  <Icon
                    type="20-close"
                    size={20}
                    className="cursor-pointer"
                    useWithStrokeCurrentColor
                    onClick={(event: React.MouseEvent<SVGSVGElement>) => {
                      event.stopPropagation();
                      setFiles(
                        files.filter(f => f.file?.name !== file.file?.name),
                      );
                    }}
                  />
                ) : (
                  <Icon type="loader" size={20} useWithStrokeCurrentColor />
                )}
              </div>
            </div>
          ))}
        </div>
        <Button
          variant="secondary"
          onClick={event => {
            event.stopPropagation();
            openFilePicker();
          }}
        >
          {t(
            "settings.community_bot.knowledge.custom.add_file_modal.uploader.upload_more",
          )}
        </Button>
      </div>
    </div>
  </div>
);
