import { useMemo } from "react";
import { usePlatformFilters } from "./usePlatformFilters";
import { useProfileFieldsFilter } from "./useProfileFieldsFilter";

export const useFilters = (filters = []) => {
  const platformFilters = usePlatformFilters(filters);
  const profileFieldsFilters = useProfileFieldsFilter(filters);

  const transformedFilters = useMemo(
    () => [...platformFilters, ...profileFieldsFilters] || [],
    [platformFilters, profileFieldsFilters],
  );

  return {
    filters: transformedFilters,
    hasFilters: transformedFilters.length > 0,
  };
};
