import type { RefObject } from "react";
import { useEffect, useState } from "react";
import type { Topic } from "@circle-react/types/Topic";

const ALL_BUTTON_WIDTH = 50;
const GAP_SIZE = 12;
const ICON_SIZE = 20;

interface UseResizeProps {
  containerRef: RefObject<HTMLDivElement>;
  moreButtonRef: RefObject<HTMLButtonElement>;
  topics: Topic[];
}

export const useResize = ({
  containerRef,
  moreButtonRef,
  topics,
}: UseResizeProps) => {
  const [visibleTopics, setVisibleTopics] = useState<Topic[]>(topics);

  const topicsNames = topics.map(topic => topic.name).join(", ");
  useEffect(() => {
    if (containerRef?.current && topics.length) {
      recomputeVisibleTopics();
      window.addEventListener("resize", recomputeVisibleTopics);
    }

    return () => {
      window.removeEventListener("resize", recomputeVisibleTopics);
    };
  }, [topicsNames, containerRef, moreButtonRef]);

  const recomputeVisibleTopics = () => {
    const containerWidth =
      containerRef.current?.getBoundingClientRect().width || 0;
    const moreButtonWidth =
      moreButtonRef.current?.getBoundingClientRect()?.width ||
      estimatePillWidth("More") + ICON_SIZE;
    let availableWidth = containerWidth - ALL_BUTTON_WIDTH - GAP_SIZE;

    const newVisibleTopics: Topic[] = [];

    topics.forEach((topic, index) => {
      const pillWidth = estimatePillWidth(topic.name);

      if (
        availableWidth > pillWidth &&
        pillWidth + moreButtonWidth + GAP_SIZE * 2 < availableWidth
      ) {
        newVisibleTopics.push(topic);
        availableWidth -= pillWidth + GAP_SIZE;
      } else if (
        index === topics.length - 1 &&
        newVisibleTopics.length === topics.length - 1 &&
        pillWidth < availableWidth + GAP_SIZE * 2
      ) {
        newVisibleTopics.push(topic);
      }
    });
    setVisibleTopics(newVisibleTopics);
  };

  // Calculate real width using hidden element
  const estimatePillWidth = (topicName: string) => {
    if (!document) return 0;

    const pill = document.createElement("div");
    pill.className =
      "max-w-[200px] border truncate px-4 py-2 text-sm font-medium truncate";
    pill.style.visibility = "hidden";
    pill.style.position = "absolute";
    pill.textContent = topicName;

    try {
      const appendedPill = document.body.appendChild(pill);
      const pillWidth = appendedPill.getBoundingClientRect().width || 0;
      document.body.removeChild(appendedPill);
      return pillWidth;
    } catch (error) {
      console.error("Error estimating pill width", error);
      return 0;
    }
  };

  return { visibleTopics };
};
