import { t } from "@/i18n-js/instance";

export const CHAT_NOTIFICATION_TYPES_OPTIONS = [
  {
    value: "all",
    label: t("notification_settings.all.label"),
    description: t("notification_settings.all.description"),
  },
  {
    value: "mentions",
    label: t("notification_settings.mentions.label"),
    description: t("notification_settings.mentions.description"),
  },
  {
    value: "never",
    label: t("notification_settings.never.label"),
    description: t("notification_settings.never.description"),
  },
];

export const CHAT_NOTIFICATION_TYPES_MEMBER_OPTIONS = [
  {
    value: "all",
    label: t("member_notification_settings.all.label"),
    description: t("member_notification_settings.all.description"),
  },
  {
    value: "mentions",
    label: t("member_notification_settings.mentions.label"),
    description: t("member_notification_settings.mentions.description"),
  },
  {
    value: "never",
    label: t("member_notification_settings.never.label"),
    description: t("member_notification_settings.never.description"),
  },
];
