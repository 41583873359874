import { t } from "@/i18n-js/instance";
import { FormTextSelect } from "@circle-react-shared/uikit/Form/FormTextSelect";
import { Typography } from "@circle-react-shared/uikit/Typography";

const i18nRoot = "settings.workflows.form";

const MEMBERS = "members";
const NON_MEMBERS = "non_members";
export const ALL_CONTACTS = "all_contacts";

const options = [
  {
    value: MEMBERS,
    label: t([i18nRoot, "audience_type_options.members"]),
  },
  {
    value: NON_MEMBERS,
    label: t([i18nRoot, "audience_type_options.non_members"]),
  },
  {
    value: ALL_CONTACTS,
    label: t([i18nRoot, "audience_type_options.all_contacts"]),
  },
];

export const ContactTypeSelector = () => (
  <FormTextSelect
    name="trigger.contact_type"
    options={options}
    initialValue={ALL_CONTACTS}
    label={
      <Typography.LabelSm weight="semibold">
        {t([i18nRoot, "audience_type"])}
      </Typography.LabelSm>
    }
  />
);
