import { useMemo } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { SpaceGroupsContextProvider } from "@circle-react/contexts/spaceGroupsContext";
import { SpacesContextProvider } from "@circle-react/contexts/spacesContext";
import { ThemeProvider } from "@circle-react/providers/Theme/ThemeProvider";
import { BotBuilderRoutes } from "@circle-react/routes/BotBuilder";
import {
  AudienceSearchContext,
  type AudienceSearchUsage,
} from "@circle-react-shared/MemberFilters/AudienceSearchContext";
import { ToastProvider } from "@circle-react-uikit/ToastV2";

export const BotBuilderApp = () => {
  const queryClient = new QueryClient();
  const audienceContextValue = useMemo(
    () => ({ usage: "contacts" as AudienceSearchUsage }),
    [],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ThemeProvider>
          <ToastProvider>
            <NiceModal.Provider>
              <SpacesContextProvider>
                <SpaceGroupsContextProvider>
                  <AudienceSearchContext.Provider value={audienceContextValue}>
                    <BotBuilderRoutes />
                  </AudienceSearchContext.Provider>
                </SpaceGroupsContextProvider>
              </SpacesContextProvider>
            </NiceModal.Provider>
          </ToastProvider>
        </ThemeProvider>
      </Router>
    </QueryClientProvider>
  );
};
