import { t } from "@/i18n-js/instance";
import type { PaywallCheckoutPreview } from "@circle-react/types";
import { CheckoutFormSection } from "../CheckoutFormSection";
import { CheckoutPreviewEntry } from "../CheckoutPreviewEntry";

export interface FuturePaymentsPreviewProps {
  preview: PaywallCheckoutPreview;
}
export const FuturePaymentsPreview = ({
  preview,
}: FuturePaymentsPreviewProps) => {
  const futurePayments = preview.future_payments;

  if (!futurePayments) {
    return null;
  }

  const {
    formatted_total: formattedTotal,
    start_on_phrase: startOnPhrase,
    formatted_subtotal: formattedSubtotal,
    discount,
    formatted_discount: formattedDiscount,
    discount_phrase: discountPhrase,
    tax,
    formatted_tax: formattedTax,
  } = futurePayments;

  return (
    <CheckoutFormSection className="gap-y-2 border-t">
      <CheckoutPreviewEntry
        label={t("paywall_checkout.future_payments.header")}
        labelWeight="semibold"
        value={formattedTotal}
        valueWeight="semibold"
        dataTestId="checkout-form-preview-future-payments-total"
      />
      <CheckoutPreviewEntry
        label={startOnPhrase}
        value={formattedSubtotal}
        dataTestId="checkout-form-preview-future-payments-subtotal"
      />
      {discount < 0 && (
        <CheckoutPreviewEntry
          label={t("paywall_checkout.coupon.discount")}
          value={formattedDiscount}
          valueColor="text-green-600"
          tooltip={discountPhrase}
          dataTestId="checkout-form-preview-future-payments-discount"
        />
      )}
      {tax > 0 && (
        <CheckoutPreviewEntry
          label={t("paywall_checkout.coupon.tax")}
          value={formattedTax}
          dataTestId="checkout-form-preview-future-payments-tax"
        />
      )}
    </CheckoutFormSection>
  );
};
