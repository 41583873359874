import type { ReactNode } from "react";
import { createContext, useContext, useMemo, useState } from "react";
import invariant from "tiny-invariant";
import { useBroadcastData } from "@circle-react-shared/Emails/BroadcastDataContext";
import { RULE_TYPES } from "../BuilderFields/constants";

export type SectionNames =
  | "to"
  | "subject"
  | "email_content"
  | "preview_text"
  | "schedule";

type FilledStatus = Map<SectionNames, boolean>;

interface BuilderBlockContextValue {
  activeSection: SectionNames | null;
  setActiveSection: (section: SectionNames | null) => void;
  filledStatus: FilledStatus;
  audienceCount: number;
}

const BuilderBlockContext = createContext<BuilderBlockContextValue | null>(
  null,
);
BuilderBlockContext.displayName = "BuilderBlockContext";

interface BuilderBlockContextProviderProps {
  children: ReactNode;
}

export const useBuilderBlockContext = () => {
  const context = useContext(BuilderBlockContext);
  invariant(
    context,
    "useBuilderBlockContext must be used within a BuilderBlockContextProvider",
  );
  return context;
};

export function BuilderBlockContextProvider({
  children,
}: BuilderBlockContextProviderProps) {
  const { data } = useBroadcastData();

  const audienceCount = data?.community_segment?.audience_count || 0;
  const filledStatusOfSections: FilledStatus = useMemo(() => {
    const getFilledToStatus = () => {
      const audienceRuleType = data?.community_segment?.rules?.rule_type;
      if (
        audienceRuleType === RULE_TYPES.ALL_MEMBERS ||
        audienceRuleType === RULE_TYPES.ALL_CONTACTS
      ) {
        return true;
      }
      if (audienceRuleType === RULE_TYPES.CUSTOM_FILTERS) {
        return !!data?.community_segment?.rules?.rules?.length;
      }
      return false;
    };
    return new Map([
      ["to", getFilledToStatus()],
      ["subject", !!data?.broadcast_content?.subject],
      ["preview_text", !!data?.broadcast_content?.preview_text],
      ["email_content", !!data?.broadcast_content?.email_content],
      ["schedule", !!data?.send_type],
    ]);
  }, [
    data?.community_segment?.rules?.rule_type,
    data?.community_segment?.rules?.rules?.length,
    data?.broadcast_content?.email_content,
    data?.broadcast_content?.preview_text,
    data?.broadcast_content?.subject,
    data?.send_type,
  ]);

  const firstUnfilledSection = useMemo(() => {
    for (const [section, isFilled] of filledStatusOfSections) {
      if (!isFilled) {
        return section;
      }
    }
    return "to";
  }, [filledStatusOfSections]);
  const [activeSection, setActiveSection] = useState<SectionNames | null>(
    firstUnfilledSection,
  );

  const value = useMemo(
    () => ({
      activeSection,
      setActiveSection,
      filledStatus: filledStatusOfSections,
      audienceCount,
    }),
    [activeSection, setActiveSection, filledStatusOfSections, audienceCount],
  );

  return (
    <BuilderBlockContext.Provider value={value}>
      {children}
    </BuilderBlockContext.Provider>
  );
}
