import { stringify } from "qs";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useIsLoadingAppData } from "@/react/hooks/useIsLoadingAppData";
import { useCustomizeSpaceDrawer } from "@circle-react/components/Drawers/CustomizeSpaceDrawer";
import {
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import {
  isCommunityAdmin,
  isMemberDirectoryVisible,
} from "@circle-react/helpers/communityMemberHelpers";
import { createBaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Button } from "@circle-react-shared/uikit/Button";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { Typography } from "@circle-react-uikit/Typography";
import { TopMemberItem } from "./TopMemberItem";

const THIRTY_MINUTES = 1800 * 1000;

export const SIDEBAR_SPACE_MEMBERS = "space-members-sidebar";

export const TopMembersV2 = () => {
  const punditUser = usePunditUserContext();
  const { currentCommunityMember, currentCommunitySettings } = punditUser;
  const { show: showMemberProfileDrawer } = useProfileDrawer();
  const { data: space, refetch } = useCurrentSpaceContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const history = useHistory();
  const { show: showSpaceDrawer } = useCustomizeSpaceDrawer();

  const { data, isLoading } = useQuery(
    [SIDEBAR_SPACE_MEMBERS, space?.id],
    () =>
      reactQueryGet(internalApi.spaceMembers.sidebar({ spaceId: space?.id })),
    {
      staleTime: THIRTY_MINUTES,
    },
  );

  const isLoadingAppData = useIsLoadingAppData();

  if (
    !isMemberDirectoryVisible(currentCommunityMember, currentCommunitySettings)
  ) {
    return null;
  }

  if (isLoading || isLoadingAppData) {
    return <SkeletonLoader variant="right-sidebar" count={2} />;
  }

  const { records: spaceMembers, has_more_space_members: hasMore } = data;

  if (!space || !spaceMembers || spaceMembers.length === 0) {
    return null;
  }

  const onClickSeeMembers = async () => {
    if (isAdmin) {
      await showSpaceDrawer({
        spaceId: space.id,
        refetch: () => void refetch(),
        initialSection: t("customize_space.members"),
      });
    } else {
      history.push(
        `/members?${stringify({
          filters: createBaseFilter({ key: "spaces", value: space.slug }),
        })}`,
      );
    }
  };

  return (
    <div className="bg-primary text-dark border-primary rounded-2xl border p-5">
      <div>
        <Button type="button" variant="text" onClick={onClickSeeMembers}>
          <Typography.LabelMd weight="semibold">
            {t("block_members_title")}
          </Typography.LabelMd>
        </Button>
      </div>
      <ul className="m-0 list-none p-0" data-testid="members-list">
        {spaceMembers.map(({ id, community_member, moderator }: any) => (
          <TopMemberItem
            key={id}
            communityMember={community_member}
            moderator={moderator}
            showMemberProfileDrawer={showMemberProfileDrawer}
          />
        ))}
      </ul>
      {hasMore && (
        <div className="mt-4">
          <Button type="button" variant="text" onClick={onClickSeeMembers}>
            <Typography.LabelSm weight="semibold">
              {t("see_members")}
            </Typography.LabelSm>
          </Button>
        </div>
      )}
    </div>
  );
};
