import type {
  SpaceGroupOption,
  SpaceOption,
} from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import {
  serializeSpaceDefault,
  useSpaceAccessManagement,
} from "@circle-react/components/Spaces/SpaceAccessManagement/hooks/useSpaceAccessManagement";

const serializeSpaceGroup = (spaceGroup: any): SpaceGroupOption => ({
  id: spaceGroup.id,
  name: spaceGroup.name,
  spaces: [],
});

export const useAccessGroupContentManagement = () => {
  const { options: spaceGroupOptions } = useSpaceAccessManagement<
    SpaceGroupOption,
    SpaceOption
  >({
    serializeSpaceGroup,
    serializeSpace: serializeSpaceDefault,
  });

  return {
    options: spaceGroupOptions,
  };
};
