import { useEffect, useState } from "react";
import { t } from "@/i18n-js/instance";
import { nextPlanWithFeatureApi } from "@/react/api/nextPlanWithFeatureApi";
import { useUpdateAgent } from "@circle-react/components/SettingsApp/CommunityBot/agentsQueries";
import { usePunditUserContext } from "@circle-react/contexts";
import { useDisableModal } from "@circle-react/pages/BotBuilder/DisableModal";
import { useEnableModal } from "@circle-react/pages/BotBuilder/EnableModal";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { ToggleWithLoader } from "@circle-react-shared/uikit/ToggleWithLoader";

export const AgentEnableSwitch = ({ row }: { row: any }) => {
  const [isEnabled, setIsEnabled] = useState(row.original?.enabled);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const enableModal = useEnableModal(row.original);
  const disableModal = useDisableModal(row.original);
  const { currentCommunity, refetch: refetchPundit } = usePunditUserContext();
  const upgradePlanModal = useUpgradePlanModal();
  const { mutate: updateAgent } = useUpdateAgent({ id: row.original.id });

  useEffect(() => {
    setIsEnabled(row.original?.enabled);
  }, [row.original?.enabled, setIsEnabled]);

  const enableAgent = () => {
    updateAgent(
      { enabled: true },
      {
        onSuccess: () => {
          setIsEnabled(true);
        },
        onError: error => {
          console.error("Failed to enable agent:", error);
          setIsEnabled(false);
        },
      },
    );
  };

  const handleUpgradePlan = async () => {
    setIsUpgrading(true);
    const nextPlanData = await nextPlanWithFeatureApi.getNextPlanWithFeature(
      (currentCommunity?.current_plan?.id ?? "").toString(),
      { plan_feature_flag: "ai_agents" },
    );

    const planTier = nextPlanData?.plan_tier || "enterprise";

    await upgradePlanModal.show({
      planTier,
      source: UPGRADE_FLOW_SOURCES.AI_AGENTS_ENABLE_MODAL,
      subheading: t("pricing_plans.upgrade_modal.subheading", {
        feature_name: t("settings.community_ai.ai_agents_feature_name"),
      }),
      successButtonLabel: t(
        "pricing_plans.upgrade_modal.actions.go_to_community_ai",
      ),
      showPlanDetails: true,
      onSuccess: () => {
        enableAgent();
        setIsUpgrading(false);
      },
      onCloseModal: async () => {
        const updatedCommunity = await refetchPundit();
        const isUpgradeSuccess =
          !!updatedCommunity?.data?.current_community
            .ai_agents_feature_flag_enabled;
        if (isUpgradeSuccess) {
          enableAgent();
        }
        setIsUpgrading(false);
      },
    });
  };

  const handleToggle = () => {
    if (!currentCommunity?.ai_agents_feature_flag_enabled) {
      void handleUpgradePlan();
    } else if (isEnabled) {
      void disableModal.show({ agent: row.original });
    } else {
      void enableModal.show({ agent: row.original });
    }
  };

  return (
    <div id={`toggle-wrapper-agent-${row.original.id}`}>
      <ToggleWithLoader
        isChecked={isEnabled}
        checkedLabel={t("settings.community_bot.agents.on_value")}
        uncheckedLabel={t("settings.community_bot.agents.off_value")}
        tooltipCheckedLabel=""
        tooltipUncheckedLabel=""
        tooltipPlacement="bottom"
        isLoading={isUpgrading}
        isDisabled={isUpgrading}
        onChange={handleToggle}
        containerClassName=""
        className=""
      />
    </div>
  );
};
