import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { PAYWALL_LIST_QUERY_KEY } from "@/react/components/Paywalls/Admin/PaywallsAdmin/constants";
import type { ApiError } from "@/react/config/CustomErrors";
import { queryClient } from "@/react/providers/QueryClient";
import type { MigrationFormPayloadType } from "@/react/types/PaywallAccessGroupMigration";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import {
  internalApi,
  settingsNavPathV3,
} from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useMigratePaywallToAccessGroupsApi = ({
  closeAllModals,
}: {
  closeAllModals: () => void;
}) => {
  const toast = useToast();
  const history = useHistory();
  const { mutate: migrateMutation, isLoading: isMigrating } = useMutation({
    mutationFn: (payload: MigrationFormPayloadType) =>
      reactQueryPost(
        internalApi.admin.paywalls.accessGroupsMigration.create(payload),
      ),
    onSuccess: () => {
      toast.success(
        t(
          "settings.access_groups.paywall_migration.modal.form.messages.success",
        ),
      );
    },
    onError: (error: ApiError) => {
      toast.error(error.message);
    },
    onSettled: () => {
      void queryClient.invalidateQueries(PAYWALL_LIST_QUERY_KEY);
      history.push(settingsNavPathV3.paywalls.index);
      closeAllModals();
    },
  });

  return { migrateMutation, isMigrating };
};
