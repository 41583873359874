import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { Modal } from "@/react/components/shared/uikit/ModalV2";
import type { Space } from "@/react/types/Space";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Content } from "@circle-react/components/Spaces/ChatSpace/RailBar/Content";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useChatParticipantsApi } from "@circle-react/hooks/chatsV2";
import { Loader } from "@circle-react-uikit/Loader";

export const ChatSpaceDetailsComponent = ({ space }: { space: Space }) => {
  const {
    chatParticipants,
    isLoading: isChatParticipantsLoading,
    refetchChatParticipants,
    hasNextPage,
    fetchNextPage,
    totalCount,
  } = useChatParticipantsApi(space.chat_room_uuid);

  const modal = useModal();

  const { chat_room_description: description } = space;

  return (
    <Modal isOpen={modal.visible} onClose={modal.visible ? modal.hide : noop}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title size="sm">
            {t("chat_space.details")} (
            {!isChatParticipantsLoading && totalCount})
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.visible ? modal.hide : noop} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="flex flex-col gap-4 !px-5 !py-2">
          {description && <Typography.BodySm>{description}</Typography.BodySm>}
          {isChatParticipantsLoading ? (
            <Loader center />
          ) : (
            <Content
              spaceMembers={chatParticipants}
              hasMoreSpaceMembers={hasNextPage}
              space={space}
              refetchSpaceMembers={refetchChatParticipants}
              fetchNextPage={fetchNextPage}
            />
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const ChatSpaceDetailsModal = ModalManager.create(
  ({ space }: { space: Space }) => <ChatSpaceDetailsComponent space={space} />,
);

export const useChatSpaceDetailsModal = () => useModal(ChatSpaceDetailsModal);
