import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useCurrentAction } from "@circle-react/components/SettingsApp/Workflows/components/ActionRule";
import { pricesEligibleForTrial } from "@circle-react/helpers/paywallPriceHelpers";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.workflows.form";

export const PaywallPriceSelectionInput = ({ paywallPrices }) => {
  const { paramsPath } = useCurrentAction();

  const paywallPriceOptions = pricesEligibleForTrial(paywallPrices).map(
    ({ id, display_name }) => ({
      value: id,
      label: display_name,
    }),
  );

  return (
    <FormTextSelect
      name={`${paramsPath}.paywall_price_id`}
      label={
        <Typography.LabelSm weight="semibold">
          {t([i18nRoot, "select_paywall_price"])}
        </Typography.LabelSm>
      }
      options={paywallPriceOptions}
      hidePlaceholder
      rules={{
        required: t([i18nRoot, "errors.required"]),
      }}
      emptyMessage={t([i18nRoot, "errors.paywalls.not_found"])}
    />
  );
};

PaywallPriceSelectionInput.propTypes = {
  paywallPrices: PropTypes.array.isRequired,
};
