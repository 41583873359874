import { usePostPageLink } from "@/react/components/SettingsApp/Posts/hooks/usePostPageLink";
import { usePlanStatus } from "@/react/hooks/usePlanStatus";
import { usePunditUserContext } from "@circle-react/contexts";
import { useMasqueradeContext } from "@circle-react/contexts/masqueradeContext";
import { useThemeContext } from "@circle-react/providers";
import { useAdminOptions } from "./adminOptions";
import { getPost } from "./getPost";
import { moderatorOptions } from "./moderatorOptions";
import type { DropdownOption } from "./types";
import { useGetModeration } from "./useGetModeration";
import { useGetShortcutsModal } from "./useGetShortcutsModal";
import { useGetToggleTheme } from "./useGetToggleTheme";
import { useGetviewAs } from "./useGetViewAs";

export interface UseDropdownOptionsProps {
  isAdmin: boolean;
  isModerator: boolean;
  memberCount?: number;
  moderationCount?: number;
  showToggleTheme: boolean;
  showCustomizeTheme: boolean;
  isWorkflowsEnabled: boolean;
}

export const useDropdownOptions = ({
  isAdmin,
  isModerator,
  memberCount,
  moderationCount,
  showToggleTheme,
  showCustomizeTheme,
  isWorkflowsEnabled,
}: UseDropdownOptionsProps): DropdownOption[] => {
  const { currentAppearance, toggleAppearance } = useThemeContext();
  const shortcutsModalButton = useGetShortcutsModal();
  const toggleTheme = useGetToggleTheme({
    currentAppearance,
    toggleAppearance,
    showToggleTheme,
  });

  const { isBannerVisible, showBanner } = useMasqueradeContext();
  const viewAs = useGetviewAs({ showBanner });

  const moderation = useGetModeration(moderationCount);
  const postPageLink = usePostPageLink();
  const post = getPost(postPageLink);

  const { isCommunityOnPlus } = usePlanStatus();
  const { currentCommunity } = usePunditUserContext();

  const isAffiliatesEnabled = Boolean(
    currentCommunity?.affiliates_feature_flag_enabled,
  );

  const adminOptions = useAdminOptions({
    memberCount,
    showCustomizeTheme,
    toggleTheme,
    viewAs,
    moderation,
    post,
    isCommunityOnPlus,
    isWorkflowsEnabled,
    isAffiliatesEnabled,
    isBannerVisible,
    shortcutsModalButton,
  });

  if (isAdmin) {
    return adminOptions;
  }

  if (isModerator) {
    return moderatorOptions({
      moderation,
      showToggleTheme,
      toggleTheme,
      post,
      shortcutsModalButton,
    });
  }

  return [
    ...(shortcutsModalButton ? [shortcutsModalButton] : []),
    ...toggleTheme,
  ];
};
