/* eslint-disable tailwindcss/no-custom-classname -- to refactor later */
import { useFormContext, useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { PaywallSpaceAccessManagement } from "./PaywallSpaceAccessManagement";
import "./style.scss";

export const AccessContent = () => {
  const { control } = useFormContext();
  const allSpacesToggleWatcher = useWatch({ control, name: "all_spaces" });

  return (
    <div className="paywalls-admin-drawer-access-tab__container flex h-[60vh] flex-col pb-4">
      <Form.Item
        name="all_spaces"
        translationRoot="settings.paywalls.modal.tabs.access"
        tooltipText={t(
          "settings.paywalls.modal.tabs.access.all_spaces_tooltip",
        )}
        inline
        hideBorder
        hideDescription
        className="flex flex-row-reverse items-center gap-x-4"
      >
        <Form.ToggleSwitch name="all_spaces" variant="small" />
      </Form.Item>
      {!allSpacesToggleWatcher && <PaywallSpaceAccessManagement />}
      {allSpacesToggleWatcher && (
        <div className="flex w-full flex-auto flex-col justify-center gap-y-3 text-center">
          <Typography.TitleSm color="text-selector-active" weight="semibold">
            {t("settings.paywalls.modal.tabs.access.all_access_title")}
          </Typography.TitleSm>
          <Typography.LabelSm color="text-selector-active">
            {t("settings.paywalls.modal.tabs.access.all_access_subtitle")}
          </Typography.LabelSm>
        </div>
      )}
    </div>
  );
};
