import classNames from "classnames";

const MENU_ITEM_CLASSES =
  "block h-8.5 max-w-[200px] shrink-0 cursor-pointer items-center truncate rounded-md bg-transparent px-4 py-2 text-xs-plus font-medium text-left transition-colors duration-200 hover:bg-tertiary focus:bg-tertiary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary";

const MENU_ITEM_INACTIVE_CLASSES =
  "text-default hover:text-default focus:text-default";
const MENU_ITEM_ACTIVE_CLASSES =
  "text-darkest hover:text-darkest focus:text-darkest";

export function menuItemClasses(active: boolean) {
  return classNames(MENU_ITEM_CLASSES, {
    [MENU_ITEM_ACTIVE_CLASSES]: active,
    [MENU_ITEM_INACTIVE_CLASSES]: !active,
  });
}
