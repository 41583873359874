import type { ReactNode } from "react";
import { Tippy } from "@circle-react/components/shared/Tippy";
import { ActionContent } from "./ActionContent";

export interface ActionProps {
  tooltipContent?: string;
  className?: string;
  children: ReactNode;
}

export const Action = ({
  tooltipContent,
  className,
  children,
}: ActionProps) => {
  if (tooltipContent) {
    return (
      <Tippy placement="top" content={tooltipContent}>
        <ActionContent tooltipContent={tooltipContent} className={className}>
          {children}
        </ActionContent>
      </Tippy>
    );
  }

  return (
    <ActionContent tooltipContent={tooltipContent} className={className}>
      {children}
    </ActionContent>
  );
};
