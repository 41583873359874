import { t } from "@/i18n-js/instance";

interface CustomizeSpaceSectionsParams {
  isCourseSpace?: boolean;
  areWorkflowsEnabled: boolean;
  isCommunityAdmin: boolean;
}

export const getCustomizeSpaceSections = ({
  isCourseSpace = false,
  areWorkflowsEnabled,
  isCommunityAdmin,
}: CustomizeSpaceSectionsParams): string[] => {
  const sections: string[] = [];

  if (isCourseSpace) sections.push(t("customize_space.lessons"));

  sections.push(t("customize_space.customize"));
  if (isCommunityAdmin) sections.push(t("customize_space.paywalls.title"));
  sections.push(t("customize_space.lock_screen"));
  sections.push(t("customize_space.members"));
  sections.push(t("customize_space.options"));

  if (areWorkflowsEnabled) sections.push(t("customize_space.workflows"));

  return sections;
};
