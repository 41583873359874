import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { SpaceGroupSelect } from "@circle-react/components/shared/SpaceGroupSelect";
import {
  SpaceGroupsContextProvider,
  useSpaceGroupsContext,
} from "@circle-react/contexts";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";

const i18nRoot = "settings.workflows.form";

export const SpaceGroupSelectorComponenet = () => {
  const { register } = useFormContext();
  const { records: spaceGroups, isLoading } = useSpaceGroupsContext();

  return (
    <>
      <input
        type="hidden"
        className="hidden"
        {...register("trigger.resource_type")}
      />
      <Form.Item
        name="trigger.resource_id"
        hideBorder
        fullWidth
        hideDescription
        label={t([i18nRoot, "choose_space_group"])}
        labelClassName="!mb-2 !text-sm"
        errorClassName="text-v2-danger text-xs mt-1"
        placeholder={t([i18nRoot, "search_space_group"])}
        className="!py-0"
      >
        {isLoading ? (
          <Loader center />
        ) : (
          <SpaceGroupSelect
            spaceGroups={spaceGroups}
            shouldShowEmoji
            variant="form-input"
            containerClassname="relative"
            dropdownClassName="!top-12"
            rules={{
              required: t("settings.workflows.form.errors.required"),
            }}
          />
        )}
      </Form.Item>
    </>
  );
};

export const SpaceGroupSelector = () => (
  <SpaceGroupsContextProvider>
    <SpaceGroupSelectorComponenet />
  </SpaceGroupsContextProvider>
);
