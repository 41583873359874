import { t } from "@/i18n-js/instance";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { useRemoveAccessGroupMembers } from "../../hooks/useRemoveAccessGroupMembers";
import { useAccessGroupData } from "../DataStoreContext/AccessGroupDataContext";

const i18nBase =
  "settings.access_groups.admin.modal.members.bulk_actions.remove_confirmation";
interface RemoveMemberProps {
  isVisible: boolean;
  onClose: () => void;
}

export const RemoveAccessGroupMembersConfirmationModal = ({
  isVisible,
  onClose,
}: RemoveMemberProps) => {
  const { totalMembersToProcess } = useAccessGroupData();
  const { mutate: removeMembers, isLoading: isRemovingMembers } =
    useRemoveAccessGroupMembers({
      closeConfirmationModal: () => {
        onClose();
      },
    });

  return (
    <ConfirmationModal
      title={t([i18nBase, "title"])}
      isOpen={isVisible}
      onClose={onClose}
      disabled={isRemovingMembers}
      onConfirm={() => {
        removeMembers();
      }}
      confirmText={t([i18nBase, "confirm"])}
      confirmVariant="danger"
    >
      {t([i18nBase, "description"], { count: totalMembersToProcess })}
    </ConfirmationModal>
  );
};
