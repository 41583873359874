import type { WorkflowType } from "@circle-react/components/SettingsApp/Workflows/constants";
import { Icon } from "@circle-react-shared/Icon";
import type { Audience, Trigger } from "../../../generated/WorkflowsApi.types";
import { Action } from "../Action";
import { useEntryRule } from "./useEntryRule";

export interface EntryRuleProps {
  trigger: Trigger;
  audience: Audience;
  workflowType: WorkflowType;
}

export const EntryRule = ({
  trigger,
  audience,
  workflowType,
}: EntryRuleProps) => {
  const entryRule = useEntryRule({
    trigger,
    audience,
    workflowType,
  });

  if (!entryRule) {
    return null;
  }

  const { title, iconType } = entryRule;

  return (
    <Action tooltipContent={title}>
      <div className="bg-tertiary flex h-8 max-h-8 w-8 max-w-[2rem] shrink-0 items-center justify-center rounded-full">
        <Icon
          type={iconType}
          size={16}
          className="text-dark !h-4 !w-4 shrink-0"
        />
      </div>
    </Action>
  );
};
