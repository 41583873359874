import { t } from "@/i18n-js/instance";
import { DynamicFilters } from "./DynamicFilters/DynamicFilters";
import { localeRoot, useFilters } from "./useFilters";
import type { FILTERS_AVAILABLE } from "./useFilters";

interface FiltersProp {
  filterNames: Array<Partial<FILTERS_AVAILABLE>>;
  customFilterNames?: Array<Partial<FILTERS_AVAILABLE>>;
}

export const Filters = ({ filterNames, customFilterNames }: FiltersProp) => {
  const { defaultFilters } = useFilters(filterNames, customFilterNames);

  return (
    <DynamicFilters
      label={t([localeRoot, "add_filter"])}
      defaultFilterItems={filterNames}
      defaultFilters={defaultFilters}
    />
  );
};
