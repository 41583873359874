import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

interface NextPlanWithFeatureParamsType {
  plan_feature_flag: string;
  [key: string]: unknown;
}

const getNextPlanWithFeature = (
  planId: string,
  params: NextPlanWithFeatureParamsType,
) => reactQueryGet(internalApi.nextPlanWithFeature.show({ planId, params }));

export const nextPlanWithFeatureApi = {
  getNextPlanWithFeature,
};
