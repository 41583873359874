import { useState } from "react";
import { useQuery } from "react-query";
import { paywallChargesApi } from "@circle-react/api/paywallChargesApi";

export const paywallChargesQueryString = "paywall-charges";

const RESULTS_PER_PAGE = 10;

type FIELDS_AVAILABLE_TO_ORDER = "created_at";

interface UseAdminPaywallChargeProp {
  orderParam?: Record<FIELDS_AVAILABLE_TO_ORDER, "desc" | "asc">;
  perPage?: number;
  pageParams?: number;
  searchKeyParams?: string;
  filters?: FiltersProp;
  enabled?: boolean;
  page: string | number;
  setPage: (value: any) => void;
}

interface FiltersProp {
  community_member_public_uid?: string;
  community_member_id?: string;
}

export const useAdminPaywallCharge = ({
  perPage = RESULTS_PER_PAGE,
  page,
  setPage,
  searchKeyParams = "",
  filters = {},
  orderParam = { created_at: "desc" },
  enabled = true,
}: UseAdminPaywallChargeProp) => {
  const [order, setOrder] = useState(orderParam);

  const httpParams = {
    filters,
    order: order,
    page,
    perPage,
    searchKey: searchKeyParams,
  };

  const fetchPaywalls = paywallChargesApi.index(httpParams);

  const {
    data,
    refetch: refetchCharges,
    isLoading: isLoadingCharges,
    remove: removeChargesFromCache,
  } = useQuery({
    queryKey: [
      paywallChargesQueryString,
      {
        filters,
        order,
        page,
        perPage,
        searchKeyParams,
      },
    ],
    queryFn: fetchPaywalls,
    enabled,
  });

  const updateOrder = (value: any) => {
    setOrder(value);
    setPage(1);
  };

  return {
    hasFilters: Object.keys(filters).length > 0,
    charges: data?.records ?? [],
    totalCharges: data?.count ?? 0,
    refetchCharges,
    removeChargesFromCache,
    isLoadingCharges,
    order,
    setOrder: updateOrder,
    perPage,
    page,
    setPage,
  };
};
