import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { useBulkDeleteSnippetModal } from "./BulkDeleteSnippetModal";
import { useEditSnippetModal } from "./EditSnippetModal";

export const DropdownActions = ({ snippet }: { snippet: any }) => {
  const editSnippetModal = useEditSnippetModal();
  const bulkDeleteSnippetModal = useBulkDeleteSnippetModal();

  return (
    <DataTable.Actions>
      {snippet.type !== "File" && (
        <DataTable.Actions.Item
          onClick={event => {
            event.stopPropagation();
            void editSnippetModal.show({ snippetId: snippet.id });
          }}
        >
          {t("settings.community_bot.knowledge.custom.edit")}
        </DataTable.Actions.Item>
      )}
      <DataTable.Actions.Item
        hasDanger
        onClick={event => {
          event.stopPropagation();
          void bulkDeleteSnippetModal.show({
            selectedIds: [snippet.id],
            setSelectedIds: noop,
          });
        }}
      >
        {t("settings.community_bot.knowledge.custom.delete")}
      </DataTable.Actions.Item>
    </DataTable.Actions>
  );
};
