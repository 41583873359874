import { isFunction } from "lodash";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useLargeScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { useSmOrMdOrLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { DMsDropdown } from "@circle-react/components/Chat/MessagingV2/DMs/DMsDropdown";
import { NotificationsList } from "@circle-react/components/Notifications/NotificationsList";
import {
  bookmarksPath,
  chatRoomsPath,
  chatThreadsPath,
  getRouteWithPrevLocation,
  notificationsPath,
} from "@circle-react/helpers/urlHelpers";
import { useTailwindLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { BookmarksList } from "@circle-react-shared/uikit/HeaderV3/RightActionBlock/Bookmarks/BookmarksList";
import { PopoverPortal } from "@circle-react-uikit/PopoverPortal";
import { PlatformMenuItem } from "./PlatformMenuItem";

export interface MemberLinks {
  allDMsBadgeText?: string;
  disabled?: boolean;
  isMessagingEnabled?: boolean;
  notificationBadgeText?: string;
  onNotificationClick?: () => void;
  resetNewNotificationsCount?: () => void;
  shouldShowChatThreadsTab?: boolean;
  unreadThreadsCount?: number;
}

export const MemberLinks = ({
  allDMsBadgeText,
  disabled,
  isMessagingEnabled,
  notificationBadgeText,
  onNotificationClick,
  resetNewNotificationsCount,
  shouldShowChatThreadsTab,
  unreadThreadsCount,
}: MemberLinks) => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const isLgScreen = useLargeScreenMediaQuery();
  const isLgScreenTw = useTailwindLgScreenMediaQuery();
  const isMobileView = useSmOrMdOrLgScreenMediaQuery();

  const isMessagingActive = pathname.includes(chatRoomsPath());
  const isChatThreadsActive = pathname.includes(chatThreadsPath());
  const isNotificationsActive = pathname.includes(notificationsPath());
  const isBookmarksActive = pathname.includes(bookmarksPath());

  return (
    <>
      {!isLgScreenTw && (
        <PopoverPortal
          button={
            <PlatformMenuItem
              dataTestId="notifications-menu"
              label={t("notifications.page_title")}
              iconName="sidebar-bell"
              badgeText={notificationBadgeText}
              ariaLabel={t("community_sidebar.unread_notifications", {
                count: Number(notificationBadgeText),
              })}
              onClick={() => {
                if (
                  isLgScreen &&
                  isFunction(onNotificationClick) &&
                  !disabled
                ) {
                  onNotificationClick();
                }
              }}
              isActive={isNotificationsActive}
            />
          }
          onOpen={resetNewNotificationsCount}
          disabled={disabled || !isFunction(onNotificationClick)}
        >
          <div
            className="flex h-full w-full flex-col"
            data-testid="notifications-drawer"
          >
            <NotificationsList containerElement="dropdown" isDropdown />
          </div>
        </PopoverPortal>
      )}
      {isMessagingEnabled &&
        !isLgScreenTw &&
        (!isMobileView ? (
          <PlatformMenuItem
            dataTestId="chat-menu"
            label={t("sidebar_all_dms")}
            iconName="16-messages"
            badgeText={allDMsBadgeText}
            ariaLabel={t("community_sidebar.unread_messages", {
              count: Number(allDMsBadgeText),
            })}
            onClick={() =>
              history.push(getRouteWithPrevLocation({ url: chatRoomsPath() }))
            }
            isActive={isMessagingActive}
          />
        ) : (
          <PopoverPortal
            button={
              <PlatformMenuItem
                dataTestId="chat-menu"
                label={t("sidebar_all_dms")}
                iconName="16-messages"
                badgeText={allDMsBadgeText}
                ariaLabel={t("community_sidebar.unread_messages", {
                  count: Number(allDMsBadgeText),
                })}
                onClick={() => isLgScreen && history.push(chatRoomsPath())}
                isActive={isMessagingActive}
              />
            }
            disabled={disabled}
          >
            <DMsDropdown />
          </PopoverPortal>
        ))}
      {shouldShowChatThreadsTab && !isLgScreenTw && (
        <PlatformMenuItem
          dataTestId="chat-threads-menu"
          label={t("chat_threads")}
          iconName="16-chat-threads"
          onClick={() => history.push(chatThreadsPath())}
          isActive={isChatThreadsActive}
          badgeText={String(unreadThreadsCount)}
          ariaLabel={t("community_sidebar.unread_messages", {
            count: Number(unreadThreadsCount),
          })}
        />
      )}
      {isLgScreen && (
        <PopoverPortal
          button={
            <PlatformMenuItem
              dataTestId="bookmarks-menu"
              label={t("header_v3.bookmarks")}
              iconName="16-bookmark"
              onClick={() => history.push(bookmarksPath())}
              isActive={isBookmarksActive}
            />
          }
          disabled={disabled}
        >
          <BookmarksList />
        </PopoverPortal>
      )}
    </>
  );
};
