import { useEffect } from "react";
import I18n from "@/i18n-js/instance";
import { useDocumentTitle } from "@circle-react/hooks/notifications/useDocumentTitle";
import { NotificationsList } from "./NotificationsList";

export const Notifications = () => {
  const { prependTitle } = useDocumentTitle();

  useEffect(() => {
    prependTitle(`${I18n.t("notifications.page_title")} `);
  });

  return (
    <div className="notifications__page">
      <NotificationsList />
    </div>
  );
};
