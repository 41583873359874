import { MyProfile } from "@circle-react/components/Leaderboard/Dashboard/MyProfile";
import { LeaderboardTable } from "@circle-react/components/Leaderboard/Dashboard/Table";
import { usePunditUserContext } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { LeaderboardHeader } from "./LeaderBoardHeader";

export const LeaderboardDashboard = () => {
  const { currentUser } = usePunditUserContext();
  const isLoggedIn = !!currentUser;

  return (
    <>
      <LeaderboardHeader />
      <div
        className={classNames("mx-auto max-w-5xl p-4", {
          "md:p-9": isLoggedIn,
        })}
      >
        {isLoggedIn && <MyProfile />}
        <LeaderboardTable />
      </div>
    </>
  );
};
