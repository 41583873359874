import { t } from "@/i18n-js/instance";
import { ButtonWithDisabled } from "@circle-react-uikit/ButtonWithDisabled";
import { Modal } from "@circle-react-uikit/ModalV2";

export interface SubmitFooterProps {
  isSubmitting: boolean;
}

export const SubmitFooter = ({ isSubmitting = false }: SubmitFooterProps) => (
  <Modal.Footer className="!px-0">
    <ButtonWithDisabled
      type="submit"
      form="bookmark-form"
      variant="primary"
      full
      large
      submitting={isSubmitting}
    >
      {t("save")}
    </ButtonWithDisabled>
  </Modal.Footer>
);
