import { useAreAllRunsCompleted } from "@circle-workflows/components/ViewWorkflowModal/Header/HeaderActions/useAreAllRunsCompleted";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useContinueEditConfirmationModal } from "../../ViewWorkflowModal/Header/HeaderActions/ContinueEditConfirmationModal";

const i18nRoot = "settings.workflows.table.actions";

interface EditProps {
  workflowId: string;
  isAutomation: boolean;
  isActive: boolean;
  isBulkOrScheduled: boolean;
  isInProgress: boolean;
}

export const Edit = ({
  workflowId,
  isAutomation,
  isActive,
  isBulkOrScheduled,
  isInProgress,
}: EditProps) => {
  const continueEditModal = useContinueEditConfirmationModal();
  const { areAllRunsCompleted } = useAreAllRunsCompleted({
    workflowId,
    disableRefetching: true,
  });

  const history = useHistory();

  const onClickHandler = () => {
    let modali18nRoot = "";
    if (isAutomation && isActive) {
      modali18nRoot =
        "settings.workflows.edit.continue_edit_automation_confirmation_modal";
    }

    if (isBulkOrScheduled && (isInProgress || !areAllRunsCompleted)) {
      modali18nRoot =
        "settings.workflows.edit.continue_edit_running_workflow_confirmation_modal";
    }

    if (!modali18nRoot) {
      history.push({
        pathname: workflowsSettingsPaths.edit({ workflowId }),
        state: {
          from: {
            path: history.location.pathname,
            query: history.location.search,
          },
        },
      });
      return;
    }

    void continueEditModal.show({
      title: t([modali18nRoot, "title"]),
      body: t([modali18nRoot, "body"]),
      onConfirm: () => {
        void continueEditModal.hide();
        history.push({
          pathname: workflowsSettingsPaths.edit({ workflowId }),
          state: {
            from: {
              path: history.location.pathname,
              query: history.location.search,
            },
          },
        });
      },
      onCancel: () => {
        void continueEditModal.hide();
      },
    });
  };

  return (
    <Dropdown.ItemWithLink onClick={onClickHandler}>
      {t([i18nRoot, "edit"])}
    </Dropdown.ItemWithLink>
  );
};
