import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

export const SendAndPauseAITooltipContent = () => (
  <div className="m-2 flex flex-col items-start gap-1">
    <Typography.LabelSm weight="medium" color="text-[#F4F4F5]">
      {t("community_inbox.admin_send_message_tooltip.title")}
    </Typography.LabelSm>
    <Typography.BodySm color="text-[#D4D4D8]">
      {t("community_inbox.admin_send_message_tooltip.description")}
    </Typography.BodySm>
  </div>
);
