import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { TRIGGER_RESOURCE_TYPE } from "@circle-react/components/SettingsApp/Workflows/constants";
import { FormTextSelect } from "@circle-react/components/shared/uikit/Form/FormTextSelect";
import { Typography } from "@circle-react-uikit/Typography";
import { AdminSelector } from "../Admin/AdminSelector";
import { HiddenCommunityInputs } from "../Community/shared/HiddenCommunityInputs";
import { LessonSelector } from "../Course/shared/LessonSelector";
import { SectionSelector } from "../Course/shared/SectionSelector";
import { WhichEventSelector } from "../Event/shared/WhichEventSelector";
import { PostSelector } from "../PostBasic/shared/PostSelector";
import { CourseSelector } from "../Space/shared/CourseSelector";
import { SpaceSelector } from "../Space/shared/SpaceSelector";
import { SpaceGroupSelector } from "../SpaceGroup/shared/SpaceGroupSelector";
import { useEntitySelector } from "./useEntitySelector";

const i18nRoot = "settings.workflows.form";

const componentsMap = {
  [TRIGGER_RESOURCE_TYPE.COMMUNITY]: HiddenCommunityInputs,
  [TRIGGER_RESOURCE_TYPE.SPACE_GROUP]: SpaceGroupSelector,
  [TRIGGER_RESOURCE_TYPE.SPACE]: SpaceSelector,
  [TRIGGER_RESOURCE_TYPE.COURSE_SPACE]: CourseSelector,
  [TRIGGER_RESOURCE_TYPE.POST_BASIC]: PostSelector,
  [TRIGGER_RESOURCE_TYPE.EVENT]: WhichEventSelector,
  [TRIGGER_RESOURCE_TYPE.LESSON]: LessonSelector,
  [TRIGGER_RESOURCE_TYPE.SECTION]: SectionSelector,
  [TRIGGER_RESOURCE_TYPE.COMMUNITY_MEMBER_ADMIN]: AdminSelector,
};

export const EntitySelector = ({ entityTypeOptions, ...props }) => {
  const { resourceType, handleEntitySelectorChange } = useEntitySelector();
  const Component = componentsMap[resourceType] || null;

  return (
    <>
      <FormTextSelect
        name="trigger.resource_type"
        options={entityTypeOptions}
        label={
          <Typography.LabelSm weight="semibold">
            {t([i18nRoot, "choose_entity"])}
          </Typography.LabelSm>
        }
        onChange={handleEntitySelectorChange}
      />
      {Component && <Component {...props} />}
    </>
  );
};

EntitySelector.propTypes = {
  entityTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};
