import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useGetPaywall } from "@circle-react/components/Modals/MemberProfileModal/Billing/Tabs/SubscriptionTab/ChangePlan/hooks/useGetPaywall";
import { Typography } from "@circle-react-uikit/Typography";
import { Badge } from "../../../Badge";
import { BaseView } from "../../BaseView";

const i18nRoot = "settings.workflows.view";
export const ViewCancelSubscription = ({ action }) => {
  const { api_params: apiParams } = action || {};
  const { paywall_id: paywallId } = apiParams || {};
  const { data: paywall = {}, isLoading } = useGetPaywall(paywallId);

  if (!paywall && !isLoading) {
    return null;
  }

  return (
    <BaseView>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "resources.paywall"])}</BaseView.Title>
        <div>
          {isLoading ? (
            <Badge isLoading />
          ) : (
            <Typography.LabelSm>
              {paywall.name || paywall.display_name}
            </Typography.LabelSm>
          )}
        </div>
      </BaseView.Section>
    </BaseView>
  );
};

ViewCancelSubscription.propTypes = {
  action: PropTypes.object,
};
