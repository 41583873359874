import { Suspense } from "react";
import { useParams } from "react-router-dom";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const AdminAudienceEditComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "AdminAudienceEditPage" */ "@circle-react/components/Modals/AdminAudienceEditModal/AdminAudienceEditModal"
    ),
);

export const AdminAudienceEditPage = () => {
  const { sqid } = useParams<{ sqid: string }>();

  return (
    <Suspense fallback={<PageLoader />}>
      <AdminAudienceEditComponent sqid={sqid} />
    </Suspense>
  );
};
