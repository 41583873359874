import { useOnboarding } from "@/react/components/CommunityOnboarding/useOnboarding";
import { CommunityOnboardingGettingStartedSection } from "@/react/components/Layout/CommunityOnboardingGettingStartedSection";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import {
  canAccessSpaceMembersPage,
  canShowPinnedPostSidebar,
} from "@/react/helpers/spaceHelpers";
import {
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { ErrorBoundary } from "@circle-react-uikit/ErrorBoundary";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { PinnedPostsV2 } from "./PinnedPostsV2";
import { TopMembersV2 } from "./TopMembersV2";

export const RightSidebarContent = () => {
  const { isOnboardingAvailable } = useOnboarding();
  const { data: space, isLoading: isSpaceLoading } = useCurrentSpaceContext();
  const { currentCommunityMember, isLoading: isPunditUserLoading } =
    usePunditUserContext();

  if (isSpaceLoading || isPunditUserLoading) {
    return null;
  }

  const isAdmin = isCommunityAdmin(currentCommunityMember);

  const shouldShowOnboarding = isAdmin && isOnboardingAvailable;

  return (
    <div className="space-y-6">
      {shouldShowOnboarding && <CommunityOnboardingGettingStartedSection />}
      {canAccessSpaceMembersPage(space, currentCommunityMember) && (
        <ErrorBoundary
          renderFunc={() => (
            <SkeletonLoader variant="right-sidebar" count={6} />
          )}
        >
          <TopMembersV2 />
        </ErrorBoundary>
      )}
      {canShowPinnedPostSidebar(space, currentCommunityMember) && (
        <ErrorBoundary renderFunc={() => null}>
          <PinnedPostsV2 />
        </ErrorBoundary>
      )}
    </div>
  );
};

RightSidebarContent.propTypes = {};
