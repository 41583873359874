import { PageMetaData } from "@/react/components/Layout/PageMetaData";
import { usePostDetails } from "@circle-react/contexts/postsDetailsContext";

export const PostMetaData = ({ postResource, bodyClassNames }: any) => {
  const { postDetails, isLoading } = usePostDetails(postResource.data?.id);

  if (isLoading || postResource.isLoading) {
    return null;
  }

  const title =
    postDetails.meta_tag_attributes?.meta_title ||
    postResource.data?.name ||
    postResource.data?.truncated_content;

  return <PageMetaData title={title} bodyClassNames={bodyClassNames} />;
};
