import { useEffect } from "react";
import { useThemeContext } from "@circle-react/providers";

const highlightAll = async () => {
  try {
    const { default: hljs } = await import(
      /* webpackChunkName: "HighlightJs_Core" */ "highlight.js/lib/core"
    );
    const { default: json } = await import(
      /* webpackChunkName: "HighlightJs_Languages_Json" */ "highlight.js/lib/languages/json"
    );
    hljs.registerLanguage("json", json);
    hljs.configure({ languages: ["json"] });
    hljs.highlightAll();
  } catch (error) {
    console.error("Error highlighting code", error);
  }
};

export const useHighlightCode = () => {
  const { isDarkAppearance } = useThemeContext();

  useEffect(() => {
    void highlightAll();
  }, [isDarkAppearance]);
};
