import { useMemo } from "react";
import { useAccessGroups } from "@circle-react/hooks/accessRegistry/useAccessGroups";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";

export const useAccessGroupNameMap = (filterParam?: BaseFilter[]) => {
  const accessGroupIds = filterParam?.map(({ value }) => String(value));

  const { data: accessGroupsPaginator } = useAccessGroups({
    params: {
      ids: accessGroupIds,
    },
    enabled: !!accessGroupIds?.length,
  });

  return useMemo(
    () =>
      accessGroupsPaginator?.records?.reduce(
        (accumulator: any, accessGroup: AccessGroupType) => {
          accumulator[String(accessGroup.id)] = accessGroup.name;
          return accumulator;
        },
        {},
      ),
    [accessGroupsPaginator],
  );
};
