import { useState } from "react";
import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { DownloadCSV } from "./DownloadCSV";
import { Participant } from "./Participant";
import { SearchParticipants } from "./SearchParticipant";
import { SortParticipants } from "./SortParticipant";
import { useDownloadCSV } from "./hooks/useDownloadCSV";
import { useParticipants } from "./hooks/useParticipants";
import "./styles.scss";

export const Participants = ({ roomId, participantsCount }) => {
  const { records, isLoading } = useParticipants(roomId);
  const [searchText, setSearchText] = useState("");
  const [enableAscendingSort, setEnableAscendingSort] = useState(false);
  const { onDownloadCSV } = useDownloadCSV(roomId);

  const onSearchChange = event => {
    setSearchText(event.target.value);
  };

  const onClickSort = sortDirection => {
    setEnableAscendingSort(!sortDirection);
  };

  let participants =
    (records &&
      records.filter(participant =>
        participant.name.toLowerCase().includes(searchText.toLocaleLowerCase()),
      )) ||
    [];

  if (enableAscendingSort) {
    participants = participants.sort((a, b) => b.name.localeCompare(a.name));
  } else {
    participants = participants.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <div className="stream-details__participants">
      <div className="border-primary mb-6 flex items-center justify-between border-b-[1px] pb-1.5">
        <SearchParticipants onSearchChange={onSearchChange} />
        <SortParticipants onClickSort={onClickSort} />
        <DownloadCSV onClickDownload={onDownloadCSV}></DownloadCSV>
      </div>
      {!isLoading && (
        <>
          <div className="stream-details__participants__label">
            {t("live_streams.dashboard.participants.participant_count", {
              count: participantsCount,
            })}
          </div>
          {participants && participants.length > 0 && (
            <div className="stream-details__participants__list">
              {participants.map(record => (
                <Participant key={record.id} record={record} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

Participants.propTypes = {
  roomId: PropTypes.number,
  participantsCount: PropTypes.number,
};
