import { t } from "@/i18n-js/instance";
import { SEARCHABLE_STATUS } from "@circle-react/helpers/communityMemberChargeHelpers";
import {
  CheckboxFilter,
  CheckboxSelectedText,
  Filter,
  useFilterUrl,
} from "@circle-react-shared/Filter";

const localeRoot = "settings.paywalls_admin_dashboard.filters.status";
const queryParamsKey = "status";

export const ChargesStatusFilter = ({
  isInitialOpen,
  changeUrl = true,
  value,
}: any) => {
  const { queryParams, updateUrl } = useFilterUrl();
  const statuses = SEARCHABLE_STATUS.map(status => ({
    label: t([localeRoot, "options", status]),
    value: status,
  }));

  return (
    <Filter
      chip={t([localeRoot, "chip"])}
      title={t([localeRoot, "title"])}
      selectedText={value || queryParams[queryParamsKey]}
      renderSelectedText={props => (
        <CheckboxSelectedText items={statuses} {...props} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        updateUrl("page", 1);
        changeUrl && updateUrl(queryParamsKey, value);
      }}
      shouldShowRemoveButton
    >
      <CheckboxFilter
        options={statuses}
        legend={t([localeRoot, "legend"])}
        name="subscription-statuses-filter"
        activeText={value ?? queryParams[queryParamsKey]}
      />
    </Filter>
  );
};

export const filterProperties = {
  name: "charge_status",
  filterKeys: [queryParamsKey],
  component: ChargesStatusFilter,
  label: t([localeRoot, "chip"]),
};
