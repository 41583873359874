import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import type { UseMutationResult } from "react-query";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { redirectToCheckoutConfirmation } from "@/react/components/Paywalls/Checkout/hooks/paymentMethods/helpers";
import { usePaywallCheckoutContext } from "@/react/contexts/Paywalls/paywallCheckoutContext";
import { paywallCheckoutApi } from "@circle-react/api/paywallCheckoutApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { getQueryParam } from "@circle-react/helpers/urlHelpers";
import { Loader } from "@circle-react-uikit/Loader";

// This only applies right now for Buy Now Pay Later providers where submitting the checkout redirects you to
// the Buy Now Pay Later provider and after confirmation or error you are redirected back to the checkout page

interface CheckoutSuccessResponse {
  checkout_attempt_id: string;
  checkout_attempt_status: string;
  payment_intent_processor_id?: string;
  payment_intent_client_secret?: string;
  subscription_processor_id?: string;
  invoice_processor_id?: string;
  community_member_id?: number | null;
  community_member_just_created?: boolean;
  community_member_subscription_id?: number | null;
  community_member_charge_id?: number | null;
}

interface CheckoutFailureResponse {
  message: string;
  error_details: string;
}

type CheckoutResponse = CheckoutSuccessResponse | CheckoutFailureResponse;

interface CheckoutParams {
  payment_intent_id: string | null;
  payment_method_type: string;
}

export const CheckoutRedirectHandler = () => {
  const { getValues } = useFormContext(); // Retrieves form data
  const formData = getValues(); // Fetches the current values of all form fields
  const { checkoutConfirmationUrl, handleError, setPaymentError } =
    usePaywallCheckoutContext();
  const paymentIntent = getQueryParam("payment_intent");
  const paymentMethodType = getQueryParam("payment_method_type") || "";
  const [isPerformingCheckout, setIsPerformingCheckout] =
    useState<boolean>(false);

  const checkoutMutation: UseMutationResult<
    CheckoutResponse,
    ApiError,
    CheckoutParams
  > = useMutation(params => paywallCheckoutApi.create({ formData: params }));
  const checkout = () =>
    checkoutMutation.mutateAsync(
      {
        ...formData,
        payment_intent_id: paymentIntent,
        payment_method_type: paymentMethodType,
      },
      {
        onError: (error: ApiError) => {
          setPaymentError({
            message: error.statusText,
            type: "danger",
            disablePayButton: false,
          });
        },
      },
    );

  const resetURL = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("payment_intent");
    url.searchParams.delete("payment_intent_client_secret");
    url.searchParams.delete("redirect_status");
    window.history.replaceState({}, "", url);
  };

  const performCheckout = async () => {
    if (paymentIntent) {
      setIsPerformingCheckout(true);
      try {
        const checkoutResponse = await checkout();

        if (
          "checkout_attempt_status" in checkoutResponse &&
          checkoutResponse.checkout_attempt_status === "completed"
        ) {
          redirectToCheckoutConfirmation(
            checkoutResponse,
            checkoutConfirmationUrl,
          );
        } else {
          resetURL();
          if ("message" in checkoutResponse) {
            handleError({
              message: checkoutResponse.message,
              disablePayButton: false,
            });
          }
        }
      } catch (error: any) {
        resetURL();
        setTimeout(() => {
          handleError({
            message: error.message,
            disablePayButton: false,
          });
        }, 2000);
        // Waiting for the preview to be ready. Rendering too early means the message will be cleared by the preview response
        setIsPerformingCheckout(false);
      }
    }
  };

  useEffect(() => {
    void performCheckout();
  }, [paymentIntent]);

  if (isPerformingCheckout) {
    return (
      <Loader
        variant="fullscreen"
        message={t("paywalls.checkout.bnpl_processing")}
      />
    );
  }

  return null;
};
