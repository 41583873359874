import { Suspense } from "react";
import { PushNotificationsListProvider } from "@/react/components/SettingsApp/PushNotificationsDashboard/context/PushNotificationsListProvider";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const PushNotificationsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PushNotificationsDashboardPage" */ "@circle-react/components/SettingsApp/PushNotificationsDashboard/index"
    ),
);

export const PushNotificationsDashboardPage = () => (
  <Suspense fallback={<PageLoader />}>
    <PushNotificationsListProvider>
      <PushNotificationsComponent />
    </PushNotificationsListProvider>
  </Suspense>
);
