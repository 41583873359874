import { t } from "@/i18n-js/instance";
import { isPlanPlus } from "@/react/helpers/planHelpers";
import type { CurrentPlan } from "@circle-react/types";
import { Typography } from "@circle-react-uikit/Typography";
import { PlanFeature } from "./PlanFeature";

interface CurrentPlanProps {
  currentPlan: CurrentPlan;
}

export const PlanFeatures = ({ currentPlan }: CurrentPlanProps) => (
  <div className="bg-primary border-primary rounded-lg border px-8 py-7">
    <div className="border-primary mb-6 flex justify-between border-b pb-6">
      <Typography.TitleSm weight="bold" data-testid="plan-name">
        {isPlanPlus(currentPlan.tier)
          ? currentPlan.name
          : t(`settings.billing.plans.${currentPlan.tier}`)}
      </Typography.TitleSm>
      <Typography.TitleSm weight="bold" data-testid="plan-price">
        {isPlanPlus(currentPlan.tier)
          ? currentPlan.annual_base_amount_with_interval
          : currentPlan.base_amount_with_interval}
      </Typography.TitleSm>
    </div>

    <div className="mb-4">
      <Typography.LabelMd weight="semibold">
        {t("settings.billing.your_plan")}
      </Typography.LabelMd>
    </div>

    <PlanFeature
      count={currentPlan.max_space_count}
      remainingValue={currentPlan.remaining_max_space_count}
    />

    <PlanFeature
      count={currentPlan.max_admin_count}
      remainingValue={currentPlan.remaining_max_admin_count}
    />

    <PlanFeature
      count={currentPlan.max_moderator_count}
      remainingValue={currentPlan.remaining_max_moderator_count}
    />

    <PlanFeature
      count={currentPlan.max_member_count}
      remainingValue={currentPlan.remaining_max_member_count}
    />

    <PlanFeature count={currentPlan.live_stream_max_host_capacity} />

    <PlanFeature count={currentPlan.live_stream_max_spectator_capacity} />

    <PlanFeature
      count={currentPlan.live_stream_monthly_stream_hours}
      remainingValue={currentPlan.remaining_live_stream_monthly_stream_hours}
    />

    <PlanFeature
      count={currentPlan.live_stream_recording_hours}
      remainingValue={currentPlan.remaining_live_stream_recording_hours}
    />

    <PlanFeature count={currentPlan.live_room_participants_capacity} />
  </div>
);
