import { t } from "@/i18n-js/instance";
import { useProfileFieldDetail } from "@circle-react/components/SettingsApp/ProfileFields/hooks/useProfileFieldDetail";
import { BaseResource } from "../../BaseResource";

const i18nRoot = "settings.workflows.view";

export const ProfileFieldResource = ({
  resourceId,
  contactType,
}: {
  resourceId: number;
  contactType: string;
}) => {
  const {
    getOptions: { data: profileField, isLoading },
  } = useProfileFieldDetail({ fieldId: resourceId });

  return (
    <div className="flex flex-col gap-6">
      <BaseResource
        label={t("settings.workflows.view.resources.profile_field")}
        value={profileField?.label}
        isLoading={isLoading}
        shouldRepresentAsBadge
      />
      <BaseResource
        label={t([i18nRoot, "resources.contact_type"])}
        value={t([
          "settings.workflows.form.audience_type_options",
          contactType,
        ])}
      />
    </div>
  );
};
