import { t } from "@/i18n-js/instance";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";

interface LeftBackArrowProps {
  onClick: () => void;
}

export const LeftBackArrow = ({ onClick }: LeftBackArrowProps) => (
  <div className="flex flex-row items-center gap-3">
    <IconButton
      name="20-arrow-left"
      onClick={onClick}
      ariaLabel={t("back")}
      iconSize={20}
      buttonSize={32}
    />
    <div className="border-primary h-7 border-l" />
  </div>
);
