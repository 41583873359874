import { useModal } from "@ebay/nice-modal-react";
import { t } from "@/i18n-js/instance";
import { IconButton } from "@circle-react/components/shared/uikit/HeaderV3/IconButton";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useAddFileModal } from "../AddFileModal";
import { useAddSnippetModal } from "../AddSnippetModal";

export interface Snippet {
  title: string;
  body: string;
}

export const AddContentModalComponent = () => {
  const modal = useModal();
  const addSnippetModal = useAddSnippetModal();
  const addFileModal = useAddFileModal();
  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content
        size="auto"
        className="h-3 max-w-[720px] overflow-visible !rounded-t-none sm:h-full sm:!rounded-t-lg md:max-h-[80vh] lg:max-h-[260px]"
      >
        <header className="flex items-center justify-between px-8 pb-4 pt-6">
          <Typography.TitleSm weight="semibold">
            {t(
              "settings.community_bot.knowledge.custom.add_content_modal.title",
            )}
          </Typography.TitleSm>
          <ul className="text-darkest flex list-none items-center space-x-2">
            <li>
              <TippyV2
                placement="bottom"
                content={t("quick_post_v2.header.close")}
              >
                <IconButton
                  variant="secondary"
                  iconSize={20}
                  onClick={modal.hide}
                  iconClassName="!text-dark"
                  name="20-close"
                  ariaLabel={t("quick_post_v2.header.close")}
                  className="!p-0"
                />
              </TippyV2>
            </li>
          </ul>
        </header>
        <div className="flex h-full flex-row gap-6 px-6 pb-6">
          <button
            type="button"
            className="hover:border-secondary flex h-full w-1/2 cursor-pointer flex-col justify-center rounded-lg border"
            aria-label={t(
              "settings.community_bot.knowledge.custom.add_content_modal.snippet.title",
            )}
            onClick={() => {
              void modal.hide();
              void addSnippetModal.show();
            }}
          >
            <div className="m-2 flex flex-col items-start justify-start gap-2 p-3">
              <div className="mb-2">
                <Icon
                  className="!h-[36px] !w-[36px]"
                  type="36-ai-snippet"
                  size={36}
                />
              </div>
              <Typography.LabelMd weight="semibold">
                {t(
                  "settings.community_bot.knowledge.custom.add_content_modal.snippet.title",
                )}
              </Typography.LabelMd>
              <Typography.LabelXs>
                {t(
                  "settings.community_bot.knowledge.custom.add_content_modal.snippet.description",
                )}
              </Typography.LabelXs>
            </div>
          </button>
          <button
            type="button"
            className="hover:border-secondary flex h-full w-1/2 cursor-pointer flex-col justify-center rounded-lg border"
            aria-label={t(
              "settings.community_bot.knowledge.custom.add_content_modal.file.title",
            )}
            onClick={() => {
              void modal.hide();
              void addFileModal.show();
            }}
          >
            <div className="m-2 flex flex-col items-start justify-start gap-2 p-3">
              <div className="mb-2">
                <Icon
                  className="!h-[36px] !w-[36px]"
                  type="36-ai-file"
                  size={36}
                />
              </div>
              <Typography.LabelMd weight="semibold">
                {t(
                  "settings.community_bot.knowledge.custom.add_content_modal.file.title",
                )}
              </Typography.LabelMd>
              <Typography.LabelXs>
                {t(
                  "settings.community_bot.knowledge.custom.add_content_modal.file.description",
                )}
              </Typography.LabelXs>
            </div>
          </button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
