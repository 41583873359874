import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { DisableModalComponent } from "./DisableModalComponent";

const DisableModal = ModalManager.create(({ agent }: { agent: AgentProp }) => (
  <DisableModalComponent agent={agent} />
));

export const useDisableModal = (agent: AgentProp) =>
  useModal(DisableModal, { agent });
