import { DataTable } from "@circle-react-uikit/DataTableV2";
import { useEditSnippetModal } from "./EditSnippetModal";

export const SnippetsList = ({ columns, snippetsQuery, onChangePage }: any) => {
  const editSnippetModal = useEditSnippetModal();

  return (
    <DataTable
      columns={columns}
      data={snippetsQuery?.data?.records ?? []}
      isLoading={snippetsQuery?.isLoading}
      initialPage={snippetsQuery?.data?.page}
      pageSize={snippetsQuery?.data?.per_page}
      totalRecordsCount={snippetsQuery?.data?.count}
      onChangePage={onChangePage}
      onClickRow={({ original }) => {
        if (original.type !== "File") {
          void editSnippetModal.show({ snippetId: original.id });
        }
      }}
    >
      <DataTable.Table>
        <DataTable.Header />
        <DataTable.Loader />
        <DataTable.Body />
      </DataTable.Table>
      <DataTable.Pagination align="left" direction="reverse" />
    </DataTable>
  );
};
