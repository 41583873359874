import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import I18n from "@/i18n-js/instance";
import { homeSettingsApi } from "@circle-react/api";
import { Icon } from "@circle-react/components/shared/Icon";
import { Drawer } from "@circle-react/components/shared/uikit/Drawer";
import { usePunditUserContext } from "@circle-react/contexts";
import { useSpaceLinkWithDefaultTab } from "@circle-react/helpers/spaceHelpers";
import { useSpaceApi } from "@circle-react/hooks/useSpaceApi";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Customize } from "./Customize";
import { Details } from "./Details";
import Navigation from "./Navigation";
import Options from "./Options";
import { TABS } from "./constants";
import { parseSpacePayload, parseSpaceResponse } from "./helpers";
import "./styles.scss";

export const EditForm = ({
  spaceId,
  spaceGroupId,
  refetch,
  refreshSidebarSpaces,
}) => {
  const { fetchSpace, space } = useSpaceApi();
  const getSpaceLinkWithDefaultTab = useSpaceLinkWithDefaultTab();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.DETAILS);
  const [imageToDelete, setImageToDelete] = useState(null);
  const { currentCommunityMember } = usePunditUserContext();

  useEffect(() => {
    fetchSpace(spaceId);
  }, []);

  const renderTab = () => {
    switch (activeTab) {
      case TABS.DETAILS:
        return <Details currentSpaceGroup={spaceGroupId} />;
      case TABS.CUSTOMIZE:
        return <Customize space={space} />;
      case TABS.OPTIONS:
        return <Options space={space} setImageToDelete={setImageToDelete} />;
    }
  };

  const { updateSpace } = useSpaceApi();
  const toastr = useToast();
  const history = useHistory();

  const handleSubmit = async (data, { reset }) => {
    try {
      setIsSubmitting(true);
      const payload = parseSpacePayload(data);
      if (imageToDelete) {
        await homeSettingsApi.deleteOpenGraphImage(imageToDelete);
        setImageToDelete(false);
      }
      const newSpaceValues = await updateSpace(payload, spaceId);
      await Promise.all([refetch(), refreshSidebarSpaces()]);
      reset(parseSpaceResponse(newSpaceValues));
      history.push(
        getSpaceLinkWithDefaultTab(newSpaceValues, currentCommunityMember),
      );
      toastr.success(I18n.t("spaces.form.edit.success"));
    } catch (err) {
      toastr.error(I18n.t("spaces.form.edit.error"));
    } finally {
      setIsSubmitting(false);
    }
  };

  const formDefaultValues = useMemo(
    () => space && parseSpaceResponse(space),
    [space],
  );

  if (!space || !formDefaultValues) {
    return (
      <div className="edit-space__loader">
        <Icon type="loader" />
      </div>
    );
  }
  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={formDefaultValues}
      className="form__wrapper edit-space edit-space__form"
    >
      <Navigation
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        space={space}
      />
      {renderTab()}

      <Drawer.Footer sticky>
        <Button disabled={isSubmitting} type="submit" full variant="primary">
          {isSubmitting
            ? I18n.t("spaces.form.edit.apply_changes_button_saving")
            : I18n.t("spaces.form.edit.apply_changes_button")}
        </Button>
      </Drawer.Footer>
    </Form>
  );
};

EditForm.propTypes = {
  spaceId: PropTypes.string.isRequired,
  spaceGroupId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  refreshSidebarSpaces: PropTypes.func.isRequired,
};
