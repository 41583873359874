import { useEffect, useRef } from "react";
import autosize from "autosize";
import classnames from "classnames";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { Form } from "@circle-react-uikit/Form";
import { FormAttachment } from "../FormAttachment";
import "./styles.css";

export const CommentFormV2 = ({
  isSubmitting,
  uploadAttachments,
  attachments,
  cancelAttachment,
  commentsCount,
  onSubmit,
}) => {
  const { setValue, handleSubmit, reset } = useFormContext();
  const comment = useWatch({ name: "comment" });
  const isFormDirty = comment;
  const allAttachmentsComplete = attachments.every(
    attachment => attachment.completed,
  );

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop: uploadAttachments,
    noClick: true,
  });

  const openFilePicker = event => {
    event.preventDefault();
    inputRef.current.click();
  };

  const isSubmitAllowed =
    !isSubmitting && isFormDirty && allAttachmentsComplete;

  const handleIconClick = () => {
    if (isSubmitAllowed) {
      handleSubmit(onSubmit)();

      textareaRef.current.disabled = true;
    }
  };

  const textareaRef = useRef(null);

  const handleTextAreaInputChange = () => {
    autosize(textareaRef.current);
    document.getElementById("scroll-to-bottom").scrollIntoView();
  };

  useEffect(() => {
    setValue("comment", "");
    reset();
    textareaRef.current.disabled = false;
    textareaRef.current.style["height"] = "44px"; // a hack to reset textarea after submitting
    document.getElementById("scroll-to-bottom").scrollIntoView();
  }, [setValue, reset, commentsCount]);

  useEffect(() => {
    handleTextAreaInputChange();
  }, []);

  return (
    <>
      <Form.Input
        name="comment"
        variant="textarea"
        label={null}
        placeholder={t("support_widget.chatbot.message_placeholder")}
        ref={textareaRef}
        rows={1}
        onInput={handleTextAreaInputChange}
        hideBorder
        focusRequired={false}
        className="form-control hide-scrollbar !mb-1 !mt-2 max-h-[128px] resize-none !py-3 !pl-6 focus:!ring-0 focus:!ring-offset-0"
      />
      {attachments.length > 0 && (
        <div className="my-4 flex flex-col gap-2 pl-6 pr-4">
          {attachments.map(attachment => (
            <FormAttachment
              key={attachment.url}
              attachment={attachment}
              onCancel={cancelAttachment}
            />
          ))}
        </div>
      )}
      <div className="flex justify-between pb-4 pl-6 pr-4" {...getRootProps()}>
        <input {...getInputProps()} />
        <TippyV2
          placement="right"
          content={t("tiptap.attach_file")}
          className="hover:text-circle focus:text-circle text-dark visited:text-dark"
        >
          <button
            type="submit"
            aria-label={t("tiptap.attach_file")}
            onClick={openFilePicker}
          >
            <Icon type="tiptap-paperclip" size={20} />
          </button>
        </TippyV2>
        <span
          className={classnames({
            "text-circle dark:text-circle visited:text-circle": isSubmitAllowed,
            "text-dark visited:text-dark": !isSubmitAllowed,
          })}
        >
          <Icon
            type="tiptap-send"
            className={classnames({
              "cursor-pointer": isSubmitAllowed,
              "cursor-not-allowed": !isSubmitAllowed,
            })}
            onClick={handleIconClick}
            size={20}
          />
        </span>
      </div>
    </>
  );
};
