import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const BrandedAppsBuilderUIComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BrandedAPpsBuilderUIPage" */ "@circle-react/components/SettingsApp/BrandedAppsBuilderUI"
    ),
);

export const BrandedAppsBuilderUIPage = () => (
  <Suspense fallback={<PageLoader />}>
    <BrandedAppsBuilderUIComponent />
  </Suspense>
);
