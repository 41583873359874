import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export interface CommunityWrapperProps extends ComponentPropsWithoutRef<"div"> {
  children: ReactNode;
  className?: string;
}

export const CommunityWrapper = ({
  children,
  className = "",
  ...rest
}: CommunityWrapperProps) => (
  <div
    className={classNames("bg-secondary", className)}
    data-testid="community-wrapper"
    {...rest}
  >
    {children}
  </div>
);
