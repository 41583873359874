import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";

interface SpaceGroupChevronProps {
  isActive?: boolean;
  isCollapsed?: boolean;
}

export const SpaceGroupChevron = ({
  isActive = false,
  isCollapsed = false,
}: SpaceGroupChevronProps) => {
  const canHover = useAnyHoverMediaQuery();

  return (
    <div
      className={classNames(
        "ml-1 mr-auto flex h-5 w-5 rounded py-0.5 transition-transform",
        {
          "hidden group-hover:flex": canHover,
          "hover:bg-c-sidebar-hover": !isActive,
          "rotate-0": isCollapsed,
          "rotate-90": !isCollapsed,
        },
      )}
      aria-label={
        isCollapsed
          ? t("community_sidebar.expand_group", { group_name: "space" })
          : t("community_sidebar.collapse_group", { group_name: "space" })
      }
    >
      <Icon type="cheveron-right" size={16} aria-hidden />
    </div>
  );
};
