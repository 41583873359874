import { useMemo, useState } from "react";
import { sortBy } from "lodash";
import type { Topic } from "@circle-react/types/Topic";
import { useFilters } from "./useFilters";

interface UseActiveFiltersHistoryOptions {
  sortTopics?: boolean;
}

// extends useFilters to sort topics based on last active filters
export const useActiveFiltersHistory = (
  unsortedTopics: Topic[],
  options: UseActiveFiltersHistoryOptions = {},
) => {
  const { currentFilters, AddOrRemoveFilter, clearFilters, isFilterSelected } =
    useFilters();

  // store initial filters in active filters history
  const [recentActiveFilters, setRecentActiveFilters] =
    useState(currentFilters);

  const topics = useMemo<Topic[]>(() => {
    const shouldSortTopics = options.sortTopics && recentActiveFilters.size > 0;
    if (shouldSortTopics) {
      // sort topics to move recent selected topics up
      const positionMap = new Map(
        Array.from(recentActiveFilters).map((item, index) => [item, index]),
      );
      return sortBy(unsortedTopics, t =>
        positionMap.get(t.id.toString()) !== undefined
          ? positionMap.get(t.id.toString())
          : Infinity,
      );
    }
    return unsortedTopics;
  }, [unsortedTopics, recentActiveFilters, options.sortTopics]);

  const toggleFilter = (topicId: string, addToHistory = false) => {
    AddOrRemoveFilter(topicId);
    if (addToHistory && isFilterSelected(topicId)) {
      setRecentActiveFilters(new Set([topicId]));
    }
  };

  const clearFiltersAndHistory = () => {
    clearFilters();
    setRecentActiveFilters(new Set());
  };

  return {
    topics,
    currentFilters,
    toggleFilter,
    clearFiltersAndHistory,
    isFilterSelected,
  };
};
