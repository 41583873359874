import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";

export const AskBotBar = () => {
  const history = useHistory();
  return (
    <div className="relative">
      <div className="pointer-events-none absolute left-0 top-2.5 flex items-center pl-3 text-gray-500">
        <Icon type="20-ai" size={20} />
      </div>
      <input
        type="text"
        className="border-primary bg-primary text-default focus:border-light placeholder:text-light block h-10 w-full rounded-md pl-10 placeholder:opacity-100 focus:ring-0 sm:text-sm"
        placeholder={t("support_widget.chatbot.what_do_you_need_help_with")}
        onFocus={() => history.push("/ask")}
      />
    </div>
  );
};
