import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import type { Profile } from "@/react/types/CommunityBot/Profile";
import { useCommunityBotProfileSearch } from "@circle-react/components/SettingsApp/CommunityInbox/hooks/useCommunityBotProfileSearch";
import { newChatRoomPath } from "@circle-react/helpers/urlHelpers";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { AgentAvatar } from "./AgentAvatar";

export const EmptyStateForRightActionBlock = () => {
  const { botProfiles, isBotsLoading } = useCommunityBotProfileSearch({
    name: "",
  });
  const { setCurrentBotProfile } = useCommunityInboxStore();
  const history = useHistory();

  const handleNewConversationClick = (botProfile: Profile) => {
    setCurrentBotProfile(botProfile);
    history.push(newChatRoomPath());
  };

  if (isBotsLoading) {
    return <Loader variant="fullscreen" />;
  }

  if (!botProfiles.length) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-start pt-12">
        <Typography.LabelSm>{t("messaging.no_messages")}</Typography.LabelSm>
      </div>
    );
  }

  if (botProfiles.length === 1) {
    const botProfile = botProfiles[0];
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-3">
        <div className="flex flex-col items-center gap-2">
          <AgentAvatar profile={botProfile} />
          <Typography.TitleSm as="h2" weight="semibold">
            {botProfile.name}
          </Typography.TitleSm>
          <div className="text-center text-sm font-normal leading-5 tracking-tighter">
            <TiptapEditor editable={false} rich_text_body={botProfile.bio} />
          </div>
        </div>
        <Button
          variant="circle"
          type="button"
          onClick={() => handleNewConversationClick(botProfile)}
          className="text-xs-plus font-semibold"
        >
          {t("community_inbox.new_conversation")}
        </Button>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex flex-col gap-3 px-5 py-3.5">
        <Typography.BodySm color="text-gray-500">
          {t("community_inbox.start_conversation")}
        </Typography.BodySm>
      </div>
      <div className="flex flex-col gap-1.5 px-5 pb-4">
        {botProfiles.map((botProfile: Profile) => (
          <button
            key={botProfile.id}
            type="button"
            onClick={() => handleNewConversationClick(botProfile)}
          >
            <div className="flex gap-3 rounded-lg border px-5 py-4">
              <AgentAvatar
                profile={botProfile}
                className="border-primary !h-11 !w-11 rounded-full border-4"
              />
              <div className="flex flex-1 flex-col text-left">
                <Typography.LabelSm weight="semibold">
                  {botProfile.name}
                </Typography.LabelSm>
                <div className="text-tertiary text-xs font-normal leading-5 tracking-tight">
                  <TiptapEditor
                    editable={false}
                    rich_text_body={botProfile.bio}
                  />
                </div>
              </div>
              <Icon
                type="20-chevron-right"
                className="text-default ml-auto"
                width={20}
                height={20}
              />
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
