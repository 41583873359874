import { useCallback, useRef, useState } from "react";
import { noop } from "lodash";

export const useComments = () => {
  const commentsFormRef = useRef({ focus: noop });
  const [shouldShowComment, setShouldShowComment] = useState(true);
  const [shouldAutofocusReplyForm, setShouldAutofocusReplyForm] =
    useState(false);

  const focusCommentsForm = useCallback(
    () => commentsFormRef?.current?.focus(),
    [commentsFormRef],
  );

  const toggleComment = () => {
    setShouldAutofocusReplyForm(false);
    setShouldShowComment(prev => !prev);
  };

  const showComments = useCallback(() => {
    setShouldShowComment(true);
    setShouldAutofocusReplyForm(true);

    // Focus the reply box if its already open
    if (commentsFormRef.current) {
      commentsFormRef.current.focus();
    }
  }, []);

  return {
    commentsFormRef,
    shouldShowComment,
    setShouldShowComment,
    focusCommentsForm,
    toggleComment,
    showComments,
    shouldAutofocusReplyForm,
  };
};
