export const asyncTaskTypes = Object.freeze({
  BULK_IMPORT_COMMUNITY_MEMBER: "AsyncTasks::BulkCommunityMemberImportTask",
  EXPORT_CSV_COMMUNITY_MEMBER_CHARGE:
    "AsyncTasks::ExportCsv::Paywalls::CommunityMemberCharge",
  EXPORT_CSV_COMMUNITY_MEMBER_SUBSCRIPTION:
    "AsyncTasks::ExportCsv::Paywalls::CommunityMemberSubscription",
  ACCESS_GROUP_MANAGE_MEMBERS: "AsyncTasks::AccessGroups::ManageMembersTask",
  PAYWALL_MEMBERS_MIGRATION:
    "AsyncTasks::AccessGroups::PaywallMembersMigrationTask",
});

export const asyncTaskStatus = Object.freeze({
  PENDING: "pending",
  PROCESSING: "processing",
  COMPLETED: "completed",
  PARTIAL_SUCCESS: "partial_success",
  FAILED: "failed",
});

export const asyncTaskChannelStatus = Object.freeze({
  FINISHED: "asyncTaskFinished",
  UPDATED: "asyncTaskUpdated",
});

export const CHANNEL_NAME = "AsyncTaskChannel";

export const isAsyncTaskChannelFinished = (
  status: string,
): status is (typeof asyncTaskChannelStatus)[keyof typeof asyncTaskChannelStatus] =>
  status === asyncTaskChannelStatus.FINISHED;

export const isAsyncTaskChannelUpdated = (
  status: string,
): status is (typeof asyncTaskChannelStatus)[keyof typeof asyncTaskChannelStatus] =>
  status === asyncTaskChannelStatus.UPDATED;

export const isAsyncTaskCompleted = (
  status: string,
): status is (typeof asyncTaskStatus)[keyof typeof asyncTaskStatus] =>
  status === asyncTaskStatus.COMPLETED;

export const didAsyncTaskFail = (
  status: string,
): status is (typeof asyncTaskStatus)[keyof typeof asyncTaskStatus] =>
  status === asyncTaskStatus.FAILED;

export const isAccessGroupManageMembersTask = (
  taskType: string,
): taskType is (typeof asyncTaskTypes)[keyof typeof asyncTaskTypes] =>
  taskType === asyncTaskTypes.ACCESS_GROUP_MANAGE_MEMBERS;

export const isPaywallMembersMigrationTask = (
  taskType: string,
): taskType is (typeof asyncTaskTypes)[keyof typeof asyncTaskTypes] =>
  taskType === asyncTaskTypes.PAYWALL_MEMBERS_MIGRATION;
