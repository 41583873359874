import { uniqueId } from "lodash";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { BroadcastSettingsForm } from "./BroadcastSettingsForm";
import { I18N_ROOT } from "./constants";

const formId = uniqueId("broadcast-settings-form-");

interface BroadcastSettingsAddressModalContentProps {
  confirmText?: string;
  onSuccess?: () => void;
}

const BroadcastSettingsAddressModalContent = ({
  confirmText,
  onSuccess,
}: BroadcastSettingsAddressModalContentProps) => {
  const modal = useModal();

  return (
    <ConfirmationModal
      isOpen={modal.visible}
      onClose={modal.hide}
      confirmVariant="circle"
      confirmText={confirmText || t("save")}
      title={t([I18N_ROOT, "can_you_tell_bit_more"])}
      onCancel={modal.hide}
      formId={formId}
      size="xl"
      titleSize="md"
      headerClassName="!pb-4 !border-solid !border-b"
      footerClassName="border border-t"
    >
      <BroadcastSettingsForm
        className="py-6"
        formId={formId}
        shouldShowSaveButton={false}
        modal={modal}
        onSuccess={onSuccess}
      />
    </ConfirmationModal>
  );
};

export const BroadcastSettingsAddressModal = ModalManager.create(
  ({ confirmText, onSuccess }: BroadcastSettingsAddressModalContentProps) => (
    <BroadcastSettingsAddressModalContent
      confirmText={confirmText}
      onSuccess={onSuccess}
    />
  ),
);

export const useBroadcastSettingsAddressModal = () =>
  useModal(BroadcastSettingsAddressModal);
