import { useState } from "react";
import { isEmpty } from "lodash";
import { t } from "@/i18n-js/instance";
import { TypeBadge } from "@/react/pages/BotBuilder/KnowledgePage/TypeBadge";
import { useAddSpaceModal } from "@circle-react/components/Modals/AddSpaceModal";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { EmptyState } from "@circle-react-shared/uikit/EmptyState";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { KnowledgeBannerV2 } from "./KnowledgeBannerV2";
import { SpacesList } from "./SpacesList";
import { useIndexSpaces, useShowSetting } from "./queries";

const generateColumns = () => [
  {
    header: t("settings.community_bot.knowledge.spaces.columns_v2.name"),
    accessorKey: "name",
    cell: ({ row }: any) => (
      <Typography.LabelSm weight="semibold">
        {row.original?.name}
      </Typography.LabelSm>
    ),
    canSort: false,
    cellClassName: "w-1/2",
  },
  {
    header: t("settings.community_bot.knowledge.spaces.columns_v2.type"),
    accessorKey: "type",
    cell: ({ row }: any) => <TypeBadge type={row.original?.type || "Post"} />,
    canSort: false,
  },
  {
    header: t(
      "settings.community_bot.knowledge.spaces.columns_v2.items_included",
    ),
    accessorKey: "posts_count",
    cell: ({ row }: any) => row.original?.posts_count,
    canSort: false,
  },
  {
    header: t(
      "settings.community_bot.knowledge.spaces.columns_v2.used_in_replies",
    ),
    accessorKey: "used_in_replies",
    cell: ({ row }: any) => row.original?.used_in_replies,
    canSort: false,
  },
];

export const SpacesV2 = () => {
  const [currentPage, setPage] = useState(1);
  const spaceModal = useAddSpaceModal();

  const spacesQuery = useIndexSpaces({ currentPage });
  const settingQuery = useShowSetting();

  const onChangePage = (page: number) => {
    setPage(page);
    spacesQuery.remove();
  };

  const spacesCount = spacesQuery?.data?.count || 0;
  const hasNoResults = isEmpty(spacesQuery?.data?.records || []);

  if (settingQuery.isLoading || spacesQuery?.isLoading) {
    return <Loader />;
  }

  if (hasNoResults) {
    return (
      <div className="mt-[20vh]">
        <EmptyState
          header={t(
            "settings.community_bot.knowledge.community.empty_state.header",
          )}
          description={t(
            "settings.community_bot.knowledge.community.empty_state.description",
          )}
          primaryLabel={t(
            "settings.community_bot.knowledge.community.empty_state.primary_label",
          )}
          onPrimaryClick={() => spaceModal.show()}
        />
      </div>
    );
  }

  return (
    <>
      {spacesCount > 0 && (
        <>
          {!settingQuery?.data?.hide_knowledge_banner && <KnowledgeBannerV2 />}
          <div className="px-6 pb-1 pt-5 lg:px-9">
            <div className="flex min-h-[1.75rem] items-center gap-4">
              <Typography.LabelMd weight="semibold">
                {t("settings.community_bot.knowledge.spaces.count", {
                  count: spacesCount,
                })}
              </Typography.LabelMd>
            </div>
          </div>
        </>
      )}
      <DataTable.TableWrapper>
        <div className="mt-0.5">
          <SpacesList
            columns={generateColumns()}
            spacesQuery={spacesQuery}
            onChangePage={onChangePage}
          />
        </div>
      </DataTable.TableWrapper>
    </>
  );
};
