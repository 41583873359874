/* eslint-disable tailwindcss/no-custom-classname -- this will be refactored later when we introduce modals v3*/
import PropTypes from "prop-types";
import I18n from "@/i18n-js/instance";
import { mobileCellStyles } from "@circle-react-shared/uikit/TableV2/constants";
import { Button } from "@circle-react-uikit/Button";
import { DataTable } from "@circle-react-uikit/DataTable";
import { StatusBadge } from "@circle-react-uikit/StatusBadge";
import { useCouponFormDrawer } from "../NewForm/CouponFormDrawer";
import { usePaywallCouponApi } from "../hooks/usePaywallCouponApi";
import { Actions } from "./Actions";

export const Records = ({ canCreate, shouldHideHeader }) => {
  const { useListPaywallCoupons } = usePaywallCouponApi({});

  const { data: paywallCoupons, refetch: refetchCoupons } =
    useListPaywallCoupons();

  const columns = [
    {
      title: I18n.t("settings.paywall_coupons.form.code_info").toUpperCase(),
      dataIndex: "code",
      key: "code",
    },
    {
      title: I18n.t("settings.paywall_coupons.form.terms").toUpperCase(),
      dataIndex: "terms",
      key: "terms",
    },
    {
      title: I18n.t("settings.paywall_coupons.form.redemptions").toUpperCase(),
      dataIndex: "redemptions",
      key: "redemptions",
      className: mobileCellStyles.lg,
      tdClassName: mobileCellStyles.lg,
    },
    {
      title: I18n.t("settings.paywall_coupons.form.status").toUpperCase(),
      dataIndex: "status",
      key: "status",
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
    },
  ];

  const dataSource = paywallCoupons?.map(paywallCoupon => ({
    key: paywallCoupon.id,
    name: paywallCoupon.name,
    redemptions: paywallCoupon.redemptions_count,
    terms: paywallCoupon.terms,
    code: paywallCoupon.code,
    status: (
      <StatusBadge
        variant={paywallCoupon.active ? "success" : "danger"}
        textTransform="capitalize"
      >
        {I18n.t(["settings.paywall_coupons.status", paywallCoupon.status])}
      </StatusBadge>
    ),
    action: (
      <Actions paywallCoupon={paywallCoupon} refetchCoupons={refetchCoupons} />
    ),
    rowClassName: "group",
  }));

  const couponFormDrawer = useCouponFormDrawer();
  const openNewCouponDrawer = () => {
    couponFormDrawer.show({ refetchCoupons: refetchCoupons });
  };

  return (
    <>
      {!shouldHideHeader && (
        <div className="table-wrapper">
          <div className="flex justify-between">
            <h3>{I18n.t("settings.paywall_coupons.title")}</h3>
            {canCreate && (
              <Button variant="circle" onClick={openNewCouponDrawer}>
                {I18n.t("settings.paywall_coupons.buttons.new")}
              </Button>
            )}
          </div>
        </div>
      )}
      <div className="paywall-coupons-list" data-testid="coupon-list">
        <DataTable
          columns={columns}
          className="paywall-coupons-list__table"
          dataSource={dataSource || []}
        />
      </div>
    </>
  );
};

Records.propTypes = {
  canCreate: PropTypes.bool,
  shouldHideHeader: PropTypes.bool,
};
