import { useCallback, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import type { AccessGroupType } from "@/react/types/AccessGroup";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { AccessGroupCard } from "@circle-react-shared/AccessRegistry/AccessGroupCard";
import { Select } from "@circle-react-shared/uikit/Select";
import { AccessGroupFormActions } from "./AccessGroupFormActions";
import { FORM_FIELD_KEYS } from "./constants";

const i18nBase = "settings.access_groups.paywall_migration.modal.form";

interface AccessGroupsChooserProps {
  accessGroups: AccessGroupType[];
}

export const AccessGroupsChooser = ({
  accessGroups,
}: AccessGroupsChooserProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { remove, append } = useFieldArray({
    name: FORM_FIELD_KEYS.access_groups,
  });

  const { watch } = useFormContext();
  const selectedAccessGroups: Array<AccessGroupType> = watch(
    FORM_FIELD_KEYS.access_groups,
  );

  const selectableAccessGroups = () =>
    accessGroups.filter(
      accessGroup =>
        !selectedAccessGroups.some(
          selectedGroup => selectedGroup.id === accessGroup.id,
        ),
    );

  const onSearch = useCallback(
    (searchValue: string) => {
      setSearchQuery(searchValue);
    },
    [setSearchQuery],
  );

  const onChange = useCallback(
    ({ value }: { value: string; text: string }) => {
      const accessGroup = accessGroups.find(
        accessGroup => `${accessGroup.id}` === value,
      );
      if (accessGroup) {
        append(accessGroup);
      }
    },
    [accessGroups, append],
  );

  const data = selectableAccessGroups()
    .filter(
      accessGroup =>
        !searchQuery ||
        accessGroup.name.toLowerCase().includes(searchQuery.toLowerCase()),
    )
    .map(accessGroup => ({
      value: `${accessGroup.id}`,
      text: accessGroup.name,
    }));

  return (
    <>
      <Select
        addable={false}
        autoClearSearchValue={false}
        autoClose
        data={data}
        defaultValue=""
        direction="down"
        disabled={false}
        hideIcon={false}
        hideSelectedItems
        minSearchValueLength={0}
        name="migration_access_group_id"
        notFoundContent={t([i18nBase, "chooser", "no_results"])}
        onChange={onChange}
        onSearch={onSearch}
        placeholder={t([i18nBase, "chooser", "placeholder"])}
        popperOptions={{}}
        useEditIcon={false}
        value=""
        variant="single"
      />
      <div className="overflow-y-auto">
        {selectedAccessGroups.map((accessGroup, index, array) => (
          <AccessGroupCard
            className={classNames({
              "rounded-none border-t-0": index > 0 && index < array.length - 1,
              "rounded-b-none": index === 0 && array.length > 1,
              "rounded-t-none border-t-0":
                index === array.length - 1 && array.length > 1,
            })}
            key={accessGroup.id}
            title={accessGroup.name}
            actions={
              <AccessGroupFormActions
                accessGroupIndex={index}
                removeAccessGroup={remove}
              />
            }
            membersCount={parseInt(accessGroup.total_members ?? "0", 10)}
            spacesCount={parseInt(accessGroup.total_spaces ?? "0", 10)}
          />
        ))}
      </div>
    </>
  );
};
