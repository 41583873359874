import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { StatusBadge } from "@circle-react-uikit/StatusBadge";
import { PaymentProcessorStatusAlert } from "./PaymentProcessorStatusAlert";

export const PaymentProcessorRestrictedFeesToApply = () => {
  const { currentCommunitySettings } = usePunditUserContext();

  const badgeComponent = (
    <StatusBadge variant="warning">
      {t(`settings.paywalls.processor_status_v2.restricted.status`)}
    </StatusBadge>
  );

  const description = (
    <p
      className="text-default text-lg"
      dangerouslySetInnerHTML={{
        __html: t(
          "settings.paywalls.processor_status_v2.restricted_for_application_fee.description",
        ),
      }}
    />
  );

  if (currentCommunitySettings?.paywalls_for_restricted_country_enabled) {
    return null;
  }

  return (
    <PaymentProcessorStatusAlert
      sentiment="warning"
      title={t(
        "settings.paywalls.processor_status_v2.restricted_for_application_fee.title",
      )}
      badge={badgeComponent}
      description={description}
    />
  );
};
