import { t } from "@/i18n-js/instance";
import { Avatar } from "@circle-react/components/SettingsApp/Workflows/components/Avatar";
import { Typography } from "@circle-react-uikit/Typography";
import { AccessGroupName } from "./AccessGroupName";

export interface HistoryDetailViewAccessGroupProps {
  body: {
    community_member_id: number;
  };
  url: string;
}

export const HistoryDetailViewAccessGroup = ({
  body,
  url,
}: HistoryDetailViewAccessGroupProps) => {
  const { community_member_id: communityMemberId } = body;

  // get id from the url /api/actions/v1/access_groups/:id/community_members.
  const accessGroupId = Number(new URL(url).pathname.split("/")[5]);

  return (
    <div className="flex flex-col gap-2">
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.access_group")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <AccessGroupName id={accessGroupId} />
        </div>
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.member")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <Avatar communityMemberId={communityMemberId} />
        </div>
      </div>
    </div>
  );
};
