import { useRef, useState } from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import I18n, { t } from "@/i18n-js/instance";
import { homeSettingsApi } from "@/react/api";
import { SEOForm } from "@/react/components/Spaces/CustomizeSpaceFullPage/SEOForm";
import { Section } from "@/react/components/Spaces/CustomizeSpaceFullPage/Section";
import { SpaceOptionsForm } from "@/react/components/Spaces/CustomizeSpaceFullPage/SpaceOptionsForm";
import { EditSpaceForm } from "@/react/components/Spaces/EditV2/EditForm/EditSpaceForm";
import {
  DIRTY_FORM_INITIAL_STATES,
  useCustomizeSpaceContext,
} from "@circle-react/contexts/customizeSpaceContext";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { SectionCard } from "@circle-react-uikit/SectionCard";
import { useToast } from "@circle-react-uikit/ToastV2";

export const OptionsForm = ({ space }) => {
  const [imageToDelete, setImageToDelete] = useState(null);
  const { dirtyForms, setDirtyForms, onSlugChange } =
    useCustomizeSpaceContext();
  const { onSave: onSubmit, onExit } = useCustomizeUIData();
  const triggerFormSubmitRef = useRef();

  const toastr = useToast();

  const [isOpenNotificationsConfirmModal, toggleNotificationsConfirmModal] =
    useBoolean(false);

  const [notificationsChanged, setNotificationsChanged] = useState(false);

  const handleSubmit = async (data, reset) => {
    if (notificationsChanged) {
      toggleNotificationsConfirmModal();
      return false;
    }

    try {
      if (imageToDelete) {
        await homeSettingsApi.deleteOpenGraphImage(imageToDelete);
        setImageToDelete(false);
      }

      await onSubmit({
        data,
        onSuccess: updatedSpace => {
          if (updatedSpace.slug !== space.slug) {
            onSlugChange(updatedSpace);
          }
        },
      });
    } catch (err) {
      toastr.error(I18n.t("spaces.form.edit.error"));
    } finally {
      isFunction(reset) && reset(data, { keepValues: true });
      setDirtyForms(DIRTY_FORM_INITIAL_STATES);
      onExit();
    }
  };

  const onChangeFormField = key => {
    setDirtyForms({
      ...dirtyForms,
      options: {
        ...dirtyForms.options,
        [key]: true,
      },
    });
  };

  return (
    <Section>
      <EditSpaceForm
        onSubmit={(data, { reset }) => handleSubmit(data, reset)}
        space={space}
        className="mx-auto flex h-full flex-col"
        formClassName="flex flex-col pb-8 gap-4"
        triggerFormSubmitRef={triggerFormSubmitRef}
      >
        <SectionCard
          title={t("customize_space.options")}
          dataTestId="customize-space-options"
          variant="fullScreen"
        >
          <SpaceOptionsForm
            space={space}
            onChange={() => onChangeFormField("options")}
            ref={triggerFormSubmitRef}
            isOpenNotificationsConfirmModal={isOpenNotificationsConfirmModal}
            toggleNotificationsConfirmModal={toggleNotificationsConfirmModal}
            setNotificationsChanged={setNotificationsChanged}
          />
        </SectionCard>
        <SectionCard
          title={t("customize_space.seo")}
          dataTestId="customize-space-seo"
          variant="fullScreen"
        >
          <SEOForm
            space={space}
            onChange={() => onChangeFormField("seo")}
            setImageToDelete={setImageToDelete}
          />
        </SectionCard>
      </EditSpaceForm>
    </Section>
  );
};

OptionsForm.propTypes = {
  space: PropTypes.object,
};
