import * as yup from "yup";
import { t } from "@/i18n-js/instance";
import {
  EMAIL_PREFIX_REGEX,
  FIELD_USE_CUSTOM_REPLY_TO_EMAIL,
} from "./constants";

const i18nRoot = "settings.emails.settings.errors";

export const formValidationSchema = yup.object().shape({
  sender_name: yup.string().required(t([i18nRoot, "sender_name_is_required"])),
  sender_email_local_part: yup
    .string()
    .matches(EMAIL_PREFIX_REGEX, {
      message: t([i18nRoot, "please_enter_valid_email_address"]),
    })
    .required(t([i18nRoot, "sender_email_is_required"])),
  reply_to_email: yup.string().when(FIELD_USE_CUSTOM_REPLY_TO_EMAIL, {
    is: true,
    then: yup
      .string()
      .email(t([i18nRoot, "please_enter_valid_email_address"]))
      .required(t([i18nRoot, "reply_to_email_is_required"])),
  }),
  address_country: yup.string().required(t([i18nRoot, "country_is_required"])),
  address_line_1: yup.string().required(t([i18nRoot, "address_is_required"])),
  address_city: yup.string().required(t([i18nRoot, "city_is_required"])),
  address_postal_code: yup
    .string()
    .required(t([i18nRoot, "zip_code_is_required"])),
});
