import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { paywallApi } from "@/react/api/paywallApi";
import type { UpdateMemberAccessResponse } from "@/react/api/paywallApi";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useUpdateMemberAccess = (paywallId: string) => {
  const { success, error } = useToast();
  const modal = useModal();
  const updateMemberAccessMutation = useMutation<
    UpdateMemberAccessResponse,
    unknown,
    string
  >(paywallId => paywallApi.updateMemberAccess(paywallId));

  const handleSubmit = () => {
    updateMemberAccessMutation.mutate(paywallId, {
      onSuccess: () => {
        success(t("settings.paywalls.alerts.success.update_access_member"));
        void modal.hide().then(() => modal.remove());
      },
      onError: () => {
        error("settings.paywalls.alerts.error.action_failed");
        void modal.hide().then(() => modal.remove());
      },
    });
  };

  const isSubmitting = updateMemberAccessMutation.isLoading;
  const onClose = () => {
    !updateMemberAccessMutation.isLoading && void modal.hide();
  };

  return {
    isOpen: modal.visible,
    isSubmitting,
    onClose,
    handleSubmit,
  };
};
