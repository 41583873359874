import { useLocation } from "react-router-dom";
import {
  isNewPlansPageCookieSet,
  isVariantBCookieSet,
} from "@/react/utils/isCookieSet";
import { usePunditUserContext } from "@circle-react/contexts";
import { settingsNavPathV3 } from "../helpers/urlHelpers";

const PLAN_PAGE_VARIANT_A_EXPERIMENT_LAUNCH_DATE = "2025-03-04T17:00:00Z";
const PLAN_PAGE_VARIANT_A_EXPERIMENT_CLOSED_DATE = "2025-03-29T18:00:00Z";

const PLAN_PAGE_VARIANT_B_EXPERIMENT_LAUNCH_DATE = "2025-03-29T18:00:00Z";

const EXPERIMENT = {
  VARIANT_A: {
    START_DATE: new Date(PLAN_PAGE_VARIANT_A_EXPERIMENT_LAUNCH_DATE),
    END_DATE: new Date(PLAN_PAGE_VARIANT_A_EXPERIMENT_CLOSED_DATE),
    FLAG_NAME: "plans_page_variant_a",
    COOKIE_NAME: "plans_page_variant_a",
    VALUES: {
      CONTROL: "control",
      VARIANT_A: "variantA",
    },
  },
  VARIANT_B: {
    START_DATE: new Date(PLAN_PAGE_VARIANT_B_EXPERIMENT_LAUNCH_DATE),
    FLAG_NAME: "plans_page_variant_b",
    COOKIE_NAME: "plans_page_variant_b",
    VALUES: {
      VARIANT_A: "variantA",
      VARIANT_B: "variantB",
    },
  },
};

export const usePlanPageVariant = () => {
  const { pathname } = useLocation();

  const isPlansPage = [
    settingsNavPathV3.plans.general,
    settingsNavPathV3.plans.billing,
    settingsNavPathV3.plans.brandedApps,
  ].some(path => pathname.includes(path));

  const { currentCommunity, currentCommunitySettings, experimentation } =
    usePunditUserContext();
  const isNewPlansPageBetaEnabled =
    currentCommunitySettings?.new_plans_page_enabled;

  const isPlanPageVariantLoading = experimentation?.isLoading;

  // [PlanPageVariantAExperiment][AfterComplete] Remove experiment code
  const communityCreatedAt =
    currentCommunity && new Date(currentCommunity.created_at);
  const isCommunityEligibleForPlanPageVariantA =
    communityCreatedAt &&
    communityCreatedAt > EXPERIMENT.VARIANT_A.START_DATE &&
    communityCreatedAt < EXPERIMENT.VARIANT_A.END_DATE;

  const experimentSegment =
    experimentation?.flags?.[EXPERIMENT.VARIANT_A.FLAG_NAME];
  const isExperimentVariantAEnabled =
    isCommunityEligibleForPlanPageVariantA &&
    experimentSegment === EXPERIMENT.VARIANT_A.VALUES.VARIANT_A;

  const isCommunityEligibleForPlanPageVariantB =
    communityCreatedAt && communityCreatedAt > EXPERIMENT.VARIANT_B.START_DATE;

  const experimentSegmentVariantBExperiment =
    experimentation?.flags?.[EXPERIMENT.VARIANT_B.FLAG_NAME];
  const isExperimentVariantB = {
    isVariantAEnabled:
      isCommunityEligibleForPlanPageVariantB &&
      experimentSegmentVariantBExperiment ===
        EXPERIMENT.VARIANT_B.VALUES.VARIANT_A,
    isVariantBEnabled:
      isCommunityEligibleForPlanPageVariantB &&
      experimentSegmentVariantBExperiment ===
        EXPERIMENT.VARIANT_B.VALUES.VARIANT_B,
  };

  const isVariantBEnabled =
    isVariantBCookieSet() || isExperimentVariantB.isVariantBEnabled;

  const isVariantAEnabled =
    isNewPlansPageCookieSet() ||
    isNewPlansPageBetaEnabled ||
    isExperimentVariantAEnabled ||
    isExperimentVariantB.isVariantAEnabled;

  const isPlansExperimentLayout =
    isPlansPage && (isVariantAEnabled || isVariantBEnabled);

  return {
    isVariantAEnabled, // variant A
    isVariantBEnabled, // variant B
    isPlansExperimentLayout, // Defines if the plan experiment layout is enabled (without sidebar)
    isPlanPageVariantLoading,
  };
};
