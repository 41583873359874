import type { MouseEvent, ReactNode } from "react";
import isFunction from "lodash/isFunction";
import { Button } from "@circle-react-uikit/Button";
import { Icon } from "../shared/Icon";

export type RenderProp = (props: {
  onClick: (event: any) => void;
}) => ReactNode;

export interface ModalButtonProps {
  children?: ReactNode;
  className?: string;
  hasIcon?: boolean;
  id?: string;
  isButton?: boolean;
  linkName?: string;
  render?: RenderProp;
  showModal: (event: MouseEvent<HTMLElement>) => void;
}

export const ModalButton = ({
  children,
  className,
  hasIcon = false,
  id,
  isButton = false,
  linkName,
  render,
  showModal,
}: ModalButtonProps) => {
  if (isFunction(render)) {
    return <>{render({ onClick: showModal })}</>;
  }

  if (isButton) {
    return (
      <Button
        type="button"
        className={className}
        id={id}
        onClick={showModal}
        aria-haspopup
        large
        isFullRounded
      >
        {hasIcon && <Icon type="plus" />}
        {linkName || children}
      </Button>
    );
  }

  return (
    <a
      className={className}
      id={id}
      onClick={showModal}
      href="#new-post"
      aria-haspopup
    >
      {linkName || children}
    </a>
  );
};
