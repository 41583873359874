import { t } from "@/i18n-js/instance";
import { useKeyboardShortcutsModal } from "@circle-react-uikit/HeaderV3/KeyboardShortcutsModal";
import type { DropdownButton } from "./types";
import { adminIconsV3 } from "./useAdminIcons";

export const useGetShortcutsModal = (): DropdownButton | null => {
  const { keyboardShortcutsIcon } = adminIconsV3;
  const shortcutsModal = useKeyboardShortcutsModal();
  const openShortcutsModal = () => {
    void shortcutsModal.show();
  };

  return {
    value: t("header_v3.keyboard_shortcuts"),
    label: t("header_v3.keyboard_shortcuts"),
    onClick: openShortcutsModal,
    icon: keyboardShortcutsIcon,
    iconSize: 20,
  };
};
