import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";
import { Avatar } from "../../../Avatar";

export interface HistoryDetailViewContactProps {
  body: {
    contact_id: number;
  };
}

export const HistoryDetailViewContact = ({
  body,
}: HistoryDetailViewContactProps) => {
  const { contact_id: contactId } = body;

  return (
    <div className="flex flex-col gap-2">
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.member")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <Avatar communityMemberId={contactId} />
        </div>
      </div>
    </div>
  );
};
