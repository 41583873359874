import { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "react-query";
import { paywallCheckoutApi } from "@circle-react/api/paywallCheckoutApi";
import type {
  PixPrepareResponseEndpoint,
  PrepareRequestEndpoint,
} from "@circle-react/api/paywallCheckoutApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import {
  isRequiresAction,
  isSucceeded,
} from "@circle-react/helpers/paywalls/paymentIntentHelpers";
import {
  GENERAL_ERROR,
  PIX_MODAL_CLOSED,
  PIX_PAYMENT_EXPIRED,
} from "../../helpers";
import { processWithRetries, redirectToCheckoutConfirmation } from "./helpers";

const throwErrorFromApi = (error: ApiError) => {
  throw new Error(error?.message, {
    cause: error.body,
  });
};

export const usePix = () => {
  const stripe: any = useStripe();
  const { checkoutConfirmationUrl } = usePaywallCheckoutContext();

  const confirmPixPaymentMutation = useMutation<any, any, any>(
    (params: any) => {
      if (!stripe) {
        throw new Error("Stripe has not been initialized.");
      }
      return stripe.confirmPixPayment(params.payment_intent_client_secret, {
        payment_method: {},
      });
    },
  );

  const [prepareCache, setPrepareCache] = useState<
    PrepareRequestEndpoint | Record<string, never>
  >({});
  const prepareMutation = useMutation<
    PixPrepareResponseEndpoint,
    ApiError,
    PrepareRequestEndpoint
  >(params => paywallCheckoutApi.prepare(params));

  const checkoutMutation = useMutation<any, any, any>(params =>
    paywallCheckoutApi.create({ formData: params }),
  );

  const fetchCheckoutAttemptMutation = useMutation((fingerprint: string) =>
    paywallCheckoutApi.attempts.show(fingerprint),
  );

  const prepare = async (formData: any) => {
    // If the user closes the modal, we need to use the previous payment intent but it is needed
    // to check if the payment intent still valid to be paid, otherwise we create another payment intent
    const isCacheEqualToParams =
      JSON.stringify(prepareCache) === JSON.stringify(formData);
    const previousPaymentIntent = isCacheEqualToParams
      ? stripe
        ? await stripe.retrievePaymentIntent(
            prepareMutation?.data?.payment_intent_client_secret || "",
          )
        : null
      : null;
    const shouldUsePreviousPayment =
      isCacheEqualToParams &&
      isRequiresAction(previousPaymentIntent?.paymentIntent);

    // We need to check if the `prepareMutation` was called before,
    // this approach re-uses the old `PaymentIntent` created previously if available
    const prepareResponse =
      (shouldUsePreviousPayment && prepareMutation?.data) ||
      (await prepareMutation.mutateAsync(formData, {
        onSuccess: () => {
          setPrepareCache(formData);
        },
        onError: throwErrorFromApi,
      }));

    if (prepareResponse) {
      const { paymentIntent } =
        await confirmPixPaymentMutation.mutateAsync(prepareResponse);

      if (isRequiresAction(paymentIntent)) {
        throw new Error("Pix modal is closed", {
          cause: PIX_MODAL_CLOSED,
        });
      }

      if (!isSucceeded(paymentIntent)) {
        // Once the PaymentIntent fails, we should reset the mutation
        // to allow the member to re-do the checkout
        prepareMutation.reset();
        setPrepareCache({});
        throw new Error("Pix payment expired", {
          cause: PIX_PAYMENT_EXPIRED,
        });
      }
    }

    return prepareResponse;
  };

  const verifyCheckoutWasProcessed = async (
    prepareResult: any,
  ): Promise<any> => {
    try {
      const checkoutAttempt = await fetchCheckoutAttemptMutation.mutateAsync(
        prepareResult.payment_intent_id,
      );
      if (checkoutAttempt.status === "completed") {
        return checkoutAttempt;
      }

      return null;
    } catch (e: any) {
      if (e.status === 404) {
        return null;
      }
      throw e;
    }
  };

  const onSubmitMutation = useMutation<any, any, any>(async formData => {
    const prepareResult = await prepare(formData);

    if (prepareResult) {
      const checkoutAttemptResult = await processWithRetries({
        executor: () => verifyCheckoutWasProcessed(prepareResult),
      });

      if (
        checkoutAttemptResult &&
        checkoutAttemptResult.status === "completed"
      ) {
        await checkoutMutation.mutateAsync({
          ...formData,
          payment_intent_id: prepareResult.payment_intent_id,
        });

        return redirectToCheckoutConfirmation(
          { payment_intent_processor_id: prepareResult.payment_intent_id },
          checkoutConfirmationUrl,
        );
      }
    }
    throw new Error("Pix payment failed", {
      cause: GENERAL_ERROR,
    });
  });

  return {
    onSubmitMutation,
    mutations: {
      prepareMutation,
      checkoutMutation,
      confirmPixPaymentMutation,
    },
  };
};
