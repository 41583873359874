import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Typography } from "@/react/components/shared/uikit/Typography";
import type { AccessGroupType } from "@/react/types/AccessGroup";
import { RadioButtonGroup } from "@circle-react-shared/RadioButtonGroup";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { AccessGroupFields } from "./AccessGroupFields";
import { AccessGroupsChooser } from "./AccessGroupsChooser";
import { FORM_FIELD_KEYS, MIGRATION_FLOW_OPTIONS } from "./constants";

const i18nBase = "settings.access_groups.paywall_migration.modal.form";

interface FormItemsProps {
  accessGroups?: AccessGroupType[];
}

export const FormItems = ({ accessGroups }: FormItemsProps) => {
  const { setValue, watch } = useFormContext();

  const migrationFlow = watch(FORM_FIELD_KEYS.migration_flow);

  const changeTabHandler = (value: string) => {
    if (migrationFlow !== value) {
      setValue(FORM_FIELD_KEYS.migration_flow, value);
    }
  };

  const isCreatingAccessGroup =
    migrationFlow === MIGRATION_FLOW_OPTIONS.new_access_group;

  const description = isEmpty(accessGroups)
    ? t([i18nBase, "new_access_group_description"])
    : t([i18nBase, "select_access_group_description"]);

  return (
    <div className="flex flex-col gap-y-5 py-6">
      <Typography.BodyMd>
        <SanitizeHTMLString content={description} />
      </Typography.BodyMd>

      {!isEmpty(accessGroups) && (
        <RadioButtonGroup
          shouldShowInlineOptions
          name="migration_access_group_flow"
          onChange={changeTabHandler}
          defaultChecked={migrationFlow}
          size="sm"
          options={[
            {
              label: t([i18nBase, "tabs.new_access_groups"]),
              value: MIGRATION_FLOW_OPTIONS.new_access_group,
            },
            {
              label: (
                <div className="flex items-center justify-center gap-1">
                  {t([i18nBase, "tabs.select_access_groups"])}
                </div>
              ),
              value: MIGRATION_FLOW_OPTIONS.select_access_groups,
            },
          ]}
          checkedOptionClassName="pl-9"
        />
      )}

      {isCreatingAccessGroup && <AccessGroupFields />}

      {!isCreatingAccessGroup && accessGroups && (
        <AccessGroupsChooser accessGroups={accessGroups} />
      )}
    </div>
  );
};
