import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useDirectUpload } from "@/react/hooks/useDirectUpload";
import { Icon } from "@circle-react-shared/Icon";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { AvatarImage } from "./AvatarImage";

export const AvatarUploader = ({ avatarUrl }: { avatarUrl: string }) => {
  const { error } = useToast();

  const { uploadFile, status, response } = useDirectUpload();
  const { register, setValue } = useFormContext();
  register("avatar");

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: { "image/*": [".jpeg", ".gif", ".png", ".jpg"] },
    onDropAccepted: acceptedFiles => {
      uploadFile(acceptedFiles[0]);
    },
    onDropRejected: () => {
      error(t("community_bot.bot_builder.customize_page.avatar_invalid"));
    },
    disabled: false,
  });

  useEffect(() => {
    if (response?.signed_id) {
      setValue("avatar", response.signed_id);
    }
  }, [response, setValue]);

  return (
    <div
      {...getRootProps()}
      className="focus-within:border-secondary border-primary flex h-28 w-full cursor-pointer items-center justify-center rounded-md border"
    >
      <input {...getInputProps()} type="file" className="hidden" />
      {status === "uploading" ? (
        <Icon type="loader" />
      ) : (
        <AvatarImage avatarUrl={avatarUrl} response={response} />
      )}
    </div>
  );
};
