import { t } from "@/i18n-js/instance";
import { customizeThemeSettingsPath } from "@circle-react/helpers/urlHelpers";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { FooterPreview } from "./FooterPreview";
import { SettingsSection } from "./SettingsSection";
import { I18N_ROOT } from "./constants";

interface BroadcastFooterProps {
  shouldHideBottomMargin?: boolean;
}

export const BroadcastFooter = ({
  shouldHideBottomMargin: shouldHideMarginBottom = true,
}: BroadcastFooterProps) => (
  <SettingsSection
    title={t([I18N_ROOT, "footer"])}
    description={
      <SanitizeHTMLString
        sanitizeProps={{ ADD_ATTR: ["target"] }}
        content={t([I18N_ROOT, "footer_description"], {
          url: customizeThemeSettingsPath(),
        })}
      />
    }
    childrenClassName="mt-7"
    shouldHideMarginBottom={shouldHideMarginBottom}
  >
    <FooterPreview />
  </SettingsSection>
);
