import { useMutation } from "react-query";
import { accessGroupMembersApi } from "@circle-react/api/admin/accessGroups/accessGroupMembersApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { manageTaskProcessTypes } from "@circle-react/helpers/accessGroups/manageTaskHelper";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { useAddAccessGroupMemberData } from "../AccessGroupMembers/AddAccessGroupMembersModal/DataStoreContext/AddAccessGroupMembersDataContext";
import { useAccessGroupData } from "../AccessGroupMembers/DataStoreContext/AccessGroupDataContext";
import { useAccessGroupRedirections } from "../hooks/useAccessGroupRedirections";

interface ResponseData {
  community_member_ids: number;
}

export const useAddMembersToAccessGroup = () => {
  const { accessGroup, setShouldBlockActions } = useAccessGroupData();

  const toastr = useToast();

  const { manageAccessGroupMembers } = accessGroupMembersApi();
  const { redirectToEditModal } = useAccessGroupRedirections();

  const { clearSelectedMemberIds, filtersToProcessMembers, selectedMemberIds } =
    useAddAccessGroupMemberData();

  return useMutation<ResponseData, ApiError>({
    mutationFn: () =>
      manageAccessGroupMembers({
        id: accessGroup.id,
        params: {
          community_member_ids: selectedMemberIds,
          process_type: manageTaskProcessTypes.JOIN,
          filters:
            selectedMemberIds.length > 0 ? undefined : filtersToProcessMembers,
        },
      }),
    onSuccess: () => {
      clearSelectedMemberIds();
      setShouldBlockActions(true);
      redirectToEditModal();
    },
    onError: (error: any) => toastr.error(error.message),
  });
};
