import { t } from "@/i18n-js/instance";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import {
  findFrequencyByIntervalAndCount,
  resolveUpfrontPaymentDetails,
  upfrontPaymentVariants,
} from "@circle-react/helpers/paywallPriceHelpers";
import { formatPaywallCurrencyAmount } from "@circle-react/helpers/paywalls/currencies";
import { paywallPriceTypes } from "@circle-react/types";
import { usePricingTabContext } from "../../../hooks/usePricingTabContext";
import type { PricingTabPriceListCellProps } from "../../types";

export const PricingTabPriceListCellPricingSubscription = ({
  row: { original: price },
}: PricingTabPriceListCellProps) => {
  const {
    price_type,
    interval_count,
    interval,
    amount,
    upfront_payment_amount,
    trial_days,
    trial_requires_upfront_payment: isUpfrontPaymentRequiredForTrial,
  } = price.price_properties;

  const { currency, priceBuildOptions } = usePricingTabContext();

  if (price_type !== paywallPriceTypes.subscription) {
    return null;
  }

  const trialPeriodDays = trial_days;
  const hasTrialPeriod = !!trial_days;

  const buildOptions = priceBuildOptions.subscription;

  const frequency = findFrequencyByIntervalAndCount(
    buildOptions,
    interval,
    interval_count,
  );

  const upfrontPaymentDetails = resolveUpfrontPaymentDetails(
    currency,
    upfront_payment_amount,
  );

  const amountInCents = mapCurrencyAmountStrToInt(amount);
  const amountFormatted = formatPaywallCurrencyAmount(currency, amountInCents, {
    amountInCents: true,
    includeCurrencyCode: true,
  });

  // TODO: move pricing description and notice building logic to the backend.
  const scenarioParts: string[] = [];
  if (hasTrialPeriod && !isUpfrontPaymentRequiredForTrial) {
    scenarioParts.push("trial");
  }
  if (upfrontPaymentDetails.variant === upfrontPaymentVariants.upfrontPayment) {
    scenarioParts.push("upfront");
  }
  if (hasTrialPeriod && isUpfrontPaymentRequiredForTrial) {
    scenarioParts.push("trial");
  }
  scenarioParts.push("value");
  const scenario = scenarioParts.join("_then_");

  return (
    <>
      {t(
        ["settings.paywall_prices", price_type, "short_description", scenario],
        {
          upfront: upfrontPaymentDetails.amountFormatted,
          interval: frequency?.as_adverb?.toLowerCase(),
          amount: amountFormatted,
          trial: trialPeriodDays,
        },
      )}
    </>
  );
};
