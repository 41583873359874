import { Icon } from "@circle-react-shared/Icon";

export const AudienceTypeIcon = () => (
  <div className="border-primary h-8 w-8 rounded-full border pl-2 pt-1">
    <Icon
      type="16-audience"
      size={16}
      aria-hidden
      stroke="secondary"
      className="-ml-1"
    />
  </div>
);
