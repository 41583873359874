import { t } from "@/i18n-js/instance";
import { STATUS } from "@circle-react/components/Events/helpers/constants";
import { dateIsPast } from "@circle-react/helpers/dateTimeHelpers";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { Chip } from "@circle-react-uikit/Chip";

interface GetLabelProps {
  isStarted: boolean;
  isFinished: boolean;
  isDraft: boolean;
  startsAt: string;
  endsAt: string;
}

const getLabel = ({
  isStarted,
  isFinished,
  isDraft,
  startsAt,
  endsAt,
}: GetLabelProps) => {
  if (isStarted && !isFinished && !isDraft) {
    return t("events.details.live_now");
  }

  if (isDraft) {
    return t("events.details.draft");
  }

  if (isFinished) {
    const timeString = dateStringToTimeAgo(endsAt);
    return t("events.details.finished", {
      time_string: timeString,
    });
  }

  const timeString = dateStringToTimeAgo(startsAt);
  return t("events.details.starts", {
    time_string: timeString,
  });
};

export interface EventTimeChipProps {
  startsAt: string;
  endsAt: string;
  status: "published" | "draft" | null;
}

export const EventTimeChip = ({
  startsAt,
  endsAt,
  status = null,
}: EventTimeChipProps) => {
  const isDraft = status === STATUS.DRAFT;
  const isFinished = dateIsPast(endsAt);
  const isStarted = dateIsPast(startsAt);
  const label = getLabel({ isStarted, isFinished, isDraft, startsAt, endsAt });

  return (
    <Chip
      title={label}
      variant={isDraft || isFinished ? "primary" : "secondary"}
      dataTestId="event-time-chip"
    />
  );
};
