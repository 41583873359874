import { t } from "@/i18n-js/instance";
import { contentSpecTypes } from "@circle-react/types/AccessRegistry";
import { ContentSpecAccessGroupsControl } from "@circle-react-shared/AccessRegistry/ContentSpecAccessGroupsControl";
import { Typography } from "@circle-react-uikit/Typography";

interface DetailsProps {
  space: {
    id: number;
    name: string;
    post_type: string;
  };
}
const i18nRoot = "spaces.delete.details";

export const Details = ({ space }: DetailsProps) => {
  const items: any = {
    default: ["space_members", "space_settings"],
    basic: ["posts", "engagement"],
    event: ["events", "engagement"],
    course: ["course_info", "course_progress", "videos_and_files", "comments"],
    image: ["posts", "engagement"],
    chat: ["messages"],
  };

  const itemsToDelete = [...(items[space.post_type] || []), ...items.default];

  return (
    <div className="flex flex-col gap-y-4">
      <Typography.BodyMd>{t("spaces.delete.description")}</Typography.BodyMd>
      <div className="border-primary space-y-2 rounded-lg border p-4">
        <div className="flex items-center">
          <Typography.LabelMd weight="semibold">
            {space.name}
          </Typography.LabelMd>
        </div>
        {itemsToDelete && (
          <ul className="list-inside list-none">
            {itemsToDelete.map(item => (
              <li key={item}>
                <Typography.LabelSm>{t([i18nRoot, item])}</Typography.LabelSm>
              </li>
            ))}
          </ul>
        )}
      </div>
      <ContentSpecAccessGroupsControl
        contentSpecType={contentSpecTypes.Space}
        contentSpecId={space.id}
      />
    </div>
  );
};
