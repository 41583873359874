import { t } from "@/i18n-js/instance";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { CommunityAndPaywallPriceInfo } from "../CommunityAndPaywallPriceInfo";
import { getValidQueryParameters } from "../helpers";

export const SSOLoginPrompt = ({ children }: any) => {
  const { currentCommunity, loginUrl, authProviderProperties } =
    usePaywallCheckoutContext();

  const queryParameters = getValidQueryParameters();
  const loginUrlWithQueryParameters =
    queryParameters.toString() === ""
      ? loginUrl
      : `${loginUrl}?${queryParameters.toString()}`;

  if (!authProviderProperties?.is_sso_authentication_required) {
    return (
      <div className="paywall-checkout-wrapper">
        <div className="paywall-checkout-container">{children}</div>
      </div>
    );
  }

  return (
    <div className="w-full bg-gradient-to-r md:grid md:grid-cols-2 md:from-slate-100">
      <div className="border-primary absolute top-0 flex w-full justify-center border-b px-6 py-4 md:hidden">
        {currentCommunity.logo_url ? (
          <img
            loading="lazy"
            className="max-h-[1.5rem]"
            src={currentCommunity.logo_url}
            alt=""
          />
        ) : (
          <span className="text-lg font-medium">{currentCommunity.name}</span>
        )}
      </div>
      <CommunityAndPaywallPriceInfo className="pointer-events-none !hidden opacity-60 md:!flex" />
      <div className="flex h-[100vh] w-full content-center items-center justify-center px-5 text-center md:px-0">
        <div className="flex max-w-[24rem] flex-col gap-y-8">
          {currentCommunity.icon_url && (
            <div className="flex justify-center">
              <img
                loading="lazy"
                className="w-[4rem]"
                src={currentCommunity.icon_url}
                alt=""
              />
            </div>
          )}
          <div className="flex flex-col gap-y-1">
            <Typography.TitleMd>
              {t("paywall_checkout.sso_login_prompt.title")}
            </Typography.TitleMd>
            <Typography.BodyMd color="text-default">
              {t("paywall_checkout.sso_login_prompt.subtitle", {
                community_name: currentCommunity.name,
              })}
            </Typography.BodyMd>
          </div>
          <div className="flex flex-row justify-center">
            <a href={loginUrlWithQueryParameters}>
              <Button variant="circle" large>
                {t("paywall_checkout.sso_login_prompt.call_to_action", {
                  auth_provider_name: authProviderProperties.name,
                })}
              </Button>
            </a>
          </div>
          <Typography.LabelXs color="text-default">
            {t("paywall_checkout.sso_login_prompt.note")}
          </Typography.LabelXs>
        </div>
      </div>
    </div>
  );
};
