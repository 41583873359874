import { t } from "@/i18n-js/instance";
import { paywallTypes } from "@circle-react/types";
import { PaywallsFilter } from "./PaywallsFilter";

const localeRoot = "settings.paywalls_admin_dashboard.filters.paywalls";
const queryParamsKey = "paywalls";

export const SpacePaywallsFilter = props => (
  <PaywallsFilter
    {...props}
    localeRoot={localeRoot}
    queryParamsKey={queryParamsKey}
    filterName="paywalls-filter"
    filterParams={{ type: [paywallTypes.space] }}
  />
);

export const filterProperties = {
  name: "paywalls",
  filterKeys: ["paywalls"],
  component: SpacePaywallsFilter,
  label: t([localeRoot, "chip"]),
};
