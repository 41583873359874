import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { eventApi } from "@circle-react/api";
import { useInvalidateEventQueries } from "@circle-react/components/Events/hooks/useInvalidateEventQueries";
import { posts } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { STATUS } from "../helpers/constants";
import { isDraft } from "./helpers";

export const useUpdateEventMutation = (event, onSuccess) => {
  const { success, error } = useToast();
  const history = useHistory();
  const invalidateQueries = useInvalidateEventQueries();
  const updateMutation = useMutation(
    formData =>
      eventApi.update({
        event,
        formData: {
          ...formData,
          event_type: event.event_type,
        },
        forReactQuery: true,
      }),
    {
      onSuccess: event => {
        success(t("events.saved_successfully"));
        invalidateQueries();
        onSuccess && onSuccess();
        if (isDraft(event)) {
          history.push(
            posts.index({
              spaceSlug: event.space_slug,
              params: {
                status: STATUS.DRAFT,
                sort: "asc",
              },
            }),
          );
        }
      },
      onError: () => error(t("events.save_error")),
    },
  );

  return {
    updateMutation,
  };
};
