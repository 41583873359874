import { t } from "@/i18n-js/instance";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { SpaceResource } from "./shared/SpaceResource";
import { SpaceSelector } from "./shared/SpaceSelector";

export const memberLeft: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_leaves_space.title",
  ),
  value: "community_member_leaves_space",
  group: t("settings.workflows.form.event_groups.space"),
  description: t(
    "settings.workflows.form.events.community_member_leaves_space.description",
  ),
  resource: TRIGGER_RESOURCE_TYPE.SPACE,
  formComponent: SpaceSelector,
  viewComponent: SpaceResource,
  icon: "space",
};
