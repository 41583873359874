import { useMemo } from "react";
import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const SORT_OPTIONS_MAP = {
  ALPHABETICAL: "alphabetical",
  OLDEST: "oldest",
  LATEST: "latest",
  ROLE: "role",
};

export const getPageSize = () => {
  // 4K screens (typically 2160px height or more)
  if (window.innerHeight >= 2160) return 120;
  // 2K/QHD screens (typically around 1440px height)
  if (window.innerHeight >= 1440) return 70;
  // Full HD screens (typically around 1080px height)
  if (window.innerHeight >= 1080) return 50;
  // Medium-sized screens
  if (window.innerHeight >= 925) return 30;
  // Smaller screens (laptops, tablets)
  return 15;
};

interface UseCommunityMembersInfiniteQueryArgs {
  query?: string;
  sort?: string;
  order?: Record<string, "asc" | "desc">;
  filters?: Array<{
    key: string;
    value: "Member" | "Moderator" | "Admin" | undefined;
  }>;
  perPage?: number;
  initialPage?: number;
  options?: Record<string, any>;
  isAdminScope?: boolean;
  scope?: {
    status: string;
    visible_in_member_directory?: boolean;
    exclude_empty_name?: boolean;
    exclude_empty_profiles?: boolean;
  };
}

export const useCommunityMembersInfiniteQuery = (
  args: UseCommunityMembersInfiniteQueryArgs = {},
) => {
  const {
    query = "",
    sort = SORT_OPTIONS_MAP.ALPHABETICAL,
    order,
    filters = {},
    perPage = getPageSize(),
    initialPage = 1,
    options = {},
    scope = {},
    isAdminScope = false,
  } = args;

  const fetchPage = ({ pageParam = initialPage }) => {
    let page = 1;

    if (typeof pageParam === "number" && pageParam > 0) {
      page = pageParam;
    }

    const params: Record<string, unknown> = {
      page,
      sort,
      filters,
      per_page: perPage,
      ...scope,
    };

    if (query) {
      params.query = query;
    }

    if (order) {
      params.order = order;
    }

    const route = isAdminScope
      ? internalApi.admin.search.communityMembers.indexV2
      : internalApi.search.communityMembers.indexV2;

    return reactQueryGet(route({ params }));
  };

  const queryKey: any[] = [
    "community-members",
    query,
    sort,
    order,
    filters,
    perPage,
  ];

  const queryOptions = {
    getNextPageParam: (lastPage: any) =>
      lastPage.has_next_page ? lastPage.page + 1 : undefined,
    getPreviousPageParam: (firstPage: any) =>
      firstPage.page > 1 ? firstPage.page - 1 : undefined,
    keepPreviousData: true,
    ...options,
  };

  const result = useInfiniteQuery(queryKey, fetchPage, queryOptions);
  const pages = useMemo(() => result.data?.pages || [], [result.data?.pages]);
  const totalMembers: number = pages[0]?.meta?.total_count || 0;
  const totalQueryMembers: number = pages[0]?.count || 0;
  const pageSize: number = pages[0]?.per_page || perPage;
  const members = useMemo(
    () => flatten(pages.map(page => page.records)),
    [pages],
  );
  return {
    ...result,
    queryKey,
    totalMembers,
    totalQueryMembers,
    members,
    pageSize,
    pages,
  };
};
