import { t } from "@/i18n-js/instance";
import { EmptyState } from "@circle-react-shared/uikit/EmptyState";
import { useAccessGroupRedirections } from "../hooks/useAccessGroupRedirections";
import { useAccessGroupData } from "./DataStoreContext/AccessGroupDataContext";

const i18nBase = "settings.access_groups.admin.modal.members";

export const AccessGroupMembersEmptyState = () => {
  const { redirectToAddMembers } = useAccessGroupRedirections();
  const handelAddMembers = () => {
    redirectToAddMembers();
  };
  const { shouldBlockActions } = useAccessGroupData();

  return (
    <div className="w-full pt-7 lg:px-9">
      <div className="border-primary rounded-2xl border py-12">
        <EmptyState
          header={t([i18nBase, "setup"])}
          description={t([i18nBase, "setup_description"])}
          secondaryLabel={t([i18nBase, "actions.select_members"])}
          secondaryDisabled={shouldBlockActions}
          onSecondaryClick={handelAddMembers}
        />
      </div>
    </div>
  );
};
