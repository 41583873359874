import { useState } from "react";
import { useModal } from "@ebay/nice-modal-react";
import { t } from "@/i18n-js/instance";
import { Modal } from "@circle-react-uikit/ModalV2";
import { ImportModalFooter } from "../ImportModalFooter";
import { ImportModalHeader } from "../ImportModalHeader";
import { useSpacesForImport } from "../queries";
import { useMultiSelect } from "../useMultiSelect";
import { Spaces } from "./Spaces";

export const ImportSpacesModalComponent = ({
  spaceIds,
  onImport,
  page,
}: {
  spaceIds: number[];
  onImport: (ids: number[]) => void;
  page: number;
}) => {
  const modal = useModal();
  const [currentPage, setPage] = useState(page);
  const spacesQuery = useSpacesForImport({
    currentPage,
    spaceIds,
  });
  const {
    selectedIds,
    allIdsOnPage,
    handleCheckboxChange,
    handleSelectAllOnPage,
    reset,
  } = useMultiSelect({ query: spacesQuery });

  const onChangePage = (page: number) => {
    // Forcing useQuery to only cache one page
    setPage(page);
    spacesQuery.remove();
  };

  const handleOnImport = () => {
    onImport(selectedIds);
    void modal.hide();
    reset();
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content
        size="auto"
        className="flex h-full max-h-full max-w-[720px] flex-col overflow-visible !rounded-t-none sm:h-full sm:!rounded-t-lg md:max-h-[80vh]"
      >
        <ImportModalHeader
          title={t(
            "community_bot.bot_builder.knowledge_page.community_resources_table.title",
          )}
        />
        <div className="flex-1 overflow-auto">
          <Spaces
            spacesQuery={spacesQuery}
            selectedIds={selectedIds}
            handleCheckboxChange={handleCheckboxChange}
            handleSelectAllOnPage={handleSelectAllOnPage}
            allIdsOnPage={allIdsOnPage}
            onChangePage={onChangePage}
          />
        </div>
        <ImportModalFooter
          onImport={handleOnImport}
          selectedCount={selectedIds.length}
          count={spacesQuery.data?.count || 0}
        />
      </Modal.Content>
    </Modal>
  );
};
