import { useEffect, useMemo } from "react";
import { useElements } from "@stripe/react-stripe-js";
import { isEqual } from "lodash";
import { useFilterPaymentMethodsSupported } from "@/react/components/Paywalls/Checkout/hooks/useFilterPaymentMethodsSupported";
import { useStripeElementOptions } from "@/react/components/Paywalls/Checkout/hooks/useStripeElementOptions";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Typography } from "@circle-react-uikit/Typography";
import { stripeElementsOptions } from "../../StripeElements/stripeElementsOptions";
import { EventCover } from "./EventCover";
import { EventDetails } from "./EventDetails";
import { PaywallPriceOption } from "./PaywallPriceOption";
import "../styles.scss";

export const PaywallPrice = () => {
  const {
    paywall,
    getSelectedPrice,
    setSelectedPrice,
    isPaywallDigitalWalletEnabled,
    isBuyNowPayLaterEnabled,
    currentCommunityMemberPaymentProcessorSessionClientSecret,
    isBuyNowPayLaterAllowed,
    setIsBuyNowPayLaterAllowed,
    isProcessingPayment,
    previewMutation,
    paymentMethodsSupported,
    disabledPaymentMethods,
  } = usePaywallCheckoutContext();
  const elements = useElements();
  const price = getSelectedPrice();
  const filteredPaymentMethodsSupported = useFilterPaymentMethodsSupported(
    paymentMethodsSupported,
    disabledPaymentMethods,
  );
  const otherStripeElementsOptions = useStripeElementOptions({
    filteredPaymentMethodsSupported,
    isPaywallDigitalWalletEnabled,
    paywall,
    price,
    isBuyNowPayLaterEnabled,
    isBuyNowPayLaterAllowed,
    amountFromPreview: previewMutation.data?.amount_due_now,
    currentCommunityMemberPaymentProcessorSessionClientSecret,
  });
  const paymentElementOptions = useMemo(
    () => ({
      ...stripeElementsOptions,
      ...otherStripeElementsOptions,
    }),
    [
      paymentMethodsSupported.sort().join(","),
      disabledPaymentMethods.sort().join(","),
      price,
      isBuyNowPayLaterAllowed,
      previewMutation.data,
    ],
  );

  const onPriceChangeHandler = (priceId: any) => {
    setSelectedPrice(priceId);
    if (elements) {
      elements.update(paymentElementOptions);
    }
  };

  useEffect(() => {
    const isAmountDueZero = previewMutation.data
      ? isEqual(previewMutation.data.amount_due_now, 0)
      : false;
    if (isAmountDueZero && isBuyNowPayLaterAllowed) {
      setIsBuyNowPayLaterAllowed(false);
    } else if (
      !isAmountDueZero &&
      !isBuyNowPayLaterAllowed &&
      !isProcessingPayment
    ) {
      setIsBuyNowPayLaterAllowed(true);
    }

    if (!elements) return;
    elements.update(paymentElementOptions);
  }, [elements, paymentElementOptions]);

  const selectedPrice = getSelectedPrice();

  const isSelectedPrice = (price: any) =>
    selectedPrice && selectedPrice.id == price.id;

  let title: string;
  if (paywall.event?.name) {
    title = paywall.event.name;
  } else {
    title = paywall.display_name;
  }

  let description: string | undefined;
  if (paywall.description) {
    description = paywall.description;
  } else if (paywall.event?.body) {
    description = paywall.event?.body;
  }

  // TODO:
  // In the future, our checkout system will rely on a paywall's contents association (polymorphic) to
  // customize the checkout page as we are doing for events. For now, we are using the event association
  // directly to display the event details.

  return (
    <div className="flex flex-col gap-8">
      <EventCover event={paywall.event} />
      <div
        className="flex flex-col gap-3"
        data-testid="checkout-paywall-detail"
      >
        <Typography.TitleLg>{title}</Typography.TitleLg>
        <EventDetails event={paywall.event} />
        {description && (
          <Typography.BodyMd>
            <span className="whitespace-pre-wrap">{description}</span>
          </Typography.BodyMd>
        )}
      </div>
      <div className="flex flex-col gap-4">
        {(paywall.prices || []).map((price: any) => (
          <PaywallPriceOption
            key={`paywall_price-${price.id}`}
            id={price.id}
            title={price.title}
            subtitle={price.subtitle}
            value={price.value}
            isSelected={isSelectedPrice(price)}
            onSelect={onPriceChangeHandler}
          />
        ))}
      </div>
    </div>
  );
};
