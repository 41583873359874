import { useFetchMemberTag } from "@circle-workflows/hooks/useFetchMemberTag";
import isEmpty from "lodash/isEmpty";
import { t } from "@/i18n-js/instance";
import { BaseResource } from "../../BaseResource";

const i18nRoot = "settings.workflows.view";

export const TagTriggerConditionsView = ({
  resourceId,
  contactType,
}: {
  resourceId: number;
  contactType: string;
}) => {
  const { data: memberTag = {}, isLoading } = useFetchMemberTag({
    id: resourceId,
  });

  if (!memberTag) {
    return null;
  }

  const badgeText = isEmpty(memberTag)
    ? t([i18nRoot, "deleted_member_tag"])
    : memberTag.name;

  return (
    <div className="flex flex-col gap-6">
      <BaseResource
        label={t([i18nRoot, "resources.member_tag"])}
        value={badgeText}
        isLoading={isLoading}
        shouldRepresentAsBadge
      />
      <BaseResource
        label={t([i18nRoot, "resources.contact_type"])}
        value={t([
          "settings.workflows.form.audience_type_options",
          contactType,
        ])}
      />
    </div>
  );
};
