import { t } from "@/i18n-js/instance";
import { useThemeContext } from "@/react/providers";
import { botBuilderNavPaths } from "@circle-react/helpers/urlHelpers/botBuilderNavPaths";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { useCreateAgent } from "./agentsQueries";
import setupAgentsDarkImage from "./svgs/setup-agents-dark.svg";
import setupAgentsLightImage from "./svgs/setup-agents-light.svg";

export const NoAgents = () => {
  const { isDarkAppearance } = useThemeContext();
  const createAgentMutation = useCreateAgent();

  const handleCreateAgent = () => {
    createAgentMutation.mutate(undefined, {
      onSuccess: data => {
        window.location.href = botBuilderNavPaths.customize({
          botPublicId: data.id.toString(),
        });
      },
    });
  };

  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center p-8 text-center"
      data-testid="empty-state"
    >
      <img
        width={343}
        height={435}
        src={isDarkAppearance ? setupAgentsDarkImage : setupAgentsLightImage}
        alt=""
        aria-hidden="true"
        loading="eager"
      />

      <div className="flex flex-col items-center justify-center gap-5">
        <div className="flex max-w-lg flex-col gap-3">
          <Typography.TitleMd weight="font-semibold">
            {t("settings.community_bot.empty.header")}
          </Typography.TitleMd>
          <Typography.BodyMd>
            {t("settings.community_bot.empty.description")}
          </Typography.BodyMd>
        </div>
        <div className="flex gap-3">
          <Button
            type="button"
            variant="circle"
            large
            onClick={() => handleCreateAgent()}
          >
            {t("settings.community_bot.empty.primary_label")}
          </Button>
        </div>
      </div>
    </div>
  );
};
