import { useCallback } from "react";
import { t } from "@/i18n-js/instance";
import { nextPlanWithFeatureApi } from "@/react/api/nextPlanWithFeatureApi";
import { usePunditUserContext } from "@circle-react/contexts";
import { botBuilderNavPaths } from "@circle-react/helpers/urlHelpers/botBuilderNavPaths";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { useCreateAgent } from "../agentsQueries";
import { CallToAction } from "./CallToAction";
import { AIInboxFeature } from "./Features/AIInboxFeature";
import { ChatAgentsFeature } from "./Features/ChatAgentsFeature";
import { KnowledgeEngineFeature } from "./Features/KnowledgeEngineFeature";
import { Header } from "./Header";
import { localeRoot } from "./constants";

export const LandingPage = () => {
  const upgradePlanModal = useUpgradePlanModal();
  const { currentCommunity } = usePunditUserContext();
  const createAgentMutation = useCreateAgent();

  const handleUpgradeAndCreate = useCallback(async () => {
    const nextPlanData = await nextPlanWithFeatureApi.getNextPlanWithFeature(
      (currentCommunity?.current_plan?.id ?? "").toString(),
      { plan_feature_flag: "ai_agents" },
    );

    const planTier = nextPlanData?.plan_tier || "enterprise";

    await upgradePlanModal.show({
      planTier,
      source: UPGRADE_FLOW_SOURCES.AI_AGENTS_ENABLE_MODAL,
      subheading: t("pricing_plans.upgrade_modal.subheading", {
        feature_name: t("settings.community_ai.ai_agents_feature_name"),
      }),
      successButtonLabel: t(
        "pricing_plans.upgrade_modal.actions.go_to_customize_agent",
      ),
      showPlanDetails: true,
      onSuccess: () => {
        createAgentMutation.mutate(undefined, {
          onSuccess: data => {
            window.location.href = botBuilderNavPaths.customize({
              botPublicId: data.id.toString(),
            });
          },
        });
      },
    });
  }, [upgradePlanModal, currentCommunity, createAgentMutation]);

  return (
    <div className="bg-primary flex h-full w-full flex-col items-center pb-4">
      <div className="inline-flex h-10 w-full flex-col items-start justify-start gap-2 overflow-hidden bg-zinc-100 px-9 py-2.5">
        <div className="inline-flex items-center justify-center gap-4 self-stretch">
          <button
            onClick={handleUpgradeAndCreate}
            className="cursor-pointer"
            type="button"
          >
            <span className="font-['Inter'] text-sm font-normal leading-tight text-zinc-900 underline">
              {t([localeRoot, "banner.upgrade_notice"])}
            </span>
          </button>
        </div>
      </div>
      <div className="flex h-full w-full max-w-[1078px] flex-col items-center gap-y-14 px-8 py-14">
        <Header />
        <div className="bg-primary flex flex-col gap-y-6">
          <ChatAgentsFeature featureNumber={1} />
          <KnowledgeEngineFeature featureNumber={2} />
          <AIInboxFeature featureNumber={3} />
        </div>
        <CallToAction />
      </div>
    </div>
  );
};
