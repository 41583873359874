import { t } from "@/i18n-js/instance";
import { AmountFilterComponent } from "./components/AmountFilterComponent";

export const queryParamsKey = {
  total_amount_paid: "total_amount_paid",
  lte: "total_amount_paid_lte",
  gte: "total_amount_paid_gte",
};

export const localeRoot =
  "settings.paywalls_admin_dashboard.filters.total_amount_paid";

export const TotalAmountPaidFilter = (props: any) => (
  <AmountFilterComponent
    queryParamsKey={queryParamsKey}
    localeRoot={localeRoot}
    {...props}
  />
);

export const filterProperties = {
  name: "total_amount_paid",
  filterKeys: Object.values(queryParamsKey),
  component: TotalAmountPaidFilter,
  label: t([localeRoot, "chip"]),
};
