import { t } from "@/i18n-js/instance";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";
import { PriceTypeSelectorField } from "./PriceTypeSelectorField";

export const BillingFrequencyField = () => {
  const { billingFrequencyOptions } = usePricingTabContext();
  const options = billingFrequencyOptions();

  return (
    <PriceTypeSelectorField
      name="billing_frequency"
      options={options}
      required
      tooltipText={t(
        "settings.paywalls.modal.tabs.pricing.add_price_modal.tooltip_text.billing_frequency",
      )}
    />
  );
};
