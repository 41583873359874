import { t } from "@/i18n-js/instance";
import { Icon } from "@/react/components/shared/Icon";
import { Modal } from "@/react/components/shared/uikit/ModalV2";
import { LabelMd } from "@/react/components/shared/uikit/Typography/components/Label/LabelMd";
import { TitleLg } from "@/react/components/shared/uikit/Typography/components/Title/TitleLg";
import { LabelSm } from "@circle-react-shared/uikit/Typography/components/Label/LabelSm";
import { Button } from "@circle-react-uikit/Button";
import { Loader } from "@circle-react-uikit/Loader";
import { useChangePlanContext } from "../ChangePlanContext";
import { paywallPresenterOptions } from "../constants";
import { useGetPaywall } from "../hooks/useGetPaywall";

export interface SuccessPageProps {
  updatedSubscription: any;
  onDone: () => void;
}

export const SuccessPage = ({
  updatedSubscription,
  onDone,
}: SuccessPageProps) => {
  const { subscription, isManagingMember } = useChangePlanContext();
  const oldPaywallQuery = useGetPaywall(
    subscription.paywall_id,
    paywallPresenterOptions,
  );
  const newPaywallQuery = useGetPaywall(
    updatedSubscription.paywall_id,
    paywallPresenterOptions,
  );

  if (oldPaywallQuery.isLoading || newPaywallQuery.isLoading) {
    return <Loader center />;
  }

  if (oldPaywallQuery.isError || newPaywallQuery.isError) {
    return null; // TODO: properly handle loading & error scenarios
  }

  const oldPaywall = oldPaywallQuery.data;
  const oldPrice = oldPaywall.all_subscription_prices.find(
    (price: any) => price.id === subscription.paywall_price_id,
  );
  const oldPlanDetails = `${oldPaywall.display_name} (${oldPrice.short_description})`;

  const newPaywall = newPaywallQuery.data;
  const newPrice = newPaywall.all_subscription_prices.find(
    (price: any) => price.id === updatedSubscription.paywall_price_id,
  );
  const newPlan = newPaywall.display_name;
  const newPlanDetails = `${newPlan} (${newPrice.short_description})`;

  const handleSubmit = () => {
    onDone();
  };

  const textLocales = isManagingMember
    ? {
        subtitle: t(
          "member_profile_modal.billing.subscriptions.success_page.admin_perspective.subtitle",
          {
            community_member_name: subscription.community_member.name,
          },
        ),
        description: t(
          "member_profile_modal.billing.subscriptions.success_page.admin_perspective.description",
          {
            community_member_name: subscription.community_member.name,
            old_plan: oldPlanDetails,
            new_plan: newPlanDetails,
          },
        ),
        actionButton: t(
          "member_profile_modal.billing.subscriptions.success_page.admin_perspective.action_button",
        ),
      }
    : {
        subtitle: t(
          "member_profile_modal.billing.subscriptions.success_page.member_perspective.subtitle",
        ),
        description: t(
          "member_profile_modal.billing.subscriptions.success_page.member_perspective.description",
          {
            old_plan: oldPlanDetails,
            new_plan: newPlanDetails,
          },
        ),
        actionButton: t(
          "member_profile_modal.billing.subscriptions.success_page.member_perspective.action_button",
        ),
      };

  return (
    <>
      <Modal.Body className="flex flex-col items-center">
        <div className="bg-v2-success m-8 flex h-16 w-16 justify-center rounded-full">
          <Icon type="check-solid" viewBox="0 0 30 22" />
        </div>
        <div className="text-center">
          <LabelMd weight="semibold">{textLocales.subtitle}</LabelMd>
          <div className="mb-4 mt-2 flex flex-col">
            <TitleLg>{newPlan}</TitleLg>
            <LabelSm>{newPrice.short_description}</LabelSm>
          </div>
          <LabelMd>{textLocales.description}</LabelMd>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="circle" full large onClick={handleSubmit}>
          {textLocales.actionButton}
        </Button>
      </Modal.Footer>
    </>
  );
};
