import { t } from "@/i18n-js/instance";
import {
  SPACE_POST_TYPES,
  TRIGGER_RESOURCE_TYPE,
} from "@circle-react/components/SettingsApp/Workflows/constants";
import { EntitySelector } from "../../EntitySelector";
import { entityTypeOptions as postOptions } from "./AnyPostSelector";

const i18nRoot = "settings.workflows.form.entity_type.post";

const entityTypeOptions = [
  ...postOptions,
  {
    value: TRIGGER_RESOURCE_TYPE.POST_BASIC,
    label: t([i18nRoot, "specific_post.title"]),
    description: t([i18nRoot, "specific_post.description"]),
  },
];

export const AnyPostSpecificSelector = () => (
  <EntitySelector
    entityTypeOptions={entityTypeOptions}
    resourceType={SPACE_POST_TYPES.BASIC}
  />
);

AnyPostSpecificSelector.propTypes = {};
