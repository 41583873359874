import { t } from "@/i18n-js/instance";
import { useFilterContext } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { Filter, InputFilter } from "@circle-react-shared/Filter";

export interface NameFilterProps {
  isInitialOpen?: boolean;
  name?: string;
}

export const NameFilter = ({
  isInitialOpen,
  name = "name",
}: NameFilterProps) => {
  const { filters, setFilters } = useFilterContext();
  return (
    <Filter
      chip={t(`search_v2.filters.${name}`)}
      title={t(`search_v2.filters.${name}`)}
      selectedText={filters?.[name]}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        setFilters({
          ...filters,
          [name]: value,
        });
      }}
      shouldShowRemoveButton
    >
      <InputFilter name={name} placeholder={t("search_v2.filters.name")} />
    </Filter>
  );
};
