import { t } from "@/i18n-js/instance";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { RecurringEventSettingResource } from "./shared/RecurringEventSettingResource";
import { RecurringEventsSelector } from "./shared/RecurringEventsSelector";

export const rsvpToRecurringEvent: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_rsvps_to_recurring_event.title",
  ),
  value: "community_member_rsvps_to_recurring_event",
  group: t("settings.workflows.form.event_groups.events"),
  description: t(
    "settings.workflows.form.events.community_member_rsvps_to_recurring_event.description",
  ),
  resource: TRIGGER_RESOURCE_TYPE.RECURRING_EVENT_SETTING,
  formComponent: RecurringEventsSelector,
  viewComponent: RecurringEventSettingResource,
  icon: "event",
};
