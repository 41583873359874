import { useModal } from "@ebay/nice-modal-react";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";

export const ImportModalFooter = ({
  onImport,
  count,
  selectedCount,
}: {
  onImport: () => void;
  count: number;
  selectedCount: number;
}) => {
  const modal = useModal();

  return (
    <div className="border-primary border-t">
      <div className="flex justify-end gap-3 px-5 py-4">
        <Button type="button" variant="secondary" onClick={modal.hide}>
          {t("community_bot.bot_builder.knowledge_page.cancel")}
        </Button>
        <Button
          type="button"
          variant="circle"
          onClick={onImport}
          disabled={count === 0 || selectedCount === 0}
        >
          {t("community_bot.bot_builder.knowledge_page.import")}
        </Button>
      </div>
    </div>
  );
};
