import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { NewSpaceGroupForm } from "@circle-react/components/SpaceGroups/NewFormV2";
import { useCreateSpaceGroupMutation } from "@circle-react/components/SpaceGroups/NewFormV2/hooks/useCreateSpaceGroupMutation";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";

const formId = "new-space-group-form";

export interface NewSpaceGroupModalProps {
  position?: number;
}

const NewSpaceGroupModalComponent = ({ position }: NewSpaceGroupModalProps) => {
  const drawer = useModal();

  const { mutate: createSpaceGroupMutation, isLoading: isSubmitting } =
    useCreateSpaceGroupMutation({
      position,
      onSuccess: drawer.hide,
    });

  return (
    <Modal
      title={t("space_groups.form.create.title")}
      isOpen={drawer.visible}
      onClose={drawer.hide}
    >
      <Modal.Overlay />
      <Modal.Content size="md">
        <Modal.Header className="!px-6 md:!px-8">
          <Modal.Title size="md">
            {t("space_groups.form.create.title")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={drawer.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="!px-6 md:!px-8">
          <NewSpaceGroupForm
            formId={formId}
            onSubmit={createSpaceGroupMutation}
          />
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          <Button
            type="submit"
            variant="circle"
            form={formId}
            disabled={isSubmitting}
            full
            large
          >
            {isSubmitting
              ? t("space_groups.form.create.submitting")
              : t("space_groups.form.create.submit")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

const NewSpaceGroupModal = ModalManager.create(
  (props: NewSpaceGroupModalProps) => (
    <NewSpaceGroupModalComponent {...props} />
  ),
);

export const useNewSpaceGroupModal = () => useModal(NewSpaceGroupModal);
