import { createContext, useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  BROADCAST_TABS,
  TAB_QUERY_NAME,
} from "@/react/components/SettingsApp/Emails/components/Broadcasts/constants";
import { serializeObjectToQueryParam } from "@circle-react/helpers/urlHelpers";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParamsV2";
import { useTable } from "../PushNotificationsTable/useTable";

const PushNotificationsList = createContext<any>(null);

PushNotificationsList.displayName = "PushNotificationsList";

export const usePushNotificationsList = (): any => {
  const PushNotificationsListContext = useContext(PushNotificationsList);
  if (!PushNotificationsListContext) {
    throw new Error(
      "usePushNotificationsList must be used within a PushNotificationsListProvider",
    );
  }
  return PushNotificationsListContext;
};

const queryParamsSchema = yup.object().shape({
  [TAB_QUERY_NAME]: yup
    .mixed<(typeof BROADCAST_TABS)[number]>()
    .default(BROADCAST_TABS[0])
    .transform(value => {
      if (!BROADCAST_TABS.includes(value)) {
        return BROADCAST_TABS[0];
      }
      return value;
    }),
});

export const PushNotificationsListProvider = ({
  children,
}: any): JSX.Element => {
  const { [TAB_QUERY_NAME]: tab } = useRouterQueryParams(queryParamsSchema);
  const tabIndex = BROADCAST_TABS.indexOf(tab);
  const initialIndex = tabIndex === -1 ? 0 : tabIndex;

  const [selectedTabIndex, setSelectedTabIndex] = useState(initialIndex);

  const history = useHistory();

  const handleTabChange = useCallback(
    ({ index, page }: { index: number; page: number }) => {
      setSelectedTabIndex(index);

      history.push({
        search: serializeObjectToQueryParam({
          page: page.toString(),
          status: BROADCAST_TABS[index],
        }),
      });
    },
    [history],
  );

  const tableProps = useTable({
    status: BROADCAST_TABS[selectedTabIndex],
  });

  return (
    <PushNotificationsList.Provider
      value={{
        selectedTabIndex,
        handleTabChange,
        ...tableProps,
      }}
    >
      {children}
    </PushNotificationsList.Provider>
  );
};
