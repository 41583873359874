import { useChatList } from "./useCommunityChatList";

export const usePinnedBotChat = () => {
  const { chats: botChats = [], isChatsLoading: isBotChatLoading } =
    useChatList({
      filter: "mine",
      per_page: 1,
    });

  return {
    botChat: botChats?.[0],
    isLoading: isBotChatLoading,
  };
};
