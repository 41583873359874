import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import type { Space } from "@/react/types/Space";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Modal } from "@circle-react-uikit/ModalV2";
import { CustomizationOptions } from "./CustomizationOptions";

export interface SpaceCustomizationModalProps {
  space?: Space;
  setIsAddMemberOpen: (isOpen: boolean) => void | Promise<void>;
  onClickAddCoverImage: () => void | Promise<void>;
}

export const SpaceCustomizationModalComponent = ({
  space,
  setIsAddMemberOpen,
  onClickAddCoverImage,
}: SpaceCustomizationModalProps) => {
  const modal = useModal();
  const isSmScreen = useSmScreenMediaQuery();

  !isSmScreen && modal.visible && void modal.hide();

  return (
    <Modal isOpen={modal.visible} onClose={modal.visible ? modal.hide : noop}>
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Modal.Header className="!px-5 !py-4">
          <Modal.Title size="sm">{t("space_settings")}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.visible ? modal.hide : noop} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="!px-0 !py-1">
          <CustomizationOptions
            space={space}
            setIsAddMemberOpen={setIsAddMemberOpen}
            onClickAddCoverImage={onClickAddCoverImage}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const SpaceCustomizationModal = ModalManager.create(
  ({
    space,
    setIsAddMemberOpen,
    onClickAddCoverImage,
  }: SpaceCustomizationModalProps) => (
    <SpaceCustomizationModalComponent
      space={space}
      setIsAddMemberOpen={setIsAddMemberOpen}
      onClickAddCoverImage={onClickAddCoverImage}
    />
  ),
);

export const useSpaceCustomizationModal = () =>
  useModal(SpaceCustomizationModal);
