import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { MarketingHub } from "@circle-react-shared/MarketingHub";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { MinimalProgressBar } from "@circle-react-uikit/MinimalProgressBar";
import { Typography } from "@circle-react-uikit/Typography";
import { FreePeriodBanner } from "./FreePeriodBanner";
import { FreeTierBanner } from "./FreeTierBanner";

interface ActiveStateProps {
  billingDetails: {
    percentage_completion_in_tier: number;
    max_contacts_count_in_tier: number;
    current_contacts_count: number;
    amount_with_currency_interval: string;
    amount_with_currency: string;
    free_period: any;
    billing_status: string;
    free_tier: boolean;
  };
}

export const ActiveState = ({ billingDetails }: ActiveStateProps) => {
  const {
    percentage_completion_in_tier: percentageCompletionInTier,
    max_contacts_count_in_tier: maxContactsCountInTier,
    current_contacts_count: currentContactsCount,
    amount_with_currency_interval: amountWithCurrencyInterval,
    amount_with_currency: amountWithCurrency,
    free_period: freePeriod,
    billing_status: billingStatus,
    free_tier: isFreeTier,
  } = billingDetails;

  const cancellationModal = MarketingHub.useCancellationModal();

  return (
    <div className="bg-primary border-primary my-6 rounded-lg border px-8 py-7">
      <div className="border-primary mb-6 flex justify-between border-b pb-6">
        <div className="flex items-center gap-2">
          <Typography.TitleSm weight="bold" data-testid="plan-name">
            {t("settings.billing.marketing_hub.title")}
          </Typography.TitleSm>
          {billingStatus === "free_period" && (
            <BadgeV2
              label={t("settings.billing.marketing_hub.free_period_badge")}
            />
          )}

          {isFreeTier && (
            <BadgeV2
              label={t("settings.billing.marketing_hub.free_tier_badge")}
            />
          )}
        </div>
        <Typography.TitleSm weight="bold" data-testid="plan-price">
          {freePeriod?.active ? (
            <>
              <s>{amountWithCurrency}</s>
              {t("settings.billing.marketing_hub.per_month")}
            </>
          ) : (
            amountWithCurrencyInterval
          )}
        </Typography.TitleSm>
      </div>

      <div>
        <div className="mb-4">
          <Typography.LabelMd weight="semibold">
            {t("settings.billing.marketing_hub.contacts")}
          </Typography.LabelMd>
        </div>
        <div className="mb-2">
          <MinimalProgressBar progress={percentageCompletionInTier} size="sm" />
        </div>
        <div className="flex justify-between">
          <Typography.LabelSm color="text-light">
            {t("settings.billing.marketing_hub.contacts_in_tier", {
              current_contacts_count: currentContactsCount,
              max_contacts_count_in_tier: maxContactsCountInTier,
            })}
          </Typography.LabelSm>
          <div>
            <Typography.LabelSm color="text-light">
              {t("settings.billing.marketing_hub.scales_as_you_grow")}
            </Typography.LabelSm>
            <TippyV2
              content={t(
                "settings.billing.marketing_hub.scales_as_you_grow_tooltip",
              )}
            >
              <Icon
                type="16-info"
                useWithStrokeCurrentColor
                size={16}
                className="ml-1"
              />
            </TippyV2>
          </div>
        </div>
      </div>
      {freePeriod?.active && <FreePeriodBanner freePeriod={freePeriod} />}
      {isFreeTier && <FreeTierBanner />}
      <div className="mt-4">
        <Link to={settingsNavPathV3.members.manage}>
          <button type="button">
            <Typography.LabelSm color="text-messaging-info-blue">
              {t("settings.billing.marketing_hub.manage_contacts")}
            </Typography.LabelSm>
          </button>
        </Link>
        <div>
          <button type="button" onClick={() => cancellationModal.show()}>
            <Typography.LabelSm color="text-messaging-info-blue">
              {t("settings.billing.marketing_hub.disable_marketing_hub")}
            </Typography.LabelSm>
          </button>
        </div>
      </div>
    </div>
  );
};
