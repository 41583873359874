import { useMemo } from "react";
import { useSpacesContext } from "@circle-react/contexts";

export const useSpaceNameMap = () => {
  const { helpers } = useSpacesContext();
  const records = helpers.spaceWithViewContentAccess();
  return useMemo(
    () =>
      records
        .map(record => ({
          label: record.emoji ? `${record.emoji} ${record.name}` : record.name,
          value: record.slug,
        }))
        .reduce((accumulator, record) => {
          accumulator[record.value] = record.label;
          return accumulator;
        }, {}),
    [records],
  );
};
