import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

export interface HistoryDetailViewSendToWebhookProps {
  url: string;
}

export const HistoryDetailViewSendToWebhook = ({
  url,
}: HistoryDetailViewSendToWebhookProps) => (
  <div className="flex flex-col gap-2">
    <div className="md:flex">
      <div className="w-1/4">
        <Typography.LabelSm weight="semibold">
          {t("settings.workflows.action_messages.send_to_webhook.url")}
        </Typography.LabelSm>
      </div>
      <div className="w-3/4">
        <Typography.LabelSm weight="semibold">{url}</Typography.LabelSm>
      </div>
    </div>
  </div>
);
