import { useFormContext } from "react-hook-form";
import { isDesktopPreviewMode } from "../../../Providers/helpers";
import { usePaywallsEditFormContext } from "../PaywallsEditFormProvider";
import { PriceMobileSelector } from "./PriceMobileSelector";
import { PriceWebSelector } from "./PriceWebSelector";

export const PaywallPriceForm = ({ index }: any) => {
  const { fieldNameRoot, previewMode } = usePaywallsEditFormContext();
  const { watch } = useFormContext();

  const currentPaywall = watch(`${fieldNameRoot}.${index}`);
  const currentPaywallPrice = isDesktopPreviewMode(previewMode)
    ? currentPaywall?.web_paywall_price
    : currentPaywall?.mobile_paywall_price;

  const filterByText = (option: any, search: string) =>
    (typeof option.label === "string" ? option.label : option.textLabel)
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(search.toLowerCase().replace(/\s+/g, ""));

  const filterPaywallPriceByPaywallId = (price: any) =>
    price.paywall_id === currentPaywall.id &&
    price.value !== currentPaywallPrice?.id;

  const filterPaywallPriceByPaywallPricePackageId = (price: any) =>
    price.paywall_id === currentPaywall.id &&
    price.value !== currentPaywallPrice?.price_package_id;

  const filterByPrice = (options: any[] = [], search = "") => {
    const availableOptions = options.filter(
      isDesktopPreviewMode(previewMode)
        ? filterPaywallPriceByPaywallPricePackageId
        : filterPaywallPriceByPaywallId,
    );

    if (!search) {
      return [...availableOptions];
    }

    return availableOptions.filter(option => filterByText(option, search));
  };

  const PriceSelectorComponent = isDesktopPreviewMode(previewMode)
    ? PriceWebSelector
    : PriceMobileSelector;

  if (!currentPaywall?.id) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-2">
      <PriceSelectorComponent
        paywall={currentPaywall}
        filterByPrice={filterByPrice}
        index={index}
      />
    </div>
  );
};
