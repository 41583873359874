import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { BillingPortal } from "@circle-react/types";

const create = (): Promise<BillingPortal> =>
  reactQueryPost(
    internalApi.accountPaymentProcessors.billingPortal.create(),
    {},
  );

export const billingPortalApi = {
  create,
};
