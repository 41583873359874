import type { Profile } from "@circle-react/types/CommunityBot/Profile";
import { Icon } from "@circle-react-shared/Icon";

interface AgentAvatarProps {
  profile: Profile;
}

export const AgentAvatar = ({ profile }: AgentAvatarProps) => (
  <>
    {!profile.avatar_url && (
      <Icon
        type="120-ai-bot"
        size={120}
        className="mb-4 !h-[7.5rem] !w-[7.5rem]"
      />
    )}
    {profile.avatar_url && (
      <img
        src={profile.avatar_url}
        alt={profile.name}
        className="mb-4 !h-[7.5rem] !w-[7.5rem] rounded-full"
        loading="lazy"
      />
    )}
  </>
);
