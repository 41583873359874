import { t } from "@/i18n-js/instance";
import { AmountFilterComponent } from "./components/AmountFilterComponent";

export const queryParamsKey = {
  amount_paid: "amount",
  lte: "amount_lte",
  gte: "amount_gte",
};

export const localeRoot = "settings.paywalls_admin_dashboard.filters.amount";

export const AmountPaidFilter = (props: any) => (
  <AmountFilterComponent
    queryParamsKey={queryParamsKey}
    localeRoot={localeRoot}
    {...props}
  />
);

export const filterProperties = {
  name: "amount",
  filterKeys: Object.values(queryParamsKey),
  component: AmountPaidFilter,
  label: t([localeRoot, "chip"]),
};
