import { communityMemberChargeApi } from "@circle-react/api/communityMemberChargeApi";
import { paywallCheckoutApi } from "@circle-react/api/paywallCheckoutApi";
import { isPaid } from "@circle-react/helpers/communityMemberChargeHelpers";
import { GENERAL_ERROR, buildErrorMessage } from "../../helpers";

export const handleCheckoutError = (
  handleError: any,
  loginUrl: string,
  resetPasswordUrl: string,
  error: any,
) => handleError(buildErrorMessage(error, { loginUrl, resetPasswordUrl }));

export const redirectToCheckoutConfirmation = (
  checkoutResponse: any,
  checkoutConfirmationUrl: string,
) => {
  const processorId =
    checkoutResponse?.subscription_processor_id ||
    checkoutResponse?.payment_intent_processor_id ||
    checkoutResponse?.invoice_processor_id;
  const url = `${checkoutConfirmationUrl}${processorId}`;

  window.webview?.paymentSuccessful?.();
  return window.open(url, "_self");
};

const PAYMENT_POLLING_TIMEOUT_MILLISECONDS = 2000;
const PAYMENT_POLLING_MAX_TRIES = 30;

const resolveSleep = async (
  sleepTimeInMilliseconds = PAYMENT_POLLING_TIMEOUT_MILLISECONDS,
) => new Promise(resolve => setTimeout(resolve, sleepTimeInMilliseconds));

interface ProcessWithRetriesProp {
  retriesThreshold?: number;
  sleepTimeInMilliseconds?: number;
  executor: () => Promise<boolean>;
}

export const processWithRetries = async ({
  retriesThreshold = PAYMENT_POLLING_MAX_TRIES,
  sleepTimeInMilliseconds = PAYMENT_POLLING_TIMEOUT_MILLISECONDS,
  executor,
}: ProcessWithRetriesProp): Promise<any> => {
  let output = null;
  for (let retryIndex = 0; retryIndex < retriesThreshold; retryIndex++) {
    output = await executor();

    if (output) {
      break;
    }

    await resolveSleep(sleepTimeInMilliseconds);
  }

  return output;
};

export const verifyPayment = async (
  handleError: any,
  handleCheckoutError: any,
  arePostCreateActionsRequired: boolean,
  checkoutConfirmationUrl: string,
  checkoutResponseBody: any,
  tries: any,
  didRequiredAdditionalActions: boolean = false,
) => {
  // Poll backend to check payment status every 2 seconds for 30 times
  const {
    payment_intent_processor_id: paymentIntentProcessorId,
    invoice_processor_id: invoiceProcessorId,
  } = checkoutResponseBody;

  if (tries > PAYMENT_POLLING_MAX_TRIES) {
    handleError(GENERAL_ERROR);
    return;
  }

  const processorId = paymentIntentProcessorId ?? invoiceProcessorId;
  const response = await communityMemberChargeApi.show_DEPRECATED(processorId);

  if (response.ok) {
    const charge = await response.json();
    if (isPaid(charge)) {
      if (didRequiredAdditionalActions && arePostCreateActionsRequired) {
        const response =
          await paywallCheckoutApi.complete(checkoutResponseBody);
        if (!response.ok) {
          const responseError = await response.json();
          handleCheckoutError(responseError);
          return;
        }
      }
      return redirectToCheckoutConfirmation(
        checkoutResponseBody,
        checkoutConfirmationUrl,
      );
    }
  }
  await resolveSleep();

  await verifyPayment(
    handleError,
    handleCheckoutError,
    arePostCreateActionsRequired,
    checkoutConfirmationUrl,
    checkoutResponseBody,
    tries + 1,
    didRequiredAdditionalActions,
  );
  return undefined;
};
