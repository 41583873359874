import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { Filter, useFilterUrl } from "@circle-react-shared/Filter";
import { CustomerSearch } from "@circle-react-shared/Paywalls/CustomerSearch";

const localeNamespace =
  "members_directory.header.search.filters.member_name_or_email";

const queryParamsKey = "community_member_id";

export const MemberNameOrEmailFilterComponent = ({
  isInitialOpen,
  changeUrl = true,
  value,
}: any) => {
  const { queryParams, updateUrl } = useFilterUrl();
  const communityMemberId = value ?? queryParams[queryParamsKey];

  const fetchCommunityMember = () =>
    reactQueryGet(
      internalApi.search.communityMembers.index({
        params: {
          page: 1,
          per_page: 10,
          filters: {
            id: communityMemberId,
          },
        },
      }),
    );

  const { data: communityMemberSelectedData } = useQuery(
    ["community-members-subscription-community-member-id", communityMemberId],
    fetchCommunityMember,
    {
      enabled: !!communityMemberId,
    },
  );

  const selectedCommunityMember = communityMemberSelectedData?.records[0];

  return (
    <Filter
      chip={t([localeNamespace, "filter_chip"])}
      title={t([localeNamespace, "title"])}
      selectedText={communityMemberId}
      renderSelectedText={() =>
        selectedCommunityMember?.name ?? communityMemberId
      }
      isInitialOpen={isInitialOpen}
      onApply={value => {
        changeUrl && updateUrl(queryParamsKey, value);
      }}
      shouldShowRemoveButton
    >
      <CustomerSearch
        record={selectedCommunityMember}
        recordType="CommunityMember"
        searchInputPlaceholder={t([localeNamespace, "placeholder"])}
        onApply={value => {
          updateUrl("page", 1);
          changeUrl && updateUrl(queryParamsKey, value);
        }}
      />
    </Filter>
  );
};
