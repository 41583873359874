import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";

export const usePaymentMethodDataParams = () => {
  const { memberBillingInfo } = usePaywallCheckoutContext();
  const name = useFormContext().getValues("name") || "";

  const addressParams = () => {
    const {
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_postal_code,
      address_country,
    } = memberBillingInfo || {};

    return {
      line1: address_line1,
      line2: address_line2,
      city: address_city,
      state: address_state,
      country: address_country,
      postal_code: address_postal_code,
    };
  };

  const paymentMethodDataParams = isEmpty(memberBillingInfo)
    ? { payment_method_data: { billing_details: { name: name } } }
    : {
        payment_method_data: {
          billing_details: { address: addressParams(), name: name },
        },
      };

  return {
    paymentMethodDataParams,
  };
};
