import type { ReactNode } from "react";
import classNames from "classnames";

export interface ActionContentProps {
  tooltipContent?: string;
  className?: string;
  children: ReactNode;
}

export const ActionContent = ({
  tooltipContent,
  className,
  children,
}: ActionContentProps) => (
  <div
    className={classNames("flex items-center justify-center", className, {
      "cursor-pointer": tooltipContent,
    })}
  >
    {children}
  </div>
);
