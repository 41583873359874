import { useMemo } from "react";
import { noop } from "lodash";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { isEditingMode } from "@circle-react/helpers/paywallHelpers";
import { tabs } from "../../../constants";

export const useModalFooter = ({ activeTab, currentPaywallStatus }) => {
  const { register, setValue } = useFormContext();

  const isLastStep = useMemo(
    () => activeTab === tabs.TRACKING_TAB,
    [activeTab],
  );

  const submitPublishAs = shouldPublish => {
    register("publish");
    setValue("publish", shouldPublish);
  };

  const submitAsPublish = () => submitPublishAs(true);
  const submitAsUnpublish = () => {
    submitPublishAs(false);
    register("status");
    setValue("status", "inactive");
  };

  const primaryButtonStatesByPaywallStatus = {
    active: {
      buttonLabel: t("settings.paywalls.modal.footer.save"),
      variant: "circle",
      onClick: noop,
    },
    draft: {
      onClick: noop,
      variant: "circle",
      buttonLabel: t("settings.paywalls.modal.footer.save"),
    },
    inactive: {
      onClick: noop,
      variant: "circle",
      buttonLabel: t("settings.paywalls.modal.footer.save"),
    },
    paywallCreation: {
      variant: "circle",
      buttonLabel: isLastStep
        ? t("settings.paywalls.modal.footer.publish")
        : t("settings.paywalls.modal.footer.next"),
      // isLastStep is needed because this button is used
      // to navigate between tabs
      onClick: () => isLastStep && submitAsPublish(),
    },
  };

  const secondaryButtonStatesByPaywallStatus = {
    active: {
      onClick: submitAsUnpublish,
      variant: "danger",
      buttonLabel: t("settings.paywalls.modal.footer.unpublish"),
    },
    draft: {
      onClick: submitAsPublish,
      variant: "secondary",
      buttonLabel: t("settings.paywalls.modal.footer.publish"),
    },
    inactive: {
      onClick: submitAsPublish,
      variant: "secondary",
      buttonLabel: t("settings.paywalls.modal.footer.publish"),
    },
    paywallCreation: {
      onClick: noop,
      variant: "secondary",
      buttonLabel: t("settings.paywalls.modal.footer.save_draft"),
    },
  };

  const currentSecondaryButtonState =
    secondaryButtonStatesByPaywallStatus[currentPaywallStatus] ??
    secondaryButtonStatesByPaywallStatus["paywallCreation"];

  const currentPrimaryButtonState =
    primaryButtonStatesByPaywallStatus[currentPaywallStatus] ??
    primaryButtonStatesByPaywallStatus["paywallCreation"];

  return {
    isLastStep,
    isEditingMode: isEditingMode(currentPaywallStatus),
    currentPrimaryButtonState,
    currentSecondaryButtonState,
  };
};
