import { isNull } from "lodash";

interface AiIndicator {
  id: number | null;
  isAiSnoozed: boolean | null;
}

export const isIndicatorNeverSet = (value: AiIndicator) =>
  isNull(value?.id) && isNull(value?.isAiSnoozed);

export const isAIPausedIndicatorSet = (value: AiIndicator) =>
  !isNull(value?.id) && value?.isAiSnoozed;

export const isAIResumedIndicatorset = (value: AiIndicator) =>
  !isNull(value?.id) && !value?.isAiSnoozed;
