import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { useDropdown } from "@circle-react-shared/DropdownMenu";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { EditForm } from "../EditForm";
import { usePaywallCouponApi } from "../hooks/usePaywallCouponApi";

export const Actions = ({ paywallCoupon, refetchCoupons }) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDrawerVisible, toggleDrawer] = useBoolean(false);
  const { handleClose } = useDropdown();
  const { destroyMutation } = usePaywallCouponApi({
    refetchCoupons,
    paywallCoupon,
  });

  const { mutateAsync: deleteCoupon, isLoading } = destroyMutation;

  const canEdit = Boolean(paywallCoupon?.policies?.can_edit);
  const canDestroy = Boolean(paywallCoupon?.policies?.can_destroy);

  const menuItems = useMemo(() => {
    const items = [];

    if (canEdit) {
      items.push({
        key: "edit-coupon",
        label: t("settings.paywall_coupons.buttons.edit"),
        onClick: () => {
          handleClose();
          toggleDrawer();
        },
      });
    }

    if (canDestroy) {
      items.push({
        key: "delete-coupon",
        label: t("settings.paywall_coupons.buttons.delete"),
        hasDanger: true,
        onClick: () => {
          handleClose();
          setIsConfirmationOpen(true);
        },
      });
    }

    return items;
  }, [canEdit, canDestroy, handleClose, toggleDrawer]);

  return (
    <>
      {canEdit && (
        <EditForm
          paywallCoupon={paywallCoupon}
          refetchCoupons={refetchCoupons}
          isDrawerVisible={isDrawerVisible}
          toggleDrawer={toggleDrawer}
        />
      )}
      <DataTable.Actions>
        {menuItems.map(({ key, label, hasDanger, onClick }) => (
          <DataTable.Actions.Item
            key={key}
            hasDanger={hasDanger}
            onClick={onClick}
          >
            {label}
          </DataTable.Actions.Item>
        ))}
      </DataTable.Actions>
      {canDestroy && (
        <ConfirmationModal
          title={t("settings.paywall_coupons.alerts.confirmation.delete_title")}
          confirmText={t("confirm")}
          cancelText={t("cancel")}
          confirmVariant="danger"
          isOpen={isConfirmationOpen}
          disabled={isLoading}
          onCancel={() => setIsConfirmationOpen(false)}
          onClose={() => setIsConfirmationOpen(false)}
          onConfirm={async () => {
            await deleteCoupon();
            setIsConfirmationOpen(false);
          }}
        >
          <p>{t("settings.paywall_coupons.alerts.confirmation.delete")}</p>
        </ConfirmationModal>
      )}
    </>
  );
};

Actions.propTypes = {
  paywallCoupon: PropTypes.object.isRequired,
  refetchCoupons: PropTypes.func.isRequired,
};
