export const botBuilderNavPaths = {
  customize: ({ botPublicId }: { botPublicId: string }) =>
    `/settings/ai-agents/edit/${botPublicId}/customize`,
  audience: ({ botPublicId }: { botPublicId: string }) =>
    `/settings/ai-agents/edit/${botPublicId}/audience`,
  behavior: ({ botPublicId }: { botPublicId: string }) =>
    `/settings/ai-agents/edit/${botPublicId}/behavior`,
  knowledge: ({ botPublicId }: { botPublicId: string }) =>
    `/settings/ai-agents/edit/${botPublicId}/knowledge`,
};
