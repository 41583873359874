import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import avatars from "@circle-assets/images/inbox-avatars.png";
import { usePunditUserContext } from "@circle-react/contexts";
import { botBuilderNavPaths } from "@circle-react/helpers/urlHelpers/botBuilderNavPaths";
import { Button } from "@circle-react-uikit/Button";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { LandingPage } from "../CommunityBot/LandingPage";
import { useCreateAgent, useIndexAgents } from "../CommunityBot/agentsQueries";

export const EmptyState = () => {
  const history = useHistory();
  const agentsQuery = useIndexAgents({ currentPage: 1 });
  const shouldDisplaySetupAgentCTA =
    !agentsQuery?.isLoading && !agentsQuery?.data?.count;
  const { currentCommunity } = usePunditUserContext();

  const createAgentMutation = useCreateAgent();

  const handleCreateAgent = () => {
    createAgentMutation.mutate(undefined, {
      onSuccess: data => {
        history.push(
          botBuilderNavPaths.customize({ botPublicId: data.id.toString() }),
        );
      },
    });
  };

  const agents = agentsQuery?.data?.records;
  const hasNoAgents = !agents?.length;

  const isFeatureDisabled = !currentCommunity?.ai_agents_feature_flag_enabled;
  const shouldShowLandingPage = isFeatureDisabled && hasNoAgents;

  if (agentsQuery.isLoading) {
    return <Loader />;
  }

  if (shouldShowLandingPage) {
    return <LandingPage />;
  }

  return (
    <div className="bg-secondary flex h-full w-full items-center justify-center text-center">
      <div className="flex w-[31rem] flex-col items-center gap-4">
        <img
          alt=""
          loading="lazy"
          src={avatars}
          className="mb-2 object-cover"
        />
        <div className="flex flex-col items-center gap-2">
          <Typography.LabelLg weight="semibold">
            {t("community_inbox.title")}
          </Typography.LabelLg>
          <Typography.BodyMd color="text-default">
            {t("community_inbox.description")}
          </Typography.BodyMd>
        </div>
        {shouldDisplaySetupAgentCTA && (
          <Button variant="circle" onClick={handleCreateAgent} large>
            {t("community_inbox.setup_agent_cta")}
          </Button>
        )}
      </div>
    </div>
  );
};
