import { useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { Controller, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { PixLimit } from "@circle-react/helpers/paywallCheckoutHelpers";
import { PaymentMethodType } from "@circle-react/helpers/paywallCheckoutHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { PaymentMethodOption } from "./PaymentMethodOption";

export const PaymentMethodSelectorForm = () => {
  const { control, setValue } = useFormContext();
  const { getSelectedPrice, amountDue } = usePaywallCheckoutContext();
  const price = getSelectedPrice();

  const hasOnePaymentMethodAvailable =
    price?.payable_payment_methods?.length === 1;
  const isAmountDueOutsidePixLimits =
    amountDue < PixLimit.MINIMUM || amountDue > PixLimit.MAXIMUM;

  useEffect(() => {
    if (hasOnePaymentMethodAvailable || isAmountDueOutsidePixLimits) {
      setValue("payment_method_type", PaymentMethodType.CARD);
    }
  }, [hasOnePaymentMethodAvailable, amountDue]);

  if (hasOnePaymentMethodAvailable || isAmountDueOutsidePixLimits) return null;

  return (
    <Controller
      control={control}
      name="payment_method_type"
      render={({ field: { onChange, value } }) => (
        <RadioGroup value={value} onChange={onChange}>
          <div className="flex flex-row space-x-4">
            <PaymentMethodOption
              value={PaymentMethodType.CARD}
              className="basis-1/2"
            >
              <Icon type="credit-card" size={16} />
              <Typography.LabelMd>
                {t("paywall_checkout.payment_method_type.card")}
              </Typography.LabelMd>
            </PaymentMethodOption>
            <PaymentMethodOption
              value={PaymentMethodType.PIX}
              className="basis-1/2"
            >
              <Icon type="20-pix" viewBox="0 0 24 24" size={24} />
              <Typography.LabelMd>
                {t("paywall_checkout.payment_method_type.pix")}
              </Typography.LabelMd>
            </PaymentMethodOption>
          </div>
        </RadioGroup>
      )}
    />
  );
};
