import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { workflows } from "@circle-react/helpers/urlHelpers/internalApi/workflows";
import { BaseResource } from "../../BaseResource";

export const RecurringEventSettingResource = ({
  resourceId,
}: {
  resourceId: number;
}) => {
  const { data: recurringEventSetting, isLoading } = useQuery<
    { name: string } | undefined
  >(
    workflows.recurringEventSettings.show({
      id: String(resourceId),
    }),
  );

  return (
    <BaseResource
      label={t("settings.workflows.view.resources.event")}
      value={recurringEventSetting?.name}
      isLoading={isLoading}
    />
  );
};
