import { t } from "@/i18n-js/instance";
import { useBroadcastReport } from "@circle-react-shared/Emails/BroadcastReport/BroadcastReportContext";
import { StatBox } from "./StatBox";

const localeNamespace = "settings.workflows.view";

export function EmailInlineStats() {
  const { data } = useBroadcastReport();

  if (!data) {
    return null;
  }

  const {
    delivered,
    opened,
    clicked,
    recipient_count: recipientCount,
    sent,
  } = data;

  const total = sent || recipientCount;

  return (
    <div className="flex flex-col gap-y-2">
      <div className="border-hover bg-secondary mt-3 overflow-hidden rounded-lg border">
        <div className="divide-primary flex items-center justify-around divide-x py-3 text-center">
          <StatBox
            total={total}
            label={t([localeNamespace, "delivered"])}
            value={delivered}
          />
          <StatBox
            total={total}
            label={t([localeNamespace, "opened"])}
            value={opened}
          />
          <StatBox
            total={total}
            label={t([localeNamespace, "clicked"])}
            value={clicked}
          />
        </div>
      </div>
    </div>
  );
}
