import classNames from "classnames";
import { isNull } from "lodash";
import { NavLink } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { Avatar } from "@/react/components/Modals/MessagingModal/LeftSidebar/List/Item/Avatar";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { Body } from "./Body";

interface ChatItemProps {
  chat: any;
  path: string;
  isBotType?: boolean;
  shouldHightlightChat?: boolean;
  isUnread?: boolean;
}
export const ChatItem = ({
  chat,
  path,
  isBotType = false,
  shouldHightlightChat = false,
  isUnread = false,
}: ChatItemProps) => {
  const {
    community_bot_profile: botProfile,
    community_member: communityMember,
    last_message: lastMessage = {},
    id,
    ai_snoozed: isAiSnoozed,
  } = chat;

  const author = isBotType ? botProfile : communityMember;
  const shouldShowDefaultBotAvatar = isBotType && !author.avatar_url;

  if (isNull(lastMessage)) {
    return null;
  }
  return (
    <div
      className={classNames({
        "border-secondary-dark mx-2 border-b last:border-none":
          !shouldHightlightChat,
      })}
    >
      <NavLink
        className={classNames(
          "hover:bg-secondary-dark -mx-2 block cursor-pointer py-[9px]",
          shouldHightlightChat
            ? "bg-[#674FD90A]/[.04] px-6"
            : "rounded-lg px-4",
        )}
        activeClassName="bg-secondary-dark"
        key={id}
        to={path}
        data-testid="chat-room-dm-list"
      >
        {shouldHightlightChat && (
          <div className="-mt-1">
            <Icon type="20-ai-bot-gradient" size={20} className="mt-1" />
            <Typography.LabelXxs
              weight="semibold"
              as="span"
              textTransform="uppercase"
              color="bg-gradient-to-t from-[#6C47D2] to-[#516DF1] bg-clip-text text-transparent"
            >
              {t("messaging.agent")}
            </Typography.LabelXxs>
          </div>
        )}
        <div className="flex w-full items-start justify-between">
          <div className="flex w-full items-center space-x-3">
            {shouldShowDefaultBotAvatar ? (
              <Icon
                type="36-ai-bot"
                size={36}
                className="!h-[36px] !w-[36px] shrink-0"
              />
            ) : (
              <Avatar isDirect otherParticipants={[author]} />
            )}

            <Body
              isDirect
              chatRoomName={author.name}
              timestamp={dateStringToTimeAgo(lastMessage.created_at, {
                format: "short",
              })}
              lastMessage={lastMessage}
              isUnread={isUnread}
              isAiSnoozed={isAiSnoozed}
            />
          </div>
        </div>
      </NavLink>
    </div>
  );
};
