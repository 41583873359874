import { t } from "@/i18n-js/instance";
import type { PaywallCheckoutPreview } from "@circle-react/types";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { CheckoutFormSection } from "../CheckoutFormSection";
import { CheckoutPreviewEntry } from "../CheckoutPreviewEntry";

export interface ProratedCreditPreviewProps {
  preview: PaywallCheckoutPreview;
}

export const ProratedCreditPreview = ({
  preview,
}: ProratedCreditPreviewProps) => {
  const {
    prorated_credit_description: proratedCreditDescription,
    prorated_credit_amount: proratedCreditAmount,
    formatted_prorated_credit_amount: formattedProratedCreditAmount,
  } = preview.first_payment;

  if (!proratedCreditDescription || proratedCreditAmount === 0) {
    return null;
  }

  return (
    <CheckoutFormSection className="gap-y-1 border-t">
      <CheckoutPreviewEntry
        label={t("paywall_checkout.prorated_credit")}
        labelWeight="semibold"
        value={formattedProratedCreditAmount}
        valueColor={
          proratedCreditAmount < 0 ? "text-green-600" : "text-primary"
        }
        dataTestId="checkout-form-preview-prorated-credit"
      />
      <Typography.LabelXs>{proratedCreditDescription}</Typography.LabelXs>
    </CheckoutFormSection>
  );
};
