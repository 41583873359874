import { memo } from "react";
import classNames from "classnames";
import { Redirect, useLocation } from "react-router-dom";
import { DeactivateAccountConfirmationModal } from "@/react/components/SettingsApp/DeactivateAccountConfirmationModal";
import { isUserSignedIn } from "@/react/helpers/punditUserHelpers";
import { usePlanPageVariant } from "@/react/hooks/usePlanPageVariant";
import { useBoolean } from "@/react/hooks/utils/useBoolean";
import { SettingsNav } from "@circle-react/components/shared/SettingsNav";
import { usePunditUserContext } from "@circle-react/contexts";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";

const Component = () => {
  const [shouldShowDeactivateModal, toggleDeactivateModal] = useBoolean(false);
  const { pathname } = useLocation();

  const isCommunityInboxPage = pathname.includes(
    settingsNavPathV3.community_inbox.index,
  );

  const punditUserContext = usePunditUserContext();
  const { isLoading } = punditUserContext;
  const { isVariantAEnabled, isVariantBEnabled } = usePlanPageVariant();

  if (isLoading) {
    return <SkeletonLoader variant="community-sidebar-v2" />;
  }

  if (!isUserSignedIn(punditUserContext)) {
    // Redirect to login page
    return <Redirect to="/" />;
  }

  return (
    <>
      <DeactivateAccountConfirmationModal
        show={shouldShowDeactivateModal}
        onClose={toggleDeactivateModal}
      />
      <div className="sidebar sidebar--left" data-testid="sidebar-left">
        <div
          className={classNames(
            "fixed h-full",
            isCommunityInboxPage ? "!max-w-[24rem]" : "!max-w-[23rem]",
            isVariantAEnabled || isVariantBEnabled ? "w-full" : "",
          )}
          data-draggable="false"
        >
          <SettingsNav />
        </div>
      </div>
    </>
  );
};

export const LeftSidebar = memo(Component);
