import type { ReactNode } from "react";
import { LabelMd } from "@/react/components/shared/uikit/Typography/components/Label/LabelMd";
import { LabelSm } from "@/react/components/shared/uikit/Typography/components/Label/LabelSm";
import { LabelXs } from "@/react/components/shared/uikit/Typography/components/Label/LabelXs";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export interface ChangePlanFormLabelGroupProps {
  className?: string;
  direction?: "row" | "col";
  detailsClassName?: string;
  label: ReactNode;
  labelSize?: string;
  labelWeight?: string;
  details?: ReactNode;
  detailsSize?: string;
  detailsWeight?: string;
  detailsColored?: boolean;
  description?: string;
  descriptionSize?: string;
  descriptionColor?: string;
  enableCursor?: boolean;
  labelColor?: string;
}

export const ChangePlanFormLabelGroup = ({
  className,
  direction = "row",
  detailsClassName,
  label,
  labelSize = "md",
  labelWeight,
  details,
  detailsSize = "md",
  detailsWeight,
  detailsColored = false,
  description,
  descriptionSize = "sm",
  descriptionColor,
  enableCursor = false,
  labelColor = "text-base",
}: ChangePlanFormLabelGroupProps) => {
  const Label = labelSize === "md" ? LabelMd : LabelSm;
  const Details = detailsSize === "md" ? LabelMd : LabelSm;
  const Description = descriptionSize === "sm" ? LabelSm : LabelXs;

  return (
    <div
      className={classNames(
        {
          ["flex flex-row justify-between space-x-4"]: direction === "row",
          ["flex flex-col space-y-2"]: direction === "col",
          ["cursor-pointer"]: enableCursor,
        },
        className,
      )}
    >
      <div className="space-y-1">
        <div>
          <Label weight={labelWeight} color={labelColor}>
            {label}{" "}
          </Label>
        </div>
        {description && (
          <div className="leading-3 text-gray-500">
            <Description color={descriptionColor}>{description}</Description>
          </div>
        )}
      </div>
      {details && (
        <div
          className={classNames("whitespace-nowrap", detailsClassName, {
            ["text-v2-success"]: detailsColored,
          })}
        >
          <Details
            weight={detailsWeight}
            color={detailsColored ? "text-v2-success" : undefined}
          >
            {details}
          </Details>
        </div>
      )}
    </div>
  );
};
