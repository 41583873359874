import { createContext, useContext } from "react";
import type { PropsWithChildren } from "react";
import type {
  FieldArrayPath,
  FieldArrayWithId,
  FieldValues,
} from "react-hook-form";
import { usePaywallBlockContext } from "../../Providers/PaywallBlockProvider";
import { usePaywallForm } from "./hooks/usePaywallForm";

export interface PaywallsEditFormProviderProp {
  blockDataPath: string;

  // usePaywallForm object
  fields?: FieldArrayWithId<FieldValues, FieldArrayPath<FieldValues>, "_id">[];
  paywallsListQuery?: any;
  paywallsAvailableList?: Array<any>;
  shouldDisableAddPaywall?: boolean;
  onClickAddPaywall?: () => void;
}

const PaywallsEditForm = createContext<PaywallsEditFormProviderProp>({
  blockDataPath: "",
});

PaywallsEditForm.displayName = "PaywallsEditFormProvider";

export const PaywallsEditFormProvider = ({
  children,
  blockDataPath,
}: PropsWithChildren<PaywallsEditFormProviderProp>) => {
  const paywallForm = usePaywallForm(blockDataPath);
  const { previewMode, setPreviewMode } = usePaywallBlockContext();

  const valueToStore = {
    blockDataPath,
    ...paywallForm,

    previewMode,
    setPreviewMode,
  };

  return (
    <PaywallsEditForm.Provider value={valueToStore}>
      {children}
    </PaywallsEditForm.Provider>
  );
};

export const usePaywallsEditFormContext = () =>
  useContext<any>(PaywallsEditForm);
