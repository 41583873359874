import { t } from "@/i18n-js/instance";
import { Filter, useFilterUrl } from "@circle-react-shared/Filter";
import { InputFilter } from "@circle-react-shared/Filter/FilterTypes/InputFilter";

export const IdFilter = ({
  isInitialOpen,
  changeUrl = true,
  value,
  queryParamsKey,
  localeRoot,
}: any) => {
  const { queryParams, updateUrl } = useFilterUrl();
  const processorIdString = value ?? queryParams[queryParamsKey];

  return (
    <Filter
      chip={t([localeRoot, "chip"])}
      title={t([localeRoot, "title"])}
      selectedText={value || queryParams[queryParamsKey]}
      renderSelectedText={() => processorIdString}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        updateUrl("page", 1);
        changeUrl && updateUrl(queryParamsKey, value);
      }}
      shouldShowRemoveButton
    >
      <InputFilter
        name={queryParamsKey}
        placeholder={t([localeRoot, "input_placeholder"])}
        autoFocus
        autoComplete="off"
      />
    </Filter>
  );
};
