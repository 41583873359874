import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { StandardLayoutHeaderWithPortal } from "@/react/components/Layout/StandardLayoutV2/StandardLayoutHeader/StandardLayoutHeaderWithPortal";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { Icon } from "@circle-react/components/shared/Icon";
import { usePunditUserContext } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Button } from "@circle-react-shared/uikit/Button";

export interface BreadcrumbProps {
  hidden?: boolean;
  backLink?: string;
  backLinkText?: string;
  customRightContent?: React.ReactNode;
}

export const Breadcrumb = ({
  hidden = false,
  backLink = "",
  backLinkText = t("back"),
  customRightContent,
}: BreadcrumbProps) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const history = useHistory();

  const onClickBackHandler = () => {
    history.push(backLink);
  };

  if (hidden || !backLink) {
    return null;
  }

  return (
    <StandardLayoutHeaderWithPortal
      leftContent={
        <SpaceHeaderTitleText>
          <Button
            type="button"
            variant="text"
            extraLarge
            onClick={onClickBackHandler}
            className={classNames("flex items-center gap-2", {
              // This css rule will override the pointer-events-none class
              // that is cascaded down from the StandardLayoutHeader component
              // We want to allow pointer events on the back link when VOM masquerading
              "!pointer-events-auto": isViewOnlyMasquerading,
            })}
          >
            <Icon type="20-arrow-left" size={20} />
            {backLinkText}
          </Button>
        </SpaceHeaderTitleText>
      }
      rightContent={customRightContent || null}
    />
  );
};
