export const Avatar = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2515_109087)">
      <rect width="32" height="32" fill="url(#paint0_linear_2515_109087)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 22.5C15.25 22.9142 15.5858 23.25 16 23.25C16.4142 23.25 16.75 22.9142 16.75 22.5C16.75 19.3244 19.3244 16.75 22.5 16.75C22.9142 16.75 23.25 16.4142 23.25 16C23.25 15.5858 22.9142 15.25 22.5 15.25C19.3244 15.25 16.75 12.6756 16.75 9.5C16.75 9.08579 16.4142 8.75 16 8.75C15.5858 8.75 15.25 9.08579 15.25 9.5C15.25 12.6756 12.6756 15.25 9.5 15.25C9.08579 15.25 8.75 15.5858 8.75 16C8.75 16.4142 9.08579 16.75 9.5 16.75C12.6756 16.75 15.25 19.3244 15.25 22.5Z"
        fill="url(#paint1_linear_2515_109087)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2515_109087"
        x1="16"
        y1="0"
        x2="16"
        y2="32"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#516DF1" />
        <stop offset="1" stopColor="#6C47D2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2515_109087"
        x1="16"
        y1="22.5"
        x2="16"
        y2="9.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7F9FF" />
        <stop offset="1" stopColor="#C4AFFF" />
      </linearGradient>
      <clipPath id="clip0_2515_109087">
        <rect width="32" height="32" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
