import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { EnableModalComponent } from "./EnableModalComponent";

const EnableModal = ModalManager.create(({ agent }: { agent: AgentProp }) => (
  <EnableModalComponent agent={agent} />
));

export const useEnableModal = (agent: AgentProp) =>
  useModal(EnableModal, { agent });
