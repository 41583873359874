import { useMemo } from "react";
import { compact, isEmpty } from "lodash";
import { useQuery } from "react-query";
import { eventApi } from "@circle-react/api";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";

export const useFetchEvents = ({ ids }: any) =>
  useQuery(
    ["workflows", "events", "list", ids],
    () => eventApi.listEvents({ params: { ids } }),
    {
      enabled: !isEmpty(compact(ids)),
    },
  );

export const useEventNameMap = (filterParam?: BaseFilter) => {
  const eventIds = filterParam?.value;
  const { data: events = {} } = useFetchEvents({ ids: eventIds });

  return useMemo(
    () =>
      events.records?.reduce((accumulator: any, record: any) => {
        accumulator[record.id] = record.name;
        return accumulator;
      }, {}) || {},
    [events],
  );
};
