import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { WORKFLOW_TYPE } from "@circle-react/components/SettingsApp/Workflows/constants";
import { Icon } from "@circle-react/components/shared/Icon";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";

const i18nRoot = "settings.workflows.templates";

const colorMap = {
  [WORKFLOW_TYPE.DYNAMIC]: {
    color: "text-[#9F15E0]",
    backgroundColor: "bg-[#9F15E00F]",
  },
  [WORKFLOW_TYPE.BULK_ACTION]: {
    color: "text-[#0A7A9D]",
    backgroundColor: "bg-[#0A7A9D14]",
  },
  default: {
    color: "text-default",
    backgroundColor: "bg-tertiary",
  },
};

export const TypeBadge = ({ type = "" }) => {
  const { color, backgroundColor } = colorMap[type] || colorMap.default;

  return (
    <TippyV2
      content={`${t([i18nRoot, "workflow_type"])}: ${t([i18nRoot, type], {
        defaultValue: type,
      })}`}
      placement="top"
    >
      <span
        className={classNames(
          "inline-flex items-center gap-1 rounded py-0.5 pl-1 pr-1.5",
          backgroundColor,
        )}
      >
        <span className="flex">
          <Icon
            type="12-lightning"
            size={12}
            className={classNames("!h-3 !w-3", color)}
          />
        </span>

        <span
          className={classNames(
            "flex items-center text-[0.7rem] font-semibold uppercase",
            color,
          )}
        >
          {t([i18nRoot, type], {
            defaultValue: type,
          })}
        </span>
      </span>
    </TippyV2>
  );
};

TypeBadge.propTypes = {
  type: PropTypes.string,
};
