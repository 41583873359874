import { PropTypes } from "prop-types";
import { t } from "@/i18n-js/instance";
import { CheckoutUrlWithCouponSelector } from "@/react/components/Paywalls/Admin/PaywallsAdmin/FormModal/DetailsTab/CheckoutUrlWithCouponSelector";
import { SupportedCurrencyChooser } from "@circle-react/components/SupportedCurrencyChooser";
import { retrievePaywallCheckoutHostname } from "@circle-react/helpers/paywallCheckoutHelpers";
import { Form } from "@circle-react-uikit/Form";
import { TabTitle } from "../TabTitle";
import { useDetailsTab } from "./hooks/useDetailsTab";
import "./style.scss";

export const DetailsTab = ({ requiresCurrency, canChangeCurrency }) => {
  const { copyWithPrefix } = useDetailsTab();

  const renderCopyCheckoutComponent = () => (
    <button type="button" onClick={copyWithPrefix} className="anchor-copy-link">
      {t("copy_link")}
    </button>
  );

  return (
    <div className="details-tab__container">
      <TabTitle title={t("settings.paywalls.modal.tabs.details.title")} />
      <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <Form.Item
          name="display_name"
          translationRoot="settings.paywalls.modal.tabs.details"
          hideBorder
          tooltipText={t(
            "settings.paywalls.modal.tabs.details.display_name_tooltip",
          )}
          hideDescription
          fullWidth={false}
          rules={{
            required: t(
              "settings.paywalls.modal.tabs.details.alerts.display_name_is_required",
            ),
            maxLength: 255,
          }}
          hidePlaceholder
        >
          <Form.Input />
        </Form.Item>
        <Form.Item
          name="name"
          translationRoot="settings.paywalls.modal.tabs.details"
          hideBorder
          fullWidth={false}
          tooltipText={t("settings.paywalls.modal.tabs.details.name_tooltip")}
          hideDescription
          hidePlaceholder
          rules={{
            maxLength: 255,
          }}
        >
          <Form.Input />
        </Form.Item>
      </div>

      <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <Form.Item
          name="subtitle"
          translationRoot="settings.paywalls.modal.tabs.details"
          hideBorder
          fullWidth={false}
          tooltipText={t(
            "settings.paywalls.modal.tabs.details.subtitle_tooltip",
          )}
          hideDescription
          hidePlaceholder
          rules={{
            maxLength: 70,
          }}
        >
          <Form.Input maxLength="70" />
        </Form.Item>

        {requiresCurrency && (
          <SupportedCurrencyChooser
            formItemName="currency_id"
            formItemTranslationRoot="settings.paywalls.modal.tabs.details"
            formItemRules={{
              required: t(
                "settings.paywalls.modal.tabs.details.alerts.currency_is_required",
              ),
            }}
            formItemTooltipText={t(
              "settings.paywalls.modal.tabs.details.currency_id_tooltip",
            )}
            disabled={!canChangeCurrency}
          />
        )}
      </div>

      <div className="details-tab__container__checkout-path__container">
        <Form.Item
          name="checkout_path"
          translationRoot="settings.paywalls.modal.tabs.details"
          hideBorder
          fullWidth={false}
          hidePlaceholder
          rules={{
            required: t(
              "settings.paywalls.modal.tabs.details.alerts.checkout_path_is_required",
            ),
            maxLength: 255,
          }}
          description={renderCopyCheckoutComponent()}
          variant="checkout-path"
        >
          <Form.Input
            prefix={
              <p className="details-tab__container__checkout-path__container__input-prefix">
                {retrievePaywallCheckoutHostname()}
              </p>
            }
          />
        </Form.Item>
      </div>
      <CheckoutUrlWithCouponSelector />
      <Form.Item
        name="description"
        translationRoot="settings.paywalls.modal.tabs.details"
        hideBorder
        fullWidth={false}
        hideDescription
      >
        <Form.TextArea />
      </Form.Item>
    </div>
  );
};

DetailsTab.propTypes = {
  requiresCurrency: PropTypes.bool.isRequired,
  canChangeCurrency: PropTypes.bool.isRequired,
};
