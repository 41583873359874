import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { ScheduleDateTimePicker } from "../ScheduleDateTimePicker";

const i18nRoot = "settings.workflows.one_time_schedule_modal";

export const OneTimeFormContent = () => {
  const { watch } = useFormContext();
  const isFormValid = Boolean(watch("scheduler.last_run_at"));
  const lastRunAt = watch("scheduler.last_run_at");

  return (
    <>
      <Modal.Body>
        <Typography.LabelMd weight="semibold">
          {t([i18nRoot, "date_and_time"])}
        </Typography.LabelMd>
        <div className="flex flex-col">
          <Form.Item
            name="scheduler.last_run_at"
            hideDescription
            hideBorder
            fullWidth
            placeholder=" "
          >
            <ScheduleDateTimePicker
              name="scheduler.last_run_at"
              datePlaceholder={t([i18nRoot, "date"])}
              timePlaceholder={t([i18nRoot, "time"])}
              minDate={new Date()}
              dataTestId="last_run_at"
            />
          </Form.Item>
        </div>
        {lastRunAt && (
          <div className="flex flex-col gap-y-4">
            <Typography.LabelSm weight="medium">
              {t(`${i18nRoot}.summary`, {
                runAt: formattedDateTime({
                  dateTime: new Date(lastRunAt),
                  format: "date_time_with_short_timezone",
                }),
              })}
            </Typography.LabelSm>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="flex w-full items-center justify-end">
        <Button variant="circle" disabled={!isFormValid}>
          {t(`${i18nRoot}.submit`)}
        </Button>
      </Modal.Footer>
    </>
  );
};
