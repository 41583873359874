import { useState } from "react";
import { compact } from "lodash";
import { t } from "@/i18n-js/instance";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { Filter, useFilterUrl } from "@circle-react-shared/Filter";
import { Button } from "@circle-react-uikit/Button";
import { DateRangePicker } from "./DateRangePicker";
import {
  DEFAULT_QUERY_PARAMS_KEY,
  LAST_SEVEN_DAYS,
  LOCALE_ROOT,
} from "./constants";

export const DateFilterComponent = ({
  isInitialOpen,
  changeUrl = true,
  value,
  queryParamsKey = DEFAULT_QUERY_PARAMS_KEY,
  localeRoot = LOCALE_ROOT,
}: any) => {
  const { queryParams, updateUrl } = useFilterUrl();
  const [currentOption, setCurrentOption] = useState(LAST_SEVEN_DAYS);
  const primaryButtonVariant = useButtonVariant();

  const lteValue = Number(queryParams[queryParamsKey.lte]);
  const gteValue = Number(queryParams[queryParamsKey.gte]);

  const lteValueDate = new Date(lteValue);
  const gteValueDate = new Date(gteValue);

  const selectedText = compact([
    isNaN(gteValueDate.getTime())
      ? null
      : formattedDateTime({
          dateTime: gteValueDate,
          format: dateFormats.short_date,
        }),
    isNaN(lteValueDate.getTime())
      ? null
      : formattedDateTime({
          dateTime: lteValueDate,
          format: dateFormats.short_date,
        }),
  ]).join("-");

  const onApply = (value: any) => {
    const {
      [queryParamsKey.default]: startDateType,
      [queryParamsKey.lte]: lteValue,
      [queryParamsKey.gte]: gteValue,
    } = value ?? {};

    if (changeUrl) {
      updateUrl("page", 1);
      updateUrl(queryParamsKey.default, startDateType);
      updateUrl(queryParamsKey.lte, lteValue);
      updateUrl(queryParamsKey.gte, gteValue);
    }
    setCurrentOption(startDateType);
  };

  return (
    <Filter
      chip={t([localeRoot, "chip"])}
      title={t([localeRoot, "title"])}
      selectedText={value || selectedText}
      isInitialOpen={isInitialOpen}
      renderSelectedText={() => selectedText}
      onApply={onApply}
      shouldShowRemoveButton
    >
      <DateRangePicker
        className="flex min-w-[18rem] flex-col gap-y-4 p-4"
        defaultQueryParams={queryParamsKey}
        defaultValues={{
          [queryParamsKey.default]: currentOption || LAST_SEVEN_DAYS,
          [queryParamsKey.lte]: lteValueDate?.getTime(),
          [queryParamsKey.gte]: gteValueDate?.getTime(),
        }}
      >
        <div className="flex flex-col gap-y-2">
          <DateRangePicker.DropdownOptions />
          <DateRangePicker.RangePicker />
        </div>
        <Button variant={primaryButtonVariant} type="submit" full>
          {t("filters.apply")}
        </Button>
      </DateRangePicker>
    </Filter>
  );
};
