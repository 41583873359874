import { Icon } from "@circle-react/components/shared/Icon";

export const EventTypeIcon = ({ type = "single" }) => {
  if (type === "single")
    return (
      <Icon size={16} type="16-calendar" className="text-default -mt-1 !w-4" />
    );

  return (
    <Icon
      size={16}
      type="16-calendar-recurring"
      className="text-default -mt-0.5 !w-4"
    />
  );
};
