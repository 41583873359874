import { t } from "@/i18n-js/instance";

export const DEFAULT_BANNER_BLOCK = {
  type: "banner",
  hidden: false,
  data: {
    heading: t("courses.lockscreen.default.banner_heading"),
    description: t("courses.lockscreen.default.banner_description"),
    button_label: t("courses.lockscreen.default.buy_now"),
    button_link: "",
    background_image: "",
    overlay_color: "#191B1F",
    heading_color: "#FFFFFF",
    text_color: "#FFFFFF",
    button_background_color: "#2B2E33",
    button_text_color: "#FFFFFF",
  },
};

export const DEFAULT_DESCRIPTION_BLOCK = {
  type: "description",
  hidden: false,
  data: {
    description_tiptap: {
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: t("courses.lockscreen.default.description"),
            },
          ],
        },
      ],
    },
    sgids_to_object_map: {},
    inline_attachments: [],
  },
};

export const DEFAULT_LESSONS_BLOCK = {
  type: "lessons",
  hidden: true,
  data: {
    sections: [],
  },
};

export const DEFAULT_CALL_TO_ACTION_BLOCK = {
  type: "call_to_action",
  hidden: false,
  data: {
    // Call to action has the same data shape as the banner block
    ...DEFAULT_BANNER_BLOCK.data,
    overlay_color: "#2C3341",
    heading: t("courses.lockscreen.default.call_to_action_heading"),
    description: t("courses.lockscreen.default.call_to_action_description"),
  },
};

export const DEFAULT_LEGACY_LOCK_SCREEN_BLOCK = {
  type: "legacy_lock_screen",
  hidden: true,
  data: {
    locked_page_heading: "",
    locked_page_description: {
      plain_text: "",
      trix_html: "",
      rendered_html_with_layout: "",
    },
    locked_button_url: "",
    locked_button_label: "",
  },
};

export const DEFAULT_PAYWALLS_BLOCK = {
  type: "paywalls",
  hidden: false,
  data: {
    paywalls: [],
  },
};

export const DEFAULT_BLOCKS = [
  DEFAULT_BANNER_BLOCK,
  DEFAULT_DESCRIPTION_BLOCK,
  DEFAULT_LESSONS_BLOCK,
  DEFAULT_PAYWALLS_BLOCK,
  DEFAULT_CALL_TO_ACTION_BLOCK,
];
