import { useMutation, useQuery } from "react-query";
import { useAddBookmarkModal } from "@/react/components/Modals/AddBookmarkModal";
import {
  reactQueryDelete,
  reactQueryPost,
  reactQueryPut,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import type { ApiError } from "@circle-react/config/CustomErrors";
import type { Space } from "@circle-react/types";
import type { Bookmark } from "@circle-react/types/Bookmarks";

export interface UseBookmarksProps {
  space?: Space;
  bookmark?: Bookmark;
  onAfterCreateOrUpdate?: () => void;
}

export const useBookmarks = ({
  space,
  bookmark,
  onAfterCreateOrUpdate,
}: UseBookmarksProps) => {
  const addBookmarkModal = useAddBookmarkModal();

  const {
    data,
    isLoading,
    refetch: refetchBookmarks,
  } = useQuery<
    {
      records: Bookmark[];
    },
    ApiError
  >(
    internalApi.spaces.bookmarks.index({
      spaceId: space?.id,
      params: { per_page: 40 },
    }),
    {
      enabled: !!space?.id,
      refetchOnWindowFocus: false,
    },
  );

  const records = data?.records || [];

  const afterCreateOrUpdate = async () => {
    onAfterCreateOrUpdate?.();
    await refetchBookmarks();
    await addBookmarkModal.hide();
  };

  const { mutate: createBookmark, isLoading: isCreatingBookmark } = useMutation<
    void,
    ApiError,
    Bookmark
  >(
    data =>
      reactQueryPost(
        internalApi.spaces.bookmarks.create({
          spaceId: space?.id,
        }),
        { bookmark: data },
      ),
    {
      onSuccess: afterCreateOrUpdate,
    },
  );

  const { mutate: updateBookmark, isLoading: isUpdatingBookmark } = useMutation<
    void,
    ApiError,
    Bookmark
  >(
    data =>
      reactQueryPut(
        internalApi.spaces.bookmarks.update({
          spaceId: space?.id,
          id: bookmark?.id,
        }),
        { bookmark: data },
      ),
    {
      onSuccess: afterCreateOrUpdate,
    },
  );

  const { mutate: updateBookmarksOrder } = useMutation<
    void,
    ApiError,
    { bookmarks_order: string[] }
  >(({ bookmarks_order }) =>
    reactQueryPut(internalApi.spaces.update({ id: space?.id }), {
      space: {
        bookmarks_order,
      },
    }),
  );

  const { mutate: deleteBookmark, isLoading: isDeleting } = useMutation(
    () =>
      reactQueryDelete(
        internalApi.spaces.bookmarks.destroy({
          spaceId: space?.id,
          id: bookmark?.id,
        }),
      ),
    {
      onSuccess: refetchBookmarks,
    },
  );

  const hasBookmarsAdded = Boolean(records?.length);
  const shouldShowBookmarks = Boolean(space && hasBookmarsAdded);

  return {
    records,
    isLoading,
    createBookmark,
    isCreatingBookmark,
    updateBookmark,
    isUpdatingBookmark,
    updateBookmarksOrder,
    deleteBookmark,
    isDeleting,
    shouldShowBookmarks,
  };
};
