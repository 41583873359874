import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { reactQueryDelete } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useEmbeddableChatApi } from "@/react/hooks/embeddable_chat/useEmbeddableChatsApi";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import {
  usePunditUserContext,
  useSpaceGroupsContext,
  useSpaceResource,
  useSpacesContext,
} from "@circle-react/contexts";
import {
  canManageSpace,
  isChatSpace,
  isPrivateSpace,
  isSecretSpace,
} from "@circle-react/helpers/spaceHelpers";
import { useLinkToRoot } from "@circle-react/hooks/useLinkToRoot";
import { SecureConfirmationModal } from "@circle-react-shared/ModalsV2/SecureConfirmationModal";
import { useToast } from "@circle-react-uikit/ToastV2";

const i18nRoot = "spaces.leave";

export const LeaveSpaceConfirmationModal = ({ show, onClose, space }) => {
  const { goToCommunityRootPath } = useLinkToRoot();
  const { currentCommunityMember } = usePunditUserContext();
  const {
    actions: { refresh: refreshSpaceGroups },
  } = useSpaceGroupsContext();
  const {
    actions: { refresh: refreshSpaces },
  } = useSpacesContext();
  const { space: spaceResource = {}, refetch: refetchSpaceResource } =
    useSpaceResource({
      spaceId: space?.id,
    });
  const { error, success } = useToast();
  const { refetchChatRoom } = useEmbeddableChatApi(
    spaceResource.chat_room_uuid,
  );

  const refreshData = async () => {
    refreshSpaceGroups();
    refreshSpaces();
    if (!isSecretSpace(space)) {
      refetchSpaceResource();
    }
    isChatSpace(space) && refetchChatRoom();
  };

  const { mutate: leaveSpaceMutation, isLoading: isLeaving } = useMutation(
    () =>
      reactQueryDelete(
        internalApi.spaceMembers.leaveSpace({
          spaceId: space?.id,
          memberId: currentCommunityMember?.id,
        }),
      ),
    {
      onSuccess: () => {
        success(t("leave_space_success", { name: space.name }), {
          duration: "short",
          shouldUseProgress: false,
        });

        refreshData();
        onClose();

        if (isSecretSpace(space)) {
          goToCommunityRootPath();
        }
      },
      onError: () => {
        error(t("leave_space_error"));
      },
    },
  );

  return (
    <SecureConfirmationModal
      title={t([i18nRoot, "title"])}
      confirmUserPrompt={t([i18nRoot, "confirm_prompt"])}
      isOpen={show}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={leaveSpaceMutation}
      confirmVariant="danger"
      isDisabled={isLeaving}
      confirmText={isLeaving ? t("leaving") : t("confirm")}
    >
      <SanitizeHTMLString
        content={t([i18nRoot, "description"], {
          space_name: space?.name || "",
        })}
      />{" "}
      {!canManageSpace(space) &&
        (isSecretSpace(space) || isPrivateSpace(space)) &&
        t([i18nRoot, "private_space"])}
    </SecureConfirmationModal>
  );
};

LeaveSpaceConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  space: PropTypes.object.isRequired,
};
