import { useCallback, useEffect } from "react";
import { isEmpty } from "lodash";
import { useNotificationsDrawer } from "@circle-react/components/Notifications/NotificationsDrawer";
import { usePunditUserContext } from "@circle-react/contexts";
import { useNotificationWebSocket } from "@circle-react/hooks/notifications/useNotificationWebSocket";
import { useNotificationsApi } from "@circle-react/hooks/notifications/useNotificationsApi";

export const useNotificationDropdown = () => {
  const { currentCommunity, currentCommunityMember } = usePunditUserContext();
  const {
    newNotificationCount,
    fetchNewNotificationsCount,
    resetNewNotificationsCount,
    onNotificationEventReceive,
  } = useNotificationsApi();

  useNotificationWebSocket(
    currentCommunityMember?.id,
    onNotificationEventReceive,
  );

  useEffect(() => {
    if (!currentCommunityMember) return;
    void fetchNewNotificationsCount(currentCommunity?.id);
  }, [currentCommunity?.id, currentCommunityMember]);

  const notificationDrawer = useNotificationsDrawer();
  const toggleNotificationDrawer = useCallback(() => {
    if (!isEmpty(currentCommunityMember) && !isEmpty(currentCommunity)) {
      void notificationDrawer.show({
        communityMemberId: currentCommunityMember?.id ?? 0,
        newNotificationCount,
      });
    }
  }, [currentCommunityMember, currentCommunity, notificationDrawer]);

  return {
    newNotificationCount,
    toggleNotificationDrawer,
    resetNewNotificationsCount,
  };
};
