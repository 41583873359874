import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useBackendProps } from "@circle-react/providers";
import { useRouteHelpers } from "@circle-react/routes/Main/useRouteHelpers";
import type { CommunitySwitcherContentV3Props } from "./CommunitySwitcherContentV3";
import { CommunitySwitcherContentV3 } from "./CommunitySwitcherContentV3";

export const CommunitySwitcher = (props: CommunitySwitcherContentV3Props) => {
  const { pathname } = useLocation();
  const { isSettingsPage } = useRouteHelpers();
  const is2faPage = pathname.startsWith("/two_fa");
  const { display_community_switcher: isDisplayCommunitySwitcherEnabled } =
    useBackendProps();

  useEffect(() => {
    if (isSettingsPage) {
      document.body.classList.remove("has-community-switcher");
    } else if (isDisplayCommunitySwitcherEnabled) {
      document.body.classList.add("has-community-switcher");
    }
  }, [pathname, isSettingsPage, isDisplayCommunitySwitcherEnabled]);

  if (isSettingsPage || is2faPage) {
    return null;
  }

  return <CommunitySwitcherContentV3 {...props} />;
};
