import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { AccessForm } from "@circle-react/components/Spaces/CustomizeSpaceFullPage/AccessForm";
import {
  isCourseSpace,
  isDraftSpace,
} from "@circle-react/helpers/spaceHelpers";
import { SectionCard } from "@circle-react-uikit/SectionCard";
import { DraftAlert } from "./DraftAlert";

export const AccessSection = ({ space, onChange }) => {
  const shouldShowDraftMessage = isCourseSpace(space) && isDraftSpace(space);

  return (
    <SectionCard
      title={t("customize_space.access")}
      dataTestId="customize-space-access"
      variant="fullScreen"
    >
      <div className="flex flex-col gap-y-2">
        {shouldShowDraftMessage && <DraftAlert />}
        <AccessForm onChange={onChange} />
      </div>
    </SectionCard>
  );
};

AccessSection.propTypes = {
  space: PropTypes.shape({}),
  isSubmitDisabled: PropTypes.bool,
  triggerFormSubmitRef: PropTypes.shape({ current: PropTypes.any }),
  onChange: PropTypes.func,
  setPristine: PropTypes.func,
};
