import { Icon } from "@circle-react-shared/Icon";

export const BehaviorTypeIcon = ({ tone }: { tone: string }) => {
  if (tone == "normal") {
    return (
      <div className="border-primary h-8 w-8 rounded-full border pl-[5px] pt-1">
        <Icon
          type="20-normal-behavior-type"
          size={20}
          useWithStrokeCurrentColor
        />
      </div>
    );
  }
  if (tone == "friendly") {
    return (
      <div className="border-primary h-8 w-8 rounded-full border pl-[5px] pt-1">
        <Icon
          type="20-friendly-behavior-type"
          size={20}
          useWithStrokeCurrentColor
        />
      </div>
    );
  }
  if (tone == "short-and-sweet") {
    return (
      <div className="border-primary h-8 w-8 rounded-full border pl-[5px] pt-1">
        <Icon
          type="20-short-and-sweet-behavior-type"
          size={20}
          useWithStrokeCurrentColor
        />
      </div>
    );
  }

  return (
    <div className="border-primary h-8 w-8 rounded-full border pl-[5px] pt-1">
      <Icon
        type="20-descriptive-behavior-type"
        size={20}
        useWithStrokeCurrentColor
      />
    </div>
  );
};
