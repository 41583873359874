import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

interface BillingItemProps {
  item?: null | number;
  labelTranslation?: string;
  label?: string;
  amountColor?: string;
  labelColor?: string;
}

export const BillingItem = ({
  item,
  labelTranslation,
  label,
  amountColor = "text-dark",
  labelColor = "text-dark",
}: BillingItemProps) => (
  <>
    {item && (
      <div className="flex justify-between pb-2">
        <Typography.LabelSm color={labelColor}>
          {labelTranslation
            ? t(`settings.billing.preview_invoice.${labelTranslation}`)
            : label}
        </Typography.LabelSm>
        <Typography.LabelSm color={amountColor}>{item}</Typography.LabelSm>
      </div>
    )}
  </>
);
