import { t } from "@/i18n-js/instance";
import { isLegacyAccessSystemAvailable } from "@/react/helpers/accessGroups/accessGroupHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";

const itemProps = {
  translationRoot: "space_groups.form.create",
  hiddenField: true,
  hideDescription: true,
  className: "flex flex-row-reverse justify-end m-0",
  labelClassName: "ml-4 mb-0",
  labelWrapperClassName: "flex items-center",
  shouldToggleValueOnLabelClick: true,
};

export const Advanced = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  return (
    <div className="mt-8 space-y-5">
      <Typography.TitleSm weight="semibold">
        {t("space_groups.form.create.permissions")}
      </Typography.TitleSm>
      <Form.Item
        {...itemProps}
        name="hide_members_count"
        tooltipText={t("space_groups.form.create.hide_members_count_tooltip")}
      >
        <Form.ToggleSwitch variant="small" />
      </Form.Item>
      <Form.Item
        {...itemProps}
        name="is_hidden_from_non_members"
        tooltipText={t(
          "space_groups.form.create.is_hidden_from_non_members_tooltip",
        )}
      >
        <Form.ToggleSwitch variant="small" />
      </Form.Item>
      <Form.Item
        {...itemProps}
        name="hide_non_member_spaces_from_sidebar"
        tooltipText={t(
          "space_groups.form.create.hide_non_member_spaces_from_sidebar_tooltip",
        )}
      >
        <Form.ToggleSwitch variant="small" />
      </Form.Item>
      <Form.Item
        {...itemProps}
        name="allow_members_to_create_spaces"
        tooltipText={t(
          "space_groups.form.create.allow_members_to_create_spaces_tooltip",
        )}
      >
        <Form.ToggleSwitch variant="small" />
      </Form.Item>

      {isLegacyAccessSystemAvailable(currentCommunitySettings) && (
        <>
          <div className="flex gap-1 pt-4">
            <Typography.TitleSm weight="semibold">
              {t("settings.access_groups.admin.legacy_access.title")}
            </Typography.TitleSm>
            <div>
              <TippyV2
                role="tooltip"
                content={t(
                  "settings.access_groups.admin.legacy_access.legacy_tooltip",
                )}
                interactive={false}
              >
                <BadgeV2
                  square
                  label={t(
                    "settings.access_groups.admin.legacy_access.legacy_badge",
                  )}
                />
              </TippyV2>
            </div>
          </div>

          <Form.Item
            {...itemProps}
            name="automatically_add_members_to_new_spaces"
            tooltipText={t(
              "space_groups.form.create.automatically_add_members_to_new_spaces_tooltip",
            )}
          >
            <Form.ToggleSwitch variant="small" />
          </Form.Item>
          <Form.Item
            {...itemProps}
            name="add_members_to_space_group_on_space_join"
            tooltipText={t(
              "space_groups.form.create.add_members_to_space_group_on_space_join_tooltip",
            )}
          >
            <Form.ToggleSwitch variant="small" />
          </Form.Item>
        </>
      )}
    </div>
  );
};
