import { AddressElement } from "@stripe/react-stripe-js";
import i18n from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { stripeAddressElementOptions } from "../../StripeElements/stripeElementsOptions";
import { useBillingInfo } from "./useBillingInfo";

export const BillingInfoForm = () => {
  const {
    communityMember,
    asPrerequisite: isAsPrerequisite,
    billingInfo,
    onStripeElementAddressChangeEvent,
    isBillingInfoComplete,
    onSaveBillingInfoClick,
    isUpdatingBillingInfo,
  } = useBillingInfo();

  return (
    <>
      <Modal.Body>
        <AddressElement
          className="mt-3"
          options={{
            ...stripeAddressElementOptions,
            defaultValues: {
              name: communityMember?.name || "",
              address: {
                line1: billingInfo.address_line1,
                line2: billingInfo.address_line2,
                city: billingInfo.address_city,
                state: billingInfo.address_state,
                postal_code: billingInfo.address_postal_code,
                country: billingInfo.address_country,
              },
            },
          }}
          onChange={onStripeElementAddressChangeEvent}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          full
          large
          variant="primary"
          disabled={!isBillingInfoComplete || isUpdatingBillingInfo}
          onClick={onSaveBillingInfoClick}
        >
          {i18n.t(
            `paywalls.community_members.billing_info_form.submit${
              isAsPrerequisite ? "_as_prerequisite" : ""
            }`,
          )}
        </Button>
      </Modal.Footer>
    </>
  );
};
