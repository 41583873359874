import { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { t } from "@/i18n-js/instance";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";
import { SpacesContextProvider } from "../../contexts";
import CurrentPostLoader from "./CurrentPostLoader";
import { ModalForm } from "./ModalForm";

export const QPEditPost = ({ currentSpaceId, postSlug }) => {
  const [show, setShow] = useState(false);
  const [, setIsModalVisible] = useState(false);

  function showModal(event) {
    event.preventDefault();
    setIsModalVisible(true);
    setShow(true);
  }

  const closeModal = () => {
    setShow(false);
  };

  const queryClient = new QueryClient(reactQueryConfig);

  return (
    <>
      <button type="button" onClick={showModal}>
        <h4>{t("edit_post")}</h4>
        <div className="dropdown-menu__descriptor">
          {t("edit_post_description")}
        </div>
      </button>

      <QueryClientProvider client={queryClient}>
        <SpacesContextProvider>
          {show && (
            <CurrentPostLoader
              currentSpaceId={currentSpaceId}
              postSlug={postSlug}
              closeModal={closeModal}
              onPostChange={() => {
                closeModal();
                window.location.reload();
              }}
            >
              <ModalForm
                show
                closeModal={closeModal}
                currentSpaceId={currentSpaceId}
              />
            </CurrentPostLoader>
          )}
        </SpacesContextProvider>
      </QueryClientProvider>
    </>
  );
};
