import { t } from "@/i18n-js/instance";
import { MemberNameOrEmailFilterComponent } from "./";

const localeRoot = "settings.paywalls_admin_dashboard.filters.members";

export const filterProperties = {
  name: "community_member_id",
  filterKeys: ["community_member_id"],
  component: MemberNameOrEmailFilterComponent,
  label: t([localeRoot, "chip"]),
};
