import { reverse, sortBy, truncate } from "lodash";
import { t } from "@/i18n-js/instance";
import { ShowPageLink } from "@/react/components/PostsV3/PostViewListItem/ShowPageLink";
import { usePostListPinnedOnSidebar } from "@/react/hooks/posts/usePostList";
import { useIsLoadingAppData } from "@/react/hooks/useIsLoadingAppData";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { Typography } from "@circle-react-uikit/Typography";

export const PinnedPostsV2 = () => {
  const { data: space } = useCurrentSpaceContext();
  const { isLoading, posts, hasNextPage, fetchNextPage } =
    usePostListPinnedOnSidebar(5);
  const isLoadingAppData = useIsLoadingAppData();

  if (isLoading || isLoadingAppData) {
    return <SkeletonLoader variant="right-sidebar" count={2} />;
  }

  if (!posts?.length) {
    return null;
  }

  const sortedPosts = reverse(sortBy(posts, "pinned_at"));

  return (
    <div className="bg-primary text-dark border-primary rounded-2xl border p-5">
      <div>
        <Typography.LabelMd weight="semibold">
          {space?.pinned_posts_label?.trim() || t("block_pinned_title")}
        </Typography.LabelMd>
      </div>
      <ul className="m-0 list-none p-0" data-testid="pinned-posts">
        {sortedPosts.map(post => (
          <li key={post.id} className="mt-4">
            <ShowPageLink
              post={post}
              className="!text-dark block text-sm leading-5"
            >
              {truncate(post.display_title, { length: 100 })}
            </ShowPageLink>
          </li>
        ))}
        {hasNextPage && (
          <div className="mt-4">
            <button type="button" onClick={() => fetchNextPage()}>
              <Typography.LabelSm weight="semibold">
                {t("see_more")}
              </Typography.LabelSm>
            </button>
          </div>
        )}
      </ul>
    </div>
  );
};
