import { useRouteMatch } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import {
  isUsingTiptapEditor,
  isUsingTrixEditor,
} from "@circle-react/helpers/postHelpers";
import { DisplayTrixContent } from "@circle-react-uikit/DisplayTrixContent";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";
import { Typography } from "@circle-react-uikit/Typography";
import { HeaderIconButton } from "../../HeaderIconButton";
import { SkeletonLoader } from "../../SkeletonLoader";
import { CIRCLE_COMMUNITY_WHATS_NEW_URL } from "../../constants";
import { useShowNews } from "../../queries";
import { OpenInNewWindowLink } from "../PostView/OpenInNewWindowLink";
import "./styles.css";

export const NewsView = () => {
  const match = useRouteMatch();
  const id = match.params.id;

  const { isError, isLoading, data: post } = useShowNews(id);

  if (isLoading) {
    return (
      <>
        <SkeletonLoader rows={1} height="52px" wrapperClassName="px-0 mb-4" />
        <SkeletonLoader
          rows={1}
          height="150px"
          wrapperClassName="px-0 space-y-0"
        />
        <SkeletonLoader rows={1} height="34px" wrapperClassName="my-4" />
        <SkeletonLoader rows={6} />
      </>
    );
  }

  if (isError) {
    return (
      <div className="flex flex-col">
        <div className="support-widget-post__header border-primary bg-primary sticky top-0 z-10 flex h-[52px] items-center justify-between border-b px-3">
          <HeaderIconButton type="back" />
          <div className="flex items-center">
            <HeaderIconButton type="expand" />
          </div>
        </div>
        <div className="support-widget-post__body grow">
          <div className="mx-6 my-3 max-h-full">
            {t("support_widget.post_not_found")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="support-widget-post__header border-primary bg-primary sticky top-0 z-10 flex h-[52px] shrink-0 items-center justify-between border-b px-3">
        <HeaderIconButton type="back" />
        <div className="flex items-center">
          <HeaderIconButton
            href={post?.post_url}
            icon="share-support-widget"
            size={16}
          />
          <HeaderIconButton type="expand" />
        </div>
      </div>
      <div className="support-widget-post__body !mb-0 grow">
        {post.cover_image_url && (
          <div>
            <img loading="lazy" alt="" src={post.cover_image_url} />
          </div>
        )}
        <div className="mx-6 my-4">
          <Typography.TitleMd>
            <a
              className="!text-dark transition-colors duration-150 ease-linear hover:!text-[#4262F0]"
              href={post.post_url || CIRCLE_COMMUNITY_WHATS_NEW_URL}
              target="_blank"
              rel="noreferrer noopener"
            >
              {post.name}
            </a>
          </Typography.TitleMd>
        </div>
        <div
          id="news_view_post_body"
          className="mx-6 my-3 flex max-h-full flex-col gap-2"
        >
          {isUsingTrixEditor(post) && post.body_trix_content && (
            <DisplayTrixContent content={post.body_trix_content} />
          )}
          {isUsingTiptapEditor(post) && post.tiptap_body && (
            <TipTapBlockEditor
              readOnly
              value={post.tiptap_body.body}
              sgidToObjectMap={post.tiptap_body.sgids_to_object_map}
              inlineAttachments={post.tiptap_body.inline_attachments}
              disabledExtensions={["cta"]}
            />
          )}
          <div className="py-4">
            <OpenInNewWindowLink postUrl={post.post_url} />
          </div>
        </div>
      </div>
    </div>
  );
};
