import { t } from "@/i18n-js/instance";
import { DataTable } from "@circle-react-uikit/DataTableV2";

export const SnippetDropdownActions = ({
  snippet,
  onRemove,
}: {
  snippet: any;
  onRemove: (id: number) => void;
}) => (
  <DataTable.Actions>
    <DataTable.Actions.Item
      hasDanger
      onClick={event => {
        event.stopPropagation();
        onRemove(snippet.id);
      }}
    >
      {t(
        "community_bot.bot_builder.knowledge_page.custom_resources_table.dropdown_actions.remove",
      )}
    </DataTable.Actions.Item>
  </DataTable.Actions>
);
