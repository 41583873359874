import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useThemeContext } from "@circle-react/providers";
import type { CommunitySwitcher } from "@circle-react/types/CommunitySwitcher";
import { CommunityLogo } from "@circle-react-shared/CommunityLogo";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useCommunityNotificationsCount } from "./useCommunityNotificationsCount";

export interface CommunitiesModalLinkProps {
  community: CommunitySwitcher;
  href: string;
  isDragDisabled?: boolean;
}

export const CommunitiesModalLink = ({
  community,
  href,
  isDragDisabled = false,
}: CommunitiesModalLinkProps) => {
  const { isDarkAppearance } = useThemeContext();
  const { notificationsCount } = useCommunityNotificationsCount(community);

  return (
    <a
      key={community.id}
      href={href}
      className="hover:bg-tertiary focus:bg-tertiary flex items-center justify-between gap-x-1 rounded-xl p-1 transition-colors duration-75"
    >
      <IconButton
        name="16-drag-handle"
        ariaLabel={t("community_switcher_drag_handle")}
        disabled={isDragDisabled}
        className={classNames({
          "cursor-grab": !isDragDisabled,
          "cursor-grabbing opacity-50": isDragDisabled,
        })}
      />
      <div className="flex w-full items-center gap-3">
        <div
          className={classNames(
            "switcher__icon !m-0 flex items-center justify-center rounded-xl border-2 border-transparent",
            {
              "active border-secondary": community.is_current_community,
            },
          )}
        >
          <CommunityLogo
            {...community}
            logo_attachment_url={undefined}
            icon_attachment_url={
              isDarkAppearance
                ? community.dark_icon_url
                : community.light_icon_url
            }
            shouldShowTooltip={false}
            notificationCount={notificationsCount}
          />
        </div>
        <Typography.LabelMd weight="medium">
          {community.name}
        </Typography.LabelMd>
      </div>
    </a>
  );
};
