import { createContext, useContext } from "react";
import { noop } from "lodash";
import type { CommunityMemberBillingInfo } from "@circle-react/types";
import type { BillingInfoCommunityMember } from "./BillingInfoModal";

export interface BillingInfoContextState {
  communityMember?: BillingInfoCommunityMember;
  billingInfo: CommunityMemberBillingInfo;
  onSuccess: typeof noop;
  asPrerequisite?: boolean;
}

export const initialBillingInfo = {
  address_line1: "",
  address_line2: "",
  address_city: "",
  address_state: "",
  address_postal_code: "",
  address_country: "",
};

export const BillingInfoContext = createContext<BillingInfoContextState>({
  communityMember: undefined,
  billingInfo: initialBillingInfo,
  onSuccess: noop,
  asPrerequisite: false,
});

BillingInfoContext.displayName = "BillingInfoContext";

export const useBillingInfoContext = () => useContext(BillingInfoContext);
