import { useEffect, useRef } from "react";
import { t } from "@/i18n-js/instance";
import circleLogo from "@circle-assets/images/circular-logo@2x.png";
import { Icon } from "@circle-react-shared/Icon";
import { BodySm } from "@circle-react-shared/uikit/Typography/components/Body/BodySm";
import { TitleMd } from "@circle-react-shared/uikit/Typography/components/Title/TitleMd";

export const OnboardingPopup = ({ onClick }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const popupElement = popupRef.current;
    if (popupElement) {
      const height = popupElement.offsetHeight;
      popupElement.style.setProperty("--arrow-top", `${height}px`);
    }
  }, []);

  return (
    <div
      ref={popupRef}
      className="support-widget-onboarding bg-circle w-[303px] rounded-lg p-6 text-white"
    >
      <div className="mb-4 flex items-start justify-between">
        <img
          loading="lazy"
          src={circleLogo}
          alt={t("support_widget.circle_logo_alt_text")}
          className="h-8 w-8"
        />
        <button type="button" className="-mt-1" onClick={onClick}>
          <Icon type="20-close" size={20} />
        </button>
      </div>
      <TitleMd color="text-white">
        {t("support_widget.onboarding.title")}
      </TitleMd>
      <BodySm color="text-white">
        {t("support_widget.onboarding.content")}
      </BodySm>
    </div>
  );
};
