import { BlockBody } from "./BlockBody";
import { BlockContent } from "./BlockContent";
import { BlockContentResolver } from "./BlockContentResolver";
import { BlockGroup } from "./BlockGroup";
import { BlockMobileContent } from "./BlockMobileContent";

export const Block = Object.assign(BlockGroup, {
  Body: BlockBody,
  Content: BlockContent,
  MobileContent: BlockMobileContent,
  ContentResolver: BlockContentResolver,
});
