import { useRef } from "react";
import { uniqueId } from "lodash";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Form } from "@circle-react-uikit/Form";
import { useNewAccessGroup } from "./hooks/useNewAccessGroup";

const i18nBase = "settings.access_groups.admin.form";
const formId = uniqueId();

interface NewAccessGroupModalContentProps {
  onSuccess?: (accessGroup: AccessGroupType) => void;
}

export const NewAccessGroupModalContent = ({
  onSuccess,
}: NewAccessGroupModalContentProps) => {
  const initialFocusRef = useRef(null);
  const modal = useModal();

  const { isSubmitting, handleSubmit } = useNewAccessGroup({
    closeModal: modal.hide,
    onSuccess: onSuccess,
  });

  return (
    <ConfirmationModal
      formId={formId}
      isOpen={modal.visible}
      onClose={modal.hide}
      title={t([i18nBase, "create_title"])}
      confirmText={t([i18nBase, "create_confirm_text"])}
      confirmVariant="circle"
      disabled={isSubmitting}
      initialFocusRef={initialFocusRef}
    >
      <Form
        id={formId}
        mode="onChange"
        defaultValues={{ name: "", description: "" }}
        onSubmit={data => handleSubmit(data)}
      >
        <Form.Item
          name="name"
          translationRoot={i18nBase}
          hideDescription
          hidePlaceholder
          hideBorder
          rules={{
            required: t([i18nBase, "validations.name_required"]),
            maxLength: {
              value: 65,
              message: t([i18nBase, "validations.name_max_length"]),
            },
          }}
          tooltipText={t([i18nBase, "tooltips.name"])}
        >
          <Form.Input ref={initialFocusRef} />
        </Form.Item>
        <Form.Item
          name="description"
          translationRoot={i18nBase}
          tooltipText={t([i18nBase, "tooltips.description"])}
          hideDescription
          hidePlaceholder
          hideBorder
          rules={{
            maxLength: {
              value: 100,
              message: t([i18nBase, "validations.description_max_length"]),
            },
          }}
        >
          <Form.Input />
        </Form.Item>
      </Form>
    </ConfirmationModal>
  );
};

const NewAccessGroupModal = ModalManager.create(
  ({ onSuccess }: NewAccessGroupModalContentProps) => (
    <NewAccessGroupModalContent onSuccess={onSuccess} />
  ),
);

export const useNewAccessGroupModal = () => useModal(NewAccessGroupModal);
