import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useFetchSpaces } from "@circle-react/components/SettingsApp/Workflows/hooks/useFetchSpaces";
import { SpaceSelect } from "@circle-react/components/shared/SpaceSelect";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";

const i18nRoot = "settings.workflows.form";

export const CourseSelector = () => {
  const { register } = useFormContext();
  const { data, isLoading } = useFetchSpaces();
  const { records: spaces = [] } = data || {};

  const courseSpaces = spaces.filter(
    (course: any) => course.post_type === "course",
  );

  if (isLoading) {
    return <Loader center />;
  }

  return (
    <>
      <input
        type="hidden"
        className="hidden"
        {...register("trigger.resource_type")}
      />
      <Form.Item
        name="trigger.resource_id"
        hideBorder
        fullWidth
        hideDescription
        label={t([i18nRoot, "choose_course"])}
        labelClassName="!mb-2 !text-sm"
        placeholder={t([i18nRoot, "search_course"])}
        className="!py-0"
      >
        {isLoading ? (
          <Loader center />
        ) : (
          <SpaceSelect
            spaces={courseSpaces}
            shouldShowEmoji
            variant="form-input"
            containerClassname="relative"
            dropdownClassName="!top-12"
            defaultButtonLabel={t([i18nRoot, "select_course"])}
            placeholder={t([i18nRoot, "search_course"])}
            name="space-select"
          />
        )}
      </Form.Item>
    </>
  );
};
