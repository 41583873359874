import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useAppendBodyClasses } from "@circle-react/hooks/useAppendBodyClasses";
import { useHideCommunitySwitcher } from "@circle-react/hooks/useHideCommunitySwitcher";

export const EmptyLayout = ({ children, bodyClassNames = "" }) => {
  useAppendBodyClasses(classNames(bodyClassNames, "trix-v2"));
  useHideCommunitySwitcher();

  return <div className="standard-layout-v2">{children}</div>;
};
