import type { ReactNode } from "react";
import type { RefObject } from "@fullcalendar/core/preact";
import * as yup from "yup";
import I18n, { t } from "@/i18n-js/instance";
import { useBookmarks } from "@/react/components/Spaces/Bookmarks/hooks/useBookmarks";
import { useFocusOnLoad } from "@circle-react/hooks/useFocusOnLoad";
import type { Space } from "@circle-react/types";
import type { Bookmark } from "@circle-react/types/Bookmarks";
import { Form } from "@circle-react-uikit/Form";

export interface BookmarkFormProps {
  space: Space;
  isEdit: boolean;
  bookmark?: Bookmark;
  renderFooter?: ({ isSubmitting }: { isSubmitting: boolean }) => ReactNode;
  isFolder?: boolean;
  folderId?: string;
  onAfterCreateOrUpdate?: () => void;
}

export const BookmarkForm = ({
  space,
  isEdit,
  bookmark,
  renderFooter,
  isFolder = false,
  folderId,
  onAfterCreateOrUpdate,
}: BookmarkFormProps) => {
  const nameInputRef: RefObject<HTMLInputElement> = useFocusOnLoad();

  const VALIDATION_SCHEMA = yup.object().shape({
    name: yup
      .string()
      .required(I18n.t("spaces.bookmarks.form.errors.name_required")),
    ...(!isFolder && {
      url: yup
        .string()
        .url(I18n.t("spaces.bookmarks.form.errors.url_format"))
        .required(I18n.t("spaces.bookmarks.form.errors.url_required")),
    }),
  });

  const {
    createBookmark,
    isCreatingBookmark,
    updateBookmark,
    isUpdatingBookmark,
  } = useBookmarks({ space, bookmark, onAfterCreateOrUpdate });

  return (
    <Form
      id="bookmark-form"
      onSubmit={(formData: any) =>
        isEdit ? updateBookmark(formData) : createBookmark(formData)
      }
      validationSchema={VALIDATION_SCHEMA}
      autoComplete="off"
      defaultValues={
        isEdit
          ? { ...bookmark }
          : {
              display_type: isFolder ? "folder" : "link",
              space_id: space.id,
              name: "",
              ...(!isFolder && { url: "", emoji: "", folder_id: folderId }),
              ...(!isFolder && folderId && { folder_id: folderId }),
            }
      }
    >
      <Form.Item
        name="name"
        variant="extra-bottom-margin"
        label={t("spaces.bookmarks.form.name")}
        hideDescription
        hideBorder
      >
        <Form.Input
          ref={nameInputRef}
          prefix={!isFolder && <Form.EmojiPickerV3Wrapper record={bookmark} />}
        />
      </Form.Item>
      {!isFolder && (
        <Form.Item
          variant="extra-bottom-margin"
          name="url"
          label={t("spaces.bookmarks.form.url")}
          hideDescription
          hideBorder
        >
          <Form.Input />
        </Form.Item>
      )}
      {renderFooter?.({
        isSubmitting: isCreatingBookmark || isUpdatingBookmark,
      })}
    </Form>
  );
};
