import { t } from "@/i18n-js/instance";
import { useGetPaywall } from "@/react/components/Modals/MemberProfileModal/Billing/Tabs/SubscriptionTab/ChangePlan/hooks/useGetPaywall";
import { BaseResource } from "../../BaseResource";

export interface PaywallResourceProps {
  resourceId: number;
}

export const PaywallResource = ({ resourceId }: PaywallResourceProps) => {
  const { data: paywall = {}, isLoading } = useGetPaywall(String(resourceId));
  return (
    <BaseResource
      label={t("settings.workflows.view.resources.paywall")}
      value={paywall.display_name}
      isLoading={isLoading}
    />
  );
};
