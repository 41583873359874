import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { useCurrentAction } from "../../../../ActionRule";

const i18nRoot = "settings.workflows.form";

export const NotifyMemberForm = () => {
  const { paramsPath } = useCurrentAction();

  return (
    <div className="flex flex-col gap-y-4">
      <Typography.LabelSm weight="semibold">
        {t([i18nRoot, "notify_member"])}
      </Typography.LabelSm>
      <div className="flex flex-row gap-x-4 py-2">
        <Form.ToggleSwitch
          name={`${paramsPath}.send_notification_subscription_created_by_admin`}
          variant="small"
        />
        <div className="flex flex-col">
          <span>
            {t([i18nRoot, "send_notification_subscription_created_by_admin"])}
          </span>
        </div>
      </div>
    </div>
  );
};
