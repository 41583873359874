import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { reactQueryDelete } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export const DeleteLinkConfirmation = ({ isOpen, onClose, link }) => {
  const { refetch: refetchLinks } = usePunditUserContext();
  const { mutate: deleteLinkMutation, isLoading: isDeleting } = useMutation(
    data =>
      reactQueryDelete(
        internalApi.communities.communityLinks.delete({
          communityLinkId: link.id,
        }),
        data,
      ),
    {
      onSuccess: () => {
        refetchLinks();
        onClose();
      },
    },
  );

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={t("delete_link")}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={deleteLinkMutation}
      cancelText={t("cancel")}
      confirmText={isDeleting ? t("deleting") : t("delete")}
      disabled={isDeleting}
      confirmVariant="danger"
    >
      {t("delete_link_confirmation", { name: link.name })}
    </ConfirmationModal>
  );
};

DeleteLinkConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  link: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};
