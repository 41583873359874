import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { AddRemoveToggle } from "../../../../shared/uikit/AddRemoveToggle/index";
import { Counter } from "../../../../shared/uikit/Counter/index";
import { useAddOnsSettingsContext } from "../addOnsSettingsContext";

export const AddOnItem = ({
  name,
  interval,
  monthlyAmount,
  step = 1,
  toggleable = false,
  maxPurchasableQuantity,
  priceCurrency,
  unitSize,
  ...props
}) => {
  const { defaultValues, getAddOnPrice, updateTotalPrice } =
    useAddOnsSettingsContext();

  const [price, setPrice] = useState();

  const onValueChange = value => {
    const newPrice = updatePrice(value);
    updateTotalPrice(name, newPrice);
  };

  const updatePrice = value => {
    const newPrice = getAddOnPrice(name, value);
    setPrice(newPrice);
    return newPrice;
  };

  useEffect(() => {
    updatePrice(defaultValues[name]);
  }, []);

  return (
    <Form.Item
      name={name}
      hideBorder
      isFirstChild
      label={t(`settings.billing.add_ons.manage.${name}`)}
      description={t(
        `settings.billing.add_ons.manage.${interval}.${name}_description`,
        {
          amount: monthlyAmount,
          quantity: unitSize,
        },
      )}
      {...props}
    >
      <div className="form-input manage-add-ons__item">
        {toggleable && (
          <div className="manage-add-ons__item-button">
            <AddRemoveToggle name={name} onChange={onValueChange} />
          </div>
        )}

        {!toggleable && (
          <div className="manage-add-ons__item-button">
            <Counter
              name={name}
              step={step}
              onChange={onValueChange}
              max={maxPurchasableQuantity}
            />
          </div>
        )}

        <div className="manage-add-ons__item-price">
          <span>
            <b>{priceCurrency + price}</b> {t("separator_slash")}{" "}
            {interval.charAt(0)}
          </span>
        </div>
      </div>
    </Form.Item>
  );
};

AddOnItem.propTypes = {
  name: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  monthlyAmount: PropTypes.string.isRequired,
  step: PropTypes.number,
  toggleable: PropTypes.bool,
  maxPurchasableQuantity: PropTypes.number,
  unitSize: PropTypes.number,
};
