import type { ReactNode } from "react";
import { LayoutContextProvider } from "@/react/components/SettingsApp/Layout/LayoutContext";
import { LayoutComponent } from "./LayoutComponent";
import { NoHeaderLayoutComponent } from "./NoHeaderLayoutComponent";

interface LayoutProps {
  shouldHideMainHeader?: boolean;
  children: ReactNode;
  hideAppBar?: boolean;
  shouldHideSidebarNestedNavBar?: boolean;
}

export const Layout = ({
  shouldHideMainHeader = true,
  children,
  hideAppBar = false,
  shouldHideSidebarNestedNavBar = false,
  ...rest
}: LayoutProps) => {
  if (shouldHideMainHeader) {
    return (
      <LayoutContextProvider>
        <NoHeaderLayoutComponent {...rest}>{children}</NoHeaderLayoutComponent>
      </LayoutContextProvider>
    );
  }

  return (
    <LayoutContextProvider>
      <LayoutComponent
        hideAppBar={hideAppBar}
        shouldHideSidebarNestedNavBar={shouldHideSidebarNestedNavBar}
        {...rest}
      >
        {children}
      </LayoutComponent>
    </LayoutContextProvider>
  );
};
