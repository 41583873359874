import { PopupButton } from "@typeform/embed-react";
import { t } from "@/i18n-js/instance";
import { isProductionEnv } from "@/react/helpers/envHelpers";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useBillingPortal } from "./hooks/useBillingPortal";
import "@typeform/embed/build/css/popup.css";

interface PaymentProcessorBillingPortalProps {
  cancellingMetadata?: null | Record<string, string>;
}

export const PaymentProcessorBillingPortal = ({
  cancellingMetadata,
}: PaymentProcessorBillingPortalProps) => {
  const { onGenerateBillingPortalUrl, isCreatingBillingPortal } =
    useBillingPortal();

  // Production Typeform has some integrations set
  // such as Slack #downgrade-survey/Google Sheets/HubSpot
  const typeFormId = isProductionEnv() ? "UJB6AB55" : "nH21fEOM";

  const linkLabels = ["view_invoices", "manage_card", "manage_billing_address"];

  return (
    <div className="border-t border-gray-200 py-5">
      {isCreatingBillingPortal && <Loader variant="fullscreen" />}

      {linkLabels.map(label => (
        <div className="mb-2" key={label}>
          <button type="button" onClick={onGenerateBillingPortalUrl}>
            <Typography.LabelSm color="text-messaging-info-blue">
              {t(`pricing_plans.${label}`)}
            </Typography.LabelSm>
          </button>
        </div>
      ))}

      {/* Typeform has 2 flows: one to cancel the subscription and another */}
      {/* to reach out and redirect to the community, so we cannot use TypeForm */}
      {/* callbacks like onSubmit or onEndingButtonClick to redirect to Stripe Portal */}
      {/* in case of canceling the subscription as we don't know which is the flow. */}
      {/* Redirecition will be handled on the ending button from Typeform for each flow */}
      {cancellingMetadata && (
        <PopupButton
          id={typeFormId}
          size={66}
          medium="demo-test"
          hidden={cancellingMetadata}
        >
          <Typography.LabelSm color="text-messaging-info-blue">
            {t("pricing_plans.cancel_plan")}
          </Typography.LabelSm>
        </PopupButton>
      )}
    </div>
  );
};
