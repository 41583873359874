import { flatten } from "lodash";
import { useInfiniteQuery } from "react-query";
import {
  CHAT_SORT_VALUES,
  CHAT_TYPES,
} from "@/react/components/SettingsApp/CommunityInbox/Sidebar/constants";
import { useCommunityInboxStore } from "@/react/hooks/chatsV2/store";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";
import type {
  ChatSortType,
  ChatType,
} from "@circle-react/types/CommunityBot/ChatSort";

interface ParamsProp {
  filter?: string;
  per_page?: number;
  sort?: ChatSortType;
  chatType?: ChatType;
  agentId?: number;
}

interface ApiParamsProp extends ParamsProp {
  chat_type: ChatType;
  page: number;
  agent_id?: number;
}

export const useChatList = (params: ParamsProp = {}) => {
  const {
    filter = "",
    per_page = 20,
    sort = CHAT_SORT_VALUES.LATEST,
    chatType = CHAT_TYPES.ALL,
    agentId = 0,
  } = params;
  const { setChats } = useCommunityInboxStore();
  const fetchChats = (page: number) => {
    const params: ApiParamsProp = {
      page,
      filter,
      per_page,
      sort,
      chat_type: chatType,
    };
    if (agentId !== 0) {
      params["agent_id"] = agentId;
    }
    return reactQueryGet(
      internalApi.communityInbox.index({
        params,
      }),
    );
  };

  const { data, isLoading, isRefetching, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ["community-inbox", filter, per_page, sort, chatType, agentId],
      ({ pageParam = 1 }) => fetchChats(pageParam),
      {
        select: ({ pages = [] }: { pages: Array<any> }) =>
          // RQ recommends to restructure your data that it still includes data.pages and data.pageParams properties - https://tanstack.com/query/v4/docs/react/guides/infinite-queries
          ({
            pages: flatten(pages.map(page => page.records)),
            pageParams: pages.map(page => page.page),
            pagesList: pages,
          }),
        getNextPageParam: lastPage =>
          lastPage.has_next_page ? lastPage.page + 1 : undefined,
        onSuccess: (data: any) => {
          const chatType = params.filter === "mine" ? "member" : "admin";
          const records = data?.pages || [];
          setChats(records, chatType);
        },
      },
    );
  const chats = data?.pages || [];
  return {
    chats,
    isChatsLoading: isLoading || isRefetching,
    hasNextPage,
    fetchNextPage,
  };
};
