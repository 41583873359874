import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface IncompleteActionsModalContentProps {
  onAction: () => void;
  i18nBase?: string;
}

export const IncompleteActionsModalContent = ({
  onAction,
  i18nBase = "settings.workflows.edit.incomplete_workflow_modal",
}: IncompleteActionsModalContentProps) => {
  const modal = useModal();
  return (
    <ActionModal
      isOpen={modal.visible}
      onClose={modal.hide}
      actionVariant="circle"
      actionText={t([i18nBase, "confirm_text"])}
      onAction={onAction}
      titleClassName="!text-left !px-0"
      title={t([i18nBase, "title"])}
    >
      <div>
        <Typography.BodyMd color="text-default">
          {t([i18nBase, "body"])}
        </Typography.BodyMd>
      </div>
    </ActionModal>
  );
};

export const IncompleteActionsModal =
  ModalManager.create<IncompleteActionsModalContentProps>(
    ({ onAction, i18nBase }) => (
      <IncompleteActionsModalContent onAction={onAction} i18nBase={i18nBase} />
    ),
  );

export const useIncompleteActionsModal = () => useModal(IncompleteActionsModal);
