import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Typography } from "@circle-react-uikit/Typography";

const i18nBase = "settings.access_groups.admin.modal.members";

interface AddAccessGroupMembersConfirmationModalContentProps {
  isProcessingMembers: boolean;
  totalMembersToProcess: number;
  handleAddMembers: () => void;
}

export const AddAccessGroupMembersConfirmationModalContent = ({
  isProcessingMembers,
  totalMembersToProcess,
  handleAddMembers,
}: AddAccessGroupMembersConfirmationModalContentProps) => {
  const modal = useModal();

  return (
    <ConfirmationModal
      isOpen={modal.visible}
      onClose={modal.hide}
      title={t([i18nBase, "add.confirm_title"])}
      confirmVariant="circle"
      confirmText={t([i18nBase, "actions.add_selected_members"], {
        count: totalMembersToProcess,
      })}
      disabled={isProcessingMembers || totalMembersToProcess === 0}
      onConfirm={() => {
        void modal.hide();
        handleAddMembers();
      }}
    >
      <div className="grid gap-y-6">
        <Typography.BodyMd>
          {t([i18nBase, "add.description1"], { count: totalMembersToProcess })}
        </Typography.BodyMd>
        <Typography.BodyMd>
          {t([i18nBase, "add.description2"])}
        </Typography.BodyMd>
      </div>
    </ConfirmationModal>
  );
};

const AddAccessGroupMembersConfirmationModal = ModalManager.create(
  (props: AddAccessGroupMembersConfirmationModalContentProps) => (
    <AddAccessGroupMembersConfirmationModalContent {...props} />
  ),
);

export const useAddAccessGroupMembersConfirmationModal = () =>
  useModal(AddAccessGroupMembersConfirmationModal);
