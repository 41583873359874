import { useAddAccessGroupMemberData } from "../../AddAccessGroupMembersModal/DataStoreContext/AddAccessGroupMembersDataContext";

export interface MemberNotInGroupIdCheckBoxCellType {
  communityMemberId: number;
}
export const MemberNotInGroupIdCheckBoxCell = ({
  communityMemberId,
}: MemberNotInGroupIdCheckBoxCellType) => {
  const { selectedMemberIds, addMemberId, updateTotalMembersToProcess } =
    useAddAccessGroupMemberData();

  const handleOnChange = () => {
    addMemberId(communityMemberId);
    updateTotalMembersToProcess(0);
  };

  return (
    <input
      type="checkbox"
      className="text-dark bg-primary hover:bg-tertiary hover:border-secondary disabled:bg-tertiary disabled:border-selector-inactive border-secondary focus:bg-tertiary focus:border-secondary placeholder:text-light h-4 w-4 cursor-pointer rounded align-middle placeholder:opacity-100 focus:ring-0 focus:ring-offset-0"
      id={`community-member-${communityMemberId}-box`}
      checked={selectedMemberIds.includes(communityMemberId)}
      onChange={handleOnChange}
      disabled={false}
    />
  );
};
