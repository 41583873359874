import { useMutation, useQuery } from "react-query";
import I18n from "@/i18n-js/instance";
import {
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const usePaywallGroupApi = ({
  closeModal = null,
  refetchPaywallGroups = null,
}) => {
  const INDEX_REQUEST_KEY = "paywall-groups";
  const SHOW_REQUEST_KEY = "paywall-group-show";
  const { success, error } = useToast();

  const useListPaywallGroups = params => {
    const queryFn = () =>
      reactQueryGet(internalApi.paywallGroup.index({ params: params }));

    return useQuery(INDEX_REQUEST_KEY, queryFn);
  };

  const useGetPaywallGroup = paywallGroupId => {
    const queryFn = () =>
      reactQueryGet(
        internalApi.paywallGroup.show({
          paywallGroupId: paywallGroupId,
        }),
      );

    return useQuery(SHOW_REQUEST_KEY, queryFn);
  };

  const updateMutation = useMutation(
    formData =>
      reactQueryPut(
        internalApi.paywallGroup.update({
          paywallGroupId: formData.id,
        }),
        { paywall_group: formData },
      ),
    {
      onSuccess: () => {
        success(I18n.t("settings.paywall_groups.alerts.success.updated"));
        closeModal();
        refetchPaywallGroups();
      },
      onError: updateError => {
        console.error(updateError.errorDetails);
        error(updateError.errorDetails, {
          duration: "long",
        });
      },
    },
  );

  const createMutation = useMutation(
    formData =>
      reactQueryPost(internalApi.paywallGroup.create(), {
        paywall_group: formData,
      }),
    {
      onSuccess: () => {
        success(I18n.t("settings.paywall_groups.alerts.success.created"));
        closeModal();
        refetchPaywallGroups();
      },
      onError: createError => {
        console.error(createError.errorDetails);
        error(createError.errorDetails, {
          duration: "long",
        });
      },
    },
  );

  return {
    useListPaywallGroups,
    updateMutation,
    createMutation,
    useGetPaywallGroup,
  };
};
