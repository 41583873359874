import { PropTypes } from "prop-types";
import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { workflowsApi } from "@circle-react/api";
import { Loader } from "@circle-react-uikit/Loader";
import { Modal } from "@circle-react-uikit/ModalV2";
import { ActionsSeparator } from "./ActionsSeparator";
import { LogMessage } from "./LogMessage";
import { SubHeader } from "./SubHeader";

export const HistoryWorkflowDetail = ({ taskId, onClose }) => {
  const { data, isLoading } = useQuery(["task-detail", taskId], () =>
    workflowsApi.taskDetail({ taskId }),
  );

  if (isLoading) {
    return <Loader center />;
  }

  const { workflow, logs, status } = data;

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {workflow.name ?? t("settings.workflows.detailed_history")}
        </Modal.Title>
        <Modal.HeaderActions>
          <Modal.CloseButton onClick={onClose} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body>
        <SubHeader task={data} />
        <div className="mt-5 flex flex-col">
          {logs.map((log, index) => (
            <div key={index}>
              <LogMessage log={log} status={status} />
              {index < logs.length - 1 && (
                <ActionsSeparator className="ml-[3.3rem]" />
              )}
              {index === logs.length - 1 && <div className="m-1 h-4" />}
            </div>
          ))}
        </div>
      </Modal.Body>
    </>
  );
};

HistoryWorkflowDetail.propTypes = {
  taskId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
