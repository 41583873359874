import { useEffect, useState } from "react";
import { useModal } from "@ebay/nice-modal-react";
import { t } from "@/i18n-js/instance";
import { useCommunitySwitcher } from "@circle-react/hooks/community_switcher/useCommunitySwitcherApi";
import type { CommunitySwitcher } from "@circle-react/types/CommunitySwitcher";
import { SortableList } from "@circle-react-shared/uikit/SortableList";
import { Modal } from "@circle-react-uikit/ModalV2";
import { getAutomaticLoginRootUrl } from "../utils";
import { CommunitiesModalLink } from "./CommunitiesModalLink";
import { CommunityModalLinkDragOverlay } from "./CommunityModalLinkDragOverlay";

export const CommunitiesModal = () => {
  const modal = useModal();
  const [sortedCommunities, setSortedCommunities] = useState<
    CommunitySwitcher[]
  >([]);
  const {
    communities,
    token,
    isLoadingCommunities,
    isReorderingCommunities,
    onSortCommunities,
  } = useCommunitySwitcher();

  const onSortHandler = (sortedCommunities: CommunitySwitcher[]) => {
    setSortedCommunities(sortedCommunities);
    onSortCommunities(sortedCommunities, {
      onError: () => {
        setSortedCommunities(communities);
      },
    });
  };

  useEffect(() => {
    if (isLoadingCommunities) {
      return;
    }
    setSortedCommunities(communities);
  }, [communities, isLoadingCommunities]);

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{t("my_communities")}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="md:my-6 md:!px-6 md:!py-0">
          <SortableList
            items={communities}
            lockedVerticalAxis
            onSort={onSortHandler}
            shouldUseKeyboardSensor={false}
          >
            <SortableList.List className="max-h-96">
              {sortedCommunities.map((community, index) => (
                <SortableList.Item
                  className="min-h-[3rem] min-w-min"
                  key={community.id}
                  item={community}
                  index={index}
                  tabIndex={-1}
                  disabled={isReorderingCommunities}
                >
                  <CommunitiesModalLink
                    key={community.id}
                    community={community}
                    href={getAutomaticLoginRootUrl(community.root_url, token)}
                    isDragDisabled={isReorderingCommunities}
                  />
                </SortableList.Item>
              ))}
            </SortableList.List>
            <SortableList.DragOverlay zIndex={1070}>
              <CommunityModalLinkDragOverlay />
            </SortableList.DragOverlay>
          </SortableList>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
