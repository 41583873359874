import { useCurrentPlanApi } from "@/react/components/SettingsApp/Plan/hooks/useCurrentPlanApi";
import { ActiveState } from "./ActiveState";
import { CancelAtPeriodEndState } from "./CancelAtPeriodEndState";
import { InactiveState } from "./InactiveState";

export const MarketingHubBillingDetails = () => {
  const { currentPlan } = useCurrentPlanApi();

  if (!currentPlan?.marketing_hub_billing) {
    return null;
  }

  const { billing_status } = currentPlan.marketing_hub_billing;

  if (billing_status === "active" || billing_status === "free_period") {
    return <ActiveState billingDetails={currentPlan.marketing_hub_billing} />;
  }

  if (billing_status === "cancel_at_period_end") {
    return (
      <CancelAtPeriodEndState
        billingDetails={currentPlan.marketing_hub_billing}
      />
    );
  }

  return <InactiveState />;
};
