import { uniqueId } from "lodash";
import { SortableContainer } from "react-sortable-hoc";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { AddLinkButton } from "./AddLinkButton";
import { LinkItem } from "./LinkItem";

export interface SortableLinksListProps {
  links: any[];
}

export const SortableLinksList = SortableContainer(
  ({ links }: SortableLinksListProps) => {
    const { currentCommunityMember } = usePunditUserContext();
    const isAdmin = isCommunityAdmin(currentCommunityMember);
    const isLgScreen = useLgScreenMediaQuery();
    const shouldDisableDragAndDropOnLinks = !isAdmin || !isLgScreen;

    return (
      <div className="flex flex-col gap-y-1">
        {links.map((link, index) => (
          <LinkItem
            index={index}
            disabled={shouldDisableDragAndDropOnLinks}
            isAdmin={isAdmin}
            key={uniqueId(String(index))}
            link={link}
          />
        ))}
        {isAdmin && <AddLinkButton />}
      </div>
    );
  },
);
