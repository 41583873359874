export const MIGRATION_FLOW_OPTIONS = Object.freeze({
  select_access_groups: "select_access_groups",
  new_access_group: "new_access_group",
});

export const FORM_FIELD_KEYS = Object.freeze({
  migration_flow: "migration_flow",
  name: "name",
  access_groups: "access_groups",
  paywall_id: "paywall_id",
  // eslint-disable-next-line no-restricted-syntax -- this is a constant
  description: "description",
});
