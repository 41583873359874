import I18n from "@/i18n-js/instance";
import { Collapsible } from "@circle-react/components/shared/uikit/Collapsible";
import { Form } from "@circle-react-uikit/Form";

function Moderators() {
  return (
    <Collapsible title={I18n.t("spaces.form.edit.options.moderators.label")}>
      <div className="edit-space__container">
        <Form.Item
          variant="extra-bottom-margin"
          label={I18n.t(
            "spaces.form.edit.options.moderators.space_moderators.label",
          )}
          description={I18n.t(
            "spaces.form.edit.options.moderators.space_moderators.description",
          )}
          name="moderator_ids"
          hideBorder
        >
          <Form.CommunityMemberSelect
            placeholder={I18n.t(
              "spaces.form.edit.options.moderators.space_moderators.placeholder",
            )}
            variant="tags"
            direction="down"
            valueField="id"
            excludeAdmins
          />
        </Form.Item>
      </div>
    </Collapsible>
  );
}

export default Moderators;
