import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { PreviewDmModalContainer } from "./PreviewDmModalContainer";
import { usePreviewDM } from "./usePreviewDM";

interface PreviewDmModalContentProps {
  body: object;
  communityMemberId: number;
  resourceAttributes: object;
  localSgidToObjectMap: object;
}

const PreviewDmModalContent = ({
  body,
  communityMemberId,
  resourceAttributes,
  localSgidToObjectMap = {},
}: PreviewDmModalContentProps) => {
  const {
    interpolatedBody,
    isLoadingCommunityMember,
    isLoadingPreviewMessageBody,
    communityMember,
  } = usePreviewDM({
    body,
    communityMemberId,
    resourceAttributes,
  });

  if (
    isLoadingCommunityMember ||
    isLoadingPreviewMessageBody ||
    !communityMember
  ) {
    return (
      <PreviewDmModalContainer>
        <Loader />
      </PreviewDmModalContainer>
    );
  }

  const { name, avatar_url: src } = communityMember;

  return (
    <PreviewDmModalContainer>
      <div className="px-2">
        <UserImage size="10" src={src} name={name} />
      </div>
      {Object.keys(interpolatedBody).length > 0 && (
        <div className="max-w-[calc(100%-3rem)] px-2">
          <div className="mb-2 flex items-center gap-x-2">
            <Typography.LabelMd weight="semibold">{name}</Typography.LabelMd>
            <Typography.LabelXs color="text-light" weight="medium">
              {t("settings.workflows.form.preview_message.placeholder_time")}
            </Typography.LabelXs>
          </div>
          <TiptapEditor
            rich_text_body={{
              body: interpolatedBody,
              sgids_to_object_map: localSgidToObjectMap,
            }}
            editable={false}
          />
        </div>
      )}
    </PreviewDmModalContainer>
  );
};

export const PreviewDmModal = ModalManager.create<PreviewDmModalContentProps>(
  props => <PreviewDmModalContent {...props} />,
);

export const usePreviewDmModal = () => useModal(PreviewDmModal);
