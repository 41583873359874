import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useConfirmDeleteActionModal } from "@circle-workflows/components/ActionRule/ConfirmDeleteActionModal";
import { useIsWorkflowActiveOrInProgress } from "@circle-workflows/components/EditWorkflowInfoBanner/useIsWorkflowActiveOrInProgress";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useWebhookTestManagement } from "../../hooks/useWebhookTestManagement";
import { availableActions } from "../Actions";
import { sendEmail } from "../Actions/Member";
import { ViewSendEmailCollapsed } from "../Actions/Member/sendEmail/ViewSendEmailCollapsed";
import { sendToWebhook } from "../Actions/Webhook";
import { WebhookTestButton } from "../Actions/Webhook/WebhookTestButton";
import { WebhookTestInfo } from "../Actions/Webhook/WebhookTestInfo";
import { useResourceStatus } from "../WorkflowForm/useResourceStatus";
import { ActionBlock } from "./ActionBlock";
import { useCurrentAction } from "./ActionContext";
import ActionContinueButton from "./ActionContinueButton";
import { ActionSelector } from "./ActionSelector";
import { useActions } from "./useActions";

export const getTitleByActionType = ({ actionType, apiParams }) => {
  const actionItem = availableActions.find(
    availableAction => availableAction.value === actionType,
  );
  if (actionItem && "getActionTitle" in actionItem) {
    return actionItem.getActionTitle(apiParams);
  }
  return t(`settings.workflows.view.actions.${actionType}.title`, {
    defaultValue: t("settings.workflows.form.add_an_action"),
  });
};

export const Action = ({
  hasBottomConnection = true,
  onAddClick,
  onDeleteClick,
}) => {
  const { isUpdatingAction, doesActionhasServerId } = useActions();
  const { index, action, path } = useCurrentAction();
  const { isCompleted, hasError, statusMessage, revalidate, hasValue } =
    useResourceStatus(path);
  const { result: isWorkflowActiveOrInProgress } =
    useIsWorkflowActiveOrInProgress();
  const confirmDeleteActionModal = useConfirmDeleteActionModal();
  const [title, setTitle] = useState("");

  const actionType = action?.action_type;
  const apiParams = action?.api_params;

  const actionObject = availableActions.find(
    availableAction => availableAction.value === actionType,
  );

  const { getValues } = useFormContext();
  const triggerType = getValues("trigger.trigger_type");

  const requestBody = {
    webhook_test: {
      url: apiParams?.url,
      trigger_type: triggerType,
    },
  };

  const {
    isTestingWebhook,
    isWebhookTestSuccessful,
    isWebhookTested,
    isWebhookUrlChanged,
    testWebhookResult,
    handleTestWebhook,
  } = useWebhookTestManagement(action, revalidate);

  const updateTitle = useCallback(() => {
    setTitle(getTitleByActionType({ actionType, apiParams }));
  }, [actionType, apiParams]);

  useEffect(() => {
    updateTitle();
  }, [updateTitle]);

  const handleDeleteClick = async index => {
    if (!doesActionhasServerId(action) || !isWorkflowActiveOrInProgress) {
      await onDeleteClick(index);
      return;
    }

    await confirmDeleteActionModal.show({
      onConfirm: async () => {
        void confirmDeleteActionModal.hide();
        void onDeleteClick(index);
      },
      onCancel: () => {
        void confirmDeleteActionModal.hide();
      },
    });
  };

  return (
    <ActionBlock
      id={action?.id || index + 1}
      key={action.id}
      actionType={actionType}
      hasBottomConnection={hasBottomConnection}
      onAddClick={async () => {
        const isValid = await revalidate();
        if (!isValid) return;
        onAddClick(index);
      }}
      onDeleteClick={() => {
        void handleDeleteClick(index);
      }}
      hasError={hasError}
      hasSuccess={isCompleted}
      statusMessage={statusMessage}
      title={title}
      isApplicableToMembersOnly={actionObject?.isApplicableToMembersOnly}
      collapsedViewMetaComponent={
        actionType === sendEmail.value && <ViewSendEmailCollapsed />
      }
    >
      <ActionSelector index={index} />
      {isWebhookTested && testWebhookResult && (
        <WebhookTestInfo
          isWebhookTestSuccessful={isWebhookTestSuccessful}
          webhookTestResult={testWebhookResult}
        />
      )}
      {actionType === sendToWebhook.value &&
      (!isWebhookTestSuccessful || isWebhookUrlChanged) ? (
        <WebhookTestButton
          handleTestWebhook={() => handleTestWebhook(requestBody)}
          isDisabled={
            isUpdatingAction ||
            !hasValue ||
            isTestingWebhook ||
            !triggerType ||
            triggerType === ""
          }
          isTestingWebhook={isTestingWebhook}
          isWebhookTestSuccessful={isWebhookTestSuccessful}
          isWebhookUrlChanged={isWebhookUrlChanged}
          isWebhookTested={isWebhookTested}
          triggerType={triggerType}
        />
      ) : (
        <ActionContinueButton onSubmit={updateTitle} />
      )}
    </ActionBlock>
  );
};

Action.propTypes = {
  hasBottomConnection: PropTypes.bool,
  onAddClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};
