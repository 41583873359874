import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceOption } from "../../SpaceAccessManagementContext";
import { i18nRoot } from "../../constants";
import { Item } from "./Item";
import { ItemButton } from "./ItemButton";
import { ItemTitleWithIcon } from "./ItemTitleWithIcon";
import { RemovableItemTitleWrapper } from "./RemovableItemTitleWrapper";
import { useChildItem } from "./hooks/useChildItem";

interface SpaceOptionWithSpaceGroupId extends SpaceOption {
  spaceGroupId: number;
  onRemoveChild: (action: () => void, object: SpaceOption) => void;
}

export const ChildRemovableItem = ({
  spaceGroupId,
  onRemoveChild = action => {
    action();
  },
  ...space
}: SpaceOptionWithSpaceGroupId) => {
  const { name, icon, tooltipText } = space;
  const {
    isChecked,
    isSpaceFound,
    isRemovable,
    isNotRemovableTooltip,
    onRemoveClick,
  } = useChildItem({
    space,
    spaceGroupId,
    onRemoveChild,
  });

  return (
    <Item
      className={classnames({ hidden: !isChecked || !isSpaceFound })}
      isDisabled={!isChecked || !isRemovable}
      onClick={isChecked && isRemovable ? onRemoveClick : undefined}
    >
      <RemovableItemTitleWrapper
        isRemovable={isRemovable}
        isNotRemovableTooltip={isNotRemovableTooltip}
      >
        <ItemTitleWithIcon icon={icon} tooltipText={tooltipText}>
          <Typography.LabelSm>{name}</Typography.LabelSm>
        </ItemTitleWithIcon>
      </RemovableItemTitleWrapper>
      {isChecked && isRemovable && (
        <ItemButton actionText={t([i18nRoot, "remove"])} />
      )}
    </Item>
  );
};
