import { t } from "@/i18n-js/instance";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useBulkDeleteSnippets } from "../snippetsQueries";

export const BulkDeleteSnippetModalComponent = ({
  selectedIds,
  setSelectedIds,
}: {
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
}) => {
  const modal = useModal();
  const bulkDeleteSnippetMutation = useBulkDeleteSnippets({
    onSuccess: () => {
      modal.remove();
      setSelectedIds([]);
    },
  });

  return (
    <ConfirmationModal
      size="lg"
      isOpen={modal.visible}
      onClose={modal.hide}
      title={t("settings.community_bot.knowledge.custom.delete_resource", {
        count: selectedIds.length,
      })}
      confirmVariant="danger"
      confirmText={
        bulkDeleteSnippetMutation.isLoading
          ? t(
              "settings.community_bot.knowledge.custom.bulk_delete_snippet_modal.confirming",
            )
          : t(
              "settings.community_bot.knowledge.custom.bulk_delete_snippet_modal.confirm",
            )
      }
      onConfirm={async () => {
        await bulkDeleteSnippetMutation.mutateAsync({ ids: selectedIds });
      }}
      disabled={bulkDeleteSnippetMutation.isLoading}
    >
      <Typography.BodyMd>
        <SanitizeHTMLString
          content={t(
            "settings.community_bot.knowledge.custom.bulk_delete_snippet_modal.description",
            {
              count: selectedIds.length,
            },
          )}
        />
      </Typography.BodyMd>
    </ConfirmationModal>
  );
};
