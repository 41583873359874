import { MemoryRouter } from "react-router-dom";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { AccessGroupDataProvider } from "./AccessGroupMembers/DataStoreContext/AccessGroupDataContext";
import { EditAccessGroupModalBody } from "./EditAccessGroupModalBody";

interface EditAccessGroupModalContainerProps {
  accessGroup: AccessGroupType;
  handleClose: () => void;
  isOpen?: boolean;
  initialTab?: string;
}
export const EditAccessGroupModalContainer = ({
  accessGroup,
  handleClose,
  initialTab = "",
  isOpen = true,
}: EditAccessGroupModalContainerProps) => (
  <AccessGroupDataProvider accessGroup={accessGroup}>
    <MemoryRouter>
      <FullScreenModal
        isOpen={isOpen}
        onClose={handleClose}
        shouldShowCloseButton
      >
        <EditAccessGroupModalBody
          onClose={handleClose}
          initialTab={initialTab}
        />
      </FullScreenModal>
    </MemoryRouter>
  </AccessGroupDataProvider>
);
