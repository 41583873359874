import { StandardLayout } from "@/react/components/Layout/StandardLayout";
import { RightSidebarContent } from "@/react/components/Spaces/RightSidebarContent";
import { isEventsSpace } from "@/react/helpers/spaceHelpers";
import { Sidebar } from "@circle-react/components/Events/Sidebar";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { CurrentPostContextProvider } from "@circle-react/contexts";
import { useHasPostRightSidebar } from "../hooks/useHasPostRightSidebar";

export const RightSidebar = ({ postResource, className }) => {
  const { data: space } = useCurrentSpaceContext();
  const hasPostRightSidebar = useHasPostRightSidebar();

  return (
    <StandardLayout.Right className={className} hidden={!hasPostRightSidebar}>
      <CurrentPostContextProvider post={postResource?.data}>
        {isEventsSpace(space) ? (
          <Sidebar onChange={postResource?.onPostChange} />
        ) : (
          <RightSidebarContent />
        )}
      </CurrentPostContextProvider>
    </StandardLayout.Right>
  );
};
