import { t } from "@/i18n-js/instance";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { AdminResource } from "./AdminResource";
import { AdminSelector } from "./AdminSelector";

export const receiveDM: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_sent_dm_to_admin.title",
  ),
  value: "community_member_sent_dm_to_admin",
  group: t("settings.workflows.form.event_groups.messaging"),
  description: t(
    "settings.workflows.form.events.community_member_sent_dm_to_admin.description",
  ),
  formComponent: AdminSelector,
  resource: TRIGGER_RESOURCE_TYPE.COMMUNITY_MEMBER_ADMIN,
  viewComponent: AdminResource,
  icon: "email",
};
