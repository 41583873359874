import { t } from "@/i18n-js/instance";
import { StripeOnboarding } from "../StripeOnboarding";
import { PaymentProcessorStatusAlert } from "./PaymentProcessorStatusAlert";

export const PaymentProcessorNotExist = () => {
  const title = t("settings.paywalls.processor_status_v2.onboarding.title");

  return (
    <div className="flex h-full w-full flex-col gap-y-4 self-center md:w-3/4">
      <PaymentProcessorStatusAlert
        sentiment="default"
        title={title}
        description={<StripeOnboarding />}
      />
      <p
        className="text-default text-xs"
        dangerouslySetInnerHTML={{
          __html: t(
            "settings.paywalls.processor_status_v2.onboarding.stripe_regulations_description",
          ),
        }}
      />
    </div>
  );
};

PaymentProcessorNotExist.propTypes = {};
