import { t } from "@/i18n-js/instance";
import { useConfirmModal } from "@circle-react/components/LiveStreams/Room/hooks";
import { useLiveRoom } from "@circle-react/components/LiveStreams/hooks";
import { roomTypeData } from "@circle-react/helpers/liveStreamRoomHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const LiveIndicator = () => {
  const { room, canManage, handleNavigateToRoom, handleEndStream } =
    useLiveRoom();

  const { confirmModal, onConfirmButtonClick } = useConfirmModal({
    title: t(`live_streams.${roomTypeData(room)}_ended.confirm`),
    onConfirm: handleEndStream,
  });

  if (!room) {
    return null;
  }

  return (
    <div className="bg-sidebar-footer-live-indicator mb-1 w-full max-w-[256px] rounded-xl p-4">
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <UserImage
            size="6"
            src={room?.creator?.avatar_url}
            name={room?.creator?.name}
          />
          <Typography.BodySm color="text-white max-w-[15ch]" truncate>
            {room?.creator?.name}
          </Typography.BodySm>
          <Icon type="24-live-waveform" size={24} />
        </div>
        <div className="bg-c-sidebar-badge flex items-center justify-center rounded-[4px] px-2 py-1">
          <span className="text-xxs font-bold uppercase leading-3 text-white">
            {t("sidebar_footer_v3.live")}
          </span>
        </div>
      </div>
      <Typography.BodyMd color="text-white line-clamp-2" weight="font-semibold">
        {room?.name}
      </Typography.BodyMd>
      <Button full className="!bg-circle mt-4" onClick={handleNavigateToRoom}>
        {t("sidebar_footer_v3.join")}
      </Button>
      {canManage && (
        <Typography.BodyXs color="text-white mt-2">
          {t("live_streams.room.cant_join")}
          <button
            type="button"
            className="ml-1 cursor-pointer text-xs font-semibold text-white"
            onClick={onConfirmButtonClick}
          >
            {t(`live_streams.room.${roomTypeData(room)}.end`)}
          </button>
        </Typography.BodyXs>
      )}
      {confirmModal}
    </div>
  );
};
