import isFunction from "lodash/isFunction";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useOnboarding } from "@/react/components/CommunityOnboarding/useOnboarding";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { usePunditUserContext } from "@circle-react/contexts";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Typography } from "@circle-react-uikit/Typography";

interface HideOnboardingConfirmationProps {
  onClose?: () => void;
}

const HideOnboardingConfirmationModalComponent = ({
  onClose,
}: HideOnboardingConfirmationProps) => {
  const drawer = useModal();
  const { currentCommunity } = usePunditUserContext();
  const { completeWholeOnboarding, refetchOnboarding } = useOnboarding();
  const history = useHistory();

  const onClickRemoveOnboarding = async () => {
    const isHomePageEnabled = currentCommunity?.home_page_enabled;
    await completeWholeOnboarding();
    await refetchOnboarding();
    isFunction(onClose) && onClose();
    void drawer.hide();
    if (isHomePageEnabled) {
      return history.push("/home");
    }
    // necessary because Rails layout won't refresh when router does
    window.location.href = "/";
  };

  return (
    <ConfirmationModal
      isOpen={drawer.visible}
      onClose={drawer.hide}
      title={t("remove_onboarding_confirmation_modal.title")}
      cancelText={t("remove_onboarding_confirmation_modal.cancel")}
      confirmText={t("remove_onboarding_confirmation_modal.confirm")}
      onConfirm={onClickRemoveOnboarding}
    >
      <Typography.BodyMd>
        {t("remove_onboarding_confirmation_modal.description")}
      </Typography.BodyMd>
    </ConfirmationModal>
  );
};

const HideOnboardingConfirmationModal = ModalManager.create(
  ({ onClose }: HideOnboardingConfirmationProps) => (
    <HideOnboardingConfirmationModalComponent onClose={onClose} />
  ),
);

export const useHideOnboardingConfirmationModal = () =>
  useModal(HideOnboardingConfirmationModal);
