import { QueryClient, QueryClientProvider } from "react-query";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ManageAddOnsForm } from "./ManageForm/ManageAddOnsForm";
import { AddOnsSettingsContextProvider } from "./addOnsSettingsContext";

const queryClient = new QueryClient();

export interface ManageAddOnsModalProps {
  onSuccess?: () => Promise<void> | void;
  refetchCurrentPlan?: () => Promise<void> | void;
}

export const ManageAddOnsModalComponent = ({
  onSuccess,
  refetchCurrentPlan,
}: ManageAddOnsModalProps) => {
  const toast = useToast();
  const drawer = useModal();

  const onAfterSubmit = () => {
    if (!onSuccess) return;

    onClose();

    if (toast) {
      toast.success(t("settings.billing.add_ons.changes_succeeded"));
    }
  };

  const onClose = () => {
    void drawer.hide();
    drawer.remove();
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AddOnsSettingsContextProvider
        onSuccess={onSuccess}
        onCloseModal={onClose}
        refetchCurrentPlan={refetchCurrentPlan}
      >
        <Modal
          isOpen={drawer.visible}
          onClose={onClose}
          className="manage-add-ons__modal"
          title={t("settings.billing.add_ons.manage_addons")}
        >
          <Modal.Overlay />
          <Modal.Content size="lg">
            <Modal.Header>
              <Modal.Title size="sm">
                {t("settings.billing.add_ons.manage_addons")}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <ManageAddOnsForm onAfterSubmit={onAfterSubmit} />
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </AddOnsSettingsContextProvider>
    </QueryClientProvider>
  );
};

const ManageAddOnsModal = ModalManager.create(
  ({ onSuccess, refetchCurrentPlan }: ManageAddOnsModalProps) => (
    <ManageAddOnsModalComponent
      onSuccess={onSuccess}
      refetchCurrentPlan={refetchCurrentPlan}
    />
  ),
);

export const useManageAddonModal = () => useModal(ManageAddOnsModal);
