import type { ComponentPropsWithoutRef } from "react";
import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export const SidebarWrapper = ({
  children,
  className,
  ...rest
}: ComponentPropsWithoutRef<"div">) => (
  <aside
    aria-label={t("left_sidebar")}
    className={classNames("standard-layout-v2__sidebar", className)}
    data-testid="standard-layout-v2-sidebar"
    {...rest}
  >
    <nav>{children}</nav>
  </aside>
);
