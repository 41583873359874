import { usePunditUserContext } from "@circle-react/contexts";

export const useWhiteLabel = () => {
  const { currentCommunity } = usePunditUserContext();

  const isWhiteLabel = !!currentCommunity?.white_label;
  const shouldShowBranding = !isWhiteLabel;

  return {
    whiteLabel: isWhiteLabel,
    shouldShowBranding,
  };
};
