import { t } from "@/i18n-js/instance";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Button } from "@circle-react-uikit/Button";
import { EMAIL_REGEX } from "@circle-react-uikit/CommunityMemberSelect";
import { TableCell, TableRow } from "@circle-react-uikit/Table";
import { Typography } from "@circle-react-uikit/Typography";

const stringIsEmail = (text: string) => EMAIL_REGEX.test("" + text);

export interface RowProps {
  id: string;
  avatarUrl: string;
  name: string;
  onRemove: (id: string) => void;
}

export const Row = ({ id, avatarUrl, name, onRemove }: RowProps) => (
  <TableRow key={id} className="group h-12">
    <TableCell variant="no-padding" className="rounded-l-lg">
      <div className="ml-4 mr-3">
        <UserImage
          name={stringIsEmail(name) ? name.charAt(0) : name}
          src={avatarUrl}
          size="8"
        />
      </div>
    </TableCell>
    <TableCell variant="no-padding" className="w-full">
      <div className="truncate p-2 pl-0">
        <Typography.LabelSm weight="semibold">{name}</Typography.LabelSm>
      </div>
    </TableCell>
    <TableCell variant="no-padding" className="w-24 rounded-r-lg">
      <div className="text-dark invisible group-hover:visible">
        <Button type="button" variant="plain" onClick={() => onRemove(id)}>
          {t("remove")}
        </Button>
      </div>
    </TableCell>
  </TableRow>
);
