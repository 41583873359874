import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { ROOM_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { Modal } from "@circle-react-uikit/Modal";
import { Tabs } from "@circle-react-uikit/Tabs";
import { Details } from "./Details";
import { Participants } from "./Participants";
import "./styles.scss";

export interface DetailsModalProp {
  isOpen: boolean;
  room: any;
  onClose: () => void;
  refetch: () => void;
}

export const DetailsModal = ({
  isOpen,
  onClose,
  room,
  refetch,
}: DetailsModalProp) => {
  const TABS = Object.freeze({
    DETAILS: t("live_streams.dashboard.details.tabs.details"),
    PARTICIPANTS: t("live_streams.dashboard.details.tabs.participants"),
  });
  const tabOptions = Object.values(TABS).map(label => ({ label }));
  const [activeTab, setActiveTab] = useState(TABS.DETAILS);

  const roomType = room?.room_type || ROOM_TYPES.STREAM;

  if (!room) return null;

  return (
    <Modal
      variant="large"
      show={isOpen}
      onClose={onClose}
      className="stream-details"
    >
      <div className="stream-details__title">
        {t(`live_streams.dashboard.details.${roomType}.title`)}
      </div>
      <Tabs
        controlled
        activeTab={activeTab}
        onClick={setActiveTab}
        options={tabOptions}
        variant="underline"
      />
      {activeTab === TABS.DETAILS && <Details room={room} refetch={refetch} />}
      {activeTab === TABS.PARTICIPANTS && (
        <Participants
          roomId={room?.id}
          participantsCount={room?.total_participants}
        />
      )}
    </Modal>
  );
};
