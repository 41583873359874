import { useClose } from "./Context";
import { menuItemClasses } from "./helpers";

export interface ButtonItemProps {
  label: string;
  active?: boolean;
  onClick?: () => void;
}

export const ButtonItem = ({
  label,
  active = false,
  onClick,
}: ButtonItemProps) => {
  const handleClick = useClose(onClick);

  return (
    <button
      key={label}
      type="button"
      onClick={handleClick}
      className={menuItemClasses(active)}
      title={label}
    >
      {label}
    </button>
  );
};
