import { useWhiteLabel } from "@circle-react/hooks/useWhiteLabel";
import { useShouldDisplayGoLiveFooter } from "../SidebarFooter/useShouldDisplayGoLiveFooter";

const poweredByHeightV3 = 16;
const goLiveButtonHeightV3 = 39;

/* TODO: Remove this logic when migrating the StandardLayout to a CSS flex/grid approach */
export const useGoLivePoweredByHeight = () => {
  const hasGoLiveButton = useShouldDisplayGoLiveFooter();
  const { shouldShowBranding } = useWhiteLabel();

  const containerPadding = shouldShowBranding || hasGoLiveButton ? 31 : 0;
  const containerGap = shouldShowBranding && hasGoLiveButton ? 8 : 0;

  const extraContainerSpacing = containerPadding + containerGap;

  return {
    goLiveButtonHeight: goLiveButtonHeightV3,
    poweredByHeight: poweredByHeightV3,
    extraContainerSpacing,
  };
};
