export const adminIconsV3 = {
  membersIcon: "20-users-v3",
  postsIcon: "20-doc-v3",
  marketingHubIcon: "16-send", // TODO: replace when design is ready
  spacesCrudIcon: "20-spaces-v3",
  moderationIcon: "20-shield-v3",
  liveStreamIcon: "20-live-v3",
  workflowsIcon: "20-flash-v3",
  aiAgentsIcon: "20-community-bot",
  aiInboxIcon: "20-community-inbox",
  paywallsIcon: "20-currency-v3",
  plansIcon: "20-card-v3",
  analyticsIcon: "20-analytics-v3",
  affiliatesIcon: "20-affiliates-v3",
  settingsIcon: "20-settings-v3",
  toggleThemeIcon: "20-moon-v3",
  toggleThemeLightIcon: "20-moon-v3",
  toggleThemeDarkIcon: "20-moon-v3", // TODO: replace when design is ready
  customizeThemeIcon: "20-color-palette-v3",
  viewAsIcon: "20-open-eye-v3",
  inviteMemberIcon: "20-user-plus-v3",
  keyboardShortcutsIcon: "20-keyboard-shortcuts",
  plusIcon: "16-20-mobile",
} as const;
