import type { CommunityMember } from "@circle-react/types";
import { useAccessGroupData } from "../../DataStoreContext/AccessGroupDataContext";

export interface MemberIdCheckBoxCellType {
  accessGroupId: number;
  communityMember: CommunityMember;
}
export const MemberIdCheckBoxCell = ({
  communityMember,
}: MemberIdCheckBoxCellType) => {
  const { selectedMemberIds, addMemberId, updateTotalMembersToProcess } =
    useAccessGroupData();

  const handleOnChange = () => {
    addMemberId(communityMember.id);
    updateTotalMembersToProcess(0);
  };

  // todo: define this based on registry entries
  const isFromPaywall = false;
  // const isFromPaywall = !!accessGroupId;
  // const isFromPaywall = !!find(communityMember.access_groups, {
  //   access_group_id: accessGroupId,
  //   grant_source_type: GRANT_SOURCES.PAYWALL,
  // });

  return (
    <input
      type="checkbox"
      className="text-dark bg-primary hover:bg-tertiary hover:border-secondary disabled:bg-tertiary disabled:border-selector-inactive border-secondary focus:bg-tertiary focus:border-secondary placeholder:text-light h-4 w-4 cursor-pointer rounded align-middle placeholder:opacity-100 focus:ring-0 focus:ring-offset-0"
      id={`community-member-${communityMember.id}-box`}
      checked={selectedMemberIds.includes(communityMember.id)}
      onChange={handleOnChange}
      disabled={isFromPaywall}
    />
  );
};
