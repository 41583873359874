import { NavLink } from "react-router-dom";
import { useClose } from "./Context";
import { menuItemClasses } from "./helpers";

export interface LinkItemProps {
  linkTo: string;
  label: string;
  active?: boolean;
  onClick?: () => void;
}

export const LinkItem = ({
  linkTo,
  label,
  active = false,
  onClick,
}: LinkItemProps) => {
  const handleClick = useClose(onClick);
  return (
    <NavLink
      key={label}
      to={linkTo}
      onClick={handleClick}
      className={menuItemClasses(active)}
      title={label}
    >
      {label}
    </NavLink>
  );
};
