import { t } from "@/i18n-js/instance";
import { useAccessGroup } from "@circle-react/hooks/accessRegistry/useAccessGroup";
import { Typography } from "@circle-react-uikit/Typography";
import { Badge } from "../../../Badge";
import { BaseView } from "../../BaseView";

const i18nRoot = "settings.workflows.view";

export const ViewAccessGroup = ({
  action,
}: {
  action: { api_params: { access_group_id: number } };
}) => {
  const { api_params: apiParams } = action || {};
  const { access_group_id: accessGroupId } = apiParams || {};
  const { accessGroup, isLoadingAccessGroup } = useAccessGroup(accessGroupId);

  if (!accessGroup && !isLoadingAccessGroup) {
    return null;
  }

  return (
    <BaseView>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "access_group"])}</BaseView.Title>
        <div>
          {isLoadingAccessGroup ? (
            <Badge isLoading={isLoadingAccessGroup} />
          ) : (
            <Typography.LabelSm>{accessGroup?.name}</Typography.LabelSm>
          )}
        </div>
      </BaseView.Section>
    </BaseView>
  );
};
