import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { TabNavigation, TabView } from "@circle-react-shared/TabNavigation";

const contentTab = t("courses.lockscreen.tabs.content");
const designTab = t("courses.lockscreen.tabs.design");
const editTabs = [{ label: contentTab }, { label: designTab }];

export const EditTabs = ({ children, ...rest }) => (
  <TabNavigation
    tabs={editTabs}
    initialTab={contentTab}
    wrapperClassName="px-0 overflow-x-auto"
    {...rest}
  >
    {children}
  </TabNavigation>
);

EditTabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ContentTab = ({ children, ...rest }) => (
  <TabView tab={contentTab} className="overflow-y-visible" {...rest}>
    {children}
  </TabView>
);

ContentTab.propTypes = {
  children: PropTypes.node.isRequired,
};

export const DesignTab = ({ children, ...rest }) => (
  <TabView tab={designTab} className="overflow-y-visible" {...rest}>
    {children}
  </TabView>
);

DesignTab.propTypes = {
  children: PropTypes.node.isRequired,
};
