import { useState } from "react";
import classNames from "classnames";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { supportWidgetPaths } from "@circle-react/helpers/urlHelpers";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { LabelMd } from "@circle-react-shared/uikit/Typography/components/Label/LabelMd";
import { HeaderIconButton } from "../../HeaderIconButton";
import { PostList } from "../../PostList";
import { SearchBar } from "../../SearchBar";
import { SpaceGroupList } from "../../SpaceGroupList";
import { useSupportWidgetContext } from "../../SupportWidgetContext";
import "./styles.css";

export const HelpView = () => {
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  const { spaceId } = useParams();
  const {
    currentSpaceProperties: {
      custom_emoji_url,
      custom_emoji_dark_url,
      emoji,
      name,
    },
  } = useSupportWidgetContext();
  const shouldRenderPostList = searchText || spaceId;
  const isSpaceGroupsRoutes = useRouteMatch(supportWidgetPaths.spaceGroups());

  return (
    <div className="flex flex-col">
      <div
        className={classNames(
          "bg-primary sticky top-0 z-10 flex h-[52px] shrink-0 items-center space-x-4 border-b px-3",
          { "justify-between": spaceId, "justify-center": !spaceId },
        )}
      >
        {spaceId && (
          <HeaderIconButton
            type="back"
            onClick={() => history.replace("/space_groups")}
          />
        )}
        {isSpaceGroupsRoutes ? (
          <LabelMd weight="semibold">
            {t("support_widget.search_container.title")}
          </LabelMd>
        ) : (
          <div className="text-dark help-view-title flex">
            <EmojiRenderer
              className="!h-5 !w-auto"
              emoji={emoji}
              customEmojiUrl={custom_emoji_url}
              customEmojiDarkUrl={custom_emoji_dark_url}
            />
            <span className="ml-2">
              <LabelMd weight="semibold">{name}</LabelMd>
            </span>
          </div>
        )}
        {spaceId && <div className="w-[44px]" />}
      </div>
      <div className="px-6 pb-2 pt-5">
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>
      {shouldRenderPostList ? (
        <PostList spaceId={spaceId} searchText={searchText} />
      ) : (
        <SpaceGroupList />
      )}
    </div>
  );
};
