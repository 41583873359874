import type { ReactNode } from "react";
import { NavLink } from "react-router-dom";

export interface NavLinkWrapperProps {
  children: ReactNode;
  path: string;
}

export const NavLinkWrapper = ({ children, path }: NavLinkWrapperProps) => (
  <NavLink
    exact
    to={path}
    className="hover:bg-secondary hover:border-dark flex items-center justify-center rounded-lg border border-transparent p-2.5 transition-all duration-150 ease-linear"
    activeClassName="bg-tertiary"
    isActive={(_, location) => location.pathname === path}
  >
    {children}
  </NavLink>
);
