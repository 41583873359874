import { t } from "@/i18n-js/instance";
import { useLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Checkbox } from "@circle-react-uikit/Checkbox";

export const NotificationPreferenceFields = () => {
  const screenLg = useLgScreenMediaQuery();

  const list = [
    {
      label: t("spaces.notification_email_label"),
      description: t("spaces.notification_email_description"),
      name: "default_notification_setting",
      icon: {
        default: "notifications-email",
        selected: "notifications-email-active",
        viewBox: "0 0 88 88",
      },
      multi: true,
    },
    {
      label: t("spaces.notification_in_app_label"),
      description: t("spaces.notification_in_app_description"),
      name: "default_in_app_notification_setting",
      icon: {
        default: "notifications-app",
        selected: "notifications-app-active",
        viewBox: "0 0 88 88",
      },
      multi: true,
    },
    {
      label: t("spaces.notification_mobile_label"),
      description: t("spaces.notification_mobile_description"),
      name: "default_mobile_notification_setting",
      icon: {
        default: "notifications-mobile",
        selected: "notifications-mobile-active",
        viewBox: "0 0 88 88",
      },
      multi: true,
    },
    {
      label: t("spaces.no_notification_label"),
      description: t("spaces.no_notification_description"),
      name: "none",
      icon: {
        default: "notifications-none",
        selected: "notifications-none-active",
        viewBox: "0 0 88 88",
      },
      multi: false,
    },
  ];

  return (
    <div className="new-space-form__notification">
      <h2 className="heading-h2 heading-max-width">
        {I18n.t("spaces.form.create.notification_step_title")}
      </h2>
      <div className="new-space-form__notification-options">
        <Checkbox
          id="notification_preferences"
          name="notification_preferences"
          variant="box"
          direction={screenLg ? "horizontal" : "vertical"}
          list={list}
          multi
        />
      </div>
    </div>
  );
};
