import InnerHTML from "dangerously-set-html-content";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { InfoBox } from "@circle-react-shared/uikit/InfoBox";

export const SsoInfoMessage = () => (
  <InfoBox className="flex gap-x-4">
    <div>
      <Icon type="20-info" size={24} aria-hidden="true" />
    </div>
    <InnerHTML html={t("settings_sso_add_members_suggestion")} />
  </InfoBox>
);
