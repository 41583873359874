import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";

export const DeleteConfirmationModalComponent = ({
  onConfirm,
  disabled,
}: any) => {
  const modal = useModal();
  return (
    <ActionModal
      isOpen={modal.visible}
      onClose={modal.hide}
      actionVariant="danger"
      actionText={t("settings.workflows.table.actions.delete")}
      title={t("settings.workflows.table.actions.delete_confirm_title")}
      onAction={onConfirm}
      disabled={disabled}
    >
      {t("settings.workflows.table.actions.delete_confirm_description")}
    </ActionModal>
  );
};

export const DeleteConfirmationModal = ModalManager.create(
  ({ onConfirm, disabled }: any) => (
    <DeleteConfirmationModalComponent
      onConfirm={onConfirm}
      disabled={disabled}
    />
  ),
);

export const useDeleteConfirmationModal = () =>
  useModal(DeleteConfirmationModal);
