import { useCallback, useEffect, useState } from "react";
import type { FocusEvent, MouseEvent } from "react";
import { flatten, uniqBy } from "lodash";
import { useInfiniteQuery, useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import type {
  AdvancedSearchResult,
  AdvancedSearchResultAgent,
  SearchType,
} from "@/react/components/search/types";
import type { SearchCountResultResponse } from "@/react/components/search/types";
import { usePunditUserContext } from "@/react/contexts";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useDebouncedValue } from "@/react/hooks/utils/useDebouncedValue";
import { useFilterContext } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { useSortContext } from "@circle-react/components/Modals/SearchResultsModal/SortContext";
import type { Profile } from "@circle-react/types/CommunityBot/Profile";
import { useCommunityBotProfileSpotlightSearch } from "../SettingsApp/CommunityInbox/hooks/useCommunityBotProfileSpotlightSearch";

interface SearchResultsResponse {
  records: AdvancedSearchResult[];
  page: number;
  per_page: number;
  has_next_page: boolean;
  count: number;
  page_count: number;
}

interface UseSearchAutocompleteV2Props {
  onPressEnter: (event: any, selectedResultIndex?: number) => void;
  scrollToElement: (selectedIndex: number) => void;
  type: SearchType;
  shouldDisplayAgents?: boolean;
}

export const useSearchAutocompleteV2 = ({
  onPressEnter,
  scrollToElement,
  type,
  shouldDisplayAgents = false,
}: UseSearchAutocompleteV2Props) => {
  const { sort } = useSortContext();
  const { filters } = useFilterContext();
  const [results, setResults] = useState<AdvancedSearchResult[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 300);
  const [hasNoResults, setHasNoResults] = useState(false);
  const [selectedResultIndex, setSelectedResultIndex] = useState(0);
  const { currentCommunitySettings } = usePunditUserContext();
  const nonEmptyFilters = Object.entries(filters).reduce<any>(
    (acc, [key, value]) => {
      if (value && value.length) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );

  const queryKey = [
    "search-results",
    debouncedSearchValue,
    type,
    sort[type],
    nonEmptyFilters,
  ];

  const fetchParticipants = (page: any) =>
    reactQueryGet(
      internalApi.searchV2.advanced.index({
        params: {
          query: debouncedSearchValue,
          per_page: 20,
          page,
          type,
          order: sort[type],
          filters: nonEmptyFilters,
        },
      }),
    );

  const { data, isLoading, error, fetchNextPage, hasNextPage } =
    useInfiniteQuery<SearchResultsResponse, Error>(
      queryKey,
      ({ pageParam = 1 }) => fetchParticipants(pageParam),
      {
        getNextPageParam: lastPage =>
          lastPage.has_next_page ? lastPage.page + 1 : undefined,
        enabled: !!debouncedSearchValue,
        refetchOnWindowFocus: false,
      },
    );

  const { data: countData, isLoading: isLoadingCount } = useQuery<
    SearchCountResultResponse,
    Error
  >(
    ["search-count", debouncedSearchValue],
    () =>
      reactQueryGet(
        internalApi.searchV2.count.index({
          params: {
            query: debouncedSearchValue,
          },
        }),
      ),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(debouncedSearchValue.length) && type !== "general",
    },
  );

  const { agents } = useCommunityBotProfileSpotlightSearch({
    enabled:
      Boolean(debouncedSearchValue.length) &&
      shouldDisplayAgents &&
      Boolean(currentCommunitySettings?.community_bot_enabled),
  });

  useEffect(() => {
    if (debouncedSearchValue && (data?.pages || agents?.length)) {
      const agentResults: AdvancedSearchResultAgent[] =
        !!agents?.length && debouncedSearchValue
          ? agents.map((agent: Profile) => ({
              type: "agent",
              highlighted_name: t("community_inbox.ask_agent", {
                agent_name: agent.name,
              }),
              ...agent,
            }))
          : [];
      const contentResults: AdvancedSearchResult[] =
        data?.pages && debouncedSearchValue
          ? uniqBy(flatten(data.pages.map(page => page.records)), "id")
          : [];
      const results = [...agentResults, ...contentResults];
      setHasNoResults(!!(results.length === 0 && debouncedSearchValue));
      setSelectedResultIndex(0);
      setResults(results);
    }
  }, [data, agents, debouncedSearchValue]);

  const resetSearch = useCallback(() => {
    setSearchValue("");
    setSelectedResultIndex(0);
    resetResults();
  }, []);

  const onMouseOver = (event: MouseEvent) => {
    const index = event.currentTarget.getAttribute("aria-posinset");
    setSelectedResultIndex(Number(index));
  };

  const onFocus = (event: FocusEvent) => {
    const index = event.currentTarget.getAttribute("aria-posinset");
    setSelectedResultIndex(Number(index));
  };

  const onMouseOut = (event: any) => {
    const index = event.currentTarget.getAttribute("aria-posinset");
    if (index === selectedResultIndex) {
      setSelectedResultIndex(-1);
    }
  };

  const onKeydown = (event: any) => {
    switch (event.key) {
      case "Escape":
        if (results.length > 0) {
          resetSearch();
          event.stopPropagation();
          event.preventDefault();
        }
        break;
      case "ArrowDown":
        {
          setSelectedResultIndex(current => {
            if (current < results.length) {
              const updatedCount = current + 1;
              scrollToElement(updatedCount);
              return updatedCount;
            }
            return current;
          });
          event.preventDefault();
        }
        break;
      case "ArrowUp":
        {
          setSelectedResultIndex(current => {
            if (current > 0) {
              const updatedCount = current - 1;
              scrollToElement(updatedCount);
              return updatedCount;
            }
            return current;
          });
          event.preventDefault();
        }
        break;
      case "Enter":
        {
          onPressEnter?.(event, selectedResultIndex);
        }
        break;
    }
  };

  const resetResults = () => {
    setResults([]);
  };

  return {
    isLoading,
    hasNoResults,
    error,
    setSearchValue,
    searchValue,
    results,
    selectedResultIndex,
    resetSearch,
    onKeydown,
    isLoadingCount,
    onMouseOver,
    onFocus,
    onMouseOut,
    hasNextPage: hasNextPage,
    fetchNextPage,
    countData,
  };
};
