import { t } from "@/i18n-js/instance";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { SpaceGroupResource } from "./shared/SpaceGroupResource";
import { SpaceGroupSelector } from "./shared/SpaceGroupSelector";

export const memberJoined: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_joined_space_group.title",
  ),
  value: "community_member_joined_space_group",
  group: t("settings.workflows.form.event_groups.space_group"),
  description: t(
    "settings.workflows.form.events.community_member_joined_space_group.description",
  ),
  resource: TRIGGER_RESOURCE_TYPE.SPACE_GROUP,
  formComponent: SpaceGroupSelector,
  viewComponent: SpaceGroupResource,
  icon: "space",
};
