import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { ROOM_STATUS } from "@circle-react/helpers/liveStreamRoomHelpers";
import "./styles.scss";

export const LiveStreamStatus = ({ status }) => {
  const statusMapObj = {
    [ROOM_STATUS.INITIALIZING]: t("live_streams.status.initializing"),
    [ROOM_STATUS.BACKSTAGE]: t("live_streams.status.backstage"),
    [ROOM_STATUS.LIVE]: t("live_streams.status.live"),
    [ROOM_STATUS.ENDED]: t("live_streams.status.finished"),
    [ROOM_STATUS.CLOSING]: t("live_streams.status.closing"),
  };

  const renderIcon = () => {
    const statusIndicator = () => (
      <div
        className={classNames("live-stream-status__icon", {
          "live-stream-status__icon--backstage":
            status === ROOM_STATUS.BACKSTAGE,
          "live-stream-status__icon--live": status === ROOM_STATUS.LIVE,
          "live-stream-status__icon--ended": status === ROOM_STATUS.ENDED,
        })}
      >
        <Icon size={6} type="middot" />
      </div>
    );
    if (
      [ROOM_STATUS.BACKSTAGE, ROOM_STATUS.LIVE, ROOM_STATUS.ENDED].includes(
        status,
      )
    ) {
      return statusIndicator();
    }
    return null;
  };

  return (
    <div className="live-stream-status">
      {renderIcon()}
      {statusMapObj[status] || ""}
    </div>
  );
};

LiveStreamStatus.propTypes = {
  status: PropTypes.string,
};
