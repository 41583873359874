import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { t } from "@/i18n-js/instance";
import { useFetchPaywallPrice } from "@circle-react/components/SettingsApp/Workflows/hooks/useFetchPaywallPrice";
import { Typography } from "@circle-react-uikit/Typography";
import { Badge } from "../../../Badge";
import { BaseView } from "../../BaseView";

const i18nRoot = "settings.workflows.view";

const renderNotiFyOption = send_notification_subscription_created_by_admin =>
  send_notification_subscription_created_by_admin ? t("yes") : t("no");

export const ViewPaywallTrial = ({ action }) => {
  const {
    api_params: {
      paywall_price_id: paywallPriceId,
      paywall_id: paywallId,
      trial_days: trialDays,
      send_notification_subscription_created_by_admin:
        send_notification_subscription_created_by_admin,
    } = {},
  } = action || {};
  const { paywallPrice, isLoading } = useFetchPaywallPrice({
    paywallId: paywallId,
    priceId: paywallPriceId,
  });

  if (!paywallPrice && !isLoading) {
    return null;
  }

  return (
    <BaseView>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "resources.paywall"])}</BaseView.Title>
        <div>
          {isLoading ? (
            <Badge isLoading />
          ) : (
            <Typography.LabelSm>
              {isEmpty(paywallPrice)
                ? t("settings.workflows.view.deleted_paywall")
                : paywallPrice.paywall_name ||
                  paywallPrice.paywall_display_name}
            </Typography.LabelSm>
          )}
        </div>
      </BaseView.Section>
      <BaseView.Section>
        <BaseView.Title>
          {t([i18nRoot, "resources.paywall_price"])}
        </BaseView.Title>
        <div>
          {isLoading ? (
            <Badge isLoading />
          ) : (
            <Typography.LabelSm>
              {isEmpty(paywallPrice)
                ? t("settings.workflows.view.deleted_paywall_price")
                : paywallPrice.display_name}
            </Typography.LabelSm>
          )}
        </div>
      </BaseView.Section>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "trial_days"])}</BaseView.Title>
        <div>
          {isLoading ? (
            <Badge isLoading />
          ) : (
            <Typography.LabelSm>{trialDays}</Typography.LabelSm>
          )}
        </div>
      </BaseView.Section>
      <BaseView.Section>
        <BaseView.Title>
          {t([i18nRoot, "send_notification_subscription_created_by_admin"])}
        </BaseView.Title>
        <div>
          {isLoading ? (
            <Badge isLoading />
          ) : (
            <Typography.LabelSm>
              {renderNotiFyOption(
                send_notification_subscription_created_by_admin,
              )}
            </Typography.LabelSm>
          )}
        </div>
      </BaseView.Section>
    </BaseView>
  );
};

ViewPaywallTrial.propTypes = {
  action: PropTypes.object,
};
