import {
  selectIsLocalScreenShared,
  selectIsSomeoneScreenSharing,
  useCustomEvent,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { t } from "@/i18n-js/instance";
import { notifyBugsnag } from "@circle-react/helpers/bugsnagHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { MODERATOR_MESSAGES, MODERATOR_TYPE } from "./constants";

const OS_ERROR = "SystemDeniedPermission";

export const useHMSScreenSharing = (canOverride = false) => {
  const { error } = useToast();
  const hmsActions = useHMSActions();
  const isScreenSharingEnabled = useHMSStore(selectIsLocalScreenShared);
  const isSomeoneScreenSharing = useHMSStore(selectIsSomeoneScreenSharing);

  const onModeratorEvent = message => {
    if (
      message === MODERATOR_MESSAGES.STOP_SCREENSHARE &&
      isScreenSharingEnabled
    ) {
      hmsActions.setScreenShareEnabled(false);
    }
  };

  const { sendEvent } = useCustomEvent({
    type: MODERATOR_TYPE,
    onEvent: onModeratorEvent,
  });

  const toggleScreenSharing = async () => {
    if (!isScreenSharingEnabled && isSomeoneScreenSharing && !canOverride) {
      return;
    }
    try {
      if (!isScreenSharingEnabled && isSomeoneScreenSharing) {
        // Override remote screenshare
        await sendEvent(MODERATOR_MESSAGES.STOP_SCREENSHARE);
      }
      await hmsActions.setScreenShareEnabled(!isScreenSharingEnabled, {
        audio: true,
        systemAudio: "include",
      });
    } catch (err) {
      if (err.name === OS_ERROR) {
        error(t("live_streams.room.screen_sharing_os_error"));
      } else {
        error(t("live_streams.room.screen_sharing_browser_error"));
      }

      console.error(err);
      notifyBugsnag(err);
    }
  };

  return {
    isScreenSharingEnabled,
    isSomeoneScreenSharing,
    toggleScreenSharing,
  };
};
