import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Drawer } from "@circle-react-uikit/Drawer";

export interface DefaultHeaderProps {
  onClose: () => void;
}

export const DefaultHeader = ({ onClose }: DefaultHeaderProps) => (
  <Drawer.Header
    addHeaderPadding
    title={t("spaces.form.create.title")}
    fixed
    Actions={
      <button type="submit" className="close-button" onClick={onClose}>
        <Icon type="close" />
        <span className="sr-only">{t("drawer.close_panel")}</span>
      </button>
    }
  />
);
