import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { WORKFLOW_STATUS, WORKFLOW_TYPE } from "../../constants";
import { useWorkflowsData } from "../../context/WorkflowsDataProvider";
import { CommunityMemberColumn } from "../CommunityMemberColumn";
import { ActionGroup } from "../NewWorkflowV2/ActionGroup";
import { WorkflowsTable } from "../Table";
import { Actions } from "../Table/Actions";
import { ActiveToggle } from "../Table/ActiveToggle";
import { Datetime } from "../Table/Datetime";
import { WorkflowName } from "../Table/WorkflowName";
import { columnStyles } from "./columnStyles";

const i18nRoot = "settings.workflows.table";

const getColumns =
  shouldShowFewerColumns =>
  ({ refetch }) => {
    const columnDefinitions = {
      name: {
        accessorKey: "name",
        header: t([i18nRoot, "name"]),
        thClassName: columnStyles.title,
        cellClassName: columnStyles.title,
        cell: info => (
          <WorkflowName>
            {info.getValue() || t([i18nRoot, "untitled_workflow"])}
          </WorkflowName>
        ),
      },
      steps: {
        accessorKey: "steps",
        header: t([i18nRoot, "steps"]),
        thClassName: columnStyles.steps,
        cellClassName: classNames("!py-0", columnStyles.steps),
        cell: info => (
          <ActionGroup
            actions={info.getValue()}
            size="md"
            truncationLimit={3}
            className="flex-wrap py-4"
          />
        ),
      },
      createdBy: {
        accessorKey: "created_by_community_member_id",
        header: t([i18nRoot, "created_by"]),
        thClassName: columnStyles.user,
        cellClassName: columnStyles.user,
        cell: info => (
          <CommunityMemberColumn id={info.getValue()} isLastNameHidden />
        ),
      },
      createdAt: {
        accessorKey: "created_at",
        header: t([i18nRoot, "created_on"]),
        thClassName: columnStyles.dateShort,
        cellClassName: columnStyles.dateShort,
        cell: info => <Datetime date={info.getValue()} />,
      },
      lastRunAt: {
        accessorKey: "last_run_at",
        header: t([i18nRoot, "last_run"]),
        thClassName: columnStyles.dateLong,
        cellClassName: columnStyles.dateLong,
        cell: info => <Datetime date={info.getValue()} shouldIncludeTime />,
      },
      tasksCount: {
        accessorKey: "tasks_count",
        header: t([i18nRoot, "total_runs"]),
        thClassName: columnStyles.number,
        thInnerClassName: "justify-end",
        cellClassName: classNames("text-right", columnStyles.number),
        cell: info => (
          <span className="break-normal">{info.getValue() || 0}</span>
        ),
      },
      active: {
        header: t([i18nRoot, "active"]),
        thClassName: columnStyles.toggleInput,
        thInnerClassName: "justify-center",
        cellClassName: classNames(
          "!py-4 cursor-default",
          columnStyles.toggleInput,
        ),
        onClick: ({ event }) => event.stopPropagation(),
        cell: info => <ActiveToggle workflow={info.row.original} />,
      },
      actions: {
        accessorKey: "actions",
        header: "",
        cellClassName: "cursor-default",
        onClick: ({ event }) => event.stopPropagation(),
        cell: ({ row }) => (
          <Actions workflow={row.original} refetch={refetch} />
        ),
      },
    };

    const allColumnKeys = [
      "name",
      "steps",
      "createdBy",
      "createdAt",
      "lastRunAt",
      "tasksCount",
      "active",
      "actions",
    ];

    const reducedColumnKeys = ["name", "createdBy", "tasksCount", "active"];

    const selectedKeys = shouldShowFewerColumns
      ? reducedColumnKeys
      : allColumnKeys;

    return selectedKeys.map(key => columnDefinitions[key]);
  };

export const AutomationsTable = () => {
  const { category } = useWorkflowsData();

  return (
    <WorkflowsTable
      columns={getColumns(category)}
      workflowType={WORKFLOW_TYPE.DYNAMIC}
      status={WORKFLOW_STATUS.ALL}
    />
  );
};
