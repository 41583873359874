import { useEffect, useRef, useState } from "react";
import Tippy from "@tippyjs/react";
import { t } from "@/i18n-js/instance";
import type { FormValues } from "@/react/components/Spaces/CourseSpace/Lessons/List/Content/Table/DripSchedule/DripScheduleForm";
import {
  calculateDripDelayValue,
  computeDripDelayCountText,
  computeDripDelayUnit,
} from "@/react/helpers/courseHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { DROPDOWN_SIZE, Dropdown } from "@circle-react-uikit/Dropdown";
import { Input } from "@circle-react-uikit/Input";
import { Typography } from "@circle-react-uikit/Typography";
import "./styles.scss";

const TRANSLATION_ROOT = "courses.edit.sections.drip_schedule.form";

interface DripDelayPickerProps {
  onChange: (payload: FormValues) => void;
  dripDelay: number;
  disabled: boolean;
}

const computeButtonValue = (dripDelay: number) => {
  const unit = computeDripDelayUnit(dripDelay);
  const count = calculateDripDelayValue(dripDelay);
  return computeDripDelayCountText(count, unit);
};

const getDropdownOptions = (inputValue: any) =>
  ["days", "weeks"].map(unit => ({
    label: t(`${TRANSLATION_ROOT}.${unit}`, {
      count: inputValue,
    }),
    value: unit,
  }));

export const DripDelayPicker = ({
  onChange,
  dripDelay,
  disabled,
}: DripDelayPickerProps) => {
  const [dropdownValue, setDropdownValue] = useState(
    computeDripDelayUnit(dripDelay),
  );
  const [inputValue, setInputValue] = useState(
    calculateDripDelayValue(dripDelay),
  );
  const [isDripDelayPickerVisible, setIsDripDelayPickerVisible] =
    useState(false);
  const [buttonValue, setButtonValue] = useState(computeButtonValue(dripDelay));
  const buttonRef = useRef<HTMLButtonElement>(null);

  const buttonText = t(`${TRANSLATION_ROOT}.drip_delay_button_text`, {
    drip_delay: buttonValue,
  });
  const dropdownLabel = t(`${TRANSLATION_ROOT}.${dropdownValue}`, {
    count: inputValue,
  });
  const dripDelayMessage = t(`${TRANSLATION_ROOT}.drip_delay_message`, {
    drip_delay: computeDripDelayCountText(
      inputValue,
      dropdownValue,
    ).toLowerCase(),
  });

  useEffect(() => {
    // When the dripDelay changes from the backend, we update the button value
    // the input value and the dropdown value to be in sync and be consistent
    setButtonValue(computeButtonValue(dripDelay));
    setDropdownValue(computeDripDelayUnit(dripDelay));
    setInputValue(calculateDripDelayValue(dripDelay));
  }, [dripDelay]);

  const onSave = () => {
    const dripDelayInDays =
      dropdownValue === "days" ? inputValue : inputValue * 7;
    onChange({
      drip_delay: dripDelayInDays,
      ...(dripDelayInDays === 0 && { notify_students_enabled: false }),
    });
    setIsDripDelayPickerVisible(false);
  };

  const validateAndSaveInputValue = (newValue: any, dripDelayUnit: any) => {
    let value = parseInt(newValue) || 0;
    const [min, max] = dripDelayUnit === "weeks" ? [0, 100] : [0, 999];
    value = Math.min(Math.max(value, min), max);
    setInputValue(value);
  };

  return (
    <div>
      <Button
        disabled={disabled}
        variant="secondary"
        className="flex w-full items-center justify-between !pr-2"
        forwardedRef={buttonRef}
        onClick={() => {
          setIsDripDelayPickerVisible(!isDripDelayPickerVisible);
        }}
      >
        <span>{buttonText}</span>
        <Icon className="text-dark" type="20-chevron-down-sm" size={20} />
      </Button>
      <Tippy
        visible={isDripDelayPickerVisible}
        onClickOutside={(_instance, event) => {
          // We ignore the click outside event if the click is on the button or any of its children
          // because it will be handled by the button's onClick listener which also toggles the visibility.
          if (
            buttonRef.current instanceof HTMLButtonElement &&
            event.target instanceof HTMLElement &&
            (event.target === buttonRef.current ||
              buttonRef.current.contains(event.target))
          ) {
            return;
          }
          setIsDripDelayPickerVisible(false);
        }}
        allowHTML
        interactive
        duration={0}
        arrow={false}
        delay={0}
        theme="drip-delay-dropdown"
        placement="bottom-start"
        content={
          <div className="bg-primary border-primary mb-6 w-64 rounded-md border !px-4 !pt-3 shadow-md">
            <Typography.BodyMd weight="font-semibold">
              {t(`${TRANSLATION_ROOT}.drip_delay`)}
            </Typography.BodyMd>
            <div className="my-3 flex w-full items-center justify-between">
              <div className="w-20">
                <Input
                  min={0}
                  max={1000}
                  value={inputValue}
                  onChange={(event: any) => {
                    validateAndSaveInputValue(
                      event.target.value,
                      dropdownValue,
                    );
                  }}
                  otherClassName="!p-2"
                  type="number"
                />
              </div>
              <Dropdown
                className="ml-2 grow"
                buttonClassName="w-full"
                size={DROPDOWN_SIZE.SMALL}
                onChange={value => {
                  setDropdownValue(value);
                  validateAndSaveInputValue(inputValue, value);
                }}
                button={
                  <Button
                    large
                    variant="secondary"
                    className="!border-primary !flex w-full items-center justify-between !px-4 !pr-3 !font-normal"
                  >
                    <span>{dropdownLabel}</span>
                    <Icon
                      className="text-dark"
                      type="20-chevron-down-sm"
                      size={20}
                    />
                  </Button>
                }
                options={getDropdownOptions(inputValue)}
              />
            </div>
            <Typography.BodySm>{dripDelayMessage}</Typography.BodySm>
            <Button variant="circle" full className="my-3" onClick={onSave}>
              {t("done")}
            </Button>
          </div>
        }
      >
        <span />
      </Tippy>
    </div>
  );
};
