import { t } from "@/i18n-js/instance";
import type { Space } from "@/react/types/Space";
import {
  canManageSpace,
  isSpaceMember,
} from "@circle-react/helpers/spaceHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { LeaveAndDeleteSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/LeaveAndDeleteSpaceOptions";
import { ManageSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/ManageSpaceOptions";
import { useChatSpaceDetailsModal } from "./ChatSpaceDetailsModal";
import { Separator } from "./Separator";

export const ChatSpaceOptions = ({
  space,
  setIsAddMemberOpen,
  isAdmin,
}: {
  space: Space;
  setIsAddMemberOpen: (isOpen: boolean) => void;
  isAdmin: boolean;
}) => {
  const canMemberManageSpace = canManageSpace(space);
  const isMember = isSpaceMember(space);
  const modal = useChatSpaceDetailsModal();
  return (
    <>
      {isMember && (
        <>
          <button
            type="submit"
            className="flex w-full items-center justify-between px-5 py-2.5"
            onClick={() => modal.show({ space })}
          >
            <div className="flex items-center gap-4">
              <Icon type="16-info" size={16} className="text-default" />
              <Typography.LabelSm color="text-light" weight="medium">
                {t("chat_space.details")}
              </Typography.LabelSm>
            </div>
            <Icon type="16-chevron-right" size={16} className="text-default" />
          </button>
          <Separator />
        </>
      )}
      {canMemberManageSpace && (
        <>
          <ManageSpaceOptions
            space={space}
            onInviteMember={() => setIsAddMemberOpen(true)}
          />
          <Separator />
        </>
      )}
      <LeaveAndDeleteSpaceOptions space={space} isAdmin={isAdmin} />
    </>
  );
};
