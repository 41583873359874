import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import {
  MAXIMUM_FREE_TRIAL_DAYS,
  MINIMUM_FREE_TRIAL_DAYS_WHEN_DISABLED,
  MINIMUM_FREE_TRIAL_DAYS_WHEN_ENABLED,
} from "../../../constants";
import { useFreeTrial } from "../../hooks/useFreeTrial";

export const TrialDaysInput = ({ className = "" }: any) => {
  const { hasTrial } = useFreeTrial();

  return (
    <Form.Item
      className={`!py-0 ${className}`}
      labelClassName="!font-normal"
      name="trial_days"
      hideDescription
      hidePlaceholder
      hideLabel
      hideBorder
      isDisabled={!hasTrial}
      rules={{
        pattern: /^[0-9]+$/,
        min: {
          value: hasTrial
            ? MINIMUM_FREE_TRIAL_DAYS_WHEN_ENABLED
            : MINIMUM_FREE_TRIAL_DAYS_WHEN_DISABLED,
          message: t(
            "settings.paywalls.modal.tabs.pricing.alerts.free_trial_should_be_greater_than",
          ),
        },
        max: {
          value: MAXIMUM_FREE_TRIAL_DAYS,
          message: t(
            "settings.paywalls.modal.tabs.pricing.alerts.free_trial_should_be_less_or_equal_than",
          ),
        },
      }}
    >
      <Form.Input
        data-testid="trial_days"
        inputMode="numeric"
        suffix={t("settings.paywalls.form.free_trial.days_label")}
        disabled={!hasTrial}
      />
    </Form.Item>
  );
};
