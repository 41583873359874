import type { UseQueryResult } from "react-query";
import { useQuery } from "react-query";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import type {
  PaginationHelper,
  PaginationParams,
} from "@circle-react/types/InternalAPIHelper";

export const queryKey = ["access_groups", "index"];

// Admins are limited to 100 access groups.
// We load them all in a single request for the sake of simplicity.
const defaultPaginationParams: Partial<PaginationParams> = {
  page: 1,
  per_page: 100,
};
export type UseAccessGroupsResult = UseQueryResult<
  PaginationHelper<AccessGroupType>
>;

interface UseAccessGroupsParams {
  params?: {
    ids?: string[];
  };
  enabled?: boolean;
}

export const useAccessGroups = ({
  params = {},
  enabled = true,
}: UseAccessGroupsParams = {}): UseAccessGroupsResult =>
  useQuery<PaginationHelper<AccessGroupType>, ApiError>(
    [queryKey, params.ids],
    () =>
      reactQueryGet(
        internalApi.admin.accessGroups.index({
          params: {
            ...defaultPaginationParams,
            ...params,
          },
        }),
      ),
    { enabled },
  );
