import { t } from "@/i18n-js/instance";
import type {
  SpaceGroupOption,
  SpaceOption,
} from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import { useSpacesContext } from "@circle-react/contexts";
import { useSpaceGroupsContext } from "@circle-react/contexts";

const i18nRoot = "space_access_management";

const getTooltipText = (visibility: any, isPrivate: any) => {
  if (isPrivate && visibility === "private") {
    return t([i18nRoot, "tooltip_icon.private"]);
  }

  if (isPrivate && visibility === "secret") {
    return t([i18nRoot, "tooltip_icon.secret"]);
  }

  return t([i18nRoot, "tooltip_icon.open"]);
};

const identifyIcon = (visibility: any, isPrivate: any) => {
  if (isPrivate && visibility === "private") {
    return "16-lock";
  }

  if (isPrivate && visibility === "secret") {
    return "16-eye-closed";
  }

  // expected to be open
  return "16-members-bis";
};

export const serializeSpaceDefault = (space: any): SpaceOption => ({
  id: space.id,
  name: space.name,
  icon: identifyIcon(space.visibility, space.is_private),
  tooltipText: getTooltipText(space.visibility, space.is_private),
});

export const serializeSpaceGroupDefault = (
  spaceGroup: any,
): SpaceGroupOption => ({
  id: spaceGroup.id,
  name: spaceGroup.name,
  spaces: [],
});

interface UseSpaceManagementProps<SpaceGroupType, SpaceType> {
  serializeSpaceGroup: (spaceGroup: any) => SpaceGroupType;
  serializeSpace: (space: any) => SpaceType;
}

export const useSpaceAccessManagement = <
  SpaceGroupType extends SpaceGroupOption,
  SpaceType extends SpaceOption,
>({
  serializeSpaceGroup,
  serializeSpace,
}: UseSpaceManagementProps<SpaceGroupType, SpaceType>) => {
  const { records: spaceRecords, isLoading: isLoadingSpaces } =
    useSpacesContext();
  const { records: spaceGroupRecords, isLoading: isLoadingSpaceGroups } =
    useSpaceGroupsContext();

  const spaceGroupOptions: SpaceGroupType[] = [];
  const spaceGroupIndex: any = {};
  const spaceIndex: any = {};

  spaceGroupRecords.forEach((spaceGroup: any) => {
    const spaceGroupOption = serializeSpaceGroup(spaceGroup);
    spaceGroupOptions.push(spaceGroupOption);
    spaceGroupIndex[spaceGroup.id] = spaceGroupOption;
  });

  spaceRecords.forEach((space: any) => {
    const spaceOption = serializeSpace(space);
    spaceGroupIndex[space.space_group_id]?.spaces.push(spaceOption);
    spaceIndex[spaceOption.id] = spaceOption;
  });

  return {
    options: spaceGroupOptions,
    spaceGroupIndex,
    spaceIndex,
    spaceRecords,
    spaceGroupRecords,
    isLoading: isLoadingSpaces || isLoadingSpaceGroups,
  };
};
