import { t } from "@/i18n-js/instance";
import { ProgressiveImage } from "@circle-react/components/shared/ProgressiveImage";
import { useAIAgentsLocalizedFeatureImages } from "@circle-react/config/Locales/AIAgentsLocalizedFeatureImages";
import { Blocks } from "../Blocks";
import { localeRoot as communityBotLocale } from "../constants";

const localeRoot = `${communityBotLocale}.blocks.knowledge_engine_feature`;

const backgroundColors = {
  primary: "#B0EBD9",
  secondary: "#EBFAF5",
};

interface KnowledgeEngineFeatureProps {
  featureNumber: number;
}

export const KnowledgeEngineFeature = ({
  featureNumber,
}: KnowledgeEngineFeatureProps) => {
  const imageVariants = useAIAgentsLocalizedFeatureImages(featureNumber);

  return (
    <Blocks>
      <Blocks.FeatureSummary backgroundColor={backgroundColors.secondary}>
        <Blocks.Header
          localeRoot={localeRoot}
          iconName="20-ai-knowledge-engine"
          backgroundColor={backgroundColors.primary}
        />
        <Blocks.FeatureList>
          <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
            {t([localeRoot, "features", "0"])}
          </Blocks.FeatureItem>
          <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
            {t([localeRoot, "features", "1"])}
          </Blocks.FeatureItem>
          <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
            {t([localeRoot, "features", "2"])}
          </Blocks.FeatureItem>
        </Blocks.FeatureList>
      </Blocks.FeatureSummary>
      <Blocks.FeaturePreview
        backgroundColor={backgroundColors.primary}
        className="flex flex-col items-center pt-12"
      >
        {imageVariants && (
          <ProgressiveImage
            src={imageVariants.large}
            srcVariants={imageVariants}
            alt={t([localeRoot, "image_alt"])}
            className="w-[584px] md:w-[789.175px]"
          />
        )}
      </Blocks.FeaturePreview>
    </Blocks>
  );
};
