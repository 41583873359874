import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { retrievePaywallCheckoutHostname } from "@circle-react/helpers/paywallCheckoutHelpers";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import { Price } from "../Price";
import { usePaywallsBlock } from "../hooks/usePaywallsBlock";

export const BlockContent = ({ paywall, description }: any) => {
  const { isEditing } = usePaywallsBlock();

  const paywallPrice = paywall.web_paywall_price;
  const paywallName = isEditing
    ? paywall.display_name || t("paywalls.lock_screen.preview.empty_block.name")
    : paywall.display_name;

  const paywallDescription = description;

  const onClickCheckout = (paywall: any) => {
    const keyParam = "price_id";
    const checkoutUrl = new URL(
      `${retrievePaywallCheckoutHostname()}${paywall.checkout_path}`,
    );
    checkoutUrl.searchParams.set(keyParam, paywallPrice.id);
    window.open(checkoutUrl, "_blank");
  };

  return (
    <>
      <div
        className={classNames("flex flex-col gap-y-5 pb-5", {
          "border-primary border-b": !!paywallDescription,
        })}
      >
        <div className="flex flex-col gap-y-2">
          <Typography.LabelLg truncate>{paywallName}</Typography.LabelLg>
          <Price price={paywallPrice} currency={paywall.currency} />
        </div>
        <div>
          <Button
            className="h-[2.15rem] w-36"
            variant="primary"
            type="button"
            onClick={() => onClickCheckout(paywall)}
          >
            {t("join")}
          </Button>
        </div>
      </div>
      {paywallDescription && (
        <div className="flex h-full flex-col justify-between gap-y-4">
          <Typography.BodySm
            color={classNames({
              "text-lightest": isEditing && !paywall.description,
            })}
          >
            <span className="whitespace-pre-wrap">{paywallDescription}</span>
          </Typography.BodySm>
        </div>
      )}
    </>
  );
};
