import { useEffect } from "react";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { SnapshotNumbers } from "@circle-react-shared/SnapshotNumbers";
import { Loader } from "@circle-react-uikit/Loader";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useSubscriptionStatsQuery } from "./hooks/useSubscriptionStatsQuery";

export const Stats = () => {
  const { error } = useToast();

  const { currentCommunitySettings } = usePunditUserContext();
  const isPaywallSnapshotsEnabled =
    currentCommunitySettings?.paywall_snapshots_enabled;

  const {
    isSubscriptionStatsLoading,
    subscriptionStats,
    subscriptionStatsError,
  } = useSubscriptionStatsQuery();

  useEffect(() => {
    if (subscriptionStatsError) {
      error(subscriptionStatsError.message);
    }
  }, [subscriptionStatsError]);

  if (!isPaywallSnapshotsEnabled) {
    return null;
  }

  if (!isSubscriptionStatsLoading && subscriptionStatsError) {
    return null;
  }

  const snapshots = [
    {
      label: t("settings.paywall_subscription_stats.mrr.label"),
      tooltip: t("settings.paywall_subscription_stats.mrr.tooltip"),
      value: subscriptionStats?.mrr_as_currency,
    },
    {
      label: t("settings.paywall_subscription_stats.arr.label"),
      tooltip: t("settings.paywall_subscription_stats.arr.tooltip"),
      value: subscriptionStats?.arr_as_currency,
    },
    {
      label: t("settings.paywall_subscription_stats.avg_mrr_per_member.label"),
      tooltip: t(
        "settings.paywall_subscription_stats.avg_mrr_per_member.tooltip",
      ),
      value: subscriptionStats?.avg_mrr_per_member_as_currency,
    },
    {
      label: t("settings.paywall_subscription_stats.churn_rate.label"),
      tooltip: t("settings.paywall_subscription_stats.churn_rate.tooltip"),
      value: subscriptionStats?.churn_rate_as_percentage,
    },
  ];

  return (
    <>
      {isSubscriptionStatsLoading ? (
        <Loader center />
      ) : (
        <div className="my-3 grid grid-cols-1 px-6 md:grid-cols-4 md:gap-6">
          {snapshots.map(snapshot => (
            <SnapshotNumbers
              number={snapshot.value}
              label={snapshot.label}
              tooltipText={snapshot.tooltip}
              key={snapshot.label}
            />
          ))}
        </div>
      )}
    </>
  );
};
