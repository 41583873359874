import { getAdminAudienceEditPaths } from "@circle-react/helpers/urlHelpers";
import { AdminLeadEditPage } from "@circle-react/pages/AdminAudience/AdminLeadEditPage";
import { ProtectedRoute } from "@circle-react-shared/ProtectedRoute";

const paths = getAdminAudienceEditPaths({
  type: "CommunityLead",
  publicId: ":leadPublicId",
});

const profileRoutes = [
  paths.index,
  paths.info,
  paths.access,
  paths.billing,
  paths.activityLogs,
] as const;

/**
 * @deprecated
 * Please use the AdminAudienceEditRoutes component instead.
 * This component will be removed in the future.
 */
export const AdminLeadEditRoutes = () => (
  <ProtectedRoute component={AdminLeadEditPage} path={profileRoutes} exact />
);
