import { useFormContext, useWatch } from "react-hook-form";
import I18n from "@/i18n-js/instance";
import { isCourseSpace } from "@circle-react/helpers/spaceHelpers";
import { Form } from "@circle-react-uikit/Form";

const VisibilityPicker = () => {
  const visibilityOptions = [
    {
      label: I18n.t("spaces.form.create.visibility_types.open"),
      value: "open",
      info: I18n.t("spaces.form.create.visibility_types.open_info"),
    },
    {
      label: I18n.t("spaces.form.create.visibility_types.private"),
      value: "private",
      info: I18n.t("spaces.form.create.visibility_types.private_info"),
      infoSubText: I18n.t(
        "spaces.form.create.visibility_types.private_info_subtext",
      ),
    },
    {
      label: I18n.t("spaces.form.create.visibility_types.secret"),
      value: "secret",
      info: I18n.t("spaces.form.create.visibility_types.secret_info"),
      infoSubText: I18n.t(
        "spaces.form.create.visibility_types.secret_info_subtext",
      ),
    },
  ];
  const postType = useWatch({ name: "post_type" });
  const visibility = useWatch({ name: "visibility" });
  const { setValue } = useFormContext();
  if (isCourseSpace({ post_type: postType })) {
    if (visibility != "draft") {
      setValue("visibility", "draft");
    }
    return (
      <Form.Item
        hideBorder
        name="visibility"
        label={I18n.t("courses.access")}
        description={I18n.t("courses.access_description")}
      />
    );
  }
  return (
    <Form.Item
      name="visibility"
      hideDescription
      label={I18n.t("spaces.form.create.visibility_types.label")}
      rules={{ required: "Please choose a visibility!", maxLength: 255 }}
      hideBorder
      fullWidth
      variant="extra-bottom-margin"
    >
      <Form.RadioGroup
        fullWidth={false}
        variant="box"
        name="visibility"
        options={visibilityOptions}
      />
    </Form.Item>
  );
};

export default VisibilityPicker;
