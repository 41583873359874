import PropTypes from "prop-types";
import I18n from "@/i18n-js/instance";
import { Collapsible } from "@circle-react/components/shared/uikit/Collapsible";
import { usePunditUserContext } from "@circle-react/contexts";
import { isPrivateCommunity } from "@circle-react/helpers/communityHelpers";
import {
  isBasicPostsSpace,
  isChatSpace,
  isEventsSpace,
} from "@circle-react/helpers/spaceHelpers";
import { Form } from "@circle-react-uikit/Form";

const Permissions = ({ space }) => {
  let fields = [];
  if (isEventsSpace(space)) {
    fields = [
      "allow_members_to_add_others",
      "hide_from_featured_areas",
      "allow_members_to_create_posts",
    ];
  }

  if (isChatSpace(space)) {
    fields = ["chat_room_show_history"];
  }

  if (isBasicPostsSpace(space)) {
    fields = [
      "allow_members_to_add_others",
      "allow_members_to_create_posts",
      "allow_post_title",
      "allow_cover_images",
      "hide_from_featured_areas",
    ];
  }

  const { currentCommunity } = usePunditUserContext();

  if (!isPrivateCommunity(currentCommunity) && !isChatSpace(space)) {
    fields = [...fields, "show_to_logged_out_visitors"];
  }

  const nameTranslationKey = name => {
    if (name !== "allow_members_to_create_posts") {
      return isEventsSpace(space)
        ? "allow_members_to_create_events"
        : "allow_members_to_create_posts";
    }
    return name;
  };

  return (
    <Collapsible title={I18n.t("spaces.form.edit.options.permissions.label")}>
      <div className="edit-space__container">
        {fields.map(name => (
          <Form.Item
            inline
            hideBorder
            hideDescription
            label={I18n.t(
              `spaces.form.edit.options.permissions.${nameTranslationKey(
                name,
              )}`,
            )}
            name={name}
            key={name}
          >
            <Form.ToggleSwitch variant="small" />
          </Form.Item>
        ))}
      </div>
    </Collapsible>
  );
};

export default Permissions;

Permissions.propTypes = {
  space: PropTypes.object,
};
