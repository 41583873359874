import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { BulkDeleteSnippetModalComponent } from "./BulkDeleteSnippetModalComponent";

const BulkDeleteSnippetModal = ModalManager.create(
  ({
    selectedIds,
    setSelectedIds,
  }: {
    selectedIds: number[];
    setSelectedIds: (ids: number[]) => void;
  }) => (
    <BulkDeleteSnippetModalComponent
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
    />
  ),
);

export const useBulkDeleteSnippetModal = () => useModal(BulkDeleteSnippetModal);
