import { t } from "@/i18n-js/instance";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { useSupportUnreleasedWorkflowsFeaturesEnabled } from "@circle-react/components/shared/Workflows/hooks/useSupportUnreleasedWorkflowsFeaturesEnabled";
import { useIsAccessGroupsWorkflowsEnabled } from "@circle-react/hooks/accessGroups/useIsAccessGroupsWorkflowsEnabled";
import { availableEvents } from "../Events";
import {
  communityMemberAddedToAccessGroup,
  communityMemberRemovedFromAccessGroup,
} from "./AccessGroup";
import { memberTagged, memberUntagged } from "./MemberTag";

const contactTagEventTranslations = {
  [memberTagged.value]: {
    labelKey: "settings.workflows.form.events.contact_tagged.title_new",
    descriptionKey:
      "settings.workflows.form.events.contact_tagged.description_new",
  },
  [memberUntagged.value]: {
    labelKey: "settings.workflows.form.events.contact_untagged.title_new",
    descriptionKey:
      "settings.workflows.form.events.contact_untagged.description_new",
  },
};
export const determineReleaseStatus = (
  released: boolean | (() => boolean) | undefined,
): boolean => {
  if (released === undefined) return true;
  return typeof released === "function" ? released() : released;
};

export const useReleasedEvents = () => {
  const shouldSupportUnreleasedWorkflowsFeaturesEnabled =
    useSupportUnreleasedWorkflowsFeaturesEnabled();

  const isAccessGroupsWorkflowsEnabled = useIsAccessGroupsWorkflowsEnabled();

  const { data: gamificationSettings } = useGamificationSettings();

  const shouldShowGamificationEvents = gamificationSettings?.enabled;

  let allAvailableEvents = availableEvents;

  if (!isAccessGroupsWorkflowsEnabled) {
    allAvailableEvents = allAvailableEvents.filter(
      availableEvent =>
        ![
          communityMemberAddedToAccessGroup.value,
          communityMemberRemovedFromAccessGroup.value,
        ].includes(availableEvent.value),
    );
  }

  if (!shouldShowGamificationEvents) {
    allAvailableEvents = allAvailableEvents.filter(
      availableEvent =>
        availableEvent.value !== "gamification_member_leveled_up",
    );
  }

  if (!shouldShowGamificationEvents) {
    allAvailableEvents = allAvailableEvents.filter(
      availableEvent =>
        availableEvent.value !== "gamification_member_leveled_down",
    );
  }

  allAvailableEvents = allAvailableEvents.map(availableEvent => {
    const translation = contactTagEventTranslations[availableEvent.value];
    if (translation) {
      return {
        ...availableEvent,
        label: t(translation.labelKey),
        description: t(translation.descriptionKey),
      };
    }
    return availableEvent;
  });

  const releasedEvents = shouldSupportUnreleasedWorkflowsFeaturesEnabled
    ? allAvailableEvents
    : allAvailableEvents.filter(availableEvent =>
        determineReleaseStatus(availableEvent.released),
      );

  return { releasedEvents };
};
