export const accessSystems = {
  registry: "registry",
  legacy: "legacy",
} as const;
export type AccessSystem = keyof typeof accessSystems;

export const accessScopes = {
  paywall: "paywall",
  access_group: "access_group",
  other: "other",
} as const;
export type AccessScope = keyof typeof accessScopes;

export const contentSpecTypes = {
  Space: "Space",
  SpaceGroup: "SpaceGroup",
  AccessGroup: "AccessGroup",
} as const;
export type ContentSpecType = keyof typeof contentSpecTypes;

export const contentTypes = {
  SpaceMember: "SpaceMember",
  SpaceGroupMember: "SpaceGroupMember",
  AccessGroupCommunityMember: "AccessGroupCommunityMember",
} as const;
export type ContentType = keyof typeof contentTypes;

export interface ChangeEvent {
  type: string;
  id: number;
  actor_type: string;
  actor_id: number;
  access_spec_type: string;
  access_spec_id: number;
}

export interface AccessSpec {
  type: ContentSpecType;
  id: number;
  name: string;
}

interface AccessRegistryCommunityMemberStateEntrySpaceContentSpec
  extends AccessSpec {
  type: "Space";
  type_label: string;
}
interface AccessRegistryCommunityMemberStateEntrySpaceGroupContentSpec
  extends AccessSpec {
  type: "SpaceGroup";
}
interface AccessRegistryCommunityMemberStateEntryAccessGroupContentSpec
  extends AccessSpec {
  type: "AccessGroup";
  spaces_count: number;
}

export type AccessRegistryCommunityMemberStateEntryContentSpec =
  | AccessRegistryCommunityMemberStateEntrySpaceContentSpec
  | AccessRegistryCommunityMemberStateEntrySpaceGroupContentSpec
  | AccessRegistryCommunityMemberStateEntryAccessGroupContentSpec;

export interface AccessRegistryCommunityMemberStateEntryContent {
  type: ContentType;
  id: number;
  created_at: string;
  access_system: AccessSystem;
  access_scope: AccessScope;
  grant_events: ChangeEvent[];
}

interface AccessRegistryCommunityMemberBaseStateEntry {
  content_spec: AccessRegistryCommunityMemberStateEntryContentSpec;
  content?: AccessRegistryCommunityMemberStateEntryContent;
}

export interface AccessRegistryCommunityMemberSpaceStateEntry
  extends AccessRegistryCommunityMemberBaseStateEntry {
  content_spec: AccessRegistryCommunityMemberStateEntrySpaceContentSpec;
}

export interface AccessRegistryCommunityMemberSpaceGroupStateEntry
  extends AccessRegistryCommunityMemberBaseStateEntry {
  content_spec: AccessRegistryCommunityMemberStateEntrySpaceGroupContentSpec;
  spaces: Record<string, AccessRegistryCommunityMemberSpaceStateEntry>;
}

export interface AccessRegistryCommunityMemberAccessGroupStateEntry
  extends AccessRegistryCommunityMemberBaseStateEntry {
  content_spec: AccessRegistryCommunityMemberStateEntryAccessGroupContentSpec;
  space_groups: Record<
    string,
    AccessRegistryCommunityMemberSpaceGroupStateEntry
  >;
}

export interface AccessRegistryCommunityMemberState {
  space_groups: Record<
    string,
    AccessRegistryCommunityMemberSpaceGroupStateEntry
  >;
  access_groups: Record<
    string,
    AccessRegistryCommunityMemberAccessGroupStateEntry
  >;

  content_specs_granted_through_access_groups: Array<
    | AccessRegistryCommunityMemberStateEntrySpaceGroupContentSpec
    | AccessRegistryCommunityMemberStateEntrySpaceContentSpec
  >;
}

interface BaseContentSpecAccessDetails {
  type: ContentSpecType;
  id: number;
  name: string;
}

export interface AccessGroupAccessDetails extends BaseContentSpecAccessDetails {
  type: "AccessGroup";
  name: string;
}

export interface SpaceAccessDetails extends BaseContentSpecAccessDetails {
  access_groups: AccessGroupAccessDetails[];
}

export interface SpaceGroupAccessDetails extends BaseContentSpecAccessDetails {
  access_groups: AccessGroupAccessDetails[];
}

export type ContentSpecAccessDetails =
  | AccessGroupAccessDetails
  | SpaceGroupAccessDetails
  | SpaceAccessDetails;
