import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export const Rerun = ({ showRerunConfirmation }) => (
  <Dropdown.ItemWithLink onClick={showRerunConfirmation}>
    {t("settings.workflows.table.actions.rerun")}
  </Dropdown.ItemWithLink>
);

Rerun.propTypes = {
  showRerunConfirmation: PropTypes.func,
};
