import { useEffect, useState } from "react";
import { t } from "@/i18n-js/instance";
import { nextPlanWithFeatureApi } from "@/react/api/nextPlanWithFeatureApi";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { useUpdateAgent } from "@circle-react/components/SettingsApp/CommunityBot/agentsQueries";
import { usePunditUserContext } from "@circle-react/contexts";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useDisableModal } from "./DisableModal";
import { useEnableModal } from "./EnableModal";

export const HeaderButtons = ({ agent }: { agent: AgentProp }) => {
  const enableModal = useEnableModal(agent);
  const disableModal = useDisableModal(agent);
  const [isEnabled, setIsEnabled] = useState(agent.enabled);
  const { mutate: updateAgent } = useUpdateAgent({ id: agent.id });
  const { currentCommunity, refetch: refetchPundit } = usePunditUserContext();
  const upgradePlanModal = useUpgradePlanModal();

  useEffect(() => {
    setIsEnabled(agent.enabled);
  }, [agent.enabled]);

  const enableAgent = () => {
    updateAgent(
      { enabled: true },
      {
        onSuccess: () => {
          setIsEnabled(true);
        },
        onError: error => {
          console.error("Failed to enable agent:", error);
          setIsEnabled(false);
        },
      },
    );
  };

  const handleUpgradePlan = async () => {
    const nextPlanData = await nextPlanWithFeatureApi.getNextPlanWithFeature(
      (currentCommunity?.current_plan?.id ?? "").toString(),
      { plan_feature_flag: "ai_agents" },
    );

    const planTier = nextPlanData?.plan_tier || "enterprise";

    await upgradePlanModal.show({
      planTier,
      source: UPGRADE_FLOW_SOURCES.AI_AGENTS_ENABLE_MODAL,
      subheading: t("pricing_plans.upgrade_modal.subheading", {
        feature_name: t("settings.community_ai.ai_agents_feature_name"),
      }),
      successButtonLabel: t(
        "pricing_plans.upgrade_modal.actions.go_to_community_ai",
      ),
      showPlanDetails: true,
      onSuccess: () => {
        enableAgent();
      },
      onCloseModal: async () => {
        const updatedCommunity = await refetchPundit();
        const isUpgradeSuccess =
          !!updatedCommunity?.data?.current_community
            .ai_agents_feature_flag_enabled;
        if (isUpgradeSuccess) {
          enableAgent();
        }
      },
    });
  };

  const handleEnableClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!currentCommunity?.ai_agents_feature_flag_enabled) {
      void handleUpgradePlan();
    } else {
      void enableModal.show({ agent });
    }
  };

  return (
    <div className="flex items-center justify-end gap-3">
      <Button type="submit" form="bot-builder-form" variant="secondary">
        <Typography.LabelSm weight="semibold">
          {t("community_bot.bot_builder.header_buttons.save.title")}
        </Typography.LabelSm>
      </Button>

      {isEnabled ? (
        <Button
          variant="circle"
          onClick={e => {
            e.preventDefault();
            void disableModal.show({ agent });
          }}
        >
          <Typography.LabelSm color="white" weight="semibold">
            {t("community_bot.bot_builder.header_buttons.disable.title")}
          </Typography.LabelSm>
        </Button>
      ) : (
        <Button variant="circle" onClick={handleEnableClick}>
          <Typography.LabelSm color="white" weight="semibold">
            {t("community_bot.bot_builder.header_buttons.enable.title")}
          </Typography.LabelSm>
        </Button>
      )}
    </div>
  );
};
