import { useMemo } from "react";
import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { PARTICIPANT_STATUS } from "@circle-react/helpers/liveStreamRoomHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type {
  Participant,
  ParticipantStatus,
} from "@circle-react/types/Live/Participant";
import type { Recorder } from "@circle-react/types/Live/Recorder";
import type { Room } from "@circle-react/types/Live/Room";

// Requesting max per page since we always want all participants
// 1000 is the maximum for enterprise plan, requesting double to
// account for addons.
const per_page = 2000;

interface FetchParticipantsParams extends Record<string, unknown> {
  status: ParticipantStatus;
  per_page: number;
  recording_key?: string;
}

interface ParticipantsResponse {
  records: Participant[];
  count: number;
}

export interface RolesMap {
  host: number[] | null;
  moderator: number[] | null;
}

export const useParticipants = (
  room?: Room,
  currentParticipant?: Participant | null,
  recorder?: Recorder | null,
) => {
  const params: FetchParticipantsParams = {
    status: PARTICIPANT_STATUS.ACTIVE,
    per_page,
  };

  if (recorder?.recording_key) {
    params.recording_key = recorder.recording_key;
  }

  // Only query participants when we have already joined
  // (!!currentParticipant)
  const {
    data,
    isLoading: isLoadingParticipants,
    refetch: refetchParticipants,
  } = useQuery<ParticipantsResponse>(
    ["participants", room?.id, currentParticipant?.id, recorder?.recording_key],
    () => {
      if (!room?.id) {
        throw new Error("Room ID is required");
      }
      return reactQueryGet(
        internalApi.liveStreams.participants({
          roomId: room.id,
          params,
        }),
      );
    },
    {
      enabled: !!room?.id && (!!currentParticipant || !!recorder),
    },
  );
  const participantsArray = data?.records;
  // We want to maintain an object of participants for easy access
  // [id] => participant
  const participants = useMemo(
    () =>
      participantsArray?.reduce<Record<number, Participant>>(
        (prev, participant) => {
          prev[participant.id] = participant;
          return prev;
        },
        {},
      ) || {},
    [participantsArray],
  );

  const roles: RolesMap = {
    host: room?.creator.id ? [room.creator.id] : null,
    moderator:
      participantsArray
        ?.filter(participant => participant.role === "moderator")
        .map(participant => participant.community_member_id) || null,
  };

  return {
    participants,
    isLoadingParticipants,
    refetchParticipants,
    roles,
  };
};
