import { t } from "@/i18n-js/instance";
import { useAttendeesPreview } from "@/react/components/Events/AttendeesPreview/hooks/useAttendeesPreview";
import type { Event } from "@circle-react/types/Event";
import { MemberProfileWrapper } from "@circle-react/wrappers/MemberProfileWrapper";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

interface AttendeesProps {
  event: Event;
}

export const Attendees = ({ event }: AttendeesProps) => {
  const { event_setting_attributes: eventData } = event;
  const { rsvp_disabled: isRsvpDisabled, hide_attendees: shouldHideAttendees } =
    eventData || {};

  const {
    totalCount: attendeeCount,
    attendees,
    handleAttendeesClick,
  } = useAttendeesPreview(event) || {};

  if (!attendeeCount || shouldHideAttendees || isRsvpDisabled) return null;

  return (
    <div className="bg-primary border-primary w-[320px] rounded-2xl border p-6">
      <div className="flex items-center justify-between">
        <Typography.LabelMd weight="medium">
          {attendeeCount} {t("events.attendees")}
        </Typography.LabelMd>
        <button onClick={handleAttendeesClick} type="button">
          <Typography.LabelSm weight="medium" color="text-link">
            {t("see_all")}
          </Typography.LabelSm>
        </button>
      </div>
      <div className="mt-6 flex flex-col space-y-3">
        {attendees?.map((attendee: any) => (
          <div className="flex items-center space-x-4" key={attendee.id}>
            <MemberProfileWrapper member={attendee}>
              <UserImage
                src={attendee.avatar_url}
                name={attendee.name}
                size="10"
              />
            </MemberProfileWrapper>
            <MemberProfileWrapper member={attendee}>
              <Typography.LabelMd>{attendee.name}</Typography.LabelMd>
            </MemberProfileWrapper>
          </div>
        ))}
      </div>
    </div>
  );
};
