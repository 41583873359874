import { PoweredByCircle } from "@circle-react/components/shared/PoweredByCircle";
import { useSidebarBorderStyles } from "./hooks";

export interface SidebarPoweredByCircleProps {
  communitySlug: string;
}

export const SidebarPoweredByCircle = ({
  communitySlug,
}: SidebarPoweredByCircleProps) => {
  const styles = useSidebarBorderStyles();

  return (
    <PoweredByCircle
      className="bg-c-sidebar justify-center !p-0"
      communitySlug={communitySlug}
      styles={styles}
    />
  );
};
