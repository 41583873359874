import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";

export const CustomizeTopicsOptionsForm = ({ onChange }: any) => {
  const {
    formState: { dirtyFields },
  } = useFormContext();

  useEffect(() => {
    if ("require_topic_selection" in dirtyFields) {
      onChange(true);
    }
  }, [dirtyFields.require_topic_selection]);

  return (
    <div>
      <Form.Item
        inlineReverse
        hideBorder
        hideDescription
        label={t("spaces.form.edit.topics.require_topic_selection")}
        tooltipText={t(
          "spaces.form.edit.topics.require_topic_selection_tooltip",
        )}
        name="require_topic_selection"
        shouldToggleValueOnLabelClick
      >
        <Form.ToggleSwitch name="require_topic_selection" variant="small" />
      </Form.Item>
    </div>
  );
};
