import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { notifyBugsnag } from "@circle-react/helpers/bugsnagHelpers";

/**
 * @deprecated
 * Please use the AdminAudienceEditPage instead.
 * This component will be removed in the future.
 */
export const AdminLeadEditPage = () => {
  const { leadPublicId } = useParams<{ leadPublicId: string }>();

  useEffect(() => {
    const message = `Accessing deprecated public_uid based admin lead edit page ${leadPublicId}`;
    notifyBugsnag(message);
    console.warn(message);

    // Because we have a complex redirect back which causes infinite-loops, we need to reload to move to /audience/contacts
    window.location.reload();
  }, [leadPublicId]);

  return null;
};
