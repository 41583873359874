import { useMemo } from "react";
import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { pushNotificationsApi } from "@circle-react/api/pushNotificationsApi";

export const DEFAULT_PAGE_SIZE = 20;
export const ALL_PUSH_NOTIFICATIONS_QUERY_KEY_PREFIX = "all-push-notifications";

export const usePushNotificationsInfiniteQuery = ({
  query = "",
  filters = {},
  perPage = DEFAULT_PAGE_SIZE,
  status,
  initialPage = 1,
  options = {},
}: {
  query?: string;
  filters?: object;
  perPage: number;
  status: string;
  initialPage: number;
  options: object;
}) => {
  const fetchPage = ({ pageParam = initialPage }) => {
    let page = 1;

    if (typeof pageParam === "number" && pageParam > 0) {
      page = pageParam;
    }

    const params = {
      page,
      filters,
      status,
      per_page: perPage,
      ...(query && { query }),
    };

    return pushNotificationsApi.list({ params });
  };

  const queryKey = [
    ALL_PUSH_NOTIFICATIONS_QUERY_KEY_PREFIX,
    query,
    filters,
    perPage,
    status,
    initialPage,
  ];

  const queryOptions = {
    getNextPageParam: (lastPage: any) =>
      lastPage.has_next_page ? lastPage.page + 1 : undefined,
    getPreviousPageParam: (firstPage: any) =>
      firstPage.page > 1 ? firstPage.page - 1 : undefined,
    keepPreviousData: true,
    ...options,
  };

  const result = useInfiniteQuery(queryKey, fetchPage, queryOptions);
  const pages = useMemo(() => result.data?.pages || [], [result.data?.pages]);
  const statusWiseCount = pages[0]?.meta || {};
  const pageSize = pages[0]?.per_page || perPage;
  const broadcasts = useMemo(
    () => flatten(pages.map(page => page.records)),
    [pages],
  );

  return {
    ...result,
    queryKey,
    statusWiseCount,
    broadcasts,
    pageSize,
    pages,
  };
};
