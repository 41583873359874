import classNames from "classnames";
import isUndefined from "lodash/isUndefined";
import { t } from "@/i18n-js/instance";
import { WORKFLOW_TABS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { useSetWorkflowSource } from "@circle-react/components/SettingsApp/Workflows/hooks/useOpenedFromPage";
import { useGetMarketingHubStatus } from "@circle-react/hooks/useGetMarketingHubStatus";
import { Loader } from "@circle-react-uikit/Loader";
import { Tab } from "@circle-react-uikit/TabV2";
import { useWorkflowsData } from "../../context/WorkflowsDataProvider";
import { Header } from "../Header";
import { LandingPage } from "../LandingPage";
import { ArchivedTable } from "./ArchivedTable";
import { AutomationsTable } from "./AutomationsTable";
import { BulkActionsTable } from "./BulkActionsTable";
import { EmptyWorkflows } from "./EmptyWorkflows";
import { MhAccessEndingSoonErrorNotice } from "./MhAccessEndingSoonErrorNotice";
import { ScheduledTable } from "./ScheduledTable";
import { TabErrorNotices } from "./TabErrorNotices";
import { useMarketingHubAccessForDate } from "./useMarketingHubAccessForDate";
import { useWorkflowTabs } from "./useWorkflowTabs";

export const WorkflowTabs = () => {
  const {
    isLoading,
    totalWorkflows,
    hasArchivedWorkflows,
    isWorkflowExecutionEnabled,
    tabsCountersMap,
    selectedTabIndex,
    handleTabChange,
    tabNames,
    tabLabels,
    hasActiveMarketingHubWorkflows,
  } = useWorkflowTabs();

  const { category } = useWorkflowsData();

  useSetWorkflowSource();

  const {
    isLoading: isLoadingMarketingHubAccess,
    isMarketingHubAccessEndingSoon,
    accessEndDate,
  } = useMarketingHubAccessForDate();
  const {
    isLoading: isLoadingMarketingHubStatus,
    data: marketingHubStatusData,
  } = useGetMarketingHubStatus();

  if (
    isLoading ||
    isLoadingMarketingHubAccess ||
    isLoadingMarketingHubStatus ||
    isUndefined(selectedTabIndex)
  ) {
    return <Loader />;
  }

  if (
    !isLoading &&
    (totalWorkflows === 0 ||
      (!isWorkflowExecutionEnabled && !marketingHubStatusData?.enabled))
  ) {
    return category ? <EmptyWorkflows category={category} /> : <LandingPage />;
  }

  return (
    <div className="bg-primary h-full">
      <Tab.Group
        selectedIndex={selectedTabIndex}
        onChange={handleTabChange}
        className="!h-auto overflow-visible"
      >
        <Header
          showBottomBorder={false}
          title={t("settings.workflows.workflows")}
        >
          <Tab.ListWrapper>
            <Tab.List>
              {tabNames.map(tab => (
                <Tab
                  className={classNames({
                    hidden:
                      tab === WORKFLOW_TABS.ARCHIVED && !hasArchivedWorkflows,
                  })}
                  key={tab}
                >
                  {tabLabels[tab]}
                  <Tab.Counter>{tabsCountersMap[tab]}</Tab.Counter>
                </Tab>
              ))}
            </Tab.List>
          </Tab.ListWrapper>
          {hasActiveMarketingHubWorkflows &&
            isMarketingHubAccessEndingSoon() && (
              <MhAccessEndingSoonErrorNotice accessEndDate={accessEndDate} />
            )}
        </Header>
        <TabErrorNotices />
        <Tab.Panels>
          <Tab.Panel>
            <AutomationsTable />
          </Tab.Panel>
          <Tab.Panel>
            <BulkActionsTable />
          </Tab.Panel>
          <Tab.Panel>
            <ScheduledTable />
          </Tab.Panel>
          {hasArchivedWorkflows && (
            <Tab.Panel>
              <ArchivedTable />
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
