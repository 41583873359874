import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const CheckoutFormCouponBadge = () => {
  const { watch } = useFormContext();
  const { clearCouponCode } = usePaywallCheckoutContext();

  const couponCode = watch("coupon_code");

  return (
    <div
      className="flex w-fit flex-row items-center rounded-lg bg-gray-100 px-4 py-2"
      data-testid="checkout-form-coupon-code"
    >
      <Typography.LabelSm weight="medium">{couponCode}</Typography.LabelSm>
      <Button
        small
        className="p-0 text-gray-600"
        type="button"
        variant="plain"
        onClick={clearCouponCode}
        data-testid="checkout-form-remove-coupon"
      >
        <span className="sr-only">
          {t("paywall_checkout.form.buttons.remove_coupon")}
        </span>
        <Icon type="16-close" size={16} />
      </Button>
    </div>
  );
};
