import { t } from "@/i18n-js/instance";
import { Avatar } from "@circle-react/components/SettingsApp/Workflows/components/Avatar";
import { Typography } from "@circle-react-uikit/Typography";
import { SpaceGroupFromId } from "./SpaceGroupFromId";

export interface HistoryDetailViewSpaceGroupProps {
  body: {
    executor_member_id: number;
  };
  url: string;
}

export const HistoryDetailViewSpaceGroup = ({
  body,
  url,
}: HistoryDetailViewSpaceGroupProps) => {
  const { executor_member_id: executorMemberId } = body;

  // get id from the url /api/actions/v1/space_groups/:id/space_group_members.
  const spaceGroupId = new URL(url).pathname.split("/")[5];

  return (
    <div className="flex flex-col gap-2">
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.space_group")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <SpaceGroupFromId id={spaceGroupId} />
        </div>
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.member")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <Avatar communityMemberId={executorMemberId} />
        </div>
      </div>
    </div>
  );
};
