import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import type { PaywallCheckoutPreview } from "@circle-react/types";
import { CheckoutFormSection } from "../CheckoutFormSection";
import { CheckoutPreviewEntry } from "../CheckoutPreviewEntry";

export interface FirstPaymentPreviewProps {
  className?: string;
  preview: PaywallCheckoutPreview;
}
export const FirstPaymentPreview = ({
  className,
  preview,
}: FirstPaymentPreviewProps) => {
  const {
    due_on_phrase: dueOnPhrase,
    formatted_amount_due: formattedAmountDue,
    subtotal,
    formatted_subtotal: formattedSubtotal,
    discount,
    formatted_discount: formattedDiscount,
    tax,
    formatted_tax: formattedTax,
    ending_balance: endingBalance,
    formatted_ending_balance: formattedEndingBalance,
  } = preview.first_payment;

  return (
    <CheckoutFormSection className={classNames("gap-y-2", className)}>
      <CheckoutPreviewEntry
        label={dueOnPhrase}
        labelWeight="semibold"
        value={formattedAmountDue}
        valueWeight="semibold"
        dataTestId="checkout-form-preview-today-payment-total"
      />
      {(discount < 0 || tax > 0 || endingBalance < 0) && (
        <>
          {subtotal >= 0 && (
            <CheckoutPreviewEntry
              label={t("paywall_checkout.coupon.subtotal")}
              value={formattedSubtotal}
              dataTestId="checkout-form-preview-today-payment-subtotal"
            />
          )}
          {discount < 0 && (
            <CheckoutPreviewEntry
              label={t("paywall_checkout.coupon.discount")}
              value={formattedDiscount}
              valueColor="text-green-600"
              dataTestId="checkout-form-preview-today-discount"
            />
          )}
          {tax > 0 && (
            <CheckoutPreviewEntry
              label={t("paywall_checkout.coupon.tax")}
              value={formattedTax}
              dataTestId="checkout-form-preview-today-tax"
            />
          )}
          {endingBalance < 0 && (
            <CheckoutPreviewEntry
              label={t("paywall_checkout.first_payment.ending_balance")}
              tooltip={t(
                "paywall_checkout.first_payment.ending_balance_tooltip",
              )}
              value={formattedEndingBalance}
              valueColor="text-green-600"
              dataTestId="checkout-form-preview-carry-over"
            />
          )}
        </>
      )}
    </CheckoutFormSection>
  );
};
