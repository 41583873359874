import { useEffect, useState } from "react";
import { useIncompleteActionsModal } from "@circle-workflows/components/ViewWorkflowModal/Header/HeaderActions/IncompleteActionsModal";
import { useWorkflow } from "@circle-workflows/components/WorkflowForm/useWorkflow";
import { useDeleteAction } from "@circle-workflows/hooks/useDeleteAction";
import { useFetchWorkflow } from "@circle-workflows/hooks/useFetchWorkflow";
import { useUpsertAction } from "@circle-workflows/hooks/useUpsertAction";
import { useUpsertActionsArray } from "@circle-workflows/hooks/useUpsertActionsArray";
import { get, isString, noop, omit } from "lodash";
import isFunction from "lodash/isFunction";
import { useFieldArray, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import {
  isMarketingHubRequired,
  isPlanCheckFailed,
} from "@/react/components/SettingsApp/Workflows/workflowApiErrorHelper";
import { nextPlanTierForWorkflowUpgrade } from "@/react/helpers/planHelpers";
import { getWorkflowSource } from "@circle-react/components/SettingsApp/Workflows/hooks/useOpenedFromPage";
import { usePunditUserContext } from "@circle-react/contexts";
import { useBlockWithConnectorData } from "@circle-react-shared/BlockWithConnector/BlockWithConnectorDataProvider";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { useToast } from "@circle-react-uikit/ToastV2";
import { WORKFLOW_STATUS, getDefaultAction } from "../../constants";
import { getActionHasValue } from "../WorkflowForm/getActionHasValue";
import { hasSomeTruthyValues } from "../WorkflowForm/hasSomeTruthyValues";
import { useActionsIds } from "./useActionsIds";

export const ACTIONS = "actions";

export const useActions = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { onCloseItemAndOpenNext, setActiveItemId } =
    useBlockWithConnectorData();
  const toast = useToast();
  const incompleteActionsModal = useIncompleteActionsModal();

  const {
    watch,
    setError,
    trigger: revalidate,
    setValue,
    resetField,
    formState,
  } = useFormContext();

  const { actionsIds, itemsIds } = useActionsIds();

  const { dirtyFields, touchedFields, defaultValues } = formState;
  const formActions = watch(ACTIONS, []);
  const hasActions = formActions.length > 0;
  const hasErrors = Object.keys(formState.errors).length > 0;

  const { remove, replace } = useFieldArray({
    name: ACTIONS,
  });

  const { status, id } = useWorkflow();
  const { refetch: refetchWorkflow } = useFetchWorkflow({ workflowId: id });

  const { currentCommunity } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity;
  const upgradePlanModal = useUpgradePlanModal();
  const { refetch: refetchPundit } = usePunditUserContext();
  const showUpgradePlanModal = error => {
    void upgradePlanModal.show({
      ...(isPlanCheckFailed(error) && {
        planTier: nextPlanTierForWorkflowUpgrade(currentPlanTier),
      }),
      subheading: error.body.message,
      isMarketingHubRequired: isMarketingHubRequired(error),
      source: getWorkflowSource(),
      onSuccess: async () => {
        await refetchPundit();
      },
      successButtonLabel: t(
        "settings.workflows.upgrade_modal.activate_this_workflow",
      ),
      usedForWorkflows: true,
      showPlanDetails: true,
    });
  };

  useEffect(() => {
    if (isFirstRender && !hasActions) {
      setIsFirstRender(false);
      replace(defaultValues?.actions);
    }
  }, [defaultValues?.actions, hasActions, isFirstRender, replace]);

  const { mutate: deleteAction, isLoading: isActionDeleting } =
    useDeleteAction();

  const {
    mutateAsync: upsertActionAsync,
    isLoading: isUpdatingAction,
    isError: isActionError,
  } = useUpsertAction();

  const onUpsertActionsSuccess = async (response = [], callback) => {
    const actions = response.map(action => omit(action, "errors"));
    replace(actions);
    resetField(ACTIONS, { defaultValue: actions });

    isFunction(callback) ? callback() : onCloseItemAndOpenNext();

    try {
      await refetchWorkflow();
    } catch (error) {
      console.error(error);
    }
  };

  const onUpsertActionsError = error => {
    const actionsError = error?.body?.data?.actions || [];

    actionsError.forEach((error, index) => {
      for (const field in error) {
        setError(`${ACTIONS}[${index}].${field}`, {
          type: "server",
          message: error[field],
        });
      }
    });

    if (isPlanCheckFailed(error) || isMarketingHubRequired(error)) {
      return showUpgradePlanModal(error);
    }
    return toast.error(error.message);
  };

  const {
    mutateAsync: upsertActionsAsync,
    isLoading: isSavingActions,
    isError: isActionsError,
  } = useUpsertActionsArray({
    onSuccess: onUpsertActionsSuccess,
    onError: onUpsertActionsError,
  });

  const replaceActionsAndSetActiveId = (actions, idIndex) => {
    replace(actions);
    resetField(ACTIONS, { defaultValue: actions });

    const id = actions[idIndex]?.id;
    setActiveItemId(id);
  };

  const addActionAtStart = () => {
    const newAction = getDefaultAction();
    const currentActions = watch(ACTIONS, []);
    const actions = [newAction, ...currentActions];

    replaceActionsAndSetActiveId(actions, 0);
  };

  const addActionAfterPosition = async ({ index }) => {
    const newAction = getDefaultAction();
    const currentActions = watch(ACTIONS, []);
    const actions = [
      ...currentActions.slice(0, index + 1),
      newAction,
      ...currentActions.slice(index + 1),
    ];

    replaceActionsAndSetActiveId(actions, index + 1);
  };

  const doesActionhasServerId = action =>
    isString(action?.id) && !action.id.startsWith("action");

  const removeActionAtPosition = async index => {
    try {
      const actions = watch(ACTIONS, []);
      const action = actions[index];
      const hasServerId = doesActionhasServerId(action);

      remove(index);
      const updatedActions = watch(ACTIONS, []);
      resetField(ACTIONS, { defaultValue: updatedActions });

      if (!hasServerId) {
        return;
      }

      deleteAction(action.id);

      if (updatedActions.length === 0 && status === WORKFLOW_STATUS.ACTIVE) {
        setValue("status", WORKFLOW_STATUS.INACTIVE);
        await refetchWorkflow();

        await incompleteActionsModal.show({
          i18nBase:
            "settings.workflows.edit.incomplete_workflow_no_actions_modal",
          onAction: () => {
            void incompleteActionsModal.hide();
          },
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const areSomeActionsDirty = formActions.some((action, index) => {
    const path = `${ACTIONS}[${index}]`;
    const dirtyInputValue = get(dirtyFields, path, null);
    return hasSomeTruthyValues(dirtyInputValue);
  });

  const areSomeActionsTouched = formActions.some((action, index) => {
    const path = `${ACTIONS}[${index}]`;
    const touchedInputValue = get(touchedFields, path, null);
    return hasSomeTruthyValues(touchedInputValue);
  });

  const areAllActionsFilled = formActions.every(action =>
    getActionHasValue(action),
  );

  const areAllActionsWithServerIds = formActions.every(
    action => isString(action?.id) && !action.id.startsWith("action"),
  );

  const areAllActionsSaved =
    areAllActionsFilled && !areSomeActionsDirty && areAllActionsWithServerIds;

  const saveActions = async (onSuccess = noop) => {
    const areActionsValid = await revalidate(ACTIONS);

    if (!areAllActionsFilled) {
      formActions.forEach((action, index) => {
        if (!getActionHasValue(action)) {
          setError(`actions[${index}]`, {
            type: "manual",
            message: t("settings.workflows.form.step_incomplete"),
          });
        }
      });
      return;
    }

    if (!areActionsValid || !areAllActionsFilled) return;

    try {
      const actions = watch(ACTIONS) || [];
      const response = await upsertActionsAsync(actions);
      onUpsertActionsSuccess(response, onSuccess);
    } catch (error) {
      onUpsertActionsError(error);
    }
  };

  const saveAction = async ({ path, action, skipOpenNext = false }) => {
    try {
      const updatedAction = await upsertActionAsync(action);
      setValue(path, updatedAction);
      resetField(path, { defaultValue: updatedAction });

      if (skipOpenNext) {
        setActiveItemId(updatedAction.id);
      } else {
        onCloseItemAndOpenNext();
      }

      void refetchWorkflow();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return {
    actionsIds,
    isFirstRender,
    addActionAfterPosition,
    addActionAtStart,
    formActions,
    hasActions,
    hasErrors,
    isActionError,
    doesActionhasServerId,
    isActionsError,
    isSavingActions,
    isUpdatingAction,
    itemsIds,
    isActionDeleting,
    removeActionAtPosition,
    saveAction,
    saveActions,
    areAllActionsFilled,
    areSomeActionsDirty,
    areSomeActionsTouched,
    areAllActionsWithServerIds,
    areAllActionsSaved,
  };
};
