import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";

export const EmptyState = ({
  openFilePicker,
}: {
  openFilePicker: () => void;
}) => (
  <div className="flex flex-col items-center justify-center gap-4 text-center">
    <div className="text-dark">
      <Icon
        className="!h-[25px] !w-[25px]"
        type="25-arrow"
        size={25}
        useWithStrokeCurrentColor
      />
    </div>
    <Typography.LabelMd weight="semibold">
      {t(
        "settings.community_bot.knowledge.custom.add_file_modal.uploader.title",
      )}
    </Typography.LabelMd>
    <Typography.LabelSm as="div">
      <SanitizeHTMLString
        content={t(
          "settings.community_bot.knowledge.custom.add_file_modal.uploader.description",
        )}
      />
    </Typography.LabelSm>
    <Button
      variant="secondary"
      onClick={event => {
        event.stopPropagation();
        openFilePicker();
      }}
    >
      {t(
        "settings.community_bot.knowledge.custom.add_file_modal.uploader.button",
      )}
    </Button>
  </div>
);
