import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { useTailwindLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import type { NavigationLink } from "@circle-react-uikit/HeaderV3/NavigationBar/hooks/useNavItemsToLinks";
import { MemberLinks } from "./MemberLinks";
import { PlatformMenuItem } from "./PlatformMenuItem";
import { PlatformMenuItemRenderer } from "./PlatformMenuItemRenderer";

export interface PlatformMenuViewProps {
  allDMsBadgeText?: string;
  disabled?: boolean;
  hasCurrentCommunityMember?: boolean;
  isActive?: boolean;
  isHomePageEnabled?: boolean;
  isMessagingEnabled?: boolean;
  isOnboardingActive?: boolean;
  notificationBadgeText?: string;
  onGettingStartedClick?: () => void;
  onGettingStartedClose?: () => void;
  onNotificationClick?: () => void;
  onSearchClick?: () => void;
  resetNewNotificationsCount?: () => void;
  shouldShowActiveHomeLink?: boolean;
  shouldShowAllEventsPage?: boolean;
  shouldShowChatThreadsTab?: boolean;
  shouldShowCoursesDirectory?: boolean;
  shouldShowLeaderboard?: boolean;
  shouldShowMemberDirectory?: boolean;
  shouldShowOnboarding?: boolean;
  unreadThreadsCount?: number;
  links: NavigationLink[];
}

export const PlatformMenuView = ({
  allDMsBadgeText = "",
  disabled = false,
  hasCurrentCommunityMember = false,
  isHomePageEnabled = false,
  isMessagingEnabled = false,
  isOnboardingActive = false,
  notificationBadgeText = "",
  onGettingStartedClick,
  onGettingStartedClose,
  onNotificationClick,
  onSearchClick,
  resetNewNotificationsCount,
  shouldShowActiveHomeLink = false,
  shouldShowAllEventsPage = false,
  shouldShowChatThreadsTab = false,
  shouldShowCoursesDirectory = false,
  shouldShowLeaderboard = false,
  shouldShowMemberDirectory = false,
  shouldShowOnboarding = false,
  unreadThreadsCount,
  links,
}: PlatformMenuViewProps) => {
  const isLgScreenTw = useTailwindLgScreenMediaQuery();

  return (
    <div className="mt-2.5 px-3">
      {!isLgScreenTw && (
        <PlatformMenuItem
          label={t("search_item")}
          onClick={() => isFunction(onSearchClick) && onSearchClick()}
          iconName="16-search"
        />
      )}
      {shouldShowOnboarding && (
        <PlatformMenuItem
          label={t("getting_started")}
          iconName="lightning"
          isActive={isOnboardingActive}
          onClick={() => {
            if (isFunction(onGettingStartedClick) && !disabled) {
              onGettingStartedClick();
            }
          }}
          rightSideContent={
            <button
              type="button"
              className="flex h-5 w-5 rounded py-0.5 transition-colors hover:bg-black/10 focus:bg-black/10"
              onClick={() => {
                if (isFunction(onGettingStartedClose) && !disabled) {
                  onGettingStartedClose();
                }
              }}
              aria-label={t("close")}
            >
              <Icon type="16-close" size={16} />
            </button>
          }
        />
      )}
      {hasCurrentCommunityMember && (
        <MemberLinks
          notificationBadgeText={notificationBadgeText}
          onNotificationClick={onNotificationClick}
          disabled={disabled}
          resetNewNotificationsCount={resetNewNotificationsCount}
          allDMsBadgeText={allDMsBadgeText}
          isMessagingEnabled={isMessagingEnabled}
          unreadThreadsCount={unreadThreadsCount}
          shouldShowChatThreadsTab={shouldShowChatThreadsTab}
        />
      )}
      {links.map(link => (
        <PlatformMenuItemRenderer
          key={link.linkTo}
          link={link}
          disabled={disabled}
          isHomePageEnabled={isHomePageEnabled}
          shouldShowActiveHomeLink={shouldShowActiveHomeLink}
          shouldShowMemberDirectory={shouldShowMemberDirectory}
          shouldShowAllEventsPage={shouldShowAllEventsPage}
          shouldShowCoursesDirectory={shouldShowCoursesDirectory}
          shouldShowLeaderboard={shouldShowLeaderboard}
        />
      ))}
    </div>
  );
};
