import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { SortByItemLinks } from "@circle-react/components/Spaces/Header/SpaceHeaderActions/SortByDropdown";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export const PostSortOptions = ({ space }) => (
  <div>
    <Dropdown.Header>{t("space_header.sort_order")}</Dropdown.Header>
    <SortByItemLinks
      defaultSort={space?.default_sort || "latest"}
      showDescription={false}
    />
  </div>
);

PostSortOptions.propTypes = {
  space: PropTypes.object.isRequired,
};
