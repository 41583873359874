import classNames from "classnames";

export const FilterButtonSkeleton = ({
  text,
  className,
}: {
  text?: string;
  className?: string;
}) => (
  <div
    className={classNames(
      "bg-tertiary border-primary max-w-[200px] animate-pulse truncate rounded-full border px-4 py-2 text-sm font-medium text-transparent duration-300",
      className,
    )}
  >
    {text || Math.random().toString(36).substring(7, 20)}
  </div>
);
