import { t } from "@/i18n-js/instance";
import { AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOptionWithApiParams } from "../../types";
import { FormTimeDelay } from "./FormTimeDelay";
import type { HistoryTimeDelayViewProps } from "./HistoryTimeDelayView";
import { HistoryTimeDelayView } from "./HistoryTimeDelayView";
import { ViewTimeDelay } from "./ViewTimeDelay";

export const TIME_UNIT_TO_SECONDS = {
  hours: 3600,
  days: 86400,
  weeks: 604800,
} as const;

export type TimeUnit = keyof typeof TIME_UNIT_TO_SECONDS;

export interface TimeDelayApiParams {
  time_unit_amount: number;
  time_unit: TimeUnit;
}

export const timeDelay: Readonly<
  ActionOptionWithApiParams<HistoryTimeDelayViewProps, TimeDelayApiParams>
> = {
  label: t("settings.workflows.form.actions.time_delay"),
  value: "time_delay",
  group: t("settings.workflows.form.action_groups.flow"),
  leftAddon: (
    <Icon type="16-workflow-time-delay" size={16} className="!h-4 !w-4" />
  ),
  formComponent: FormTimeDelay,
  viewComponent: ViewTimeDelay,
  isCompactView: true,
  compactViewTitlePrefix: (
    <Icon type="24-workflow-time-delay" size={24} className="!h-5 !w-5" />
  ),
  historyDetailViewComponent: HistoryTimeDelayView,
  getActionTitle: apiParams => {
    const { time_unit_amount, time_unit } = apiParams;
    if (!time_unit_amount || !time_unit) {
      return t("settings.workflows.view.actions.time_delay.title");
    }
    return t("settings.workflows.view.actions.time_delay.title_with_units", {
      time_unit_amount,
      time_unit: t(
        `settings.workflows.view.actions.time_delay.options.${time_unit}`,
        {
          count: Number(time_unit_amount),
        },
      ),
    });
  },
  icon: "clock",
  isCompactHistoryView: true,
  isApplicableToMembersOnly: false,
  availablePlanTier:
    AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS.MH_WITH_LOWER_OR_HIGHER_TIER_PLAN,
};
