import { useFormContext } from "react-hook-form";
import type {
  MigrationFormPayloadType,
  MigrationFormValuesType,
} from "@/react/types/PaywallAccessGroupMigration";

export const useSanitizeFormPayload = () => {
  const { getValues } = useFormContext<MigrationFormValuesType>();
  const values = getValues();

  const sanitizedPayload: MigrationFormPayloadType = {
    paywall_id: values.paywall_id,
    name: values.name,
    migration_flow: values.migration_flow,
    description: values.description,
    access_group_ids: values?.access_groups?.map(
      (access_group: { id: number }) => access_group.id,
    ),
  };

  return {
    formValues: sanitizedPayload,
  };
};
