import { t } from "@/i18n-js/instance";
import { useFilterContext } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { Filter } from "@circle-react-shared/Filter";
import { Button } from "@circle-react-shared/uikit/Button";
import { Form } from "@circle-react-shared/uikit/Form";

interface Option {
  value: string;
  label: string;
}

interface RadioFilterProps {
  chip: string;
  title: string;
  name: string;
  options: Option[];
  close?: () => void;
}

export const RadioFilter = ({
  chip,
  title,
  name,
  options,
  close,
}: RadioFilterProps) => {
  const { filters, setFilters } = useFilterContext();
  const handleApply = (value: string) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  return (
    <Filter
      chip={chip}
      title={title}
      selectedText={
        options.find(option => option.value === filters[name])?.label
      }
      onApply={handleApply}
      shouldShowRemoveButton
    >
      <Form
        defaultValues={{ [name]: filters[name] }}
        className="p-4"
        onSubmit={submitData => {
          handleApply(submitData[name]);
          close?.();
        }}
      >
        <Form.RadioGroup variant="radio" name={name} options={options} />
        <Button className="mt-4" variant="primary" type="submit" full>
          {t("filters.apply")}
        </Button>
      </Form>
    </Filter>
  );
};
