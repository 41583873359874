import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import type { DropdownProps } from "@circle-react-uikit/Dropdown";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export interface SpaceGroupOptionsDropdownProps extends DropdownProps {
  onAddMembersClick?: () => void;
  onEditSpaceGroupClick?: () => void;
  seeMembersLink: string;
  seeSpacesLink: string;
  onToggle: (isOpen: boolean) => void;
  canManage?: boolean;
}

export const SpaceGroupOptionsDropdown = ({
  onAddMembersClick,
  onEditSpaceGroupClick,
  seeMembersLink,
  seeSpacesLink,
  onToggle,
  canManage = false,
  ...rest
}: SpaceGroupOptionsDropdownProps) => {
  const POPPER_OPTIONS = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [4, 0],
        },
      },
    ],
  };

  const { isViewOnlyMasquerading } = usePunditUserContext();

  return (
    <Dropdown
      className="flex items-center"
      buttonClassName="flex py-0.5 w-5 h-5 rounded hover:bg-black/10 focus-visible:bg-black/10 transition-colors"
      menuButtonEl="button"
      buttonLabel={t("space_groups.view_space_group_options")}
      button={
        <Icon
          type="16-menu-dots-horizontal"
          size={16}
          useWithFillCurrentColor
        />
      }
      onToggle={onToggle}
      popperOptions={POPPER_OPTIONS}
      appendTo={document.body}
      direction="bottom-start"
      data-testid="spacegroup-options-wrapper"
      buttonDataTestId="spacegroup-options-button"
      {...rest}
    >
      <Dropdown.ItemWithLink to={seeSpacesLink} role="menuitem">
        {t("space_groups.see_spaces")}
      </Dropdown.ItemWithLink>
      {canManage && (
        <>
          <Dropdown.ItemWithLink to={seeMembersLink} role="menuitem">
            {t("space_groups.see_members")}
          </Dropdown.ItemWithLink>
          {!isViewOnlyMasquerading && (
            <Dropdown.ItemWithLink
              onClick={() =>
                isFunction(onAddMembersClick) && onAddMembersClick()
              }
              role="menuitem"
            >
              {t("space_groups.add_members")}
            </Dropdown.ItemWithLink>
          )}
          {!isViewOnlyMasquerading && (
            <Dropdown.ItemWithLink
              role="menuitem"
              onClick={() =>
                isFunction(onEditSpaceGroupClick) && onEditSpaceGroupClick()
              }
            >
              {t("space_groups.edit_space_group")}
            </Dropdown.ItemWithLink>
          )}
        </>
      )}
    </Dropdown>
  );
};
