import { useState } from "react";
import { isEmpty } from "lodash";
import { t } from "@/i18n-js/instance";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useConfirmMigrationModal } from "../ConfirmMigrationModal";
import { MIGRATION_FLOW_OPTIONS } from "../constants";
import { useSanitizeFormPayload } from "./useSanitizeFormPayload";

const i18nBase = "settings.access_groups.paywall_migration.modal.form";

export const useMigrationForm = ({
  closeModals,
}: {
  closeModals: () => void;
}) => {
  const confirmMigrationModal = useConfirmMigrationModal();

  const { formValues } = useSanitizeFormPayload();

  const isCreatingAccessGroup =
    formValues.migration_flow === MIGRATION_FLOW_OPTIONS.new_access_group;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabs = { select: 0, review: 1 };
  const isReviewTab = selectedTabIndex === tabs.review;
  const isSelectAccessGroupsTab = selectedTabIndex === tabs.select;

  const toastr = useToast();

  const isFormValid = () => {
    const name = formValues.name;
    const isEmptyName = isEmpty(name) || (name && name.trim() === "");
    const isNameTooLong = name && name.length > 65;
    if (isCreatingAccessGroup && (isEmptyName || isNameTooLong)) {
      if (isEmptyName) {
        toastr.error(t([i18nBase, "validations.name_required"]));
      } else if (isNameTooLong) {
        toastr.error(t([i18nBase, "validations.name_max_length"]));
      }
      return false;
    }
    if (!isCreatingAccessGroup && isEmpty(formValues.access_group_ids)) {
      toastr.error(t([i18nBase, "validations.access_group_required"]));
      return false;
    }
    return true;
  };

  const handleTabChange = (index: number) => {
    if (isFormValid()) {
      setSelectedTabIndex(index);
    }
  };

  const handleFormNavigation = () => {
    if (isSelectAccessGroupsTab && isFormValid()) {
      setSelectedTabIndex(tabs.review);
    } else if (isReviewTab && isFormValid()) {
      void confirmMigrationModal.show({
        formValues: formValues,
        closeModals: closeModals,
      });
    }
  };

  return {
    isCreatingAccessGroup,
    handleFormNavigation,
    isFormValid,
    handleTabChange,
    selectedTabIndex,
    isReviewTab,
  };
};
