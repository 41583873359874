import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { Breadcrumb as BreadcrumbV2 } from "@/react/components/Layout/StandardLayoutV2/Breadcrumb";
import { spaces } from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { PostActionsDropdownMenu } from "../PostViewListItem/PostActionsDropdownMenu";

export const PostDetailsHeader = ({ space, postResource, onPostDestroy }) => {
  const isSpacePresent = !isEmpty(space);
  const isSmScreen = useSmScreenMediaQuery();
  const history = useHistory();
  const { currentCommunitySettings } = usePunditUserContext();
  const isNewPostDetailsHeaderOnMobileWebEnabled = Boolean(
    currentCommunitySettings?.post_details_mobile_header_enabled,
  );

  if (!isSpacePresent) return <div className="h-16"></div>;

  if (isSmScreen && isNewPostDetailsHeaderOnMobileWebEnabled)
    return (
      <div className="bg-primary border-b-primary sticky top-0 z-10 flex items-center justify-between border-b px-5 py-4">
        <IconButton
          name="16-arrow-left"
          iconClassName="!text-dark"
          onClick={() =>
            history.push(
              spaces.show({
                spaceSlug: space.slug,
              }),
            )
          }
        />
        {!(postResource.status === "error") && (
          <PostActionsDropdownMenu
            space={space}
            onChange={postResource.onPostChange}
            onPostDestroy={onPostDestroy}
            refetchPosts={postResource.refetchPosts}
          />
        )}
      </div>
    );

  return (
    <BreadcrumbV2
      hidden={!space.slug}
      backLink={spaces.show({
        spaceSlug: space.slug,
      })}
      backLinkText={t("back_to_space_slug", space)}
    />
  );
};
