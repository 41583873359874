import { useMemo } from "react";
import { useSpaceGroupsList } from "@circle-react/components/shared/MemberFilters/SharedFilters/SpaceGroupsFilter/useSpaceGroupsList";

export const useSpaceGroupNameMap = () => {
  const { visibleSpaceGroups = [] } = useSpaceGroupsList();

  return useMemo(
    () =>
      visibleSpaceGroups.reduce((accumulator, record) => {
        accumulator[record.value] = record.label;
        return accumulator;
      }, {}),
    [visibleSpaceGroups],
  );
};
