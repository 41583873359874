import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useUpdateEventMutation } from "@circle-react/components/Events/EditForm/useUpdateEventMutation";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { staticAssetUrl } from "@circle-react/config/Settings";
import { usePunditUserContext } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import { useThemeContext } from "@circle-react/providers";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { useToast } from "@circle-react-uikit/ToastV2";

interface Props {
  isPinned?: boolean;
  event: any;
  isThumbnailView?: boolean;
  isFullView?: boolean;
}

export const CoverImage = ({
  isPinned,
  event,
  isThumbnailView = false,
  isFullView = false,
}: Props) => {
  const { cover_image_url: coverImageUrl, cover_image: coverImage } = event;
  const { isDarkAppearance } = useThemeContext();
  const { error } = useToast();
  const { updateMutation } = useUpdateEventMutation(event);
  const { canUpdatePost } = useCurrentPostHelpers();
  const { isViewOnlyMasquerading } = usePunditUserContext();

  const coverImageFallbackUrl = isDarkAppearance
    ? staticAssetUrl("dark-mode-cover-thumbnail-2x.png")
    : staticAssetUrl("light-mode-cover-thumbnail-2x.png");

  const onAddCoverImage = async (formValues: any) => {
    try {
      await updateMutation.mutateAsync(formValues);
    } catch (e) {
      error(t("something_went_wrong"));
    }
  };

  const imgSrcUrl = isThumbnailView
    ? coverImageUrl || coverImageFallbackUrl
    : coverImageUrl;
  const postHref = postsPath({
    spaceSlug: event.space_slug,
    slug: event.slug,
  });

  if (!imgSrcUrl) {
    return null;
  }
  return (
    <Link
      id="event-cover-image"
      className="group relative aspect-[2.8] max-h-[366px] w-full shrink-0 sm:mb-6"
      to={postHref}
    >
      <img
        loading="lazy"
        alt=""
        src={imgSrcUrl}
        className={classNames(
          "border-primary relative h-full w-full border object-cover sm:rounded-md",
          isFullView ? "md:rounded-2xl" : "rounded-t-md",
        )}
      />
      {isPinned && (
        <div className="bg-primary border-primary text-default absolute right-4 top-4 flex h-8 w-8 items-center justify-center rounded-full border">
          <Icon type="16-pin-sm" size={16} />
        </div>
      )}
      <Form
        id="event-cover-image"
        onSubmit={onAddCoverImage}
        defaultValues={{ cover_image: coverImage }}
      >
        {({ triggerFormSubmit }) => (
          <CoverImageInput
            name="cover_image"
            showAddCoverBtn={false}
            customAspectRatioHelpText={t(
              "image_upload.recommended_size_event_cover",
            )}
            onImageAttachSuccess={triggerFormSubmit}
            renderer={({ processing, handleChangeCoverClick }) => {
              if (processing || updateMutation.isLoading) {
                return (
                  <div className="bg-primary absolute bottom-0 left-0 flex h-full w-full items-center justify-center rounded-md border">
                    <Loader center />
                  </div>
                );
              }
              if (canUpdatePost) {
                return (
                  <Button
                    variant="secondary"
                    className="absolute bottom-6 left-6 hidden group-hover:block"
                    onClick={handleChangeCoverClick}
                    disabled={processing || isViewOnlyMasquerading}
                  >
                    {t(coverImageUrl ? "update_cover" : "add_cover")}
                  </Button>
                );
              }
              // eslint-disable-next-line react/jsx-no-useless-fragment -- Hard to refactor out
              return <></>;
            }}
          />
        )}
      </Form>
    </Link>
  );
};
