import { useLiveStreamContext } from "@circle-react/contexts/LiveStreams";
import {
  ROOM_VIEW_TYPES,
  canManageRoom,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { useProviderSyncRoom } from "./provider";
import { useRoomType } from "./useRoomType";

export const useViewType = () => {
  const {
    actions: { setViewTypeOverride, updateRoomAsync },
    data: { room, viewTypeOverride },
  } = useLiveStreamContext();
  const { isLiveStream } = useRoomType();
  const viewType = viewTypeOverride || room?.view_type;
  const syncRoom = useProviderSyncRoom();

  const updateViewType = async (newViewType: string) => {
    if (viewType === newViewType) {
      return;
    }

    if (isLiveStream || canManageRoom(room)) {
      try {
        await updateRoomAsync({ view_type: newViewType });
        syncRoom();
      } catch (e: any) {
        console.error(e.message);
      }
    } else {
      setViewTypeOverride(newViewType);
    }
  };

  const setSpeakerTopView = () => updateViewType(ROOM_VIEW_TYPES.SPEAKER);

  const setSpeakerSideView = () =>
    updateViewType(ROOM_VIEW_TYPES.SPEAKER_VARIANT);

  const setGridView = () => updateViewType(ROOM_VIEW_TYPES.GRID);

  return {
    isSpeakerTopView: viewType === ROOM_VIEW_TYPES.SPEAKER,
    isSpeakerSideView: viewType === ROOM_VIEW_TYPES.SPEAKER_VARIANT,
    isSpeakerView:
      viewType === ROOM_VIEW_TYPES.SPEAKER ||
      viewType === ROOM_VIEW_TYPES.SPEAKER_VARIANT,
    isGridView: viewType === ROOM_VIEW_TYPES.GRID,
    setSpeakerTopView,
    setSpeakerSideView,
    setGridView,
  };
};
