import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { BodyMd } from "@circle-react-shared/uikit/Typography/components/Body/BodyMd";
import { TitleSm } from "@circle-react-shared/uikit/Typography/components/Title/TitleSm";
import { useUpdateSetting } from "./queries";

export const KnowledgeBannerV2 = () => {
  const updateSettingMutation = useUpdateSetting();

  return (
    <div className="bg-secondary border-primary flex justify-between border-b px-6 py-8 lg:px-9">
      <div className="flex items-start space-x-8">
        <div className="flex flex-col gap-2">
          <TitleSm weight="semibold">
            {t("settings.community_bot.knowledge.banner.title")}
          </TitleSm>
          <BodyMd color="text-light">
            {t("settings.community_bot.knowledge.banner.description_v2")}
          </BodyMd>
        </div>
      </div>
      <Icon
        type="20-close"
        size={20}
        className="cursor-pointer !self-start"
        onClick={() => {
          updateSettingMutation.mutate({
            hide_knowledge_banner: true,
          });
        }}
      />
    </div>
  );
};
