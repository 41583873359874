import type { SegmentPage } from "@circle-react/components/SettingsApp/Segments/hooks/useSegmentsInfiniteQuery";
import { ALL_SEGMENTS_QUERY_KEY_PREFIX } from "@circle-react/components/SettingsApp/Segments/hooks/useSegmentsInfiniteQuery";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useUnPaginateQuery } from "@circle-react/hooks/reactQuery/useUnPaginateQuery";

interface Segments {
  segments: SegmentPage[];
  isLoading: boolean;
}

export const useSegments = (): Segments => {
  const { records: segments = [], isLoading } = useUnPaginateQuery({
    api: internalApi.segments.index,
    key: ALL_SEGMENTS_QUERY_KEY_PREFIX,
  });

  return { segments, isLoading: isLoading ?? false };
};
