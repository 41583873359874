import { t } from "@/i18n-js/instance";
import { useMigratePaywallToAccessGroupsApi } from "@/react/hooks/paywalls/paywallAccessGroupMigration/useMigratePaywallToAccessGroupsApi";
import type { MigrationFormPayloadType } from "@/react/types/PaywallAccessGroupMigration";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { SecureConfirmationModal } from "@circle-react-shared/ModalsV2/SecureConfirmationModal";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";

const i18nBase =
  "settings.access_groups.paywall_migration.modal.form.confirm_migration";

interface ConfirmMigrationContentProps {
  formValues: MigrationFormPayloadType;
  closeModals: () => void;
}

export const ConfirmMigrationContent = ({
  formValues,
  closeModals,
}: ConfirmMigrationContentProps) => {
  const modal = useModal();
  const { migrateMutation, isMigrating } = useMigratePaywallToAccessGroupsApi({
    closeAllModals: () => {
      void modal.hide();
      closeModals();
    },
  });
  return (
    <SecureConfirmationModal
      title={t([i18nBase, "title"])}
      isOpen={modal.visible}
      onClose={modal.hide}
      onCancel={modal.hide}
      isDisabled={isMigrating}
      confirmText={t([i18nBase, "action"])}
      confirmVariant="danger"
      onConfirm={() => {
        migrateMutation(formValues);
      }}
    >
      <SanitizeHTMLString content={t([i18nBase, "description"])} />
    </SecureConfirmationModal>
  );
};

const ConfirmMigration = ModalManager.create(
  (props: ConfirmMigrationContentProps) => (
    <ConfirmMigrationContent {...props} />
  ),
);

export const useConfirmMigrationModal = () => useModal(ConfirmMigration);
