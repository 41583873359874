import { FORM_ID } from ".";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Drawer } from "@circle-react-uikit/Drawer";

export interface DefaultFooterProps {
  isLoading?: boolean;
}

export const DefaultFooter = ({ isLoading = false }: DefaultFooterProps) => (
  <Drawer.Footer sticky>
    <Button
      type="submit"
      variant="circle"
      full
      disabled={isLoading}
      form={FORM_ID}
    >
      {isLoading ? t("creating") : t("create_space")}
    </Button>
  </Drawer.Footer>
);
