import { t } from "@/i18n-js/instance";
import { NewBadge } from "@circle-react-shared/NewBadge";
import { Typography } from "@circle-react-uikit/Typography";
import { BlockListItem } from "../BlockListItem";

export const PaywallsItem = () => (
  <BlockListItem
    icon="16-payments"
    label={
      <div className="flex flex-row gap-x-2">
        <Typography.LabelSm>
          {t("courses.lockscreen.paywalls")}
        </Typography.LabelSm>
        <NewBadge />
      </div>
    }
  />
);
