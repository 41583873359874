import { TippyV2 } from "@circle-react-shared/TippyV2";

export interface RemovableItemTitleWrapperProps {
  isRemovable: boolean;
  isNotRemovableTooltip?: string;
  children: React.ReactNode;
}
export const RemovableItemTitleWrapper = ({
  isRemovable,
  isNotRemovableTooltip,
  children,
}: RemovableItemTitleWrapperProps) => {
  if (isRemovable) {
    return <>{children}</>;
  }
  return (
    <TippyV2
      className="flex"
      placement="top"
      delay={300}
      content={isNotRemovableTooltip}
      interactive={false}
    >
      {children}
    </TippyV2>
  );
};
