import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { SpaceGroupSelect } from "@circle-react/components/shared/SpaceGroupSelect";
import { useSpaceGroupsContext } from "@circle-react/contexts";
import { useMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Checkbox } from "@circle-react-uikit/Checkbox";
import { Form } from "@circle-react-uikit/Form";

export const BasicInformation = () => {
  const spaceGroupsContext = useSpaceGroupsContext();
  const spaceGroups = spaceGroupsContext.records;
  const isMdScreen = useMdScreenMediaQuery();

  const typeList = [
    {
      label: t("spaces.form.create.space_type_basic_post_label"),
      name: "basic",
      description: t("spaces.form.create.space_type_basic_post_description"),
      icon: {
        default: "space-open",
        selected: "space-open-active",
        viewBox: "0 0 80 80",
      },
      multi: false,
    },
    {
      label: t("spaces.form.create.space_type_event_post_label"),
      name: "event",
      description: t("spaces.form.create.space_type_event_post_description"),
      icon: {
        default: "space-private",
        selected: "space-private-active",
        viewBox: "0 0 80 80",
      },
      multi: false,
    },
  ];

  const visibilityList = [
    {
      label: t("spaces.form.create.visibility_open_label"),
      name: "open",
      description: t("spaces.form.create.visibility_open_description"),
      icon: {
        default: "space-open",
        selected: "space-open-active",
        viewBox: "0 0 80 80",
      },
      alertTitle: t("spaces.form.create.visibility_open_alert_title"),
      multi: false,
    },
    {
      label: t("spaces.form.create.visibility_private_label"),
      name: "private",
      description: t("spaces.form.create.visibility_private_description"),
      icon: {
        default: "space-private",
        selected: "space-private-active",
        viewBox: "0 0 80 80",
      },
      alertTitle: t("spaces.form.create.visibility_private_alert_title"),
      multi: false,
    },
    {
      label: t("spaces.form.create.visibility_secret_label"),
      name: "secret",
      description: t("spaces.form.create.visibility_secret_description"),
      icon: {
        default: "space-members-only",
        selected: "space-members-only-active",
        viewBox: "0 0 80 80",
      },
      multi: false,
    },
  ];

  return (
    <div className="new-space-form__step new-space-form__basic-info">
      <div className="new-space-form__space-group">
        <h2 className="heading-h2">
          {spaceGroups.length === 1 &&
            t("spaces.form.create.space_type_step_title_no_spaces")}
          {spaceGroups.length > 1 &&
            t("spaces.form.create.space_type_step_title")}
        </h2>
        {spaceGroups.length > 1 && (
          <SpaceGroupSelect
            variant="large"
            name="space_group_id"
            spaceGroups={spaceGroups}
          />
        )}
      </div>
      <div className="new-space-form__name">
        <Form.Item
          name="emoji"
          hideBorder
          hideDescription
          label={t("spaces.form.create.icon")}
        >
          <Form.EmojiPicker
            name="emoji"
            placement="bottom-start"
            emojiSize={23}
            defaultEmoji="hash_prefix"
          />
        </Form.Item>
        <Form.Item
          name="name"
          hideBorder
          hideDescription
          translationRoot="spaces.form"
          rules={{ required: "Name is required!", maxLength: 255 }}
          placeholder={t("spaces.form.create.name_your_space")}
          fullWidth
        >
          <Form.Input
            label={t("spaces.form.create.space_name")}
            className="form-control form-control--lg"
            autoFocus
          />
        </Form.Item>
      </div>
      <div className="new-space-form__type">
        <h3 className="heading-h3">
          {t("spaces.form.create.space_type_label")}
        </h3>
        <Checkbox
          id="post_type"
          name="post_type"
          variant="box"
          direction={isMdScreen ? "horizontal" : "vertical"}
          list={typeList}
        />
      </div>

      <div className="new-space-form__visibility">
        <label>{t("spaces.form.create.visibility_step_title")}</label>
        <Checkbox
          id="visibility"
          name="visibility"
          variant="box"
          direction={isMdScreen ? "horizontal" : "vertical"}
          list={visibilityList}
        />
      </div>
    </div>
  );
};

BasicInformation.propTypes = {
  spaceGroupId: PropTypes.number,
};
