import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Typography } from "@circle-react-uikit/Typography";
import { CheckoutFormTaxIdForm } from "./CheckoutFormTaxIdForm";

export const CheckoutFormTaxIdField = () => {
  const { watch } = useFormContext();
  const {
    community_member_billing_info_attributes: {
      address_country: memberAddressCountry,
    },
  } = watch();

  const {
    isMemberAddressRequired,
    isTaxIdCollectionEnabled,
    shouldShowTaxIdForm,
    enableTaxIdCollection,
  } = usePaywallCheckoutContext();

  if (
    !shouldShowTaxIdForm ||
    (isMemberAddressRequired && !memberAddressCountry)
  ) {
    return null;
  }

  if (isTaxIdCollectionEnabled) {
    return <CheckoutFormTaxIdForm />;
  }

  return (
    <div className="flex flex-row items-center gap-x-1">
      <Typography.LabelSm weight="regular">
        {t("paywall_checkout.form.tax_id.message")}{" "}
        <button
          type="button"
          className="cursor-pointer text-blue-600"
          onClick={enableTaxIdCollection}
        >
          {t("paywall_checkout.form.tax_id.cta")}
        </button>
      </Typography.LabelSm>
    </div>
  );
};
