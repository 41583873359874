import { t } from "@/i18n-js/instance";
import {
  MemberFilters,
  SharedFilters,
} from "@circle-react-shared/MemberFilters";
import { AllFiltersList } from "@circle-react-shared/MemberFilters/DynamicFilters";

const {
  SpaceGroupsFilterV2,
  SpacesFilter,
  StatusFilter,
  MemberTagsFilter,
  PaywallsFilter,
  EventsFilter,
  HasPastDueSubscriptionFilter,
  JoinedCommunityDaysAgoFilter,
  LastLoggedInDaysAgoFilter,
  AvatarSet,
  ActivityScoreFilter,
  HeadlineFilter,
  NameFilter,
  LocationFilter,
  BioFilter,
  EmailFilter,
  InvitationLinksFilter,
} = SharedFilters;

const daysAgoFilters = [
  {
    name: "joined_community_days_ago",
    component: JoinedCommunityDaysAgoFilter,
    label: t(
      "members_directory.header.search.filters.joined_community_days_ago",
    ),
    listLabel: t(
      "members_directory.header.search.filters.joined_community_days_ago_available",
    ),
  },
  {
    name: "last_logged_in_days_ago",
    component: LastLoggedInDaysAgoFilter,
    label: t("members_directory.header.search.filters.last_logged_in_days_ago"),
  },
];

const defaultMemberFilters = [
  {
    name: "name",
    component: NameFilter,
    label: t("members_directory.header.search.filters.name"),
  },
  {
    name: "email",
    component: EmailFilter,
    label: t("members_directory.header.search.filters.email"),
  },
  {
    name: "spaces",
    component: SpacesFilter,
    label: t("members_directory.header.search.filters.spaces.singular_title"),
  },
  {
    name: "invitation_link_ids",
    component: InvitationLinksFilter,
    label: t(
      "members_directory.header.search.filters.invitation_link_ids.singular_title",
    ),
  },
  {
    name: "space_groups",
    component: SpaceGroupsFilterV2,
    label: t(
      "members_directory.header.search.filters.space_groups.singular_title",
    ),
  },
  {
    name: "online_status",
    component: StatusFilter,
    label: t("members_directory.header.search.filters.online_status"),
  },
  {
    name: "headline",
    component: HeadlineFilter,
    label: t("members_directory.header.search.filters.headline"),
  },
  {
    name: "bio",
    component: BioFilter,
    label: t("members_directory.header.search.filters.bio"),
  },
  {
    name: "location",
    component: LocationFilter,
    label: t("members_directory.header.search.filters.location"),
  },
  {
    name: "tags",
    component: MemberTagsFilter,
    label: t("members_directory.header.search.filters.tags.singular_title"),
  },
  {
    name: "paywalls",
    component: PaywallsFilter,
    label: t("members_directory.header.search.filters.paywalls.singular_title"),
  },
  {
    name: "events",
    component: EventsFilter,
    label: t("members_directory.header.search.filters.events.singular_title"),
  },
  {
    name: "has_past_due_subscription",
    component: HasPastDueSubscriptionFilter,
    label: t(
      "members_directory.header.search.filters.has_past_due_subscription",
    ),
  },
  ...daysAgoFilters,
  {
    name: "is_avatar_set",
    component: AvatarSet,
    label: t("members_directory.header.search.filters.avatar_set"),
  },
  {
    name: "activity_score",
    component: ActivityScoreFilter,
    label: t("members_directory.header.search.filters.activity_score"),
  },
];

export const MembersFilters = () => (
  <div className="!px-0">
    <MemberFilters
      platformFilters={defaultMemberFilters}
      defaultFilterNames={["name", "spaces", "tags", "events"]}
      className="!items-start border-none pb-0 pt-7"
    >
      <AllFiltersList />
    </MemberFilters>
  </div>
);
