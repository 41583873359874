import type { ReactNode } from "react";
import classNames from "classnames";
import type { RegisterOptions } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";

export interface PricingTabAddPriceModalInputWrapperProps {
  name: string;
  labelKey?: string;
  children: ReactNode;
  placeholder?: string;
  rules?: RegisterOptions;
  transformValue?: (value: string) => string | undefined;
}

export const PricingTabAddPriceModalInputWrapper = ({
  name,
  labelKey,
  children,
  ...rest
}: PricingTabAddPriceModalInputWrapperProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <Form.Item
      {...rest}
      name={name}
      className={classNames({ "border-red-500": error }, "!m-0 grow !p-0")}
      label={t(
        `settings.paywalls.modal.tabs.pricing.add_price_modal.field_labels.${
          labelKey || name
        }`,
      )}
      labelClassName="!mb-2 !text-sm !font-semibold !leading-5 !tighter !normal-case !text-dark"
      labelWrapperClassName="!m-0"
      errorClassName="mt-1 text-red-500 text-sm"
      hideDescription
      hideBorder
    >
      {children}
    </Form.Item>
  );
};
