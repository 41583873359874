import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { SpaceChangesItem } from "./SpaceChangesItem";

interface SpaceChangesModalType {
  lostSpaces: string[];
  newSpaces: string[];
}

const i18nBase =
  "settings.access_groups.paywall_migration.modal.preview.space_changes";

export const SpaceChangesContent = ({
  lostSpaces,
  newSpaces,
}: SpaceChangesModalType) => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title size="sm">
            <Typography.TitleSm weight="bold">
              {t([i18nBase, "title"])}
            </Typography.TitleSm>
            <div className="mt-6">
              <Typography.BodySm>
                {t([i18nBase, "description"])}
              </Typography.BodySm>
            </div>
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="mb-9 overflow-y-auto pt-2">
          {newSpaces.length > 0 && (
            <SpaceChangesItem
              className="mb-3"
              spaces={newSpaces}
              iconType="15-green-checkmark"
              titleTranslation="new_spaces"
            />
          )}
          <SpaceChangesItem
            spaces={lostSpaces}
            iconType="12-red-close"
            titleTranslation="lost_spaces"
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const SpaceChangesModal = ModalManager.create(
  ({ lostSpaces, newSpaces }: SpaceChangesModalType) => (
    <SpaceChangesContent lostSpaces={lostSpaces} newSpaces={newSpaces} />
  ),
);

export const useSpaceChangesModal = () => useModal(SpaceChangesModal);
