import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";
import { BillingItem } from "./BillingItem";

interface InvoiceDetailsProps {
  invoice: any;
  title: string;
}

export const InvoiceDetails = ({ invoice, title }: InvoiceDetailsProps) => {
  if (invoice === null) {
    return null;
  }

  return (
    <>
      {invoice.next_payment_date && (
        <div className="border-primary mb-3 flex justify-between border-b pb-3">
          <Typography.LabelSm weight="bold">{title}</Typography.LabelSm>
          <Typography.LabelSm>{invoice.next_payment_date}</Typography.LabelSm>
        </div>
      )}

      {invoice.line_items.map((lineItem: any) => (
        <BillingItem
          key={lineItem.id}
          item={lineItem.amount}
          label={lineItem.name}
        />
      ))}

      {invoice.coupon_amount && (
        <BillingItem
          item={invoice.coupon_amount}
          label={t("settings.billing.preview_invoice.coupon_terms", {
            coupon_name: invoice.coupon_name,
            discount: invoice.coupon_terms,
          })}
          labelColor="text-light"
          amountColor="text-v2-success"
        />
      )}
      {invoice.tax && <BillingItem item={invoice.tax} labelTranslation="tax" />}
      {invoice.balance && (
        <BillingItem
          item={invoice.balance}
          labelTranslation="balance"
          amountColor="text-v2-success"
        />
      )}
      <BillingItem item={invoice.amount_due} labelTranslation="amount_due" />
    </>
  );
};
