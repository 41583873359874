import type { TwoUserStackProps } from "@circleco/ui-kit";
import { TwoUserStack } from "@circleco/ui-kit";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

type Users = TwoUserStackProps["users"];

interface GroupUserImageProps {
  user1: any;
  user2: any;
  className?: string;
}

/** @deprecated Please use TwoUserStack component from @circleco/ui-kit */
export const GroupUserImage = ({
  user1,
  user2,
  className = "",
}: GroupUserImageProps) => {
  const users: Users = [
    { name: user1?.name || "", src: user1?.avatar_url || "" },
    { name: user2?.name || "", src: user2?.avatar_url || "" },
  ];

  return (
    <div
      className={classNames(
        "border-tertiary group-hover:border-hover mr-2.5",
        className,
      )}
    >
      <TwoUserStack users={users} isFullRounded />
    </div>
  );
};
