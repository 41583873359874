import { useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { MultiStepPanel } from "@circle-react-uikit/MultiStepPanel";
import { StepPanelContainer } from "./StepPanelContainer";

export const FormContainer = ({ currentSpaceGroupId, onModalClose }) => {
  const { watch, trigger } = useFormContext();
  const visibilityInput = watch("visibility");

  const initialSteps = {
    basicInformation: {
      sequence: 1,
      title: t("name_and_type"),
      status: "active",
    },
    lockScreen: { sequence: 2, title: t("lock_screen"), status: "upcoming" },
    notificationPreferences: {
      sequence: 3,
      title: t("notification"),
      status: "upcoming",
    },
  };

  const [stepsData, setStepsData] = useState(initialSteps);
  const isPrivateSpaceWithLockedScreen = () =>
    visibilityInput?.includes("private");

  const onNextClick = async currentStep => {
    switch (currentStep) {
      case stepsData.basicInformation.sequence: {
        const isValidName = await trigger("name");
        if (isValidName) {
          let updatedSteps = {
            ...stepsData,
            basicInformation: {
              ...stepsData.basicInformation,
              status: "completed",
            },
          };

          if (isPrivateSpaceWithLockedScreen() === true) {
            updatedSteps = {
              ...updatedSteps,
              lockScreen: { ...stepsData.lockScreen, status: "active" },
            };
          } else {
            updatedSteps = {
              ...updatedSteps,
              lockScreen: { ...stepsData.lockScreen, status: "inactive" },
              notificationPreferences: {
                ...stepsData.notificationPreferences,
                status: "active",
              },
            };
          }
          setStepsData(updatedSteps);
        }
        break;
      }
      case stepsData.lockScreen.sequence: {
        const isValidHeading = await trigger("locked_page_heading");

        if (isValidHeading) {
          const updatedSteps = {
            ...stepsData,
            lockScreen: { ...stepsData.lockScreen, status: "completed" },
            notificationPreferences: {
              ...stepsData.notificationPreferences,
              status: "active",
            },
          };
          setStepsData(updatedSteps);
        }

        break;
      }
    }
  };

  const onPreviousClick = currentStep => {
    switch (currentStep) {
      case stepsData.lockScreen.sequence: {
        const updatedSteps = {
          ...stepsData,
          lockScreen: { ...stepsData.lockScreen, status: "upcoming" },
          basicInformation: {
            ...stepsData.basicInformation,
            status: "active",
          },
        };
        setStepsData(updatedSteps);

        break;
      }
      case stepsData.notificationPreferences.sequence: {
        let updatedSteps = {
          ...stepsData,
          notificationPreferences: {
            ...stepsData.notificationPreferences,
            status: "upcoming",
          },
        };

        if (isPrivateSpaceWithLockedScreen() === true) {
          updatedSteps = {
            ...updatedSteps,
            lockScreen: { ...stepsData.lockScreen, status: "active" },
          };
        } else {
          updatedSteps = {
            ...updatedSteps,
            lockScreen: { ...stepsData.lockScreen, status: "upcoming" },
            basicInformation: {
              ...stepsData.basicInformation,
              status: "active",
            },
          };
        }

        setStepsData(updatedSteps);
        break;
      }
    }
  };

  if (!stepsData) {
    return null;
  }

  return (
    <MultiStepPanel
      steps={stepsData}
      onNextClick={onNextClick}
      onPreviousClick={onPreviousClick}
      onClose={onModalClose}
      stepPanelContainer={
        <StepPanelContainer
          currentSpaceGroupId={currentSpaceGroupId}
          isPrivateSpaceWithLockedScreen={isPrivateSpaceWithLockedScreen}
        />
      }
    />
  );
};

FormContainer.propTypes = {
  currentSpaceGroupId: PropTypes.number,
  onModalClose: PropTypes.func,
};
