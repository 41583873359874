import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { communitiesPaths } from "@circle-react/helpers/routerPathHelpers";
import { PlatformMenuItemLinkV3 } from "./PlatformMenuItemLinkV3";
import { usePlatformMenuV3 } from "./usePlatformMenuV3";

export interface PlatformMenuV3Props {
  isDisabled?: boolean;
  isFeedLinkActive?: boolean;
}

export const PlatformMenuV3 = ({
  isDisabled = false,
  isFeedLinkActive = false,
}: PlatformMenuV3Props) => {
  const { shouldShowOnboarding, hideOnboardingModal } = usePlatformMenuV3();
  const { currentCommunity } = usePunditUserContext();
  const { home_page_enabled: shouldShowFeed } = currentCommunity ?? {};

  if (!shouldShowOnboarding && !shouldShowFeed) {
    return null;
  }

  return (
    <nav aria-label={t("secondary_navigation_bar")}>
      <ul className="bg-c-sidebar mt-4 flex list-none flex-col gap-y-1 px-3 lg:mt-6 lg:px-6">
        {shouldShowOnboarding && (
          <li className="relative">
            <PlatformMenuItemLinkV3
              text={t("getting_started")}
              linkTo={communitiesPaths.onboarding()}
              icon="20-todo-list-v3"
              isDisabled={isDisabled}
              onClose={async () => {
                await hideOnboardingModal.show();
              }}
            />
          </li>
        )}
        {shouldShowFeed && (
          <li className="relative">
            <PlatformMenuItemLinkV3
              text={t("feed")}
              linkTo={communitiesPaths.feed()}
              icon="20-feed-v3"
              isDisabled={isDisabled}
              isActive={isFeedLinkActive}
            />
          </li>
        )}
      </ul>
    </nav>
  );
};
