import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { FormTextSelect } from "@circle-react-shared/uikit/Form/FormTextSelect";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { FrequencyOptions } from "./FrequencyOptions";
import { frequencySelectOptions } from "./frequencySelectOptions";

export function CreateSchedule() {
  const { watch } = useFormContext();

  const frequency = watch("frequency");
  const firstRun = watch("first_run_at");
  const lastRun = watch("last_run_at");
  const daysOfWeek = watch("days_of_week");
  const endsOn = watch("ends_on");

  const isFormFilled = useMemo(() => {
    if (!frequency) {
      return false;
    }

    if (frequency === "weekly" && !daysOfWeek.length) {
      return false;
    }

    if (endsOn === "on" && !lastRun) {
      return false;
    }

    return Boolean(firstRun);
  }, [frequency, firstRun, lastRun, endsOn, daysOfWeek]);

  return (
    <div className="flex flex-col gap-y-10">
      <div>
        <Form.Item
          name="frequency"
          label={t("settings.workflows.recurring_schedule_modal.frequency")}
          hideDescription
          hideBorder
        >
          <FormTextSelect
            name="frequency"
            label={null}
            options={frequencySelectOptions}
          />
        </Form.Item>
        <FrequencyOptions />
      </div>
      <Button type="submit" variant="circle" disabled={!isFormFilled}>
        {t("continue")}
      </Button>
    </div>
  );
}
