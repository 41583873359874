import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import type { ItemProps } from "@circle-react-uikit/Form/Item";

export const FormItem = ({ ...props }: ItemProps) => (
  <Form.Item
    translationRoot="settings.branding_form"
    hideDescription
    hideBorder
    fullWidth
    tooltipText={t(`settings.branding_form.${props.name}_tooltip`)}
    {...props}
  />
);
