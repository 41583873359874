import { stubTrue } from "lodash";
import { t } from "@/i18n-js/instance";
import { useSpacesForFilters } from "@/react/components/Spaces/hooks/useSpacesForFilters";
import { useFilterContext } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { useSpacesContext } from "@circle-react/contexts";
import {
  isBasicPostsSpace,
  isCourseSpace,
  isEventsSpace,
} from "@circle-react/helpers/spaceHelpers";
import {
  CheckboxFilterV2 as CheckboxFilter,
  Filter,
} from "@circle-react-shared/Filter";
import { Loader } from "@circle-react-uikit/Loader";

type SpaceType = "posts" | "courses" | "events" | null;

export interface SpacesFilterProps {
  spaceType?: SpaceType;
}

export const SpacesFilter = ({ spaceType = "posts" }: SpacesFilterProps) => {
  const { filters, setFilters } = useFilterContext();
  const { helpers } = useSpacesContext();

  const filterSpaces = (space: any) => {
    switch (spaceType) {
      case "posts":
        return isBasicPostsSpace(space);
      case "courses":
        return isCourseSpace(space);
      case "events":
        return isEventsSpace(space);
      default:
        return stubTrue();
    }
  };

  const spaces = useSpacesForFilters({
    spaces: helpers.spaceWithViewContentAccess().filter(filterSpaces),
    valueKey: "id",
  });

  return (
    <Filter
      chip={t("search_v2.filters.space")}
      title={t("search_v2.filters.space")}
      selectedText={
        filters?.space_ids
          ? t("search_v2.filters.n_spaces", {
              count: filters?.space_ids?.length || 0,
            })
          : ""
      }
      onApply={data => {
        setFilters({
          ...filters,
          space_ids: (data?.value || []).length ? data?.value : null,
        });
      }}
      shouldShowRemoveButton={!!filters?.space_ids}
    >
      {helpers.isDataLoading() ? (
        <Loader />
      ) : (
        <CheckboxFilter
          options={spaces}
          className="w-full"
          param={{
            id: "spaces",
            key: "spaces",
            value: filters?.space_ids || [],
          }}
          legend={t("search_v2.filters.space")}
          hasSearch
          searchProps={{
            placeholder: t("search_v2.filters.search_spaces"),
          }}
        />
      )}
    </Filter>
  );
};
