import { useCurrentPlanApi } from "@circle-react/components/SettingsApp/Plan/hooks/useCurrentPlanApi";
import { usePunditUserContext } from "@circle-react/contexts";

export const useMarketingHubBilling = () => {
  // TODO: We have another PR to refactor the currentPlanApi to return
  // the MarketingHub subscription
  const { currentPlan, isCurrentPlanLoading } = useCurrentPlanApi();
  const { isLoading: isLoadingPunditContext, currentCommunity } =
    usePunditUserContext();

  const marketingHubBilling = currentPlan?.marketing_hub_billing;
  const billingStatus = marketingHubBilling?.billing_status;
  const marketingBillingFreePeriod =
    currentPlan?.marketing_hub_billing?.free_period;

  const isTrialing = currentCommunity?.is_on_trial;
  const isInFreePeriod = !isTrialing && billingStatus === "free_period";
  const isActive = !isTrialing && billingStatus === "active";

  // [MarketingHub][BeforeRelease] Check if those are the right conditions
  const isInactive =
    !(isActive || isInFreePeriod || isTrialing) || billingStatus === "inactive";

  return {
    marketingHubBilling,
    marketingBillingFreePeriod,
    isLoading: isCurrentPlanLoading || isLoadingPunditContext,
    billingStatus,
    isTrialing,
    isInFreePeriod,
    isActive,
    isInactive,
  };
};
