import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { BodyMd } from "@circle-react-shared/uikit/Typography/components/Body/BodyMd";
import { BodySm } from "@circle-react-shared/uikit/Typography/components/Body/BodySm";
import { TitleSm } from "@circle-react-shared/uikit/Typography/components/Title/TitleSm";
import type { AgentProp } from "./Agents";
import { Avatar } from "./Avatar";
import { useUpdateSetting } from "./queries";

export const AgentsBanner = ({
  agents,
  handleEditAgent,
}: {
  agents: AgentProp[];
  handleEditAgent: (agent: AgentProp) => void;
}) => {
  const updateSettingMutation = useUpdateSetting();

  return (
    <div className="bg-secondary border-primary flex justify-between border-b px-12 py-8">
      <div className="flex items-start space-x-8">
        <Avatar />
        <div className="flex flex-col gap-2">
          <TitleSm weight="semibold">
            {t("settings.community_bot.agents.banner.title")}
          </TitleSm>
          <BodyMd color="text-light">
            {t("settings.community_bot.agents.banner.description")}
          </BodyMd>
          {agents.length > 0 && (
            <Button
              variant="secondary"
              aria-hidden
              className="text-darkest mt-2 flex w-fit gap-1.5"
              onClick={() => handleEditAgent(agents[0])}
            >
              <BodySm weight="bold">
                {t(
                  "settings.community_bot.agents.banner.edit_profile_button.title",
                )}
              </BodySm>
            </Button>
          )}
        </div>
      </div>
      <Icon
        type="20-close"
        size={20}
        className="cursor-pointer !self-start"
        onClick={() => {
          updateSettingMutation.mutate({
            hide_agents_banner: true,
          });
        }}
      />
    </div>
  );
};
