import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { AccessGroupContentParamsType } from "@circle-react/types/AccessGroup";

export const accessGroupContentApi = () => {
  const updateAccessGroupContent = ({
    accessGroupId,
    params,
  }: {
    accessGroupId: number;
    params: AccessGroupContentParamsType;
  }) =>
    reactQueryPut(
      internalApi.admin.accessGroupContent.update(accessGroupId),
      params,
    );

  return {
    updateAccessGroupContent,
  };
};
