import { getCommunityLogoUrlAndType } from "@/react/helpers/communityHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  isCommunityAdmin,
  isCommunityModerator,
} from "@circle-react/helpers/communityMemberHelpers";
import {
  useIsCustomizeCommunityAvailable,
  usePlanHasCustomizeCommunity,
} from "@circle-react/hooks/useIsCustomizeCommunity";
import { useLinkToRoot } from "@circle-react/hooks/useLinkToRoot";
import { useSmOrMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { APPEARANCE, useThemeContext } from "@circle-react/providers";
import { LogoTopSectionView } from "./LogoTopSectionView";
import { TopSectionContainer } from "./TopSectionContainer";

export const LogoTopSection = () => {
  const { currentCommunity, currentCommunityMember, currentCommunitySettings } =
    usePunditUserContext();
  const { goToCommunityRootPath } = useLinkToRoot();
  const { currentAppearance, isDarkAppearance } = useThemeContext();
  const isCustomizeCommunityAvailable = useIsCustomizeCommunityAvailable();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const isModerator = isCommunityModerator(currentCommunityMember);

  const { url, type } = getCommunityLogoUrlAndType(
    currentCommunity,
    isDarkAppearance,
  );

  const hasDarkMode = Boolean(currentCommunitySettings?.dark_mode_enabled);

  const isSmOrMdScreen = useSmOrMdScreenMediaQuery();
  const isCustomizeThemeAvailable = usePlanHasCustomizeCommunity();

  const {
    member_count: memberCount,
    moderation_count: moderationCount,
    dark_icon_url: darkIconUrl,
    dark_logo_url: darkLogoUrl,
  } = currentCommunity ?? {};

  let logoUrl = url;

  if (currentAppearance === APPEARANCE.DARK && isCustomizeThemeAvailable) {
    if (type === "logo" && darkLogoUrl) {
      logoUrl = darkLogoUrl;
    } else if (type === "icon" && darkIconUrl) {
      logoUrl = darkIconUrl;
    }
  }

  return (
    <TopSectionContainer hasPadding={false}>
      <LogoTopSectionView
        communityName={currentCommunity?.name}
        isAdmin={isAdmin}
        isModerator={isModerator}
        logoType={type}
        logoUrl={logoUrl}
        showToggleTheme={hasDarkMode}
        showCustomizeTheme={isCustomizeCommunityAvailable && !isSmOrMdScreen}
        onLogoClick={goToCommunityRootPath}
        memberCount={memberCount}
        moderationCount={moderationCount}
      />
    </TopSectionContainer>
  );
};
