import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { BigLoader } from "@circle-react/components/shared/BigLoader";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useWorkflow } from "../WorkflowForm/useWorkflow";
import { confirmBodyProps } from "./confirmBodyProps";

export interface ConfirmContentProps {
  onCancel: () => void;
  onPublish: () => void;
  onBack?: () => void;
  isLoading?: boolean;
  isRerun?: boolean;
  rerunParams?: any;
}

export const ConfirmContent = ({
  onCancel,
  onPublish,
  isLoading = false,
  isRerun,
  rerunParams,
  onBack,
}: ConfirmContentProps) => {
  const { isBulkAction, isScheduled } = useWorkflow();

  const buttonTitle = () => {
    if (isBulkAction) {
      return t("settings.workflows.confirm_modal.confirm_and_run");
    }

    if (isScheduled) {
      return t("settings.workflows.confirm_modal.schedule");
    }

    return t("settings.workflows.confirm_modal.publish_and_enable");
  };

  if (isLoading) {
    return (
      <Modal.Content>
        <Modal.Body className="w-full !px-10 !py-16">
          <BigLoader
            title={t("settings.workflows.publishing_your_workflow")}
            subtitle={t("settings.workflows.we_are_preparing")}
          />
        </Modal.Body>
      </Modal.Content>
    );
  }

  const { title, body } = confirmBodyProps({
    isBulkAction: isBulkAction,
    isScheduled: isScheduled,
    isRerun,
    rerunParams,
  });

  return (
    <Modal.Content size="md">
      <Modal.Header>
        <Modal.Title size="md">{title}</Modal.Title>
        <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
          <Modal.CloseButton onClick={onCancel} />
        </Modal.HeaderActions>
        {isFunction(onBack) && (
          <Modal.HeaderActions className="absolute left-0 top-0 ml-6 mt-6">
            <Modal.BackButton onClick={onBack} />
          </Modal.HeaderActions>
        )}
      </Modal.Header>
      {body}
      <Modal.Footer className="flex w-full items-center justify-end">
        <Button
          variant={isBulkAction ? "danger" : "circle"}
          onClick={onPublish}
        >
          {buttonTitle()}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
