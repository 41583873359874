import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { usePaywallBlockContext } from "../../Providers/PaywallBlockProvider";
import { isDesktopPreviewMode } from "../../Providers/helpers";
import { Block } from "./Block";
import { EmptyPaywallBlock } from "./EmptyPaywallBlock";
import { LegalText } from "./LegalText";
import {
  isMobilePaywallPriceValid,
  isPaywallValidToRender,
  isWebPaywallPriceValid,
} from "./helpers";
import { usePaywallsBlock } from "./hooks/usePaywallsBlock";

export const PaywallsBlock = ({ paywalls }: any) => {
  const { paywallWithErrorsByIndex, isEditing } = usePaywallsBlock();

  const { previewMode } = usePaywallBlockContext();
  const validator = isDesktopPreviewMode(previewMode)
    ? isWebPaywallPriceValid
    : isMobilePaywallPriceValid;

  const hasAnyPaywallsToRender = paywalls.some((paywall: any) =>
    isPaywallValidToRender(paywall, validator),
  );

  if (!hasAnyPaywallsToRender && !isEditing) {
    return null;
  }

  if (!hasAnyPaywallsToRender && isEditing) {
    return (
      <div
        className={classNames("bg-primary flex w-full flex-col items-center")}
      >
        <EmptyPaywallBlock
          desktopPreviewMode={isDesktopPreviewMode(previewMode)}
        />
      </div>
    );
  }

  const hasFavorites = paywalls.some(
    (paywall: any) => paywall.paywall_highlighted,
  );

  const filterValidPaywallsToRender = (paywall: any) =>
    validator(paywall) || isEditing;

  const getPaywallDescription = (paywall: any) => {
    if (isEditing && isDesktopPreviewMode(previewMode)) {
      return (
        paywall.description ||
        t("paywalls.lock_screen.preview.empty_block.description")
      );
    }
    return paywall.description;
  };

  return (
    <div
      className={classNames(
        "bg-primary flex w-full flex-col items-center gap-y-3",
      )}
    >
      <Block desktopPreviewMode={isDesktopPreviewMode(previewMode)}>
        {paywalls
          .filter(filterValidPaywallsToRender)
          .map((paywall: any, index: number) => (
            <Block.Body
              key={paywall.id}
              isHighlighted={paywall.paywall_highlighted}
              isError={!!paywallWithErrorsByIndex[index]}
              isEmpty={!validator(paywall)}
              hasFavorites={hasFavorites}
            >
              <Block.ContentResolver
                paywall={paywall}
                description={getPaywallDescription(paywall)}
              />
            </Block.Body>
          ))}
      </Block>
      {isDesktopPreviewMode(previewMode) ? null : <LegalText />}
    </div>
  );
};
