import { NavLink } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { formatDate } from "@/react/components/SettingsApp/ManageMembers/TabContent/Cells";
import type { AdvancedSearchResultPost } from "@/react/components/search/types";
import { postsPath } from "@/react/helpers/urlHelpers";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";
import { HighlightedText } from "./HighlightedText";

export const Post = ({ item }: { item: AdvancedSearchResultPost }) => {
  const path = postsPath({ spaceSlug: item.space_slug, slug: item.slug });
  const body = item.highlighted_body || item.body;

  return (
    <NavLink
      to={path}
      className={classNames("flex gap-4 pr-0 md:gap-1 md:pr-6", {
        "flex-col-reverse md:flex-row": item.cover_image_url,
      })}
      data-testid="post-result"
    >
      <div className="flex flex-1 flex-col gap-3">
        {!item.hide_meta_info && (
          <div className="flex items-center gap-2">
            <UserImage
              src={item.user_avatar_url ?? ""}
              name={item.user_name}
              size="6"
            />
            <Typography.LabelXs color="text-selector-active">
              {`${String(item.user_name)} • ${formatDate(item.created_at)}`}
            </Typography.LabelXs>
            {item.flagged_for_approval_at && (
              <BadgeV2
                className="!bg-author-in-review post__headline-tag align mt-0 border-0 align-middle uppercase text-white"
                label={t("in_review")}
              />
            )}
          </div>
        )}
        <div className="[&_mark]:!bg-search-yellow flex flex-col gap-2">
          <Typography.LabelMd weight="semibold">
            <HighlightedText
              originalText={item.name}
              highlightedText={item.highlighted_name}
            />
          </Typography.LabelMd>
          <div className="line-clamp-4">
            <Typography.BodySm color="text-default">
              <SanitizeHTMLString content={body} />
            </Typography.BodySm>
          </div>
        </div>
      </div>

      {item.cover_image_url && (
        <div className="md:w-42 w-full rounded md:h-24">
          <img
            loading="lazy"
            className="h-full w-full rounded-md object-cover"
            src={item.cover_image_url}
            alt=""
          />
        </div>
      )}
    </NavLink>
  );
};
