import { useQuery } from "react-query";
import { billingInfoApi } from "@circle-react/api/paywalls/communityMembers/billingInfoApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { CommunityMemberBillingInfo } from "@circle-react/types";
import type { BillingInfoCommunityMember } from "./BillingInfoModal";

export const useBillingInfoQuery = (
  communityMember?: BillingInfoCommunityMember,
) => {
  const {
    isLoading: isLoadingBillingInfo,
    data: billingInfo,
    refetch: refetchBillingInfo,
  } = useQuery<CommunityMemberBillingInfo, ApiError>(
    internalApi.paywalls.communityMembers.billingInfo.show(
      communityMember?.id || 0,
    ),
    () => billingInfoApi.show(communityMember?.id || 0),
    {
      // 404 is expected for members without a billing information
      retry: (_: number, error) => error.status !== 404,
      useErrorBoundary: error => error?.status !== 404,
      enabled: !!communityMember && !!communityMember.id,
    },
  );

  return {
    isLoadingBillingInfo,
    billingInfo,
    refetchBillingInfo,
  };
};
