import { t } from "@/i18n-js/instance";
import type { DropdownButton } from "./types";
import { adminIconsV3 } from "./useAdminIcons";

export interface UseGetViewAsProps {
  showBanner: () => void;
}

export const useGetviewAs = ({
  showBanner,
}: UseGetViewAsProps): DropdownButton => {
  const { viewAsIcon } = adminIconsV3;

  const viewAs: DropdownButton = {
    icon: viewAsIcon,
    iconSize: 20,
    label: t("view_as"),
    onClick: () => {
      // setTimeout is used here to transfer focus from LogoDropdown to newly opened modal
      // if you just call showBanner, the modal is opened, but search inside modal is not focused,
      // because focus stays on LogoDropdown. Couldn't find a better way to solve this
      setTimeout(() => {
        showBanner();
      }, 0);
    },
    value: "viewAs",
  };
  return viewAs;
};
