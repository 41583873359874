import { t } from "@/i18n-js/instance";
import { useAccessGroups } from "@circle-react/hooks/accessRegistry/useAccessGroups";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useCurrentAction } from "../../../ActionRule";

const i18nRoot = "settings.workflows.form";

export const FormSelectAccessGroup = () => {
  const { paramsPath } = useCurrentAction();

  const {
    data: accessGroupsPaginator,
    isLoading: isLoadingAccessGroups,
    isError: isErrorAccessGroups,
  } = useAccessGroups();

  if (isLoadingAccessGroups) {
    return <Loader center />;
  }

  if (isErrorAccessGroups) {
    return (
      <Typography.LabelXs>
        <span className="text-v2-danger">
          {t([i18nRoot, "errors.access_groups.fetch_failed"])}
        </span>
      </Typography.LabelXs>
    );
  }

  const options = accessGroupsPaginator?.records?.map(
    (accessGroup: AccessGroupType) => ({
      label: accessGroup.name,
      value: accessGroup.id,
    }),
  );

  return (
    <FormTextSelect
      name={`${paramsPath}.access_group_id`}
      label={
        <Typography.LabelSm weight="semibold">
          {t([i18nRoot, "select_access_group"])}
        </Typography.LabelSm>
      }
      options={options}
      rules={{
        required: t([i18nRoot, "errors.required"]),
      }}
      emptyMessage={t([i18nRoot, "errors.access_groups.not_found"])}
    />
  );
};
