import I18n from "@/i18n-js/instance";

export const TABLE_COLUMNS = [
  {
    title: I18n.t("settings.paywall_groups.table.name"),
    dataIndex: "name",
    key: "name",
  },
  {
    title: I18n.t("settings.paywall_groups.table.paywalls"),
    dataIndex: "paywalls",
    key: "paywalls",
  },
  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
  },
];
