import { dateComponents } from "@circle-react/helpers/dateTimeHelpers/dateComponents";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export interface DateAvatarProps {
  dateString: string;
  isSquare?: boolean;
}

export const DateAvatar = ({
  dateString,
  isSquare = false,
}: DateAvatarProps) => {
  const { dayOfMonth, shortMonthString } = dateComponents(dateString);

  return (
    <div
      className={classNames(
        "bg-secondary flex cursor-default flex-col items-center justify-center rounded-md py-1.5",
        {
          "h-12 w-12": isSquare,
          "w-10": !isSquare,
        },
      )}
    >
      <div className="text-dark text-center text-base font-bold leading-5">
        {dayOfMonth}
      </div>
      <div
        className="text-dark text-center font-medium uppercase"
        style={{
          fontSize: "9px",
        }}
      >
        {shortMonthString}
      </div>
    </div>
  );
};
