import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { marketingEmailAddressSettingsApi } from "@circle-react/api/marketingEmailAddressSettingsApi";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import type { MarketingEmailAddressSettingsAttributes } from "../components/BroadcastSettings/interfaces";

export const MARKETING_EMAIL_ADDRESS_SETTINGS_KEY = [
  "marketing-email-address-settings",
];

export const useGetMarketingEmailAddressSettings = () => {
  const toastr = useToast();
  return useQuery<MarketingEmailAddressSettingsAttributes>({
    queryKey: MARKETING_EMAIL_ADDRESS_SETTINGS_KEY,
    queryFn: () => marketingEmailAddressSettingsApi.show(),
    onError: () => toastr.error(t("error")),
  });
};
