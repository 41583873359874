import { compact } from "lodash";
import { t } from "@/i18n-js/instance";
import { isInstallmentsPrice } from "@circle-react/helpers/paywallPriceHelpers";
import { formatPaywallCurrencyAmount } from "@circle-react/helpers/paywalls/currencies";
import { Typography } from "@circle-react-uikit/Typography";

const installmentLocale =
  "paywalls.lock_screen.preview.price.installment_payment";

const priceTypeLocale = "settings.paywall_prices.price_types";
const freeTrialLocale = "paywalls.lock_screen.preview.price.free_trial";
const oncePaymentLocale = "paywalls.lock_screen.preview.price.once_payment";
const trialLocale = "paywalls.lock_screen.preview.price.trial";
const todayPaymentLocale = "paywalls.lock_screen.preview.price.today_payment";

const scenarios: Record<string, any> = {
  trial_then_upfront_then_value: {
    free_trial_label: freeTrialLocale,
    once_payment: oncePaymentLocale,
    installment_payment: installmentLocale,
  },
  upfront_then_trial_then_value: {
    once_payment: oncePaymentLocale,
    free_trial_label: trialLocale,
    installment_payment: installmentLocale,
  },
  trial_then_value: {
    free_trial_label: freeTrialLocale,
    installment_payment: installmentLocale,
  },
  upfront_then_value: {
    once_payment: todayPaymentLocale,
    installment_payment: installmentLocale,
  },
  value: {
    installment_payment: installmentLocale,
  },
};

export const Price = ({ price, currency }: any) => {
  const scenarioToRender = scenarios[price?.scenario] || scenarios["value"];
  const displayAmount = formatPaywallCurrencyAmount(currency, price?.amount, {
    includeCurrencyCode: false,
    includeCurrencySymbol: true,
  });

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-x-1">
        <Typography.TitleXl size="text-4xl">
          {displayAmount || price?.amount_formatted}
        </Typography.TitleXl>
        <Typography.LabelSm color="text-default">
          {currency.code.toUpperCase()}
        </Typography.LabelSm>
      </div>
      <div className="flex flex-col gap-y-2">
        {Object.keys(scenarioToRender).length > 0 && (
          <Typography.LabelXs color="text-default">
            {compact(
              Object.keys(scenarioToRender).map(key => scenarioToRender[key]),
            )
              .map((localeString: string) =>
                t(localeString, {
                  once_payment_amount: formatPaywallCurrencyAmount(
                    currency,
                    price?.upfront_payment_amount,
                  ),
                  installment_count: price?.installments_count,
                  installment_amount: formatPaywallCurrencyAmount(
                    currency,
                    price?.installment_amount,
                  ),
                  interval_qualifier: t([
                    priceTypeLocale,
                    "as_adverb",
                    price.interval,
                    price.interval_count,
                  ]).toLowerCase(),
                  count: price?.trial_days,
                }),
              )
              .join(t("paywalls.lock_screen.preview.price.separator"))}
          </Typography.LabelXs>
        )}
      </div>
    </div>
  );
};

const PriceInstallment = Object.assign(Price, {
  validateToRender: (price: any) => isInstallmentsPrice(price),
});

export { PriceInstallment };
