import { t } from "@/i18n-js/instance";
import { PageMetaData } from "@/react/components/Layout/PageMetaData";
import { StandardLayout } from "@/react/components/Layout/StandardLayout";
import { StandardLayoutV2 } from "@/react/components/Layout/StandardLayoutV2";
import { Breadcrumb as BreadcrumbV2 } from "@/react/components/Layout/StandardLayoutV2/Breadcrumb";
import { EventDropdownActions } from "@/react/components/PostsV3/PostViewListItem/PostActionsDropdownMenu/NewPostActionsDropdown/EventDropdownActions";
import { spaces } from "@/react/helpers/urlHelpers";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { CurrentPostContextProvider } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useLargeScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { EventDetail } from "./EventDetail";

export const EventDetailPage = ({
  event,
  space,
  isLoading,
  onChange,
  onDestroy,
  isSharedPostMinimalLayout = false,
}: any) => {
  const isSmallScreen = useLargeScreenMediaQuery();

  if (isLoading) {
    return <SkeletonLoader variant="new-event-detail" />;
  }

  if (isSharedPostMinimalLayout) {
    const isHeaderVisible = document.getElementById("root-header-v2_1");
    return (
      <StandardLayout.Body
        isMinimalLayout
        className={classNames("!bg-primary", {
          "!min-h-screen": !isHeaderVisible,
          "!min-h-[calc(100vh-61px)]": isHeaderVisible,
        })}
      >
        <PageMetaData bodyClassNames="view-shared-post minimal-layout hide-search-bar" />
        <CurrentPostContextProvider post={event}>
          {event && (
            <div
              className="w-full sm:p-0 lg:mx-auto lg:max-w-5xl lg:p-4"
              data-testid="post-full"
            >
              <EventDetail
                onChange={onChange}
                onDestroy={onDestroy}
                isSharedPostMinimalLayout={isSharedPostMinimalLayout}
              />
            </div>
          )}
        </CurrentPostContextProvider>
      </StandardLayout.Body>
    );
  }

  return (
    <VersionedContent fullWidth>
      <StandardLayout.Body>
        <CurrentPostContextProvider post={event}>
          {({ record, refetchPostDetails }) => (
            <>
              <PageMetaData title={space.name} />
              <BreadcrumbV2
                hidden={!space.slug}
                backLink={spaces.show({
                  spaceSlug: space.slug,
                })}
                backLinkText={t("back_to_space_slug", space)}
                customRightContent={
                  isSmallScreen && (
                    <div className="flex items-center">
                      <BookmarkButton
                        bookmarkType="event"
                        record={record}
                        onSuccess={refetchPostDetails}
                      />
                      <EventDropdownActions
                        onPostDestroy={onDestroy}
                        onChange={onChange}
                      />
                    </div>
                  )
                }
              />
              <StandardLayoutV2.Content noPadding fullWidth fullHeight>
                {event && (
                  <div
                    className="w-full sm:px-0 sm:py-4 md:mx-auto md:max-w-5xl lg:py-2"
                    data-testid="post-full"
                  >
                    <EventDetail onChange={onChange} onDestroy={onDestroy} />
                  </div>
                )}
              </StandardLayoutV2.Content>
            </>
          )}
        </CurrentPostContextProvider>
      </StandardLayout.Body>
    </VersionedContent>
  );
};
