import { t } from "@/i18n-js/instance";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const NewConversationBlock = () => {
  const { currentBotProfile } = useCommunityInboxStore();

  return (
    <div className="mx-2 mb-4">
      {currentBotProfile?.avatar_url && (
        <img
          src={currentBotProfile?.avatar_url}
          alt={currentBotProfile?.name}
          className="mb-2 h-12 w-12 rounded-full"
          loading="lazy"
        />
      )}

      {!currentBotProfile?.avatar_url && (
        <Icon type="48-ai-bot" size={48} className="mb-2 !h-[3rem] !w-[3rem]" />
      )}

      <Typography.TitleSm as="h2" weight="semibold">
        {t("messaging.start_conversation")}
      </Typography.TitleSm>
      <Typography.BodySm color="text-default">
        {t("messaging.bot_start_conversation", {
          name: currentBotProfile?.name,
        })}
      </Typography.BodySm>
    </div>
  );
};
