import { useCallback } from "react";
import { t } from "@/i18n-js/instance";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { useAccessGroupRedirections } from "../../hooks/useAccessGroupRedirections";
import { useAddMembersToAccessGroup } from "../../hooks/useAddMembersToAccessGroup";
import { useAddAccessGroupMembersConfirmationModal } from "./AddAccessGroupMembersConfirmationModal";
import { useAddAccessGroupMemberData } from "./DataStoreContext/AddAccessGroupMembersDataContext";
import { MembersNotInGroup } from "./MembersNotInGroup";

const i18nBase = "settings.access_groups.admin.modal.members";

export const AddAccessGroupMembersModalContent = () => {
  const { totalMembersToProcess } = useAddAccessGroupMemberData();
  const { redirectToEditModal } = useAccessGroupRedirections();
  const handleClose = () => {
    redirectToEditModal();
  };

  const { mutate: addMembers, isLoading: isProcessingMembers } =
    useAddMembersToAccessGroup();

  const handleAddMembers = () => {
    // we have 2 ways to add members, either by selecting them or by using filters
    addMembers();
  };

  const addAccessGroupMembersConfirmationModal =
    useAddAccessGroupMembersConfirmationModal();
  const showAddAccessGroupMembersConfirmationModal = useCallback(() => {
    void addAccessGroupMembersConfirmationModal.show({
      isProcessingMembers,
      totalMembersToProcess,
      handleAddMembers,
    });
  }, [
    isProcessingMembers,
    totalMembersToProcess,
    handleAddMembers,
    addAccessGroupMembersConfirmationModal,
  ]);

  return (
    <FullScreenModal
      isOpen
      onClose={handleClose}
      shouldShowCloseButton
      size="xl"
    >
      <FullScreenModal.Header className="lg:px-9">
        <FullScreenModal.HeaderContent>
          <Typography.TitleSm truncate weight="semibold">
            {t([i18nBase, "add.title"])}
          </Typography.TitleSm>
        </FullScreenModal.HeaderContent>
      </FullScreenModal.Header>
      <FullScreenModal.Body wrapperClassName="!ml-0">
        <MembersNotInGroup />
      </FullScreenModal.Body>
      <FullScreenModal.Footer>
        <div className="flex w-full flex-row justify-end gap-3">
          <Button type="button" onClick={handleClose} variant="secondary">
            {t([i18nBase, "actions.cancel"])}
          </Button>
          <Button
            onClick={showAddAccessGroupMembersConfirmationModal}
            type="submit"
            disabled={isProcessingMembers || totalMembersToProcess === 0}
            variant="circle"
          >
            {t([i18nBase, "actions.add_selected_members"], {
              count: totalMembersToProcess,
            })}
          </Button>
        </div>
      </FullScreenModal.Footer>
    </FullScreenModal>
  );
};
