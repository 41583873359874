import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";

export const SubscriptionGroupIdField = ({ className = "" }: any) => {
  const { hasRecurringPrices } = usePricingTabContext();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!hasRecurringPrices()) {
      setValue("apple_subscription_group_processor_id", "");
    }
  }, [hasRecurringPrices]);

  return (
    <Form.Item
      className={`!py-0 ${className}`}
      name="apple_subscription_group_processor_id"
      hideDescription
      hidePlaceholder
      label={t("settings.paywalls.form.subscription_group_id_label")}
      hideBorder
      rules={{ required: hasRecurringPrices() }}
    >
      <Form.Input disabled={!hasRecurringPrices()} />
    </Form.Item>
  );
};
