import { createContext, useContext } from "react";
import { isFunction, noop } from "lodash";
import { useParams } from "react-router-dom";
import type { Participant } from "@/react/types/Live/Participant";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { isConference } from "@circle-react/helpers/liveStreamRoomHelpers";
import type { Recorder } from "@circle-react/types/Live/Recorder";
import type { Room } from "@circle-react/types/Live/Room";
import { useLiveStreamRoom } from "./useLiveStreamRoom";
import type { RolesMap } from "./useParticipants";
import { useParticipants } from "./useParticipants";
import type { CreateRecorderFunction } from "./useRecorder";
import { useRecorder } from "./useRecorder";

interface LiveStreamContextType {
  status: {
    isLoadingRoom: boolean;
    isLoadingParticipants: boolean;
    isRoomError: boolean;
    apiError: ApiError | null;
    isJoining: boolean;
    isCreatingRecorder: boolean;
    isLeaving: boolean;
    isStreamEnded: boolean;
  };
  data: {
    room?: Room;
    recorder: Recorder | null;
    participants: Record<number, Participant>;
    roles: RolesMap;
    canHost: boolean;
    currentParticipant: Participant | null;
    viewTypeOverride: string | null;
    isConferenceRoom: boolean;
  };
  actions: {
    joinAsHost: () => void;
    joinAsParticipant: () => void;
    createRecorder: CreateRecorderFunction | null;
    updateRoom: (formData: any) => void;
    updateRoomAsync: (formData: any) => Promise<void>;
    updateRoomSilentlyAsync: (formData: any) => Promise<void>;
    goLive: () => void;
    goLiveAsync: () => Promise<void>;
    leave: () => void;
    leaveAsync: () => Promise<Participant | void>;
    endStream: () => void;
    endStreamDelayed: () => void;
    endStreamDelayedAsync: () => Promise<void>;
    onEndStream: (reason: string) => void;
    refetchRoom: () => void;
    refetchParticipants: () => void;
    setViewTypeOverride: (type: string | null) => void;
  };
}

const LiveStreamContext = createContext<LiveStreamContextType>({
  status: {
    isLoadingRoom: false,
    isLoadingParticipants: false,
    isRoomError: false,
    apiError: null,
    isJoining: false,
    isCreatingRecorder: false,
    isLeaving: false,
    isStreamEnded: false,
  },
  data: {
    room: undefined,
    recorder: null,
    participants: {},
    roles: {
      host: [],
      moderator: [],
    },
    canHost: false,
    currentParticipant: null,
    viewTypeOverride: null,
    isConferenceRoom: false,
  },
  actions: {
    joinAsHost: noop,
    joinAsParticipant: noop,
    createRecorder: null,
    updateRoom: noop,
    updateRoomAsync: () => Promise.resolve(),
    updateRoomSilentlyAsync: () => Promise.resolve(),
    goLive: noop,
    goLiveAsync: () => Promise.resolve(),
    leave: noop,
    leaveAsync: () => Promise.resolve(),
    endStream: noop,
    endStreamDelayed: noop,
    endStreamDelayedAsync: () => Promise.resolve(),
    onEndStream: noop,
    refetchRoom: noop,
    refetchParticipants: noop,
    setViewTypeOverride: noop,
  },
});
LiveStreamContext.displayName = "LiveStreamContext";

interface LiveStreamContextProviderProps {
  children:
    | React.ReactNode
    | ((value: LiveStreamContextType) => React.ReactNode);
}

export const LiveStreamContextProvider = ({
  children,
}: LiveStreamContextProviderProps) => {
  const { roomSlug } = useParams<{ roomSlug: string }>();
  const {
    room,
    isLoading: isLoadingRoom,
    isError: isRoomError,
    apiError,
    joinAsHost,
    joinAsParticipant,
    isJoining,
    currentParticipant,
    updateRoom,
    updateRoomAsync,
    updateRoomSilentlyAsync,
    goLive,
    goLiveAsync,
    leave,
    leaveAsync,
    endStream,
    endStreamDelayed,
    endStreamDelayedAsync,
    onEndStream,
    isLeaving,
    refetchRoom,
    isStreamEnded,
    viewTypeOverride,
    setViewTypeOverride,
  } = useLiveStreamRoom(roomSlug);

  const { recorder, createRecorder, isCreatingRecorder } = useRecorder(room);

  const { participants, isLoadingParticipants, refetchParticipants, roles } =
    useParticipants(room, currentParticipant, recorder);

  const value = {
    status: {
      isLoadingRoom,
      isLoadingParticipants,
      isRoomError,
      apiError,
      isJoining,
      isCreatingRecorder,
      isLeaving,
      isStreamEnded,
    },
    data: {
      room,
      recorder,
      participants,
      roles,
      canHost: !!room && room.can_host,
      currentParticipant,
      viewTypeOverride,
      isConferenceRoom: isConference(room),
    },
    actions: {
      joinAsHost,
      joinAsParticipant,
      createRecorder,
      updateRoom,
      updateRoomAsync,
      updateRoomSilentlyAsync,
      goLive,
      goLiveAsync,
      leave,
      leaveAsync,
      endStream,
      endStreamDelayed,
      endStreamDelayedAsync,
      onEndStream,
      refetchRoom,
      refetchParticipants,
      setViewTypeOverride,
    },
  };

  return (
    <LiveStreamContext.Provider value={value}>
      {isFunction(children) ? children(value) : children}
    </LiveStreamContext.Provider>
  );
};

// General context hook with raw value
export const useLiveStreamContext = () => useContext(LiveStreamContext);

export const useLiveStreamContextData = () => useLiveStreamContext().data;

export const useLiveStreamContextActions = () => useLiveStreamContext().actions;
