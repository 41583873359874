import { useMemo } from "react";
import { isEmpty } from "lodash";
import { t } from "@/i18n-js/instance";
import { renderArraySelectedText } from "@/react/components/shared/Filter/FilterTypes/ArrayFilterComparator/useArrayComparatorSelectedItem";
import type { ProfileFieldBaseFilter } from "@/react/components/shared/MemberFilters/types";
import { useArchivedFields } from "@circle-react/components/SettingsApp/ProfileFields/hooks/useArchivedFields";
import { FIELD_TYPES } from "@circle-react/components/shared/ProfileFields/constants";
import { renderTextSelectedItem } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator";
import { useProfileFieldFilters } from "@circle-react-shared/MemberFilters/useProfileFieldFilters";

const buildProfileFieldsFilter = (
  filterParam: ProfileFieldBaseFilter,
  profileField: any,
) => {
  const { label: name, field_type: fieldType, choices = [] } = profileField;
  let displayText: string | null = null;

  if (
    [
      FIELD_TYPES.TEXT,
      FIELD_TYPES.NUMBER,
      FIELD_TYPES.TEXTAREA,
      FIELD_TYPES.LINK,
    ].includes(fieldType)
  ) {
    displayText = renderTextSelectedItem(filterParam);
  } else if ([FIELD_TYPES.SELECT, FIELD_TYPES.CHECKBOX].includes(fieldType)) {
    const filterValue = filterParam.value;
    const choice = choices.find(
      (choice: any) => choice.id === Number(filterValue),
    );
    displayText = renderArraySelectedText(
      filterParam,
      choice ? choice.value : filterValue,
    );
  }

  return {
    name,
    value: displayText,
  };
};

export const useProfileFieldsFilter = (filterMap = []) => {
  const { customProfileFields, isLoadingProfileFields } =
    useProfileFieldFilters({ platformFilters: [], component: () => null });

  const { getArchivedQueryOptions } = useArchivedFields();
  const { data: archivedProfileFields = [], isLoading: isLoadingArchived } =
    getArchivedQueryOptions;

  return useMemo(() => {
    if (isEmpty(filterMap) || isLoadingProfileFields || isLoadingArchived) {
      return [];
    }

    const profileFieldFilters = filterMap.filter(
      ({ key }) => key === "profile_field",
    );

    return profileFieldFilters.map(filterParam => {
      const { profile_field_id: profileFieldId } = filterParam;
      const customField = customProfileFields.find(
        ({ id }: { id: number }) => id === Number(profileFieldId),
      );

      if (customField) {
        return buildProfileFieldsFilter(filterParam, customField);
      }

      const archivedField = archivedProfileFields.find(
        ({ id }: { id: number }) => id === Number(profileFieldId),
      );

      if (archivedField) {
        return buildProfileFieldsFilter(filterParam, archivedField);
      }

      return {
        name: t("settings.workflows.view.deleted_profile_field"),
        value: "",
      };
    });
  }, [
    filterMap,
    isLoadingProfileFields,
    isLoadingArchived,
    customProfileFields,
    archivedProfileFields,
  ]);
};
