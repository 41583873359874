import { TemplateGroup } from "./TemplateGroup";
import { useTemplateList } from "./useTemplateList";

interface TemplateListProps {
  category?: string;
  includeCreateButton?: boolean;
}

export const TemplateList = ({
  category,
  includeCreateButton = false,
}: TemplateListProps) => {
  const { templateGroups } = useTemplateList();

  const filteredGroups = category
    ? templateGroups.filter(
        (template: any) => template.id === `category-${category}`,
      )
    : templateGroups;

  return (
    <div className="flex flex-col pb-12">
      {filteredGroups.map((group: any) => (
        <TemplateGroup
          category={category}
          key={group.id}
          includeCreateButton={includeCreateButton}
          className="pt-8 md:pt-16 md:first:pt-6"
          {...group}
        />
      ))}
    </div>
  );
};
