import { useState } from "react";
import { t } from "@/i18n-js/instance";
import {
  reactQueryPatch,
  transformToFormData,
} from "@/react/helpers/backendRequestHelpers";
import {
  landingPageSettingsPath,
  resetCommunityWelcomeBannerPreferencePath,
} from "@/react/helpers/urlHelpers";
import { useHomePageApi } from "@/react/hooks/home_page/useHomePageApi";
import { useToast } from "@circle-react-uikit/ToastV2";

const defaultFetchOptions = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  credentials: "same-origin",
};

export const useLandingPageSettingsApi = () => {
  const [settings, setSettings] = useState({
    community: {},
    community_setting: {},
  });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [metaData, setMetaData] = useState({
    postSortOptions: [],
    trendingPostWindowLimit: 30,
    homePageOption: {},
  });
  const [loggedInUserSpaceOptions, setLoggedInUserSpaceOptions] = useState([]);
  const [loggedOutUserSpaceOptions, setLoggedOutUserSpaceOptions] = useState(
    [],
  );
  const [isSaving, setIsSaving] = useState(false);
  const { error, success } = useToast();
  const { fetchBannerData } = useHomePageApi();

  const fetchLandingPageSettings = () => {
    setDataLoaded(false);
    fetch(landingPageSettingsPath(), defaultFetchOptions)
      .then(r => r.json())
      .then(data => {
        setSettings(data.settings);
        setMetaData({
          postSortOptions: data.post_sort_options,
          trendingPostWindowLimit: data.trending_post_window_limit,
          homePageOption: data.home_page_option,
        });
        setLoggedInUserSpaceOptions(data.logged_in_user_space_options);
        setLoggedOutUserSpaceOptions(data.logged_out_user_space_options);
      })
      .finally(() => {
        setDataLoaded(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const reSetWelcomeBannerPreference = () =>
    reactQueryPatch(resetCommunityWelcomeBannerPreferencePath());

  const updateLandingPageSettings = async settings => {
    try {
      setIsSaving(true);
      const transform = transformToFormData({
        landing_page_settings: settings,
      });

      //Cannot use react query due to implementation on open graph image.
      fetch(landingPageSettingsPath(), {
        method: "POST",
        body: transform,
        mode: "no-cors",
        ...defaultFetchOptions,
      })
        .then(r => r.json())
        .then(() => {
          success(t("changes_saved"));
          setIsSaving(false);
          fetchBannerData();
        })
        .catch(() => {
          error(t("something_went_wrong"));
          setIsSaving(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  return {
    dataLoaded,
    settings,
    metaData,
    loggedInUserSpaceOptions,
    loggedOutUserSpaceOptions,
    setSettings,
    fetchLandingPageSettings,
    setLoggedInUserSpaceOptions,
    setLoggedOutUserSpaceOptions,
    reSetWelcomeBannerPreference,
    updateLandingPageSettings,
    isSaving,
  };
};
