import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { URL_AND_MAILTO_PATTERN } from "@/react/helpers/urlHelpers/constants";
import { ImageUploadInput } from "@circle-react-shared/ImageUploadInput";
import { Form } from "@circle-react-uikit/Form";
import { ContentTab, DesignTab, EditTabs } from "../Sidebar/EditTabs";

const fourByOneAspectRatio = 4 / 1;
const colorPlaceholder = "#000000";

export const BannerEditTabs = ({ blockDataPath }) => {
  const buttonLabel = useWatch({ name: `${blockDataPath}.button_label` });
  const buttonLink = useWatch({ name: `${blockDataPath}.button_link` });

  return (
    <EditTabs>
      <ContentTab>
        <Form.Item
          name={`${blockDataPath}.heading`}
          hideBorder
          hideDescription
          label={t("courses.lockscreen.fields.heading")}
        >
          <Form.Input />
        </Form.Item>
        <Form.Item
          name={`${blockDataPath}.description`}
          hideBorder
          hideDescription
          label={t("courses.lockscreen.fields.description")}
        >
          <Form.TextArea />
        </Form.Item>
        <Form.Item
          name={`${blockDataPath}.button_label`}
          hideBorder
          hideDescription
          label={t("courses.lockscreen.fields.button_label")}
          rules={{
            required:
              buttonLink &&
              t("courses.lockscreen.errors.button_label_required"),
          }}
        >
          <Form.Input />
        </Form.Item>
        <Form.Item
          name={`${blockDataPath}.button_link`}
          hideBorder
          hideDescription
          label={t("courses.lockscreen.fields.button_link")}
          rules={{
            required:
              buttonLabel &&
              t("courses.lockscreen.errors.button_link_required"),
            pattern: {
              value: URL_AND_MAILTO_PATTERN,
              message: t("courses.lockscreen.errors.button_link_invalid"),
            },
          }}
        >
          <Form.Input />
        </Form.Item>
      </ContentTab>
      <DesignTab>
        <div className="mb-5" />
        <ImageUploadInput
          label={t("courses.lockscreen.fields.background_image")}
          name={`${blockDataPath}.background_image`}
          aspectRatio={fourByOneAspectRatio}
          customAspectRatioHelpText={t(
            "image_upload.recommended_size_lock_screen_background_image",
          )}
          previewVariant="cover"
          hideUnsplash={false}
        />
        <Form.Item
          name={`${blockDataPath}.overlay_color`}
          label={t("courses.lockscreen.fields.overlay_color")}
          placeholder={colorPlaceholder}
          hideBorder
          hideDescription
        >
          <Form.ColorPicker />
        </Form.Item>
        <Form.Item
          name={`${blockDataPath}.heading_color`}
          label={t("courses.lockscreen.fields.heading")}
          placeholder={colorPlaceholder}
          hideBorder
          hideDescription
        >
          <Form.ColorPicker />
        </Form.Item>
        <Form.Item
          name={`${blockDataPath}.text_color`}
          label={t("courses.lockscreen.fields.text")}
          placeholder={colorPlaceholder}
          hideBorder
          hideDescription
        >
          <Form.ColorPicker />
        </Form.Item>
        <Form.Item
          name={`${blockDataPath}.button_background_color`}
          label={t("courses.lockscreen.fields.button_background")}
          placeholder={colorPlaceholder}
          hideBorder
          hideDescription
        >
          <Form.ColorPicker />
        </Form.Item>
        <Form.Item
          name={`${blockDataPath}.button_text_color`}
          label={t("courses.lockscreen.fields.button_label")}
          placeholder={colorPlaceholder}
          hideBorder
          hideDescription
        >
          <Form.ColorPicker />
        </Form.Item>
      </DesignTab>
    </EditTabs>
  );
};

BannerEditTabs.propTypes = {
  blockDataPath: PropTypes.string.isRequired,
};
