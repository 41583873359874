import { useEffect, useRef } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";

interface NameEditorProps {
  name: string;
  setName: (name: string) => void;
  isLoading: boolean;
  onSubmit: () => void;
  onClose: () => void;
  useSemiboldFont?: boolean;
  dataTestId?: string;
}

export const NameEditor = ({
  name,
  setName,
  isLoading,
  onSubmit,
  onClose,
  useSemiboldFont = false,
  dataTestId,
}: NameEditorProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <form onSubmit={onSubmit} className="flex w-full items-center pr-4">
      <input
        ref={inputRef}
        className={classNames(
          "bg-primary border-input focus:border-secondary placeholder:text-light -my-1.5 -ml-2 mr-2 w-full rounded border px-2 py-1.5 text-sm placeholder:opacity-100 focus:outline-none",
          {
            "font-semibold": useSemiboldFont,
          },
        )}
        onChange={e => setName(e.target.value)}
        value={name}
        onBlur={onSubmit}
        data-testid={dataTestId}
      />
      {isLoading ? (
        <Icon type="loader" />
      ) : (
        <>
          <IconButton
            type="submit"
            name="16-check"
            onClick={onSubmit}
            className="!border-v2-success mr-1.5 border"
            iconClassName="!text-v2-success"
            ariaLabel={t("save_and_close")}
          />
          <IconButton
            type="submit"
            className="border-secondary border"
            name="16-close"
            onClick={onClose}
            ariaLabel={t("close")}
          />
        </>
      )}
    </form>
  );
};
