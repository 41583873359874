export const textToRichText = (text: string) => ({
  body: {
    type: "doc",
    content: [
      {
        type: "paragraph",
        content: [{ type: "text", text }],
      },
    ],
  },
  attachments: [],
  localAttachments: [],
  localSgidToObjectMap: {},
});
