import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const MobileDashboard = () => (
  <div className="flex h-full w-full items-center justify-center px-6">
    <div className="flex flex-col items-center gap-2">
      <div className="bg-tertiary mb-4 flex h-16 w-16 items-center justify-center rounded-lg">
        <Icon
          type="20-community-bot"
          className="text-default h-8 !w-8"
          size={20}
        />
      </div>
      <Typography.TitleMd color="text-default">
        {t("settings.community_ai.ai_agents_feature_name")}
      </Typography.TitleMd>
      <Typography.BodyMd color="text-default" textAlign="text-center">
        {t("settings.community_ai.ai_agents_feature_mobile_description")}
      </Typography.BodyMd>
    </div>
  </div>
);
