import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "space_access_management";

interface ListHeaderColumnProp {
  title?: string;
  actionText?: string;
  shouldShowButton?: boolean;
  onClick?: () => void;
}

export const ListHeaderColumn = ({
  title = t([i18nRoot, "no_access"]),
  actionText = t([i18nRoot, "add_all"]),
  shouldShowButton = true,
  onClick,
}: ListHeaderColumnProp) => {
  const action = () => {
    onClick?.();
  };

  return (
    <div className="flex w-full items-center justify-between px-6 py-5">
      <Typography.LabelXxs weight="semibold" color="text-light">
        {title}
      </Typography.LabelXxs>
      {shouldShowButton && (
        <Button
          type="button"
          variant="text"
          small
          onClick={action}
          className="ring-secondary focus:ring-1"
        >
          {actionText}
        </Button>
      )}
    </div>
  );
};
