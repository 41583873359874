import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

const i18nRoot = "settings.workflows.form";

export const useFetchLessons = ({ name, onlyQuizzes = false }) => {
  const { error } = useToast();

  return useQuery(
    ["workflows", "list", "lessons", name],
    () =>
      reactQueryGet(
        onlyQuizzes
          ? internalApi.settings.courses.quizzes.index({ params: { name } })
          : internalApi.settings.courses.lessons.index({ params: { name } }),
      ),
    {
      onError: () => error(t([i18nRoot, "errors", "lessons", "fetch_failed"])),
    },
  );
};
