import classNames from "classnames";
import { t } from "@/i18n-js/instance";

export interface CommunityLogoProps {
  name: string;
  src: string;
  className?: string;
}

export const CommunityLogo = ({ name, src, className }: CommunityLogoProps) => (
  <img
    loading="eager"
    id="community-logo"
    alt={t("community_logo_alt", { community_name: name })}
    className={classNames("max-h-9 max-w-[14.375rem]", className)}
    src={src}
  />
);
