import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { updateOrRemoveQueryParam } from "@circle-react/helpers/urlHelpers";
import { getPageSize } from "@circle-react/hooks/useCommunityMembersInfiniteQueryV2";
import { useCommunityMembersInfiniteQuery } from "@circle-react/hooks/useCommunityMembersInfiniteQueryV2";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { Loader } from "@circle-react-uikit/Loader";
import { MembersNotInGroupTable } from "../AccessGroupMembersTable/MembersNotInGroupTable";
import { useFilterParams } from "../AccessGroupMembersTable/hooks/useFilterParams";
import { useAccessGroupData } from "../DataStoreContext/AccessGroupDataContext";
import { useAddAccessGroupMemberData } from "./DataStoreContext/AddAccessGroupMembersDataContext";

const i18nBase = "settings.access_groups.admin.modal.members";
const pageQueryName = "page";

export const MembersNotInGroup = () => {
  const queryParams = useRouterQueryParams();
  const initialPage = Number(queryParams[pageQueryName] || 1);
  const [page, setPage] = useState(initialPage);
  const history = useHistory();
  const { accessGroup } = useAccessGroupData();
  const {
    updateTotalMembersToProcess,
    updateFiltersToAddMembers,
    selectedMemberIds,
  } = useAddAccessGroupMemberData();

  const { query, order, onSort, filters, scope } = useFilterParams({
    accessGroupId: accessGroup.id,
    shouldLoadGroupMembers: false,
  });

  useEffect(() => {
    history.push({
      search: updateOrRemoveQueryParam(pageQueryName, String(page)),
    });
  }, [page, history]);

  const infiniteQuery = useCommunityMembersInfiniteQuery({
    perPage: getPageSize(),
    options: {
      keepPreviousData: false,
    },
    isAdminScope: true,
    order: order,
    initialPage: initialPage,
    query: query,
    filters: filters,
    scope: scope,
  });

  useEffect(() => {
    updateFiltersToAddMembers(filters);
    updateTotalMembersToProcess(infiniteQuery.totalQueryMembers);
  }, [
    infiniteQuery.totalQueryMembers,
    updateTotalMembersToProcess,
    filters,
    updateFiltersToAddMembers,
  ]);

  if (infiniteQuery.isRefetching && !infiniteQuery.isFetched) {
    return <Loader center />;
  }

  return (
    <MembersNotInGroupTable
      initialPage={initialPage}
      order={order}
      onSort={onSort}
      setPage={setPage}
      infiniteQuery={infiniteQuery}
    >
      <DataTable.Headline>
        <DataTable.Headline.Title>
          {selectedMemberIds.length == 0
            ? t([i18nBase, "count"], {
                count: infiniteQuery.totalQueryMembers,
              })
            : t([i18nBase, "count_selected"], {
                count: infiniteQuery.totalQueryMembers,
                total_selected: selectedMemberIds.length,
              })}
        </DataTable.Headline.Title>
      </DataTable.Headline>
    </MembersNotInGroupTable>
  );
};
