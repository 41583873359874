import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface SnippetProp {
  id: number;
  name: string;
  type: string;
  used_in_replies: number;
}

export interface SnippetResponse {
  records: Array<SnippetProp>;
  count: number;
}

export const SNIPPETS_FOR_KNOWLEDGE_QUERY_KEY = [
  "internal_api",
  "community_bot",
  "custom_resources",
  "for_knowledge",
];

export const SNIPPETS_FOR_IMPORT_QUERY_KEY = [
  "internal_api",
  "community_bot",
  "custom_resources",
  "for_import",
];

export const useSnippetsForKnowledge = ({
  currentPage,
  snippetIds,
}: {
  currentPage: number;
  snippetIds: number[];
}) =>
  useQuery<SnippetResponse>({
    queryKey: [...SNIPPETS_FOR_KNOWLEDGE_QUERY_KEY, snippetIds],
    queryFn: () =>
      reactQueryGet(
        internalApi.communityBot.customResources.index({
          params: {
            page: currentPage,
            per_page: 10,
            snippet_ids: snippetIds.join(","),
            imported: "true",
          },
        }),
      ),
    keepPreviousData: true,
  });

export const useSnippetsForImport = ({
  currentPage,
  snippetIds,
}: {
  currentPage: number;
  snippetIds: number[];
}) =>
  useQuery<SnippetResponse>({
    queryKey: [...SNIPPETS_FOR_IMPORT_QUERY_KEY, snippetIds],
    queryFn: () =>
      reactQueryGet(
        internalApi.communityBot.customResources.index({
          params: {
            page: currentPage,
            per_page: 10,
            snippet_ids: snippetIds.join(","),
            imported: "false",
          },
        }),
      ),
    keepPreviousData: true,
  });

interface SpaceProp {
  id: number;
  name: string;
  type: string;
  used_in_replies: number;
  posts_count: number;
}

export interface SpaceResponse {
  records: Array<SpaceProp>;
  count: number;
}

export const SPACES_FOR_KNOWLEDGE_QUERY_KEY = [
  "internal_api",
  "community_bot",
  "community_resources",
  "for_knowledge",
];

export const SPACES_FOR_IMPORT_QUERY_KEY = [
  "internal_api",
  "community_bot",
  "community_resources",
  "for_import",
];

export const useSpacesForKnowledge = ({
  currentPage,
  spaceIds,
}: {
  currentPage: number;
  spaceIds: number[];
}) =>
  useQuery<SpaceResponse>({
    queryKey: [...SPACES_FOR_KNOWLEDGE_QUERY_KEY, spaceIds],
    queryFn: () =>
      reactQueryGet(
        internalApi.communityBot.communityResources.index({
          params: {
            page: currentPage,
            per_page: 10,
            imported: "true",
            space_ids: spaceIds.join(","),
          },
        }),
      ),
    keepPreviousData: true,
  });

export const useSpacesForImport = ({
  currentPage,
  spaceIds,
}: {
  currentPage: number;
  spaceIds: number[];
}) =>
  useQuery<SpaceResponse>({
    queryKey: [...SPACES_FOR_IMPORT_QUERY_KEY, spaceIds],
    queryFn: () =>
      reactQueryGet(
        internalApi.communityBot.communityResources.index({
          params: {
            page: currentPage,
            per_page: 10,
            imported: "false",
            space_ids: spaceIds.join(","),
          },
        }),
      ),
    keepPreviousData: true,
  });
