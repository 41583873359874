import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export type HeaderNavItemKind =
  | "custom"
  | "home"
  | "members"
  | "events"
  | "courses"
  | "leaderboard";

export type HeaderNavItemVisibility = "enabled" | "disabled" | "admin_only";

export interface HeaderNavItem {
  id: number;
  community_id: number;
  name: string;
  url: string | null;
  open_in_new_window: boolean;
  visibility: HeaderNavItemVisibility;
  kind: HeaderNavItemKind;
}

export interface UseFetchHeaderNavItemsProps {
  id?: number | string;
}

export const useFetchHeaderNavItems = ({ id }: UseFetchHeaderNavItemsProps) =>
  useQuery<HeaderNavItem[], Error>(
    ["community", "header-nav-items"],
    () => reactQueryGet(internalApi.settings.navigation.list()),
    {
      enabled: !!id,
    },
  );
