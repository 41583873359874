import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { liveStreamApi } from "@circle-react/api";
import { useLiveStreamContextActions } from "@circle-react/contexts/LiveStreams";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useProviderPeerId } from "./provider";

export const useRemoveParticipant = (roomId, participantId, peerId) => {
  const { externalPeerId } = useProviderPeerId(peerId, participantId);
  const { success, error } = useToast();
  const { refetchParticipants } = useLiveStreamContextActions();
  const removeParticipantMutation = useMutation(
    () =>
      liveStreamApi.removeParticipant(
        {
          roomId,
          participantId,
          peerId: externalPeerId,
        },
        {
          forReactQuery: true,
        },
      ),
    {
      onSuccess: () => {
        refetchParticipants();
        success(t("live_streams.room.participant_removed_message"), {
          duration: "short",
          shouldUseProgress: false,
        });
      },
      onError: () => error(t("live_streams.room.participant_removed_error")),
    },
  );

  return removeParticipantMutation.mutate;
};
