import { t } from "@/i18n-js/instance";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace = "settings.emails.view_broadcast";

export const RecipientsProcessingMessage = () => (
  <div className="py-5">
    <Typography.BodyMd color="text-default">
      <SanitizeHTMLString
        content={t([localeNamespace, "please_wait_processing_recipient_list"])}
      />
    </Typography.BodyMd>
  </div>
);
