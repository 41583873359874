import { t } from "@/i18n-js/instance";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { useAccessGroupRedirections } from "../hooks/useAccessGroupRedirections";
import { useAccessGroupWebSocket } from "../hooks/useAccessGroupWebSocket";
import { AccessGroupMembersContent } from "./AccessGroupMembersContent";
import { useAccessGroupData } from "./DataStoreContext/AccessGroupDataContext";

const i18nBase = "settings.access_groups.admin.modal.members";

export const AccessGroupMembers = () => {
  const { accessGroup, shouldBlockActions, clearMemberIdsAndFilters } =
    useAccessGroupData();

  useAccessGroupWebSocket();

  const { redirectToAddMembers } = useAccessGroupRedirections();

  const handelAddMembers = () => {
    clearMemberIdsAndFilters();
    redirectToAddMembers();
  };

  return (
    <FullScreenModal.Body wrapperClassName="max-w-screen-lg">
      <div className="mt-14">
        <div className="flex justify-between px-6 lg:px-9">
          <div>
            <Typography.TitleMd>
              {t([i18nBase, "title"], {
                access_group: accessGroup.name,
              })}
            </Typography.TitleMd>
            <Typography.LabelMd color="text-light">
              {t([i18nBase, "subtitle"])}
            </Typography.LabelMd>
          </div>
          <div>
            <Button
              disabled={shouldBlockActions}
              variant="secondary-light"
              isFullRounded={false}
              onClick={handelAddMembers}
              className="!border-primary"
            >
              {t([i18nBase, "actions.add_members"])}
            </Button>
          </div>
        </div>
        <AccessGroupMembersContent />
      </div>
    </FullScreenModal.Body>
  );
};
