import { useMemo } from "react";
import { isEmpty, isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { getInvitationStatus } from "@/react/components/SettingsApp/ManageMembers/TabContent/Cells";
import type { ArrayComparatorParam } from "@/react/components/shared/Filter/FilterTypes/ArrayFilterComparator/arrayComparators";
import { renderArraySelectedText } from "@/react/components/shared/Filter/FilterTypes/ArrayFilterComparator/useArrayComparatorSelectedItem";
import { formatInvitedAtDaysAgoLabel } from "@/react/components/shared/MemberFilters/SharedFilters/InvitedAtDaysAgoFilter";
import { renderTextSelectedItem } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";
import { renderSelectedOptionLabel as renderActivityScoreRangeLabel } from "@circle-react-shared/MemberFilters/SharedFilters/ActivityScoreFilter";
import { renderSelectedOptionLabel as renderAvatarSetSelectedOption } from "@circle-react-shared/MemberFilters/SharedFilters/AvatarSet";
import { renderJoinedCommunityDaysAgoLabel } from "@circle-react-shared/MemberFilters/SharedFilters/JoinedCommunityDaysAgoFilter";
import { renderLoggedInDaysLabel } from "@circle-react-shared/MemberFilters/SharedFilters/LastLoggedInDaysAgoFilter";
import { renderSelectedOptionLabel as renderMemberSelectedOption } from "@circle-react-shared/MemberFilters/SharedFilters/MemberFilter";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { useAccessGroupNameMap } from "./useAccessGroupNameMap";
import { useEventNameMap } from "./useEventNameMap";
import { useMemberTagsIdMap } from "./useMemberTagsIdMap";
import { usePaywallsNameMap } from "./usePaywallsNameMap";
import { useSegmentsIdMap } from "./useSegmentsIdMap";
import { useSpaceGroupNameMap } from "./useSpaceGroupNameMap";
import { useSpaceNameMap } from "./useSpaceNameMap";

const i18nRoot = "settings.workflows.view";

interface BuildPlatformFilterArgs {
  filterParam: BaseFilter;
  spaceNameMap: Record<string, string>;
  tagsIdMap: Record<number, string>;
  spaceGroupNameMap: Record<string, string>;
  eventNameMap: Record<number, string>;
  paywallsNameMap: Record<string, string>;
  segmentsIdMap: Record<number, string>;
  accessGroupNameMap: Record<string, string>;
}

const valueToRenderTextForArray = (
  filterParam: ArrayComparatorParam,
  itemMap: Record<string, string>,
) =>
  renderArraySelectedText(
    filterParam,
    filterParam.value ? itemMap[filterParam.value] : null,
  );

const buildPlatformFilter = ({
  filterParam,
  spaceNameMap = {},
  tagsIdMap = {},
  spaceGroupNameMap = {},
  eventNameMap = {},
  paywallsNameMap = {},
  segmentsIdMap = {},
  accessGroupNameMap = {},
}: BuildPlatformFilterArgs) => {
  const { key, value } = filterParam;
  const name = t([i18nRoot, "audience_filters", key], {
    defaultValue: t([i18nRoot, "unknown"]),
  });

  const valueToRenderText: any = {
    name: renderTextSelectedItem,
    email: renderTextSelectedItem,
    headline: renderTextSelectedItem,
    spaces: (filterParam: ArrayComparatorParam) =>
      valueToRenderTextForArray(filterParam, spaceNameMap),
    tags: (filterParam: ArrayComparatorParam) =>
      valueToRenderTextForArray(filterParam, tagsIdMap),
    space_groups: (filterParam: ArrayComparatorParam) =>
      valueToRenderTextForArray(filterParam, spaceGroupNameMap),
    events: (filterParam: ArrayComparatorParam) =>
      valueToRenderTextForArray(filterParam, eventNameMap),
    paywalls: (filterParam: ArrayComparatorParam) =>
      valueToRenderTextForArray(filterParam, paywallsNameMap),
    joined_community_days_ago: renderJoinedCommunityDaysAgoLabel,
    last_logged_in_days_ago: renderLoggedInDaysLabel,
    activity_score: renderActivityScoreRangeLabel,
    is_avatar_set: ({ value }: { value: string }) =>
      renderAvatarSetSelectedOption(value),
    member: ({ value }: { value: string }) =>
      renderMemberSelectedOption({ selectedText: value }),
    segment_id: ({ value }: { value: string }) => segmentsIdMap[Number(value)],
    access_group_ids: (filterParam: ArrayComparatorParam) =>
      valueToRenderTextForArray(filterParam, accessGroupNameMap),
    invitation_status: ({ value }: { value: string }) =>
      getInvitationStatus(value),
    invited_at_days_ago: formatInvitedAtDaysAgoLabel,
  };

  if (isFunction(valueToRenderText[key])) {
    return {
      name,
      value: valueToRenderText[key](filterParam),
    };
  }

  return { name, value };
};

export const usePlatformFilters = (allFilters: BaseFilter[] = []) => {
  const spaceNameMap = useSpaceNameMap();
  const tagsIdMap = useMemberTagsIdMap();
  const spaceGroupNameMap = useSpaceGroupNameMap();
  const eventNameMap = useEventNameMap(
    allFilters.find(({ key }) => key === "events"),
  );
  const paywallsNameMap = usePaywallsNameMap();
  const segmentsIdMap = useSegmentsIdMap();

  const accessGroupIdsFilter = allFilters.filter(
    ({ key }) => key === "access_group_ids",
  );

  const accessGroupNameMap = useAccessGroupNameMap(accessGroupIdsFilter);

  return useMemo(() => {
    const platformFilters = allFilters.filter(
      ({ key }) => key !== "profile_field",
    );

    if (isEmpty(platformFilters)) {
      return [];
    }

    return platformFilters.map(filterParam =>
      buildPlatformFilter({
        filterParam,
        spaceNameMap,
        tagsIdMap,
        spaceGroupNameMap,
        eventNameMap,
        paywallsNameMap,
        segmentsIdMap,
        accessGroupNameMap,
      }),
    );
  }, [
    allFilters,
    spaceNameMap,
    tagsIdMap,
    spaceGroupNameMap,
    eventNameMap,
    paywallsNameMap,
    segmentsIdMap,
    accessGroupNameMap,
  ]);
};
