import type { Profile } from "@circle-react/types/CommunityBot/Profile";
import { Icon } from "@circle-react-shared/Icon";

interface AgentAvatarProps {
  profile: Profile;
  className?: string;
}

export const AgentAvatar = ({
  profile,
  className = "!h-[3rem] !w-[3rem]",
}: AgentAvatarProps) => (
  <>
    {!profile.avatar_url ? (
      <Icon type="120-ai-bot" size={120} className={className} />
    ) : (
      <img
        src={profile.avatar_url}
        alt={profile.name}
        className={`rounded-full ${className}`}
        loading="lazy"
      />
    )}
  </>
);
