import { useMutation, useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { accessGroupsApi } from "@circle-react/api/admin/accessGroups/accessGroupsApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { useAccessGroupsStatusInfiniteQuery } from "../../AccessGroupsTabs/hooks/useAccessGroupsStatusInfiniteQuery";
import { getAccessGroupQueryKey } from "./useGetAccessGroup";

interface ResponseData {
  id: number;
}

interface UseRenameAccessGroupParams {
  id: number;
}

export const useRenameAccessGroup = ({ id }: UseRenameAccessGroupParams) => {
  const queryClient = useQueryClient();
  const toastr = useToast();
  const { updateAccessGroup } = accessGroupsApi();
  const { accessGroupsInfiniteQuery } = useAccessGroupsStatusInfiniteQuery();

  return useMutation<
    ResponseData,
    ApiError,
    { name: string; description: string }
  >({
    mutationFn: data =>
      updateAccessGroup({
        id,
        params: { name: data.name, description: data.description },
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries(getAccessGroupQueryKey(id));
      void queryClient.invalidateQueries(accessGroupsInfiniteQuery.queryKey[0]);
      toastr.success(t("settings.access_groups.admin.messages.updated"));
    },
    onError: (error: any) => toastr.error(error.message),
  });
};
