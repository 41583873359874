import { t } from "@/i18n-js/instance";
import { useMarketingEmailLimits } from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/Notices/useMarketingEmailLimits";
import { toLocaleString } from "@circle-react/helpers/number";
import { Icon } from "@circle-react-shared/Icon";
import { ErrorNotice } from "@circle-react-shared/uikit/Notice/ErrorNotice";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";

export const TabErrorNotices = () => {
  const {
    shouldShowEmailNotices,
    isSendingMarketingEmailsAllowed,
    marketingHubData,
  } = useMarketingEmailLimits();

  if (
    !shouldShowEmailNotices ||
    !marketingHubData ||
    isSendingMarketingEmailsAllowed
  ) {
    return null;
  }

  const i18nBase = marketingHubData.trialing
    ? "settings.workflows.table.notices.trialing_limit_exhausted"
    : "settings.workflows.table.notices.marketing_hub_limit_exceeded";

  return (
    <div className="bg-primary px-6 pt-6">
      <ErrorNotice
        iconEl={
          <Icon type="20-info" size={20} className="mt-0.5 !self-start" />
        }
      >
        <SanitizeHTMLString
          sanitizeProps={{ ADD_ATTR: ["target"] }}
          content={t(`${i18nBase}`, {
            limit: toLocaleString(
              marketingHubData.limits.marketing_email_limit,
            ),
          })}
        />
      </ErrorNotice>
    </div>
  );
};
