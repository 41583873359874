import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ImportSnippetsModalComponent } from "./ImportSnippetsModalComponent";

const ImportSnippetsModal = ModalManager.create(
  ({
    snippetIds,
    onImport,
  }: {
    snippetIds: number[];
    onImport: (ids: number[]) => void;
  }) => (
    <ImportSnippetsModalComponent
      snippetIds={snippetIds}
      onImport={onImport}
      page={1}
    />
  ),
);

export const useImportSnippetsModal = () => useModal(ImportSnippetsModal);
