import { t } from "@/i18n-js/instance";
import { botBuilderNavPaths } from "@circle-react/helpers/urlHelpers/botBuilderNavPaths";
import { Button } from "@circle-react-shared/uikit/Button";
import { useCreateAgent } from "../agentsQueries";
import { localeRoot } from "./constants";

export const CallToAction = () => {
  const createAgentMutation = useCreateAgent();

  const handleCreateAgent = () => {
    createAgentMutation.mutate(undefined, {
      onSuccess: data => {
        window.location.href = botBuilderNavPaths.customize({
          botPublicId: data.id.toString(),
        });
      },
    });
  };

  return (
    <div>
      <Button variant="circle" onClick={handleCreateAgent} extraLarge>
        {t([localeRoot, "call_to_action"])}
      </Button>
    </div>
  );
};
