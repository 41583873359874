import { t } from "@/i18n-js/instance";
import { Icon } from "@/react/components/shared/Icon";
import { Typography } from "@/react/components/shared/uikit/Typography";

const i18nBase =
  "settings.access_groups.paywall_migration.modal.preview.space_changes";

interface SpaceChangesItemProps {
  spaces: string[];
  iconType: "12-red-close" | "15-green-checkmark";
  titleTranslation: string;
  className?: string;
}

export const SpaceChangesItem = ({
  spaces,
  iconType,
  titleTranslation,
  className,
}: SpaceChangesItemProps) => {
  if (spaces.length <= 0) {
    return null;
  }
  return (
    <div className={className}>
      <Typography.LabelSm weight="bold">
        {t([i18nBase, titleTranslation], { count: spaces.length })}
      </Typography.LabelSm>
      <ul className="list-none pb-1 pt-2">
        {spaces.map(space => (
          <li key={space} className="flex justify-between pb-1">
            <div className="flex align-middle">
              <div>
                <Icon type="14-blue-bullet" />
              </div>
              <Typography.BodySm>{space}</Typography.BodySm>
            </div>
            <Icon type={iconType} />
          </li>
        ))}
      </ul>
    </div>
  );
};
