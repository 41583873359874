import type { ChatType } from "./slices/createCommunityInboxSlice";
import type { ChatStoreProps } from "./useChatStore";

export const selectInboxMessages = (state: ChatStoreProps) =>
  state.inbox.messages;
export const selectInboxChat =
  (state: ChatStoreProps, type: ChatType) => (id: number) =>
    state.inbox[type === "member" ? "memberChats" : "adminChats"].find(
      chat => chat.id === id,
    );
