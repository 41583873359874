import { AgentAvatar } from "@circle-react/components/Modals/MessagingModal/LeftSidebar/List/BotChatList/AgentAvatar";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { Typography } from "@circle-react-uikit/Typography";

export const PreviewConversationBlock = () => {
  const { currentBotProfile } = useCommunityInboxStore();
  if (!currentBotProfile) {
    return null;
  }
  const { name, bio } = currentBotProfile;

  return (
    <div className="mx-2 mb-4">
      <div className="mb-4">
        <AgentAvatar profile={currentBotProfile} />
      </div>
      <Typography.LabelMd as="div" weight="semibold">
        {name}
      </Typography.LabelMd>
      {bio && (
        <div className="text-default mt-1 whitespace-pre-line text-sm font-normal">
          <TiptapEditor editable={false} rich_text_body={bio} />
        </div>
      )}
    </div>
  );
};
