import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink, useParams } from "react-router-dom";
import { useCourseCustomLabels } from "@/react/components/Spaces/CourseSpace/Shared/useCourseCustomLabels";
import { LessonIcon } from "@/react/components/Spaces/CourseSpace/Summary/Content/LessonIcon";
import { drippedAtTippyContent } from "@/react/helpers/courseHelpers";
import { coursePaths } from "@circle-react/helpers/urlHelpers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";

export const LessonContent = ({ lesson = {}, isMember, section }) => {
  const params = useParams();
  const spaceSlug = params?.spaceSlug;
  const { translatedLabels } = useCourseCustomLabels();

  return (
    <NavLink
      onClick={event => {
        if (!isMember) {
          event.preventDefault();
        }
      }}
      to={coursePaths.showLesson({
        spaceSlug,
        lessonId: lesson.id,
        sectionId: section.id,
      })}
      className={classNames(
        "flex w-full items-center space-x-2 p-4",
        isMember ? "cursor-pointer" : "cursor-default",
      )}
      key={lesson.id}
    >
      <TippyV2
        content={drippedAtTippyContent({
          drippedAt: section.dripped_at,
          dripDelay: section.drip_delay,
          needsToCompletePreviousLesson:
            lesson.needs_to_complete_previous_lesson,
          lessonLabelSingular: translatedLabels.lesson.singular,
          lessonLabelPlural: translatedLabels.lesson.plural,
          sectionLabelSingular: translatedLabels.section.singular,
        })}
        placement="bottom-start"
        disabled={lesson.is_dripped}
        arrow={false}
        className="flex w-full items-center"
        dataTestId="lesson-content-tittle"
      >
        <LessonIcon
          isDripped={lesson.is_dripped}
          isSpaceMember={isMember}
          isCompleted={lesson.completed}
        />
        <div className="ml-3 flex-1">
          <Typography.BodySm>{lesson.name}</Typography.BodySm>
        </div>
        {lesson.featured_media?.duration && (
          <Typography.BodySm>
            {lesson.featured_media.duration}
          </Typography.BodySm>
        )}
      </TippyV2>
    </NavLink>
  );
};

LessonContent.propTypes = {
  lesson: PropTypes.object,
  isMember: PropTypes.bool,
  section: PropTypes.object,
};
