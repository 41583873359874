import { useEffect, useState } from "react";
import { isEmpty, noop } from "lodash";
import { useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { EmptySearch } from "@/react/components/search/EmptySearch";
import { EmptySearchResultModal } from "@/react/components/search/EmptySearchResultModal";
import { SearchInput } from "@/react/components/search/SearchInput";
import { SearchResultModalHeaderSkeleton } from "@/react/components/search/SearchResultModalHeaderSkeleton";
import { SearchResultsModalSkeleton } from "@/react/components/search/SearchResultsModalSkeleton";
import type { SearchType } from "@/react/components/search/types";
import { useRecentSearches } from "@/react/components/search/useRecentSearches";
import { useSearchV2 } from "@/react/components/search/useSearchV2";
import { FilterBar } from "@circle-react/components/Modals/SearchResultsModal/FilterBar";
import { useFilterContext } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { Sort } from "@circle-react/components/Modals/SearchResultsModal/Sort";
import { usePunditUserContext } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Tab } from "@circle-react-uikit/TabV2";
import { Typography } from "@circle-react-uikit/Typography";
import { SearchResultsList } from "./SearchResultsList";

export interface TabObject {
  id: SearchType;
  label: string;
}

export type TabArray = TabObject[];

export interface SearchResultsModalProps {
  isOpen: boolean;
  onClose: () => void;
  defaultSearchValue: string;
}

export const SearchResultsModal = ({
  isOpen,
  onClose = noop,
  defaultSearchValue,
}: SearchResultsModalProps) => {
  const TABS: TabArray = [
    {
      id: "posts",
      label: t("search_v2.tabs.posts"),
    },
    {
      id: "comments",
      label: t("search_v2.tabs.comments"),
    },
    {
      id: "messages",
      label: t("search_v2.tabs.messages"),
    },
    {
      id: "lessons",
      label: t("search_v2.tabs.lessons"),
    },
    {
      id: "events",
      label: t("search_v2.tabs.events"),
    },
    {
      id: "members",
      label: t("search_v2.tabs.members"),
    },
  ];

  const { isViewOnlyMasquerading, currentCommunitySettings } =
    usePunditUserContext();
  const { chat_room_message_search_enabled: isChatRoomMessageSearchEnabled } =
    currentCommunitySettings || {};
  const location = useLocation();
  const [localSearchValue, setLocalSearchValue] = useState<string>("");
  const { addRecentSearch } = useRecentSearches();
  const [type, setType] = useState<SearchType>(TABS[0].id);
  const { filters, resetFilters } = useFilterContext();
  const {
    isLoading,
    isLoadingCount,
    results: searchResults,
    setSearchValue,
    onKeydown,
    searchValue,
    fetchNextPage,
    hasNextPage,
    countData,
  } = useSearchV2({ onClose, type });

  const FILTERED_TABS = isChatRoomMessageSearchEnabled
    ? TABS
    : TABS.filter(tab => tab.id !== "messages");

  const getFilterBasedOnTabIndex = (tabIndex: number): SearchType =>
    FILTERED_TABS[tabIndex].id;

  const onChangeResultTab = (tabIndex: number) => {
    const idToFilter = getFilterBasedOnTabIndex(tabIndex);
    setType(idToFilter);
  };

  const onClearSearch = () => {
    setSearchValue("");
    setLocalSearchValue("");
  };

  const getTabCount = (tabId: SearchType): number =>
    countData?.find(item => item.type === tabId)?.count || 0;

  const onPressEnter = () => {
    addRecentSearch({ type: type, query: localSearchValue });
    setSearchValue(localSearchValue);
  };

  useEffect(() => {
    setSearchValue(defaultSearchValue);
    setLocalSearchValue(defaultSearchValue);
    return () => {
      setType("general");
    };
  }, [defaultSearchValue, setSearchValue]);

  useEffect(() => {
    resetFilters();
  }, [type]);

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <FullScreenModal
      isOpen={isOpen}
      onClose={onClose}
      shouldShowCloseButton
      contentPortalId="search-v2-portal"
    >
      <FullScreenModal.Header>
        <SearchInput
          isLoading={isLoading}
          isFixed={false}
          searchValue={localSearchValue}
          onChange={setLocalSearchValue}
          onKeydown={event => {
            if (event.key === "Enter") {
              onPressEnter();
              return;
            }
            onKeydown(event);
          }}
          onClickCancel={onClearSearch}
          shouldShowClearButton
        />
      </FullScreenModal.Header>

      <div className="mx-auto w-full max-w-screen-md px-3 lg:px-0">
        {isLoadingCount && <SearchResultModalHeaderSkeleton />}

        <div
          className={classNames({
            hidden: isLoadingCount || !searchValue,
          })}
        >
          <div className="mb-4 line-clamp-2 pt-6 md:pt-10">
            <Typography.TitleMd>
              {t("search_v2.search_result_for", {
                query: searchValue,
              })}
            </Typography.TitleMd>
          </div>
          <Tab.Group
            onChange={isViewOnlyMasquerading ? noop : onChangeResultTab}
            className="border-b"
          >
            <Tab.List>
              {FILTERED_TABS.map(tab => (
                <Tab key={tab.id} disabled={isViewOnlyMasquerading}>
                  {tab.label}
                  <Tab.Counter>{getTabCount(tab.id)}</Tab.Counter>
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>
      </div>
      {!isLoading && !!searchValue && (
        <div className="mx-auto mt-2 flex w-full max-w-screen-md list-none flex-wrap items-center space-x-2 px-3 lg:px-0">
          {searchResults.length == 0 && isEmpty(filters) ? null : (
            <FilterBar type={type} />
          )}
          {searchResults.length > 0 && <Sort type={type} />}
        </div>
      )}

      <FullScreenModal.Body wrapperClassName="h-full overflow-auto max-w-screen-md px-3 lg:px-0">
        {isLoading ? (
          <SearchResultsModalSkeleton type={type} numberOfRecords={10} />
        ) : searchValue ? (
          <div id="scrollable-container" className="h-full overflow-auto">
            {searchResults.length > 0 ? (
              <SearchResultsList
                searchResults={searchResults}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
              />
            ) : (
              <EmptySearchResultModal type={type} />
            )}
          </div>
        ) : (
          <EmptySearch />
        )}
      </FullScreenModal.Body>
    </FullScreenModal>
  );
};
