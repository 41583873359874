import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { usePaywallsAdminFormModal } from "./FormModal/";
import { PaymentProcessorStatus } from "./constants";
import "./style.scss";

export const Header = ({
  refetchPaywalls,
  paymentProcessorStatus,
  paywallGroupsEnabled,
}) => {
  const paywallsAdminHeader = usePaywallsAdminFormModal();

  const showPaywallsAdminFormModal = () =>
    paywallsAdminHeader.show({
      paywall: undefined,
      refetchPaywalls,
      paywallGroupsEnabled,
    });

  const renderAccordingByPaymentProcessorStatus = {
    [PaymentProcessorStatus.ENABLED]: () => (
      <div className="flex justify-between">
        <Typography.TitleSm weight="font-semibold">
          {t("settings.paywalls.title")}
        </Typography.TitleSm>
        <Button variant="circle" onClick={showPaywallsAdminFormModal}>
          {t("settings.paywalls.buttons.new")}
        </Button>
      </div>
    ),
  };
  const titleWithoutButton = () => (
    <div className="flex justify-between">
      <Typography.TitleSm weight="font-semibold">
        {t("settings.paywalls.title")}
      </Typography.TitleSm>
    </div>
  );

  const renderComponent =
    renderAccordingByPaymentProcessorStatus[paymentProcessorStatus] ??
    titleWithoutButton;

  return (
    <div className="paywalls-admin-header__container table-wrapper">
      {renderComponent()}
    </div>
  );
};

Header.propTypes = {
  refetchPaywalls: PropTypes.func.isRequired,
  paymentProcessorStatus: PropTypes.string.isRequired,
  paywallGroupsEnabled: PropTypes.bool.isRequired,
};
