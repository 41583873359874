import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useFetchSpaces } from "@circle-react/components/SettingsApp/Workflows/hooks/useFetchSpaces";
import { SpaceSelect } from "@circle-react-shared/SpaceSelect";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";

const i18nRoot = "settings.workflows.form";

export const SpaceSelector = ({ resourceType = "" }) => {
  const { register } = useFormContext();
  const { data, isLoading } = useFetchSpaces();
  const { records = [] } = data || {};

  const spaces = records.filter(({ post_type: postType }) =>
    resourceType ? postType === resourceType : true,
  );

  return (
    <>
      <input
        type="hidden"
        className="hidden"
        {...register("trigger.resource_type")}
      />
      <Form.Item
        name="trigger.resource_id"
        hideBorder
        fullWidth
        hideDescription
        label={t([i18nRoot, "choose_space"])}
        labelClassName="!mb-2 !text-sm"
        errorClassName="text-v2-danger text-xs mt-1"
        placeholder={t([i18nRoot, "search_space"])}
        rules={{
          required: t("settings.workflows.form.errors.required"),
        }}
        className="!py-0"
      >
        {isLoading ? (
          <Loader center />
        ) : (
          <SpaceSelect
            spaces={spaces}
            shouldShowEmoji
            variant="form-input"
            containerClassname="relative"
            dropdownClassName="!top-12"
            rules={{
              required: t("settings.workflows.form.errors.required"),
            }}
          />
        )}
      </Form.Item>
    </>
  );
};

SpaceSelector.propTypes = {
  resourceType: PropTypes.string,
};
