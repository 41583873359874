import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { usePaywalls } from "@/react/components/PaywallCoupons/Admin/FormFields/usePaywalls";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";

export const WhichPaywallSelector = () => {
  const { register } = useFormContext();
  const { activePaywalls, isLoading, isSuccess } = usePaywalls();

  const eventsOptions = activePaywalls.map(paywall => ({
    label: paywall.display_name,
    value: paywall.id,
  }));

  if (isLoading) {
    return <Loader center />;
  }

  if (!isSuccess) {
    return (
      <Typography.LabelXs>
        <span className="text-v2-danger">
          {t("settings.workflows.form.errors.paywalls.fetch_failed")}
        </span>
      </Typography.LabelXs>
    );
  }

  return (
    <>
      <div className="hidden">
        <input type="hidden" {...register("trigger.resource_type")} />
      </div>

      <FormTextSelect
        name="trigger.resource_id"
        label={t("settings.workflows.form.which_paywall")}
        options={eventsOptions}
        rules={{
          required: t("settings.workflows.form.errors.required"),
        }}
        emptyMessage={t("settings.workflows.form.errors.paywalls.not_found")}
      />
    </>
  );
};

WhichPaywallSelector.propTypes = {};
