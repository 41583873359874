import type { ComponentPropsWithoutRef } from "react";
import { useTrialBanner } from "@/react/components/Layout/TrialBanner/useTrialBanner";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useMasqueradeEnabledAndActive } from "@circle-react/hooks/useMasqueradeEnabledAndActive";
import { useTailwindLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useSettingsHeader } from "@circle-react-shared/uikit/HeaderV3/useSettingsHeader";
import { getHeightV3Styles } from "./utils";

export interface ContentWrapperProps extends ComponentPropsWithoutRef<"div"> {
  isBgSecondary?: boolean;
  isBgPrimary?: boolean;
}

export const ContentWrapper = ({
  children,
  isBgSecondary = false,
  isBgPrimary = false,
  className,
  ...rest
}: ContentWrapperProps) => {
  const { shouldShowTrialBanner } = useTrialBanner();
  const isLgScreen = useTailwindLgScreenMediaQuery();
  const isMasqueradingEnabledAndActive = useMasqueradeEnabledAndActive();

  const { isReactFlowWorkflowsRoute } = useSettingsHeader();

  const heightStyles = getHeightV3Styles(
    shouldShowTrialBanner,
    isLgScreen,
    isMasqueradingEnabledAndActive,
    isReactFlowWorkflowsRoute,
  );

  return (
    <div
      className={classNames(
        "standard-layout-v2__content-wrapper",
        {
          "!bg-secondary": isBgSecondary,
          "bg-primary": isBgPrimary,
        },
        heightStyles,
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
