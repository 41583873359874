import { t } from "@/i18n-js/instance";
import { useAudienceInfiniteQuery } from "@circle-react/hooks/useAudienceInfiniteQuery";
import { STATUS_OPTIONS_MAP } from "@circle-react-shared/MembersList/constants";
import { FormTextSelect } from "@circle-react-shared/uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Typography } from "@circle-react-shared/uikit/Typography";

const DEFAULT_PAGE_SIZE = 100;
const filters = [
  { id: "1", key: "role", value: "Admin" },
  { id: "2", key: "member", value: "true" },
];

const audienceQueryOptions = {
  perPage: DEFAULT_PAGE_SIZE,
  order: { created_at: "desc" as const },
  scope: {
    status: STATUS_OPTIONS_MAP.ACTIVE,
    exclude_empty_name: false,
    exclude_empty_profiles: false,
  },
  options: { keepPreviousData: true },
  filters,
};

export const AdminSelector = () => {
  const audienceQuery = useAudienceInfiniteQuery(audienceQueryOptions);
  const { audience: audience, isLoading } = audienceQuery;

  if (isLoading) {
    return <Loader center />;
  }

  const audienceOptions = audience?.map((member: any) => {
    const { name, id } = member;

    return {
      label: name,
      value: id,
    };
  });

  return (
    <FormTextSelect
      name="trigger.resource_id"
      label={
        <Typography.LabelSm weight="semibold">
          {t("settings.workflows.form.which_admin")}
        </Typography.LabelSm>
      }
      options={audienceOptions}
      rules={{
        required: t("settings.workflows.form.errors.required"),
      }}
      emptyMessage={t(
        "settings.workflows.form.errors.upcoming_events.not_found",
      )}
    />
  );
};
