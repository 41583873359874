import { AppBar } from "@/react/components/Layout/AppBar";
import { MobileBottomToolbar } from "@/react/components/Layout/MobileBottomToolbar";
import { useAppendBodyClasses } from "@/react/hooks/useAppendBodyClasses";
import { useSmOrMdScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { usePunditUserContext } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { HeaderV3 } from "@circle-react-shared/uikit/HeaderV3";

export const MinimalLayout = ({ children, bodyClassNames = "" }) => {
  const { currentCommunityMember } = usePunditUserContext();
  useAppendBodyClasses(classNames(bodyClassNames, "trix-v2 minimal-layout"));
  const isSmOrMdScreen = useSmOrMdScreenMediaQuery();

  return (
    <div id="panel" className="minimal-layout__wrapper">
      <div className="community__content !bg-secondary">
        <HeaderV3 isRenderedOutsideSpace />
        <AppBar />
        <div className="standard-layout-v2 no-right-sidebar">{children}</div>
        {Boolean(!!currentCommunityMember && isSmOrMdScreen) && (
          <MobileBottomToolbar />
        )}
      </div>
    </div>
  );
};
