import { isFunction } from "lodash";
import type { ButtonProps } from "@circle-react-uikit/Button";
import { Button } from "@circle-react-uikit/Button";

export interface ContinueButtonProps extends ButtonProps {
  onClick?: () => void;
}

export const ContinueButton = ({
  onClick,
  children,
  ...rest
}: ContinueButtonProps) => (
  <Button
    large={false}
    variant="circle"
    type="button"
    onClick={() => {
      isFunction(onClick) && onClick();
    }}
    {...rest}
  >
    {children}
  </Button>
);
