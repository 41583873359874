import { useActiveParticipantList, useRoom } from ".";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { liveStreamApi } from "@circle-react/api";
import {
  useLiveStreamContextActions,
  useLiveStreamContextData,
} from "@circle-react/contexts/LiveStreams";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useProviderRoleChange } from "./provider";

export const useRemoveCommunityMemberAsCohost = refetchCohosts => {
  const room = useRoom();
  const { requestRoleChange } = useProviderRoleChange();
  const { success, error } = useToast();
  const { hosts: activeHostPeers } = useActiveParticipantList();
  const { participants } = useLiveStreamContextData();
  const { refetchParticipants } = useLiveStreamContextActions();
  const onSuccessShared = () => {
    refetchCohosts && refetchCohosts();
    success(t("live_streams.room.cohost_removed_successfully"), {
      duration: "short",
      shouldUseProgress: false,
    });
  };
  const onErrorShared = () =>
    error(t("live_streams.room.could_not_remove_cohost_error"));
  const removeInactiveCohostMutation = useMutation(
    id => liveStreamApi.removeCohost({ id, roomId: room?.id }),
    {
      onSuccess: onSuccessShared,
      onError: onErrorShared,
    },
  );
  const findActivePeerIdByParticipantId = participantId => {
    if (!activeHostPeers) {
      return null;
    }
    const activeHost = activeHostPeers.find(
      hostPeer => Number(hostPeer.customerUserId) === participantId,
    );
    return activeHost?.id;
  };
  const removeActiveCohostMutation = useMutation(
    participantId =>
      liveStreamApi.changeRole({
        roomId: room?.id,
        participantId,
        role: PARTICIPANT_ROLES.SPECTATOR,
        forReactQuery: true,
      }),
    {
      onSuccess: participant => {
        const peerId = findActivePeerIdByParticipantId(participant?.id);
        requestRoleChange(peerId, PARTICIPANT_ROLES.HLS_SPECTATOR, true);
        refetchParticipants();
        onSuccessShared();
      },
      onError: onErrorShared,
    },
  );
  const findActiveParticipantIdByCommunityMemberId = communityMemberId => {
    if (!activeHostPeers) {
      return false;
    }
    const activeHost = activeHostPeers.find(
      hostPeer =>
        participants?.[hostPeer.customerUserId]?.community_member_id ===
        communityMemberId,
    );
    return activeHost?.customerUserId;
  };

  return communityMemberId => {
    const activeParticipantId =
      findActiveParticipantIdByCommunityMemberId(communityMemberId);
    if (activeParticipantId) {
      removeActiveCohostMutation.mutate(activeParticipantId);
    } else {
      removeInactiveCohostMutation.mutate(communityMemberId);
    }
  };
};
