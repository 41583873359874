import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { liveStreamApi } from "@circle-react/api";
import {
  PARTICIPANT_ROLES,
  ROOM_TYPES,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useProviderRoleChange } from "./provider";
import { useRoomType } from "./useRoomType";

const TARGET_ROLES = Object.freeze({
  [ROOM_TYPES.STREAM]: PARTICIPANT_ROLES.HLS_SPECTATOR,
  [ROOM_TYPES.CONFERENCE]: PARTICIPANT_ROLES.HOST,
});

const getTargetRole = roomType =>
  TARGET_ROLES[roomType] || PARTICIPANT_ROLES.HLS_SPECTATOR;

export const useRemoveParticipantAsModerator = (
  peerId,
  roomId,
  participantId,
) => {
  const { success, error } = useToast();
  const { requestRoleChange } = useProviderRoleChange();
  const { roomType } = useRoomType();
  const role = getTargetRole(roomType);
  const removeModeratorMutation = useMutation(
    () =>
      liveStreamApi.changeRole(
        {
          roomId,
          participantId,
          role,
        },
        {
          forReactQuery: true,
        },
      ),
    {
      onSuccess: () => {
        requestRoleChange(peerId, role, true);
        success(
          t("live_streams.room.participant_removed_as_moderator_message"),
          {
            duration: "short",
            shouldUseProgress: false,
          },
        );
      },
      onError: () =>
        error(t("live_streams.room.participant_removed_as_moderator_error")),
    },
  );

  return removeModeratorMutation.mutate;
};
