import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { spaceGroupApi } from "@/react/api";
import { useSpaceGroupResource } from "@circle-react/contexts";
import { spaceGroupPaths } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useEditSpaceGroupMutation = ({ spaceGroup, onSuccess }) => {
  const { success, error } = useToast();
  const history = useHistory();
  const { refetch } = useSpaceGroupResource(spaceGroup.id);

  const mutationFn = formData => {
    const { moderator_community_member_ids, ...spaceGroupValues } = formData;
    return spaceGroupApi.update(spaceGroup.id, {
      space_group: spaceGroupValues,
      moderator_community_member_ids,
    });
  };

  const mutation = useMutation(mutationFn, {
    onSuccess: response => {
      success(t("space_groups.form.edit.saved_successfully"));
      refetch();
      onSuccess();
      history.push(spaceGroupPaths.show({ spaceGroupSlug: response.slug }));
    },
    onError: () => error(t("space_groups.form.create.save_error")),
  });

  return {
    ...mutation,
    spaceGroup,
  };
};
