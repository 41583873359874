import { t } from "@/i18n-js/instance";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { PaywallResource } from "./shared/PaywallResource";
import { WhichPaywallSelector } from "./shared/WhichPaywallSelector";

export const memberUnsubscribed: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_unsubscribed_from_paywall.title",
  ),
  value: "community_member_unsubscribed_from_paywall",
  group: t("settings.workflows.form.event_groups.paywalls"),
  description: t(
    "settings.workflows.form.events.community_member_unsubscribed_from_paywall.description",
  ),
  resource: TRIGGER_RESOURCE_TYPE.PAYWALL,
  formComponent: WhichPaywallSelector,
  viewComponent: PaywallResource,
  icon: "paywall",
};
