import classnames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";
import "./style.scss";

export const PaymentProcessorStatusAlert = ({
  sentiment,
  title,
  badge,
  description,
}) => {
  const freeTrialInputClassName = classnames(
    "md:border md:border-primary md:rounded-lg md:p-8 bg-primary",
    {
      "flex flex-row": sentiment !== "default",
    },
  );

  return (
    <div
      className={freeTrialInputClassName}
      data-testid="payment-processor-status"
    >
      <div className="flex flex-col gap-y-2">
        {badge && <div>{badge}</div>}
        {title && (
          <Typography.TitleMd data-testid="title">{title}</Typography.TitleMd>
        )}
        {description && <div data-testid="description">{description}</div>}
      </div>
    </div>
  );
};
