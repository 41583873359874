import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceGroupOption } from "../../SpaceAccessManagementContext";
import { i18nRoot } from "../../constants";
import { Item } from "./Item";
import { ItemButton } from "./ItemButton";
import { ItemTitle } from "./ItemTitle";
import { RemovableItemTitleWrapper } from "./RemovableItemTitleWrapper";
import { useParentItem } from "./hooks/useParentItem";

interface ParentRemovableItemProp extends SpaceGroupOption {
  onRemoveParent: (action: () => void, object: SpaceGroupOption) => void;
}

export const ParentRemovableItem = ({
  onRemoveParent = action => {
    action();
  },
  ...spaceGroup
}: ParentRemovableItemProp) => {
  const { name } = spaceGroup;
  const {
    isChecked,
    isFromSearch,
    isAllSpacesSelected,
    spacesAlreadySelected,
    isRemovable,
    isNotRemovableTooltip,
    onRemoveClick,
  } = useParentItem({ spaceGroup, onRemoveParent });

  const shouldHide =
    (!isChecked && spacesAlreadySelected.length === 0) || !isFromSearch;

  const spacesCounterText =
    isChecked && isAllSpacesSelected
      ? t("space_access_management.list.spaces_counter.all_spaces")
      : t("space_access_management.list.spaces_counter", {
          count: spacesAlreadySelected.length,
        });

  return (
    <Item
      className={classnames({ hidden: shouldHide })}
      isDisabled={!isChecked || !isRemovable}
      onClick={isChecked && isRemovable ? onRemoveClick : undefined}
    >
      <RemovableItemTitleWrapper
        isRemovable={isRemovable}
        isNotRemovableTooltip={isNotRemovableTooltip}
      >
        <ItemTitle>
          <Typography.LabelSm weight="semibold">
            {name}
            <span className="font-normal">{spacesCounterText}</span>
          </Typography.LabelSm>
        </ItemTitle>
      </RemovableItemTitleWrapper>
      {isChecked && isRemovable && (
        <ItemButton actionText={t([i18nRoot, "remove"])} />
      )}
    </Item>
  );
};
