import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { accessGroupsApi } from "@circle-react/api/admin/accessGroups/accessGroupsApi";
import { accessGroupPaths } from "@circle-react/helpers/urlHelpers/accessGroupPaths";
import { queryClient } from "@circle-react/providers";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { useAccessGroupsStatusInfiniteQuery } from "../../AccessGroupsTabs/hooks/useAccessGroupsStatusInfiniteQuery";

interface UseNewAccessGroupProps {
  closeModal: () => Promise<unknown>;
  onSuccess?: (accessGroup: AccessGroupType) => void;
}

interface NewAccessGroupParams {
  name: string;
  description: string;
}

export const useNewAccessGroup = ({
  closeModal,
  onSuccess,
}: UseNewAccessGroupProps) => {
  const { accessGroupsInfiniteQuery } = useAccessGroupsStatusInfiniteQuery();
  const toastr = useToast();
  const { createAccessGroup } = accessGroupsApi();
  const history = useHistory();

  const { mutate: handleSubmit, isLoading } = useMutation({
    mutationFn: (data: NewAccessGroupParams) => createAccessGroup(data),
    onSuccess: (responseData: AccessGroupType) => {
      void queryClient.invalidateQueries(accessGroupsInfiniteQuery.queryKey);
      void closeModal();

      if (onSuccess) {
        onSuccess(responseData);
      } else {
        history.push({
          pathname: accessGroupPaths.edit({
            accessGroupId: responseData.id,
          }),
        });
      }
    },
    onError: (error: any) => toastr.error(error.message),
  });

  return {
    isSubmitting: isLoading,
    handleSubmit,
  };
};
