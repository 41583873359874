import { usePaywallBlockContext } from "../../../Providers/PaywallBlockProvider";
import { isDesktopPreviewMode } from "../../../Providers/helpers";
import { Block } from "./";

export const BlockContentResolver = (params: any) => {
  const { previewMode } = usePaywallBlockContext();
  const Component = isDesktopPreviewMode(previewMode)
    ? Block.Content
    : Block.MobileContent;

  return <Component {...params} />;
};
