import { useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { DripDelayPicker } from "@/react/components/Spaces/CourseSpace/Lessons/List/Content/Table/DripSchedule/DripDelayPicker";
import { useDripScheduleMutation } from "@/react/components/Spaces/CourseSpace/Lessons/List/Content/Table/DripSchedule/hooks/useDripScheduleMutation";
import {
  isScheduledCourseSpace,
  isStructuredCourseSpace,
} from "@/react/helpers/courseHelpers";
import { getCourseSectionsKey } from "@/react/hooks/courses/useCoursesApi";
import type { CoursesSection } from "@/react/types/CoursesSection";
import type { Space } from "@/react/types/Space";
import { useToast } from "@circle-react-uikit/ToastV2";
import { DrippedAtDateTimePicker } from "./DrippedAtDateTimePicker";

interface DripScheduleFormProps {
  space: Space;
  section: CoursesSection;
}

export interface FormValues {
  dripped_at?: string | null;
  drip_delay?: number;
  notify_students_enabled?: boolean;
}

export const DripScheduleForm = ({ space, section }: DripScheduleFormProps) => {
  const { success, error } = useToast();
  const queryClient = useQueryClient();
  const dripType = isScheduledCourseSpace(space)
    ? t("courses.edit.sections.drip_schedule.form.drip_date")
    : t("courses.edit.sections.drip_schedule.form.drip_delay");

  const { changeDripScheduleMutation } = useDripScheduleMutation({
    section,
    options: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          getCourseSectionsKey(section.space_id),
        );
        success(
          t("courses.edit.sections.drip_schedule.form.save_success", {
            drip_type: dripType,
          }),
          {
            duration: "short",
            shouldUseProgress: false,
          },
        );
      },
      onError: () => {
        error(
          t("courses.edit.sections.drip_schedule.form.save_error", {
            drip_type: dripType.toLowerCase(),
          }),
        );
      },
    },
  });

  const handleSubmit = (payload: FormValues) => {
    changeDripScheduleMutation.mutate({
      course_section: payload,
    });
  };
  return (
    <>
      {isScheduledCourseSpace(space) && (
        <DrippedAtDateTimePicker
          onChange={handleSubmit}
          section={section}
          disabled={changeDripScheduleMutation.isLoading}
        />
      )}
      {isStructuredCourseSpace(space) && (
        <DripDelayPicker
          onChange={handleSubmit}
          dripDelay={section.drip_delay ?? 0}
          disabled={changeDripScheduleMutation.isLoading}
        />
      )}
    </>
  );
};
