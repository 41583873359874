import { useMutation } from "react-query";
import { marketingHubSubscriptionApi } from "@/react/marketinghubSubscriptionApi";

export const useMarketingHubSubscriptionApi = (
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const {
    mutate: cancelMarketingHubSubscription,
    isLoading: isCancellingMarketingHubSubscription,
  } = useMutation(
    ({
      reason,
      reasonDescription,
    }: {
      reason: string;
      reasonDescription: string;
    }) => marketingHubSubscriptionApi.cancel(reason, reasonDescription),
    {
      onSuccess: () => {
        onSuccess?.();
      },
      onError: () => {
        onError?.();
      },
    },
  );

  return {
    cancelMarketingHubSubscription,
    isCancellingMarketingHubSubscription,
  };
};
