import type { ReactNode } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export interface CoverImageWrapperProps {
  children: ReactNode;
}

export const CoverImageWrapper = ({ children }: CoverImageWrapperProps) => (
  <div className={classNames("md:px-6 md:pb-3 md:pt-5 lg:px-9")}>
    {children}
  </div>
);
