import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { useFormAudienceCounter } from "../AudienceMatchCounter/useFormAudienceCounter";
import { ActionList } from "./ActionList";

const matchRoot = "settings.workflows.confirm_modal.members_match_right_now";

export const BulkActionList = () => {
  const { count, formattedCount } = useFormAudienceCounter();
  const localizedText = t(matchRoot, { count });

  const memberMatchText =
    count > 0 ? `${formattedCount} ${localizedText}` : localizedText;

  return (
    <>
      <div className="flex flex-row items-center gap-3">
        <Icon type="24-workflow-audience" size={24} className="!h-5 !w-5" />
        <Typography.BodySm>{memberMatchText}</Typography.BodySm>
      </div>
      <ActionList />
    </>
  );
};
