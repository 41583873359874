import { forwardRef } from "react";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface FilterButtonProps {
  isActive?: boolean;
  onClick: () => void;
  label: string;
  icon?: IconType;
  className?: string;
}

export const FilterButton = forwardRef<any, FilterButtonProps>(
  (
    { isActive = false, onClick, label, icon, className }: FilterButtonProps,
    ref,
  ) => (
    <button
      onClick={onClick}
      type="button"
      ref={ref}
      className={classNames(
        "bg-primary border-primary hover:bg-overlay-dark focus-visible:outline-secondary text-darkest max-w-[200px] shrink-0 truncate rounded-full border px-4 py-2 text-sm transition-colors duration-75 focus-visible:rounded-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        {
          "border-secondary": isActive,
        },
        className,
      )}
    >
      <Typography.LabelSm weight="medium" color="text-primary">
        {label}
      </Typography.LabelSm>
      {icon && (
        <Icon
          type={icon}
          size={16}
          color="text-primary"
          className="hover:bg-overlay-dark -mt-0.5 ml-0.5 h-4 w-4"
        />
      )}
    </button>
  ),
);

FilterButton.displayName = "FilterButton";
