import { isEmpty, noop } from "lodash";
import type { UseQueryResult } from "react-query";
import { t } from "@/i18n-js/instance";
import { Form } from "@/react/components/shared/uikit/Form";
import { useAddSpaceModal } from "@circle-react/components/Modals/AddSpaceModal";
import { EmptyState } from "@circle-react-uikit/EmptyState";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { TableList } from "../TableList";
import { generateColumns } from "../generateColumns";
import type { SpaceResponse } from "../queries";

export interface Space {
  id: number;
  title: string;
  used_in_replies: number;
}

export const Spaces = ({
  spacesQuery,
  selectedIds,
  handleCheckboxChange,
  handleSelectAllOnPage,
  allIdsOnPage,
  onChangePage,
}: {
  spacesQuery: UseQueryResult<SpaceResponse>;
  selectedIds: number[];
  handleCheckboxChange: (id: number) => void;
  handleSelectAllOnPage: (checked: boolean) => void;
  allIdsOnPage: number[];
  onChangePage: (page: number) => void;
}) => {
  const spacesCount = spacesQuery?.data?.count || 0;
  const hasNoResults = isEmpty(spacesQuery?.data?.records || []);
  const spaceModal = useAddSpaceModal();

  if (spacesQuery?.isLoading) {
    return <Loader />;
  }

  if (hasNoResults) {
    return (
      <div className="mt-[20vh]">
        <EmptyState
          header={t(
            "community_bot.bot_builder.knowledge_page.import_community_resources_empty_state.header",
          )}
          description={t(
            "community_bot.bot_builder.knowledge_page.import_community_resources_empty_state.description",
          )}
          primaryLabel={t(
            "community_bot.bot_builder.knowledge_page.import_community_resources_empty_state.primary_label",
          )}
          onPrimaryClick={() => spaceModal.show()}
        />
      </div>
    );
  }

  return (
    <Form name="import-spaces" onSubmit={noop}>
      {spacesCount > 0 && (
        <div className="px-5 pb-1 pt-5">
          <div className="flex min-h-[1.75rem] items-center justify-between gap-4">
            {selectedIds.length > 0 ? (
              <div className="flex items-center gap-4">
                <Typography.LabelMd weight="semibold">
                  {t(
                    "community_bot.bot_builder.knowledge_page.selected_count",
                    {
                      selected_count: selectedIds.length,
                      count: spacesCount,
                    },
                  )}
                </Typography.LabelMd>
              </div>
            ) : (
              <Typography.LabelMd weight="semibold">
                {t(
                  "community_bot.bot_builder.knowledge_page.community_resources_table.count",
                  {
                    count: spacesCount,
                  },
                )}
              </Typography.LabelMd>
            )}
          </div>
        </div>
      )}
      <div className="px-5">
        <TableList
          columns={generateColumns(
            selectedIds,
            handleCheckboxChange,
            handleSelectAllOnPage,
            allIdsOnPage,
            "spaces",
          )}
          query={spacesQuery}
          onChangePage={onChangePage}
        />
      </div>
    </Form>
  );
};
