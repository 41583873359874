import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface AccessGroupParamsType {
  access_groups: [];
  record_type: string;
  record_id: string;
}

export const accessGroupsSourcesApi = () => {
  const manageAccessGroupSources = ({
    params,
  }: {
    params: AccessGroupParamsType;
  }) => reactQueryPut(internalApi.admin.accessGroupSources.manage(), params);

  return {
    manageAccessGroupSources,
  };
};
