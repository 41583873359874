import { Checkbox } from "@/react/components/shared/uikit/Form/Checkbox";

export const CheckboxCell = ({
  row,
  handleCheckboxChange,
  selectedIds,
}: {
  row: any;
  handleCheckboxChange: (id: number) => void;
  selectedIds: number[];
}) => (
  <Checkbox
    checked={selectedIds.includes(row.original?.id)}
    onChange={() => handleCheckboxChange(row.original?.id)}
    name={`resource-${row.original?.id}`}
    isFormItem={false}
    onClick={e => e.stopPropagation()}
  />
);
