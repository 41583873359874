import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { accessGroupsApi } from "@circle-react/api/admin/accessGroups/accessGroupsApi";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

export const getAccessGroupQueryKey = (id: number) => [
  "access_groups",
  "show",
  id,
];

export const useGetAccessGroup = (id: number) => {
  const toastr = useToast();
  const { showAccessGroup } = accessGroupsApi();

  return useQuery<AccessGroupType>({
    queryKey: getAccessGroupQueryKey(id),
    queryFn: () => showAccessGroup(id),
    onError: () => toastr.error(t("error")),
  });
};
