import type { ComponentPropsWithoutRef } from "react";
import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useIsAppBarVisible } from "../../useIsAppBarVisible";

export const StandardLayoutHeaderContainer = ({
  children,
  className,
  ...rest
}: ComponentPropsWithoutRef<"div">) => {
  const isAppBarVisible = useIsAppBarVisible();

  return (
    <header
      id="standard-layout-header"
      aria-label={t("page_header")}
      className={classNames(
        "z-20 sm:relative sm:top-0 lg:sticky",
        isAppBarVisible ? "top-16" : "top-0",
        className,
      )}
      {...rest}
    >
      {children}
    </header>
  );
};
