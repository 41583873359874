import { t } from "@/i18n-js/instance";
import { viewOnlyMasqueradeAs } from "@circle-react/api/masqueradingApi";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useAccessGroupData } from "../AccessGroupMembers/DataStoreContext/AccessGroupDataContext";
import { useArchiveAccessGroupModal } from "../ArchiveAccessGroupModal";
import { useRenameAccessGroupModal } from "../RenameAccessGroupModal";

const i18nBase = "settings.access_groups.admin.form.actions";

export const AccessGroupActions = () => {
  const { accessGroup } = useAccessGroupData();
  const renameAccessGroupModal = useRenameAccessGroupModal();
  const handleRename = () => renameAccessGroupModal.show({ accessGroup });

  const archiveAccessGroupModal = useArchiveAccessGroupModal();
  const handleArchive = () => archiveAccessGroupModal.show({ accessGroup });

  const masqueradeAsAccessGroup = () =>
    viewOnlyMasqueradeAs({
      entityId: accessGroup.id,
      entityType: "AccessGroup",
    })
      .then(data => window.open(data.masquerade_url, "_self"))
      .catch(error => console.error(error));

  return (
    <Dropdown
      button={
        <Button type="button" variant="tertiary" className="flex !pr-4" large>
          {t([i18nBase, "title"])}
          <Icon type="caret-down-bold" size={16} className="ml-1" />
        </Button>
      }
      menuButtonEl="div"
    >
      <Dropdown.ItemWithLink linkType="button" onClick={handleRename}>
        <Dropdown.IconContainer>
          <Typography.LabelSm weight="regular" color="text-very-dark">
            {t([i18nBase, "rename"])}
          </Typography.LabelSm>
        </Dropdown.IconContainer>
      </Dropdown.ItemWithLink>

      <Dropdown.ItemWithLink linkType="button" onClick={handleArchive}>
        <Dropdown.IconContainer>
          <Typography.LabelSm weight="regular" color="text-very-dark">
            {t([i18nBase, "archive"])}
          </Typography.LabelSm>
        </Dropdown.IconContainer>
      </Dropdown.ItemWithLink>

      <Dropdown.ItemWithLink onClick={masqueradeAsAccessGroup}>
        <Dropdown.IconContainer>
          <Typography.LabelSm weight="regular" color="text-very-dark">
            {t([i18nBase, "view_as"])}
          </Typography.LabelSm>
        </Dropdown.IconContainer>
      </Dropdown.ItemWithLink>
    </Dropdown>
  );
};
