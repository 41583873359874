import {
  canCreatePostInSpace,
  canManageSpace,
  isBasicPostsSpace,
} from "@/react/helpers/spaceHelpers";
import { isFeedViewSpace, isListViewSpace } from "@/react/helpers/spaceHelpers";
import { useHasSpaceRightSidebar } from "@circle-react/components/Spaces/hooks/useHasSpaceRightSidebar";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";

export const useSpaceQuickPostBox = () => {
  const { data: space } = useCurrentSpaceContext();
  const hasSpaceRightSidebar = useHasSpaceRightSidebar();
  const isIframeEmbed = useIsIframeEmbed();

  const isSpacePostSpace = isBasicPostsSpace(space);
  const canMemberManageSpace = canManageSpace(space);
  const canCreatePost = canCreatePostInSpace(space);
  const isListView = isListViewSpace(space);
  const isFeedView = isFeedViewSpace(space);

  const canMemberCreatePosts =
    isSpacePostSpace && (canCreatePost || canMemberManageSpace);

  const spaceSupportsQuickPostBox =
    isFeedView || (isListView && hasSpaceRightSidebar);

  const shouldDisplayQuickPostBox =
    canMemberCreatePosts && spaceSupportsQuickPostBox && !isIframeEmbed;

  return {
    shouldDisplayQuickPostBox,
  };
};
