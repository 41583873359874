import { t } from "@/i18n-js/instance";
import { RSVP_STATUS } from "@circle-react/components/Events/helpers/constants";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import type { Event } from "@circle-react/types/Event";
import { isEventFinished } from "./helpers";

interface Props {
  event: Event;
  isEventDetailsPage?: boolean;
}

export const RsvpWarningMessages = ({
  event,
  isEventDetailsPage = false,
}: Props) => {
  const {
    event_setting_attributes: eventData,
    rsvped_event: isRsvpedEvent,
    rsvp_status: rsvpStatus,
  } = event;

  const {
    rsvp_limit_reached: isRsvpLimitReached,
    show_rsvp_limit_warning: shouldShowRsvpWarning,
  } = eventData || {};

  const classNames = isEventDetailsPage ? "text-center pt-2" : "text-left pt-2";
  const isAttendeeInvited = rsvpStatus == RSVP_STATUS.INVITED;

  if (isRsvpedEvent || isAttendeeInvited) {
    return null;
  }
  return (
    <>
      {isRsvpLimitReached && (
        <Typography.LabelXs color="text-gray-800" weight="semibold">
          <div className={classNames}>
            {t("events.rsvp.rsvp_limit_reached")}
          </div>
        </Typography.LabelXs>
      )}
      {shouldShowRsvpWarning && !isEventFinished(event) && (
        <Typography.LabelXs weight="semibold">
          <div className={classNames} style={{ color: "#F5A607" }}>
            {t("events.rsvp.rsvp_limit_warning")}
          </div>
        </Typography.LabelXs>
      )}
    </>
  );
};
