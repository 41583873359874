import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { Drawer } from "@circle-react-uikit/Drawer";
import { MemberDirectorySettingsForm } from "./MemberDirectorySettingsForm";

interface SettingsProps {
  open: boolean;
  onClose: () => void;
}

const localeScope = "members_directory.header.settings";

export const Settings = ({ open, onClose }: SettingsProps) => {
  const { isLoading, currentCommunitySettings } = usePunditUserContext();

  if (!currentCommunitySettings || isLoading) {
    return null;
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t(`${localeScope}.title`)}
      noWrapperPadding
    >
      <MemberDirectorySettingsForm
        currentCommunitySettings={currentCommunitySettings}
        onClose={onClose}
      />
    </Drawer>
  );
};
