import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";

export const DiscoverButton = () => {
  const location = useLocation();
  const isActive = location.pathname === "/discover";

  return (
    <TippyV2
      content={<div className="space-x-2 p-1">{t("discover.tooltip")}</div>}
      placement="right"
      arrow={false}
      offset={[0, 12]}
      interactive={false}
    >
      <Link
        data-testid="community-switcher-link"
        className={classNames(
          "switcher__icon hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover !m-0 flex items-center justify-center rounded-xl border-2 border-transparent transition-colors duration-150",
          {
            active: isActive,
            "border-secondary": isActive,
          },
        )}
        to="/discover"
      >
        <div>
          <div
            className="brand-icon brand-icon__initial !h-8 !w-8 !rounded-lg !outline-none"
            style={{
              color: "#FFF",
              backgroundColor: "#863CFF",
            }}
          >
            <Icon type="20-discover" size={20} />
          </div>
        </div>
      </Link>
    </TippyV2>
  );
};
