import type { ReactNode } from "react";
import classNames from "classnames";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";

export interface SpaceGroupViewWrapperProps {
  listId?: string;
  isCollapsed?: boolean;
  children?: ReactNode;
  onCollapseToggle?: () => void;
  isDragging?: boolean;
}

export const SpaceGroupViewWrapper = ({
  listId = "",
  isCollapsed = false,
  children,
  onCollapseToggle,
  isDragging,
}: SpaceGroupViewWrapperProps) => {
  const { currentCommunityMember } = usePunditUserContext();

  return (
    <button
      type="button"
      className={classNames(
        "text-c-sidebar hover:text-c-sidebar hover:bg-c-sidebar-hover focus-visible:text-c-sidebar focus-visible:bg-c-sidebar-hover focus-visible:outline-secondary mb-1 flex w-full select-none items-center justify-between rounded-lg px-4 py-1.5 text-sm font-semibold transition-colors duration-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        {
          "cursor-grab":
            !isDragging && isCommunityAdmin(currentCommunityMember),
          "cursor-grabbing": isDragging,
        },
      )}
      data-testid="space-group"
      onClick={onCollapseToggle}
      aria-expanded={!isCollapsed}
      aria-controls={listId}
    >
      {children}
    </button>
  );
};
