import { useAccessGroup } from "@circle-react/hooks/accessRegistry/useAccessGroup";

export const AccessGroupName = ({ id }: { id: number }) => {
  const { accessGroup, isLoadingAccessGroup } = useAccessGroup(id);

  if (isLoadingAccessGroup) {
    return (
      <span className="bg-tertiary block h-4 w-full animate-pulse rounded" />
    );
  }
  return <span>{accessGroup?.name}</span>;
};
