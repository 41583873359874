import { t } from "@/i18n-js/instance";
import { memberDirectoryPath } from "@circle-react/helpers/routerPathHelpers";
import type { NavigationLink } from "@circle-react-uikit/HeaderV3/NavigationBar/hooks/useNavItemsToLinks";
import { ExternalLink } from "./ExternalLink";
import { PlatformMenuItemLink } from "./PlatformMenuItemLink";

interface PlatformMenuItemRendererProps {
  link: NavigationLink;
  disabled: boolean;
  isHomePageEnabled: boolean;
  shouldShowActiveHomeLink: boolean;
  shouldShowMemberDirectory: boolean;
  shouldShowAllEventsPage: boolean;
  shouldShowCoursesDirectory: boolean;
  shouldShowLeaderboard: boolean;
}

export const PlatformMenuItemRenderer = ({
  link,
  disabled,
  isHomePageEnabled,
  shouldShowActiveHomeLink,
  shouldShowMemberDirectory,
  shouldShowAllEventsPage,
  shouldShowCoursesDirectory,
  shouldShowLeaderboard,
}: PlatformMenuItemRendererProps) => {
  if (link.kind === "home") {
    return (
      <PlatformMenuItemLink
        label={t("feed")}
        linkTo="/feed"
        iconName="home-v2"
        disabled={disabled}
        isActive={shouldShowActiveHomeLink}
        show={isHomePageEnabled}
      />
    );
  }

  if (link.kind === "members") {
    return (
      <PlatformMenuItemLink
        label={link.label || t("members_directory.menu.title")}
        linkTo={memberDirectoryPath()}
        iconName="members-v2"
        disabled={disabled}
        show={shouldShowMemberDirectory}
      />
    );
  }

  if (link.kind === "events") {
    return (
      <PlatformMenuItemLink
        label={link.label || t("events.title")}
        linkTo="/events"
        iconName="16-calendar"
        disabled={disabled}
        show={shouldShowAllEventsPage}
      />
    );
  }

  if (link.kind === "courses") {
    return (
      <PlatformMenuItemLink
        label={link.label || t("courses.directory.title")}
        linkTo="/courses"
        iconName="16-book"
        disabled={disabled}
        isGrayBadge
        show={shouldShowCoursesDirectory}
      />
    );
  }

  if (link.kind === "leaderboard") {
    return (
      <PlatformMenuItemLink
        label={link.label || t("leaderboard.title")}
        linkTo="/leaderboard?period=7_days"
        iconName="16-analytics"
        disabled={disabled}
        isGrayBadge
        show={shouldShowLeaderboard}
      />
    );
  }

  if (link.kind === "custom") {
    return (
      <ExternalLink
        disabled={disabled}
        iconName="16-site-link"
        label={link.label}
        linkTo={link.linkTo}
        show={!link.isDisabled}
      />
    );
  }

  return null;
};
