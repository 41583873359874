import { t } from "@/i18n-js/instance";
import type { CurrentPlan } from "@circle-react/types";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useUpcomingInvoiceApi } from "../hooks/useUpcomingInvoiceApi";
import { InvoiceDetails } from "./InvoiceDetails";
import { PaymentProcessorBillingPortal } from "./PaymentProcessorBillingPortal";

interface UpcomingInvoiceProps {
  showBillingPortal?: boolean;
  currentPlan: CurrentPlan;
}

export const UpcomingInvoice = ({
  showBillingPortal,
  currentPlan,
}: UpcomingInvoiceProps) => {
  const { upcomingInvoice, isUpcomingInvoiceLoading } = useUpcomingInvoiceApi();

  if (isUpcomingInvoiceLoading) {
    return <Loader center />;
  }

  const {
    community_plan_invoice: communityPlanInvoice,
    secondary_subscription_invoice: secondarySubscriptionInvoice,
    shared_invoice: sharedInvoice,
  } = upcomingInvoice;

  return (
    <div className="bg-primary border-primary my-6 rounded-lg border px-8 py-7">
      <div className="mb-6 flex justify-between">
        <Typography.TitleSm weight="bold">
          {t("settings.billing.billing_cycle.title")}
        </Typography.TitleSm>
      </div>

      <InvoiceDetails
        invoice={communityPlanInvoice}
        title={t("settings.billing.preview_invoice.community_plan_title", {
          community_plan: `Circle ${currentPlan.display_name}`,
        })}
      />

      {!sharedInvoice && (
        <>
          <br />
          <InvoiceDetails
            invoice={secondarySubscriptionInvoice}
            title={t("settings.billing.preview_invoice.marketing_plan_title")}
          />
        </>
      )}

      {showBillingPortal && (
        <PaymentProcessorBillingPortal
          cancellingMetadata={currentPlan.cancelling_metadata}
        />
      )}
    </div>
  );
};
