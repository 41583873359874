import { t } from "@/i18n-js/instance";
import { useSearchResultsModal } from "@/react/components/Modals/SearchResultsModal";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useKeyboardNavigation } from "@circle-react-shared/uikit/TipTapBlockEditor/AiCopilotBlock/hooks/useKeyboardNavigation";
import { Typography } from "@circle-react-shared/uikit/Typography";
import type { RecentSearch } from "../useRecentSearches";
import { RecentSearchItem } from "./RecentSearchItem";

interface RecentSearchesProps {
  recentSearches: RecentSearch[];
  removeRecentSearch: (searchValue: string) => void;
}

export const RecentSearches = ({
  recentSearches,
  removeRecentSearch,
}: RecentSearchesProps) => {
  const { show: showSearchResultModal } = useSearchResultsModal();
  const canHover = useAnyHoverMediaQuery();

  const handleClick = (searchValue: any) => {
    void showSearchResultModal({ searchValue }).catch(e => console.error(e));
  };

  const firstFiveRecentSearches = recentSearches.slice(0, 5);

  const { activeIndex, setActiveIndex } = useKeyboardNavigation({
    optionsCount: firstFiveRecentSearches.length + 1,
    onEnter: (index: any) => {
      const query = index === 0 ? "" : recentSearches[index - 1].query;
      handleClick(query);
    },
  });

  return (
    <div className="p-2">
      <ul className="list-none" data-testid="recent-search-list">
        <li className="ml-4">
          <Typography.LabelXxs weight="semibold" color="text-default">
            {t("search_v2.recent_searches.title")}
          </Typography.LabelXxs>
        </li>
        {firstFiveRecentSearches.map((searchTerm, index) => (
          <RecentSearchItem
            searchTerm={searchTerm}
            index={index}
            activeIndex={activeIndex}
            handleClick={handleClick}
            removeRecentSearch={removeRecentSearch}
            canHover={canHover}
            setActiveIndex={setActiveIndex}
            key={`${searchTerm.type}-${searchTerm.query}`}
            dataTestId="recent-search-item"
          />
        ))}
      </ul>
    </div>
  );
};
