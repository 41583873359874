import { fetchRequest, reactQueryGet } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const show_DEPRECATED = (processorId: string) =>
  fetchRequest(
    internalApi.communityMemberCharge.show({
      processorId,
    }),
    {
      method: "GET",
    },
  );

const show = (processorId: string) =>
  reactQueryGet(
    internalApi.communityMemberCharge.show({
      processorId,
    }),
  );

export const communityMemberChargeApi = {
  show,
  show_DEPRECATED,
};
