import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useCurrentCommunityMember } from "@circle-react/contexts";
import { Form } from "@circle-react-uikit/Form";
import { useCurrentAction } from "../../../ActionRule";
import { useWorkflow } from "../../../WorkflowForm/useWorkflow";
import { MessageBodyInput } from "./MessageBodyInput";

const useDefaultValueForField = (fieldPath, defaultValue) => {
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    const currentValue = getValues(fieldPath);

    if (!currentValue) {
      setValue(fieldPath, defaultValue);
    }
  }, [fieldPath, setValue, getValues, defaultValue]);
};

export const FormSendDM = () => {
  const { paramsPath } = useCurrentAction();
  const { register } = useFormContext();
  const { isBulkAction } = useWorkflow();
  const { id: currentCommunityMemberId } = useCurrentCommunityMember();
  const toValue = isBulkAction ? undefined : "initiator";

  useDefaultValueForField(`${paramsPath}.from`, currentCommunityMemberId);
  useDefaultValueForField(`${paramsPath}.to`, toValue);

  return (
    <div className="flex flex-col gap-9">
      <Form.Item
        name={`${paramsPath}.from`}
        hideBorder
        fullWidth
        hideDescription
        label={t("settings.workflows.form.from")}
        labelClassName="!mb-2 !text-sm"
        className="!py-0"
      >
        <Form.CommunityMemberSelect
          defaultValue={currentCommunityMemberId}
          name={`${paramsPath}.from`}
          filters={{
            role: "admin_or_moderator",
            messaging_enabled: true,
          }}
        />
      </Form.Item>

      <div className="hidden">
        <input type="hidden" {...register(`${paramsPath}.to`)} />
      </div>

      <MessageBodyInput />
    </div>
  );
};
