import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Input } from "./Input";
import { Label } from "./Label";

export const TitleInput = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { setFocus } = useFormContext();

  useEffect(() => {
    if (isEditing) {
      setFocus("name");
    }
  }, [isEditing, setFocus]);

  return (
    <>
      <Label isEditing={isEditing} onClick={() => setIsEditing(true)} />
      <Input
        isEditing={isEditing}
        onBlur={() => setIsEditing(false)}
        maxLength={50}
      />
    </>
  );
};
