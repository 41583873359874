import { reactQueryPost } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { AccessGroupMemberFilterType } from "@circle-react/types/AccessGroup";

interface AccessGroupMemberParamsType {
  community_member_ids: number[];
  process_type: string;
  filters?: AccessGroupMemberFilterType;
}

export const accessGroupMembersApi = () => {
  const manageAccessGroupMembers = ({
    id,
    params,
  }: {
    id: number;
    params: AccessGroupMemberParamsType;
  }) => reactQueryPost(internalApi.admin.accessGroupMembers.manage(id), params);

  return {
    manageAccessGroupMembers,
  };
};
