import { t } from "@/i18n-js/instance";
import { BlockListItem } from "../BlockListItem";

export const LessonsItem = () => (
  <BlockListItem
    icon="16-text"
    label={t("courses.lockscreen.lessons")}
    noEdit
  />
);
