import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface PlanFeatureProps {
  count: string;
  remainingValue?: null | number;
}

export const PlanFeature = ({ count, remainingValue }: PlanFeatureProps) => (
  <div className="flex gap-3 pb-2">
    <Icon type="check" size={16} />
    <Typography.LabelSm color="text-dark">{count}</Typography.LabelSm>
    {typeof remainingValue == "number" && (
      <Typography.LabelSm color="text-light">
        {t("pricing_plans.features_with_values.remaining_resources", {
          count: remainingValue,
        })}
      </Typography.LabelSm>
    )}
  </div>
);
