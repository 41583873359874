import { useEffect } from "react";
import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { notificationsPath } from "@circle-react/helpers/urlHelpers";
import { detectBreakpoints } from "@circle-react/hooks/detectBreakpoints";
import { useDocumentTitle } from "@circle-react/hooks/notifications/useDocumentTitle";
import { useNotificationWebSocket } from "@circle-react/hooks/notifications/useNotificationWebSocket";
import { useNotificationsApi } from "@circle-react/hooks/notifications/useNotificationsApi";
import { useWindowResize } from "@circle-react/hooks/utils/useWindowResize";
import {
  DropdownMenu_DEPRECATED,
  useDropdown,
} from "@circle-react-shared/DropdownMenu";
import { Icon } from "@circle-react-shared/Icon";
import { NUMBER_OF_NOTIFICATION_IN_MENU } from "../../../config/Settings";
import { NotificationsList } from "../NotificationsList";

export const NotificationsDropdown = ({
  containerElement,
  communityMemberId,
  newNotificationCount: newNotificationCountFromProps,
}) => {
  const { open, handleClose, setOpen } = useDropdown(false);

  const { isMobileView } = detectBreakpoints();
  const {
    newNotificationCount,
    setNewNotificationCount,
    resetNewNotificationsCount,
    onNotificationEventReceive,
    resizeNotificationWindow,
  } = useNotificationsApi(NUMBER_OF_NOTIFICATION_IN_MENU);

  const { prependTitle, resetTitleToOriginal } = useDocumentTitle();

  useEffect(() => {
    setNewNotificationCount(newNotificationCountFromProps);
  }, [newNotificationCountFromProps]);

  useNotificationWebSocket(communityMemberId, onNotificationEventReceive);

  useWindowResize(resizeNotificationWindow);

  useEffect(() => {
    if (newNotificationCount) {
      document.title = prependTitle(`(${newNotificationCount}) `);
    } else {
      document.title = resetTitleToOriginal();
    }
  }, [newNotificationCount]);

  const onMenuClickHandler = () => {
    const isOpen = dropdownState();
    if (isMobileView() && isOpen) {
      return;
    }
    isOpen ? handleClose() : setOpen(true);
    resetNewNotificationsCount();
  };

  const dropdownState = () => {
    if (isMobileView()) {
      //Fix inconsistent open state of dropdown based on classes:
      //Hidden class for dropdown gets added from stimulus controller so dropdown is hidden on UI
      //But open state for dropdown in react remains set to true
      const hiddenNotificationsDropdown = document.getElementsByClassName(
        "dropdown-menu--bottom-bar bottomBarnotificationDropdown hidden",
      );
      if (hiddenNotificationsDropdown.length > 0 && open) {
        handleClose();
        hiddenNotificationsDropdown[0].classList.remove("hidden");
        return false;
      }
    }
    return open;
  };

  return (
    <div className="notifications__menu">
      <button
        type="button"
        className="notifications__menu--icon"
        data-testid="notifications-menu"
        onClick={onMenuClickHandler}
        data-action="click->bottom-bar#openNotifications"
        data-target="bottom-bar.notifications"
      >
        <Icon type="bell" />
        {newNotificationCount ? <span>{newNotificationCount}</span> : ""}
      </button>
      <DropdownMenu_DEPRECATED
        open={open}
        handleClose={handleClose}
        disableToggle={isMobileView() && open}
        className={containerElement + "notificationDropdown"}
      >
        <div
          id={containerElement + "NotificationWrapperOuter"}
          className="dropdown-menu dropdown-menu--right dropdown-menu__notifications"
        >
          <div className="dropdown-menu__inside">
            <NotificationsList
              key={`notification-${open}`}
              loadPagination={false}
              containerElement={containerElement}
            />
            <div className="notifications__footer">
              <span>
                <a href={notificationsPath()}>
                  <div>{t("view_all_notifications")}</div>
                </a>
              </span>
            </div>
          </div>
        </div>
      </DropdownMenu_DEPRECATED>
    </div>
  );
};

NotificationsDropdown.propTypes = {
  containerElement: PropTypes.string.isRequired,
  communityMemberId: PropTypes.number.isRequired,
  newNotificationCount: PropTypes.number.isRequired,
};
