import classnames from "classnames";
import { Switch } from "@circle-react-uikit/Switch";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceOption } from "../../SpaceAccessManagementContext";
import { Item } from "./Item";
import { ItemTitleWithIcon } from "./ItemTitleWithIcon";
import { RemovableItemTitleWrapper } from "./RemovableItemTitleWrapper";
import { useChildItem } from "./hooks/useChildItem";

interface SpaceOptionWithSpaceGroupId {
  spaceGroupId: number;
  space: SpaceOption;
  onAddChild?: (action: () => void, object: SpaceOption) => void;
  onRemoveChild?: (action: () => void, object: SpaceOption) => void;
}

export const ChildMobileItem = ({
  spaceGroupId,
  space,
  onAddChild = action => {
    action();
  },
  onRemoveChild = action => {
    action();
  },
}: SpaceOptionWithSpaceGroupId) => {
  const { name, icon, tooltipText } = space;
  const {
    onRemoveClick,
    onAddClick,
    isChecked,
    isSpaceFound,
    isRemovable,
    isNotRemovableTooltip,
  } = useChildItem({
    space,
    spaceGroupId,
    onAddChild,
    onRemoveChild,
  });

  const onClick = isChecked
    ? isRemovable
      ? onRemoveClick
      : undefined
    : onAddClick;
  const isDisabled = isChecked && !isRemovable;

  return (
    <Item
      className={classnames({ hidden: !isSpaceFound })}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      <RemovableItemTitleWrapper
        isRemovable={isRemovable}
        isNotRemovableTooltip={isNotRemovableTooltip}
      >
        <ItemTitleWithIcon icon={icon} tooltipText={tooltipText}>
          <Typography.LabelSm>{name}</Typography.LabelSm>
        </ItemTitleWithIcon>
      </RemovableItemTitleWrapper>
      <Switch as="span" checked={isChecked} />
    </Item>
  );
};
