import type { Agent } from "@/react/types/CommunityBot/ChatSort";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface AgentDropdownProps {
  className?: string;
  activeItem: Agent;
  onItemClick: (item: Agent) => void;
  items: Agent[];
}

export const AgentDropdown = ({
  className,
  activeItem,
  onItemClick,
  items,
}: AgentDropdownProps) => (
  <Dropdown
    direction="bottom-start"
    className={className}
    buttonClassName="w-full"
    menuItemsClassName="!w-40"
    button={
      <Dropdown.MenuButton className="hover:bg-secondary-dark border-primary flex justify-between rounded-md border bg-transparent !py-1.5 pl-3 pr-2 transition-colors">
        <div className="flex gap-x-2">
          <Typography.LabelSm weight="medium">
            {activeItem.name}
          </Typography.LabelSm>
        </div>
        <Icon
          size={16}
          type="16-chevron-triangle-down-small"
          className="text-dark"
        />
      </Dropdown.MenuButton>
    }
  >
    {items.map((item: Agent) => {
      const { name, id } = item;
      return (
        <Dropdown.Item key={id} onClick={() => onItemClick(item)}>
          <Dropdown.ItemLink
            type="button"
            active={activeItem.id === id}
            aria-label={name}
          >
            <div className="flex items-center gap-x-2">
              <span>{name}</span>
            </div>
          </Dropdown.ItemLink>
        </Dropdown.Item>
      );
    })}
  </Dropdown>
);
