import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { accessGroupsApi } from "@circle-react/api/admin/accessGroups/accessGroupsApi";

export const DEFAULT_PAGE_SIZE = 10;

interface UseAccessGroupsInfiniteQueryProps {
  query?: any;
  filters?: object;
  perPage?: number;
  initialPage?: number;
  options?: object;
}

export const useAccessGroupsInfiniteQuery = (
  args: UseAccessGroupsInfiniteQueryProps = {},
) => {
  const {
    query = "",
    filters = {},
    perPage = DEFAULT_PAGE_SIZE,
    initialPage = 1,
    options = {},
  } = args;
  const { listAccessGroups } = accessGroupsApi();

  const fetchPage = ({ pageParam = initialPage }) => {
    const params = {
      page: Number(pageParam) > 0 ? Number(pageParam) : 1,
      ...filters,
      per_page: perPage,
      query: query,
    };

    return listAccessGroups({ params });
  };

  const queryKey = ["access_groups_list", query, filters, perPage];

  const queryOptions = {
    getNextPageParam: (lastPage: any) =>
      lastPage.has_next_page ? parseInt(lastPage.page) + 1 : undefined,
    getPreviousPageParam: (firstPage: any) =>
      firstPage.page > 1 ? parseInt(firstPage.page) - 1 : undefined,
    keepPreviousData: true,
    ...options,
  };

  const result = useInfiniteQuery(queryKey, fetchPage, queryOptions);
  const pages = useMemo(() => result.data?.pages || [], [result.data?.pages]);
  const totalAccessGroups = pages[0]?.meta?.total_count || 0;
  const totalQueryAccessGroups = pages[0]?.count || 0;
  const pageSize = pages[0]?.per_page || perPage;

  return {
    ...result,
    queryKey,
    totalAccessGroups,
    totalQueryAccessGroups,
    pageSize,
    pages,
  };
};
