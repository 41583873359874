import { createContext, useContext, useState } from "react";
import type { PropsWithChildren } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

export const PREVIEW_MODE = Object.freeze({
  DESKTOP: "desktop",
  MOBILE: "mobile",
});

export type PREVIEW_MODE_KEYS = keyof typeof PREVIEW_MODE;
export type PreviewMode = (typeof PREVIEW_MODE)[PREVIEW_MODE_KEYS];

interface FormBlocksContextProp {
  blocks: any[];
  isEditingBlock: boolean;
  blockBeingEdited: string | null;
  getBlockIndex: (blockId: string) => number;
  removeBlockBeingEdited: () => void;
  setBlockBeingEdited: (param: any) => void;
  toggleBlockHidden: (blockId: string) => void;
  getBlockDataPath: (blockId: string) => void;
  replaceBlocks: (newBlocks: any) => void;
  previewMode: PreviewMode;
  setPreviewMode: (previewMode: PreviewMode) => void;
}

interface FormBlocksProviderProp {
  name: string;
}

const FormBlocksContext = createContext<FormBlocksContextProp | null>(null);
FormBlocksContext.displayName = "FormBlocksContext";

export const useFormBlocks = () => {
  const context = useContext(FormBlocksContext);

  if (!context) {
    throw new Error("useFormBlocks must be used within a FormBlocksProvider");
  }

  return context;
};

export const FormBlocksProvider = ({
  children,
  name = "blocks",
}: PropsWithChildren<FormBlocksProviderProp>) => {
  const [blockBeingEdited, setBlockBeingEdited] = useState(null);
  const { watch } = useFormContext();
  const { fields, update, replace } = useFieldArray({ name });
  const [previewMode, setPreviewMode] = useState<PreviewMode>(
    PREVIEW_MODE.DESKTOP,
  );

  // "fields" is not updated on every onChange event by default, the following
  // code gets the latest value of "fields" from the form state, see
  // "Controlled Field Array" on https://www.react-hook-form.com/api/usefieldarray
  const watchFieldArray = watch(name);
  const blocks = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));

  const isEditingBlock = Boolean(blockBeingEdited);
  const removeBlockBeingEdited = () => {
    setPreviewMode("desktop");
    setBlockBeingEdited(null);
  };

  const toggleBlockHidden = (blockId = "") => {
    if (!blockId) return;

    const block = blocks.find(block => block.id === blockId);
    if (!block) return;

    const index = blocks.indexOf(block);
    if (index === -1) return;

    const updatedBlock = { ...block, hidden: !block.hidden };
    update(index, updatedBlock);
  };

  const getBlockIndex = (blockId = "") => {
    if (!blockId) return -1;

    const block = blocks.find(block => block.id === blockId);
    if (!block) return -1;

    return blocks.indexOf(block);
  };

  const getBlockDataPath = (blockId = "") => {
    const index = getBlockIndex(blockId);
    if (index === -1) return "";
    return `${name}.${index}.data`;
  };

  const replaceBlocks = (newBlocks: any) => replace(newBlocks);

  return (
    <FormBlocksContext.Provider
      value={{
        blocks,
        isEditingBlock,
        blockBeingEdited,
        getBlockIndex,
        removeBlockBeingEdited,
        setBlockBeingEdited,
        toggleBlockHidden,
        getBlockDataPath,
        replaceBlocks,
        previewMode,
        setPreviewMode,
      }}
    >
      {children}
    </FormBlocksContext.Provider>
  );
};
