import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { useSupportWidgetContext } from "../SupportWidgetContext";
import { SEND_MESSAGE_FORM_DEFAULT_VALUES } from "../constants";
import { getLocalStorage, setLocalStorage } from "../utils";

export const DraftMessageButton = () => {
  const { isDraftConversationPresent, setIsDraftConversationPresent } =
    useSupportWidgetContext();
  const history = useHistory();
  const draftMessage = getLocalStorage().formValues?.text;
  const draftIndicatorTitle =
    t("support_widget.draft_message_button.title") +
    " " +
    (draftMessage
      ? draftMessage
      : t("support_widget.draft_message_button.default_title"));

  const handleClickCloseButton = event => {
    setLocalStorage({
      ...getLocalStorage(),
      formValues: SEND_MESSAGE_FORM_DEFAULT_VALUES,
    });
    setIsDraftConversationPresent(false);
    event.stopPropagation();
  };

  if (!isDraftConversationPresent) {
    return null;
  }

  return (
    <button
      type="button"
      className="text-dark hover:bg-tertiary hover:text-dark focus:bg-secondary focus:text-dark cursor-pointer px-6 py-2"
      onClick={() => {
        history.replace("/form");
      }}
    >
      <div className="flex justify-between">
        <div className="mr-5 flex min-w-0 flex-col text-left">
          <Typography.LabelSm truncate weight="medium">
            {draftIndicatorTitle}
          </Typography.LabelSm>
          <div>
            <Typography.BodyXs className="text-xs" color="text-gray-400">
              {t("support_widget.draft_message_button.subtitle")}
            </Typography.BodyXs>
          </div>
        </div>
        <div className="items-center self-center">
          <span className="text-dark px-4">
            <Icon
              type="16-trash-bin"
              useWithFillCurrentColor
              onClick={handleClickCloseButton}
              size={20}
            />
          </span>
        </div>
      </div>
    </button>
  );
};
