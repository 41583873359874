import { useState } from "react";
import { useUpdateWorkflowForm } from "@circle-workflows/hooks/useUpdateWorkflow";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";

export const useWorkflow = () => {
  const queryClient = useQueryClient();

  const {
    mutate: updateWorkflow,
    mutateAsync: updateWorkflowAsync,
    isLoading: isSavingWorkflow,
    isError: isWorkflowError,
  } = useUpdateWorkflowForm();

  const { watch, setValue, formState } = useFormContext();
  const id = watch("id");
  const name = watch("name");
  const schedule = watch("schedule");
  const [previousName, setPreviousName] = useState(name);
  const hasName = Boolean(name);
  const placeholderName = t("settings.workflows.form.untitled_workflow");
  const status = watch("status");

  const saveWorkflowName = async newName => {
    if (newName === previousName) {
      return;
    }
    if (newName.trim() === "") {
      setValue("name", previousName);
      return;
    }
    await updateWorkflowAsync({ name: newName });
    queryClient.invalidateQueries({ queryKey: ["workflows", id] });
    setPreviousName(newName);
  };

  const { defaultValues } = formState;
  const type = defaultValues?.workflow_type;
  const isDynamic = type === "dynamic";
  const isBulkAction = type === "bulk_action";
  const isScheduled = type === "scheduled";
  const hasRuns = defaultValues?.runs_count > 0;

  return {
    id,
    hasRuns,
    name,
    type,
    placeholderName,
    hasName,
    status,
    saveWorkflowName,
    updateWorkflow,
    updateWorkflowAsync,
    isBulkAction,
    isDynamic,
    isScheduled,
    schedule,
    isSavingWorkflow,
    isWorkflowError,
  };
};
