import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { Alert } from "@circle-react-shared/uikit/AlertV2";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { Typography } from "@circle-react-uikit/Typography";
import { PlanDetails } from "../PlanDetails/PlanDetails";
import { AccountManager } from "./AccountManager";

export const PlusPlan = ({ isExpired }: { isExpired?: boolean }) => {
  const {
    // @ts-expect-error remove when PlusPlan is typed
    currentCommunity: { csm },
  } = usePunditUserContext();

  const shouldShowBillingDetails = !isExpired;

  return (
    <div className="bg-primary h-full pb-10">
      <div
        className="bg-secondary px-5 py-20 xl:px-40"
        style={{ backgroundColor: "#191B1F" }}
      >
        <BadgeV2
          label={t("settings.billing.your_membership")}
          inverted
          square
        />
        <div>
          <Typography.TitleXxl color="text-white">
            {t("settings.billing.plans.plus")}
          </Typography.TitleXxl>
        </div>
      </div>

      {isExpired && (
        <div className="mt-20 px-5 xl:flex xl:px-40">
          <Alert
            className="!w-full"
            title={t(
              "settings.billing.plans.errors.canceled_plus_subscription",
            )}
            type="warning"
          />
        </div>
      )}

      <div className="mt-20 px-5 xl:flex xl:px-40">
        <div className="grow pr-6">
          <PlanDetails
            showBillingDetails={shouldShowBillingDetails}
            forceFromSubscription={false}
          />
        </div>
        {csm && (
          <div>
            <AccountManager
              name={csm.name}
              email={csm.email}
              image={csm.profile_image}
            />
          </div>
        )}
      </div>
    </div>
  );
};
