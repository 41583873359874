import { useQuery } from "react-query";
import type { AccessGroupSourceType } from "@/react/types";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface ListAccessGroupSourcesQueryProps {
  recordType: string;
  recordId?: number;
}

export const listAccessGroupSourceQueryKey = "access-group-sources-by-record";
export const useListAccessGroupSourcesQuery = ({
  recordType,
  recordId,
}: ListAccessGroupSourcesQueryProps) => {
  const { data: accessGroupSources, isLoading: isLoadingAccessGroupSources } =
    useQuery<AccessGroupSourceType[], Error>(
      [listAccessGroupSourceQueryKey, recordType, recordId],
      () =>
        reactQueryGet(
          internalApi.admin.accessGroupSources.index({
            record_type: recordType,
            record_id: recordId,
          }),
        ),
    );

  return {
    accessGroupSources,
    isLoadingAccessGroupSources,
  };
};
