import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { FormTipTapBlockEditor } from "@circle-react-uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import { Typography } from "@circle-react-uikit/Typography";

export const DescriptionEdit = ({ blockDataPath }) => {
  const { watch, setValue } = useFormContext();

  const sgidToObjectMapPath = `${blockDataPath}.sgids_to_object_map`;
  const sgidToObjectMap = watch(sgidToObjectMapPath) || {};

  const handleMapChange = localSgidToObjectMap => {
    const newMap = { ...sgidToObjectMap, ...localSgidToObjectMap };
    setValue(sgidToObjectMapPath, newMap);
  };

  const inlineAttachmentsPath = `${blockDataPath}.inline_attachments`;
  const inlineAttachments = watch(inlineAttachmentsPath) || [];
  const handleLocalAttachmentsChange = newAttachments =>
    setValue(inlineAttachmentsPath, [...inlineAttachments, ...newAttachments]);

  return (
    <>
      <div className="mb-7 flex flex-col gap-2">
        <Typography.LabelLg weight="semibold">
          {t("courses.lockscreen.block_name.description")}
        </Typography.LabelLg>
        <Typography.LabelSm>
          {t("courses.lockscreen.placeholder.slash_commands")}
        </Typography.LabelSm>
      </div>
      <div className="border-primary h-96 resize-y overflow-y-auto rounded-lg border px-4 py-3">
        <FormTipTapBlockEditor
          name={`${blockDataPath}.description_tiptap`}
          sgidToObjectMap={sgidToObjectMap}
          inlineAttachments={inlineAttachments}
          className="h-full"
          editorClassName="h-full"
          onLocalSgidToObjectMapChange={handleMapChange}
          onLocalAttachmentsChange={handleLocalAttachmentsChange}
          disabledExtensions={["mention", "poll", "ai_copilot"]}
        />
      </div>
    </>
  );
};

DescriptionEdit.propTypes = {
  blockDataPath: PropTypes.string.isRequired,
};
