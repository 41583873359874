import type { ReactNode } from "react";
import { t } from "@/i18n-js/instance";

export interface RightProps {
  children: ReactNode;
  hidden?: boolean;
}

export const Right = ({ children, hidden = false }: RightProps) => {
  if (hidden) {
    return null;
  }

  return (
    <aside
      aria-label={t("right_sidebar")}
      className="sidebar sidebar--right"
      data-testid="sidebar-right"
    >
      <div className="sidebar__content" data-draggable="false">
        {children}
      </div>
    </aside>
  );
};
