import { t } from "@/i18n-js/instance";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { Icon } from "@circle-react/components/shared/Icon";

export const FloatingActionButton = ({ isOpen, onClick }) => (
  <BlockInViewOnlyMode>
    <button
      type="submit"
      className="bg-support-widget float-right hidden h-14 w-14 self-end rounded-full text-white shadow-md lg:block"
      onClick={onClick}
      aria-label={t("support_widget.floating_button_aria_label")}
      aria-expanded={isOpen}
      aria-controls="support-widget"
    >
      <Icon
        type={isOpen ? "20-chevron-down" : "chat"}
        size={isOpen ? 20 : null}
      />
    </button>
  </BlockInViewOnlyMode>
);
