import { t } from "@/i18n-js/instance";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import { CommunityResource } from "../Community/shared/CommunityResource";
import { HiddenCommunityInputs } from "../Community/shared/HiddenCommunityInputs";
import type { EventOption } from "../types";

export const emailChanged: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_email_changed.title",
  ),
  value: "community_member_email_changed",
  group: t("settings.workflows.form.event_groups.member"),
  description: t(
    "settings.workflows.form.events.community_member_email_changed.description",
  ),
  formComponent: HiddenCommunityInputs,
  resource: TRIGGER_RESOURCE_TYPE.COMMUNITY,
  viewComponent: CommunityResource,
  icon: "email",
  hasNoVisibleFormFields: true,
};
