import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Badge } from "@circle-react-uikit/Badge";

export const PaywallStatusBadge = ({ paywall }) => (
  <Badge variant={paywall.status === "active" ? "success" : "default"}>
    {t(`settings.paywalls.status.${paywall.status}`)}
  </Badge>
);

PaywallStatusBadge.propTypes = {
  paywall: PropTypes.object.isRequired,
};
