import { t } from "@/i18n-js/instance";
import { StatusBadge } from "@circle-react-shared/uikit/StatusBadge";
import type { PricingTabPriceListCellProps } from "../types";

const localeRoot =
  "settings.paywalls.modal.tabs.pricing.mobile_price_packages.status";

interface BadgeProperty {
  locale: Array<string>;
  variant: "default" | "success" | "danger";
}

interface BadgeProperties {
  pending: BadgeProperty;
  active: BadgeProperty;
  denied: BadgeProperty;
  unknown: BadgeProperty;
}

export const StatusColumn = ({
  row: { original: pricePackage },
}: PricingTabPriceListCellProps) => {
  const { status } = pricePackage;

  const badgeProperties: BadgeProperties = {
    pending: {
      locale: [localeRoot, "pending"],
      variant: "default",
    },
    active: {
      locale: [localeRoot, "active"],
      variant: "success",
    },
    denied: {
      locale: [localeRoot, "denied"],
      variant: "danger",
    },
    unknown: {
      locale: [localeRoot, "unknown"],
      variant: "default",
    },
  };

  const selectedProperty =
    badgeProperties[status] ?? badgeProperties["unknown"];

  return (
    <div className="flex flex-row items-center gap-2">
      <StatusBadge variant={selectedProperty.variant}>
        {t(selectedProperty.locale)}
      </StatusBadge>
    </div>
  );
};
