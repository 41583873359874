import { t } from "@/i18n-js/instance";
import { LabelMd } from "@circle-react-shared/uikit/Typography/components/Label/LabelMd";
import { EventItem } from "../../EventItem";
import { EventsDateDivider } from "../../EventsDateDivider";
import { SkeletonLoader } from "../../SkeletonLoader";
import { useListEvents } from "../../queries";

export const EventsListView = () => {
  const { isLoading, data } = useListEvents();

  if (isLoading) {
    return (
      <>
        <SkeletonLoader rows={1} height="52px" wrapperClassName="!px-0 mb-4" />
        <SkeletonLoader
          rows={1}
          height="20px"
          wrapperClassName="px-2 mb-4"
          width="25%"
        />
        <SkeletonLoader rows={4} height="70px" wrapperClassName="px-2 mb-4" />
        <SkeletonLoader
          rows={1}
          height="20px"
          wrapperClassName="px-2 mb-4"
          width="25%"
        />
        <SkeletonLoader rows={1} height="70px" wrapperClassName="px-2 mb-4" />
      </>
    );
  }

  const events = data?.records || [];

  return (
    <div className="flex flex-col">
      <div className="border-primary bg-primary sticky top-0 z-10 flex h-[52px] shrink-0 items-center justify-center border-b px-3">
        <LabelMd weight="semibold">{t("support_widget.events")}</LabelMd>
      </div>
      {events.map((event, index) => (
        <>
          <EventsDateDivider
            current={event.starts_at}
            previous={events[index - 1]?.starts_at}
          />
          <EventItem
            key={event.id}
            event={event}
            last={index === events.length - 1}
          />
        </>
      ))}
    </div>
  );
};
