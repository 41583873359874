import { useCallback, useMemo } from "react";
import type { Participant } from "@/react/types/Live/Participant";
import type { Peer } from "@/react/types/Live/Peer";
import { useLiveStreamContextData } from "@circle-react/contexts/LiveStreams";
import { useProviderPeersList } from "./provider";

// Note: This hook returns "peers" as it's the simplest
// way to get active participants.

const sortAlphabetically =
  (participants: Record<number, Participant>) =>
  (participantA: Peer, participantB: Peer) => {
    const customerUserIdA =
      participantA?.customerUserId && parseInt(participantA.customerUserId);
    const customerUserIdB =
      participantB?.customerUserId && parseInt(participantB.customerUserId);
    const nameA = customerUserIdA
      ? (participants?.[customerUserIdA]?.name ?? "")
      : "";
    const nameB = customerUserIdB
      ? (participants?.[customerUserIdB]?.name ?? "")
      : "";
    if (nameA > nameB) {
      return 1;
    }
    if (nameA === nameB) {
      return 0;
    }
    return -1;
  };

export const useActiveParticipantList = () => {
  const { hosts, spectators, moderators } = useProviderPeersList();
  const { room, participants } = useLiveStreamContextData();

  const sortByRole = useCallback(
    (hostA, hostB) => {
      if (
        participants?.[hostA.customerUserId]?.community_member_id ===
        room?.creator?.id
      ) {
        return -1;
      }
      if (
        participants?.[hostB.customerUserId]?.community_member_id ===
        room?.creator?.id
      ) {
        return 1;
      }
      return 0;
    },
    [participants, room?.creator?.id],
  );

  const orderedHosts = useMemo(
    () =>
      // Need to return room creator / main host first
      (hosts &&
        [...hosts].sort(sortAlphabetically(participants)).sort(sortByRole)) ||
      [],
    [hosts, sortByRole, participants],
  );

  const orderedModerators = useMemo(
    () =>
      (moderators && [...moderators].sort(sortAlphabetically(participants))) ||
      [],
    [moderators, participants],
  );

  const orderedSpectators = useMemo(
    () => spectators?.sort(sortAlphabetically(participants)) || [],
    [spectators, participants],
  );

  const peers = [...orderedHosts, ...orderedModerators, ...orderedSpectators];
  const host = orderedHosts.find((currentHost: Peer) => {
    const customerUserId =
      currentHost.customerUserId && parseInt(currentHost.customerUserId);
    return (
      customerUserId &&
      participants?.[customerUserId]?.community_member_id == room?.creator?.id
    );
  });
  const cohosts = host
    ? orderedHosts.filter(
        (currentHost: Peer) =>
          currentHost.customerUserId !== host.customerUserId,
      )
    : orderedHosts;

  return {
    peers,
    hosts: orderedHosts,
    cohosts,
    moderators: host ? [host, ...orderedModerators] : orderedModerators,
    spectators: orderedSpectators,
    count: peers?.length || 0,
  };
};
