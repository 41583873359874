import { useMemo } from "react";
import { t } from "@/i18n-js/instance";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Tab } from "@circle-react-uikit/TabV2";
import { Typography } from "@circle-react-uikit/Typography";
import type { WebhookTest } from "../../generated/WorkflowsApi.types";
import { ExpandableCode } from "../HistoryWorkflowDetailModal/ExpandableCode";
import { Result } from "../Table/Result";

interface WebhookTestResultDetailProps {
  testResult: WebhookTest;
  onClose: () => void;
}

export const WebhookTestResultDetail = ({
  testResult,
  onClose,
}: WebhookTestResultDetailProps) => {
  const safeJsonStringify = (value: object) => {
    try {
      return JSON.stringify(value, null, 2);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return "";
    }
  };

  const resultDetailsJson = useMemo(() => {
    const request = safeJsonStringify(testResult.request_body);
    const response = safeJsonStringify(testResult.response_body);

    return { request, response };
  }, [testResult]);

  const date = formattedDateTime({
    dateTime: new Date(testResult.date_time),
    format: "short_date_at_short_time",
  });
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {t(
            "settings.workflows.action_messages.send_to_webhook.webhook_test_result",
          )}
        </Modal.Title>
        <Modal.HeaderActions>
          <Modal.CloseButton onClick={onClose} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <div className="border-dark rounded-lg border">
            <Tab.Group>
              <div className="bg-secondary border-dark flex flex-col gap-3.5 rounded-t-lg border-b px-6 pt-5">
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-2 md:flex-row">
                    <Result
                      result={testResult?.success ? "success" : "failed"}
                      isBadge={false}
                    />
                    <Typography.LabelSm color="text-default" as="time">
                      {date}
                    </Typography.LabelSm>
                  </div>
                </div>
                <Tab.List>
                  <Tab>
                    {t(
                      "settings.workflows.action_messages.send_to_webhook.webhook_url",
                    )}
                  </Tab>
                  <Tab>
                    {t(
                      "settings.workflows.action_messages.send_to_webhook.request_body",
                    )}
                  </Tab>
                  <Tab>
                    {t(
                      "settings.workflows.action_messages.send_to_webhook.response_body",
                    )}
                  </Tab>
                </Tab.List>
              </div>
              <Tab.Panels>
                <Tab.Panel className="px-6 py-5">{testResult.url}</Tab.Panel>
                <Tab.Panel className="p-2">
                  <ExpandableCode code={resultDetailsJson.request} />
                </Tab.Panel>
                <Tab.Panel className="p-2">
                  <ExpandableCode code={resultDetailsJson.response} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
        <div className="mt-5" />
      </Modal.Body>
    </>
  );
};
