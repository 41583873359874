import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import type { PaymentProcessor } from "@/react/types/CurrentCommunity";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { PAYWALL_LIST_QUERY_KEY } from "../constants";
import { usePaymentProcessorStatus } from "./usePaymentProcessorStatus";

export const usePaywallsAdmin = ({
  paymentProcessor,
}: {
  paymentProcessor: PaymentProcessor | undefined;
}) => {
  const fetchPaywalls = async () => reactQueryGet(internalApi.paywalls.index());
  const {
    data: paywalls,
    refetch: refetchPaywalls,
    isLoading: isPaywallsLoading,
  } = useQuery(PAYWALL_LIST_QUERY_KEY, fetchPaywalls);

  const { paymentProcessorStatus } = usePaymentProcessorStatus({
    paymentProcessor,
  });

  return {
    paywalls,
    refetchPaywalls,
    isPaywallsLoading,
    paymentProcessorStatus,
  };
};
