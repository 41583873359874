import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { nextPlanWithFeatureApi } from "@/react/api/nextPlanWithFeatureApi";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { Typography } from "@circle-react-uikit/Typography";
import { usePauseAiAction } from "../hooks/usePauseAiAction";

export const UpgradeToEnterpriseButton = () => {
  const { isResumeAiLoading } = usePauseAiAction();
  const upgradePlanModal = useUpgradePlanModal();
  const { currentCommunity, isLoading } = usePunditUserContext();
  const history = useHistory();
  const handleUpgradePlan = async () => {
    if (!currentCommunity) return;

    const nextPlanData = await nextPlanWithFeatureApi.getNextPlanWithFeature(
      currentCommunity.current_plan.id.toString(),
      { plan_feature_flag: "ai_agents" },
    );

    const planTier = nextPlanData?.plan_tier || "enterprise";

    await upgradePlanModal.show({
      planTier,
      source: UPGRADE_FLOW_SOURCES.AI_AGENTS_ENABLE_MODAL,
      subheading: t("pricing_plans.upgrade_modal.subheading", {
        feature_name: t("settings.community_ai.ai_agents_feature_name"),
      }),
      successButtonLabel: t(
        "pricing_plans.upgrade_modal.actions.go_to_community_ai",
      ),
      showPlanDetails: true,
      onSuccess: () => {
        history.push("/settings/ai-agents/agents");
      },
    });
  };

  return (
    <button
      type="button"
      onClick={handleUpgradePlan}
      disabled={isLoading}
      className="h-[34px] rounded-full border bg-[#15803D] px-4 transition-all duration-300 hover:bg-[#15803D]/90"
    >
      <div className="flex items-center space-x-1">
        <Icon type="16-ai-agent" size={16} className="text-white" />
        <Typography.LabelSm weight="bold" color="text-white">
          {t(
            isResumeAiLoading
              ? "community_bot.ai_resuming"
              : "community_bot.resume_ai",
          )}
        </Typography.LabelSm>
      </div>
    </button>
  );
};
