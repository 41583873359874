import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { AccessGroupContentParamsType } from "@circle-react/types/AccessGroup";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Typography } from "@circle-react-uikit/Typography";
import { useUpdateAccessGroupContent } from "./hooks/useUpdateAccessGroupContent";

const i18nBase =
  "settings.access_groups.admin.modal.access.update_confirmation";

interface ConfirmationComponentProps {
  contentData: AccessGroupContentParamsType;
  accessGroupId: number;
}

const ConfirmationComponent = ({
  contentData,
  accessGroupId,
}: ConfirmationComponentProps) => {
  const modal = useModal();
  const onClose = () => {
    void modal.hide();
    modal.remove();
  };
  const { handleSubmit, isSubmitting } = useUpdateAccessGroupContent({
    accessGroupId,
    closeModal: onClose,
  });

  const onConfirm = () => {
    handleSubmit(contentData);
  };

  return (
    <ConfirmationModal
      isOpen={modal.visible}
      title={t([i18nBase, "title"])}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmVariant="circle"
      confirmText={t([i18nBase, "on_confirm"])}
      disabled={isSubmitting}
    >
      <Typography.BodyMd>{t([i18nBase, "description"])}</Typography.BodyMd>
    </ConfirmationModal>
  );
};

const confirmationModal = ModalManager.create(
  ({ contentData, accessGroupId }: ConfirmationComponentProps) => (
    <ConfirmationComponent
      contentData={contentData}
      accessGroupId={accessGroupId}
    />
  ),
);

export const useAccessGroupContentUpdateConfirmationModal = () =>
  useModal(confirmationModal);
