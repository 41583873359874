import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { liveStreamApi } from "@circle-react/api/liveStreamApi";
import { usePunditUserContext } from "@circle-react/contexts";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useChatTranscript = (room, refetch) => {
  const { error, success } = useToast();
  const { currentUser } = usePunditUserContext();
  const chatTranscriptMutation = useMutation(
    () => liveStreamApi.chatTranscript({ id: room?.id }),
    {
      onSuccess: () => {
        success(
          t("live_streams.room.chat_transcript_success", {
            name: room?.name,
            email: currentUser?.email,
          }),
        );
        refetch();
      },
      onError: () => error(t("live_streams.room.chat_transcript_error")),
    },
  );

  return {
    chatTranscript: chatTranscriptMutation.mutate,
  };
};
