import { t } from "@/i18n-js/instance";
import { AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOptionWithApiParams } from "../../types";
import { FormPaywallTrial } from "./FormPaywallTrial";
import { HistoryDetailViewTrialPaywall } from "./HistoryDetailViewTrialPaywall";
import type { HistoryDetailViewTrialPaywallProps } from "./HistoryDetailViewTrialPaywall/HistoryDetailViewTrialPaywall";
import { ViewPaywallTrial } from "./ViewPaywallTrial";

export interface SubscribeToTrialApiParams {
  paywall_id: number;
  paywall_price_id: number;
}

export const subscribeToTrial: Readonly<
  ActionOptionWithApiParams<
    HistoryDetailViewTrialPaywallProps,
    SubscribeToTrialApiParams
  >
> = {
  label: t(
    "settings.workflows.form.actions.subscribe_community_member_to_paywall_trial",
  ),
  value: "subscribe_community_member_to_paywall_trial",
  group: t("settings.workflows.form.action_groups.paywall"),
  leftAddon: <Icon type="16-paywall" size={16} className="!h-4 !w-4" />,
  formComponent: FormPaywallTrial,
  viewComponent: ViewPaywallTrial,
  historyDetailViewComponent: HistoryDetailViewTrialPaywall,
  isApplicableToMembersOnly: true,
  availablePlanTier:
    AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS.HIGHER_TIER_PLAN_ONLY,
} as const;
