import type { ReactNode } from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { OptionItem } from "./OptionItem";
import "./styles.scss";

export interface RadioGroupBoxProps {
  name: string;
  className?: string;
  options: {
    value: string;
    label: string;
    disabled?: boolean;
    rightIcon?: ReactNode;
    description?: string;
  }[];
  onChange?: (value: string) => void;
  fullWidth?: boolean;
}

export const RadioGroupBox = ({
  name,
  className,
  options,
  onChange,
  fullWidth = false,
}: RadioGroupBoxProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: controllerOnChange, value } }) => (
        <div>
          <RadioGroup
            value={value}
            onChange={value => {
              onChange?.(value);
              controllerOnChange(value);
            }}
            className={classNames(
              "bot-builder-behavior-option-group",
              className,
              {
                "bot-builder-behavior-option-group--full-width": fullWidth,
              },
            )}
          >
            {options.map(option => (
              <RadioGroup.Option
                key={option.value}
                value={option.value}
                className={classNames({
                  "pointer-events-none": option.disabled,
                })}
              >
                {({ checked }) => (
                  <OptionItem
                    checked={checked}
                    option={option}
                    rightIcon={option.rightIcon}
                  />
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
      )}
    />
  );
};
