import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useUpdateEventMutation } from "@circle-react/components/Events/EditForm/useUpdateEventMutation";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { staticAssetUrl } from "@circle-react/config/Settings";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import { useThemeContext } from "@circle-react/providers";
import { Button } from "@circle-react-uikit/Button";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { useToast } from "@circle-react-uikit/ToastV2";

interface Props {
  event: any;
}

export const ThumbnailImage = ({ event }: Props) => {
  const {
    cover_image_url: coverImageUrl,
    cover_image: coverImage,
    cover_image_variants,
  } = event;
  const { thumbnail: coverImgThumbnail } = cover_image_variants || {};
  const { isDarkAppearance } = useThemeContext();
  const { error } = useToast();
  const { updateMutation } = useUpdateEventMutation(event);
  const { canUpdatePost } = useCurrentPostHelpers();

  const coverImageFallbackUrl = isDarkAppearance
    ? staticAssetUrl("dark-mode-cover-thumbnail-2x.png")
    : staticAssetUrl("light-mode-cover-thumbnail-2x.png");

  const onAddCoverImage = async (formValues: any) => {
    try {
      await updateMutation.mutateAsync(formValues);
    } catch (e) {
      error(t("something_went_wrong"));
    }
  };

  const imgSrcUrl = coverImageUrl || coverImageFallbackUrl;
  const imgUrl = coverImgThumbnail || imgSrcUrl;
  const postHref = postsPath({
    spaceSlug: event.space_slug,
    slug: event.slug,
  });

  return (
    <Link
      id="event-thumbnail-image"
      className="group relative mb-2 mr-6 hidden h-full w-full shrink-0 md:mb-0 md:block md:h-[73px] md:w-[200px]"
      to={postHref}
    >
      <img
        loading="lazy"
        alt=""
        src={imgUrl}
        className="border-primary relative h-full max-h-[73px] w-full rounded-lg border object-cover"
      />
      <Form
        id="event-thumbnail-image"
        onSubmit={onAddCoverImage}
        defaultValues={{ cover_image: coverImage }}
      >
        {({ triggerFormSubmit }) => (
          <CoverImageInput
            name="cover_image"
            showAddCoverBtn={false}
            onImageAttachSuccess={triggerFormSubmit}
            customAspectRatioHelpText={t(
              "image_upload.recommended_size_event_cover",
            )}
            renderer={({ processing, handleChangeCoverClick }) => {
              if (processing || updateMutation.isLoading) {
                return (
                  <div className="bg-primary absolute bottom-0 left-0 flex h-full w-[200px] items-center justify-center rounded-md border">
                    <Loader center />
                  </div>
                );
              }
              if (canUpdatePost) {
                return (
                  <Button
                    variant="secondary"
                    className="absolute bottom-4 left-4 hidden group-hover:block"
                    onClick={handleChangeCoverClick}
                    disabled={processing}
                  >
                    {t(coverImgThumbnail ? "update" : "add_cover")}
                  </Button>
                );
              }
              // eslint-disable-next-line react/jsx-no-useless-fragment -- Hard to refactor out
              return <></>;
            }}
          />
        )}
      </Form>
    </Link>
  );
};
