import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { useTemplates } from "./useTemplates";

export interface WorkflowFormData {
  templates: any[];
  isLoadingTemplates: boolean;
  selectedTemplateId?: string;
}

const WorkflowFormData = createContext<WorkflowFormData>({
  templates: [],
  isLoadingTemplates: false,
  selectedTemplateId: undefined,
});
WorkflowFormData.displayName = "WorkflowFormData";

export interface CreateWorkflowProviderProps {
  children: ReactNode;
  category?: string;
}

export const CreateWorkflowProvider = ({
  children,
  category,
}: CreateWorkflowProviderProps) => {
  const { templates, isLoading: isLoadingTemplates } = useTemplates(category);

  return (
    <WorkflowFormData.Provider
      value={{
        templates,
        isLoadingTemplates,
      }}
    >
      {children}
    </WorkflowFormData.Provider>
  );
};

export const useCreateWorkflowData = () => useContext(WorkflowFormData);
