import classNames from "classnames";
import { isFunction } from "lodash";
import { useFormContext } from "react-hook-form";

interface InputProps {
  isEditing: boolean;
  transformValue?: (value: any) => any;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  [key: string]: any;
}

export const Input = ({
  isEditing,
  transformValue,
  onBlur,
  maxLength,
}: InputProps) => {
  const { register } = useFormContext();

  const {
    ref: formRef,
    onChange: formOnChange,
    onBlur: formOnBlur,
  } = register("name");

  return (
    <input
      autoComplete="off"
      type="text"
      data-1p-ignore
      ref={formRef}
      name="name"
      spellCheck="false"
      onChange={event => {
        if (isFunction(transformValue)) {
          event.target.value = transformValue(event.target.value);
        }
        void formOnChange(event);
      }}
      onBlur={event => {
        void formOnBlur(event);
        onBlur?.(event);
      }}
      maxLength={maxLength}
      className={classNames(
        "placeholder:text-light w-full min-w-[6rem] max-w-full border-0 bg-transparent p-0 text-xl font-semibold placeholder:opacity-100 focus:outline-none focus:ring-0",
        {
          hidden: !isEditing,
        },
      )}
    />
  );
};
