import { useCallback } from "react";
import { omit } from "lodash";
import { getIsValueEmpty } from "@circle-react-shared/MemberFilters/getIsValueEmpty";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";

export const useMemberFilterUrl = (
  filters: Array<BaseFilter>,
  setFilters: (filters: Array<BaseFilter>) => void,
) => {
  const updateFilters = useCallback(
    (id: string, newFilterParam: BaseFilter) => {
      const getFilter = () => {
        if (getIsValueEmpty(newFilterParam)) {
          return filters.filter(filterParam => id !== filterParam.id);
        }
        const hasIdAlready = filters.some(filterParam => id === filterParam.id);
        const newFilterParamForUrl = omit(newFilterParam, ["isInitialOpen"]);
        if (hasIdAlready) {
          return filters.map(filterParam => {
            if (filterParam.id === id) {
              return {
                ...filterParam,
                ...newFilterParamForUrl,
              };
            }
            return filterParam;
          });
        }
        return [...filters, newFilterParamForUrl];
      };

      setFilters(getFilter());
    },
    [filters, setFilters],
  );

  return {
    updateFilters,
  };
};
