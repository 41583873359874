import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { LegacyTrixEditorInput } from "./LegacyTrixEditorInput";

export const LegacyLockScreenEdit = ({ blockDataPath }) => (
  <>
    <Typography.LabelLg weight="semibold">
      {t("courses.lockscreen.block_name.legacy_lock_screen")}
    </Typography.LabelLg>
    <div className="mb-7" />
    <div>
      <Form.Item
        name={`${blockDataPath}.locked_page_heading`}
        hideBorder
        hideDescription
        label={t("courses.lockscreen.fields.heading")}
      >
        <Form.Input />
      </Form.Item>
      <LegacyTrixEditorInput blockDataPath={blockDataPath} />
      <Form.Item
        name={`${blockDataPath}.locked_button_label`}
        hideBorder
        hideDescription
        label={t("courses.lockscreen.fields.button_label")}
      >
        <Form.Input />
      </Form.Item>
      <Form.Item
        name={`${blockDataPath}.locked_button_url`}
        hideBorder
        hideDescription
        label={t("courses.lockscreen.fields.button_link")}
      >
        <Form.Input />
      </Form.Item>
    </div>
  </>
);

LegacyLockScreenEdit.propTypes = {
  blockDataPath: PropTypes.string.isRequired,
};
