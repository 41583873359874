import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { ThreeDotsDropdownV3 } from "@circle-react-shared/ThreeDotsDropdownV3";
import type { DropdownOptions } from "@circle-react-shared/uikit/Dropdown";

export const LeaderboardSettingsDropdown = () => {
  const history = useHistory();
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  if (!isAdmin) {
    return null;
  }

  const dropdownOptions: DropdownOptions = [
    {
      label: t("leaderboard.settings"),
      onClick: () => history.push("/settings/gamification"),
      value: "settings",
    },
  ];

  return (
    <ThreeDotsDropdownV3
      label={t("leaderboard.settings")}
      options={dropdownOptions}
      dataTestId="leaderboard-settings"
    />
  );
};
