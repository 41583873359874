import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { TiptapEditor } from "@circle-react-uikit/TipTap";
import { Typography } from "@circle-react-uikit/Typography";
import { BaseView } from "../../BaseView";

const i18nRoot = "settings.workflows.view";

export const ViewSendPushNotification = ({ action }) => {
  const { api_params: apiParams } = action || {};
  const { path, message: richTextBody } = apiParams || {};
  const { currentCommunity } = usePunditUserContext();
  const { root_url: rootUrl } = currentCommunity;

  if (!richTextBody) return null;

  return (
    <BaseView>
      {path && (
        <BaseView.Section>
          <BaseView.Title>{t([i18nRoot, "path"])}</BaseView.Title>
          <div>
            <Typography.LabelSm>{`https://${rootUrl}/${path}`}</Typography.LabelSm>
          </div>
        </BaseView.Section>
      )}
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "message_body"])}</BaseView.Title>
        <div className="text-default text-sm">
          <TiptapEditor
            key={uniqueId("tiptap_")}
            rich_text_body={richTextBody}
            editable={false}
          />
        </div>
      </BaseView.Section>
    </BaseView>
  );
};

ViewSendPushNotification.propTypes = {
  action: PropTypes.object,
  hasSeparator: PropTypes.bool,
};
