import type { ReactNode } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { FormEmailContent } from "./Actions/Member/sendEmail/FormEmailContent";

export interface GetSendEmailPathProps {
  path: string;
  broadcastId?: string | number;
}

export const getSendEmailPath = ({
  path,
  broadcastId = ":broadcastId",
}: GetSendEmailPathProps) => `${path}/send-email/${broadcastId}`;

export interface BroadcastContentEditorRouteWrapperProps {
  children: ReactNode;
}

export const BroadcastContentEditorRouteWrapper = ({
  children,
}: BroadcastContentEditorRouteWrapperProps) => {
  const { path } = useRouteMatch();

  return (
    <>
      {children}
      <Route path={getSendEmailPath({ path })}>
        <FormEmailContent />
      </Route>
    </>
  );
};
