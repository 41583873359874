import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const SEOComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "SEOComponent" */ "@circle-react/components/SettingsApp/Site/SEO"
    ),
);

export const SEO = () => (
  <Suspense fallback={<PageLoader />}>
    <SEOComponent />
  </Suspense>
);
