import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { AddLinkForm } from "@circle-react/components/Spaces/SidebarV2/CommunityMenu/Links/AddLinkForm";
import { Modal } from "@circle-react-uikit/ModalV2";

export interface AddLinkModalProps {
  isEdit?: boolean;
  link?: any;
}

export const AddLinkModalComponent = ({
  isEdit = false,
  link,
}: AddLinkModalProps) => {
  const drawer = useModal();
  return (
    <Modal title={t("add_link")} isOpen={drawer.visible} onClose={drawer.hide}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title className="text-center" size="md">
            {t("add_link")}
          </Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={drawer.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <AddLinkForm isEdit={isEdit} link={link} />
      </Modal.Content>
    </Modal>
  );
};

const AddLinkModal = ModalManager.create(
  ({ isEdit, link }: AddLinkModalProps) => (
    <AddLinkModalComponent isEdit={isEdit} link={link} />
  ),
);

export const useAddLinkModal = () => useModal(AddLinkModal);
