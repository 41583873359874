import classnames from "classnames";
import { EventDetailPage } from "@/react/components/Events/NewEventsSpace/EventDetailPage";
import { LAYOUT_CONSTANTS } from "@/react/components/Layout/DynamicLayout/constants";
import { StandardLayout } from "@/react/components/Layout/StandardLayout";
import { PostItem } from "@/react/components/PostsV3/PostViewList/PostItem";
import { PostMetaData } from "@/react/components/PostsV3/Show/PostMetaData";
import { useDynamicLayoutContext } from "@circle-react/components/Layout/DynamicLayout";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { EditPostModal } from "@circle-react/components/PostsV3/PostViewListItem/PostActionsDropdownMenu/EditPostModal";
import { CurrentPostContextProvider } from "@circle-react/contexts";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";
import { isSharedPostView } from "@circle-react/helpers/postHelpers";
import { isEventPost } from "@circle-react/helpers/postHelpers";
import { useBackendProps } from "@circle-react/providers";
import { Loader } from "@circle-react-uikit/Loader";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { IFramePostView } from "./IFramePostView";
import { MinimalPostView } from "./MinimalPostView";
import { PostDetailsHeader } from "./PostDetailsHeader";
import { PostNotFound } from "./PostNotFound";
import { RightSidebar } from "./RightSidebar";
import { VersionedPostWrapper } from "./VersionedPostWrapper";
import { useShowPost } from "./useShowPost";

export const Show = () => {
  const {
    postSlug,
    space,
    spaceSlug,
    postResource,
    isLoading,
    isIframeEmbed,
    hasRightSidebar,
    bodyClassNames,
    perPagePostIds,
    onPostDestroy,
    shouldShowEditPostModal,
    setShouldShowEditPostModal,
    spacesContext,
  } = useShowPost();
  const { minimal_layout: isMinimalLayout } = useBackendProps();
  const { layoutType } = useDynamicLayoutContext();
  if (postResource.status === "error" && postResource.error?.status === 404) {
    return (
      <PostNotFound
        space={space}
        postResource={postResource}
        onPostDestroy={onPostDestroy}
        spaceSlug={spaceSlug}
      />
    );
  }

  if (isIframeEmbed) {
    return (
      <PostsDetailsContextProvider perPagePostIds={perPagePostIds}>
        <IFramePostView postResource={postResource} />
      </PostsDetailsContextProvider>
    );
  }

  const _isSharedPostView = isSharedPostView(postResource.data || {}, {
    postSlugInUrl: postSlug,
  });

  const isSharedPostMinimalLayout =
    postResource.data?.minimal_layout && _isSharedPostView;

  if (spacesContext.isLoading || (isMinimalLayout && !postResource?.data)) {
    return <Loader center />;
  }

  const isEventPostType = isEventPost(postResource?.data);

  if (isSharedPostMinimalLayout && !isEventPostType) {
    return (
      <PostsDetailsContextProvider
        perPagePostIds={perPagePostIds}
        isSharedPostView={_isSharedPostView}
      >
        <MinimalPostView
          postResource={postResource}
          onPostDestroy={onPostDestroy}
        />
      </PostsDetailsContextProvider>
    );
  }

  if (isEventPostType) {
    return (
      <PostsDetailsContextProvider
        perPagePostIds={perPagePostIds}
        isSharedPostView={!!_isSharedPostView}
      >
        <EventDetailPage
          event={postResource?.post}
          space={space}
          isLoading={postResource?.isLoading}
          onChange={postResource?.onPostChange}
          onDestroy={onPostDestroy}
          isSharedPostMinimalLayout={isSharedPostMinimalLayout}
        />
      </PostsDetailsContextProvider>
    );
  }

  const shouldHideSpaceHeader = [
    LAYOUT_CONSTANTS.empty,
    LAYOUT_CONSTANTS.minimal,
  ].includes(layoutType);

  return (
    <PostsDetailsContextProvider
      perPagePostIds={perPagePostIds}
      isSharedPostView={!!_isSharedPostView}
    >
      <VersionedContent fullWidth>
        <StandardLayout.Body>
          <PostMetaData
            postResource={postResource}
            bodyClassNames={bodyClassNames}
          />
          {!shouldHideSpaceHeader && (
            <CurrentPostContextProvider post={postResource?.post}>
              <PostDetailsHeader
                space={space}
                postResource={postResource}
                onPostDestroy={onPostDestroy}
              />
            </CurrentPostContextProvider>
          )}
          <VersionedPostWrapper>
            <SkeletonLoader variant="full-post-view" show={isLoading} />
            {!isLoading && postResource.data && (
              <div
                className={classnames("react-page-post-show w-full", {
                  "react-page-post-show--no-right-sidebar": hasRightSidebar,
                })}
                data-testid="post-full"
              >
                <PostItem
                  key={postResource.data.id}
                  post={postResource.data}
                  onPostChange={postResource.onPostChange}
                  onPostDestroy={onPostDestroy}
                  pageSupportsContentTruncation={false}
                  defaultShowComment
                  hideShareLink={isEventPostType}
                />
                <EditPostModal
                  show={shouldShowEditPostModal}
                  post={postResource?.post}
                  closeModal={setShouldShowEditPostModal}
                  onChange={postResource.onPostChange}
                />
              </div>
            )}
            <RightSidebar postResource={postResource} />
          </VersionedPostWrapper>
        </StandardLayout.Body>
      </VersionedContent>
    </PostsDetailsContextProvider>
  );
};

export default Show;
