import { useEffect, useRef } from "react";
import { noop, truncate } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { supportWidgetApi } from "@circle-react/api/supportWidgetApi";
import { LabelMd } from "@circle-react/components/shared/uikit/Typography/components/Label/LabelMd";
import { usePunditUserContext } from "@circle-react/contexts";
import { Form } from "@circle-react-uikit/Form";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";
import { Comment } from "../../Comment";
import { CommentFormV2 } from "../../CommentFormV2";
import { DateDivider } from "../../DateDivider";
import { HeaderIconButton } from "../../HeaderIconButton";
import { SkeletonLoader } from "../../SkeletonLoader";
import { useZendeskUploads } from "../../hooks/useZendeskUploads";
import { useShowConversation } from "../../queries";
import { UPDATE_CONVERSATION_SCHEMA } from "../../schemas";

export const ConversationViewV2 = () => {
  const contentRef = useRef(null);
  const toastr = useToast();
  const { currentCommunityMember } = usePunditUserContext();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const id = Number(match.params.id);
  const { isError, isLoading, data } = useShowConversation(id);

  const conversation = data?.conversation;
  const comments = data?.comments;

  const { uploadAttachments, attachments, setAttachments, cancelAttachment } =
    useZendeskUploads({
      controlName: `conversation-view-${id}`,
      cachingEnabled: false,
    });

  const queryClient = useQueryClient();

  const { mutate: updateSupportWidgetConversation, isLoading: isSubmitting } =
    useMutation(
      ({ comment }) =>
        supportWidgetApi
          .updateConversation({
            comment,
            id,
            zendeskUploadIds: attachments.map(
              attachment => attachment.zendeskUploadId,
            ),
          })
          .then(response => response.json()),
      {
        onSuccess: data => {
          queryClient.setQueryData(["conversations", "show", id], previous => ({
            conversation: previous.conversation,
            comments: [...previous.comments, data.comment],
          }));

          setAttachments([]);

          toastr.success(t("support_widget.send_message_form.success"));
        },
        onError: noop,
      },
    );

  useEffect(() => {
    if (!isLoading && !isError && contentRef.current) {
      // We can find a better way to query all links that are actually in the content, not part of the UI
      contentRef.current
        .querySelectorAll(".comment-body a")
        .forEach(element => {
          element.setAttribute("target", "_blank");
          element.setAttribute("rel", "noreferrer noopener");
        });

      contentRef.current
        .querySelectorAll(".comment-body img")
        .forEach(element => {
          const wrapper = document.createElement("a");
          wrapper.setAttribute("target", "_blank");
          wrapper.setAttribute("rel", "noreferrer noopener");
          wrapper.setAttribute("href", element.getAttribute("src"));

          element.parentNode.insertBefore(wrapper, element);
          wrapper.appendChild(element);
        });

      contentRef.current
        .querySelectorAll(".signature")
        .forEach(element => element.remove());

      document.getElementById("scroll-to-bottom").scrollIntoView();
    }
  }, [isLoading, isError]);

  if (isLoading) {
    return (
      <>
        <SkeletonLoader rows={1} height="52px" wrapperClassName="!px-0 mb-4" />
        <SkeletonLoader rows={4} height="60px" />
      </>
    );
  }

  if (isError) {
    return (
      <div className="flex flex-col">
        <div className="support-widget-post__header border-primary flex h-[52px] shrink-0 items-center justify-between border-b px-3">
          <HeaderIconButton type="back" />
        </div>
        <div className="grow">
          <div className="mx-6 my-3 max-h-full">
            {t("support_widget.no_messages_found")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-primary support-widget-post__header border-primary sticky top-0 z-10 flex h-[52px] shrink-0 items-center justify-between border-b px-3">
        {location.state?.from == "CreateConversationView" ? (
          <HeaderIconButton type="back" onClick={() => history.push("/")} />
        ) : (
          <HeaderIconButton type="back" />
        )}
        <Typography.LabelMd weight="semibold" color="text-dark">
          {isSubmitting
            ? t("support_widget.sending")
            : truncate(conversation.subject, {
                length: 30,
              })}
        </Typography.LabelMd>
        <div className="w-[44px]" />
      </div>
      <div className="mt-auto flex flex-col">
        <div className="mx-6 my-4 flex flex-col gap-4" ref={contentRef}>
          {comments.map((comment, index) => (
            <>
              <DateDivider
                current={comment.created_at}
                previous={comments[index - 1]?.created_at}
              />
              <Comment
                author={
                  comment.author_id === conversation.requester_id
                    ? currentCommunityMember.name
                    : comment.author.name
                }
                avatar={
                  comment.author_id === conversation.requester_id
                    ? currentCommunityMember.avatar_url
                    : comment.author.photo?.content_url
                }
                attachments={comment.attachments}
                content={comment.html_body}
                createdAt={comment.created_at}
                key={comment.id}
              />
            </>
          ))}
        </div>
      </div>
      <div>
        {conversation.status === "closed" ? (
          <div className="dark:border-light mt-auto flex flex-col items-center gap-5 border-t p-5">
            <LabelMd weight="medium" color="text-light">
              {t("support_widget.conversation_closed")}
            </LabelMd>
          </div>
        ) : (
          <div className="border-t-primary border-t">
            <Form
              preventAccidentalLeave
              validationSchema={UPDATE_CONVERSATION_SCHEMA}
              id="update-message-form"
              onSubmit={updateSupportWidgetConversation}
              mode="onSubmit"
              defaultValues={{ comment: "" }}
            >
              <CommentFormV2
                isSubmitting={isSubmitting || isLoading}
                attachments={attachments}
                uploadAttachments={uploadAttachments}
                cancelAttachment={cancelAttachment}
                commentsCount={comments.length}
                onSubmit={updateSupportWidgetConversation}
              />
            </Form>
          </div>
        )}
        <div id="scroll-to-bottom" />
      </div>
    </>
  );
};
