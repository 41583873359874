import { t } from "@/i18n-js/instance";
import { toLocaleString } from "@circle-react/helpers/number";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace = "settings.workflows.view";

interface RecipientCountProps {
  recipientCount: number | null;
}

export const RecipientCount = ({ recipientCount }: RecipientCountProps) => {
  if (recipientCount === null) {
    return (
      <Typography.LabelXs weight="semibold" color="text-default">
        {t([localeNamespace, "no_recipients"])}
      </Typography.LabelXs>
    );
  }

  return (
    <>
      <Typography.LabelXs weight="semibold" color="text-default">
        {toLocaleString(recipientCount)}
      </Typography.LabelXs>
      <Typography.LabelXs weight="semibold" color="text-very-dark">
        {t([localeNamespace, "recipients"])}
      </Typography.LabelXs>
    </>
  );
};
