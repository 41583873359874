import { t } from "@/i18n-js/instance";
import { StandardLayout } from "@circle-react/components/Layout/StandardLayout";
import { LockedSpaceDefault } from "@circle-react/components/Spaces/Locked/LockedSpaceDefault";
import { MobileAppPreview } from "@circle-react-shared/Previews/MobileAppPreview";
import { BlockSelector } from "../Blocks";
import { PREVIEW_MODE } from "../Providers";
import type { PreviewMode } from "../Providers";
import { isDesktopPreviewMode } from "../Providers/helpers";

interface BlocksPreviewViewerProp {
  blocks: any;
  previewMode: PreviewMode;
}

export const BlocksPreviewViewer = ({
  blocks = [],
  previewMode = PREVIEW_MODE.DESKTOP,
}: BlocksPreviewViewerProp) => {
  const hasNonHiddenBlocks =
    Array.isArray(blocks) && blocks.some(block => !block?.hidden);

  if (hasNonHiddenBlocks) {
    return (
      <div className="bg-primary text-dark flex h-full flex-col gap-y-10">
        {blocks
          .filter(block => !block.hidden)
          .map(block => (
            <div key={block.id} className="pointer-events-none select-none">
              <BlockSelector block={block} />
            </div>
          ))}
      </div>
    );
  }

  return isDesktopPreviewMode(previewMode) ? (
    <StandardLayout.Body hasPaddingTopMobile={false}>
      <LockedSpaceDefault />
    </StandardLayout.Body>
  ) : (
    <MobileAppPreview.Page
      title={t(["spaces_directory", "card", "private"])}
      subtitle={t(["this_space_is_private_description"])}
    />
  );
};
