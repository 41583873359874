import type { RerunFormData } from "@circle-workflows/hooks/useRerunWorkflow";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useWorkflow } from "../../WorkflowForm/useWorkflow";
import { confirmBodyProps } from "../confirmBodyProps";

interface ConfirmRerunProps {
  onCancel: () => void;
  onPublish: () => void;
  rerunParams: RerunFormData | null;
  onBack: () => void;
}

export const ConfirmRerun = ({
  onCancel,
  onPublish,
  rerunParams,
  onBack,
}: ConfirmRerunProps) => {
  const { isBulkAction, isScheduled } = useWorkflow();

  const { title, body } = confirmBodyProps({
    isBulkAction: isBulkAction,
    isScheduled: isScheduled,
    isRerun: true,
    rerunParams,
  });

  return (
    <Modal.Content size="md">
      <Modal.Header>
        <Modal.Title size="md">{title}</Modal.Title>
        <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
          <Modal.CloseButton onClick={onCancel} />
        </Modal.HeaderActions>
        <Modal.HeaderActions className="absolute left-0 top-0 ml-6 mt-6">
          <Modal.BackButton onClick={onBack} />
        </Modal.HeaderActions>
      </Modal.Header>
      {body}
      <Modal.Footer className="flex w-full items-center justify-end">
        <Button variant="danger" onClick={onPublish}>
          {t("settings.workflows.confirm_modal.confirm_and_run")}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
