import PropTypes from "prop-types";
import { useFetchMemberTag } from "@circle-workflows/hooks/useFetchMemberTag";
import isEmpty from "lodash/isEmpty";
import { t } from "@/i18n-js/instance";
import { Badge } from "../../../Badge";
import { BaseView } from "../../BaseView";

const i18nRoot = "settings.workflows.view";

export const ViewTag = ({ action }) => {
  const { api_params: apiParams } = action || {};
  const { member_tag_id: memberTagId } = apiParams || {};
  const { data: memberTag = {}, isLoading } = useFetchMemberTag({
    id: memberTagId,
  });

  if (!memberTag) {
    return null;
  }

  const badgeText = isEmpty(memberTag)
    ? t([i18nRoot, "deleted_member_tag"])
    : memberTag.name;

  return (
    <BaseView>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "member_tag"])}</BaseView.Title>
        <div>
          {isLoading ? (
            <Badge isLoading />
          ) : (
            memberTag && <Badge text={badgeText} />
          )}
        </div>
      </BaseView.Section>
    </BaseView>
  );
};

ViewTag.propTypes = {
  action: PropTypes.object,
};
