import type { ReactNode } from "react";
import { useCallback, useState } from "react";
import { createContext, useContext } from "react";
import { isEqual } from "lodash";
import noop from "lodash/noop";
import invariant from "tiny-invariant";
import type { AccessGroupMemberFilterType } from "@circle-react/types/AccessGroup";

export interface AddAccessGroupMembersDataContextInterface {
  selectedMemberIds: number[];
  setSelectedMemberIds: (communityMemberIds: number[]) => void;
  updateFiltersToAddMembers: (filters: AccessGroupMemberFilterType) => void;
  clearSelectedMemberIds: () => void;
  addMemberId: (communityMemberId: number) => void;
  updateTotalMembersToProcess: (total: number) => void;
  totalMembersToProcess: number;
  filtersToProcessMembers: any;
  setFiltersToProcessMembers: (filters: AccessGroupMemberFilterType) => void;
}

const AddAccessGroupMembersDataContext =
  createContext<AddAccessGroupMembersDataContextInterface>({
    updateFiltersToAddMembers: noop,
    selectedMemberIds: [],
    setSelectedMemberIds: noop,
    clearSelectedMemberIds: noop,
    addMemberId: noop,
    updateTotalMembersToProcess: noop,
    totalMembersToProcess: 0,
    filtersToProcessMembers: {},
    setFiltersToProcessMembers: noop,
  });

AddAccessGroupMembersDataContext.displayName =
  "AddAccessGroupMembersDataContext";

export const useAddAccessGroupMemberData = () => {
  const context = useContext(AddAccessGroupMembersDataContext);
  invariant(
    context,
    "useAddAccessGroupMemberData must be used within a AddAccessGroupMembersDataContextProvider",
  );
  return context;
};

interface AddAccessGroupMembersDataContextProviderInterface {
  children: ReactNode;
}

export function AddAccessGroupMemberDataProvider({
  children,
}: Readonly<AddAccessGroupMembersDataContextProviderInterface>) {
  const [selectedMemberIds, setSelectedMemberIds] = useState<number[]>([]);

  const [totalMembersToProcess, setTotalMembersToProcess] = useState(0);

  const [filtersToProcessMembers, setFiltersToProcessMembers] =
    useState<AccessGroupMemberFilterType>([]);

  const clearSelectedMemberIds = useCallback(() => {
    setSelectedMemberIds([]);
  }, [setSelectedMemberIds]);

  const addMemberId = useCallback(
    (communityMemberId: number) => {
      setSelectedMemberIds(selectedMemberIds => {
        if (selectedMemberIds.includes(communityMemberId)) {
          return selectedMemberIds.filter(id => id !== communityMemberId);
        }
        return [...selectedMemberIds, communityMemberId];
      });
    },
    [setSelectedMemberIds],
  );

  const updateFiltersToAddMembers = useCallback(
    (newFilters: AccessGroupMemberFilterType) => {
      if (!isEqual(newFilters, filtersToProcessMembers)) {
        clearSelectedMemberIds();
        setFiltersToProcessMembers(newFilters);
      }
    },
    [filtersToProcessMembers, clearSelectedMemberIds],
  );

  const updateTotalMembersToProcess = useCallback(
    (total = 0) => {
      // members manually selected have priority
      const totalSelected = selectedMemberIds.length;
      if (totalSelected > 0) {
        if (totalSelected != totalMembersToProcess) {
          setTotalMembersToProcess(selectedMemberIds.length);
        }
      } else if (total != totalMembersToProcess) {
        setTotalMembersToProcess(total);
      }
    },
    [selectedMemberIds.length, totalMembersToProcess],
  );

  return (
    <AddAccessGroupMembersDataContext.Provider
      value={{
        selectedMemberIds,
        setSelectedMemberIds,
        clearSelectedMemberIds,
        addMemberId,
        updateFiltersToAddMembers,
        updateTotalMembersToProcess,
        totalMembersToProcess,
        setFiltersToProcessMembers,
        filtersToProcessMembers,
      }}
    >
      {children}
    </AddAccessGroupMembersDataContext.Provider>
  );
}
