import type { MouseEvent, ReactNode } from "react";
import { Fragment, useState } from "react";
import { useQuickPostV2Modal } from "@circle-react/components/QuickPostV2";
import { POST_FORM_CACHE } from "@circle-react/components/constants";
import { usePersistedFormFieldValues } from "@circle-react/hooks/utils/usePersistFormFields";
import {
  CurrentPostContextProvider,
  useCurrentSpaceContext,
  usePunditUserContext,
} from "../../contexts";
import type { RenderProp } from "./ModalButton";
import { ModalButton } from "./ModalButton";
import { ModalForm } from "./ModalForm";

export interface OpenQPModalProps {
  bodyForEditor?: string;
  children?: ReactNode;
  className?: string;
  currentSpaceId?: number;
  hasIcon?: boolean;
  id?: string;
  isButton?: boolean;
  linkName?: string;
  maxZIndexEnabled?: boolean;
  onSubmitSuccess?: () => void;
  render?: RenderProp;
  tiptapBodyForEditor?: object;
  title?: string;
}

export const OpenQPModal = ({
  bodyForEditor = "",
  children,
  className,
  currentSpaceId,
  hasIcon = false,
  id,
  isButton = true,
  linkName,
  maxZIndexEnabled = false,
  onSubmitSuccess,
  render,
  tiptapBodyForEditor,
  title = "",
}: OpenQPModalProps) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const quickPostV2Modal = useQuickPostV2Modal();
  const [isShowing, setIsShowing] = useState(false);
  const spaceContext = useCurrentSpaceContext();
  const spaceId: any = currentSpaceId || spaceContext?.data?.id;

  const { getPersistedFormFieldValues } = usePersistedFormFieldValues({
    cacheKey: POST_FORM_CACHE,
  });

  const [post, setPost] = useState<any>({ space_id: spaceId });

  const handleShowModal = (event: MouseEvent<HTMLElement>) => {
    if (isViewOnlyMasquerading) {
      return;
    }

    event.preventDefault();

    const persistedFormValues = getPersistedFormFieldValues();

    setPost({
      ...persistedFormValues,
      name: title || persistedFormValues?.name,
      body_for_editor: bodyForEditor || persistedFormValues?.body,
      space_id: spaceId || persistedFormValues?.space_id,
    });

    void quickPostV2Modal.show({
      spaceId,
      onSubmitSuccess,
      bodyForEditor,
      tiptapBodyForEditor,
    });
  };

  const closeModal = () => {
    setIsShowing(false);
    setPost({});
  };

  return (
    <Fragment key={spaceId}>
      <ModalButton
        className={className}
        hasIcon={hasIcon}
        id={id}
        isButton={isButton}
        linkName={linkName}
        render={render}
        showModal={handleShowModal}
      >
        {children}
      </ModalButton>
      {isShowing && (
        <CurrentPostContextProvider post={post} onPostChange={setPost}>
          <ModalForm
            show
            closeModal={closeModal}
            currentSpaceId={spaceId}
            maxZIndexEnabled={maxZIndexEnabled}
            onSubmitSuccess={onSubmitSuccess}
          />
        </CurrentPostContextProvider>
      )}
    </Fragment>
  );
};
