import { t } from "@/i18n-js/instance";
import { ProfileHoverCard } from "@circle-react/components/Profile/HoverCard";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

interface HostProps {
  host: {
    id: number;
    avatar_url: string;
    name: string;
    headline: string;
    public_uid: string;
  };
}

export const Host = ({ host }: HostProps) => {
  const profileLinkProps = useProfileLinkProps({
    public_uid: host.public_uid,
  });
  return (
    <div className="flex space-x-4">
      <a {...profileLinkProps} title={host.name}>
        <ProfileHoverCard
          communityMemberId={host.id}
          name={host.name}
          publicUid={host.public_uid}
          headline={host.headline}
          avatarURL={host.avatar_url}
        >
          <UserImage src={host.avatar_url} name={host.name} size="10" />
        </ProfileHoverCard>
      </a>
      <div className="flex flex-col">
        <Typography.LabelSm weight="normal">
          {t("event_details.hosted_by")}
        </Typography.LabelSm>
        <a {...profileLinkProps} title={host.name}>
          <Typography.LabelSm weight="semibold">{host.name}</Typography.LabelSm>
        </a>
      </div>
    </div>
  );
};
