import InfiniteScroll from "react-infinite-scroll-component";
import { chatInboxPath } from "@circle-react/helpers/urlHelpers";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import type {
  ChatSortType,
  ChatType,
} from "@circle-react/types/CommunityBot/ChatSort";
import type { Chat } from "@circle-react/types/CommunityInbox/Chat";
import { Loader } from "@circle-react-uikit/Loader";
import { useChatList } from "../../hooks/useCommunityChatList";
import { useCommunityInboxSync } from "../../hooks/useCommunityInboxSync";
import { ChatItem } from "./ChatItem";
import { EmptyChat } from "./EmptyChat";

export const ChatListComponent = ({
  activeSort,
  activeChatType,
  activeAgent,
}: {
  activeSort: ChatSortType;
  activeChatType: ChatType;
  activeAgent: { name: string; id: number };
}) => {
  const { isChatsLoading, hasNextPage, fetchNextPage } = useChatList({
    sort: activeSort,
    filter: "has_member_interaction",
    chatType: activeChatType,
    agentId: activeAgent.id,
  });
  const { adminChats: chats, currentChatId } = useCommunityInboxStore();
  useCommunityInboxSync();

  if (isChatsLoading) {
    return (
      <div className="h-full overflow-auto p-2">
        <Loader />
      </div>
    );
  }

  if (chats.length == 0) {
    return <EmptyChat />;
  }
  return (
    <InfiniteScroll
      className="!h-[calc(100dvh-160px)] overflow-auto px-2"
      style={{ overflow: "auto" }}
      scrollThreshold={0.8}
      next={fetchNextPage}
      height={600}
      hasMore={Boolean(hasNextPage)}
      dataLength={chats.length}
      loader={<Loader center />}
    >
      {chats.map((chat: Chat) => {
        const isOpenChatRoom = chat.id == currentChatId;
        return (
          <ChatItem
            key={chat.id}
            chat={chat}
            path={chatInboxPath({ id: chat.id.toString() })}
            isUnread={!isOpenChatRoom && chat.has_unread_messages_for_admin}
          />
        );
      })}
    </InfiniteScroll>
  );
};
