import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { RadioButtonGroup } from "@circle-react-shared/RadioButtonGroup";
import { Typography } from "@circle-react-uikit/Typography";
import { MigrationEmptyState } from "./MigrationEmptyState";

interface PaywallMigrationToAccessGroupsProps {
  spaceAccessNode: React.ReactNode;
  closePaywallFormModal: () => void;
}

const i18nRoot = "settings.access_groups.paywall_migration";
export const tabs = Object.freeze({
  access_groups: "access_groups",
  space_access: "space_access",
});

export const PaywallMigrationToAccessGroups = ({
  spaceAccessNode,
  closePaywallFormModal,
}: PaywallMigrationToAccessGroupsProps) => {
  const [defaultTab, setDefaultTab] = useState<string>(tabs.space_access);
  const changeTabHandler = (value: string) => {
    setDefaultTab(value);
  };
  return (
    <>
      <Typography.BodySm>{t([i18nRoot, "subtitle"])}</Typography.BodySm>
      <div className="my-5">
        <RadioButtonGroup
          shouldShowInlineOptions
          name="access_groups_enabled"
          onChange={changeTabHandler}
          defaultChecked={defaultTab}
          size="sm"
          options={[
            {
              label: (
                <div className="flex items-center justify-center gap-1">
                  {t([i18nRoot, "tabs.access_groups"])}
                  <div className="border-v2-danger bg-v2-danger flex w-fit items-center space-x-2 rounded-2xl px-1 text-white">
                    <Typography.LabelXxs
                      weight="semibold"
                      color="text-inherit"
                      truncate
                      data-testid="chip-title"
                    >
                      {t([i18nRoot, "tabs.new"])}
                    </Typography.LabelXxs>
                  </div>
                </div>
              ),
              value: tabs.access_groups,
            },
            {
              label: t([i18nRoot, "tabs.space_access"]),
              value: tabs.space_access,
            },
          ]}
          checkedOptionClassName="pl-9"
        />
        {defaultTab === tabs.space_access && spaceAccessNode}
        {defaultTab === tabs.access_groups && (
          <MigrationEmptyState closePaywallFormModal={closePaywallFormModal} />
        )}
      </div>
    </>
  );
};
