import { useEffect, useRef } from "react";
import { t } from "@/i18n-js/instance";
import { PAYWALL_COUPON_STATUS } from "@circle-react/helpers/paywallCouponHelpers";
import {
  couponStatusesDefault,
  useCouponForm,
} from "@circle-react-shared/Paywalls/Modals/CreatePaywallSubscriptionModal/Form/hooks/useCouponForm";
import { useBulkImportTrialPaywallSubscriptionFormContext } from "./BulkImportTrialPaywallSubscriptionFormProvider";
import { CustomFormItem } from "./CustomFormItem";
import { CustomSelectInput } from "./CustomSelectInput";

export const CouponForm = () => {
  const {
    paywallId,
    paywallCouponCode,
    setPaywallCouponCode,
    skipDateValidations,
  } = useBulkImportTrialPaywallSubscriptionFormContext();
  const { couponCodeOptions } = useCouponForm({
    paywallId,
    selectCouponString: t("bulk_import_page.paywall.choose_coupon"),
    couponStatuses: skipDateValidations
      ? couponStatusesDefault
      : [PAYWALL_COUPON_STATUS.ACTIVE],
    skipDateValidations,
  });

  const onPaywallCouponCodeChange = e => {
    setPaywallCouponCode(e.target.value ?? paywallCouponCode);
  };

  const paywallIdOld = useRef(paywallId);
  useEffect(() => {
    if (paywallIdOld.current !== paywallId) {
      setPaywallCouponCode("");
      paywallIdOld.current = paywallId;
    }
  }, [paywallId, setPaywallCouponCode]);

  return (
    <CustomFormItem label={t("create_paywall_subscription_modal.form.coupon")}>
      <CustomSelectInput
        name="paywall[paywall_coupon_code]"
        options={couponCodeOptions}
        disabled={couponCodeOptions.length === 1}
        onChange={onPaywallCouponCodeChange}
        value={paywallCouponCode}
      />
    </CustomFormItem>
  );
};
