import { useState } from "react";

export const useMultiSelect = ({ query }: { query: any }) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const allIdsOnPage = query.data?.records?.map((row: any) => row.id) || [];

  const reset = () => {
    setSelectedIds([]);
  };

  const handleCheckboxChange = (id: number) => {
    setSelectedIds(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id],
    );
  };

  const handleSelectAllOnPage = (checked: boolean) => {
    setSelectedIds(prev => {
      const newSelectedIds = new Set(prev);

      if (checked) {
        // Add all items from the current page
        allIdsOnPage.forEach((id: number) => newSelectedIds.add(id));
      } else {
        // Remove items only from the current page
        allIdsOnPage.forEach((id: number) => newSelectedIds.delete(id));
      }

      return Array.from(newSelectedIds);
    });
  };

  return {
    selectedIds,
    allIdsOnPage,
    handleCheckboxChange,
    handleSelectAllOnPage,
    reset,
  };
};
