import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { formsApi } from "@circle-react/api/formsApi";
import type { Form } from "@circle-react/types/Form";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

export interface UseGetFormProps {
  id?: number;
}

export const useGetForm = ({ id }: UseGetFormProps) => {
  const toastr = useToast();

  return useQuery<Form | null>({
    queryKey: ["forms", "show", id],
    queryFn: () => {
      if (id === undefined) return null;
      return formsApi.show(id);
    },
    onError: () => toastr.error(t("error")),
    enabled: id !== undefined,
  });
};
