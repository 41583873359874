import type { ComponentType } from "react";
import { Controller } from "react-hook-form";
import { useFilterValueContext } from "@circle-react-shared/MemberFilters/FilterValueContext";
import { getIsValueEmpty } from "@circle-react-shared/MemberFilters/getIsValueEmpty";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";

interface FormFilterFieldProps {
  index: number;
  param: BaseFilter;
  onApply: (value: BaseFilter) => void;
}

/** @TODO update the prop type when fixed */
export const getFormFieldComponent = (Component: ComponentType<any>) =>
  function FormFilterField({
    index,
    onApply,
    param,
    ...props
  }: FormFilterFieldProps) {
    const { onClear } = useFilterValueContext();
    const fieldName = `audience.rules.rules[${index}]`;

    return (
      <Controller
        name={fieldName}
        defaultValue={param}
        render={({ field: { value, onChange } }) => (
          <Component
            {...props}
            param={value}
            onApply={(param: BaseFilter) => {
              if (!param || getIsValueEmpty(param)) {
                onClear?.(index);
              } else {
                /* Do not rerender with value - null. We will clear the index if there isn't a value in param */
                onChange(param);
              }
              onApply(param);
            }}
          />
        )}
      />
    );
  };
