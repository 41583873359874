import { PaymentProcessorStatus } from "@circle-react/components/Paywalls/Admin/PaywallsAdmin/constants";

export const STATUS = Object.freeze({
  RESTRICTED: "restricted",
  PENDING: "pending",
  REJECTED: "rejected",
  ENABLED: "enabled",
});

export const PAYMENT_PROCESSORS = Object.freeze({
  STRIPE_US: "CommunityPaymentProcessors::StripeUnitedStates",
  STRIPE_BR: "CommunityPaymentProcessors::StripeBrazil",
  REVENUE_CAT: "CommunityPaymentProcessors::RevenueCat",
});

export const paymentProcessorValidations = paymentProcessor => [
  () => (!paymentProcessor ? PaymentProcessorStatus.NOT_EXIST : undefined),
  () =>
    paymentProcessor.status === STATUS.RESTRICTED
      ? PaymentProcessorStatus.RESTRICTED
      : undefined,
  () =>
    paymentProcessor.is_restricted_for_application_fees
      ? PaymentProcessorStatus.RESTRICED_FOR_APPLICATION_FEES
      : undefined,
  () =>
    paymentProcessor.status === STATUS.PENDING
      ? PaymentProcessorStatus.PENDING
      : undefined,
  () =>
    paymentProcessor.status === STATUS.REJECTED
      ? PaymentProcessorStatus.REJECTED
      : undefined,
];

export const hasRevenueCatPaymentProcessor = paymentProcessors =>
  paymentProcessors?.includes(PAYMENT_PROCESSORS.REVENUE_CAT);

export const isEnabled = paymentProcessorStatus =>
  paymentProcessorStatus === PaymentProcessorStatus.ENABLED;
