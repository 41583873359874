import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useMutation, useQueryClient } from "react-query";
import I18n from "@/i18n-js/instance";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { reactQueryPut } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import {
  getCourseSectionsKey,
  getLessonKey,
} from "@circle-react/hooks/courses/useCoursesApi";
import { Dropdown } from "@circle-react-uikit/Dropdown";

const getOptions = () => [
  {
    label: I18n.t("courses.edit.sections.statuses.draft"),
    value: "draft",
  },
  {
    label: I18n.t("courses.edit.sections.statuses.published"),
    value: "published",
  },
];

export const StatusPicker = ({ lesson, section }) => {
  const queryClient = useQueryClient();
  const { data: space } = useCurrentSpaceContext();
  const [activeOptionValue, setActiveOptionValue] = useState(lesson.status);

  useEffect(() => {
    setActiveOptionValue(lesson.status);
  }, [lesson.status]);

  const statusChangeMutation = useMutation(
    data =>
      reactQueryPut(
        internalApi.courses.updateLesson({
          lessonId: lesson.id,
          sectionId: section.id,
          courseId: space.id,
        }),
        data,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getCourseSectionsKey(space.id));
        await queryClient.invalidateQueries(getLessonKey(lesson.id));
      },
    },
  );

  const handleStatusChange = newValue => {
    setActiveOptionValue(newValue);
    statusChangeMutation.mutate({
      course_lesson: { ...lesson, status: newValue },
    });
  };

  return (
    <Dropdown
      options={getOptions()}
      onChange={handleStatusChange}
      buttonClassName="w-28"
      button={
        <Dropdown.ActiveLabelButton
          className={classNames(
            "flex justify-between rounded-md px-2 py-1 text-xs",
            {
              "bg-secondary": activeOptionValue === "draft",
              "bg-green-100": activeOptionValue === "published",
            },
          )}
          labelColor={
            activeOptionValue === "published" ? "text-green-900" : "text-dark"
          }
          activeOptionValue={activeOptionValue}
          options={getOptions()}
        />
      }
      dataTestId="lesson-status-dropdown"
    />
  );
};

StatusPicker.propTypes = {
  lesson: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
};
