import { createContext, useCallback, useContext } from "react";
import type { ReactNode } from "react";
import { noop } from "lodash";
import { useFieldArray, useFormContext } from "react-hook-form";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  isOneTimePrice,
  isRecurringPrice,
  subscriptionPrices,
} from "@circle-react/helpers/paywallPriceHelpers";
import type {
  PaywallCurrency,
  PaywallPriceBuildOptions,
} from "@circle-react/types";
import {
  paywallCurrencyDirections,
  paywallCurrencySmallestUnits,
  paywallPriceTypes,
} from "@circle-react/types";
import type {
  PricingTabPriceItemId,
  PricingTabSelectOption,
} from "../../types";
import type { PricingTabPriceItem } from "../types";
import type { PaywallPricePackage } from "../types";

export const findPriceIndex = (
  prices: Array<PricingTabPriceItem>,
  price: PricingTabPriceItemId,
): number =>
  prices.findIndex((item: PricingTabPriceItem) => {
    if (price.id) {
      return item.id === price.id;
    }
    return item.tmp_id === price.tmp_id;
  });

export interface MobilePricingTabContextProps {
  currency: PaywallCurrency;
  priceBuildOptions: PaywallPriceBuildOptions;
  priceTypeOptions: () => Array<PricingTabSelectOption>;
  defaultPriceTypeOption: () => PricingTabSelectOption;
  getPrices: () => Array<PricingTabPriceItem>;
  getActivePrices: () => Array<PricingTabPriceItem>;
  hasRecurringPrices: () => boolean;
  addPrice: (data: PaywallPricePackage) => void;
  removePrice: (price: PricingTabPriceItemId) => void;
  isShowingAddPriceModal: () => boolean;
  showAddPriceModal: () => void;
  hideAddPriceModal: () => void;
  hasActivePrices: boolean;
  hasSubscriptionPrices: () => boolean;
  billingFrequencyOptions: () => Array<{ value: string; label: string }>;
  disableAddPriceButton: () => boolean;
}

const MobilePricingTabContext = createContext<MobilePricingTabContextProps>({
  currency: {
    id: 1,
    code: "usd",
    direction: paywallCurrencyDirections.ltr,
    smallest_unit: paywallCurrencySmallestUnits.decimal,
    symbol: "$",
    min_charge_amount: 0,
    max_charge_amount: 0,
    is_default: true,
  },
  priceBuildOptions: {},
  priceTypeOptions: () => [],
  defaultPriceTypeOption: () => ({
    value: "",
    label: "",
  }),
  getPrices: () => [],
  getActivePrices: () => [],
  hasRecurringPrices: () => false,
  addPrice: noop,
  removePrice: noop,
  isShowingAddPriceModal: () => false,
  showAddPriceModal: noop,
  hideAddPriceModal: noop,
  hasActivePrices: false,
  hasSubscriptionPrices: () => false,
  billingFrequencyOptions: () => [],
  disableAddPriceButton: () => false,
});

MobilePricingTabContext.displayName = "MobilePricingTabContext";

export interface PricingTabContextProviderProps {
  currency: PaywallCurrency;
  children: ReactNode;
}

export const MobilePricingTabContextProvider = ({
  currency,
  children,
}: PricingTabContextProviderProps) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const priceBuildOptions =
    currentCommunitySettings?.price_build_options?.mobile;

  const { setValue, watch } = useFormContext();

  const getPrices = useCallback(
    (): Array<PricingTabPriceItem> => watch("mobile_price_packages_attributes"),
    [watch],
  );
  const getActivePrices = useCallback(
    () => getPrices().filter(price => !price._destroy),
    [getPrices],
  );
  const hasActivePrices = getActivePrices().length > 0;

  const hasOneTimePrices = useCallback(() => {
    const pricePackages = getActivePrices();
    return !!pricePackages.find(pricePackage =>
      isOneTimePrice(pricePackage.price_properties),
    );
  }, [getActivePrices]);

  const hasRecurringPrices = useCallback(() => {
    const pricePackages = getActivePrices();
    return !!pricePackages.find(pricePackage =>
      isRecurringPrice(pricePackage.price_properties),
    );
  }, [getActivePrices]);

  const hasSubscriptionPrices = useCallback(() => {
    const prices = getActivePrices();
    return subscriptionPrices(prices).length > 0;
  }, [getActivePrices]);

  const { append, update, remove } = useFieldArray({
    name: "mobile_price_packages_attributes",
    rules: { minLength: 1 },
  });

  const priceTypeOptions = useCallback(() => {
    if (!priceBuildOptions) {
      return [];
    }

    return Object.keys(priceBuildOptions)
      .filter(value => value !== paywallPriceTypes.installments)
      .filter(
        value =>
          value !== paywallPriceTypes.onetime ||
          (!hasOneTimePrices() && value === paywallPriceTypes.onetime),
      )
      .map(value => ({
        value,
        label: priceBuildOptions[value].name,
      }));
  }, [priceBuildOptions, hasOneTimePrices]);

  const billingFrequencyOptions = useCallback(() => {
    if (!priceBuildOptions) {
      return [];
    }

    return Object.entries(priceBuildOptions["subscription"].frequencies)
      .filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars -- we're not going to use the first variable
        ([_, frequency]: Array<any>) =>
          !getActivePrices().find(
            price =>
              price.price_properties.interval === frequency.interval &&
              price.price_properties.interval_count ===
                frequency.interval_count,
          ),
      )
      .map(([value, frequency]: Array<any>) => ({
        value,
        label: frequency.as_adjective,
      }));
  }, [priceBuildOptions, watch, hasOneTimePrices()]);

  const defaultPriceTypeOption = useCallback(
    () => priceTypeOptions()[0],
    [priceTypeOptions],
  );

  const addPrice = useCallback(
    (data: PaywallPricePackage) => {
      append({
        ...data,
        tmp_id: `tmp_${Date.now()}`,
      });
    },
    [currency, append],
  );

  const removePrice = useCallback(
    (price: PricingTabPriceItemId) => {
      const prices = getPrices();
      const priceIdx = findPriceIndex(prices, price);
      if (priceIdx > -1) {
        const existingPrice = prices[priceIdx];
        if (existingPrice.id) {
          update(priceIdx, {
            ...existingPrice,
            _destroy: true,
          });
        } else {
          remove(priceIdx);
        }
      }
    },
    [getPrices, update, remove],
  );

  const isShowingAddPriceModal = useCallback(
    () => !!watch("isShowingAddPriceModal"),
    [watch],
  );
  const showAddPriceModal = useCallback(() => {
    setValue("isShowingAddPriceModal", true);
  }, [setValue]);
  const hideAddPriceModal = useCallback(() => {
    setValue("isShowingAddPriceModal", false);
  }, [setValue]);
  const disableAddPriceButton = useCallback(
    () =>
      billingFrequencyOptions().length === 0 &&
      !priceTypeOptions().find(
        option => option.value === paywallPriceTypes.onetime,
      ),
    [billingFrequencyOptions, priceTypeOptions, paywallPriceTypes],
  );

  return (
    <MobilePricingTabContext.Provider
      value={{
        currency,
        priceBuildOptions: priceBuildOptions || {},
        priceTypeOptions,
        defaultPriceTypeOption,
        getPrices,
        getActivePrices,
        hasRecurringPrices,
        addPrice,
        removePrice,
        isShowingAddPriceModal,
        showAddPriceModal,
        hideAddPriceModal,
        hasActivePrices,
        hasSubscriptionPrices,
        billingFrequencyOptions,
        disableAddPriceButton,
      }}
    >
      {children}
    </MobilePricingTabContext.Provider>
  );
};

export const usePricingTabContext = () => useContext(MobilePricingTabContext);
