import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import {
  stepKeys,
  useOnboarding,
} from "@/react/components/CommunityOnboarding/useOnboarding";
import { communityApi } from "@circle-react/api";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { usePunditUserContext } from "@circle-react/contexts";
import { ImageUploadInput } from "@circle-react-shared/ImageUploadInput";
import { Button } from "@circle-react-uikit/Button";
import { Drawer } from "@circle-react-uikit/Drawer";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ColorPickerField } from "./ColorPickerField";
import { FormItem } from "./FormItem";
import "./styles.scss";

interface FormValues {
  prefs: {
    brand_color: {
      light: string;
    };
  };
  logo: string | null;
  icon: string | null;
}

export const BrandingEditForm = () => {
  const { currentCommunity, isLoading } = usePunditUserContext();
  const { updateStepIfIncomplete } = useOnboarding();

  const { success, error } = useToast();
  const mutation = useMutation<unknown, Error, FormValues>(
    formData => communityApi.update({ community: formData }),
    {
      onSuccess: async () => {
        success(t("settings.branding_form.saved_successfully"));
        await updateStepIfIncomplete(stepKeys.customize_branding);
        window.location.reload();
      },
      onError: err => {
        error(err.message);
      },
    },
  );

  if (isLoading || !currentCommunity) {
    return <Loader center />;
  }

  const defaultFormValues: FormValues = {
    prefs: {
      brand_color: currentCommunity.prefs.brand_color,
    },
    logo: currentCommunity.logo,
    icon: currentCommunity.icon,
  };

  const handleSubmit = (data: FormValues) => {
    mutation.mutate(data);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultFormValues}
      className="branding-drawer__form"
    >
      <div className="branding-drawer__container">
        <ColorPickerField name="prefs.brand_color.light" />
        <FormItem name="logo" variant="extra-bottom-margin">
          <ImageUploadInput
            aspectRatio={ASPECT_RATIO.COMMUNITY_LOGO_V3}
            changeImageText={t("replace")}
            hideEmbedUrl
            hideUnsplash
            isCropEnabled={false}
            name="logo"
            showAddCoverBtn
            title={t("customize_theme.logo_tab.upload_community_logo")}
          />
        </FormItem>
        <FormItem name="icon" variant="extra-bottom-margin">
          <ImageUploadInput
            aspectRatio={ASPECT_RATIO.COMMUNITY_ICON}
            changeImageText={t("replace")}
            hideEmbedUrl
            hideUnsplash
            name="icon"
            showAddCoverBtn
            title={t("customize_theme.logo_tab.upload_community_icon")}
          />
        </FormItem>
      </div>
      <Drawer.Footer sticky>
        <Button
          type="submit"
          variant="primary"
          disabled={mutation.isLoading}
          full
        >
          {mutation.isLoading ? t("saving") : t("save")}
        </Button>
      </Drawer.Footer>
    </Form>
  );
};
