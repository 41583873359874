import { t } from "@/i18n-js/instance";
import {
  chatRoomPath,
  chatSpacePath,
  coursePaths,
} from "@circle-react/helpers/urlHelpers";

/**
 * Returns participant names string
 * count:1 Just Name1
 * count:2 Name1 and Name2
 * count:3 Name1, Name2 and Name3
 * count:3> Name1, Name2, Name3 and x others
 */
export const getParticipantNames = (participants, count) => {
  let participantNames = ``;
  participants.forEach((participant, index) => {
    const isLastIndex = index === participants.length - 1;
    const isSecondLastIndex = index === participants.length - 2;

    if (count === 1) {
      participantNames = `${t("just")} ${participant.name}`;
    } else if (isSecondLastIndex && count <= 3) {
      participantNames = participantNames + participant.name + ` ${t("and")} `;
    } else if (isLastIndex && count > 3) {
      const displayCount = count - 3;
      participantNames =
        participantNames +
        participant.name +
        ` ${t("and")} ` +
        displayCount.toString() +
        (displayCount === 1 ? ` ${t("other")}` : `${t("others")}`);
    } else if (isLastIndex) {
      participantNames = participantNames + participant.name;
    } else {
      participantNames = participantNames + participant.name + ", ";
    }
  });
  return participantNames;
};

export const sortUnreadChatThreadsIds = (
  unreadChatThreadIds,
  chatThreads,
  isInverted = false,
) => {
  const sortingArr = chatThreads.map(
    thread => thread.parent_message.chat_thread_id,
  );

  return unreadChatThreadIds.sort((a, b) =>
    isInverted
      ? sortingArr.indexOf(b) - sortingArr.indexOf(a)
      : sortingArr.indexOf(a) - sortingArr.indexOf(b),
  );
};

export const getSeparateSortedUnreadThreadIds = (
  unreadThreadIds,
  chatThreads,
) => {
  let unreadThreadIdsAtTop = [];
  let unreadThreadIdsAtBottom = [];
  unreadThreadIds.forEach(unreadThreadId => {
    const element = document.getElementById(unreadThreadId);
    if (!element) return;
    const rect = element.getBoundingClientRect();
    if (rect.top > 0) unreadThreadIdsAtBottom.push(unreadThreadId);
    else unreadThreadIdsAtTop.push(unreadThreadId);
  });

  unreadThreadIdsAtBottom = sortUnreadChatThreadsIds(
    unreadThreadIdsAtBottom,
    chatThreads,
  );

  unreadThreadIdsAtTop = sortUnreadChatThreadsIds(
    unreadThreadIdsAtTop,
    chatThreads,
    true,
  );

  return {
    unreadThreadIdsAtTop,
    unreadThreadIdsAtBottom,
  };
};

export const scrollToThread = unreadThreadId => {
  const element = document.getElementById(unreadThreadId);
  element.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};

export const getChatRoomUrl = ({
  isEmbedded,
  isMessaging,
  lesson,
  slug,
  chatRoomUuid,
}) =>
  isEmbedded && !isMessaging
    ? lesson
      ? coursePaths.showLesson({
          spaceSlug: slug,
          sectionId: lesson.courses_section_id,
          lessonId: lesson.id,
        })
      : chatSpacePath({ spaceSlug: slug })
    : chatRoomPath({ uuid: chatRoomUuid });
