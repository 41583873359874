import { t } from "@/i18n-js/instance";
import { ProfileHoverCard } from "@circle-react/components/Profile/HoverCard";
import { getProfileLinkProps } from "@circle-react/helpers/profileHelpers";
import type { ShowMemberProfileDrawer } from "@circle-react/helpers/profileHelpers";
import type { CommunityMember } from "@circle-react/types/CommunityMember";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

const MODERATOR_LABEL = "M";

export interface TopMemberProps {
  communityMember: CommunityMember;
  moderator: boolean;
  showMemberProfileDrawer: ShowMemberProfileDrawer;
}

export const TopMemberItem = ({
  communityMember,
  moderator,
  showMemberProfileDrawer,
}: TopMemberProps) => {
  const { first_name, public_uid, avatar_url, name, id, headline } =
    communityMember;

  if (!first_name) {
    return null;
  }

  const profileLinkProps = getProfileLinkProps({
    publicUid: public_uid ?? "",
    params: { show_back_link: true },
    showMemberProfileDrawer,
    name,
  });

  return (
    <li className="mt-4">
      <ProfileHoverCard
        communityMemberId={id}
        name={name}
        publicUid={public_uid}
        headline={headline}
        avatarURL={avatar_url}
      >
        <a
          className="text-dark relative flex items-center"
          {...profileLinkProps}
        >
          <div className="relative top-0 h-auto p-0">
            <div className="text-dark z-[1] mr-3 flex h-10 w-10 items-center rounded-lg object-cover text-sm leading-5">
              <UserImage name={name ?? ""} src={avatar_url} size="10" />
            </div>
          </div>
          <div className="!text-dark inline-flex items-center justify-center text-sm">
            {name}
            {moderator && (
              <TippyV2
                className="bg-brand text-brand-button ml-1 inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-full text-center text-xs font-semibold leading-normal"
                content={t("moderator")}
              >
                {MODERATOR_LABEL}
              </TippyV2>
            )}
          </div>
        </a>
      </ProfileHoverCard>
    </li>
  );
};
