import { useEffect } from "react";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  useMediaQuery,
  useTailwindLgScreenMediaQuery,
} from "@circle-react/hooks/useMediaQuery";
import { useSpaceSidebarNotifications } from "@circle-react/hooks/useSpaceSidebarNotifications";
import { Links } from "./CommunityMenu/Links";
import { SpaceGroupList } from "./CommunityMenu/SpaceGroupList";
import { isTouchEvent } from "./CommunityMenu/utils";
import { GoLivePoweredBy } from "./GoLivePoweredBy";
import { LinksWrapper } from "./LinksWrapper";
import { LogoTopSection } from "./LogoTopSection";
import { PlatformMenu } from "./PlatformMenu";
import { PlatformMenuV3 } from "./PlatformMenuV3";
import { ProfileSection } from "./ProfileSection";
import { ProfileWrapper } from "./ProfileWrapper";
import { LiveIndicator } from "./SidebarFooter";
import { SidebarV2Wrapper } from "./SidebarV2Wrapper";

export interface SidebarV2Props {
  onSortEnd: (sortProps: any) => void;
  spaceGroupWithSpaces: any[];
  onSpaceSortEnd: (sortProps: any) => void;
  updateBeforeSortStart: (sortProps: any) => void;
  spaceGroupDragProperties?: any;
}

export const SidebarV2 = ({
  onSortEnd,
  spaceGroupWithSpaces,
  onSpaceSortEnd,
  updateBeforeSortStart,
  spaceGroupDragProperties,
}: SidebarV2Props) => {
  const { currentCommunity, currentCommunityMember } = usePunditUserContext();
  const isLargeScreen = useTailwindLgScreenMediaQuery();
  const isSmOrMdOrLgScreen = useMediaQuery("(max-width: 1023px)");
  const shouldShowPlatforMenu = isSmOrMdOrLgScreen;
  const shouldShowV3PlatforMenu = isLargeScreen;

  const { refetchSpaceNotifications } = useSpaceSidebarNotifications();

  useEffect(() => {
    currentCommunityMember && void refetchSpaceNotifications();
  }, [currentCommunityMember, refetchSpaceNotifications]);

  if (!currentCommunityMember && currentCommunity?.is_private) {
    return null;
  }

  return (
    <SidebarV2Wrapper>
      {!isLargeScreen && <LogoTopSection />}
      <LinksWrapper>
        {shouldShowPlatforMenu && <PlatformMenu />}
        {shouldShowV3PlatforMenu && <PlatformMenuV3 />}
        <SpaceGroupList
          distance={25}
          lockAxis="y"
          onSortEnd={onSortEnd}
          spaceGroupWithSpaces={spaceGroupWithSpaces}
          onSpaceSortEnd={onSpaceSortEnd}
          updateBeforeSortStart={updateBeforeSortStart}
          spaceGroupDragProperties={spaceGroupDragProperties}
          shouldCancelStart={e => isTouchEvent(e)}
          helperClass="!pointer-events-auto"
        />
        <Links />
      </LinksWrapper>
      <ProfileWrapper>
        <LiveIndicator />
        {!isLargeScreen && <ProfileSection />}
        <GoLivePoweredBy />
      </ProfileWrapper>
    </SidebarV2Wrapper>
  );
};
