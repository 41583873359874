import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Accordion } from "@circle-react-uikit/Accordion";
import { Panel } from "@circle-react-uikit/Panel";
import { PrettyPrintJson } from "./PrettyPrintJson";
import "./styles.scss";

const SUCCESS = "success";

export const DebugSessions = ({ debug_session, debug_session_logs }) => (
  <div className="custom-login-debug-sessions">
    <div className="custom-login-debug-sessions-content">
      <Panel>
        <div className="alert alert-warning alert-cookie-warning" role="alert">
          <div className="alert-wrapper">
            <div>
              {t(
                "custom_logins.debug_session_logs.experimental_feature_warning",
              )}
            </div>
          </div>
        </div>
        <br />
        {debug_session.status === SUCCESS && (
          <div className="alert alert-success" role="alert">
            <div className="alert-wrapper">
              <div>{t("custom_logins.debug_session_logs.success_sso")}</div>
            </div>
          </div>
        )}
        {debug_session.status !== SUCCESS && (
          <div className="alert alert-error" role="alert">
            <div className="alert-wrapper">
              <div>{t("custom_logins.debug_session_logs.fail_sso")}</div>
            </div>
          </div>
        )}
        <br />
        <h3>{t("custom_logins.debug_session_logs.logs")}</h3>
        <br />
        {debug_session_logs.map(debug_session_log => {
          const title = t(
            `custom_logins.debug_session_logs.${debug_session_log.step}`,
          );
          const isDone = debug_session_log.status === SUCCESS;
          const defaultActiveItemTitle = isDone ? null : title;
          return (
            <Accordion
              key={debug_session_log}
              defaultActiveItemTitle={defaultActiveItemTitle}
            >
              <Accordion.Item
                title={title}
                showIcon
                isDone={debug_session_log.status === SUCCESS}
              >
                {debug_session_log.context.map((context_item, index) => (
                  <PrettyPrintJson key={index} data={context_item} />
                ))}
              </Accordion.Item>
            </Accordion>
          );
        })}
      </Panel>
    </div>
  </div>
);

DebugSessions.propTypes = {
  debug_session: PropTypes.object.isRequired,
  debug_session_logs: PropTypes.array.isRequired,
};
