import { t } from "@/i18n-js/instance";
import { useAccountSubscriptionApi } from "@/react/hooks/useAccountSubscriptionApi";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Button } from "@circle-react-shared/uikit/Button";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface CancelAtPeriodEndStateProps {
  billingDetails: {
    current_period_end: string;
  };
}

export const CancelAtPeriodEndState = ({
  billingDetails,
}: CancelAtPeriodEndStateProps) => {
  const { current_period_end: currentPeriodEnd } = billingDetails;
  const {
    reactivateMarketingHubSubscription,
    isReactivatingMarketingHubSubscription,
  } = useAccountSubscriptionApi();

  const formattedEndDate = formattedDateTime({
    dateTime: new Date(currentPeriodEnd),
    format: dateFormats.short_month_and_date_with_year,
  });

  const handleReactivate = () => {
    reactivateMarketingHubSubscription();
    //[TODO][MarketingHubBilling][AfterRelease] Add toast notification and refetch instead of reloading
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  if (isReactivatingMarketingHubSubscription) {
    return <Loader variant="fullscreen" center animate />;
  }

  return (
    <div className="bg-primary border-primary my-6 rounded-lg border px-8 py-7">
      <div className="border-primary mb-6 flex justify-between border-b pb-6">
        <div className="flex items-center gap-2">
          <Typography.TitleSm weight="bold" data-testid="plan-name">
            {t("settings.billing.marketing_hub.title")}
          </Typography.TitleSm>
        </div>
      </div>
      <div className="mb-2">
        <Typography.BodySm color="secondary">
          {t(
            "settings.billing.marketing_hub.cancel_at_period_end_state.callout",
            {
              date: formattedEndDate,
            },
          )}
        </Typography.BodySm>
      </div>
      <div className="mb-4">
        <Typography.BodySm color="secondary">
          {t("settings.billing.marketing_hub.cancel_at_period_end_state.text")}
        </Typography.BodySm>
      </div>
      <div>
        <Button onClick={handleReactivate} variant="secondary">
          <Typography.LabelSm weight="medium">
            {t(
              "settings.billing.marketing_hub.cancel_at_period_end_state.button",
            )}
          </Typography.LabelSm>
        </Button>
      </div>
    </div>
  );
};
