import type { RerunFormData } from "@circle-workflows/hooks/useRerunWorkflow";
import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { RerunBody } from "../RerunContent/RerunBody";

interface ModalRerunProps {
  onCancel: () => void;
  onBack?: () => void;
  onSubmit: (data: RerunFormData) => void;
  doNotSkipTasks: string;
}

export const ModalRerun = ({
  doNotSkipTasks,
  onCancel,
  onSubmit,
  onBack,
}: ModalRerunProps) => {
  const defaultValues: any = {
    do_not_skip_tasks: doNotSkipTasks ?? "false",
  };

  return (
    <Modal.Content onClose={onCancel}>
      <Modal.Header>
        <Modal.Title>
          {t("settings.workflows.recurring_schedule_modal.rerun_settings")}
        </Modal.Title>
        <Modal.CloseButton onClick={onCancel} />
        {isFunction(onBack) && (
          <Modal.HeaderActions className="absolute left-0 top-0 ml-6 mt-6">
            <Modal.BackButton onClick={onBack} />
          </Modal.HeaderActions>
        )}
      </Modal.Header>
      <Form defaultValues={defaultValues} onSubmit={onSubmit}>
        <div className="flex flex-col gap-y-8">
          <RerunBody />
          <Modal.Footer>
            <Button variant="circle" full large type="submit">
              {t(`settings.workflows.rerun_modal.continue`)}
            </Button>
          </Modal.Footer>
        </div>
      </Form>
    </Modal.Content>
  );
};
