import { createContext, useContext, useState } from "react";
import type { PropsWithChildren } from "react";
import type { Dispatch, SetStateAction } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import type { IconType } from "@circle-react-shared/Icon/IconNames";
import { NOT_FOUND } from "./constants";

export interface SpaceOption {
  id: number;
  name: string;
  icon?: IconType;
  tooltipText?: string;
  topicsCount?: number;
}
export interface SpaceGroupOption extends SpaceOption {
  spaces: SpaceOption[];
}

export interface SpaceAccessListContextProp {
  options: SpaceGroupOption[];
  parentFieldName: string;
  childFieldName: string;
  fieldArrayFields: {
    spaceGroups: ReturnType<typeof useFieldArray> | Record<string, never>;
    spaces: ReturnType<typeof useFieldArray> | Record<string, never>;
  };
  addSpaceGroups: (spaceGroupId: number | Array<number>) => void;
  addSpaces: (spaceId: number | Array<number>) => void;
  removeSpaceGroup: (spaceGroupId: number) => void;
  removeSpaces: (spaceIds: Array<number>) => void;
  spaceGroupsSelected: Array<number>;
  spacesSelected: Array<number>;
  isSpaceGroupSelected: (spaceGroupId: number) => boolean;
  isSpaceSelected: (spaceId: number) => boolean;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  getIsFromSearch: (spaceName: string) => boolean;
  getHasAnySearchResults: () => boolean;
  hasSpaceGroupUnselectedSpaces: (spaceGroup: SpaceGroupOption) => boolean;
  notRemovableParentItemTooltip?: string;
  notRemovableParentIds?: Array<number>;
  notRemovableChildItemTooltip?: string;
  notRemovableChildIds?: Array<number>;
  isSpaceGroupRemovable: (spaceGroupId: number) => boolean;
  isSpaceRemovable: (spaceId: number) => boolean;
  areAllSpacesAndSpaceGroupsRemovable: () => boolean;
}

const SpaceAccessManagementContext =
  createContext<SpaceAccessListContextProp | null>(null);

SpaceAccessManagementContext.displayName = "SpaceAccessManagementContext";

export interface SpaceAccessManagementContextProviderProp {
  options: SpaceGroupOption[];
  parentFieldName?: string;
  childFieldName?: string;
  notRemovableParentItemTooltip?: string;
  notRemovableParentIds?: Array<number>;
  notRemovableChildItemTooltip?: string;
  notRemovableChildIds?: Array<number>;
}

export const SpaceAccessManagementContextProvider = ({
  options,
  children,
  parentFieldName = "space_group_ids",
  childFieldName = "space_ids",
  notRemovableParentItemTooltip,
  notRemovableParentIds,
  notRemovableChildItemTooltip,
  notRemovableChildIds,
}: PropsWithChildren<SpaceAccessManagementContextProviderProp>) => {
  const { watch, setValue } = useFormContext();
  const fieldArrayFields = {
    spaceGroups: useFieldArray({
      name: parentFieldName,
      shouldUnregister: false,
    }),
    spaces: useFieldArray({ name: childFieldName, shouldUnregister: false }),
  };
  const [searchText, setSearchText] = useState("");

  const spaceGroupsSelected = watch(parentFieldName);
  const spacesSelected = watch(childFieldName);

  const removeSpaceGroup = (spaceGroupId: number) => {
    const index = spaceGroupsSelected.findIndex(
      (spaceGroupIdSelected: any) => spaceGroupIdSelected === spaceGroupId,
    );

    if (index !== NOT_FOUND) {
      fieldArrayFields.spaceGroups.remove(index);
    }
  };

  const removeSpaces = (spaceIds: Array<number>) => {
    const spacesFiltered = spacesSelected.filter(
      (spaceId: any) => !spaceIds.includes(spaceId),
    );
    setValue(childFieldName, spacesFiltered);
  };

  const addSpaceGroups = (spaceGroupId: number | Array<number>) => {
    fieldArrayFields.spaceGroups.append(spaceGroupId);
  };

  const addSpaces = (spaceId: number | Array<number>) => {
    fieldArrayFields.spaces.append(spaceId);
  };

  const isSpaceGroupSelected = (spaceGroupId: number) =>
    spaceGroupsSelected.includes(spaceGroupId);
  const isSpaceSelected = (spaceId: number) => spacesSelected.includes(spaceId);

  const getIsFromSearch = (name: string) =>
    name.trim().toLowerCase().search(searchText.trim().toLowerCase()) !==
    NOT_FOUND;

  const getHasAnySearchResults = () =>
    options.some(
      spaceGroup =>
        getIsFromSearch(spaceGroup.name) ||
        spaceGroup.spaces.some(space => getIsFromSearch(space.name)),
    );

  const hasSpaceGroupUnselectedSpaces = (spaceGroup: SpaceGroupOption) =>
    spaceGroup.spaces.some(space => !spacesSelected.includes(space.id));

  const isSpaceGroupRemovable = (spaceGroupId: number) =>
    !notRemovableParentIds ||
    notRemovableParentIds.indexOf(spaceGroupId) === NOT_FOUND;
  const isSpaceRemovable = (spaceId: number) =>
    !notRemovableChildIds ||
    notRemovableChildIds.indexOf(spaceId) === NOT_FOUND;
  const areAllSpacesAndSpaceGroupsRemovable = () =>
    (!notRemovableParentIds && !notRemovableChildIds) ||
    (notRemovableParentIds?.length === 0 && notRemovableChildIds?.length === 0);

  const valueToStore = {
    options,
    parentFieldName,
    childFieldName,
    fieldArrayFields,
    spaceGroupsSelected,
    spacesSelected,
    addSpaceGroups,
    addSpaces,
    removeSpaceGroup,
    removeSpaces,
    isSpaceGroupSelected,
    isSpaceSelected,
    searchText,
    setSearchText,
    getIsFromSearch,
    getHasAnySearchResults,
    hasSpaceGroupUnselectedSpaces,
    notRemovableParentItemTooltip,
    notRemovableParentIds,
    notRemovableChildItemTooltip,
    notRemovableChildIds,
    isSpaceGroupRemovable,
    isSpaceRemovable,
    areAllSpacesAndSpaceGroupsRemovable,
  };

  return (
    <SpaceAccessManagementContext.Provider value={valueToStore}>
      {children}
    </SpaceAccessManagementContext.Provider>
  );
};

export const useSpaceAccessManagementContext = () => {
  const context = useContext(SpaceAccessManagementContext);

  if (!context) {
    throw new Error(
      "useSpaceManagementContext must be used within a SpaceManagementContextProvider",
    );
  }
  return context;
};
