import { NavLink } from "react-router-dom";
import { getRouteForResultType } from "@/react/components/HeaderV2/Search/utils";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-uikit/Typography";
import { AuthorInfo } from "./AuthorInfo";

export const Comment = ({ item }: { item: any }) => {
  const path = getRouteForResultType(item);
  const body = item.highlighted_body || item.body;
  return (
    <NavLink
      to={path}
      className="flex max-w-full items-center gap-8 pr-6"
      data-testid="comment-result"
    >
      <div className="flex w-full flex-col gap-3">
        <AuthorInfo
          name={item.user_name}
          postName={item.post_name}
          createdAt={item.created_at}
          avatarUrl={item.user_avatar_url}
          userImageSize="8"
          flaggedForApprovalAt={item.flagged_for_approval_at}
        />
        <div className="[&_mark]:!bg-search-yellow line-clamp-4">
          <Typography.BodySm color="text-default">
            <SanitizeHTMLString content={body} />
          </Typography.BodySm>
        </div>
      </div>
    </NavLink>
  );
};
