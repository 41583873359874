import { isEventsSpace } from "@/react/helpers/spaceHelpers";
import { useHasSpaceRightSidebar } from "@circle-react/components/Spaces/hooks/useHasSpaceRightSidebar";
import { useCurrentSpaceContext } from "@circle-react/contexts";

export const useHasPostRightSidebar = () => {
  const { data: space } = useCurrentSpaceContext();
  const hasSpaceRightSidebar = useHasSpaceRightSidebar();

  return hasSpaceRightSidebar || isEventsSpace(space);
};
