import { t } from "@/i18n-js/instance";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import type { Source } from "@circle-react/types/CommunityInbox/Message";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";

interface SourcesProps {
  sources: Source[];
}

export const Sources = ({ sources }: SourcesProps) => {
  const [isOpen, toggleIsOpen] = useBoolean(false);
  const sourcesCount = sources.length;

  if (!isOpen) {
    const sourcesText =
      sourcesCount === 1
        ? t("community_inbox.messages.sources.count_singular")
        : t("community_inbox.messages.sources.count_plural", {
            count: sourcesCount,
          });
    return (
      <Button
        variant="secondary-light"
        type="button"
        onClick={toggleIsOpen}
        small
      >
        <span className="flex items-center gap-2">
          <Icon type="16-ai-sources" size={16} />
          {sourcesText}
        </span>
      </Button>
    );
  }

  return (
    <div className="flex flex-col items-start gap-3">
      <div className="flex flex-col gap-2">
        {sources.map(source => (
          <a
            key={`source-${source.url}`}
            href={source.url}
            target="_blank"
            rel="noreferrer"
            className="text-default hover:text-default text-sm font-normal underline"
          >
            {source.title || source.url}
          </a>
        ))}
      </div>
      <Button
        variant="secondary-light"
        type="button"
        onClick={toggleIsOpen}
        small
      >
        <span className="flex items-center gap-2">
          <Icon type="16-ai-sources" size={16} />
          {t("community_inbox.messages.sources.hide")}
        </span>
      </Button>
    </div>
  );
};
