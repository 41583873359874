import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const AiInboxTitle = () => (
  <div className="border-primary flex h-14 items-center border-b px-6 py-2">
    <Typography.TitleSm weight="semibold">
      {t("settings_nav_v3.community_inbox.label")}
    </Typography.TitleSm>
  </div>
);
