import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { PaymentMethodType } from "@/react/helpers/paywallCheckoutHelpers";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";

const defaultHandler = (event: any) => {
  const defaultMessage = "Failed to Render PE";
  console.error(defaultMessage, event);
  notifyBugsnag(new Error(event?.error?.message ?? defaultMessage));
};

const FILTERABLE_PAYMENT_METHODS = [
  PaymentMethodType.LINK,
  PaymentMethodType.KLARNA,
  PaymentMethodType.AFFIRM,
  PaymentMethodType.AFTERPAY_CLEARPAY,
];

export const useHandleFailedPaymentElementRender = () => {
  const { disabledPaymentMethods, setDisabledPaymentMethods } =
    usePaywallCheckoutContext();

  const handleDisablePaymentMethod = (method: string) => {
    const newDisabledMethods = [...disabledPaymentMethods, method];
    setDisabledPaymentMethods(newDisabledMethods);
  };

  return (failedEvent: any) => {
    if (failedEvent.error?.param === "deferred_intent.payment_method_types") {
      const errorMessage = failedEvent.error?.message?.toLowerCase() || "";

      const failedPaymentMethod = FILTERABLE_PAYMENT_METHODS.find(method =>
        errorMessage.includes(method.toLowerCase()),
      );
      if (failedPaymentMethod) {
        return handleDisablePaymentMethod(failedPaymentMethod);
      }
    }
    defaultHandler(failedEvent);
  };
};
