import { useRouteMatch } from "react-router-dom";
import { useOnboarding } from "@/react/components/CommunityOnboarding/useOnboarding";
import { useHideOnboardingConfirmationModal } from "@/react/components/Modals/HideOnboardingConfirmationModal";
import { useSpotlightSearch } from "@/react/components/Modals/SpotlightSearch";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { useNewSpaceGroupModal } from "@circle-react/components/Modals/NewSpaceGroupModal";
import { usePunditUserContext } from "@circle-react/contexts";
import { communitiesPaths } from "@circle-react/helpers/routerPathHelpers";

export const usePlatformMenu = () => {
  const { isOnboardingAvailable } = useOnboarding();
  const { currentCommunity, currentCommunityMember, currentCommunitySettings } =
    usePunditUserContext();

  const spaceGroupModal = useNewSpaceGroupModal();
  const toggleSpaceGroupDrawer = (position = 1) => {
    void spaceGroupModal.show({ position });
  };

  const isOnboardingActive = Boolean(
    useRouteMatch(communitiesPaths.onboarding()),
  );
  const hideOnboardingConfirmationModal = useHideOnboardingConfirmationModal();
  const isAdmin = Boolean(isCommunityAdmin(currentCommunityMember));

  const shouldShowOnboarding = Boolean(isAdmin && isOnboardingAvailable);
  const spotlightSearch = useSpotlightSearch();

  const showSpotlightSearch = () => {
    void spotlightSearch.show();
  };

  const shouldShowAllEventsPage = Boolean(
    isAdmin || currentCommunitySettings?.public_events_page,
  );

  return {
    shouldShowOnboarding,
    isOnboardingActive,
    hideOnboardingConfirmationModal,
    currentCommunity,
    currentCommunitySettings,
    currentCommunityMember,
    isAdmin,
    spotlightSearch,
    toggleSpaceGroupDrawer,
    showSpotlightSearch,
    shouldShowAllEventsPage,
  };
};
