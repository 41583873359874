import { useThemeObject } from "@circle-react/providers";
import { LockscreenCanvas } from "@circle-react-shared/CanvasPreviews";
import { useFormBlocks } from "../Providers";

export const WebPreview = ({ space = {} }: any) => {
  const theme = useThemeObject();
  const { blocks } = useFormBlocks();
  const { emoji = "", name = "" } = space;

  return (
    <LockscreenCanvas
      theme={theme}
      headerSpaceEmoji={emoji}
      headerSpaceTitle={name}
      blocks={blocks}
      screenSize="desktop"
    />
  );
};
