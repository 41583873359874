import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { ArchiveAccessGroup } from "./ArchiveAccessGroup";
import { ArchiveAccessGroupWithRunningEntryPoints } from "./ArchiveAccessGroupWithRunningEntryPoints";

interface ArchiveAccessGroupModalContentType {
  accessGroup: AccessGroupType;
}

export const ArchiveAccessGroupModalContent = ({
  accessGroup,
}: ArchiveAccessGroupModalContentType) => {
  const modal = useModal();
  const areRunningAccessGroups =
    accessGroup.running_sources && accessGroup.running_sources.length > 0;

  if (areRunningAccessGroups) {
    return (
      <ArchiveAccessGroupWithRunningEntryPoints
        accessGroup={accessGroup}
        isOpen={modal.visible}
        onClose={modal.hide}
      />
    );
  }

  return (
    <ArchiveAccessGroup
      accessGroup={accessGroup}
      isOpen={modal.visible}
      onClose={modal.hide}
    />
  );
};

const ArchiveAccessGroupModal = ModalManager.create(
  ({ accessGroup }: ArchiveAccessGroupModalContentType) => (
    <ArchiveAccessGroupModalContent accessGroup={accessGroup} />
  ),
);

export const useArchiveAccessGroupModal = () =>
  useModal(ArchiveAccessGroupModal);
