import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { Typography } from "@circle-react-uikit/Typography";
import { useSupportWidgetContext } from "../SupportWidgetContext";

export const SalesAgentButton = () => {
  const { toggleWidget } = useSupportWidgetContext();
  const { currentUser } = usePunditUserContext();
  const handleOnClick = () => {
    if (typeof window.drift === "undefined") {
      return;
    }

    toggleWidget();
    window.drift.load("fbn954vvh72x");
    window.drift.on("ready", function () {
      window.drift.config({
        verticalOffset: 4,
        horizontalOffset: 18,
      });
      window.drift.identify(currentUser.id, {
        name: currentUser.name,
        email: currentUser.email,
      });
      window.drift.api.startInteraction({ interactionId: 361881 });
    });
  };

  window.drift.on("chatClose", function () {
    window.drift.api.widget.hide();
    window.drift.unload();
    toggleWidget();
  });

  return (
    <button
      type="submit"
      onClick={handleOnClick}
      className="border-primary bg-primary mb-4 block w-full rounded-lg border pt-4 text-left"
    >
      <div className="mb-4 ml-6">
        <Typography.LabelMd weight="semibold">
          {t("support_widget.talk_to_agent")}
        </Typography.LabelMd>
      </div>
    </button>
  );
};
