import { t } from "@/i18n-js/instance";
import { SearchResultsContainer } from "@/react/components/search/SearchResultsContainer";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const EmptySearch = () => (
  <SearchResultsContainer>
    <div
      className="m-auto flex max-w-md flex-col items-center justify-center gap-4 p-8 pb-14"
      data-testid="empty-search"
    >
      <Icon type="24-search-v2" className="text-default !h-6 !w-6" size={24} />
      <div className="flex flex-col gap-2">
        <Typography.TitleSm weight="semibold">
          {t("search_v2.search_community")}
        </Typography.TitleSm>
        <Typography.BodySm color="text-default">
          {t("search_v2.search_community_description")}
        </Typography.BodySm>
      </div>
    </div>
  </SearchResultsContainer>
);
