import { useCohostsList } from ".";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { liveStreamApi } from "@circle-react/api";
import { useLiveStreamContextActions } from "@circle-react/contexts/LiveStreams";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useProviderRoleChange } from "./provider";

export const useRemoveParticipantAsCohost = (peerId, roomId, participantId) => {
  const { success, error } = useToast();
  const { requestRoleChange } = useProviderRoleChange();
  const { refetchParticipants } = useLiveStreamContextActions();
  const { refetchCohosts } = useCohostsList();
  const removeCohostMutation = useMutation(
    () =>
      liveStreamApi.changeRole(
        {
          roomId,
          participantId,
          role: PARTICIPANT_ROLES.HLS_SPECTATOR,
        },
        {
          forReactQuery: true,
        },
      ),
    {
      onSuccess: () => {
        requestRoleChange(peerId, PARTICIPANT_ROLES.HLS_SPECTATOR, true);
        refetchParticipants();
        refetchCohosts();
        success(t("live_streams.room.participant_removed_as_cohost_message"), {
          duration: "short",
          shouldUseProgress: false,
        });
      },
      onError: () =>
        error(t("live_streams.room.participant_removed_as_cohost_error")),
    },
  );

  return removeCohostMutation.mutate;
};
