import { t } from "@/i18n-js/instance";

const style = { zIndex: 1000 };

export interface CookiesDisabledProps {
  isThirdParty?: boolean;
}

export const CookiesDisabled = ({
  isThirdParty = false,
}: CookiesDisabledProps) => {
  const warningMessage = isThirdParty
    ? t("cookie_warning.third_party_cookies_disabled")
    : t("cookie_warning.cookies_disabled");

  const linkText = isThirdParty
    ? t("cookie_warning.how_enable_third_party_cookies")
    : t("cookie_warning.how_enable_cookies");

  return (
    <div className="h-full w-full" style={style}>
      <div className="alert alert-warning alert-cookie-warning" role="alert">
        <div className="alert-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            aria-hidden
            className="alert-icon flex-no-shrink fill-current"
          >
            <path d="M2 2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v18l-8-4-8 4V2z" />
          </svg>
          <div>
            <h4>{warningMessage}</h4>
            <p>
              <a
                href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/auto"
                target="_parent"
              >
                {linkText}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
