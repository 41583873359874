import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

const localeRoot = "settings.paywalls_admin_dashboard.filters.empty_page";

export const EmptyPage = () => {
  const title = t([localeRoot, "title"]);
  const subtitle = t([localeRoot, "subtitle"]);

  return (
    <div className="text-dark max-w-8xl mx-auto p-4 lg:px-6 lg:py-4">
      <div className="bg-primary border-primary border p-6 text-center lg:rounded-lg lg:p-36">
        <Typography.TitleSm weight="bold">{title}</Typography.TitleSm>
        <div className="mt-3">
          <Typography.BodyMd color="text-default">{subtitle}</Typography.BodyMd>
        </div>
      </div>
    </div>
  );
};
