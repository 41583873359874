import { t } from "@/i18n-js/instance";
import { DateFilterComponent } from "./components/DateFilterComponent";

const localeRoot = "settings.paywalls_admin_dashboard.filters.date";

export const defaultQueryParams = {
  default: "created_at",
  lte: "created_at_lte",
  gte: "created_at_gte",
};

export const DateFilter = (props: any) => (
  <DateFilterComponent
    {...props}
    queryParamsKey={defaultQueryParams}
    localeRoot={localeRoot}
  />
);

export const filterProperties = {
  name: "created_at",
  filterKeys: Object.values(defaultQueryParams),
  component: DateFilter,
  label: t([localeRoot, "chip"]),
};
