import { t } from "@/i18n-js/instance";
import { AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../../types";
import type { HistoryDetailViewMemberProps } from "./HistoryDetailViewMember";
import { HistoryDetailViewMember } from "./HistoryDetailViewMember";

export const disableMessaging: Readonly<
  ActionOption<HistoryDetailViewMemberProps>
> = {
  label: t("settings.workflows.form.actions.disable_messaging_on_member"),
  value: "disable_messaging_on_member",
  group: t("settings.workflows.form.action_groups.members"),
  leftAddon: <Icon type="16-disable-DM" size={16} className="!h-4 !w-4" />,
  formComponent: null,
  viewComponent: null,
  historyDetailViewComponent: HistoryDetailViewMember,
  icon: "chat",
  isApplicableToMembersOnly: true,
  availablePlanTier:
    AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS.HIGHER_TIER_PLAN_ONLY,
  disablable: true,
  disableToolTipText: t(
    "workflows_v2.action_selector.enable_dm_for_action_tooltip",
  ),
} as const;
