import { SpaceEmoji } from "@circle-react/components/Spaces/SpaceEmoji";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useActiveSpaceMembersCount } from "@circle-react/contexts/useActiveSpaceMembersCount";
import type { Space } from "@circle-react/types";
import { SpaceHeaderTitleText } from "./SpaceHeaderTitleText";

interface SpaceTitleWithEmojiProps {
  space: Space;
  shouldShowMembersCount?: boolean;
}

export const SpaceTitleWithEmoji = ({
  space,
  shouldShowMembersCount = false,
}: SpaceTitleWithEmojiProps) => {
  const { count, isLoading } = useActiveSpaceMembersCount({
    spaceId: space.id,
    enabled: shouldShowMembersCount,
  });

  return (
    <div className="flex items-center gap-2 truncate">
      <SpaceEmoji className="justify-center text-sm" space={space} />
      <div className="flex truncate">
        <SpaceHeaderTitleText truncate>{space.name}</SpaceHeaderTitleText>
        {shouldShowMembersCount && (
          <div className="ml-1">
            <Typography.BodyLg weight="semibold" tracking="tracking-normal">
              {!isLoading && `(${count})`}
            </Typography.BodyLg>
          </div>
        )}
      </div>
    </div>
  );
};
