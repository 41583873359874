import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-shared/uikit/Button";
import { ButtonWithDisabled } from "@circle-react-uikit/ButtonWithDisabled";
import { Modal } from "@circle-react-uikit/ModalV2";

export interface BookmarkFormFooterProps {
  isSubmitting: boolean;
  onClickCancel: () => void;
}

export const BookmarkFormFooter = ({
  isSubmitting = false,
  onClickCancel = () => {},
}: BookmarkFormFooterProps) => (
  <Modal.Footer className="flex justify-end gap-2 !px-0">
    <Button type="button" variant="secondary" large onClick={onClickCancel}>
      {t("back")}
    </Button>
    <ButtonWithDisabled
      type="submit"
      form="bookmark-form"
      variant="circle"
      large
      submitting={isSubmitting}
    >
      {t("save")}
    </ButtonWithDisabled>
  </Modal.Footer>
);
