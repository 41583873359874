import { t } from "@/i18n-js/instance";
import { SpaceListButton } from "@/react/components/Spaces/SidebarV2/CommunityMenu/SpaceList/SpaceListButton";
import type { SpaceListButtonProps } from "@/react/components/Spaces/SidebarV2/CommunityMenu/SpaceList/SpaceListButton";
import { Icon } from "@circle-react-shared/Icon";

export const AddSpaceButtonView = (props: SpaceListButtonProps) => (
  <SpaceListButton {...props}>
    <Icon size={16} type="16-add-new" />
    <span data-testid="add-space" className="mx-2">
      {t("add_space")}
    </span>
  </SpaceListButton>
);
