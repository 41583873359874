import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { useModalData } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

export const SpaceGroupModerators = () => {
  const { contentPortalElement } = useModalData();

  return (
    <div className="mt-8">
      <Typography.TitleSm weight="semibold">
        {t("space_groups.form.moderators")}
      </Typography.TitleSm>
      <Form.Item
        translationRoot="space_groups.form.edit"
        name="moderator_community_member_ids"
        tooltipText={t(
          "space_groups.form.edit.moderator_community_member_ids_tooltip",
        )}
        hideDescription
        hideBorder
        fullWidth
      >
        <Form.CommunityMemberSelect
          name="moderator_community_member_ids"
          variant="tags"
          excludeAdmins
          appendTo={contentPortalElement}
        />
      </Form.Item>
    </div>
  );
};

SpaceGroupModerators.propTypes = {
  moderators: PropTypes.array,
};
