import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { OpenQPModal } from "@/react/components/QuickPost/OpenQPModal";
import { useConfirmModal } from "@circle-react/components/LiveStreams/Room/hooks";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import { Tippy } from "@circle-react-shared/Tippy";
import { IconDropdownMenu } from "@circle-react-uikit/IconDropdownMenu";
import { VideoPlayer } from "@circle-react-uikit/VideoPlayer";
import { useDeleteRecording } from "../../../Actions/hooks/useDeleteRecording";
import { usePostBodyWithRecording } from "../../../Actions/hooks/usePostBodyWithRecording";

const DropdownButton = () => (
  <Tippy content={t("live_streams.dashboard.details.actions")}>
    <Icon type="menu-dots-vertical" />
  </Tippy>
);

export const Recording = ({ room, refetch }) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const { deleteRecording } = useDeleteRecording(room, refetch);
  const { trixBodyWithRecording, tiptapBodyWithRecording } =
    usePostBodyWithRecording({ room });
  const {
    confirmModal: deleteRecordingModal,
    onConfirmButtonClick: onDeleteRecordingButtonClick,
  } = useConfirmModal({
    title: t("live_streams.delete_recording.confirm"),
    onConfirm: deleteRecording,
    description: t("live_streams.delete_recording.confirm_description"),
    maxIndex: true,
  });
  const menuItems = [
    {
      key: "download",
      label: t("live_streams.dashboard.details.download"),
      href: room.recording_url,
    },
    {
      key: "delete",
      label: t("live_streams.dashboard.details.delete"),
      onClick: onDeleteRecordingButtonClick,
    },
    {
      key: "post-to-space",
      linkComponent: (
        <OpenQPModal
          hasIcon={false}
          isButton={false}
          linkName={t("live_streams.dashboard.table.actions.post_to_space")}
          bodyForEditor={trixBodyWithRecording}
          tiptapBodyForEditor={tiptapBodyWithRecording}
          title={room.name}
          maxZIndexEnabled
        />
      ),
      label: t("live_streams.dashboard.table.actions.post_to_space"),
    },
  ];
  if (room.transcript_url) {
    const onDownloadTranscriptClick = () => {
      window.open(room.transcript_url, "_blank");
    };
    menuItems.splice(1, 0, {
      key: "download-transcript",
      label: t("live_streams.dashboard.details.download_transcript"),
      onClick: onDownloadTranscriptClick,
    });
  }

  return (
    <div>
      <VideoPlayer
        rounded
        src={room.recording_url}
        type="video/mp4"
        mediaTranscriptId={room.transcript_id}
        variant={
          currentCommunitySettings?.community_ai_settings_enabled
            ? "enhanced"
            : "default"
        }
      />
      <div className="flex items-center justify-between">
        <div className="text-dark mb-1 mt-4 text-lg font-semibold">
          {room.recording_name}
        </div>
        {deleteRecordingModal}
        <IconDropdownMenu
          renderButton={() => <DropdownButton />}
          menuItems={menuItems}
          dropdownSide="left"
          shouldCloseOnClickItem
        />
      </div>
    </div>
  );
};

export const RecordingDuration = ({ recording_duration }) => (
  <div className="mb-6">
    <div className="stream-details__details__label">
      {t("live_streams.dashboard.details.duration")}
    </div>
    <p>{recording_duration}</p>
  </div>
);

Recording.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    created_at: PropTypes.string,
    recording_url: PropTypes.string,
    recording_name: PropTypes.string,
    recording_duration: PropTypes.string,
  }),
  refetch: PropTypes.func.isRequired,
};

RecordingDuration.propTypes = {
  recording_duration: PropTypes.string,
};
