import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";

export const SearchBar = ({
  searchText,
  setSearchText,
  initialFocus = true,
  ...props
}) => {
  const inputRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (initialFocus) {
      inputRef.current?.focus();
    }
  }, [initialFocus, location]);
  return (
    <div className="relative">
      <div className="text-dark pointer-events-none absolute left-0 top-[11px] flex items-center pl-3">
        <Icon type="16-search" size={20} />
      </div>
      <input
        ref={inputRef}
        type="text"
        defaultValue={searchText}
        className="border-primary bg-primary text-default focus:border-light placeholder:text-light block h-10 w-full rounded-md pl-10 placeholder:opacity-100 focus:ring-0 sm:text-sm"
        placeholder={t("support_widget.placeholder_text")}
        onChange={event => {
          setSearchText(event.target.value);
        }}
        {...props}
      />
    </div>
  );
};

SearchBar.propTypes = {
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  initialFocus: PropTypes.bool,
};
