import { useRef } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Container } from "./Container";
import { HightlightedCode } from "./HightlightedCode";
import { useExpandableCode } from "./useExpandableCode";

export interface ExpandableCodeProps {
  code: string;
  className?: string;
}

export const ExpandableCode = ({ code, className }: ExpandableCodeProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isTruncationEnabled, isExpanded, toggleExpand } = useExpandableCode({
    containerRef,
  });

  return (
    <div className={classNames("flex flex-col items-start gap-1", className)}>
      <Container
        ref={containerRef}
        isExpanded={isExpanded}
        isTruncationEnabled={isTruncationEnabled}
      >
        <HightlightedCode>{code}</HightlightedCode>
      </Container>
      {isTruncationEnabled && (
        <div className="px-1">
          <button
            type="submit"
            className="text-default hover:text-dark text-xs transition-colors"
            onClick={toggleExpand}
          >
            {isExpanded ? t("show_less") : t("show_more")}
          </button>
        </div>
      )}
    </div>
  );
};
