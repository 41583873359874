import type { ReactNode } from "react";

export interface SpaceGroupListViewProps {
  children: ReactNode;
}

export const SpaceGroupListView = ({ children }: SpaceGroupListViewProps) => (
  <div className="bg-c-sidebar mt-4 flex flex-col gap-y-6 px-3 lg:mt-6 lg:px-6">
    {children}
  </div>
);
