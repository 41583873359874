import { isActive } from "@circle-react/helpers/paywallHelpers";

export const isWebPaywallPriceValid = (paywall: any) =>
  paywall.id && paywall?.web_paywall_price?.id;

export const isMobilePaywallPriceValid = (paywall: any) =>
  paywall.id && paywall?.mobile_paywall_price?.id;

export const isPaywallValidToRender = (paywall: any, validation: any) =>
  isActive(paywall) && validation(paywall);
