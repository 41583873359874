import { useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { usePaywallGroupApi } from "@circle-react/components/PaywallGroups/Admin/hooks/usePaywallGroupApi";
import { usePricingTabContext } from "./usePricingTabContext";

export const useSubscriptionGroup = currency => {
  const { control } = useFormContext();
  const id = useWatch({ control, name: "id" });
  const areAccessGroupsEnabled = useWatch({
    control,
    name: "access_groups_enabled",
  });
  const { setValue } = useFormContext();
  const { hasSubscriptionPrices } = usePricingTabContext();
  const { useListPaywallGroups } = usePaywallGroupApi({});

  const { data: paywallGroups } = useListPaywallGroups({
    for_access_groups: areAccessGroupsEnabled,
  });

  const hasSubscriptionPricesValue = hasSubscriptionPrices();

  const eligiblePaywallGroups = (paywallGroups || []).filter(
    group => group.currency.id === currency.id,
  );
  const canShowChooser = eligiblePaywallGroups.length > 0 && id;
  const canEnableChooser = hasSubscriptionPricesValue && id;
  const defaultPaywallGroupId =
    eligiblePaywallGroups.length > 0 ? eligiblePaywallGroups[0].id : "";
  const paywallGroupSelectOptions = eligiblePaywallGroups.map(group => ({
    value: `${group.id}`,
    label: group.name,
  }));

  const isEditingPaywall = useWatch({ name: "id" });
  const isCreatingPaywall = !isEditingPaywall;

  const enableSubscriptionGroup = useWatch(
    {
      name: "enable_subscription_group",
    },
    false,
  );
  const enableSubscriptionGroupPreviousState = useRef(enableSubscriptionGroup);

  // If is creating paywall and there are subscription prices and toggle is off, set toggle to on.
  useEffect(() => {
    if (
      isCreatingPaywall &&
      hasSubscriptionPricesValue &&
      !enableSubscriptionGroupPreviousState.current &&
      id
    ) {
      setValue("enable_subscription_group", true);
    }
  }, [
    enableSubscriptionGroupPreviousState,
    hasSubscriptionPricesValue,
    isCreatingPaywall,
    setValue,
    id,
  ]);

  // If there are no subscription prices, disable the toggle and clear the group id.
  useEffect(() => {
    if (!hasSubscriptionPricesValue) {
      setValue("enable_subscription_group", false);
      setValue("paywall_group_id", defaultPaywallGroupId);
    }
  }, [defaultPaywallGroupId, hasSubscriptionPricesValue, setValue]);

  return {
    canShowChooser,
    canEnableChooser,
    enableSubscriptionGroup,
    paywallGroupSelectOptions,
  };
};
