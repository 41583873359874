import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Button } from "@circle-react-uikit/Button";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { usePayment } from "../hooks/usePayment";
import { CheckoutFormAccountInfo } from "./CheckoutFormAccountInfo";
import { CheckoutFormAlerts } from "./CheckoutFormAlerts";
import { CheckoutFormBillingInfoFields } from "./CheckoutFormBillingInfoFields";
import { CheckoutFormCouponField } from "./CheckoutFormCouponField";
import { CheckoutFormSection } from "./CheckoutFormSection";
import { CheckoutFormTOS } from "./CheckoutFormTOS";
import { CheckoutFormTaxIdField } from "./CheckoutFormTaxIdField";
import { CheckoutFormTitle } from "./CheckoutFormTitle";
import { CheckoutPreview } from "./CheckoutPreview";
import { PaymentMethodSelectorForm } from "./PaymentMethodSelectorForm";
import { PaymentMethodTypeForm } from "./PaymentMethodTypeForm";

export const CheckoutForm = () => {
  const formMethods = useFormContext();
  const { handleSubmit } = formMethods;
  const { isProcessingPayment } = usePaywallCheckoutContext();

  const {
    handleOnChangeCard,
    handleOnReadyCard,
    onSubmit,
    payButtonLabel,
    stripe,
    isSubmitDisabled,
  } = usePayment();

  return (
    <div className="user-info">
      <div className="paywall-info__content">
        <div data-testid="checkout-form-container">
          {isProcessingPayment && <Loader variant="fullscreen" />}
          <form
            className="flex flex-col"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="checkout-form"
          >
            <CheckoutFormTitle />
            <CheckoutFormAccountInfo />
            <CheckoutFormSection className="gap-y-5">
              <PaymentMethodSelectorForm />
              <PaymentMethodTypeForm
                handleOnReadyCard={handleOnReadyCard}
                handleOnChangeCard={handleOnChangeCard}
              />
              <CheckoutFormBillingInfoFields />
              <CheckoutFormCouponField />
              <CheckoutFormTaxIdField />
            </CheckoutFormSection>
            <CheckoutPreview />
            <CheckoutFormAlerts />
            <CheckoutFormSection className="gap-y-5">
              <CheckoutFormTOS />
              <Button
                type="submit"
                large
                variant="primary"
                disabled={!stripe || isSubmitDisabled}
                data-testid="checkout-form-submit-button"
              >
                {payButtonLabel}
              </Button>
              <div
                className="flex flex-row items-center justify-center"
                data-testid="checkout-form-secure-legend-container"
              >
                <Icon color="text-secondary-50" size={16} type="lock-solid" />
                <Typography.LabelXs color="text-secondary-50">
                  {t("paywall_checkout.payment_secure_legend")}
                </Typography.LabelXs>
              </div>
            </CheckoutFormSection>
          </form>
        </div>
      </div>
    </div>
  );
};
