import { t } from "@/i18n-js/instance";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { useFilterContext } from "@circle-react-shared/Filter/Filter";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";

export const AutoCompleteInput = () => {
  const name = "member-filter";
  const { selectedText, onApply } = useFilterContext();
  const handleApply = (submitData, { reset }) => {
    onApply(submitData[name]);
    reset();
  };
  const primaryButtonVariant = useButtonVariant();

  return (
    <Form defaultValues={{ [name]: +selectedText }} onSubmit={handleApply}>
      <div className="flex flex-col gap-y-4 p-4">
        <Form.CommunityMemberSelect
          name={name}
          includeInactive
          direction="down"
          autoFocus
        />
        <Button variant={primaryButtonVariant} type="submit" full>
          {t("filters.apply")}
        </Button>
      </div>
    </Form>
  );
};

AutoCompleteInput.propTypes = {};
