import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const EmptyChatThreads = () => (
  <div className="bg-primary flex h-full items-center justify-center lg:h-[calc(100vh-64px)]">
    <div className="flex w-full flex-col items-center justify-center space-y-2 md:w-[413px]">
      <div className="p-2">
        <Icon type="32-chat-threads" size={32} />
      </div>
      <Typography.LabelLg weight="semibold">
        {t("chat_threads_empty_page.title")}
      </Typography.LabelLg>
      <Typography.BodyMd textAlign="text-center">
        {t("chat_threads_empty_page.description")}
      </Typography.BodyMd>
    </div>
  </div>
);
