import { useEffect } from "react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useFormContext, useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { CoverImage } from "@/react/components/HomePageV2/Header/EditHome/CoverImage";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useSpaceApi } from "@/react/hooks/useSpaceApi";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { FormTipTapBlockEditor } from "@circle-react-shared/uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import { Toolbar } from "@circle-react-shared/uikit/TipTapBlockEditor/Toolbar";
import { Form } from "@circle-react-uikit/Form";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ToggleSwitchWithLabel } from "@circle-react-uikit/ToggleSwitchWithLabel";
import { checkIfTipTapValueEmpty } from "./helper";

const i18nRoot = "customize_space.welcome_banner";

interface FromComponentProps {
  isLoggedIn: boolean;
  onChange: (value: boolean) => void;
  setDirty: (key: any, value: any) => void;
  spaceId: number;
}

export const FormComponent = ({
  isLoggedIn,
  setDirty,
  onChange,
  spaceId,
}: FromComponentProps) => {
  const toastr = useToast();
  const title = `${isLoggedIn ? "logged_in" : "logged_out"}_title`;
  const coverImage = `${isLoggedIn ? "logged_in" : "logged_out"}_cover`;
  const description = `${isLoggedIn ? "logged_in" : "logged_out"}_description`;

  const { reSetWelcomeBannerPreference } = useSpaceApi();

  const [shouldShowConfirmationModal, toggleShouldShowConfirmationModal] =
    useBoolean(false);

  const {
    formState: { dirtyFields },
    setValue,
    getValues,
  } = useFormContext();

  const loggedInBannerEnabled = useWatch({ name: "logged_in_banner_enabled" });
  const loggedOutBannerEnabled = useWatch({
    name: "logged_out_banner_enabled",
  });

  const isVideo = getValues(
    isLoggedIn ? "logged_in_media_is_video" : "logged_out_media_is_video",
  );
  const tiptapValue = useWatch({ name: description });
  const titleValue = useWatch({ name: title });
  const coverImageValue = useWatch({ name: coverImage });

  const bannerEnabled = isLoggedIn
    ? loggedInBannerEnabled
    : loggedOutBannerEnabled;

  const bannerField = isLoggedIn
    ? "logged_in_banner_enabled"
    : "logged_out_banner_enabled";

  const bannerLabel = isLoggedIn
    ? "enable_for_logged_in_members"
    : "enable_for_logged_out_members";

  useEffect(() => {
    if (title in dirtyFields || description in dirtyFields) {
      onChange(true);
    }
  }, [dirtyFields[title], dirtyFields[description]]);

  useEffect(() => {
    const areAllFieldsEmpty =
      checkIfTipTapValueEmpty(tiptapValue) &&
      isEmpty(titleValue) &&
      isEmpty(coverImageValue);
    if (isLoggedIn) {
      setDirty(
        "loggedIn",
        loggedInBannerEnabled && areAllFieldsEmpty ? true : false,
      );
    } else {
      setDirty(
        "loggedOut",
        loggedOutBannerEnabled && areAllFieldsEmpty ? true : false,
      );
    }
  }, [
    tiptapValue?.body,
    isLoggedIn,
    loggedInBannerEnabled,
    loggedOutBannerEnabled,
    titleValue,
    coverImageValue,
  ]);

  return (
    <>
      <ToggleSwitchWithLabel
        label={t([i18nRoot, bannerLabel])}
        field={bannerField}
        value={bannerEnabled}
        onChange={() => {
          setValue(bannerField, !bannerEnabled);
        }}
        className="mb-2 w-full text-lg font-bold"
        variant="large"
      />
      <div
        className={classNames("mx-auto mt-4 flex h-full flex-col", {
          "cursor-not-allowed opacity-20": !bannerEnabled,
        })}
      >
        <CoverImage
          title={t([i18nRoot, "cover_image"])}
          name={coverImage}
          description={t([i18nRoot, "cover_image_description"])}
          aspectRatio={ASPECT_RATIO.OPEN_GRAPH}
          disabled={!bannerEnabled}
          removeAttachmentsPath={internalApi.spaces.destroy_welcome_banner_cover(
            {
              id: spaceId,
            },
          )}
          removeAttachmentsParams={{
            banner_type: isLoggedIn
              ? "logged_in_space_welcome_banner"
              : "logged_out_space_welcome_banner",
          }}
          isVideo={isVideo}
          isSpaceWelcomeBanner
        />
        <div className="-mt-1">
          <Form.Item
            name={title}
            hideDescription
            translationRoot={i18nRoot}
            variant="extra-bottom-margin"
            hideBorder
            fullWidth
            hidePlaceholder
          >
            <Form.Input disabled={!bannerEnabled} />
          </Form.Item>
        </div>
        <div className="-mt-1">
          <Form.Item
            name={description}
            hideDescription
            translationRoot={i18nRoot}
            variant="extra-bottom-margin"
            hideBorder
            fullWidth
            hidePlaceholder
          >
            <div className="border-primary active:border-darkest focus-within:border-darkest h-full max-h-[400px] overflow-y-auto rounded-lg border px-4 py-3">
              <FormTipTapBlockEditor
                placeholder={t(
                  "customize_space.welcome_banner.write_a_description",
                )}
                name={`${description}.body`}
                toolbars={[
                  {
                    portalId: "welcome-banner-description-toolbar",
                    component: Toolbar,
                  },
                ]}
                className="max-h-[350px] min-h-[100px] overflow-y-auto"
                editorClassName="h-full"
                sgidToObjectMap={tiptapValue?.sgids_to_object_map || {}}
                disabledExtensions={["poll", "ai_copilot", "mention", "cta"]}
              />
              <div id="welcome-banner-description-toolbar" />
            </div>
          </Form.Item>
        </div>
        {isLoggedIn && (
          <div className="flex flex-col">
            <Form.Item
              name="allow_close"
              description={
                <div className="pr-8">
                  {t([i18nRoot, "allow_close_description"])}
                  <button
                    type="button"
                    onClick={() => {
                      bannerEnabled && toggleShouldShowConfirmationModal();
                    }}
                    className="ml-1 cursor-pointer text-sm underline hover:opacity-80"
                  >
                    {t([i18nRoot, "reset_preference_title"])}
                  </button>
                </div>
              }
              translationRoot={i18nRoot}
              variant="default"
              inlineReverse
              hideBorder
              fullWidth
              hidePlaceholder
              className="!items-start"
            >
              <Form.ToggleSwitch
                name="allow_close"
                variant="small"
                wrapperClass="!mt-1"
              />
            </Form.Item>
          </div>
        )}
        <ConfirmationModal
          isOpen={shouldShowConfirmationModal}
          onClose={toggleShouldShowConfirmationModal}
          onConfirm={async () => {
            await reSetWelcomeBannerPreference(spaceId);
            toggleShouldShowConfirmationModal();
            toastr.success(
              t("space_welcome_banner.preferences_update_success"),
            );
          }}
          confirmText={t("confirm")}
          title={t([i18nRoot, "reset_preference_title"])}
        >
          <div className="mb-6">
            {t([i18nRoot, "reset_preference_description"])}
          </div>
        </ConfirmationModal>
      </div>
    </>
  );
};
