import type { Message } from "@circle-react/types/CommunityInbox/Message";
import { Icon } from "@circle-react-shared/Icon";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { ThinkingAvatar } from "./ThinkingAvatar";

interface AvatarProps {
  message: Message;
}

export const Avatar = ({ message }: AvatarProps) => {
  const {
    author_type: authorType,
    community_bot_profile: communityBotProfile,
    community_member: communityMember,
    is_processing: isProcessing,
  } = message;

  const isUserMessage = authorType === "community_member";
  const authorName = isUserMessage
    ? communityMember?.name || ""
    : communityBotProfile?.name || "";
  const authorAvatar = isUserMessage
    ? communityMember?.avatar_url || ""
    : communityBotProfile?.avatar_url || "";

  const shouldShowDefaultBotAvatar =
    !isUserMessage && !communityBotProfile?.avatar_url;

  if (isProcessing) {
    return <ThinkingAvatar name={authorName} />;
  }
  if (shouldShowDefaultBotAvatar) {
    return <Icon type="36-ai-bot" size={36} className="!h-[36px] !w-[36px]" />;
  }
  return (
    <UserImage src={authorAvatar} name={authorName} size="9" isFullRounded />
  );
};
