import { t } from "@/i18n-js/instance";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { Chip } from "@circle-react-uikit/Chip";
import { useAttendeesPreview } from "../../AttendeesPreview/hooks/useAttendeesPreview";
import { EventTimeChip } from "../ListViewItem/EventTimeChip";
import { EventLocationChip } from "./EventLocationChip";

export const EventInfoChips = ({ event, shouldHideLocation = false }: any) => {
  const {
    status,
    hide_meta_info: hideMetaInfo,
    event_setting_attributes: eventData,
    community_member: communityMember,
    event_attendees: { count: attendeesCount } = { count: 0 },
    paywall_attributes: ticketData,
  } = event;

  const {
    starts_at: startsAt,
    ends_at: endsAt,
    hide_attendees: hideAttendees,
  } = eventData || {};

  const hostName = communityMember.name;

  const { handleAttendeesClick } = useAttendeesPreview(event) || {};

  const shouldShowAttendees =
    !isPaidEvent(event) && !hideAttendees && attendeesCount > 0;

  return (
    <div className="mt-6 flex flex-wrap gap-2 pb-2 md:pb-0">
      <EventTimeChip startsAt={startsAt} endsAt={endsAt} status={status} />
      {!shouldHideLocation && <EventLocationChip eventData={eventData} />}
      {!hideMetaInfo && <Chip title={hostName} icon="16-user" />}
      {shouldShowAttendees && (
        <Chip
          title={`${String(attendeesCount)} ${t("events.attendees")}`}
          icon="members-v2"
          onClick={handleAttendeesClick}
          isButton
        />
      )}
      {isPaidEvent(event) && (
        <Chip
          title={`${String(ticketData.price_with_code)}`}
          icon="16-event-ticket"
        />
      )}
    </div>
  );
};
