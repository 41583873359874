import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";

export const DefaultSort = ({ defaultValue = "oldest" }) => {
  const localeScope = "members_directory.header.settings.form";
  const options = [
    { value: "alphabetical", label: t("alphabetical") },
    { value: "oldest", label: t("oldest") },
    { value: "latest", label: t("latest") },
  ];
  return (
    <Form.Item
      name="member_directory_settings.sort"
      label={I18n.t(`${localeScope}.sort`)}
      hideDescription
      hideBorder
    >
      <Form.SelectNative options={options} defaultValue={defaultValue} />
    </Form.Item>
  );
};
