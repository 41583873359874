import { useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import liveChatNotificationSound from "@circle-assets/sounds/live_chat_notification_sound.mp3";
import { useWebSocket } from "@circle-react/hooks/useWebSocket";
import { useSupportWidgetContext } from "../SupportWidgetContext";
import { processHandler } from "../chatbot";
import { timestamp, uuid } from "../messageFactory";
import { buildContactSupportMessage } from "../messageFactory";
import {
  useCreateChatMessage,
  useCreateConversation,
  useCreateLiveChatMessage,
  useGetLiveChatStatus,
} from "../queries";
import { autolink } from "../utils";

export const useChat = () => {
  const { liveChatEnabled } = useSupportWidgetContext();
  const { data: liveChatStatus, isLoading: isLiveChatStatusLoading } =
    useGetLiveChatStatus({ enabled: liveChatEnabled });
  const [isLiveChatWithDeflectionEnabled, setIsLiveChatWithDeflectionEnabled] =
    useState(false);
  const displayLiveChat = liveChatStatus?.is_available;

  const history = useHistory();

  const [sessionId, setSessionId] = useState(null);
  const [conversationType, setConversationType] = useState(null);
  const [productArea, setProductArea] = useState(null);
  // When Live Chat feature flag is enabled, we want to initially set bot processing
  // to true, because we will be fetching live chat status via api call
  const [isBotProcessing, setIsBotProcessing] = useState(liveChatEnabled);
  const [isUserInputEnabled, setIsUserInputEnabled] = useState(false);
  const [isLiveChatEnded, setIsLiveChatEnded] = useState(false);
  const [isConversationEnded, setIsConversationEnded] = useState(false);
  const [canRestart, setCanRestart] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [hasPostedLiveMessage, setHasPostedLiveMessage] = useState(false);
  const [currentQueuePosition, setCurrentQueuePosition] = useState(null);
  const liveChatNotificationAudio = new Audio(liveChatNotificationSound);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [hasAgentJoined, setHasAgentJoined] = useState(false);
  liveChatNotificationAudio.oncanplaythrough = () => setIsAudioLoaded(true);

  const addMessage = (type, { ...attributes }) => {
    setMessages(oldMessages => [
      ...oldMessages,
      {
        id: uuid(),
        type,
        createdAt: timestamp(),
        ...attributes,
      },
    ]);
  };

  const { mutate: createSupportWidgetFeedback } = useCreateConversation({
    attachments: [],
    source: "chat",
    onSuccess: () => {
      setIsBotProcessing(false);
      addMessage("bot", {
        content: t("support_widget.chatbot.templates.feedback_received"),
        buttons: [],
      });
      addMessage("bot", {
        content: t("support_widget.chatbot.templates.restart_prompt"),
        buttons: [
          {
            label: t("support_widget.chatbot.answer_yes"),
            event: "restart-chat",
            value: null,
          },
          {
            label: t("support_widget.chatbot.answer_no"),
            event: "complete-chat",
            value: null,
          },
        ],
      });
    },
  });

  const { mutate: createChatMessage } = useCreateChatMessage({
    onSuccess: data => {
      setSessionId(data.result.session_id);
      setIsBotProcessing(false);
      setIsUserInputEnabled(true);
      let content = data.result.answer;
      let buttons = [];
      const sources = content.match(/<sources>(.*)<\/sources>/s);
      if (sources) {
        content = content.replace(sources[0], "");
        const sourcesBody = JSON.parse(sources[1]);
        const list = sourcesBody
          .filter(source => source.url !== "Not Available")
          .map(({ url, title }) => ({
            url: url
              ? url.replace(
                  "https://help-en.circle.so",
                  "https://help.circle.so",
                )
              : null,
            title,
          }))
          .map(
            source =>
              `<li><a target="_blank" href="${source.url}">${source.title}</a></li>`,
          );

        if (list.length > 0) {
          content = content.concat(
            `<h6 class="sources-title">${t(
              "support_widget.chatbot.helpful_resources",
            )}</h6><ul>${list.join("")}</ul>`,
          );
        }
      }

      if (content.match(/<ticket>(.*)<\/ticket>/s)) {
        const message = buildContactSupportMessage({
          displayLiveChat: data?.live_chat?.is_available,
          emailWaitTime: data?.live_chat?.email_wait_time,
          liveChatWaitTime: data?.live_chat?.live_chat_wait_time,
        });

        content = message.content;
        buttons = message.buttons;

        setIsUserInputEnabled(false);
        setIsLiveChatWithDeflectionEnabled(data?.live_chat?.is_available);
      }

      addMessage("bot", {
        content: content,
        buttons: buttons,
        showDisclaimer: true,
      });
    },
  });

  const { mutate: createLiveChatMessage } = useCreateLiveChatMessage({
    onSuccess: data => {
      if (data.result?.error == "chat_closed") {
        const openingTime = data.result?.opening_time;
        const closingTime = data.result?.closing_time;

        setIsUserInputEnabled(false);
        setIsBotProcessing(false);

        addMessage("bot", {
          content: t("support_widget.chatbot.templates.live_chat_closed", {
            openingTime,
            closingTime,
          }),
          buttons: [
            {
              label: t("support_widget.send_a_message"),
              event: "contact-support",
              value: null,
            },
          ],
        });
      } else {
        setSessionId(data.result.session_id);
        if (!hasPostedLiveMessage) {
          setHasPostedLiveMessage(true);
        }
      }
    },
  });

  useWebSocket(
    {
      channel: "SupportWidget::LiveChatChannel",
      onMessageReceive: data => {
        if (data.event === "newMessage") {
          addMessage(data.userType, {
            content: autolink(data.content),
            authorAvatarUrl: data.author.avatarUrl,
            authorName: data.author.name,
            buttons: [],
            attachments: data.attachments,
          });
          data.userType != "user" &&
            isAudioLoaded &&
            liveChatNotificationAudio.play();
        } else if (data.event === "completeChat") {
          setIsLiveChatEnded(true);
          setIsUserInputEnabled(false);
          setIsWaiting(false);
        } else if (data.event === "enterQueue") {
          setCurrentQueuePosition(data.position);
          setIsWaiting(true);
          setCanRestart(false);
          setIsBotProcessing(false);
          addMessage("bot", {
            content: t(
              "support_widget.chatbot.templates.live_chat_first_message_posted",
            ),
            buttons: [],
          });
        } else if (data.event === "updateQueue") {
          setCurrentQueuePosition(data.position);
        } else if (data.event === "agentJoined") {
          addMessage("bot", {
            content: t(
              "support_widget.chatbot.templates.live_chat_agent_joined",
              { agent_first_name: data.agent_first_name },
            ),
            buttons: [],
          });
          isAudioLoaded && liveChatNotificationAudio.play();
          setIsWaiting(false);
          setHasAgentJoined(true);
        }
      },
      chat_bot_session_id: sessionId,
      canCreateConnection:
        (displayLiveChat || isLiveChatWithDeflectionEnabled) && sessionId,
    },
    [],
  );

  const process = processHandler({
    history,
    addMessage,
    setIsUserInputEnabled,
    setConversationType,
    setProductArea,
    conversationType,
    setIsBotProcessing,
    createSupportWidgetFeedback,
    productArea,
    createChatMessage,
    sessionId,
    setSessionId,
    setIsConversationEnded,
    setCanRestart,
    displayLiveChat,
    createLiveChatMessage,
    setIsLiveChatEnded,
    setIsWaiting,
    hasPostedLiveMessage,
    setIsLiveChatWithDeflectionEnabled,
  });

  return {
    sessionId,
    conversationType,
    messages,
    setMessages,
    isBotProcessing,
    isUserInputEnabled,
    isLiveChatEnded,
    isConversationEnded,
    canRestart,
    process,
    isWaiting,
    currentQueuePosition,
    isLiveChatStatusLoading,
    hasPostedLiveMessage,
    hasAgentJoined,
  };
};
