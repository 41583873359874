import { useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "react-query";
import {
  PaymentMethodType,
  isAffirm,
  isAfterpayClearpay,
  isKlarna,
} from "@/react/helpers/paywallCheckoutHelpers";
import type {
  PaymentIntentPrepareResponseEndpoint,
  PrepareRequestEndpoint,
} from "@circle-react/api/paywallCheckoutApi";
import { paywallCheckoutApi } from "@circle-react/api/paywallCheckoutApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { notifyBugsnag } from "@circle-react/helpers/bugsnagHelpers";
import { isSucceeded } from "@circle-react/helpers/paywalls/paymentIntentHelpers";

export const useBuyNowPayLater = () => {
  const stripe = useStripe();
  const {
    currentCommunity,
    stripePaymentMethodType,
    currentUser,
    memberBillingInfo,
  } = usePaywallCheckoutContext();

  const throwErrorFromApi = (error: ApiError) => {
    throw new Error(error?.message, {
      cause: error.body,
    });
  };

  const STRIPE_NOT_INITIALIZED_ERROR = "Stripe has not been initialized.";

  const constructReturnUrl = (paymentMethodType: string) => {
    const url = new URL(window.location.href);
    url.searchParams.append("payment_method_type", paymentMethodType);
    return url.toString();
  };

  const getBillingField = (
    field:
      | "address_country"
      | "address_line1"
      | "address_city"
      | "address_postal_code"
      | "address_state",
    params: {
      billing_info_attributes: Record<
        | "address_country"
        | "address_line1"
        | "address_city"
        | "address_postal_code"
        | "address_state",
        string
      >;
    },
  ) => memberBillingInfo?.[field] || params.billing_info_attributes[field];

  const confirmKlarnaPaymentMutation = useMutation<any, any, any>(
    async (params: any) => {
      if (!stripe) {
        notifyBugsnag(STRIPE_NOT_INITIALIZED_ERROR);
        throw new Error(STRIPE_NOT_INITIALIZED_ERROR);
      }
      return stripe.confirmKlarnaPayment(params.payment_intent_client_secret, {
        payment_method: {
          billing_details: {
            email: params.email,
            address: { country: getBillingField("address_country", params) },
          },
        },
        return_url: constructReturnUrl(PaymentMethodType.KLARNA),
      });
    },
  );

  const confirmAfterpayClearpayPaymentMutation = useMutation<any, any, any>(
    async (params: any) => {
      if (!stripe) {
        notifyBugsnag(STRIPE_NOT_INITIALIZED_ERROR);
        throw new Error(STRIPE_NOT_INITIALIZED_ERROR);
      }
      return stripe.confirmAfterpayClearpayPayment(
        params.payment_intent_client_secret,
        {
          payment_method: {
            billing_details: {
              email: params.email,
              name: params.name,
              address: {
                country: getBillingField("address_country", params),
                line1: getBillingField("address_line1", params),
                city: getBillingField("address_city", params),
                postal_code: getBillingField("address_postal_code", params),
              },
            },
          },
          shipping: {
            name: params.name,
            address: {
              country: getBillingField("address_country", params),
              line1: getBillingField("address_line1", params),
              city: getBillingField("address_city", params),
              postal_code: getBillingField("address_postal_code", params),
            },
          },
          return_url: constructReturnUrl(PaymentMethodType.AFTERPAY_CLEARPAY),
        },
      );
    },
  );

  const confirmAffirmPaymentMutation = useMutation<any, any, any>(
    async (params: any) => {
      if (!stripe) {
        notifyBugsnag(STRIPE_NOT_INITIALIZED_ERROR);
        throw new Error(STRIPE_NOT_INITIALIZED_ERROR);
      }
      return stripe.confirmAffirmPayment(params.payment_intent_client_secret, {
        payment_method: {
          billing_details: {
            email: params.email,
            name: params.name,
            address: {
              country: getBillingField("address_country", params),
              line1: getBillingField("address_line1", params),
              city: getBillingField("address_city", params),
              state: getBillingField("address_state", params),
              postal_code: getBillingField("address_postal_code", params),
            },
          },
        },
        shipping: {
          name: params.name,
          address: {
            country: getBillingField("address_country", params),
            line1: getBillingField("address_line1", params),
            city: getBillingField("address_city", params),
            state: getBillingField("address_state", params),
            postal_code: getBillingField("address_postal_code", params),
          },
        },
        return_url: constructReturnUrl(PaymentMethodType.AFFIRM),
      });
    },
  );

  const prepareMutation = useMutation<
    PaymentIntentPrepareResponseEndpoint,
    ApiError,
    PrepareRequestEndpoint
  >(params => paywallCheckoutApi.prepare(params));

  const prepare = async (formData: any) => {
    const {
      name,
      email,
      payment_method_type,
      paywall_price_id,
      coupon_code,
      coupon_code_applied,
      community_member_billing_info_attributes,
      password,
    } = formData;

    const params: PrepareRequestEndpoint = {
      name,
      email,
      payment_method_type,
      paywall_price_id,
      community_id: currentCommunity.id,
      coupon_code: coupon_code_applied ? coupon_code : undefined,
      community_member_billing_info_attributes,
      password,
    };

    const prepareResponse = await prepareMutation.mutateAsync(params, {
      onSuccess: () => {},
      onError: throwErrorFromApi,
    });

    if (prepareResponse) {
      const confirmParams = {
        payment_intent_client_secret:
          prepareResponse.payment_intent_client_secret,
        billing_info_attributes: community_member_billing_info_attributes,
        email: email || currentUser.email,
        name: name || `${currentUser.first_name} ${currentUser.last_name}`,
      };

      let paymentIntent;

      if (isKlarna(formData.payment_method_type)) {
        paymentIntent =
          await confirmKlarnaPaymentMutation.mutateAsync(confirmParams);
      } else if (isAfterpayClearpay(formData.payment_method_type)) {
        paymentIntent =
          await confirmAfterpayClearpayPaymentMutation.mutateAsync(
            confirmParams,
          );
      } else if (isAffirm(formData.payment_method_type)) {
        paymentIntent =
          await confirmAffirmPaymentMutation.mutateAsync(confirmParams);
      } else {
        notifyBugsnag(
          `Unsupported payment method type: ${formData.payment_method_type}`,
        );
        throw new Error(
          `Unsupported payment method type: ${formData.payment_method_type}`,
        );
      }

      if (!isSucceeded(paymentIntent)) {
        // Once the PaymentIntent fails, we should reset the mutation
        // to allow the member to re-do the checkout
        prepareMutation.reset();
        throw new Error("Buy Now Pay Later payment expired");
      }
    }

    return prepareResponse;
  };

  const onSubmitMutation = useMutation<any, any, any>(async formData => {
    formData.payment_method_type = stripePaymentMethodType;
    await prepare(formData);
  });

  return {
    onSubmitMutation,
    mutations: { prepareMutation },
  };
};
