import { t } from "@/i18n-js/instance";
import { Alert } from "@circle-react-shared/uikit/AlertV2";
import { Loader } from "@circle-react-uikit/Loader";
import { AddOns } from "../AddOns";
import { useCurrentPlanApi } from "../hooks/useCurrentPlanApi";
import { MarketingHubBillingDetails } from "./MarketingHubBillingDetails";
import { PlanFeatures } from "./PlanFeatures";
import { UpcomingInvoice } from "./UpcomingInvoice";

interface PlanDetailsProps {
  showBillingPortal?: boolean;
  showAddons?: boolean;
  forceFromSubscription?: boolean;
  showBillingDetails?: boolean;
}

export const PlanDetails = ({
  showBillingPortal,
  showAddons,
  forceFromSubscription = true,
  showBillingDetails = true,
}: PlanDetailsProps) => {
  const {
    currentPlan,
    isCurrentPlanLoading,
    errorFecthingCurrentPlan,
    refetchCurrentPlan,
  } = useCurrentPlanApi(forceFromSubscription);

  if (isCurrentPlanLoading) {
    return <Loader center />;
  }
  if (errorFecthingCurrentPlan || !currentPlan) {
    return (
      <Alert
        title={t("settings.billing.plans.errors.plan_not_found")}
        type="alert"
      />
    );
  }

  return (
    <>
      <PlanFeatures currentPlan={currentPlan} />

      {showAddons && (
        <AddOns
          currentPlan={currentPlan}
          refetchCurrentPlan={refetchCurrentPlan}
        />
      )}

      <MarketingHubBillingDetails />

      {showBillingDetails && (
        <UpcomingInvoice
          showBillingPortal={showBillingPortal}
          currentPlan={currentPlan}
        />
      )}
    </>
  );
};
