import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { usePaywallsEditFormContext } from "../PaywallsEditFormProvider";
import { DropdownText } from "./DropdownText";

interface HeaderFormProp {
  index: number;
}

export const HeaderForm = ({ index }: HeaderFormProp) => {
  const { setValue, watch, unregister } = useFormContext();
  const { fieldArrayMethods, fieldNameRoot } = usePaywallsEditFormContext();
  const isPaywallHighlighted = watch(
    `${fieldNameRoot}.${index}.paywall_highlighted`,
  );
  const isValidToRemoveOrValidToMove = fieldArrayMethods.fields.length > 1;

  const removePaywallOnClick = () => {
    unregister(`${fieldNameRoot}.${index}.id`);
    fieldArrayMethods.remove(index);
  };

  const highlightPriceOnClick = () => {
    fieldArrayMethods.fields.forEach((_: any, paywallIndex: number) => {
      setValue(
        `${fieldNameRoot}.${paywallIndex}.paywall_highlighted`,
        paywallIndex === index,
      );
    });
  };

  const removeHighlightPriceOnClick = () => {
    setValue(`${fieldNameRoot}.${index}.paywall_highlighted`, false);
  };

  return (
    <div className="flex flex-row justify-between">
      <Typography.LabelLg weight="semibold">
        {t("paywalls.lock_screen.sidebar.paywall_edit_form.paywall_title", {
          index: index + 1,
        })}
      </Typography.LabelLg>
      <Dropdown
        button={
          <IconButton
            name="16-menu-dots-horizontal"
            iconClassName="!text-dark"
            ariaLabel={t("community_member.actions")}
          />
        }
      >
        {isPaywallHighlighted && (
          <Dropdown.ItemWithLink
            onClick={removeHighlightPriceOnClick}
            linkType="button"
          >
            <DropdownText
              localeString="paywalls.lock_screen.sidebar.paywall_edit_form.remove_highlight"
              icon="16-star-fill"
            />
          </Dropdown.ItemWithLink>
        )}
        {!isPaywallHighlighted && (
          <Dropdown.ItemWithLink
            onClick={highlightPriceOnClick}
            linkType="button"
          >
            <DropdownText
              localeString="paywalls.lock_screen.sidebar.paywall_edit_form.highlight_price"
              icon="16-star"
            />
          </Dropdown.ItemWithLink>
        )}
        {isValidToRemoveOrValidToMove && (
          <>
            <Dropdown.ItemWithLink
              onClick={() => {
                fieldArrayMethods.swap(index, index - 1);
              }}
              isDisabled={index <= 0}
              linkType="button"
            >
              <DropdownText
                localeString="paywalls.lock_screen.sidebar.paywall_edit_form.move_up"
                icon="16-arrow-up"
              />
            </Dropdown.ItemWithLink>
            <Dropdown.ItemWithLink
              onClick={() => {
                fieldArrayMethods.swap(index, index + 1);
              }}
              isDisabled={fieldArrayMethods.fields.length <= index + 1}
              linkType="button"
            >
              <DropdownText
                localeString="paywalls.lock_screen.sidebar.paywall_edit_form.move_down"
                icon="16-arrow-down"
              />
            </Dropdown.ItemWithLink>
            <Dropdown.ItemWithLink
              onClick={removePaywallOnClick}
              linkType="button"
              hasDanger
            >
              <DropdownText localeString="delete" icon="16-trash-bin" />
            </Dropdown.ItemWithLink>
          </>
        )}
      </Dropdown>
    </div>
  );
};
