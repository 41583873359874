import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { isDraftSpace, isPublicSpace } from "@/react/helpers/spaceHelpers";
import { Typography } from "@circle-react-uikit/Typography";

export const Header = ({ space, spaceGroup }) => {
  const isAddToSpacesEnabled =
    !!spaceGroup.automatically_add_members_to_new_spaces;
  const isAddToSpaceGroupEnabled =
    !!spaceGroup.add_members_to_space_group_on_space_join;

  return (
    <>
      <Typography.TitleSm weight="semibold">
        {t("customize_space.paywalls.subtitle")}
      </Typography.TitleSm>

      <Typography.BodyMd color="text-default">
        <ul className="mt-3 space-y-0.5 px-2">
          <li>{t("customize_space.paywalls.description.li_1")}</li>
          <li>{t("customize_space.paywalls.description.li_2")}</li>
          {(isAddToSpacesEnabled || isAddToSpaceGroupEnabled) && (
            <>
              <li>{t("customize_space.paywalls.description.li_3")}</li>
              {isAddToSpaceGroupEnabled && (
                <li className="ml-4">
                  {t("customize_space.paywalls.description.li_3_1")}
                </li>
              )}
              {isAddToSpacesEnabled && (
                <li className="ml-4">
                  {t("customize_space.paywalls.description.li_3_2")}
                </li>
              )}
            </>
          )}
          {!isDraftSpace(space) && isPublicSpace(space) && (
            <li>{t("customize_space.paywalls.description.li_4")}</li>
          )}
          <li>{t("customize_space.paywalls.description.li_5")}</li>
          <li>
            {t("customize_space.paywalls.description.li_6.prefix")}{" "}
            <a
              href="https://help.circle.so/c/paywall-setup/"
              target="_blank"
              rel="noreferrer"
            >
              {t("customize_space.paywalls.description.li_6.link")}
            </a>
          </li>
        </ul>
      </Typography.BodyMd>
    </>
  );
};

Header.propTypes = {
  space: PropTypes.object.isRequired,
  spaceGroup: PropTypes.object.isRequired,
};
