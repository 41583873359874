import {
  useInviteParticipantToCohost,
  useInviteParticipantToModerate,
  usePeerRaiseHand,
  useRemoveParticipant,
  useRemoveParticipantAsCohost,
  useRemoveParticipantAsModerator,
  useRoom,
} from ".";
import { useLiveStreamContextData } from "@circle-react/contexts/LiveStreams";
import {
  PARTICIPANT_ROLES,
  getParticipantFromPeerMetadata,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import type { Peer } from "@circle-react/types/Live/Peer";

export const useParticipant = (peer: Peer) => {
  const { id: peerId, customerUserId: participantId, roleName, name } = peer;
  const { isHandRaised, lowerHand } = usePeerRaiseHand(peerId);
  const room = useRoom();
  const { participants } = useLiveStreamContextData();
  const participant =
    getParticipantFromPeerMetadata(peer) || participants[Number(participantId)];
  const inviteToCohost = useInviteParticipantToCohost(
    peerId,
    room?.id,
    participant?.community_member_id,
  );
  const removeAsCohost = useRemoveParticipantAsCohost(
    peerId,
    room?.id,
    participantId,
  );
  const inviteToModerator = useInviteParticipantToModerate(
    peerId,
    room?.id,
    participant?.community_member_id,
    participantId,
  );
  const removeAsModerator = useRemoveParticipantAsModerator(
    peerId,
    room?.id,
    participantId,
  );
  const isCurrentParticipant = peer.isLocal;
  // Note: we only have 1 host role in 100ms that covers both host and cohosts.
  // In our case the host is the creator of the room, co-hosts are other
  // participants with the host role.
  const isHost =
    !!participant?.community_member_id &&
    participant?.community_member_id === room?.creator?.id;
  const isCohost =
    !isHost &&
    (participant?.role === PARTICIPANT_ROLES.HOST ||
      roleName === PARTICIPANT_ROLES.HOST);
  const isModerator =
    !isHost &&
    (participant?.role === PARTICIPANT_ROLES.MODERATOR ||
      roleName === PARTICIPANT_ROLES.MODERATOR);

  const removeParticipant = useRemoveParticipant(
    room?.id,
    participantId,
    peerId,
  );

  return {
    participant,
    name: participant?.name || name,
    avatarUrl: participant?.avatar_url,
    isCurrentParticipant,
    isHandRaised,
    isHost,
    isCohost,
    isModerator,
    inviteToCohost,
    removeAsCohost,
    lowerHand,
    removeParticipant,
    removeAsModerator,
    inviteToModerator,
  };
};
