export const spaceIconsV3 = {
  coverImageIcon: "20-image-v3",
  customizeLessonsIcon: "20-doc-v3",
  welcomeBannerIcon: "20-welcome-banner-v3",
  customizeIcon: "20-customize-v3",
  creditCardIcon: "20-card-v3",
  customizeLockScreenIcon: "20-locker-closed-v3",
  customizeMembersIcon: "20-users-v3",
  inviteMemberIcon: "20-user-plus-v3",
  customizeOptionsIcon: "20-info-v3",
  leaveSpaceIcon: "20-sign-out-v3",
  deleteSpaceIcon: "20-bin-v3",
  addSpaceLinksIcon: "20-book-v3",
} as const;
