import { useTrialBanner } from "@circle-react/components/Layout/TrialBanner/useTrialBanner";
import { useLiveRoom } from "@circle-react/components/LiveStreams/hooks";
import { useMasqueradeEnabledAndActive } from "@circle-react/hooks/useMasqueradeEnabledAndActive";
import { useTailwindLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useWhiteLabel } from "@circle-react/hooks/useWhiteLabel";
import { useShouldDisplayGoLiveFooter } from "../SidebarFooter/useShouldDisplayGoLiveFooter";
import { useGoLivePoweredByHeight } from "./useGoLivePoweredByHeight";

const topbarHeightV3 = 63;
const communityDropdownHeight = 63;
const trialBannerHeight = 48;
const roomHeight = 168;
const mobileProfileHeight = 65;
const masqueradeBannerHeight = 48;

export const useScrollbarStyles = () => {
  const isLargeScreen = useTailwindLgScreenMediaQuery();
  const { shouldShowTrialBanner } = useTrialBanner();
  const { room } = useLiveRoom();
  const { shouldShowBranding } = useWhiteLabel();

  const hasGoLiveButton = useShouldDisplayGoLiveFooter();
  const isMasqueradingEnabledAndActive = useMasqueradeEnabledAndActive();
  const { goLiveButtonHeight, poweredByHeight, extraContainerSpacing } =
    useGoLivePoweredByHeight();

  const hasRoom = Boolean(room);
  const topbarHeight = topbarHeightV3;

  const heights = [
    isMasqueradingEnabledAndActive ? masqueradeBannerHeight : 0,
    isLargeScreen ? topbarHeight : 0,
    isLargeScreen ? 0 : communityDropdownHeight,
    isLargeScreen && shouldShowTrialBanner ? trialBannerHeight : 0,
    isLargeScreen && hasGoLiveButton ? goLiveButtonHeight : 0,
    hasRoom ? roomHeight : 0,
    !isLargeScreen ? mobileProfileHeight : 0,
    shouldShowBranding ? poweredByHeight : 0,
    extraContainerSpacing,
  ];
  const height = heights.reduce((acc, curr) => acc + curr, 0);

  return {
    paddingBottom: height,
  };
};
