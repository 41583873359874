import { NavLink } from "react-router-dom";
import { ContentBody } from "@/react/components/Chat/ChatWindowV2/MessageItem/ContentBody";
import { getChatRoomUrlWithMessageId } from "@/react/components/Chat/ChatWindowV2/helpers";
import { formatDate } from "@/react/components/SettingsApp/ManageMembers/TabContent/Cells";
import type { ChatRoomMessageSearchResult } from "@/react/types/Chat/ChatRoomMessage";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

export const Messages = ({ item }: { item: ChatRoomMessageSearchResult }) => {
  const {
    sender,
    sent_at: sentAt,
    embedded: isEmbedded,
    chat_space_slug,
  } = item;

  const chatRoomName = isEmbedded ? item.chat_space_name : item.chat_room_name;
  return (
    <NavLink
      to={getChatRoomUrlWithMessageId(
        { uuid: item.chat_room_uuid, slug: chat_space_slug },
        item,
      )}
      className="flex gap-4 pr-0 md:gap-1 md:pr-6"
      data-testid="message-result"
    >
      <div className="flex flex-1 flex-col gap-3">
        <div className="flex items-center gap-2">
          <UserImage
            src={item.user_avatar_url ?? ""}
            name={sender?.name ?? ""}
            size="6"
          />
          <Typography.LabelXs color="text-selector-active">
            {`${String(sender?.name)} • ${formatDate(sentAt)} • ${chatRoomName}`}
          </Typography.LabelXs>
        </div>
        <div className="[&_mark]:!bg-search-yellow flex flex-col gap-2">
          <div className="line-clamp-4">
            <ContentBody message={item} shouldShowPreviewBlocks={false} />
          </div>
        </div>
      </div>
    </NavLink>
  );
};
