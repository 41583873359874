import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface AccessGroupParamsType {
  name: string;
  description: string;
}

export const accessGroupsApi = () => {
  const listAccessGroups = ({ params = {} }) =>
    reactQueryGet(internalApi.admin.accessGroups.index({ params }));

  const createAccessGroup = (params: AccessGroupParamsType) =>
    reactQueryPost(internalApi.admin.accessGroups.create(), params);

  const updateAccessGroup = ({
    id,
    params,
  }: {
    id: number;
    params: AccessGroupParamsType;
  }) => reactQueryPut(internalApi.admin.accessGroups.update(id), params);

  const showAccessGroup = (id: number) =>
    reactQueryGet(internalApi.admin.accessGroups.show(id));

  const destroyAccessGroup = (id: number) =>
    reactQueryDelete(internalApi.admin.accessGroups.delete(id));

  const unarchiveAccessGroup = (id: number) =>
    reactQueryPut(internalApi.admin.accessGroups.unarchive(id));

  return {
    listAccessGroups,
    createAccessGroup,
    showAccessGroup,
    updateAccessGroup,
    destroyAccessGroup,
    unarchiveAccessGroup,
  };
};
