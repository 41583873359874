import { useEffect, useState } from "react";
import { useModal } from "@ebay/nice-modal-react";
import { useDropzone } from "react-dropzone";
import { t } from "@/i18n-js/instance";
import { useDirectUpload } from "@/react/hooks/useDirectUpload";
import { IconButton } from "@circle-react/components/shared/uikit/HeaderV3/IconButton";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useAddContentModal } from "../AddContentModal";
import { useCreateFromFilesSnippet } from "../snippetsQueries";
import { EmptyState } from "./EmptyState";
import { FileList } from "./FileList";

const MAX_FILES = 15;

export interface FileToUpload {
  file: File | null;
  signedId: string | null;
  status: "selected" | "check_in_progress" | "valid";
}

export const AddFileModalComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<FileToUpload[]>([]);

  const modal = useModal();

  const addContentModal = useAddContentModal();

  const createFromFilesSnippetMutation = useCreateFromFilesSnippet({
    onSuccess: () => {
      modal.remove();
    },
  });

  const { uploadFile, status, response, isError } = useDirectUpload();

  const { error } = useToast();
  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: true,
    accept: { "text/plain": [".txt"], "application/pdf": [".pdf"] },
    noClick: files.length > 0,
    maxSize: 100 * 1024 * 1024,
    onDropAccepted: acceptedFiles => {
      if (files.length + acceptedFiles.length > MAX_FILES) {
        error(
          t(
            "settings.community_bot.knowledge.custom.add_file_modal.uploader.errors.max_files",
            {
              max_files: MAX_FILES,
            },
          ),
        );
        return;
      }
      acceptedFiles.forEach(file => {
        uploadFile(file);

        setFiles(prevFiles => [
          ...prevFiles,
          { file, signedId: null, status: "selected" },
        ]);
      });
    },
    onDropRejected: rejectedFiles => {
      rejectedFiles.forEach(file => {
        error(
          t(
            "settings.community_bot.knowledge.custom.add_file_modal.uploader.errors.rejected",
            {
              file_name: file.file.name,
            },
          ),
        );
      });
    },
  });

  useEffect(() => {
    if (isError) {
      if (!response) {
        setFiles([]);
        error(
          t(
            "settings.community_bot.knowledge.custom.add_file_modal.uploader.errors.upload_failed",
          ),
        );
      } else if (response.filename) {
        setFiles(files.filter(f => f.file?.name !== response.filename));
        error(
          t(
            "settings.community_bot.knowledge.custom.add_file_modal.uploader.errors.file_upload_failed",
            {
              file_name: response.filename,
            },
          ),
        );
      }
    }
    if (
      status === "completed" &&
      response &&
      files.find(
        f => f.file?.name === response.filename && f.status === "selected",
      )
    ) {
      setFiles(prevFiles =>
        prevFiles.map(file =>
          response.filename === file.file?.name
            ? {
                ...file,
                signedId: response.signed_id,
                status: "valid",
              }
            : file,
        ),
      );
    }
  }, [status, response, isError, error, files]);

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content
        size="auto"
        className="h-3 max-w-[720px] overflow-visible !rounded-t-none sm:h-full sm:!rounded-t-lg md:max-h-[80vh] lg:max-h-[500px]"
      >
        <header className="flex items-center justify-between px-5 py-4">
          <Typography.TitleSm weight="semibold">
            {t("settings.community_bot.knowledge.custom.add_file_modal.title")}
          </Typography.TitleSm>
          <ul className="text-darkest flex list-none items-center space-x-2">
            <li>
              <TippyV2
                placement="bottom"
                content={t("quick_post_v2.header.close")}
              >
                <IconButton
                  variant="secondary"
                  iconSize={20}
                  onClick={modal.hide}
                  iconClassName="!text-dark"
                  name="20-close"
                  ariaLabel={t("quick_post_v2.header.close")}
                />
              </TippyV2>
            </li>
          </ul>
        </header>
        <div className="flex h-full flex-row overflow-hidden pb-4">
          <div
            {...getRootProps()}
            className={classNames(
              "focus-within:border-secondary border-primary mx-4 mb-4 flex h-full w-full items-center justify-center rounded-md border border-dashed",
              { "cursor-pointer": files.length === 0 },
            )}
          >
            {files.length === 0 && <EmptyState openFilePicker={open} />}
            {files.length > 0 && (
              <FileList
                files={files}
                setFiles={setFiles}
                openFilePicker={open}
              />
            )}
            <input {...getInputProps()} type="file" className="hidden" />
          </div>
        </div>
        <footer
          className="border-primary flex items-center justify-end border-t px-5 py-4"
          data-testid="quick-post-footer"
        >
          <div className="flex items-center space-x-2">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                void modal.hide();
                void addContentModal.show();
              }}
            >
              {t(
                "settings.community_bot.knowledge.custom.add_file_modal.footer.back",
              )}
            </Button>
            <Button
              variant="circle"
              type="button"
              disabled={
                isLoading ||
                files.length === 0 ||
                files.some(file => file.status !== "valid")
              }
              onClick={() => {
                setIsLoading(true);
                createFromFilesSnippetMutation.mutate(
                  files
                    .map(file => file.signedId ?? "")
                    .filter(id => id.length > 0),
                  {
                    onError: () => {
                      setIsLoading(false);
                      error(
                        t(
                          "settings.community_bot.knowledge.custom.add_file_modal.footer.errors.upload_failed",
                        ),
                      );
                    },
                  },
                );
              }}
            >
              {t(
                "settings.community_bot.knowledge.custom.add_file_modal.footer.add",
              )}
            </Button>
          </div>
        </footer>
      </Modal.Content>
    </Modal>
  );
};
