import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

export interface QuickPostBoxProps {
  userName: string;
  userAvatarUrl?: string;
  onClick: ComponentPropsWithoutRef<"button">["onClick"];
}

export const QuickPostBox = ({
  userName,
  userAvatarUrl,
  onClick,
}: QuickPostBoxProps) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames(
      "border-primary bg-primary focus-visible:!outline-secondary flex w-full cursor-pointer items-center justify-between rounded-2xl border p-3.5 transition-shadow hover:shadow-md focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2",
    )}
  >
    <div className="flex items-center gap-4">
      <UserImage name={userName} src={userAvatarUrl} size="8" />
      <Typography.LabelMd color="text-light">
        {t("start_a_post")}
      </Typography.LabelMd>
    </div>
    <div
      className={classNames(
        "bg-tertiary flex h-8 w-8 justify-center rounded-full",
      )}
    >
      <Icon type="20-plus-v2" size={20} className="text-dark" />
    </div>
  </button>
);
