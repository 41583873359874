import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { EmptyState } from "@/react/components/shared/uikit/EmptyState";
import { useMigrationModal } from "./MigrationModal";

const i18nRoot = "settings.access_groups.paywall_migration.empty_state";

export const MigrationEmptyState = ({
  closePaywallFormModal,
}: {
  closePaywallFormModal: () => void;
}) => {
  const { watch } = useFormContext();
  const paywallId = watch("id");
  const migrationModal = useMigrationModal();

  const goToPaywallKnowledgeBase = () => {
    window.open(
      "https://help.circle.so/p/basics/community-access/access-group-overview",
      "_blank",
    );
  };
  return (
    <div className="mt-6 rounded border border-gray-200">
      <EmptyState
        header={t([i18nRoot, "header"])}
        description={t([i18nRoot, "description"])}
        primaryLabel={t([i18nRoot, "primary_cta"])}
        secondaryLabel={t([i18nRoot, "secondary_cta"])}
        onPrimaryClick={() => {
          void migrationModal.show({
            paywallId: paywallId,
            closePaywallFormModal,
          });
        }}
        onSecondaryClick={goToPaywallKnowledgeBase}
      />
    </div>
  );
};
