import classnames from "classnames";
import { Switch } from "@circle-react-uikit/Switch";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceGroupOption } from "../../SpaceAccessManagementContext";
import { Item } from "./Item";
import { ItemTitle } from "./ItemTitle";
import { RemovableItemTitleWrapper } from "./RemovableItemTitleWrapper";
import { useParentItem } from "./hooks/useParentItem";

interface ParentMobileItemProp {
  spaceGroup: SpaceGroupOption;
  isToggleEnabled?: boolean;
  onAddParent?: (action: () => void, object: SpaceGroupOption) => void;
  onRemoveParent?: (action: () => void, object: SpaceGroupOption) => void;
}

export const ParentMobileItem = ({
  isToggleEnabled = true,
  spaceGroup,
  onAddParent = action => {
    action();
  },
  onRemoveParent = action => {
    action();
  },
}: ParentMobileItemProp) => {
  const { name } = spaceGroup;
  const {
    isChecked,
    onAddClick,
    onRemoveClick,
    isFromSearch,
    isRemovable,
    isNotRemovableTooltip,
  } = useParentItem({
    spaceGroup,
    onAddParent,
    onRemoveParent,
  });

  const onClick = isChecked
    ? isRemovable
      ? onRemoveClick
      : undefined
    : onAddClick;
  const isDisabled = isToggleEnabled || (isChecked && !isRemovable);

  return (
    <Item
      className={classnames({ hidden: !isFromSearch })}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      <RemovableItemTitleWrapper
        isRemovable={isRemovable}
        isNotRemovableTooltip={isNotRemovableTooltip}
      >
        <ItemTitle>
          <Typography.LabelSm weight="semibold">{name}</Typography.LabelSm>
        </ItemTitle>
      </RemovableItemTitleWrapper>
      {isToggleEnabled && <Switch as="span" checked={isChecked} />}
    </Item>
  );
};
