import { useEffect } from "react";
import { t } from "@/i18n-js/instance";
import { Icon } from "@/react/components/shared/Icon";
import { useToast } from "@/react/components/shared/uikit/ToastV2";
import { Typography } from "@/react/components/shared/uikit/Typography";
import { useAccessGroupsMigrationPreviewApi } from "@/react/hooks/paywalls/paywallAccessGroupMigration/useAccessGroupsMigrationPreviewApi";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Loader } from "@circle-react-uikit/Loader";
import { useSanitizeFormPayload } from "../hooks/useSanitizeFormPayload";
import { AccessGroups } from "./AccessGroups";
import { Members } from "./Members";

const i18nBase = "settings.access_groups.paywall_migration.modal.preview";

export const Preview = () => {
  const { formValues } = useSanitizeFormPayload();
  const { previewData, isLoading, error, isError } =
    useAccessGroupsMigrationPreviewApi(formValues);
  const toastr = useToast();

  useEffect(() => {
    if (isError && error) {
      toastr.error(error.message);
    }
  }, [error, isError]);

  if (isLoading) {
    return <Loader />;
  }
  if (!previewData) {
    return null;
  }
  return (
    <div className="mt-6">
      <Typography.BodyMd>
        <SanitizeHTMLString content={t([i18nBase, "description"])} />
      </Typography.BodyMd>
      <div className="mt-5 flex gap-4">
        <div className="flex-1">
          <Members />
        </div>
        <Icon type="24-arrow-right" />
        <div className="flex-1">
          <AccessGroups previewData={previewData} />
        </div>
      </div>
    </div>
  );
};
