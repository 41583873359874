import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const BrandedAppsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BrandedAppsDashboardPage" */ "@circle-react/components/SettingsApp/BrandedAppsDashboard/index"
    ),
);

export const BrandedAppsDashboardPage = () => (
  <Suspense fallback={<PageLoader />}>
    <BrandedAppsComponent />
  </Suspense>
);
