import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { FORM_ID } from "@/react/components/Spaces/NewV2/NewForm";
import {
  COURSE_TYPE_FORM_ID,
  CourseTypePickerForm,
} from "@/react/components/Spaces/NewV2/NewForm/CourseTypePickerForm";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { NewV2 } from "@circle-react/components/Spaces/NewV2";
import type { SpaceType } from "@circle-react/types/Space";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { BackButton } from "./BackButton";
import { SPACE_TYPE_FORM_ID, SpaceTypePickerForm } from "./SpaceTypePickerForm";

export interface SpaceTypePickerModalProps {
  spaceGroupId?: number;
  selectedPostType?: SpaceType;
}

export const SpaceTypePickerModalComponent = ({
  spaceGroupId,
  selectedPostType,
}: SpaceTypePickerModalProps) => {
  const drawer = useModal();
  const [postType, setPostType] = useState(selectedPostType ?? "basic");
  const [hasSelectedSpaceType, setHasSelectedSpaceType] = useState(
    Boolean(selectedPostType) && selectedPostType !== "course",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCourseSpace, setIsCourseSpace] = useState(
    selectedPostType === "course",
  );
  const [courseSetting, setCourseSetting] = useState<any>();

  const modalTitle = isCourseSpace
    ? t("spaces.form.create.course_types.choose_course_type")
    : hasSelectedSpaceType
      ? t(`spaces.form.create.space_titles.${postType}`)
      : t("spaces.form.create.space_types.choose_type");

  const handleClose = () => {
    void drawer.hide();
    setIsCourseSpace(false);
    setCourseSetting(undefined);
    setHasSelectedSpaceType(false);
    drawer.remove();
  };

  return (
    <Modal
      isOpen={drawer.visible}
      onClose={handleClose}
      contentPortalId="space-type-picker-modal"
      title={modalTitle}
    >
      <Modal.Overlay />
      <Modal.Content size="md">
        <Modal.Header className="!px-6 md:!px-8">
          {hasSelectedSpaceType && (
            <BackButton
              hideForm={() => {
                setHasSelectedSpaceType(false);
                if (postType === "course") {
                  setIsCourseSpace(true);
                }
              }}
            />
          )}
          {isCourseSpace && (
            <BackButton
              hideForm={() => {
                setIsCourseSpace(false);
                setHasSelectedSpaceType(false);
              }}
            />
          )}
          <Modal.Title className="w-auto" size="sm">
            {modalTitle}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={handleClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="!px-6 !py-1 md:!px-8">
          {!hasSelectedSpaceType && !isCourseSpace && (
            <SpaceTypePickerForm
              onSubmit={({ post_type }) => {
                setPostType(post_type);
                post_type === "course"
                  ? setIsCourseSpace(true)
                  : setHasSelectedSpaceType(true);
              }}
            />
          )}
          {isCourseSpace && (
            <CourseTypePickerForm
              onSubmit={({ course_setting }: any) => {
                setCourseSetting(course_setting);
                setIsCourseSpace(false);
                setHasSelectedSpaceType(true);
              }}
              courseSetting={courseSetting}
            />
          )}
          {hasSelectedSpaceType && (
            <NewV2
              onClose={handleClose}
              post_type={postType}
              courseSetting={courseSetting}
              hideSpaceTypePicker
              currentSpaceGroupId={spaceGroupId}
              setExternalLoadingState={setIsLoading}
              renderHeader={() => <>{}</>}
              renderFooter={() => <>{}</>}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            form={
              hasSelectedSpaceType
                ? FORM_ID
                : isCourseSpace
                  ? COURSE_TYPE_FORM_ID
                  : SPACE_TYPE_FORM_ID
            }
            type="submit"
            data-testid="create-space-button"
            variant="circle"
            full
            large
            disabled={isLoading}
          >
            {hasSelectedSpaceType
              ? isLoading
                ? t("creating")
                : t("create_space")
              : t("spaces.form.create.space_types.next")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
