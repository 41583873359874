import type { ReactNode } from "react";

export interface SidebarV2WrapperProps {
  children: ReactNode;
}

export const SidebarV2Wrapper = ({ children }: SidebarV2WrapperProps) => (
  <div className="bg-c-sidebar w-sidebar-v3 fixed flex h-full max-h-full flex-col">
    {children}
  </div>
);
