import type { ReactNode } from "react";
import classnames from "classnames";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { useSidebarBorderStyles } from "../hooks";

export interface TopSectionContainerProps {
  hasPadding?: boolean;
  isSticky?: boolean;
  children: ReactNode;
  className?: string;
}

export const TopSectionContainer = ({
  hasPadding = true,
  isSticky = true,
  children,
  className,
}: TopSectionContainerProps) => {
  const styles = useSidebarBorderStyles();

  return (
    <BlockInViewOnlyMode>
      <div
        className={classnames(
          className,
          "top-0 z-30 flex h-16 min-h-[4rem] items-center justify-between gap-2",
          {
            "px-4": hasPadding,
            sticky: isSticky,
          },
        )}
        data-testid="top_section_container"
        style={styles}
      >
        {children}
      </div>
    </BlockInViewOnlyMode>
  );
};
