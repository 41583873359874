import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { CreateWorkflowItem } from "@circle-react/components/SettingsApp/Workflows/components/NewWorkflowModal/CreateWorkflowItem";
import { TemplateItem } from "@circle-react/components/SettingsApp/Workflows/components/NewWorkflowModal/TemplateItem";
import { Typography } from "@circle-react-uikit/Typography";

export interface TemplateGroupProps {
  id: string;
  category?: string;
  title: string;
  description?: string;
  includeCreateButton: boolean;
  templates: any[];
  className?: string;
}

export const TemplateGroup = ({
  id,
  category,
  title,
  description,
  includeCreateButton,
  templates = [],
  className,
}: TemplateGroupProps) => {
  const history = useHistory();

  const onClickTemplateItem = (templateId: string) => {
    const path = `/settings/workflows/new/preview/${templateId}`;
    history.push(path);
  };

  return (
    <div
      id={id}
      className={classNames("flex max-w-[62rem] flex-col gap-7", className)}
    >
      {!category && (
        <div className="flex flex-col gap-1.5">
          <div>
            <Typography.TitleMd>{title}</Typography.TitleMd>
          </div>
          {description && (
            <div>
              <Typography.LabelMd color="text-default">
                {description}
              </Typography.LabelMd>
            </div>
          )}
        </div>
      )}
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {includeCreateButton && <CreateWorkflowItem />}
        {templates.map(template => (
          <TemplateItem
            {...template}
            key={template.id}
            templateKey={template.key}
            templateType={template.template_type}
            onClick={() => onClickTemplateItem(template.id)}
          />
        ))}
      </div>
    </div>
  );
};
