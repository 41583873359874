import type { Dispatch } from "react";
import { useMemo } from "react";
import { t } from "@/i18n-js/instance";
import { AddSection } from "@/react/components/Spaces/CourseSpace/Lessons/List/Content/AddSection";
import { isDrippedCourseSpace } from "@/react/helpers/courseHelpers";
import type { BooleanListAction } from "@/react/hooks/utils/useBooleanList";
import type { CoursesSection } from "@/react/types/CoursesSection";
import type { Space } from "@/react/types/Space";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { TableCell, TableHead, TableRow } from "@circle-react-uikit/Table";
import { Typography } from "@circle-react-uikit/Typography";

interface HeadProps {
  space: Space;
  sections: CoursesSection[];
  dispatchSectionsNameEditor: Dispatch<BooleanListAction>;
}

export const Head = ({
  space,
  sections,
  dispatchSectionsNameEditor,
}: HeadProps) => {
  const lessonCount = useMemo(
    () =>
      sections.reduce(
        (acc: number, section: CoursesSection) => acc + section.lessons.length,
        0,
      ),
    [sections],
  );

  return (
    <TableHead
      className="divide-primary divide-y !border-t-0 p-6"
      data-testid="customize-lessons-table-head"
    >
      <TableRow>
        <TableCell
          {...(isDrippedCourseSpace(space) && {
            colSpan: 3,
          })}
          className="px-6"
        >
          <Typography.TitleSm as="span" weight="semibold">
            {t("courses.edit.section_count", {
              count: sections?.length || 0,
            })}{" "}
            {t("separator_dot")}{" "}
            {t("courses.edit.lesson_count", {
              count: lessonCount,
            })}
          </Typography.TitleSm>
        </TableCell>
        <TableCell className="px-6 text-right">
          <AddSection
            variant="circle"
            sections={sections}
            setIsNameEditable={id =>
              dispatchSectionsNameEditor({
                id,
                value: true,
              })
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className="!py-3 px-6"
          colSpan={isDrippedCourseSpace(space) ? 1 : 2}
        >
          <Typography.LabelXs textTransform="uppercase">
            {t("courses.edit.sections.lesson_name")}
          </Typography.LabelXs>
        </TableCell>
        {isDrippedCourseSpace(space) && (
          <>
            <TableCell>
              <Typography.LabelXs textTransform="uppercase">
                {t("courses.edit.sections.schedule")}
              </Typography.LabelXs>
            </TableCell>
            <TableCell className="!px-0 !py-3" colSpan={2}>
              <Typography.LabelXs textTransform="uppercase">
                {t("courses.edit.sections.notify_students")}
                <TippyV2
                  content={t("courses.edit.sections.notify_students_tooltip")}
                  className="inline-flex"
                  placement="top-end"
                >
                  <Icon type="question-mark" className="ml-2 !w-3" />
                </TippyV2>
              </Typography.LabelXs>
            </TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
};
