import { NavLink } from "react-router-dom";
import { formatDate } from "@/react/components/SettingsApp/ManageMembers/TabContent/Cells";
import type { AdvancedSearchResultImagePost } from "@/react/components/search/types";
import { postsPath } from "@/react/helpers/urlHelpers";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

export const ImagePost = ({
  item,
}: {
  item: AdvancedSearchResultImagePost;
}) => {
  const path = postsPath({ spaceSlug: item.space_slug, slug: item.slug });
  const body = item.highlighted_body || item.body;

  return (
    <NavLink
      to={path}
      className={classNames("flex gap-4 pr-0 md:gap-1 md:pr-6", {
        "flex-col-reverse md:flex-row": item.main_gallery_image_url,
      })}
      data-testid="post-result"
    >
      <div className="flex flex-1 flex-col gap-3">
        {!item.hide_meta_info && (
          <div className="flex items-center gap-2">
            <UserImage
              src={item.user_avatar_url ?? ""}
              name={item.user_name}
              size="6"
            />
            <Typography.LabelXs color="text-selector-active">
              {`${String(item.user_name)} • ${formatDate(item.created_at)}`}
            </Typography.LabelXs>
          </div>
        )}

        <div className="[&_mark]:!bg-search-yellow flex flex-col gap-2">
          <div className="line-clamp-4">
            <Typography.BodySm color="text-default">
              <SanitizeHTMLString content={body} />
            </Typography.BodySm>
          </div>
        </div>
      </div>

      {item.main_gallery_image_url && (
        <div className="md:w-42 w-full rounded md:h-24">
          <img
            loading="lazy"
            className="h-full w-full rounded-md object-cover"
            src={item.main_gallery_image_url}
            alt=""
          />
        </div>
      )}
    </NavLink>
  );
};
