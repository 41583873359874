import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import type { ResponseParams } from "@circle-react/api/paywalls/communityPaymentProcessorApi";
import { communityPaymentProcessorApi } from "@circle-react/api/paywalls/communityPaymentProcessorApi";
import { Button } from "@circle-react-uikit/Button";
import { StatusBadge } from "@circle-react-uikit/StatusBadge";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Description } from "./Description";
import { PaymentProcessorStatusAlert } from "./PaymentProcessorStatusAlert";

export const PaymentProcessorRestricted = () => {
  const defaultErrorMessage = t("settings.paywalls.alerts.error.action_failed");
  const { error } = useToast();
  const { mutate, isLoading } = useMutation<
    ResponseParams,
    Record<string, string>
  >(() => communityPaymentProcessorApi.refresh());

  const onSubmit = () => {
    mutate(undefined, {
      onSuccess: response => {
        window.location.href = response.url;
      },
      onError: response => {
        error(response?.message || defaultErrorMessage);
      },
    });
  };

  const sentiment = "danger";
  const badgeComponent = (
    <StatusBadge variant={sentiment}>
      {t("settings.paywalls.processor_status_v2.restricted.status")}
    </StatusBadge>
  );
  const descriptionComponent = (
    <div className="flex flex-col gap-y-4">
      <Description>
        <p
          className="text-default text-lg"
          dangerouslySetInnerHTML={{
            __html: t(
              "settings.paywalls.processor_status_v2.restricted.description",
            ),
          }}
        />
      </Description>
      <div>
        <Button variant="circle" onClick={onSubmit} disabled={isLoading}>
          {t("settings.paywalls.buttons.refresh")}
        </Button>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-y-4">
      <PaymentProcessorStatusAlert
        sentiment={sentiment}
        title={t("settings.paywalls.processor_status_v2.restricted.title")}
        badge={badgeComponent}
        description={descriptionComponent}
      />
    </div>
  );
};
