import { createContext, useContext } from "react";
import type { ReactNode } from "react";

export interface NavigationMenuContextProps {
  isOpen: boolean;
  close: () => void;
}

const NavigationMenuContext = createContext<NavigationMenuContextProps | null>(
  null,
);
NavigationMenuContext.displayName = "NavigationMenuContext";

export const useNavigationMenuContext = (): NavigationMenuContextProps => {
  const context = useContext(NavigationMenuContext);

  if (!context) {
    throw new Error(
      "useNavigationMenuContext must be used within a NavigationMenu",
    );
  }

  return context;
};

export const useClose = (callback?: () => void) => {
  const { close } = useNavigationMenuContext();

  return () => {
    close();
    callback?.();
  };
};

export interface NavigationMenuProviderProps
  extends NavigationMenuContextProps {
  children: ReactNode;
}

export const NavigationMenuProvider = ({
  isOpen,
  close,
  children,
}: NavigationMenuProviderProps) => (
  <NavigationMenuContext.Provider value={{ isOpen, close }}>
    {children}
  </NavigationMenuContext.Provider>
);
