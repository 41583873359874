import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { debounce, get } from "lodash";
import { useFormContext, useFormState } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useSpaceMutation } from "@circle-react/components/Spaces/hooks";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { InfoBox } from "@circle-react-uikit/InfoBox";
import { RichTextInput } from "@circle-react-uikit/RichTextInput";
import { Typography } from "@circle-react-uikit/Typography";

export const LegacyTrixEditorInput = ({ blockDataPath }) => {
  const { data: space } = useCurrentSpaceContext();
  const { watch, setValue } = useFormContext();
  const { defaultValues } = useFormState();
  const [ignoreFirstTrixChange, setIgnoreFirstTrixChange] = useState(true);

  const trixHtmlPath = `${blockDataPath}.locked_page_description.trix_html`;
  const renderedHtmlPath = `${blockDataPath}.locked_page_description.rendered_html_with_layout`;

  const [initialTrixHtml, setInitialTrixHtml] = useState(
    get(defaultValues, trixHtmlPath),
  );
  const trixHtml = watch(trixHtmlPath);

  const { mutate: updateSpace } = useSpaceMutation({
    space,
    options: {
      onSuccess: data => {
        const newHTml =
          data?.locked_page_description?.rendered_html_with_layout;
        setValue(renderedHtmlPath, newHTml);
      },
    },
  });

  useEffect(() => {
    if (trixHtml === initialTrixHtml) {
      return;
    }

    if (trixHtml !== initialTrixHtml && ignoreFirstTrixChange) {
      setInitialTrixHtml(trixHtml);
      setIgnoreFirstTrixChange(false);
      return;
    }

    const debouncedUpdate = debounce(() => {
      updateSpace({ locked_page_description: trixHtml });
    }, 500);

    debouncedUpdate();

    return debouncedUpdate.cancel;
  }, [updateSpace, initialTrixHtml, trixHtml, ignoreFirstTrixChange]);

  return (
    <div className="pb-2 pt-5">
      <div className="text-dark mb-2 flex items-center">
        <Typography.LabelSm weight="semibold">
          {t("courses.lockscreen.fields.description")}
        </Typography.LabelSm>
      </div>
      <div className="trix-v2">
        <RichTextInput
          className="locked-page-description"
          name={trixHtmlPath}
          id={trixHtmlPath}
        />
      </div>
      <InfoBox className="mt-2">
        {t("courses.lockscreen.legacy_description_update_warning")}
      </InfoBox>
    </div>
  );
};

LegacyTrixEditorInput.propTypes = {
  blockDataPath: PropTypes.string.isRequired,
};
