import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { usePlanStatus } from "@/react/hooks/usePlanStatus";
import { useGetPaywallsCurrencies } from "@circle-react/hooks/paywalls/useGetPaywallsCurrencies";
import { useIsInAppEnabled } from "@circle-react/hooks/useIsInAppEnabled";
import { Tab } from "@circle-react-shared/uikit/TabV2";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { MobilePricingTab } from "./MobilePricingTab";
import { MobilePricingTabContextProvider } from "./MobilePricingTab/hooks/usePricingTabContext";
import { StripePricingTab } from "./StripePricingTab";
import { StripePricingTabContextProvider } from "./StripePricingTab/hooks/usePricingTabContext";

export const PricingTab = () => {
  const { watch } = useFormContext();
  const { isCommunityOnPlus } = usePlanStatus();
  const isInAppEnabled = useIsInAppEnabled();

  const { data: paywallsCurrencies, isLoading: isLoadingPaywallsCurrencies } =
    useGetPaywallsCurrencies();

  if (isLoadingPaywallsCurrencies) {
    return <Loader />;
  }

  const currencyId = watch("currency_id");
  const currency = paywallsCurrencies.find(
    currency => currency.id === parseInt(currencyId),
  );

  return (
    <div className="flex h-[60vh] flex-col gap-x-5 gap-y-4 !overflow-hidden px-4 pt-8">
      <Typography.TitleSm weight="semibold">
        {t("settings.paywalls.modal.tabs.pricing.title")}
      </Typography.TitleSm>
      <Tab.Group className="!overflow-hidden">
        {isCommunityOnPlus && isInAppEnabled && (
          <Tab.List className="border-primary border-b">
            <Tab isButtonType>{t("web")}</Tab>
            <Tab isButtonType>{t("mobile")}</Tab>
          </Tab.List>
        )}
        <Tab.Panels
          className={classNames("pb-10", { "pt-8": isCommunityOnPlus })}
        >
          <Tab.Panel>
            <StripePricingTabContextProvider currency={currency}>
              <StripePricingTab />
            </StripePricingTabContextProvider>
          </Tab.Panel>
          {isCommunityOnPlus && isInAppEnabled && (
            <Tab.Panel>
              <MobilePricingTabContextProvider currency={currency}>
                <MobilePricingTab />
              </MobilePricingTabContextProvider>
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
