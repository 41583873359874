import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import feature1 from "@circle-assets/images/features/leaderboard/feature1.png";
import feature2 from "@circle-assets/images/features/leaderboard/feature2.png";
import feature3 from "@circle-assets/images/features/leaderboard/feature3.png";
import { StandardLayoutHeaderWithPortal } from "@circle-react/components/Layout/StandardLayoutV2/StandardLayoutHeader/StandardLayoutHeaderWithPortal";
import { FeatureCard } from "@circle-react/components/Leaderboard/LandingPage/FeatureCard";
import { FeatureImage } from "@circle-react/components/Leaderboard/LandingPage/FeatureImage";
import { LandingFooter } from "@circle-react/components/Leaderboard/LandingPage/Footer";
import { LandingHeader } from "@circle-react/components/Leaderboard/LandingPage/Header";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { Button } from "@circle-react-shared/uikit/Button";

export const LeaderboardLandingPage = () => (
  <div className="bg-primary h-full">
    <StandardLayoutHeaderWithPortal
      leftContent={
        <SpaceHeaderTitleText>{t("leaderboard.title")}</SpaceHeaderTitleText>
      }
      rightContent={
        <Link to="/settings/gamification">
          <Button large variant="circle">
            {t("leaderboard.activate")}
          </Button>
        </Link>
      }
    />
    <main className="mx-auto max-w-6xl">
      <div className="flex h-full w-full flex-col space-y-16 p-14">
        <LandingHeader />
        <div className="grid grid-flow-row-dense grid-cols-6 gap-6 xl:auto-rows-fr xl:grid-cols-12">
          <FeatureCard
            color="pink"
            icon="leaderboard-progress"
            feature="collect_points"
            featureCount={4}
            iconSize={24}
          />
          <FeatureImage src={feature1} />
          <FeatureCard
            color="green"
            icon="leaderboard-activity"
            feature="level_up"
          />
          <FeatureImage src={feature2} />
          <FeatureCard
            color="purple"
            icon="leaderboard-levelup"
            feature="earn_rewards"
          />
          <FeatureImage src={feature3} />
        </div>
        <LandingFooter />
      </div>
    </main>
  </div>
);
