import classNames from "classnames";
import { useFormContext } from "react-hook-form";

interface LabelProps {
  isEditing: boolean;
  onClick: () => void;
}

export const Label = ({ isEditing, ...rest }: LabelProps) => {
  const { getValues } = useFormContext();
  const name = getValues("name");

  return (
    <div
      {...rest}
      className={classNames("flex h-7 min-w-0 flex-col", {
        hidden: isEditing,
      })}
    >
      <span
        className={classNames(
          "hover:border-dark focus:border-dark text-dark truncate border-b border-dashed border-transparent text-xl font-semibold",
        )}
      >
        {name}
      </span>
    </div>
  );
};
