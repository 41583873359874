import type { ComponentPropsWithRef } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { I18N_ROOT } from "./constants";

export function SenderEmail(props: ComponentPropsWithRef<typeof Form.Input>) {
  const { getValues } = useFormContext();
  const [senderEmailDomain] = getValues(["sender_email_domain"]);
  const [isCustomDomainEnabled] = getValues([
    "is_custom_domain_enabled_for_marketing_hub",
  ]);

  return (
    <div className="flex flex-col gap-y-2">
      <Form.Input
        suffix={
          <div className="whitespace-nowrap py-1">
            <Typography.LabelMd color="text-light">{`@${senderEmailDomain}`}</Typography.LabelMd>
          </div>
        }
        suffixWrapperClassName="!bg-selector-active"
        {...props}
      />
      {!isCustomDomainEnabled && (
        <Typography.LabelXs color="text-default">
          {t([I18N_ROOT, "sender_email_description"])}
        </Typography.LabelXs>
      )}
    </div>
  );
}
