import { useState } from "react";
import PropTypes from "prop-types";
import I18n from "@/i18n-js/instance";
import { Tabs } from "@circle-react-uikit/Tabs";

export const GroupTabs = ({
  setDataLoaded,
  setHasNextPage,
  setNotificationGroup,
}) => {
  const TABS = {
    INBOX: I18n.t("notifications.group_tabs.inbox"),
    FOLLOWING: I18n.t("notifications.group_tabs.following"),
    ALL: I18n.t("notifications.group_tabs.all"),
    ARCHIVED: I18n.t("notifications.group_tabs.archived"),
  };
  const [activeTab, setActiveTab] = useState(TABS.INBOX);

  const setDataLoadingDefaults = () => {
    setDataLoaded(false);
    setHasNextPage(false);
  };

  const handleTabClick = tab => {
    setNotificationGroup(tab.toLowerCase());
    setDataLoadingDefaults();
    setActiveTab(tab);
  };

  return (
    <Tabs
      wrapperClassName="px-6"
      controlled
      activeTab={activeTab}
      onClick={handleTabClick}
      options={Object.values(TABS).map(tab => ({ label: tab }))}
      variant="underline-sm"
    />
  );
};

GroupTabs.propTypes = {
  setNotificationGroup: PropTypes.func.isRequired,
  setDataLoaded: PropTypes.func.isRequired,
  setHasNextPage: PropTypes.func.isRequired,
};
