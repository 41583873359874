import { ConnectStripeState } from "../States/ConnectStripeState";
import { EmptyState } from "../States/EmptyState";
import { RootForm } from "./RootForm";

export const WebForm = () => (
  <ConnectStripeState>
    <EmptyState>
      <RootForm />
    </EmptyState>
  </ConnectStripeState>
);
