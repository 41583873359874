import { useEffect } from "react";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { useIsLoadingAppData } from "@/react/hooks/useIsLoadingAppData";
import { useRouterQueryParams } from "@/react/hooks/useRouterQueryParams";
import { QuickPostBoxModal } from "@circle-react/components/QuickPost/QuickPostBox";
import { TopicFilters } from "@circle-react/components/Spaces/TopicFilters";
import { useFilters } from "@circle-react/components/Spaces/TopicFilters/hooks/useFilters";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";
import { usePostList } from "@circle-react/hooks/posts/usePostList";
import { NoPostsWithTopic } from "./NoPostsWithTopic";
import { PostItem } from "./PostItem";
import { PostListLoader } from "./PostListLoader";
import { PostListWrapper } from "./PostListWrapper";
import { PostNavigationProvider } from "./PostNavigationContext";
import { PostsEmptyState } from "./PostsEmptyState";
import { useSpaceQuickPostBox } from "./hooks/useSpaceQuickPostBox";
import { usePostView } from "./usePostView";

const getPageSize = () => {
  if (window.innerHeight >= 1600) return 36;
  if (window.innerHeight >= 925) return 24;
  return 15;
};

export const PostViewList = () => {
  const { sort } = useRouterQueryParams();
  const { data: space } = useCurrentSpaceContext();
  const { shouldDisplayQuickPostBox } = useSpaceQuickPostBox();
  const isLoadingAppData = useIsLoadingAppData();
  const { currentFilters } = useFilters();
  const hasSelectedFilters = !!currentFilters.size;

  const {
    posts: allPosts,
    mapPages,
    hasNextPage,
    isLoading,
    fetchNextPage,
    refetch: refetchPosts,
    onPostChange,
    onPostDestroy,
  } = usePostList({
    sort,
    include_top_pinned_post: true,
    used_on: space?.display_view,
    per_page: getPageSize(),
    topics: Array.from(currentFilters),
  });

  const {
    firstPost,
    remainingPosts,
    shouldDisplayTopicsFilters,
    isFirstPostPinnedOutside,
    isFirstPostInside,
    hasNoPosts,
    isCardsView,
  } = usePostView(allPosts);

  useEffect(() => {
    window.scrollTo(0, 0);
    const postsListContainer = document.getElementById("panel");
    if (postsListContainer) {
      postsListContainer.scrollTo(0, 0);
    }
  }, [space?.id]);

  const perPagePostIds = mapPages((page: any) =>
    page.records.map((post: any) => post.id),
  );

  const isLoadingPosts = isLoading && (!allPosts || !allPosts.length);

  // Show loader whenever space or app data is loading
  // Skip loader if there are selected filters and space is loaded so that the posts skeleton loader
  // can be shown under the quick post button.
  if (
    (isLoadingPosts && !hasSelectedFilters) ||
    !space?.id ||
    isLoadingAppData
  ) {
    return (
      <PostListLoader
        count={12}
        space={space}
        hasPinnedPost={!!space?.top_pinned_post_id}
      />
    );
  }

  if (hasNoPosts && !hasSelectedFilters) {
    return <PostsEmptyState space={space} />;
  }

  return (
    <PostsDetailsContextProvider
      spaceId={space.id}
      perPagePostIds={perPagePostIds}
    >
      <PostNavigationProvider
        hasNextPage={hasNextPage}
        params={{
          sort,
          topics: Array.from(currentFilters),
          per_page: getPageSize(),
          include_top_pinned_post: true,
          used_on: space?.display_view,
        }}
      >
        <PostListWrapper displayView={space.display_view}>
          {shouldDisplayTopicsFilters && <TopicFilters />}
          {shouldDisplayQuickPostBox && (
            <div className="mb-6 hidden md:block">
              <QuickPostBoxModal />
            </div>
          )}
          {isLoadingPosts ? (
            <PostListLoader
              count={12}
              space={space}
              hasPinnedPost={!!space?.top_pinned_post_id}
            />
          ) : hasNoPosts && hasSelectedFilters ? (
            <NoPostsWithTopic />
          ) : (
            <>
              {isFirstPostPinnedOutside && (
                <PostItem
                  post={firstPost}
                  space={space}
                  onPostChange={onPostChange}
                  refetchPosts={refetchPosts}
                  isFirst
                  className="mt-5 md:mt-0"
                />
              )}
              <InfiniteScroll
                className={classNames(
                  "infinity_scroll infinity_scroll__container",
                  {
                    "infinity_scroll__container--auto-fit mx-4 grid grid-cols-1 gap-6 first:mt-5 md:mx-0 md:grid-cols-2 md:first:mt-0 xl:grid-cols-3":
                      isCardsView,
                  },
                )}
                style={{ overflow: "initial" }}
                scrollThreshold={0.5}
                next={fetchNextPage}
                hasMore={Boolean(hasNextPage)}
                loader={
                  <PostListLoader
                    count={10}
                    space={space}
                    withContainer={false}
                  />
                }
                dataLength={remainingPosts.length}
                scrollableTarget="panel"
              >
                {isFirstPostInside && (
                  <PostItem
                    key={firstPost.id}
                    post={firstPost}
                    space={space}
                    onPostChange={onPostChange}
                    onPostDestroy={onPostDestroy}
                    refetchPosts={refetchPosts}
                    isFirst
                  />
                )}
                {remainingPosts.map(post => (
                  <PostItem
                    key={post.id}
                    post={post}
                    space={space}
                    onPostChange={onPostChange}
                    onPostDestroy={onPostDestroy}
                    refetchPosts={refetchPosts}
                  />
                ))}
              </InfiniteScroll>
            </>
          )}
        </PostListWrapper>
      </PostNavigationProvider>
    </PostsDetailsContextProvider>
  );
};

export default PostViewList;
