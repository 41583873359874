import { useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet";
import { FormProvider, useForm } from "react-hook-form";
import { QueryClient, QueryClientProvider } from "react-query";
import { PaywallCheckoutContextProvider } from "@/react/contexts/Paywalls/paywallCheckoutContext";
import { useAppendBodyClasses } from "@/react/hooks/useAppendBodyClasses";
import type { CommunityMemberBillingInfo } from "@/react/types";
import { loadStripeWrapper } from "@circle-react/hooks/paywalls/useStripeElement";
import { ControlledThemeProvider, THEME_MAP } from "@circle-react/providers";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";
import { stripeElementsOptions } from "../StripeElements/stripeElementsOptions";
import { CheckoutForm } from "./CheckoutForm";
import { CheckoutRedirectHandler } from "./CheckoutRedirectHandler";
import { CommunityAndPaywallPriceInfo } from "./CommunityAndPaywallPriceInfo";
import { SSOLoginPrompt } from "./SSOLoginPrompt";
import { useFilterPaymentMethodsSupported } from "./hooks/useFilterPaymentMethodsSupported";
import { useInitialPrice } from "./hooks/useInitialPrice";
import { useStripeElementOptions } from "./hooks/useStripeElementOptions";
import "./styles.scss";

interface CheckoutProps {
  paywall: {
    event?: { name: string };
    display_name: string;
    prices: any[];
  };
  currentCommunity: { id: string };
  isTaxCollectionEnabled: boolean;
  currentCommunityMember: { id: string };
  hasAlreadyPurchased: boolean;
  currentUser: { id: string };
  loginUrl: string;
  resetPasswordUrl: string;
  communityRootUrl: string;
  processorId: string;
  checkoutConfirmationUrl: string;
  processorKey: string;
  areCouponsEnabled: boolean;
  isMemberAddressRequired: boolean;
  memberBillingInfo: CommunityMemberBillingInfo;
  isPaywallDigitalWalletEnabled: boolean;
  isBuyNowPayLaterEnabled: boolean;
  paymentMethodsSupported: string[];
  currentCommunityMemberPaymentProcessorSessionClientSecret: string;
  isTaxIdMandatory: boolean;
  isMemberWithSavedPaymentMethods: boolean;
  isBrazilianAccountAndProcessorStripeBr: boolean;
  shouldShowTaxIdForm: boolean;
  authProviderProperties: {
    name?: string;
    is_sso_authentication_required?: boolean;
  };
}

export const Checkout = (props: CheckoutProps) => {
  const {
    paywall,
    currentCommunity,
    currentUser,
    processorId,
    processorKey,
    isMemberAddressRequired,
    isPaywallDigitalWalletEnabled,
    isBuyNowPayLaterEnabled,
    paymentMethodsSupported,
    currentCommunityMemberPaymentProcessorSessionClientSecret,
  } = props;

  const [disabledPaymentMethods, setDisabledPaymentMethods] = useState<
    string[]
  >([]);

  const filteredPaymentMethodsSupported = useFilterPaymentMethodsSupported(
    paymentMethodsSupported,
    disabledPaymentMethods,
  );

  // Once Login process can be executed at community level we can
  // render this component on App.jsx and start using usePunditUserContext
  // instead of passing current community/user as params
  const stripePromise = useMemo(
    () =>
      loadStripeWrapper({
        stripeAccountId: processorId,
        stripePublicKey: processorKey,
        locale: window.locale,
      }),
    [processorKey, processorId],
  );

  const price = useInitialPrice({ prices: paywall.prices });

  const formMethods = useForm({
    defaultValues: {
      community_id: currentCommunity.id,
      user_id: currentUser?.id,
      paywall_price_id: price.id,
      coupon_code_applied: false,
      community_member_billing_info_attributes: {},
      community_member_billing_info_address_attributes_complete:
        !isMemberAddressRequired,
      payment_method_type: "card",
    },
  });

  const queryClient = new QueryClient(reactQueryConfig as any);

  let title;
  if (paywall?.event?.name) {
    title = paywall.event.name;
  } else {
    title = paywall.display_name;
  }

  useAppendBodyClasses("paywall-checkout");
  const otherStripeElementsOptions = useStripeElementOptions({
    filteredPaymentMethodsSupported,
    isPaywallDigitalWalletEnabled,
    isBuyNowPayLaterEnabled,
    isBuyNowPayLaterAllowed: true,
    paywall,
    price,
    currentCommunityMemberPaymentProcessorSessionClientSecret,
  });

  return (
    <Elements
      // Force PaymentElement to re-render whenever the Payment Methods supported is changed.
      key={filteredPaymentMethodsSupported.join(",")}
      stripe={stripePromise}
      options={{
        ...stripeElementsOptions,
        ...otherStripeElementsOptions,
      }}
    >
      <ControlledThemeProvider theme={THEME_MAP["default"]}>
        <QueryClientProvider client={queryClient}>
          <FormProvider {...formMethods}>
            <PaywallCheckoutContextProvider
              {...props}
              paymentElementKey=""
              paymentMethodsSupported={filteredPaymentMethodsSupported}
              isPaywallDigitalWalletEnabled={isPaywallDigitalWalletEnabled}
              disabledPaymentMethods={disabledPaymentMethods}
              setDisabledPaymentMethods={setDisabledPaymentMethods}
            >
              <Helmet>
                <title>{title}</title>
              </Helmet>
              <SSOLoginPrompt>
                <CommunityAndPaywallPriceInfo />
                <CheckoutForm />
                <CheckoutRedirectHandler />
              </SSOLoginPrompt>
            </PaywallCheckoutContextProvider>
          </FormProvider>
        </QueryClientProvider>
      </ControlledThemeProvider>
    </Elements>
  );
};
