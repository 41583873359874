import { classNames } from "@circle-react/helpers/twMergeWithCN";

const getHeightV3Styles = (
  shouldShowTrialBanner: boolean,
  isLgScreen: boolean,
  isMasqueradingEnabledAndActive: boolean,
  isReactFlowWorkflowsRoute: boolean,
) => {
  if (isReactFlowWorkflowsRoute) return "";

  return classNames({
    "lg:!min-h-[calc(100vh-64px)]":
      !shouldShowTrialBanner && isLgScreen && !isMasqueradingEnabledAndActive,
    "lg:!min-h-[calc(100vh-64px-48px)]":
      (shouldShowTrialBanner || isLgScreen) && isMasqueradingEnabledAndActive,
    "lg:!min-h-[calc(100vh-112px)]":
      (isLgScreen && isMasqueradingEnabledAndActive) ||
      (isLgScreen && shouldShowTrialBanner),
    "lg:!min-h-[calc(100vh-160px)]":
      shouldShowTrialBanner && isLgScreen && isMasqueradingEnabledAndActive,
    "lg:!min-h-[calc(100vh-64px-48px-48px)]":
      isLgScreen && isMasqueradingEnabledAndActive && shouldShowTrialBanner,
  });
};

export { getHeightV3Styles };
