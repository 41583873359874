import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { ProfileResponse } from "@circle-react/hooks/profile/useProfileInfo";
import { PROFILE_INFO_QUERY_KEY } from "@circle-react/hooks/profile/useProfileInfo";
import { BaseResource } from "../BaseResource";

interface AdminResourceProps {
  resourceId: number;
  resourceType: string;
  contactType: string;
}

export const AdminResource = ({ resourceId }: AdminResourceProps) => {
  const { data: profile, isLoading } = useQuery<ProfileResponse, ApiError>(
    [PROFILE_INFO_QUERY_KEY, resourceId, {}],
    () =>
      reactQueryGet(
        internalApi.profile.show({
          memberPublicId: String(resourceId),
          params: {},
        }),
      ),
  );

  return (
    <BaseResource
      label={t("settings.workflows.view.resources.messaging")}
      value={profile?.name}
      isLoading={isLoading}
    />
  );
};
