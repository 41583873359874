import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";

const i18nBase = "settings.access_groups.paywall_migration.modal.form";

export const AccessGroupFields = () => (
  <div className="mx-1">
    <Form.Item
      name="name"
      translationRoot={i18nBase}
      hideDescription
      hidePlaceholder
      hideBorder
      rules={{
        required: t([i18nBase, "validations.name_required"]),
        maxLength: {
          value: 65,
          message: t([i18nBase, "validations.name_max_length"]),
        },
      }}
      tooltipText={t([i18nBase, "tooltips.name"])}
    >
      <Form.Input />
    </Form.Item>
    <Form.Item
      name="description"
      translationRoot={i18nBase}
      hideDescription
      hidePlaceholder
      hideBorder
      tooltipText={t([i18nBase, "tooltips.description"])}
    >
      <Form.Input />
    </Form.Item>
  </div>
);
