import { useState } from "react";
import { noop, omit } from "lodash";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { DEFAULT_COURSE_TYPE } from "@/react/components/Spaces/CourseSpace/CourseTypePicker/constants";
import { isLegacyAccessSystemAvailable } from "@/react/helpers/accessGroups/accessGroupHelpers";
import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import {
  getNextTier,
  isPlanEnterprise,
  isPlanPlus,
} from "@/react/helpers/planHelpers";
import {
  usePunditUserContext,
  useSpaceGroupsContext,
  useSpacesContext,
} from "@circle-react/contexts";
import {
  settingsNavPathV3,
  viewSpacePath,
} from "@circle-react/helpers/urlHelpers";
import { useSpaceApi } from "@circle-react/hooks/useSpaceApi";
import type { CourseSetting, SpaceType } from "@circle-react/types/Space";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { Form } from "@circle-react-uikit/Form";
import { DEFAULT_ICON } from "@circle-react-uikit/Form/FormEmojiPickerV3/constants";
import { useToast } from "@circle-react-uikit/ToastV2";
import { AutoAddMembers } from "./AutoAddMembers";
import BasicInfo from "./BasicInfo";
import { DefaultFooter } from "./DefaultFooter";
import { DefaultHeader } from "./DefaultHeader";
import EventOptions from "./EventOptions";
import { NotificationTypePicker } from "./NotificationTypePicker";
import { SpaceTypePicker } from "./SpaceTypePicker";
import VisibilityPicker from "./VisibilityPicker";
import { isMaxSpaceLimitReached } from "./spacesApiErrorHelper";

export const FORM_ID = "create-space-form";

export interface NewFormProps {
  onClose: () => void;
  currentSpaceGroupId?: number;
  post_type?: SpaceType;
  courseSetting?: CourseSetting;
  renderHeader?: typeof DefaultHeader;
  renderFooter?: typeof DefaultFooter;
  hideSpaceTypePicker?: boolean;
  setExternalLoadingState?: (state: boolean) => void;
}

export const NewForm = ({
  onClose,
  currentSpaceGroupId,
  post_type = "basic",
  courseSetting = { course_type: DEFAULT_COURSE_TYPE },
  renderHeader = DefaultHeader,
  renderFooter = DefaultFooter,
  hideSpaceTypePicker = false,
  setExternalLoadingState = noop,
}: NewFormProps) => {
  const history = useHistory();
  const {
    actions: { refresh },
  } = useSpacesContext();
  const [isLoading, setIsLoading] = useState(false);
  const { createSpace, maxSpaceLimit } = useSpaceApi();
  const { currentCommunity, currentCommunitySettings, currentCommunityMember } =
    usePunditUserContext();
  const canCreateAllTypesOfSpaces = !!currentCommunityMember?.is_admin;
  const toast = useToast();
  const upgradePlanModal = useUpgradePlanModal();
  const {
    helpers: { isDataLoading: isSpaceGroupsDataLoading, findBy },
  } = useSpaceGroupsContext();
  const currentPlanTier = currentCommunity?.current_plan_tier || "";

  const currentSpaceGroup = findBy({ id: currentSpaceGroupId });

  const defaultValues: any = {
    space_group_id: currentSpaceGroupId,
    post_type: canCreateAllTypesOfSpaces ? post_type : "basic",
    visibility: "open",
    course_setting: courseSetting,
    emoji: "#",
    custom_emoji: DEFAULT_ICON,
    notification_type: [
      "default_notification_setting",
      "default_in_app_notification_setting",
      "default_mobile_notification_setting",
    ],
    is_post_disabled: true,
    add_members_from_space_group:
      currentSpaceGroup?.automatically_add_members_to_new_spaces,
    default_comment_sort: "oldest",
    default_member_sort: "latest",
    default_sort: "latest",
    hide_right_sidebar: post_type === "basic",
  };

  if (post_type == "chat") {
    defaultValues["notification_type"] = "never";
  }

  if (isSpaceGroupsDataLoading()) {
    return <LoaderRow />;
  }

  const getNotificationPreferences = (value: any) => ({
    default_notification_setting: value.includes("default_notification_setting")
      ? "all"
      : "never",
    default_in_app_notification_setting: value.includes(
      "default_in_app_notification_setting",
    )
      ? "all"
      : "never",
    default_mobile_notification_setting: value.includes(
      "default_mobile_notification_setting",
    )
      ? "all"
      : "never",
    default_mention_mobile_notification_setting: value.includes(
      "default_mention_mobile_notification_setting",
    )
      ? "all"
      : "never",
    default_mention_in_app_notification_setting: value.includes(
      "default_mention_in_app_notification_setting",
    )
      ? "all"
      : "never",
  });

  const isLegibleForUpgradePlanModal =
    !isPlanEnterprise(currentPlanTier) &&
    !isPlanPlus(currentPlanTier) &&
    Boolean(currentCommunityMember?.is_admin) &&
    Boolean(currentCommunitySettings?.upgrade_flows_for_space_creation_enabled);

  const checkPlanRestrictions = async (spaceGroupId: any) => {
    if (isLegibleForUpgradePlanModal) {
      await maxSpaceLimit(spaceGroupId);
    }
  };

  const onChangePlanSuccess = async () => {
    onClose();
    history.push(settingsNavPathV3.site.spaces);
    await upgradePlanModal.hide();
  };

  const showUpgradePlanModal = async (apiError: any) => {
    setIsLoading(false);
    setExternalLoadingState(false);
    await upgradePlanModal.show({
      planTier: getNextTier(currentPlanTier) || undefined,
      onSuccess: onChangePlanSuccess,
      subheading: apiError.body.message,
      successButtonLabel: t("pricing_plans.upgrade_modal.actions.go_to_spaces"),
      source: UPGRADE_FLOW_SOURCES.SPACE_LIMIT_UPGRADE_MODAL,
      showPlanDetails: true,
    });
  };

  const mapChatNotificationPreference = (value: any) => {
    if (value == "all") {
      return [
        "default_mobile_notification_setting",
        "default_mention_mobile_notification_setting",
        "default_mention_in_app_notification_setting",
      ];
    }
    if (value == "mentions") {
      return [
        "default_mention_mobile_notification_setting",
        "default_mention_in_app_notification_setting",
      ];
    }
    return [];
  };

  const buildPayload = (data: any) => ({
    ...omit(data, "notification_type", "course_setting"),
    ...getNotificationPreferences(
      data.post_type === "chat"
        ? mapChatNotificationPreference(data.notification_type)
        : data.notification_type,
    ),
    ...(data.post_type === "course"
      ? { course_setting: data.course_setting }
      : {}),
    is_post_disabled:
      data.post_type === "event" ? data.is_post_disabled : false,
  });

  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    setExternalLoadingState(true);

    try {
      await checkPlanRestrictions(data.space_group_id);
      const payload = buildPayload(data);
      const newSpace = await createSpace(payload);
      await refresh();
      onClose();
      history.push(viewSpacePath({ spaceSlug: newSpace.slug }));
    } catch (apiError: any) {
      notifyBugsnag(apiError);
      console.error(apiError);
      const { firstErrorDetail, errorDetails } = apiError;
      const errorMessage = firstErrorDetail ? firstErrorDetail : errorDetails;
      if (isMaxSpaceLimitReached(apiError) && isLegibleForUpgradePlanModal) {
        await showUpgradePlanModal(apiError);
      } else {
        toast.error(errorMessage);
      }
    } finally {
      setExternalLoadingState(false);
    }
  };

  return (
    <Form
      id={FORM_ID}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      className="create-space__form"
    >
      {renderHeader({ onClose })}
      <div>
        <BasicInfo />
        {isLegacyAccessSystemAvailable(currentCommunitySettings) && (
          <AutoAddMembers
            spaceGroupMemberCount={
              currentSpaceGroup?.space_group_members_count || 0
            }
            chatSpaceMemberLimit={
              currentCommunity?.chat_space_members_limit || 0
            }
          />
        )}
        {canCreateAllTypesOfSpaces && !hideSpaceTypePicker && (
          <SpaceTypePicker
            isCoursesEnabled={currentCommunity?.courses_feature_flag_enabled}
          />
        )}
        <VisibilityPicker />
        <NotificationTypePicker />
        <EventOptions />
      </div>
      {renderFooter({ isLoading })}
    </Form>
  );
};
