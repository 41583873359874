import { t } from "@/i18n-js/instance";
import { useAddLinkModal } from "@circle-react/components/Modals/AddLinkModal";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { Icon } from "@circle-react/components/shared/Icon";

export const AddLinkButton = () => {
  const addLinkModal = useAddLinkModal();

  return (
    <BlockInViewOnlyMode>
      <button
        type="button"
        className="text-c-sidebar hover:text-c-sidebar hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover text-xs-plus h-8.5 flex w-full items-center rounded-lg px-4 font-medium transition-colors duration-200"
        onClick={() => addLinkModal.show()}
      >
        <Icon type="16-add-new" size={16} />
        <span className="ml-2">{t("add_link")}</span>
      </button>
    </BlockInViewOnlyMode>
  );
};
