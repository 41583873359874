import { t } from "@/i18n-js/instance";
import { Avatar } from "@circle-react/components/SettingsApp/Workflows/components/Avatar";
import { Typography } from "@circle-react-uikit/Typography";
import type { SubscribeToTrialApiParams } from "../subscribeToTrial";
import { PaywallPriceFromDetails } from "./PaywallPriceFromDetails";

export interface HistoryDetailViewTrialPaywallProps {
  body: {
    community_member_id: number;
    trial_days: number;
  };
  apiParams: SubscribeToTrialApiParams;
}

export const HistoryDetailViewTrialPaywall = ({
  body,
  apiParams,
}: HistoryDetailViewTrialPaywallProps) => {
  const { community_member_id: communityMemberId, trial_days: trialDays } =
    body;
  const { paywall_id: paywallId, paywall_price_id: paywallPriceId } =
    apiParams ?? {};

  return (
    <div className="flex flex-col gap-2">
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.paywall_price")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <PaywallPriceFromDetails
            paywallId={paywallId}
            paywallPriceId={paywallPriceId}
          />
        </div>
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.member")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <Avatar communityMemberId={communityMemberId} />
        </div>
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.trial_days")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">{trialDays}</div>
      </div>
    </div>
  );
};
