import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { useLandingPageSettingsApi } from "../../hooks/home_page/useLandingPageSettingsApi";
import RichTextInput from "../RichTextInput";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { FieldLabel } from "../shared/Form/FieldLabel";
import { MediaDirectUploader } from "../shared/MediaDirectUploader";
import { ToggleSwitch } from "../shared/ToggleSwitch";
import { useDisableWindowScroll } from "../shared/TrixEditor/useDisableWindowScroll";
import { Uploader } from "../shared/Uploader";

const i18nRoot = "landing_page.settings.welcome_banner";

export const BannerForm = ({
  keyField,
  title,
  media,
  description,
  allowClose,
  enabled,
  onTitleChange,
  onBannerDescriptionChange,
  displayAllowClose,
  onAllowCloseChange,
  onRemoveMedia,
  onEnabledChange,
  setMediaSettings,
}) => {
  const { reSetWelcomeBannerPreference } = useLandingPageSettingsApi();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewMedia, setPreviewMedia] = useState({});
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useDisableWindowScroll(isModalOpen);

  const uploadProgress = progress => {
    setIsLoading(true);
    setProgress(progress);
  };

  const afterUpload = (itemUrl, signedId, contentType, fieldName) => {
    setPreviewMedia({ url: itemUrl, type: contentType });
    setIsLoading(false);
    const mediaFieldName = fieldName
      .split("community")[1]
      .replace(/^\[(.+)\]$/, "$1");

    setMediaSettings(mediaFieldName, signedId);
  };

  const onMediaUpload = (event, fieldName) => {
    const formClass = "media-uploader";
    try {
      const uploader = new Uploader(
        event.target.files[0],
        afterUpload,
        formClass || "",
        fieldName || "",
        uploadProgress,
      );
      uploader.start();
    } catch (e) {
      console.error(e);
    }
  };

  const removeMedia = () => {
    setPreviewMedia({});
    onRemoveMedia();
  };

  const mediaUploaderElement = () => (
    <div
      className="form-group full-width media-uploader"
      id={`field-${keyField}`}
    >
      <FieldLabel
        field={keyField}
        label={t(`${i18nRoot}.media`)}
        description={t(`${i18nRoot}.media_description`)}
      />
      <MediaDirectUploader
        id={keyField}
        name={keyField}
        media={media}
        previewMedia={previewMedia}
        onMediaUpload={event =>
          onMediaUpload(event, `community[${keyField}_media]`)
        }
        isLoading={isLoading}
        progress={progress}
        onRemoveMedia={removeMedia}
      />
    </div>
  );

  const descriptionField = () => (
    <div className="form-group full-width">
      <FieldLabel
        field={`${keyField}-description`}
        label={t(`${i18nRoot}.description`)}
      />
      <div className="form-input">
        <RichTextInput
          id={`rich-text-description-${keyField}`}
          key={`rich-text-description-${keyField}`}
          placeholder={t(`${i18nRoot}.write_a_description`)}
          name={`rich-text-description-${keyField}`}
          value={description || ""}
          onChange={onBannerDescriptionChange}
          is_private_space={false}
          preventAccidentalLeave={false}
        />
      </div>
    </div>
  );

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const handleResetBannerClick = () => {
    setIsModalOpen(true);
  };

  const handleResetConfirmation = event => {
    event.preventDefault();
    reSetWelcomeBannerPreference();
    setIsModalOpen(false);
  };

  const resetBannerLink = () => (
    <>
      <button
        type="button"
        onClick={handleResetBannerClick}
        className="homepage-reset-banner-link w-full text-left"
      >
        {t(`${i18nRoot}.reset_preference_title`)}
      </button>
      {Boolean(isModalOpen) && (
        <ConfirmationModal
          show={isModalOpen}
          closeOnEscape
          closeOnOutsideClick
          onClose={onModalClose}
          onConfirmation={handleResetConfirmation}
          headerText={t(`${i18nRoot}.reset_preference_title`)}
          bodyText={t(`${i18nRoot}.reset_preference_description`)}
          className="reset-banner-modal"
        />
      )}
    </>
  );

  const allowCloseToggle = () => (
    <div className="form-group full-width" id={`field-${keyField}-allow-close`}>
      <div className="form-meta">
        <label htmlFor={`${keyField}-allow-close`}>
          {t(`${i18nRoot}.allow_close_title`)}
        </label>
        <div className="field-description">
          {t(`${i18nRoot}.allow_close_title_description`)}&nbsp;
          {resetBannerLink()}
        </div>
      </div>
      <div className="form-input">
        <div className="form-meta-button">
          <ToggleSwitch
            field="allow_close"
            value={allowClose}
            onToggleChange={onAllowCloseChange}
          />
        </div>
      </div>
    </div>
  );

  const welcomeBannerFields = () => (
    <div className="form-section__background">
      {mediaUploaderElement()}

      <div className="form-group full-width">
        <FieldLabel
          field={`${keyField}-title`}
          label={t(`${i18nRoot}.title`)}
        />
        <div className="form-input">
          <input
            className="form-control placeholder:text-light placeholder:opacity-100"
            value={title ? title : ""}
            onChange={onTitleChange}
          />
        </div>
      </div>

      {descriptionField()}
      {displayAllowClose && allowCloseToggle()}
    </div>
  );

  return (
    <div className="form-section__content">
      <div
        className="form-group full-width"
        id={`field-${keyField}-show-banner`}
      >
        <FieldLabel
          field={keyField}
          label={t(`${i18nRoot}.header`)}
          description={t(`${i18nRoot}.header_description`)}
        />
        <div className="form-input">
          <div className="form-meta-button">
            <ToggleSwitch
              field={`field-${keyField}-enabled`}
              value={enabled}
              onToggleChange={onEnabledChange}
            />
          </div>
        </div>
      </div>

      {enabled && welcomeBannerFields()}
    </div>
  );
};
