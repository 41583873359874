import { MobilePreview } from "./MobilePreview";
import { WebPreview } from "./WebPreview";

export const LockscreenPreview = Object.assign(
  {},
  {
    WebPreview,
    MobilePreview,
  },
);
