import { useState } from "react";
import { t } from "@/i18n-js/instance";
import type { Space } from "@/react/types/Space";
import { usePunditUserContext, useSpaceResource } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { CourseTypePickerModal } from "./CourseTypePickerModal";
import { DEFAULT_COURSE_TYPE } from "./constants";

interface CourseTypePickerLabelProps {
  space: Space;
}

export const CourseTypePickerLabel = ({
  space,
}: CourseTypePickerLabelProps) => {
  const [isCourseTypePickerModalOpen, setIsCourseTypePickerModalOpen] =
    useState(false);
  const { data: spaceResource, isLoading } = useSpaceResource({
    spaceId: space?.id,
  });
  const { isViewOnlyMasquerading } = usePunditUserContext();

  if (isLoading) return <Loader />;
  if (!spaceResource) return null;

  const courseType =
    spaceResource.course_setting?.course_type || DEFAULT_COURSE_TYPE;

  return (
    <>
      <CourseTypePickerModal
        space={spaceResource}
        isOpen={isCourseTypePickerModalOpen}
        onClose={() => {
          setIsCourseTypePickerModalOpen(false);
        }}
      />
      <Typography.BodySm weight="font-medium">
        <Icon type="16-book" size={16} className="align-text-bottom" />{" "}
        {t("spaces.form.create.course_types.label")}:{" "}
        <Button
          variant="plain"
          className="hover:bg-tertiary !text-dark !px-1 !py-0.5 !font-semibold underline decoration-dashed decoration-1 underline-offset-2"
          onClick={() => setIsCourseTypePickerModalOpen(true)}
          disabled={isViewOnlyMasquerading}
        >
          {t(`spaces.form.create.course_types.${courseType}`)}
        </Button>
      </Typography.BodySm>
    </>
  );
};
