import { useMemo } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import { localeRoot as localeCountry } from "@circle-react/types/Countries";
import { Modal } from "@circle-react-uikit/ModalV2";
import { SanitizeHTMLString } from "@circle-react-uikit/SanitizeContent";
import {
  countriesNotSupported,
  countriesToBeSorted,
  firstCountriesToBeShown,
} from "../constants";
import { CountriesForm } from "./CountriesForm";
import { CountryWarningDialog } from "./CountryWarningDialog";
import { ModalFooter } from "./ModalFooter";

const localeRoot = "stripe_onboarding_modal";

export const BusinessForm = ({ isLoading, onClose, defaultCountry }: any) => {
  const {
    watch,
    formState: { isSubmitSuccessful },
  } = useFormContext();
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();
  const countries = useMemo(() => {
    const firstCountries = firstCountriesToBeShown.map(countryCode => ({
      label: t([localeCountry, countryCode]),
      value: countryCode.toLowerCase(),
    }));

    const countriesSorted = [...countriesToBeSorted, ...countriesNotSupported]
      .map(countryCode => ({
        label: t([localeCountry, countryCode]),
        value: countryCode.toLowerCase(),
      }))
      .sort((first, second) => (first.label >= second.label ? 1 : -1));

    return [...firstCountries, ...countriesSorted];
  }, []);

  const selectedCountry = watch("country");
  const isCountryNotSupported = countriesNotSupported.includes(
    selectedCountry.toUpperCase(),
  );

  const isComboBoxDisabled = isLoading || isSubmitSuccessful;
  const isButtonDisabled =
    isLoading || isSubmitSuccessful || isCountryNotSupported;

  return (
    <>
      <Modal.Body
        className={classNames({
          "!px-5": isMobileWebNewSpaceCustomizationUIEnabled,
        })}
      >
        <CountriesForm
          countries={countries}
          isDisabled={isComboBoxDisabled}
          defaultValue={defaultCountry}
        />
        {isCountryNotSupported && (
          <CountryWarningDialog>
            <SanitizeHTMLString
              content={t([localeRoot, "country_not_supported"], {
                country: t([localeCountry, selectedCountry.toUpperCase()]),
              })}
            />
          </CountryWarningDialog>
        )}
      </Modal.Body>
      <ModalFooter isDisabled={isButtonDisabled} onClose={onClose} />
    </>
  );
};
