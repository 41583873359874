import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import type { Profile } from "@circle-react/types/CommunityBot/Profile";

interface BotProfileSearchResponse {
  records: Profile[];
}

export const useCommunityBotProfileSpotlightSearch = ({
  enabled,
}: {
  enabled: boolean | undefined;
}) => {
  const { data } = useQuery<BotProfileSearchResponse, Error>(
    ["bot-profiles"],
    () => reactQueryGet(internalApi.communityBot.agents.indexForSearch()),
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  );

  return {
    agents: data?.records,
  };
};
