import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { broadcastsApi } from "@circle-react/api/broadcastsApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

const i18nBase = "settings.emails.broadcasts.new_broadcast_modal";

interface CreateBroadcastParams {
  internal_name: string;
  subject?: string;
  preview_text?: string;
  broadcast_content_attributes?: {
    subject: string;
    preview_text: string;
    email_content?: string;
  };
}

interface ResponseData {
  id: number;
}

export const useBroadcastCreate = () => {
  const toastr = useToast();

  return useMutation<ResponseData, ApiError, CreateBroadcastParams>({
    mutationFn: data => broadcastsApi.create(data),
    onError: () => toastr.error(t([i18nBase, "error_saving"])),
  });
};
