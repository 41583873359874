import isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { supportWidgetPaths } from "@circle-react/helpers/urlHelpers";
import { useDebouncedValue } from "@circle-react/hooks/utils/useDebouncedValue";
import { Typography } from "@circle-react-uikit/Typography";
import { Button } from "../Button";
import { PostItem } from "../PostItem";
import { SkeletonLoader } from "../SkeletonLoader";
import { useListPosts } from "../queries";

export const PostList = ({ spaceId, searchText }) => {
  const debouncedsearchText = useDebouncedValue(searchText, 500);
  const { isLoading, data } = useListPosts(spaceId, debouncedsearchText);
  const posts = data?.records || [];

  if (isLoading) {
    return <SkeletonLoader rows={5} />;
  }

  if (isEmpty(posts)) {
    return (
      <div className="flex flex-col justify-center px-6 py-2">
        <div className="mx-auto mb-5 mt-auto w-full">
          <div className="border-primary bg-primary rounded-lg border">
            <div className="mt-4 text-center">
              <Typography.LabelMd weight="semibold" color="dark:text-[#ABAFB2]">
                {t("support_widget.no_articles_found")}
              </Typography.LabelMd>
            </div>
            <div className="mx-6 my-5">
              <Link to={supportWidgetPaths.askChatBot()}>
                <Button
                  label={t("support_widget.still_need_help")}
                  className="dark:bg-gray-50 dark:text-gray-800"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-auto">
      <div className="mt-1.5 flex flex-col justify-center">
        {posts.map(post => (
          <PostItem post={post} key={post.id} />
        ))}
      </div>
    </div>
  );
};
