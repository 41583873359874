import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { TiptapEditor } from "@circle-react-uikit/TipTap";
import { Typography } from "@circle-react-uikit/Typography";
import { BaseView } from "../BaseView";

const i18nRoot = "settings.workflows.view";

export const ViewSendToWebhook = ({ action }) => {
  const { api_params: apiParams } = action || {};
  const { url, description: richTextBody } = apiParams || {};

  return (
    <BaseView>
      {url && (
        <BaseView.Section>
          <BaseView.Title>{t([i18nRoot, "webhook.url"])}</BaseView.Title>
          <div>
            <Typography.LabelSm>{url}</Typography.LabelSm>
          </div>
        </BaseView.Section>
      )}
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "webhook.description"])}</BaseView.Title>
        {!richTextBody ? (
          <Typography.LabelSm>
            {t([i18nRoot, "webhook.empty_description"])}
          </Typography.LabelSm>
        ) : (
          <div className="text-default text-sm">
            <TiptapEditor rich_text_body={richTextBody} editable={false} />
          </div>
        )}
      </BaseView.Section>
    </BaseView>
  );
};

ViewSendToWebhook.propTypes = {
  action: PropTypes.object,
  hasSeparator: PropTypes.bool,
};
