import type { ReactNode } from "react";
import { t } from "@/i18n-js/instance";
import type { ALERT_TYPES } from "@circle-react-shared/uikit/AlertV2/Alert";
import { Alert } from "@circle-react-uikit/AlertV2";
import { Typography } from "@circle-react-uikit/Typography";
import type { WebhookTest } from "../../../generated/WorkflowsApi.types";
import { useWebhookTestResultModal } from "../../WebhookTestResultModal";

interface Props {
  isWebhookTestSuccessful: boolean;
  webhookTestResult: WebhookTest;
}

interface AlertParam {
  type: ALERT_TYPES;
  title: string;
  description: ReactNode;
}

const successParams: AlertParam = {
  type: "success",
  title: t("settings.workflows.action_messages.send_to_webhook.test_success"),
  description: t(
    "settings.workflows.action_messages.send_to_webhook.success_support_text",
  ),
};

const errorParams: AlertParam = {
  type: "alert",
  title: t("settings.workflows.action_messages.send_to_webhook.test_failed"),
  description: t(
    "settings.workflows.action_messages.send_to_webhook.error_support_text",
  ),
};

export const WebhookTestInfo = ({
  isWebhookTestSuccessful,
  webhookTestResult,
}: Props) => {
  const webhookTestDetailModal = useWebhookTestResultModal();
  const handleShowTestDataOutputClick = async () => {
    await webhookTestDetailModal.show({ testResult: webhookTestResult });
  };

  const {
    type: alertType,
    title: alertTitle,
    description: supportText,
  } = isWebhookTestSuccessful ? successParams : errorParams;
  const alertDescription = (
    <span className="flex gap-x-1">
      <Typography.LabelSm>{supportText}</Typography.LabelSm>
      <button
        type="button"
        className="border-light hover:border-darkest border-b border-dashed"
        onClick={handleShowTestDataOutputClick}
      >
        <Typography.LabelSm weight="medium">
          {t(
            "settings.workflows.action_messages.send_to_webhook.show_test_data_output",
          )}
        </Typography.LabelSm>
      </button>
    </span>
  );

  return (
    <div className="mb-9">
      <Alert
        type={alertType}
        title={alertTitle}
        description={alertDescription}
      />
    </div>
  );
};
