import { t } from "@/i18n-js/instance";
import { useSmOrMdOrLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import botBuilderCanvasDark from "@circle-assets/images/bot-builder-canvas-dark.png";
import botBuilderCanvas from "@circle-assets/images/bot-builder-canvas.png";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/agentsQueries";
import { useThemeContext } from "@circle-react/providers";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Form } from "@circle-react-shared/uikit/Form";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Accordion } from "@circle-react-uikit/Accordion";
import { ChatPreview } from "../ChatPreview";
import { CollapsedSidebar } from "../CollapsedSidebar";
import { Header } from "../Header";
import { PauseAISetting } from "./PauseAISetting";
import { RadioGroupBox } from "./RadioGroupBox";
import { DEFAULT_PAUSE_AI_HANDOFF_MESSAGE } from "./constants";

interface FormDataProps
  extends Pick<
    AgentProp,
    | "name"
    | "purpose"
    | "answer_type"
    | "tone"
    | "pause_ai_keywords"
    | "pause_ai_handoff_message"
  > {}

export const BehaviorPage = ({ agent }: { agent: AgentProp }) => {
  const { isDarkAppearance } = useThemeContext();
  const shouldShowChatPreview = !useSmOrMdOrLgScreenMediaQuery();

  const onClose = () => {
    window.location.href = "/settings/ai-agents/agents";
  };

  if (!agent) return null;

  const formDefaultValues: FormDataProps = {
    name: agent.name,
    purpose: agent.purpose,
    answer_type: agent.answer_type,
    tone: agent.tone,
    pause_ai_keywords: agent.pause_ai_keywords,
    pause_ai_handoff_message:
      agent.pause_ai_handoff_message || DEFAULT_PAUSE_AI_HANDOFF_MESSAGE,
  };

  return (
    <div className="flex flex-col">
      <Header agent={agent} onClose={onClose} />
      <div
        className="flex"
        style={{
          backgroundImage: `url(${isDarkAppearance ? botBuilderCanvasDark : botBuilderCanvas})`,
        }}
      >
        <CollapsedSidebar />
        <div className="bg-primary border-r-primary h-[calc(100vh-67px)] w-[453px] flex-col overflow-y-auto border-r">
          <Accordion defaultOpen>
            <div className="border-primary border-b px-6 py-5">
              <Typography.LabelLg weight="semibold">
                {t("community_bot.bot_builder.behavior_page.title")}
              </Typography.LabelLg>
            </div>
            <div className="h-auto px-6 pt-5">
              <Accordion.Item
                title={t("community_bot.bot_builder.behavior_page.general")}
              >
                <div className="text-dark flex items-center">
                  <Form.Label
                    fieldId="purpose"
                    label={t(
                      "community_bot.bot_builder.behavior_page.form.purpose.label",
                    )}
                    hideBorder
                    className="!pt-0"
                    labelClassName="cursor-text text-sm"
                  />
                  <TippyV2
                    content={t(
                      "community_bot.bot_builder.behavior_page.form.purpose.tooltip_text",
                    )}
                  >
                    <Icon
                      type="16-question-mark"
                      className="mb-3 ml-1"
                      size={16}
                      useWithStrokeCurrentColor
                    />
                  </TippyV2>
                </div>
                <div className="mb-5">
                  <Form.Input
                    name="purpose"
                    variant="textarea"
                    defaultValue={formDefaultValues.purpose}
                    rows={4}
                    maxLength={1000}
                    fullWidth
                    focusRequired={false}
                    className="form-control focus:!border-secondary focus:!ring-0 focus:!ring-offset-0"
                    placeholder={t(
                      "community_bot.bot_builder.behavior_page.form.purpose.placeholder",
                    )}
                  />
                </div>
              </Accordion.Item>
            </div>
            <div className="border-primary h-auto border-t px-6 pt-5">
              <Accordion.Item
                title={t("community_bot.bot_builder.behavior_page.style")}
              >
                <Form.Label
                  fieldId="answer_type"
                  label={t(
                    "community_bot.bot_builder.behavior_page.form.answer_type.label",
                  )}
                  hideBorder
                  className="!pt-0"
                  labelClassName="cursor-text text-sm"
                />
                <RadioGroupBox
                  fullWidth
                  name="answer_type"
                  options={[
                    {
                      label: t(
                        "community_bot.bot_builder.behavior_page.form.answer_type.ai_generated",
                      ),
                      value: "ai-generated",
                      rightIcon: (
                        <Icon
                          type="24-ai-list-sparkle"
                          size={24}
                          useWithStrokeCurrentColor
                        />
                      ),
                    },
                    {
                      label: t(
                        "community_bot.bot_builder.behavior_page.form.answer_type.custom",
                      ),
                      value: "custom",
                      disabled: true,
                      rightIcon: (
                        <Icon
                          type="24-note-text"
                          size={24}
                          useWithStrokeCurrentColor
                        />
                      ),
                    },
                  ]}
                />
                <Form.Label
                  fieldId="tone"
                  label={t(
                    "community_bot.bot_builder.behavior_page.form.tone.label",
                  )}
                  hideBorder
                  labelClassName="cursor-text text-sm"
                />
                <RadioGroupBox
                  className="mb-5 grid grid-cols-2 gap-4"
                  name="tone"
                  options={[
                    {
                      label: t(
                        "community_bot.bot_builder.behavior_page.form.tone.normal_label",
                      ),
                      value: "normal",
                      rightIcon: (
                        <Icon
                          type="20-normal-behavior-type"
                          size={24}
                          useWithStrokeCurrentColor
                        />
                      ),
                      description: t(
                        "community_bot.bot_builder.behavior_page.form.tone.normal_description",
                      ),
                    },
                    {
                      label: t(
                        "community_bot.bot_builder.behavior_page.form.tone.friendly_label",
                      ),
                      value: "friendly",
                      rightIcon: (
                        <Icon
                          type="20-friendly-behavior-type"
                          size={24}
                          useWithStrokeCurrentColor
                        />
                      ),
                      description: t(
                        "community_bot.bot_builder.behavior_page.form.tone.friendly_description",
                      ),
                    },
                    {
                      label: t(
                        "community_bot.bot_builder.behavior_page.form.tone.short_and_sweet_label",
                      ),
                      value: "short-and-sweet",
                      rightIcon: (
                        <Icon
                          type="20-short-and-sweet-behavior-type"
                          size={24}
                          useWithStrokeCurrentColor
                        />
                      ),
                      description: t(
                        "community_bot.bot_builder.behavior_page.form.tone.short_and_sweet_description",
                      ),
                    },
                    {
                      label: t(
                        "community_bot.bot_builder.behavior_page.form.tone.descriptive_label",
                      ),
                      value: "descriptive",
                      rightIcon: (
                        <Icon
                          type="20-descriptive-behavior-type"
                          size={24}
                          useWithStrokeCurrentColor
                        />
                      ),
                      description: t(
                        "community_bot.bot_builder.behavior_page.form.tone.descriptive_description",
                      ),
                    },
                  ]}
                />
              </Accordion.Item>
            </div>
            <PauseAISetting formDefaultValues={formDefaultValues} />
          </Accordion>
        </div>
        {shouldShowChatPreview && <ChatPreview />}
      </div>
    </div>
  );
};
