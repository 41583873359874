import { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import {
  getLayoutOptions,
  getMobileLayoutOptions,
} from "@/react/components/Spaces/Edit/EditForm/Customize/constants";
import {
  isBasicPostsSpace,
  isImagesSpace,
  isMembersSpace,
} from "@/react/helpers/spaceHelpers";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import { Form } from "@circle-react-uikit/Form";

export const CustomizeLayoutForm = ({ space, onChange }) => {
  const {
    watch,
    setValue,
    formState: { dirtyFields },
  } = useFormContext();
  const visibleTabs = watch("visible_tabs");
  const displayView = watch("display_view");
  const showRightSidebarField = watch("show_right_sidebar");
  const shouldHideMembersSidebarBlock = watch("hide_member_sidebar");
  const isCardsView = displayView === "cards";
  const shouldShowHideMembersSwitch = isBasicPostsSpace(space) && !isCardsView;
  const showToggleSidebar = !isCardsView;
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();

  useEffect(() => {
    if (
      "show_right_sidebar" in dirtyFields ||
      "hide_member_sidebar" in dirtyFields ||
      "visible_tabs" in dirtyFields ||
      "display_view" in dirtyFields
    ) {
      onChange(true);
    }
  }, [
    dirtyFields.visible_tabs,
    dirtyFields.display_view,
    dirtyFields.show_right_sidebar,
    dirtyFields.hide_member_sidebar,
  ]);

  // set the default value for the switch based on the visible tabs (if members is visible or not)
  useEffect(() => {
    if (visibleTabs?.includes("members")) {
      setValue("hide_member_sidebar", true);
    } else {
      setValue("hide_member_sidebar", false);
    }
  }, [space]);

  // change the visible tabs array to only posts or posts and members (members being the condition to render the block)
  useEffect(() => {
    if (shouldShowHideMembersSwitch) {
      if (shouldHideMembersSidebarBlock) {
        setValue("visible_tabs", ["posts", "members"]);
      } else {
        setValue("visible_tabs", ["posts"]);
      }
    }
  }, [shouldHideMembersSidebarBlock, shouldShowHideMembersSwitch]);

  const isSpaceMembersSpace = isMembersSpace(space);
  const isSpaceImageSpace = isImagesSpace(space);
  const excludedOptions = isSpaceMembersSpace ? ["posts"] : [];
  const isHorizontalLayout =
    isMobileWebNewSpaceCustomizationUIEnabled || isSpaceImageSpace;
  const layoutOptions = (
    <Form.Item
      hideBorder
      hideDescription
      label={t("spaces.form.edit.customize.layout.label")}
      name="display_view"
      variant="extra-bottom-margin"
    >
      <Form.RadioGroup
        fullWidth={false}
        labelOutside={!isHorizontalLayout}
        options={
          isMobileWebNewSpaceCustomizationUIEnabled
            ? getMobileLayoutOptions({
                excludedOptions,
                postType: space.post_type,
              })
            : getLayoutOptions({ excludedOptions, postType: space.post_type })
        }
        optionAlignment={isHorizontalLayout ? "row" : "center"}
        className="!grid !grid-cols-1 sm:!grid sm:!grid-cols-3"
      />
    </Form.Item>
  );

  if (isSpaceMembersSpace || isSpaceImageSpace) {
    return <div>{layoutOptions}</div>;
  }

  return (
    <div>
      {layoutOptions}
      {showToggleSidebar && (
        <Form.Item
          name="show_right_sidebar"
          label={t("spaces.form.edit.customize.right_sidebar")}
          className="!pb-0"
          inlineReverse
          hideBorder
          hideDescription
          shouldToggleValueOnLabelClick
        >
          <Form.ToggleSwitch variant="small" />
        </Form.Item>
      )}
      {shouldShowHideMembersSwitch ? (
        <Form.Item
          name="hide_member_sidebar"
          label={t("spaces.form.edit.customize.show_sidebar_members_block")}
          className="!pb-0"
          isDisabled={!showRightSidebarField}
          inlineReverse
          hideBorder
          hideDescription
          shouldToggleValueOnLabelClick
        >
          <Form.ToggleSwitch
            disabled={!showRightSidebarField}
            variant="small"
          />
        </Form.Item>
      ) : null}
    </div>
  );
};

CustomizeLayoutForm.propTypes = {
  onChange: PropTypes.func,
  space: PropTypes.object,
};
