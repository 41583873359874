import { t } from "@/i18n-js/instance";
import {
  canCreatePostInSpace,
  isImagesSpace,
} from "@/react/helpers/spaceHelpers";
import { useQuickPostV2Modal } from "@circle-react/components/QuickPostV2";
import type { Space } from "@circle-react/types/Space";
import { EmptyState } from "@circle-react-uikit/EmptyState";

export interface NoPostsProps {
  space: Space;
}

export const PostsEmptyState = ({ space }: NoPostsProps) => {
  const isSpaceImagesSpace = isImagesSpace(space);
  const quickPostModal = useQuickPostV2Modal();

  if (isSpaceImagesSpace) {
    return (
      <EmptyState
        header={t("image_space.empty_state.title")}
        description={t("image_space.empty_state.description")}
      />
    );
  }

  if (!canCreatePostInSpace(space)) {
    return <EmptyState header={t("no_posts_to_show")} />;
  }

  return (
    <EmptyState
      header={t("empty_state.posts.title")}
      description={t("empty_state.posts.description")}
      primaryLabel={t("empty_state.posts.create_post")}
      onPrimaryClick={() => quickPostModal.show({ spaceId: space.id })}
    />
  );
};
