import { useQuery } from "react-query";
import { useCommunityInboxStore } from "@/react/hooks/chatsV2/store";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

export const useCommunityChat = (id: number, isDms: boolean) => {
  const { adminChats, memberChats } = useCommunityInboxStore();
  const chats = isDms ? memberChats : adminChats;
  const currentChat = chats?.find(chat => chat.id === id);

  const fetchChat = () =>
    reactQueryGet(internalApi.communityInbox.show({ id }));

  const {
    data: chat,
    isLoading,
    error,
  } = useQuery(["community-inbox", "chat-room", id], fetchChat, {
    enabled: !!id && !currentChat,
  });

  return {
    isChatLoading: isLoading,
    currentChat: currentChat || chat,
    error,
  };
};
