import { ChatItem } from "@circle-react/components/SettingsApp/CommunityInbox/Sidebar/ChatList/ChatItem";
import { chatBotPath } from "@circle-react/helpers/urlHelpers";
import type { Chat } from "@circle-react/types/CommunityInbox/Chat";

interface BotChatItemProps {
  chat: Chat;
}

export const BotChatItem = ({ chat }: BotChatItemProps) => (
  <ChatItem
    key={chat.id}
    chat={chat}
    path={chatBotPath({ id: chat.id.toString() })}
    isUnread={chat.has_unread_messages_for_member}
    isBotType
    shouldHightlightChat
  />
);
