import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { communityPaymentProcessorApi } from "@circle-react/api/paywalls/communityPaymentProcessorApi";
import type {
  CreateParams,
  ResponseParams,
} from "@circle-react/api/paywalls/communityPaymentProcessorApi";
import { useToast } from "@circle-react-uikit/ToastV2";

interface UseCommunityPaymentProcessor {
  onSuccess?: (response: ResponseParams, _payload: CreateParams) => void;
  onError?: (response: Record<string, string>) => void;
}

export const useCommunityPaymentProcessor = ({
  onSuccess,
  onError,
}: UseCommunityPaymentProcessor) => {
  const defaultErrorMessage = t("settings.paywalls.alerts.error.action_failed");
  const { error } = useToast();

  const { mutate, isLoading, isSuccess } = useMutation<
    ResponseParams,
    Record<string, string>,
    CreateParams
  >(formData => communityPaymentProcessorApi.create(formData));

  const onSuccessDefault = (response: ResponseParams) => {
    window.location.href = response.url;
  };

  const onErrorDefault = (response: Record<string, string>) => {
    error(response?.message || defaultErrorMessage);
  };

  const onSubmit = (payload: CreateParams) => {
    mutate(payload, {
      onSuccess: onSuccess || onSuccessDefault,
      onError: onError || onErrorDefault,
    });
  };

  return {
    onSubmit,
    isLoading,
    isSuccess,
  };
};
