import { t } from "@/i18n-js/instance";
import type { CommunityMember } from "@circle-react/types";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";
import { useAccessGroupData } from "../../DataStoreContext/AccessGroupDataContext";

interface NameCellProps {
  member: CommunityMember;
  isAvatarHidden?: boolean;
}

export const NameCell = ({
  member,
  isAvatarHidden = false,
}: Readonly<NameCellProps>) => {
  const { showMemberProfileEdit } = useAccessGroupData();
  const { name, avatar_url, email } = member;

  if (!name) {
    return <>{t("dash")}</>;
  }

  return (
    <button
      className="flex items-center gap-3 text-left"
      type="button"
      onClick={() => showMemberProfileEdit({ member })}
    >
      {!isAvatarHidden && <UserImage src={avatar_url} name={name} size="6" />}
      <div className="grid pr-4">
        <Typography.LabelSm weight="semibold">{name}</Typography.LabelSm>

        <Typography.LabelSm color="text-default">{email}</Typography.LabelSm>
      </div>
    </button>
  );
};
