import { useMutation } from "react-query";
import type { SortableElementProps } from "react-sortable-hoc";
import { SortableElement } from "react-sortable-hoc";
import { useAddSpaceModal } from "@circle-react/components/Modals/AddSpaceModal";
import { useEditSpaceGroupModal } from "@circle-react/components/Modals/EditSpaceGroupModal";
import type { SpaceGroupWithSpaces } from "@circle-react/components/Spaces/Sidebar";
import { usePunditUserContext } from "@circle-react/contexts";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { canManageSpaceGroups } from "@circle-react/helpers/spaceGroupHelpers";
import { spaceGroupPaths } from "@circle-react/helpers/urlHelpers";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { usePlatformMenu } from "../../PlatformMenu/usePlatformMenu";
import { SpaceList } from "../SpaceList";
import { isTouchEvent } from "../utils";
import { SpaceGroupView } from "./SpaceGroupView";

export interface SpaceGroupProps extends SortableElementProps {
  spaceGroup: SpaceGroupWithSpaces;
  onSpaceSortEnd: (props: {
    oldIndex: number;
    newIndex: number;
    spaceGroup: SpaceGroupWithSpaces;
  }) => void;
  position: number;
  spaceGroupDragProperties?: {
    index: number;
    isDragging: boolean;
  };
}

export const SpaceGroup = SortableElement(
  ({
    spaceGroup,
    onSpaceSortEnd,
    position,
    spaceGroupDragProperties,
  }: SpaceGroupProps) => {
    const { currentCommunityMember } = usePunditUserContext();
    const [isCollapsed, toggleIsCollapsed] = useBoolean(spaceGroup.collapsed);
    const editSpaceGroupModal = useEditSpaceGroupModal();
    const spaceModal = useAddSpaceModal();
    const { toggleSpaceGroupDrawer } = usePlatformMenu();

    const getIsCollapsed = () => {
      const isDragging = Boolean(
        position === spaceGroupDragProperties?.index &&
          spaceGroupDragProperties?.isDragging,
      );

      if (isDragging) return true;

      return isCollapsed;
    };

    const { mutate: toggleVisibility } = useMutation(() =>
      reactQueryPost(
        spaceGroupPaths.toggleVisibility({
          spaceGroupId: String(spaceGroup.id),
        }),
      ),
    );

    const isAdmin = isCommunityAdmin(currentCommunityMember);
    const canManage = canManageSpaceGroups({ spaceGroup });

    const handleCollapseToggle = () => {
      toggleIsCollapsed();
      toggleVisibility();
    };

    const showMembersModal = () =>
      editSpaceGroupModal.show({
        spaceGroup,
        tab: "Members",
      });

    const showCreateSpaceModal = () =>
      spaceModal.show({ spaceGroupId: spaceGroup.id });

    const showEditSpaceGroupModal = () =>
      editSpaceGroupModal.show({
        spaceGroup,
        tab: "Details",
      });

    const spaceGroupId = spaceGroup.slug;
    const listId = `${spaceGroup.slug}-list`;

    return (
      <SpaceGroupView
        id={spaceGroupId}
        listId={listId}
        position={position}
        isCollapsed={getIsCollapsed()}
        isDragging={spaceGroupDragProperties?.isDragging}
        canManage={canManage}
        onCollapseToggle={handleCollapseToggle}
        spaceGroup={spaceGroup}
        onEditSpaceGroupClick={showEditSpaceGroupModal}
        onCreateSpaceClick={showCreateSpaceModal}
        onCreateSpaceGroupClick={toggleSpaceGroupDrawer}
        isAdmin={isAdmin}
        onAddMembersClick={showMembersModal}
      >
        <SpaceList
          listId={listId}
          distance={10}
          lockAxis="y"
          shouldCancelStart={e => isTouchEvent(e)}
          onSortEnd={({ oldIndex, newIndex }) =>
            onSpaceSortEnd({ oldIndex, newIndex, spaceGroup })
          }
          spaceGroup={spaceGroup}
        />
      </SpaceGroupView>
    );
  },
);
