import { t } from "@/i18n-js/instance";
import { AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { useIsAccessGroupsWorkflowsEnabled } from "@circle-react/hooks/accessGroups/useIsAccessGroupsWorkflowsEnabled";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../types";
import { FormSelectAccessGroup } from "./shared/FormSelectAccessGroup";
import { HistoryDetailViewAccessGroup } from "./shared/HistoryDetailViewAccessGroup";
import type { HistoryDetailViewAccessGroupProps } from "./shared/HistoryDetailViewAccessGroup";
import { ViewAccessGroup } from "./shared/ViewAccessGroup";

export const removeCommunityMember: Readonly<
  ActionOption<HistoryDetailViewAccessGroupProps>
> = {
  label: t(
    "settings.workflows.form.actions.remove_community_member_from_access_group",
  ),
  value: "remove_community_member_from_access_group",
  group: t("settings.workflows.form.action_groups.access_group"),
  leftAddon: <Icon type="16-folder-remove" size={16} className="!h-4 !w-4" />,
  formComponent: FormSelectAccessGroup,
  viewComponent: ViewAccessGroup,
  historyDetailViewComponent: HistoryDetailViewAccessGroup,
  icon: "user-remove",
  isApplicableToMembersOnly: true,
  released: useIsAccessGroupsWorkflowsEnabled,
  availablePlanTier:
    AVAILABLE_WORKFLOW_ACTION_AS_PER_PLAN_TIERS.HIGHER_TIER_PLAN_ONLY,
} as const;
