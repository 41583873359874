import { useEffect, useState } from "react";
import classNames from "classnames";
import { useQueryClient } from "react-query";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";
import { HeaderActions } from "./HeaderActions";
import { PostHeaderSkeleton } from "./SkeletonLoader/PostHeaderSkeleton";

export interface ModalHeaderProps {
  onChange: () => void;
  spaceId: any;
  isLoading?: boolean;
  invalidateQueries?: () => void;
  onPostDestroy: () => void;
}

export const ModalHeader = ({
  onChange,
  spaceId,
  isLoading = false,
  invalidateQueries,
  onPostDestroy,
}: ModalHeaderProps) => {
  const queryClient = useQueryClient();
  const { post } = useCurrentPostHelpers();
  const { name } = post;
  const isSmScreen = useSmScreenMediaQuery();
  const [isContentVisible, setIsContentVisible] = useState(!isLoading);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!isLoading) {
      timer = setTimeout(() => setIsContentVisible(true), 50);
    } else {
      setIsContentVisible(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isLoading]);

  const invalidateSpacePosts = () => {
    void queryClient.invalidateQueries(["posts", spaceId]);
  };

  if (isLoading) {
    return <PostHeaderSkeleton />;
  }

  return (
    <Modal.Header
      className={classNames(
        "flex w-full items-center gap-4",
        "transition-opacity duration-300 ease-in-out",
        "!px-4 !py-3 md:!px-8 md:!pb-2 md:!pt-6",
        {
          "opacity-0": !isContentVisible,
          "opacity-100": isContentVisible,
          "!justify-end": !name,
        },
      )}
    >
      {name && !isSmScreen && <Typography.TitleMd>{name}</Typography.TitleMd>}
      <HeaderActions
        onChange={onChange}
        onPostDestroy={onPostDestroy}
        invalidateQueries={invalidateQueries}
        invalidateSpacePosts={invalidateSpacePosts}
      />
    </Modal.Header>
  );
};
