import { useState } from "react";
import { customProgressEmitter } from "@circle-react-shared/uikit/ToastV2";
import type { ToastCustomProgress } from "@circle-react-shared/uikit/ToastV2/toastCustomProgressEmitter";

export const useCustomProgressToast = () => {
  const [toastsIdsMap, setToastsIdsMap] = useState<Record<string, boolean>>({});

  const updateToastProgress = (customProgress: ToastCustomProgress) => {
    customProgressEmitter.setCustomProgress(customProgress);
  };

  return {
    toastsIdsMap,
    setToastsIdsMap,
    updateToastProgress,
  };
};
