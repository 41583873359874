import { t } from "@/i18n-js/instance";
import { AmountField } from "../Fields/AmountField";
import { BillingFrequencyField } from "../Fields/BillingFrequencyField";
import { InputField } from "../Fields/InputField";
import { TrialDaysField } from "../Fields/TrialDaysField";

const localeRoot =
  "settings.paywalls.modal.tabs.pricing.add_price_modal.tooltip_text";

export const SubscriptionForm = () => (
  <div className="flex flex-col gap-4">
    <div className="flex flex-row gap-x-4">
      <InputField
        name="subscription_apple_processor_id"
        tooltipText={t([localeRoot, "subscription_apple_processor_id"])}
      />
      <InputField
        name="subscription_google_processor_id"
        tooltipText={t([localeRoot, "subscription_google_processor_id"])}
      />
    </div>
    <div className="flex flex-row gap-x-4">
      <div className="basis-1/2">
        <BillingFrequencyField />
      </div>
      <div className="basis-1/2">
        <AmountField name="amount" labelKey="recurring_price" />
      </div>
    </div>
    <TrialDaysField />
  </div>
);
