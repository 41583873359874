import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useSpaceMutation } from "@circle-react/components/Spaces/hooks";
import { useSpaceResource } from "@circle-react/contexts";
import { useToast } from "@circle-react-uikit/ToastV2";
import { LockscreenView } from "./LockscreenView";
import { LockScreenProviders } from "./Providers";

const LockscreenContainer = ({ space = {} }) => {
  const { success, error } = useToast();
  const { refetch } = useSpaceResource({ spaceId: space.id });

  const { mutate: saveSpace } = useSpaceMutation({
    space,
    options: {
      onSuccess: () => {
        refetch();
        success(t("courses.lockscreen.toast.saved"));
      },
      onError: () => {
        error(t("courses.lockscreen.toast.save_error"));
      },
    },
  });

  return <LockscreenView space={space} onSave={saveSpace} />;
};

LockscreenContainer.propTypes = {
  space: PropTypes.object,
};

export const Lockscreen = ({ space = {} }) => (
  <LockScreenProviders space={space}>
    <LockscreenContainer space={space} />
  </LockScreenProviders>
);

Lockscreen.propTypes = {
  space: PropTypes.object,
};
