import { t } from "@/i18n-js/instance";
import { BookmarkForm } from "@/react/components/Spaces/Bookmarks/BookmarkForm";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { Space } from "@circle-react/types";
import { Modal } from "@circle-react-uikit/ModalV2";
import { SubmitFooter } from "./SubmitFooter";

export interface AddBookmarkModalProps {
  isFolder?: boolean;
  space: Space;
  folderId?: string;
  bookmark?: any;
  isEdit?: boolean;
}

export const AddBookmarkModalComponent = ({
  isFolder = true,
  space,
  folderId,
  bookmark,
  isEdit = false,
}: AddBookmarkModalProps) => {
  const drawer = useModal();
  const editOrCreateSuffix = isEdit ? "edit" : "create";
  const linkOrFolderSuffix = isFolder ? "folder" : "link";

  return (
    <Modal isOpen={drawer.visible} onClose={drawer.hide}>
      <Modal.Overlay />
      <Modal.Content className="!overflow-visible">
        <Modal.Header>
          <Modal.Title className="text-center" size="md">
            {t(`${editOrCreateSuffix}_${linkOrFolderSuffix}`, {
              scope: "spaces.bookmarks",
            })}
          </Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={drawer.hide} />
          </Modal.HeaderActions>
        </Modal.Header>

        <Modal.Body className="!overflow-visible px-2">
          <BookmarkForm
            space={space}
            bookmark={bookmark}
            folderId={folderId}
            isFolder={isFolder}
            isEdit={isEdit}
            renderFooter={SubmitFooter}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const AddBookmarkModal = ModalManager.create(
  ({ folderId, space, isFolder, bookmark, isEdit }: AddBookmarkModalProps) => (
    <AddBookmarkModalComponent
      folderId={folderId}
      space={space}
      isFolder={isFolder}
      bookmark={bookmark}
      isEdit={isEdit}
    />
  ),
);

export const useAddBookmarkModal = () => useModal(AddBookmarkModal);
