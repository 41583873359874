import { usePunditUserContext } from "@circle-react/contexts";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { useWebSocket } from "@circle-react/hooks/useWebSocket";
import type { AdminChannelMessage } from "@circle-react/types/CommunityInbox/AdminChannelMessage";

export const useCommunityInboxSync = () => {
  const { currentCommunity } = usePunditUserContext();
  const communityId = currentCommunity?.id;
  const {
    appendMessage,
    updateMessage,
    destroyMessage,
    appendChat,
    updateChat,
    destroyChat,
  } = useCommunityInboxStore();

  return useWebSocket(
    {
      channel: "CommunityInbox::AdminChannel",
      community_id: communityId,
      canCreateConnection: !!communityId,
      onMessageReceive: (data: AdminChannelMessage) => {
        switch (data.event) {
          case "createMessage": {
            appendMessage(data.json_message, "admin");
            break;
          }
          case "updateMessage": {
            updateMessage(data.json_message);
            break;
          }
          case "destroyMessage": {
            destroyMessage(data.json_message.id);
            break;
          }
          case "createChat": {
            const chat = data.json_message;
            if (chat.has_member_interaction) {
              appendChat(chat, "admin");
            }
            break;
          }
          case "updateChat": {
            const chat = data.json_message;
            if (chat.has_member_interaction) {
              updateChat(chat, "admin");
            }
            break;
          }
          case "destroyChat": {
            destroyChat(data.json_message.id, "admin");
            break;
          }
          default:
            break;
        }
      },
    },
    [communityId],
  );
};
