import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { useLeaveSpaceConfirmationModal } from "@/react/components/Modals/LeaveSpaceConfirmationModal";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { spaceIconsV3 } from "./useSpaceOptionsIcons";

export const LeaveSpaceOption = () => {
  const leaveSpaceConfirmationModal = useLeaveSpaceConfirmationModal();
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { leaveSpaceIcon } = spaceIconsV3;

  return (
    <Dropdown.ItemWithLink
      key={t("space_header.leave_space")}
      onClick={isViewOnlyMasquerading ? noop : leaveSpaceConfirmationModal.show}
    >
      <Dropdown.IconContainer>
        <span className="h-5 w-5">
          <Icon type={leaveSpaceIcon} className="text-v2-danger" size={20} />
        </span>
        <Typography.LabelXsPlus weight="medium" color="text-v2-danger">
          {t("space_header.leave_space")}
        </Typography.LabelXsPlus>
      </Dropdown.IconContainer>
    </Dropdown.ItemWithLink>
  );
};
