import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { workflowsApi } from "@/react/api";
import { useSupportUnreleasedWorkflowsFeaturesEnabled } from "@circle-react/components/shared/Workflows/hooks/useSupportUnreleasedWorkflowsFeaturesEnabled";
import type { Params } from "@circle-react/helpers/urlHelpers/toQueryString";

const PER_PAGE = 100;

export const useTemplates = (category?: string) => {
  const hasUnreleasedWorkflows = useSupportUnreleasedWorkflowsFeaturesEnabled();

  const fetchPage = (page: number) => {
    const queryParams: Params = {
      page,
      per_page: PER_PAGE,
      v2_2_enabled: true,
    };

    if (category) {
      queryParams.category = category;
    }

    return workflowsApi.templates({ params: queryParams });
  };

  const { data, isLoading, fetchNextPage } = useInfiniteQuery<any, Error>(
    "workflow-templates-list",
    ({ pageParam = 1 }: { pageParam?: number }) => fetchPage(pageParam),
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  const pages = data?.pages || [];
  const allTemplates = flatten(pages.map(page => page.records));
  const availableTemplates = hasUnreleasedWorkflows
    ? allTemplates
    : allTemplates.filter(template => template.released);

  return {
    templates: availableTemplates,
    isLoading,
    fetchNextPage,
  };
};
