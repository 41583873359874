import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useFieldStatus } from "./useFieldStatus";

export const useResourceStatus = resourcePath => {
  const { trigger } = useFormContext();
  const { isDirty, isTouched, hasError, hasValue, hasId, value } =
    useFieldStatus(resourcePath);

  const isCompleted = hasId && hasValue && !isDirty && !isTouched && !hasError;

  const statusMessage = hasError
    ? t("settings.workflows.form.step_incomplete")
    : isCompleted
      ? t("settings.workflows.form.step_complete")
      : "";

  const revalidate = () => trigger(resourcePath);

  return {
    hasError,
    hasValue,
    isCompleted,
    revalidate,
    statusMessage,
    wasSaved: hasId,
    value,
  };
};
