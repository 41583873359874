import { t } from "@/i18n-js/instance";
import {
  LOCATION_TYPES,
  LOCATION_TYPE_TAGS,
} from "@circle-react/components/Events/helpers/constants";
import { Chip } from "@circle-react-uikit/Chip";

export const EventLocationChip = ({ eventData }: any) => {
  const { location_type: locationType } = eventData || {};

  const isLiveStream = locationType === LOCATION_TYPES.LIVE_STREAM;
  const isLiveRoom = locationType === LOCATION_TYPES.LIVE_ROOM;

  switch (true) {
    case locationType === LOCATION_TYPES.VIRTUAL: {
      return (
        <Chip
          title={t("events.locations.virtual")}
          icon="16-camera"
          variant="primary"
        />
      );
    }
    case isLiveStream || isLiveRoom: {
      return (
        <Chip
          title={t(
            isLiveStream
              ? "events.locations.live_stream"
              : "events.locations.live_room",
          )}
          icon="16-camera"
          variant="primary"
        />
      );
    }
    case locationType === LOCATION_TYPES.TBD ||
      locationType === LOCATION_TYPES.IN_PERSON: {
      return (
        <Chip title={LOCATION_TYPE_TAGS[locationType]} icon="16-location" />
      );
    }
    default: {
      return <Chip title={LOCATION_TYPE_TAGS[locationType]} icon="16-camera" />;
    }
  }
};
