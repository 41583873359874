import { t } from "@/i18n-js/instance";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Modal } from "@circle-react-uikit/ModalV2";

interface BackButtonProps {
  hideForm: () => void;
}

export const BackButton = ({ hideForm }: BackButtonProps) => (
  <Modal.HeaderActions>
    <IconButton
      ariaLabel={t("back")}
      name="20-chevron-left"
      onClick={hideForm}
      iconSize={20}
      buttonSize={28}
      iconClassName="!text-dark"
      className="mr-2"
      variant="primary"
    />
  </Modal.HeaderActions>
);
