import { Suspense } from "react";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const DiscoverComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "DiscoverPage" */ "@circle-react/components/DiscoverComponent"
    ),
);

export const DiscoverPage = () => (
  <VersionedContent fullWidth fullHeight={false}>
    <Suspense fallback={<PageLoader />}>
      <DiscoverComponent />
    </Suspense>
  </VersionedContent>
);
