import { t } from "@/i18n-js/instance";

export const tabs = {
  DETAILS_TAB: t("settings.paywalls.modal.tabs.details.title"),
  PRICING_TAB: t("settings.paywalls.modal.tabs.pricing.title"),
  ACCESS_TAB: t("settings.paywalls.modal.tabs.access.title"),
  POST_PURCHASE_TAB: t("settings.paywalls.modal.tabs.post_purchase.title"),
  TRACKING_TAB: t("settings.paywalls.modal.tabs.tracking.title"),
  WORKFLOWS_TAB: t("settings.paywalls.modal.tabs.workflows.title"),
};

export const PaymentProcessorStatus = {
  NOT_EXIST: "PAYMENT_PROCESSOR_NOT_EXIST",
  RESTRICTED: "PAYMENT_PROCESSOR_RESTRICTED",
  RESTRICED_FOR_APPLICATION_FEES:
    "PAYMENT_PROCESSOR_RESTRICTED_FOR_APPLICATION_FEES",
  PENDING: "PAYMENT_PROCESSOR_PENDING",
  REJECTED: "PAYMENT_PROCESSOR_REJECTED",
  ENABLED: "PAYMENT_PROCESSOR_ENABLED",
};

export const columnsListComponent = [
  {
    title: t("settings.paywalls.list.form.display_name_label"),
    dataIndex: "displayName",
    key: "displayName",
  },
  {
    title: t("settings.paywalls.list.form.amount_label"),
    dataIndex: "preferred_price",
    key: "preferred_price",
  },
  {
    title: t("settings.paywalls.list.form.status_label"),
    dataIndex: "status",
    key: "status",
  },
  {
    title: " ",
    dataIndex: "action",
    key: "action",
  },
  {
    title: " ",
    dataIndex: "actionMenu",
    key: "actionMenu",
  },
];

export const PAYWALL_LIST_QUERY_KEY = "paywalls";
