import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";

export const SelfCancelableToggle = ({
  tooltipText,
  isToggleDisabled,
  onChanging,
}: any) => (
  <Form.Item
    className="!py-0"
    labelClassName="!font-normal"
    name="self_cancelable"
    tooltipText={t(`settings.paywalls.modal.tabs.pricing.${tooltipText}`)}
    translationRoot="settings.paywalls.modal.tabs.pricing"
    fullWidth
    hideDescription
    hidePlaceholder
    hideBorder
    inlineReverse
    isDisabled={isToggleDisabled}
  >
    <Form.ToggleSwitch
      onToggleChange={onChanging}
      name="self_cancelable"
      variant="small"
      disabled={isToggleDisabled}
    />
  </Form.Item>
);
