import { t } from "@/i18n-js/instance";
import { usePauseAiAction } from "@/react/components/SettingsApp/CommunityInbox/hooks/usePauseAiAction";
import { usePunditUserContext } from "@/react/contexts/punditUserContext";
import { classNames } from "@/react/helpers/twMergeWithCN";
import { useEnableModal } from "@/react/pages/BotBuilder/EnableModal";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";
import { UpgradeToEnterpriseButton } from "./UpgradeToEnterpriseButton";

const useAgentEligibility = (chat: any) => {
  const { currentCommunity } = usePunditUserContext();

  return {
    isAgentEligibleButDisabled:
      !chat.community_bot_profile?.enabled &&
      currentCommunity?.ai_agents_feature_flag_enabled,
    isChatDisabled: chat.disabled && chat.community_bot_profile?.enabled,
  };
};

export const PauseAIButton = ({ chat }: { chat: any }) => {
  const toastr = useToast();
  const { currentCommunity } = usePunditUserContext();
  const { pauseAi, isPauseAiLoading, resumeAi, isResumeAiLoading } =
    usePauseAiAction();

  const enableModal = useEnableModal(chat.community_bot_profile);

  const { isAgentEligibleButDisabled, isChatDisabled } =
    useAgentEligibility(chat);

  const toggleAI = async (isPausing: boolean) => {
    if (isChatDisabled) {
      return;
    }
    if (isAgentEligibleButDisabled) {
      void enableModal.show();
    } else {
      try {
        await (isPausing ? pauseAi(chat.id) : resumeAi(chat.id));
      } catch (error) {
        toastr.error(t("messaging.actions.pause_ai_error"));
      }
    }
  };

  if (!currentCommunity?.ai_agents_feature_flag_enabled) {
    return <UpgradeToEnterpriseButton />;
  }

  if (chat.ai_snoozed) {
    return (
      <TippyV2
        enabled={isChatDisabled}
        content={t("community_bot.pause_ai.tooltip")}
      >
        <button
          type="button"
          onClick={() => toggleAI(false)}
          className={classNames(
            "h-[34px] rounded-full border bg-[#15803D] px-4 transition-all duration-300 hover:bg-[#15803D]/90",
            {
              "cursor-not-allowed opacity-80": isChatDisabled,
            },
          )}
        >
          <div className="flex items-center space-x-1">
            <Icon
              type={isResumeAiLoading ? "loader" : "16-ai-agent"}
              size={16}
              className="text-white"
            />
            <Typography.LabelSm weight="bold" color="text-white">
              {t(
                isResumeAiLoading
                  ? "community_bot.ai_resuming"
                  : "community_bot.resume_ai",
              )}
            </Typography.LabelSm>
          </div>
        </button>
      </TippyV2>
    );
  }
  return (
    <TippyV2
      enabled={isChatDisabled}
      content={t("community_bot.pause_ai.tooltip")}
    >
      <button
        type="button"
        onClick={() => toggleAI(true)}
        className={classNames(
          "hover:bg-secondary h-[34px] rounded-full border px-4 transition-all duration-300",
          {
            "cursor-not-allowed opacity-80": isChatDisabled,
          },
        )}
      >
        <div className="flex items-center space-x-1">
          <Icon
            type={isPauseAiLoading ? "loader" : "16-ai-snooze"}
            size={16}
            className="text-dark font-medium"
          />
          <Typography.LabelSm weight="medium">
            {t(
              isPauseAiLoading
                ? "community_bot.ai_pausing"
                : "community_bot.pause_ai.title",
            )}
          </Typography.LabelSm>
        </div>
      </button>
    </TippyV2>
  );
};
