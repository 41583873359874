import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { useFreeTrial } from "../../hooks/useFreeTrial";

export const TrialToggle = () => {
  const { hasPricesEligibleForTrial, hasTrial } = useFreeTrial();

  return (
    <Form.Item
      className="!py-0"
      labelClassName="!font-normal"
      name="enable_trial"
      translationRoot="settings.paywalls.modal.tabs.pricing"
      tooltipText={t(
        "settings.paywalls.modal.tabs.pricing.enable_trial_tooltip",
      )}
      fullWidth
      hideDescription
      hidePlaceholder
      hideBorder
      inlineReverse
      variant="checkout-path"
      isDisabled={!hasPricesEligibleForTrial()}
    >
      <Form.ToggleSwitch
        name="enable_trial"
        variant="small"
        disabled={!hasPricesEligibleForTrial()}
        value={String(hasTrial)}
      />
    </Form.Item>
  );
};
