import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface InvitedByTabProps {
  name: string;
  isFeaturedEvent?: boolean;
}
export const InvitedByTab = ({
  name,
  isFeaturedEvent = false,
}: InvitedByTabProps) => (
  <div
    className={classNames(
      "mb-5 flex h-[44px] w-full items-center justify-start gap-x-3 bg-green-100",
      isFeaturedEvent ? "rounded-t-lg sm:rounded-lg" : "rounded-lg",
    )}
  >
    <Icon type="20-users-plus" size={20} className="ms-3" />
    <Typography.LabelXs weight="medium" color="text-[#18181B]">
      {t("events.rsvp.invited_by", { name })}
    </Typography.LabelXs>
  </div>
);
