import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { cannotPurchaseAgain } from "@circle-react/helpers/paywallCheckoutHelpers";
import { memberProfileModalPaths } from "@circle-react/helpers/urlHelpers";

export const CheckoutFormAlerts = () => {
  const {
    paywall,
    communityRootUrl,
    hasAlreadyPurchased,
    paymentError,
    paywallFailure,
  } = usePaywallCheckoutContext();

  let level = "";
  let message = "";

  if (cannotPurchaseAgain(hasAlreadyPurchased, paywall)) {
    level = "warning";
    message = t("services.paywalls.failures.paywall.already_purchased", {
      billing_url: `${communityRootUrl}${memberProfileModalPaths.billing.slice(
        1,
      )}`,
    });
  } else if (paywallFailure) {
    level = paywallFailure.level;
    message = paywallFailure.message;
  } else if (paymentError && paymentError.message) {
    level = paymentError.type;
    message = paymentError.message;
  }

  if (!level || !message) {
    return null;
  }

  return (
    <div
      className={classNames("rounded border px-6 py-5", {
        "border-red-500 bg-red-50 text-red-800": level === "danger",
        "border-yellow-500 bg-yellow-50 text-yellow-800": level === "warning",
      })}
      dangerouslySetInnerHTML={{
        __html: message,
      }}
      data-testid="checkout-alert"
    />
  );
};
