import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Form } from "@circle-react-uikit/Form";
import { internalApi } from "../../../../helpers/urlHelpers";
import { ChatEnded, TYPE_AI_CHAT, TYPE_LIVE_CHAT } from "../../ChatEnded";
import { ChatForm } from "../../ChatForm";
import { ChatMessage } from "../../ChatMessage";
import { Thinking } from "../../ChatMessage/Thinking";
import { FormAttachment } from "../../FormAttachment";
import { HeaderIconButton } from "../../HeaderIconButton";
import { LiveChatForm } from "../../LiveChatForm";
import {
  LiveChatLeaveDialog,
  WARNING_WHEN_CHATTING,
  WARNING_WHEN_IN_QUEUE,
} from "../../LiveChatLeaveDialog";
import { useSupportWidgetContext } from "../../SupportWidgetContext";
import { useChat } from "../../hooks/useChat";
import { useSunshineUploads } from "../../hooks/useSunshineUploads";
import { ordinalize } from "./ordinalize";

const ACTION_COLLAPSE = "collapse";
const ACTION_BACK = "back";

export const ChatBotView = () => {
  const {
    sessionId,
    conversationType,
    messages,
    setMessages,
    isBotProcessing,
    isUserInputEnabled,
    isLiveChatEnded,
    isConversationEnded,
    canRestart,
    process,
    isWaiting,
    currentQueuePosition,
    isLiveChatStatusLoading,
    hasPostedLiveMessage,
    hasAgentJoined,
  } = useChat();
  const { setOnWidgetClose, toggleWidget } = useSupportWidgetContext();
  const history = useHistory();
  const [warningType, setWarningType] = useState(null);
  const [leaveAction, setLeaveAction] = useState(null);
  const { attachments, uploadAttachments, cancelAttachment } =
    useSunshineUploads({ sessionId });
  const canLoseLiveChatConversation =
    conversationType == "live-chat" && hasPostedLiveMessage && !isLiveChatEnded;

  const onLeave = () => {
    if (leaveAction == ACTION_BACK) {
      history.replace("/");
    } else {
      toggleWidget({ shouldTriggerOnWidgetClose: false });
    }
  };

  useEffect(() => {
    if (messages.length == 0 && !isLiveChatStatusLoading) {
      process({ event: "start-chat" });
    }

    const element = document.getElementById("message-scroll-view");
    if (element) {
      element.scrollIntoView();
    }
  }, [messages.length, process, isLiveChatStatusLoading]);

  useEffect(() => {
    if (canLoseLiveChatConversation) {
      const onUnload = e => {
        e.preventDefault();
        e.returnValue = "";
        return "";
      };

      window.addEventListener("beforeunload", onUnload);
      setOnWidgetClose(() => {
        setLeaveAction(ACTION_COLLAPSE);
        setWarningType(
          !hasAgentJoined ? WARNING_WHEN_IN_QUEUE : WARNING_WHEN_CHATTING,
        );
      });

      return () => {
        window.removeEventListener("beforeunload", onUnload);
        setOnWidgetClose(null);
      };
    }
  }, [canLoseLiveChatConversation, setOnWidgetClose, hasAgentJoined]);

  const handleRestartChatButtonClick = () => {
    setMessages([]);
  };

  const onSubmit = event => {
    process({ event: "post-message", value: event.message });
  };

  const preventLeavingLiveChat = () => {
    if (canLoseLiveChatConversation) {
      setLeaveAction(ACTION_BACK);
      setWarningType(
        !hasAgentJoined ? WARNING_WHEN_IN_QUEUE : WARNING_WHEN_CHATTING,
      );
    } else {
      history.replace("/");
    }
  };

  if (warningType) {
    return (
      <LiveChatLeaveDialog
        warningType={warningType}
        onStay={() => {
          setLeaveAction(null);
          setWarningType(null);
        }}
        onLeave={onLeave}
      />
    );
  }

  return (
    <>
      <div className="sticky top-0 z-10">
        <div className="border-primary bg-primary flex h-[52px] shrink-0 items-center justify-between border-b px-3">
          <HeaderIconButton type="back" onClick={preventLeavingLiveChat} />
          <div>
            {canRestart && (
              <TippyV2
                placement="top"
                content={t("support_widget.chatbot.restart")}
              >
                <HeaderIconButton
                  ariaLabel={t(
                    "support_widget.search_container.restart_button_label",
                  )}
                  icon="16-restart"
                  className="items-center justify-center"
                  size={16}
                  onClick={handleRestartChatButtonClick}
                />
              </TippyV2>
            )}
            {sessionId && (
              <TippyV2
                placement="top"
                content={t("support_widget.chatbot.download_chat")}
              >
                <HeaderIconButton
                  href={internalApi.supportWidget.downloadChat(sessionId)}
                  icon="download"
                  className=""
                />
              </TippyV2>
            )}
          </div>
        </div>
        {isWaiting && (
          <div className="border-primary bg-secondary flex gap-4 border-b p-4">
            <div className="bg-primary flex h-10 w-10 shrink-0 items-center justify-center rounded-lg text-[#6550D9]">
              <Icon type="20-time-delay" size={20} />
            </div>
            <div>
              <Typography.BodySm>
                {t("support_widget.chatbot.live_chat_wait_queue.please_wait")}
              </Typography.BodySm>
              <Typography.BodySm weight="font-bold">
                {t(
                  "support_widget.chatbot.live_chat_wait_queue.current_position",
                  { position: ordinalize(currentQueuePosition) },
                )}
              </Typography.BodySm>
            </div>
          </div>
        )}
      </div>
      <div className="mb-6 mt-auto flex flex-col">
        {messages.map(message => (
          <ChatMessage key={message.id} {...message} process={process} />
        ))}
        {isBotProcessing && <Thinking />}
        {attachments.length > 0 && (
          <div className="mt-4 flex flex-col gap-2 pl-6 pr-4">
            {attachments.map(attachment => (
              <FormAttachment
                key={attachment.url}
                attachment={attachment}
                onCancel={cancelAttachment}
              />
            ))}
          </div>
        )}
        <div id="message-scroll-view" />
      </div>
      {isUserInputEnabled ? (
        <div className="bg-primary sticky bottom-0 z-10">
          <div className="border-primary border-t">
            <Form onSubmit={onSubmit}>
              {conversationType == "live-chat" ? (
                <LiveChatForm
                  onSubmit={onSubmit}
                  uploadAttachments={uploadAttachments}
                  uploadingEnabled={hasPostedLiveMessage}
                />
              ) : (
                <ChatForm onSubmit={onSubmit} />
              )}
            </Form>
          </div>
        </div>
      ) : null}
      {isConversationEnded && (
        <ChatEnded type={TYPE_AI_CHAT} onClick={() => history.replace("/")} />
      )}
      {isLiveChatEnded && (
        <ChatEnded type={TYPE_LIVE_CHAT} onClick={() => history.replace("/")} />
      )}
    </>
  );
};
