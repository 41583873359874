import { t } from "@/i18n-js/instance";
import { paywallTypes } from "@circle-react/types";
import { PaywallsFilter } from "./PaywallsFilter";

const localeRoot = "settings.paywalls_admin_dashboard.filters.events";
const queryParamsKey = "events";

export const EventPaywallsFilter = props => (
  <PaywallsFilter
    {...props}
    localeRoot={localeRoot}
    queryParamsKey={queryParamsKey}
    filterName="event-paywalls-filter"
    filterParams={{ type: [paywallTypes.event] }}
  />
);

export const filterProperties = {
  name: "events",
  filterKeys: ["events"],
  component: EventPaywallsFilter,
  label: t([localeRoot, "chip"]),
};
