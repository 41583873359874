import { t } from "@/i18n-js/instance";
import { useGetForm } from "@circle-react/components/SettingsApp/Emails/components/Forms/hooks/useGetForm";
import { BaseResource } from "@circle-react/components/SettingsApp/Workflows/components/Events/BaseResource";

export interface FormResourcePropType {
  resourceId: number;
}

export const FormResource = ({ resourceId }: FormResourcePropType) => {
  const { data: form, isLoading } = useGetForm({ id: resourceId });

  return (
    <BaseResource
      label={t("settings.workflows.form.events.received_form_submission.form")}
      value={form?.name}
      isLoading={isLoading}
    />
  );
};
