import { t } from "@/i18n-js/instance";
import { AmountField } from "../Fields/AmountField";
import { InputField } from "../Fields/InputField";

const localeRoot =
  "settings.paywalls.modal.tabs.pricing.add_price_modal.tooltip_text";

export const OneTimeForm = () => (
  <>
    <div className="flex flex-row gap-x-4">
      <InputField
        name="in_app_apple_processor_id"
        tooltipText={t([localeRoot, "in_app_apple_processor_id"])}
      />
      <InputField
        name="in_app_google_processor_id"
        tooltipText={t([localeRoot, "in_app_apple_processor_id"])}
      />
    </div>
    <AmountField name="amount" labelKey="price" />
  </>
);
