import { useEffect, useState } from "react";
import { omit } from "lodash";
import { Switch } from "react-router-dom";
import {
  useShowAgent,
  useUpdateAgent,
} from "@circle-react/components/SettingsApp/CommunityBot/agentsQueries";
import { AudiencePage } from "@circle-react/pages/BotBuilder/AudiencePage";
import { useMemberFilterUrl } from "@circle-react/pages/BotBuilder/AudiencePage/useMemberFilter";
import { BehaviorPage } from "@circle-react/pages/BotBuilder/BehaviorPage";
import { CustomizePage } from "@circle-react/pages/BotBuilder/CustomizePage";
import { KnowledgePage } from "@circle-react/pages/BotBuilder/KnowledgePage";
import { applyV3Fonts } from "@circle-react/providers/Theme";
import {
  USAGE_SECTIONS,
  UsageContextProvider,
} from "@circle-react/providers/UsageContext";
import { FilterValueContextProvider } from "@circle-react-shared/MemberFilters";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { ProtectedRoute } from "@circle-react-shared/ProtectedRoute";
import { ErrorBoundary } from "@circle-react-shared/uikit/ErrorBoundary";
import { SomethingWentWrong } from "@circle-react-shared/uikit/ErrorPages";
import { Form } from "@circle-react-shared/uikit/Form";

const renderSomethingWentWrong = () => <SomethingWentWrong />;

export const BotBuilderRoutes = () => {
  applyV3Fonts();
  const urlElements = window.location.pathname.split("/");
  const id = parseInt(urlElements[urlElements.length - 2]);

  const [filters, setFilters] = useState<Array<BaseFilter>>([]);
  const { data: agent } = useShowAgent({ id });
  const { mutate: updateAgent } = useUpdateAgent({ id });
  const { updateFilters } = useMemberFilterUrl(filters, setFilters);

  useEffect(() => {
    setFilters(agent?.members_filters || []);
  }, [agent, setFilters]);

  if (!agent) return null;

  return (
    <FilterValueContextProvider
      filterParams={filters}
      onApply={(id, filter) => {
        updateFilters(id, filter);
      }}
      onClearAll={() => setFilters([])}
    >
      <Form
        id="bot-builder-form"
        encType="multipart/form-data"
        defaultValues={omit(agent, "members_filters")}
        onSubmit={(data: any, _helpers: any, event: any) => {
          const formId = event?.nativeEvent?.srcElement?.getAttribute("id");
          if (formId === "bot-builder-form") {
            updateAgent({ ...data, members_filters: filters });
          }
        }}
      >
        <ErrorBoundary renderFunc={renderSomethingWentWrong}>
          <UsageContextProvider section={USAGE_SECTIONS.ADMIN}>
            <Switch>
              <ProtectedRoute
                path="/settings/ai-agents/edit/:botPublicId/audience"
                render={props => (
                  <AudiencePage {...props} agent={agent} filters={filters} />
                )}
              />
              <ProtectedRoute
                path="/settings/ai-agents/edit/:botPublicId/customize"
                render={props => <CustomizePage {...props} agent={agent} />}
              />
              <ProtectedRoute
                path="/settings/ai-agents/edit/:botPublicId/behavior"
                render={props => <BehaviorPage {...props} agent={agent} />}
              />
              <ProtectedRoute
                path="/settings/ai-agents/edit/:botPublicId/knowledge"
                render={props => <KnowledgePage {...props} agent={agent} />}
              />
            </Switch>
          </UsageContextProvider>
        </ErrorBoundary>
      </Form>
    </FilterValueContextProvider>
  );
};
