import { useMemo } from "react";
import { t } from "@/i18n-js/instance";
import type { IconType } from "@/react/components/shared/Icon";
import { getActionIcon } from "@circle-react-shared/WorkflowsV2/ActionNode/constants";

const i18nRoot = "settings.workflows";

export interface UseActionGroupProps {
  actions: string[];
  truncationLimit: number;
}

export interface UseActionGroupReturn {
  actions: {
    title: string;
    icon: IconType;
  }[];
  remainingActionsCount: number;
  remainingItemsTooltip: string;
}

export const useActionGroup = ({
  actions: actionsProp,
  truncationLimit,
}: UseActionGroupProps): UseActionGroupReturn => {
  const mappedActions = useMemo(
    () =>
      actionsProp
        .filter(actionType => actionType !== "")
        .map(actionType => {
          const icon = getActionIcon(actionType);

          return {
            title: `${t([i18nRoot, "action_types", actionType], {
              defaultValue: t([i18nRoot, "action_types", "unknown"]),
            })}`,
            icon,
          };
        }),
    [actionsProp],
  );

  const remainingItemsTooltip = useMemo(
    () =>
      actionsProp
        .slice(truncationLimit)
        .map(
          actionType =>
            `${t([i18nRoot, "action_types", actionType], {
              defaultValue: t([i18nRoot, "action_types", "unknown"]),
            })}`,
        )
        .join(", "),
    [actionsProp, truncationLimit],
  );

  const remainingActionsCount = actionsProp.length - truncationLimit;

  const actions =
    remainingActionsCount > 1
      ? mappedActions.slice(0, truncationLimit)
      : mappedActions;

  return {
    actions,
    remainingActionsCount,
    remainingItemsTooltip,
  };
};
