import { useEffect } from "react";
import { PostItem } from "@/react/components/PostsV3/PostViewList/PostItem";
import { useDynamicLayoutContext } from "@circle-react/components/Layout/DynamicLayout";
import { PageMetaData } from "@circle-react/components/Layout/PageMetaData";
import { StandardLayout } from "@circle-react/components/Layout/StandardLayout";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";

export const IFramePostView = ({ postResource }) => {
  const post = postResource.data;
  const { setEmptyLayout, setStandardLayout } = useDynamicLayoutContext();

  useEffect(() => {
    setEmptyLayout();
    return setStandardLayout;
  }, []);

  if (!post) return null;

  return (
    <VersionedContent fullWidth>
      <StandardLayout.Body>
        <PageMetaData bodyClassNames="view-space" />
        <StandardLayout.Content>
          <PostItem post={post} forceIframeView />
        </StandardLayout.Content>
      </StandardLayout.Body>
    </VersionedContent>
  );
};
