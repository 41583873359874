import { t } from "@/i18n-js/instance";

export const defaultSuggestedPauseAiKeywords = [
  t(
    "community_bot.bot_builder.behavior_page.suggested_pause_ai_keywords.support",
  ),
  t(
    "community_bot.bot_builder.behavior_page.suggested_pause_ai_keywords.error",
  ),
  t(
    "community_bot.bot_builder.behavior_page.suggested_pause_ai_keywords.human",
  ),
  t(
    "community_bot.bot_builder.behavior_page.suggested_pause_ai_keywords.need_help",
  ),
];
