import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useAccessGroups } from "@circle-react/hooks/accessRegistry/useAccessGroups";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";

export const AccessGroupSelector = () => {
  const { register } = useFormContext();
  const { data: accessGroupsPaginator, isLoading: isLoadingAccessGroups } =
    useAccessGroups();

  const eventsOptions = accessGroupsPaginator?.records?.map(
    (accessGroup: AccessGroupType) => ({
      label: accessGroup.name,
      value: accessGroup.id,
    }),
  );

  if (isLoadingAccessGroups) {
    return <Loader center />;
  }

  return (
    <>
      <div className="hidden">
        <input type="hidden" {...register("trigger.resource_type")} />
      </div>

      <FormTextSelect
        name="trigger.resource_id"
        label={t("settings.workflows.form.choose_access_group")}
        options={eventsOptions}
        rules={{
          required: t("settings.workflows.form.errors.required"),
        }}
        emptyMessage={t(
          "settings.workflows.form.errors.access_groups.not_found",
        )}
      />
    </>
  );
};
